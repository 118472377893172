/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useMemo } from 'react';
import { useForm, useFormContext } from 'react-hook-form';
import { useAutoSaveHandler } from '../../../../hooks/useAutoSaveHandler';
import { Data, Hooks, formatWholeDollars } from '@3nickels/data-modules';
import { BudgetSectionProps, MiscFormData, mapMiscFormToMonthlySpendingRest } from '../../Types';
import * as Yup from 'yup';
import EmbeddedFormInput from '../../../../components/EmbeddedFormInput';
import { Grid } from '@mui/material';
import { cleanWholeNumberStr } from '../../../../helpers/utilityFunctions';
import { SpendingProps } from '../Types';
import { CollapsiblePanel } from '../../../../components/CollapsiblePanel';
import { Svgs } from '../../../../assets/svg';
import FormContent from '../../../../components/form/FormContent';
import _ from 'lodash';
import { t } from 'i18next';

const schema: () => Yup.Schema = () =>
  Yup.object({
    otherTransportation: Yup.string().notRequired(),
    transportation: Yup.string().notRequired(),
    carMaintenance: Yup.string().notRequired(),
    carInsurance: Yup.string().notRequired(),
    fuel: Yup.string().notRequired(),
  });

export declare type MiscellaneousFormProps = BudgetSectionProps & {
  defaultValues: MiscFormData;
};

export const MiscellaneousForm: React.FC<MiscellaneousFormProps> = ({ defaultValues, mode }) => {
  const { formState } = useFormContext<MiscFormData>();
  const { person } = Hooks.useCombinedSelfData();
  const commands = Hooks.useCommands();

  const max = useMemo(() => {
    if (mode === 'monthly') {
      return 999999;
    }

    return 999999 * 12;
  }, [mode]);

  const formatInputs = (data: any): MiscFormData => {
    const entertainment = cleanWholeNumberStr(data.entertainment ?? '', { max });
    const otherExpenses = cleanWholeNumberStr(data.otherExpenses ?? '', { max });

    return { entertainment, otherExpenses };
  };

  const saveHandler = useCallback(
    async (data: MiscFormData) => {
      const params = mapMiscFormToMonthlySpendingRest(mode, data);
      const filtered = _.pickBy(params, (x) => typeof x !== 'undefined');
      await commands.execute(Data.Budget.Commands.UpdateMonthlySpending, filtered);
    },
    [person, mode, commands]
  );

  const { onChange } = useAutoSaveHandler({
    defaultValues,
    mode: 'partial',
    onSave: saveHandler,
    schema: schema(),
    transformValues: formatInputs,
  });

  // TODO -- CardDisplay should be the refactored version: CollapsibleCard
  // (we just need to create it)
  return (
    <form onChange={onChange}>
      <Grid className='embedded-form'>
        <EmbeddedFormInput<MiscFormData>
          label='Entertainment'
          placeholder='$0'
          autoComplete='off'
          name='entertainment'
          type='dollar'
          max={max}
          defaultValue={defaultValues.entertainment}
          error={formState.errors.entertainment !== undefined}
          helperText={formState.errors.entertainment?.message?.toString()}
        />
        <EmbeddedFormInput<MiscFormData>
          label='OtherMiscellaneous'
          placeholder='$0'
          autoComplete='off'
          name='otherExpenses'
          type='dollar'
          max={max}
          defaultValue={defaultValues.otherExpenses}
          error={formState.errors.otherExpenses !== undefined}
          helperText={formState.errors.otherExpenses?.message?.toString()}
        />
      </Grid>
    </form>
  );
};

export const MiscellaneousSpendingSection: React.FC<BudgetSectionProps & SpendingProps> = ({
  mode,
  monthlySpending,
  spendingSummary,
}) => {
  const defaultValues = useMemo(() => {
    return {
      entertainment: Data.Budget.adjustMonthlyAmount(mode, monthlySpending?.entertainment),
      otherExpenses: Data.Budget.adjustMonthlyAmount(mode, monthlySpending?.otherExpenses),
    };
  }, [monthlySpending, mode]);

  const summary = useMemo(
    () => Data.Budget.adjustMonthlyAmount(mode, spendingSummary.misc),
    [spendingSummary, mode]
  );

  const methods = useForm<MiscFormData>();
  return (
    <CollapsiblePanel
      variant='compressed'
      header={t('Miscellaneous')}
      expandIconPosition='relative'
      icon={<Svgs.DisplaySmallCustomCoins />}
      summary={formatWholeDollars(summary)}>
      <Grid className='embedded-content'>
        <FormContent formProviderProps={methods}>
          <MiscellaneousForm defaultValues={defaultValues} mode={mode} />
        </FormContent>
      </Grid>
    </CollapsiblePanel>
  );
};
