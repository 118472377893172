/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React from 'react';
import {
  Data,
  Domain,
  formatWholeDollars,
  Hooks,
  useObservableGuardCondition,
} from '@3nickels/data-modules';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import FormContent from '../../../../../components/form/FormContent';
import { WizardStep } from '@aesop-fables/scrinium';
import { WizardFooter } from '../../../../../components/form/WizardFooter';
import { useLoading } from '../../../../../hooks/useLoading';
import { EditViewProps, getWizardFooterProps } from '../../../../EditViewUtils';
import { cleanWholeNumberStr } from '../../../../../helpers/utilityFunctions';
import { Spacing } from '../../../../../themes';
import IraContributionsForm from './IraContributionsForm';
import { useRetirementPlanPath } from '../../../../../hooks/useRetirementPlanPath';
import { t } from 'i18next';

const accountContributionsSchema = (planType: Domain.IraPlanTypeEnum, maxContrib = 10000000) =>
  Yup.object({
    annualContribPreTaxDollar: Yup.string()
      .notRequired()
      .test(
        'preTax',
        t('MaximumColonX', { x: formatWholeDollars(maxContrib) }) as string,
        (amount) => {
          const contributionType = Domain.getIraContributionType(planType);
          if (contributionType === 'annualContribPreTaxDollar') {
            const cleanNumber = cleanWholeNumberStr(amount ?? '');
            return cleanNumber <= maxContrib;
          }

          return true;
        }
      ),
    annualContribRothDollar: Yup.string()
      .notRequired()
      .test(
        'roth',
        t('MaximumColonX', { x: formatWholeDollars(maxContrib) }) as string,
        (amount) => {
          const contributionType = Domain.getIraContributionType(planType);
          if (contributionType === 'annualContribRothDollar') {
            const cleanNumber = cleanWholeNumberStr(amount ?? '');
            return cleanNumber <= maxContrib;
          }

          return true;
        }
      ),
    annualContribAfterTaxDollar: Yup.string()
      .notRequired()
      .test(
        'postTax',
        t('MaximumColonX', { x: formatWholeDollars(maxContrib) }) as string,
        (amount) => {
          const contributionType = Domain.getIraContributionType(planType);
          if (contributionType === 'annualContribAfterTaxDollar') {
            const cleanNumber = cleanWholeNumberStr(amount ?? '');
            return cleanNumber <= maxContrib;
          }

          return true;
        }
      ),
  });

interface IraContributionsEditViewProps extends EditViewProps {
  currentStep: WizardStep<
    Data.InvestmentAccounts.AccountContributionsFormData,
    Data.InvestmentAccounts.InvestmentAccountWizardParams
  >;
  wizard: Data.InvestmentAccounts.RetirementWizard;
  params: Data.InvestmentAccounts.InvestmentAccountWizardParams;
}

export const IraContributionsEditView: React.FC<IraContributionsEditViewProps> = ({
  currentStep,
  wizard,
  params,
  name,
  editing,
  onBack,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const ready = useObservableGuardCondition();
  const path = useRetirementPlanPath();
  const iraContributionLimit = Hooks.useIraContributionLimit();
  const methods = useForm<Data.InvestmentAccounts.AccountContributionsFormData>({
    defaultValues: {
      ...currentStep.model,
      contributionType: Domain.EligibleContributionTypeEnum.Dollar,
      eligibleForContributions: true,
    },
    resolver: yupResolver(
      accountContributionsSchema(
        params.type as unknown as Domain.IraPlanTypeEnum,
        iraContributionLimit
      )
    ),
  });
  const { setLoading } = useLoading();

  const formatInputs = (values: any) => {
    const annualContribPreTaxDollar = cleanWholeNumberStr(
      values['annualContribPreTaxDollar'] ?? ''
    );
    const annualContribRothDollar = cleanWholeNumberStr(values['annualContribRothDollar'] ?? '');
    const annualContribAfterTaxDollar = cleanWholeNumberStr(
      values['annualContribAfterTaxDollar'] ?? ''
    );
    const paycheckContributions =
      annualContribPreTaxDollar > 0 ||
      annualContribRothDollar > 0 ||
      annualContribAfterTaxDollar > 0;
    let formatted: Partial<Data.InvestmentAccounts.AccountContributionsFormData> = {
      paycheckContributions,
      annualContribPreTaxDollar,
      annualContribRothDollar,
      annualContribAfterTaxDollar,
    };

    // only send roth/afterTax allowed if different from currentValue
    if (currentStep.model.rothContributionsAllowed !== values['rothContributionsAllowed']) {
      formatted = {
        ...formatted,
        rothContributionsAllowed: values['rothContributionsAllowed'] === 'true',
      };
    }
    if (currentStep.model.afterTaxContributionsAllowed !== values['afterTaxContributionsAllowed']) {
      formatted = {
        ...formatted,
        afterTaxContributionsAllowed: values['afterTaxContributionsAllowed'] === 'true',
      };
    }

    return formatted;
  };

  const onSubmit = async (values: any) => {
    setLoading(true);

    try {
      const cleanedValues = formatInputs(values);
      currentStep.save({ ...values, ...cleanedValues });
      await wizard.commitStep('contributions');

      if (editing && onBack) {
        onBack();
        return;
      }

      const wizardPlanType =
        Domain.planTypeIdToAccountKey[params.type as keyof typeof Domain.planTypeIdToAccountKey];

      navigate(`/account-details/retirement-savings-plan/${path}/${wizardPlanType}/${params.id}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {ready && (
        <FormContent formProviderProps={methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Box>
              {/* Should be about 30px */}
              <Typography className='title' color='primary' component='h1' variant='h1'>
                {t('AnnualContributions')}
              </Typography>
              <Typography variant='p18Bold' color='secondary' mt={Spacing.xxxs}>
                {name}
              </Typography>
              <Typography variant='p16' color='secondary' mt={1}>
                {t('HowMuchMoneyWillYouPutIntoThisAccount')}
              </Typography>

              <Grid container justifyContent='center' mt={2}>
                <Grid item sm={editing ? 10 : 6}>
                  <IraContributionsForm
                    contributions={currentStep.model}
                    planType={params.type as unknown as Domain.IraPlanTypeEnum}
                    onSubmit={onSubmit}
                  />
                </Grid>
              </Grid>
            </Box>
            <WizardFooter
              color={editing ? 'primary' : undefined}
              disableBack={false}
              onBack={onBack}
              {...getWizardFooterProps('Next', editing)}
            />
          </form>
        </FormContent>
      )}
    </>
  );
};
