import { Data, Domain, Hooks } from '@3nickels/data-modules';
import { Grid, Typography, Box, DialogContent, DialogActions } from '@mui/material';
import { t } from 'i18next';
import { Svgs } from '../../../assets/svg';
import { GroupEdit } from '../../../components/GroupEdit';
import { cleanWholeNumberStr } from '../../../helpers/utilityFunctions';
import { Spacer } from '../../../components';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MutatorState } from '@aesop-fables/scrinium';
import { useLoading } from '../../../hooks/useLoading';
import { PalmTreeSvg } from '../../../components/images/PalmTreeSvg';
import { BootstrapDialog, BootstrapHeader } from '../../../components/Modal';
import { Button } from '../../../components/buttons/Button';
import HelpPopover, { HelpPopoverText } from '../../../components/HelpPopover';
import Tippy from '@tippyjs/react';
import { HelpOutline } from '@mui/icons-material';
import { theme } from '../../../themes/ThemeWrapper';
import { usePlaidCredentialRefresh } from '../../../hooks/usePlaidCredentialRefresh';
import RemoveAccountModal from '../RemoveAccountModal';
import { useMessage } from '../../../hooks/useMessage';

interface RetirementPlanViewProps {
  retirementPlans: Domain.PlanData[];
  deleteRetirementPlan: MutatorState<Domain.IInvestmentAccount>;
}

export const RetirementPlanView: React.FC<RetirementPlanViewProps> = ({
  retirementPlans,
  deleteRetirementPlan,
}) => {
  const { setLoading } = useLoading();
  const navigate = useNavigate();
  const { showMessage } = useMessage();
  const commands = Hooks.useCommands();
  const { wizard } = Hooks.useRetirementWizard();
  const { wizard: pensionWizard } = Hooks.usePensionWizard();
  const { wizard: annuityWizard } = Hooks.useAnnuityWizard();
  const organization = Hooks.useOrganizationData();
  const { institutionalAccount, institutionalAccountRequiresReview } =
    Hooks.useInstitutionalAccount();
  const planTypes = Hooks.useAvailablePlanTypes('me');
  const spousePlanTypes = Hooks.useAvailablePlanTypes('spouse');
  const duplicateAccountService = Hooks.useDuplicateAccountService();
  const [modalOpen, setModalOpen] = useState(false);
  const [reviewInstitutionalAccountOpen, setReviewInstitutionalAccountOpen] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState<
    Domain.IInvestmentAccount & { planType?: Domain.PlanTypeEnum } & Domain.OwnedAccount
  >();
  const { setIdAndUpdateLogin } = usePlaidCredentialRefresh();

  const isPension = (planType?: Domain.PlanTypeEnum) => planType === Domain.PlanTypeEnum.Pension;
  const isAnnuity = (planType?: Domain.PlanTypeEnum) =>
    planType === Domain.PlanTypeEnum['Annuity | Fixed'];

  const handleEdit = (x: Domain.PlanData) => {
    const isSpouse = x.owner === 'spouse';
    const type =
      x.planType ??
      (isSpouse && spousePlanTypes.length > 0
        ? (spousePlanTypes[0].key as number)
        : (planTypes[0].key as number));

    if (x.id === institutionalAccount?.id && institutionalAccountRequiresReview) {
      setSelectedAccount({
        ...Data.InvestmentAccounts.mapPlanDataToInvestmentAccount(x),
        planType: x.planType,
        participantId: x.participantId,
      });
      setReviewInstitutionalAccountOpen(true);
      return;
    }

    if (isPension(x.planType)) {
      pensionWizard.start({ id: x.id }).then(() => {
        navigate(`/account-details/pension-plan/summary/${x.id}`);
      });
      return;
    }

    if (isAnnuity(x.planType)) {
      annuityWizard.start({ id: x.id }).then(() => {
        navigate(`/account-details/fixed-annuity/summary/${x.id}`);
      });
      return;
    }

    wizard.start({ id: x.id, type, isSpouse });
    navigate(
      `/account-details/retirement-savings-plan/summary/${
        Domain.planTypeIdToAccountKey[x.planType as keyof typeof Domain.planTypeIdToAccountKey]
      }/${x.id}`
    );
  };

  const handleDeleteRetirementPlan = async (
    account: Domain.IInvestmentAccount & {
      planType?: Domain.PlanTypeEnum;
    }
  ) => {
    if (account.id) {
      setLoading(true);
      if (isAnnuity(account.planType)) {
        try {
          await commands.execute(Data.Annuities.Commands.DeleteAnnuity, account.id);
          showMessage('Annuity Removed', 'success');
        } catch (err) {
          showMessage(t('WeWerentBankingOnThat') as string, 'error');
        } finally {
          setLoading(false);
        }
      } else {
        deleteRetirementPlan.action(account);
      }
    }
  };

  const startInstitutionalAccount = async () => {
    if (selectedAccount && selectedAccount.planType) {
      const duplicate = await duplicateAccountService.checkForInstitutionalAccountDuplicates();
      if (duplicate?.planType) {
        wizard.start({
          id: duplicate.id,
          type: duplicate.planType,
          isSpouse: false,
        });
        navigate(
          `/account-details/retirement-savings-plan/duplicate/${
            Domain.planTypeIdToAccountKey[
              duplicate.planType as keyof typeof Domain.planTypeIdToAccountKey
            ]
          }/${duplicate.id}`
        );
        return;
      }
      wizard.start({
        id: selectedAccount.id,
        type: selectedAccount.planType,
        isSpouse: false,
      });
      navigate(
        `/account-details/retirement-savings-plan/basics/${
          Domain.planTypeIdToAccountKey[
            selectedAccount.planType as keyof typeof Domain.planTypeIdToAccountKey
          ]
        }/${selectedAccount.id}`
      );
    }
  };

  if (retirementPlans.length === 0) return null;

  return (
    <>
      <Grid display='none'>
        <ReviewInstitutionalAccountModal
          open={reviewInstitutionalAccountOpen}
          setOpen={setReviewInstitutionalAccountOpen}
          onStart={startInstitutionalAccount}
        />
      </Grid>
      <RemoveAccountModal
        name={selectedAccount?.name?.replace('*', '')}
        type={selectedAccount?.planType && Domain.PlanTypeEnum[selectedAccount.planType]}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        handleSave={() => {
          if (selectedAccount) {
            handleDeleteRetirementPlan(selectedAccount);
          }
        }}
      />
      <Grid item xs={12} className='account-view'>
        <Typography
          className='account-type-header'
          display='flex'
          alignItems='center'
          color='primary'
          variant='p18Bold'>
          {PalmTreeSvg({ fontSize: 'large' })}
          {t('RetirementPlans', { number: retirementPlans.length })}
        </Typography>
        <Box>
          <GroupEdit<Domain.PlanData>
            className='account-list'
            items={retirementPlans}
            keyFn={(x) => `${x.id ?? 0}`}
            mainContentFn={(x) => {
              const isInstitutional = x.id === institutionalAccount?.id;

              return (
                <Grid container>
                  <Grid marginRight='10px'>
                    <Grid
                      container
                      justifyContent='space-between'
                      alignItems='center'
                      flexDirection='row'>
                      <Typography
                        className='hover-clickable'
                        color='secondary'
                        fontWeight='bold'
                        onClick={() => handleEdit(x)}>
                        {x.nickname}
                      </Typography>
                      <Grid ml='3px' display='flex' alignItems='center'>
                        {(isPension(x.planType) || isAnnuity(x.planType)) && (
                          <Tippy
                            placement='right'
                            content={
                              isPension(x.planType) ? (
                                <HelpPopover title={t('PensionPlans')}>
                                  <HelpPopoverText>
                                    {t('BecausePensionRepresentAPotentialSourceOfIncome')}
                                  </HelpPopoverText>
                                </HelpPopover>
                              ) : (
                                <HelpPopover title={t('FixedAnnuity')}>
                                  <HelpPopoverText>
                                    {t('BecauseAnnuitiesRepresentAPotentialSourceOfIncome')}
                                  </HelpPopoverText>
                                </HelpPopover>
                              )
                            }
                            theme='light-nickels-theme'>
                            <HelpOutline
                              style={{
                                color: theme.palette.primary.main,
                                height: '20px',
                              }}
                            />
                          </Tippy>
                        )}
                      </Grid>
                    </Grid>
                    <Grid direction='row' display='flex' alignItems='center'>
                      <Typography marginTop='5px' color='secondary' variant='p12'>
                        {isInstitutional
                          ? organization?.name
                          : x.linkedInstitution?.institutionName ?? t('ManuallyAdded')}
                      </Typography>

                      {x.linkedInstitution?.requiresLogin && (
                        <>
                          <Typography ml={'10px'} marginTop='5px' color='secondary' variant='p12'>
                            |
                          </Typography>
                          <Typography
                            onClick={() => {
                              setIdAndUpdateLogin(x.linkedInstitution?.accessId ?? 0);
                            }}
                            sx={{
                              '&:hover': {
                                textDecoration: 'underline',
                                cursor: 'pointer',
                              },
                            }}
                            ml={'10px'}
                            marginTop='5px'
                            color='error'
                            component='span'
                            variant='p12'>
                            {t('LoginExpired')}
                          </Typography>
                        </>
                      )}
                    </Grid>
                  </Grid>
                  <Grid
                    className='account-view-status-icon'
                    height='20px'
                    display='flex'
                    alignItems='center'>
                    {isInstitutional && <Svgs.IconOrganization />}
                    {x.isLinked && (
                      <Grid display='flex' alignItems='center'>
                        <Svgs.LinkPaperClip />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              );
            }}
            summaryFn={(x) => {
              const summary = Data.InvestmentAccounts.mapPlanDataToInvestmentAccount(x).summary;
              return (
                <Grid>
                  <Typography
                    color={cleanWholeNumberStr(summary ?? '') >= 0 ? 'secondary' : 'error'}>
                    {summary}
                  </Typography>
                </Grid>
              );
            }}
            needsAttention={(x) =>
              (x.id === institutionalAccount?.id && institutionalAccountRequiresReview) ||
              x.linkedInstitution?.requiresLogin
            }
            stackEndContent
            disableDelete={(x) => x.id === institutionalAccount?.id}
            onDelete={(x) => {
              setSelectedAccount({
                ...Data.InvestmentAccounts.mapPlanDataToInvestmentAccount(x),
                planType: x.planType,
                participantId: x.participantId,
              });
              setModalOpen(true);
            }}
            editLabel={(x) => {
              if (x.id === institutionalAccount?.id && institutionalAccountRequiresReview)
                return t('Review');
              return t('Edit');
            }}
            onEdit={(x) => {
              handleEdit(x);
            }}
          />
        </Box>
      </Grid>
    </>
  );
};

interface ReviewInstitutionalAccountModalProps {
  onStart: () => void;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ReviewInstitutionalAccountModal: React.FC<ReviewInstitutionalAccountModalProps> = ({
  open,
  setOpen,
  onStart,
}) => {
  const organization = Hooks.useOrganizationData();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div style={{ borderRadius: '10px', padding: '20px' }}>
      <BootstrapDialog onClose={handleClose} aria-labelledby='customized-dialog-title' open={open}>
        <BootstrapHeader id='customized-dialog-title' onClose={handleClose} />
        <DialogContent className='institutional-modal-content'>
          <Svgs.ArtOrganization />
          <Typography variant='p24Bold' color='secondary'>
            {t('ThisPlanWasCreatedByOrganization', { organization: organization?.name })}
          </Typography>
          <Typography variant='p16' color='secondary'>
            {t('YoureOrganizationDidTheHeavyLifting')}
          </Typography>
        </DialogContent>
        <DialogActions className='institutional-modal-actions'>
          <Button color='primary' size='large' onClick={onStart}>
            <Typography variant='p16'>{t('Start')}</Typography>
          </Button>
        </DialogActions>
        <Spacer height={'xs'} />
      </BootstrapDialog>
    </div>
  );
};
