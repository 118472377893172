import { Data, Domain, Hooks } from '@3nickels/data-modules';
import { Box, Grid, Drawer, Stack } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { WizardFooter } from '../../../../components/form/WizardFooter';
import { Spacing } from '../../../../themes';
import { LayoutMeta, withLayoutMeta } from '../../../../types/LayoutMeta';
import { AccountDetailsLayoutMeta } from '../../AccountDetailsLayout';
import { OtherAssetEditViewWrapper as OtherAssetEditView } from './OtherAssetEditView';
import OtherAssetCard from './OtherAssetCard';
import { useMutation, useObservable } from '@aesop-fables/scrinium';
import { combineLatest } from 'rxjs';
import { Loading } from '../../../../hooks/useLoading';
import { useMessage } from '../../../../hooks/useMessage';
import { SummaryView } from '../../../../components/SummaryHeader';
import { t } from 'i18next';

declare type OtherAssetSummaryDrawerType = keyof Data.OtherAssets.OtherAssetWizardRegistry;

const OtherAssetSummaryView: React.FC = () => {
  const navigate = useNavigate();
  const { showMessage } = useMessage();
  const [open, setOpen] = useState(false);
  const data = Hooks.useOtherAssetWizardData();
  const includeSpouse = Hooks.useIncludeSpouse();
  const { assets, spouseAssets } = Hooks.useOtherAsset();
  const deleteMutation = useMutation(new Data.OtherAssets.Mutations.DeleteOtherAsset());
  const { wizard } = Hooks.useOtherAssetWizard();
  const isStarted = useObservable(wizard.isStarted$);

  const { id } = useParams();
  const otherAssetId = parseInt(id ?? '');
  const currentBalance = data.basic.value ?? 0;

  const accounts = spouseAssets ? [...assets, ...spouseAssets] : assets;
  const account = useMemo(
    () => accounts.find((x) => x.id === otherAssetId) as Domain.IInvestmentAccount,
    [accounts, otherAssetId]
  );

  useEffect(() => {
    if (typeof isStarted !== undefined && !isStarted) {
      wizard.start({ id: otherAssetId });
    }
  }, [isStarted]);

  const openDrawer = (key?: OtherAssetSummaryDrawerType) => {
    if (key) {
      wizard.selectStep(key);
    }
    setOpen(true);
  };

  const removeAccountHandler = useCallback(() => {
    deleteMutation.action(account?.id ?? 0); // no need to actually wait since the datacache will still be updated
    navigate('/account-details');
    showMessage(t('OtherAssetRemoved').toString());
  }, [account]);

  return (
    <SummaryView
      accountName={data.basic.name ?? ''}
      balance={currentBalance}
      onRemoveAccount={removeAccountHandler}
      accountType={Domain.FinancialAccountTypeEnum.InvestmentAccount}
      accountSubType={Domain.planTypeToAccountLabel[Domain.PlanTypeEnum['Other Asset']]}>
      <Grid item mt={2}>
        <Stack spacing={2}>
          <OtherAssetCard
            asset={data.basic}
            includeSpouse={includeSpouse}
            onEdit={() => openDrawer('basic')}
          />
        </Stack>
      </Grid>
      <WizardDrawer
        open={open}
        onClose={() => {
          setOpen(false);
          // wizard.selectStep(undefined as unknown as keyof Data.OtherAssets.OtherAssetWizardRegistry);
        }}
      />
      <WizardFooter nextLabel='Done' onDone={() => navigate('/account-details')} />
    </SummaryView>
  );
};

interface WizardDrawerProps {
  open: boolean;
  onClose: () => void;
}

const WizardDrawer: React.FC<WizardDrawerProps> = ({ onClose, open }) => {
  return (
    <Drawer anchor='right' open={open} onClose={onClose}>
      <Box p={Spacing.xxs}>
        <WizardForm onBack={onClose} />
      </Box>
    </Drawer>
  );
};

interface WizardFormProps {
  onBack: () => void;
}

const WizardForm: React.FC<WizardFormProps> = ({ onBack }) => {
  const { wizard } = Hooks.useOtherAssetWizard();
  const [initialized, current] = useObservable(
    combineLatest([wizard.initialized$, wizard.current$])
  ) ?? [false, undefined];
  const loading = !initialized;
  if (loading) {
    return <Loading />;
  }

  const key = current?.key;
  if (key === 'basic') {
    return <OtherAssetEditView editing onBack={onBack} />;
  }

  return null;
};

export interface OtherAssetSummaryOtherAssetdProps {
  onEdit: () => void;
  needsAttention?: boolean;
}

const meta = {
  nextLocaleKey: 'Done',
  headerVariant: 'summary',
} satisfies LayoutMeta<AccountDetailsLayoutMeta>;

export default withLayoutMeta(OtherAssetSummaryView, meta);
