/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { Api, Data, Domain, Hooks } from '@3nickels/data-modules';
import { FormLoader } from '../../../../../components/FormLoader';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import FormContent from '../../../../../components/form/FormContent';
import { WizardFooter } from '../../../../../components/form/WizardFooter';
import { useLoading } from '../../../../../hooks/useLoading';
import { EditViewProps, getWizardFooterProps } from '../../../../EditViewUtils';
import { InvestmentData } from '../../Types';
import InvestmentDetailsForm, { InvestmentDetailsFormData } from './InvestmentDetailsForm';
import { cleanWholeNumberStr } from '../../../../../helpers/utilityFunctions';
import { Spacer } from '../../../../../components';
import BulletedList from '../../../../../components/BulletedList';
import HelpPopover, { HelpPopoverText } from '../../../../../components/HelpPopover';
import HelpTitle from '../../../../../components/HelpTitle';
import { Margins } from '../../../../../themes';
import { showBalanceTypeToggle } from '../../../helpers/functions';
import { t } from 'i18next';

const investmentSchema = (
  type?: Domain.InvestmentAccountTypeEnum,
  showBalanceType?: boolean,
  investments?: Domain.InvestmentData[],
  editing?: boolean
) =>
  Yup.object({
    selected: Yup.object()
      .test(
        'investment-required',
        t(t('Required') as string) as string,
        function (value, { createError, path, parent }) {
          const { ticker, securityId, securityName } = parent;

          if (ticker && securityId && securityName) return true;

          return createError({
            message: t(t('Required') as string) as string,
            path,
          });
        }
      )
      .test(
        'no-duplicate',
        t('AlreadyUsed') as string,
        function (value, { createError, path, parent }) {
          const { ticker, taxType } = parent;

          const isDuplicate = investments?.some(
            (investment) =>
              investment.ticker === ticker &&
              (typeof taxType === 'undefined' || investment.taxType?.toString() === taxType)
          );

          if (!isDuplicate || editing) return true;

          return createError({
            message: t('AlreadyUsed') as string,
            path,
          });
        }
      ),
    valueType:
      type !== Domain.InvestmentAccountTypeEnum.BrokerageAccount
        ? Yup.string()
            .required(t('Required') as string)
            .test('valueType', t('Required') as string, (valueType) => {
              return Domain.InvestmentValueTypeEnumList.some((t) => {
                return t.key.toString() === valueType;
              });
            })
        : Yup.string().notRequired(),
    balanceType: Yup.string().when('valueType', {
      is: Domain.InvestmentValueTypeEnum['Add Balance'].toString(),
      then: (schema) =>
        schema
          .required(t('Required') as string)
          .test('balanceType', t('Required') as string, (balanceType) => {
            return Domain.InvestmentBalanceTypeEnumList.some((t) => {
              return t.key.toString() === balanceType;
            });
          }),
    }),
    taxType:
      showBalanceType &&
      type !== Domain.InvestmentAccountTypeEnum.HSA &&
      type !== Domain.InvestmentAccountTypeEnum.BrokerageAccount
        ? Yup.string()
            .when('valueType', {
              is: Domain.InvestmentValueTypeEnum['Add Balance'].toString(),
              then: (schema) =>
                schema
                  .required(t('Required') as string)
                  .test('taxType', t('Required') as string, (taxType) => {
                    return Domain.TaxTypeEnumList.some((t) => {
                      return t.key.toString() === taxType;
                    });
                  }),
            })
            .test(
              'no-duplicate',
              t('AlreadyUsed') as string,
              function (value, { createError, path, parent }) {
                const { ticker, taxType } = parent;

                const isDuplicate = investments?.some(
                  (investment) =>
                    investment.ticker === ticker &&
                    (typeof taxType === 'undefined' || investment.taxType?.toString() === taxType)
                );

                if (!isDuplicate || editing) return true;

                return createError({
                  message: t('AlreadyUsed') as string,
                  path,
                });
              }
            )
        : Yup.string().notRequired(),
    balance: Yup.string().when('valueType', {
      is: Domain.InvestmentValueTypeEnum['Add Balance'].toString(),
      then: (schema) =>
        schema.when('balanceType', {
          is: Domain.InvestmentBalanceTypeEnum['Total Balance'].toString(),
          then: (schema) =>
            schema
              .required(t('Required') as string)
              .test('balance', t('BalanceMustBeGreaterThan0') as string, (balance) => {
                const cleanNumber = cleanWholeNumberStr(balance);
                return cleanNumber > 0;
              }),
        }),
    }),
    nrShares: Yup.string().when('valueType', {
      is: Domain.InvestmentValueTypeEnum['Add Balance'].toString(),
      then: (schema) =>
        schema.when('balanceType', {
          is: Domain.InvestmentBalanceTypeEnum['# of Shares'].toString(),
          then: (schema) =>
            schema
              .required(t('Required') as string)
              .test('nrShares', t('NumberOfSharesMustBeGreaterThan0') as string, (nrShares) => {
                const cleanNumber = cleanWholeNumberStr(nrShares);
                return cleanNumber > 0;
              }),
        }),
    }),
  });

export const InvestmentDetailsEditViewWrapper: React.FC<EditViewProps> = ({ editing, onBack }) => {
  const { account } = Hooks.useInvestmentContext();
  return (
    <FormLoader loading={account?.id === 0}>
      <InvestmentDetailsEditView editing={editing} onBack={onBack} />
    </FormLoader>
  );
};

declare type InvestmentDetailsEditViewProps = EditViewProps;

const InvestmentDetailsEditView: React.FC<InvestmentDetailsEditViewProps> = ({
  editing,
  onBack,
}) => {
  const { t } = useTranslation();
  const { account, institutionalAccount, selectedInvestment, investments } =
    Hooks.useInvestmentContext();
  const { params } =
    Hooks.useRetirementWizard<Data.InvestmentAccounts.AccountContributionsFormData>();
  const { basic, contributions } = Hooks.useRetirementWizardData();
  const { setLoading } = useLoading();
  const [selectedSecurity, setSelectedSecurity] = useState<Api.SecurityRest | undefined>(undefined);
  const commands = Hooks.useCommands();
  const planTypes =
    basic?.owner === 'spouse'
      ? Hooks.useSpouseAvailablePlanTypes()
      : Hooks.useSelfAvailablePlanTypes();
  const planEligibility = planTypes.find((plan) => plan.planType === params?.type);
  const showBalanceType = showBalanceTypeToggle(params?.type ?? Domain.PlanTypeEnum['#457']);
  const methods = useForm<InvestmentData>({
    defaultValues: selectedInvestment,
    resolver: yupResolver(
      investmentSchema(account?.accountType, showBalanceType, investments, editing)
    ),
  });
  const planData = account?.account as Domain.PlanData;
  const watchedTaxType = methods.watch('taxType');
  const watchedTicker = methods.watch('ticker');

  const formatInputs = (values: any) => {
    const valueType = parseInt(values.valueType);
    const balanceType = parseInt(values.balanceType);
    const taxType = parseInt(values.taxType);
    const balance = cleanWholeNumberStr(values.balance);
    const nrShares = cleanWholeNumberStr(values.nrShares);
    const rest = Domain.mapInvestmentDataToInvestmentRest(
      {
        ...values,
        valueType,
        balanceType,
        taxType,
        balance,
        nrShares,
      },
      selectedSecurity
    );
    const totalValue = rest.totalValue;
    return { ...rest, totalValue };
  };

  const onSubmit = async (values: InvestmentDetailsFormData) => {
    setLoading(true);

    try {
      if (account) {
        const cleanedValues = formatInputs(values);
        await commands.execute(Data.InvestmentAccounts.Commands.SaveInvestment, {
          investmentAccountId: account.id ?? 0,
          investmentAccountType: account.accountType,
          institutionalAccount,
          ...cleanedValues,
        });

        if (onBack) {
          onBack();
          return;
        }
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (watchedTicker) {
      methods.trigger('selected');
      methods.trigger('taxType');
    }
  }, [watchedTaxType, watchedTicker, methods.trigger]);

  return (
    <>
      <FormContent formProviderProps={methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Box>
            {/* Should be about 30px */}
            <HelpTitle
              text={editing ? t('EditAnInvestment') : t('AddAnInvestment')}
              helpContext={AddInvestmentHelpContent(
                editing ? t('EditAnInvestment') : t('AddAnInvestment'),
                planData.planType
              )}
            />
            <Grid container justifyContent='center' mt={2}>
              <Grid item sm={10}>
                <InvestmentDetailsForm
                  investment={selectedInvestment}
                  account={account}
                  institutionalAccount={institutionalAccount}
                  contributions={contributions}
                  planEligibility={planEligibility}
                  params={params}
                  onSubmit={onSubmit}
                  selectSecurity={(security?: Api.SecurityRest) => setSelectedSecurity(security)}
                />
              </Grid>
            </Grid>
          </Box>
          <WizardFooter
            color={'primary'}
            disableBack={false}
            backLabel='Cancel'
            onBack={onBack}
            {...getWizardFooterProps('Save', editing)}
          />
        </form>
      </FormContent>
    </>
  );
};

export const AddInvestmentHelpContent = (
  title: string,
  planType: Domain.PlanTypeEnum | undefined
) => {
  const isIraOrBrokerage =
    Domain.isIRA(planType) || planType === Domain.PlanTypeEnum['Brokerage Account'];

  return (
    <HelpPopover title={title}>
      <HelpPopoverText>{t('WeNeedInformationAboutHowThisIsInvested')}</HelpPopoverText>
      {!isIraOrBrokerage && (
        <>
          <Spacer height='xxs' />
          <HelpPopoverText>
            <strong>{t('WeCanProvideMorePersonalizedInvestmentAdvice')}</strong>
          </HelpPopoverText>
        </>
      )}
      <Spacer height='xxs' />
      <HelpPopoverText>{t('AlsoNote')}</HelpPopoverText>
      <BulletedList style={Margins.mt_xxs}>
        <>{t('SomePubliclyTradedInvestments')}</>
        <>{t('UsingTickersSpeedsUpDataEntry')}</>
      </BulletedList>
    </HelpPopover>
  );
};

export default InvestmentDetailsEditViewWrapper;
