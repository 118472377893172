import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Card, Grid, IconButton, Link, Typography } from '@mui/material';
import { Domain } from '@3nickels/data-modules';
import {
  convertToTitleCase,
  formatDateMMDDYYYY,
  formatDecimalDollars,
} from '../../../helpers/utilityFunctions';
import { Svgs } from '../../../assets/svg';
import UnsubscribeModal from '../manage-subscription/UnsubscribeModal';
import { Colors } from '../../../themes';
import { Row } from '../../../components';
import ActionSheet from '../../../components/ActionSheet';
import { useDeviceType } from '../../../hooks/useDeviceType';
import { getName, getSubscriptionStatus } from '../manage-subscription/functions';

interface SubscriptionSectionProps {
  subscriptionInfoData: Domain.SubscriptionInfo[] | undefined;
  threeNickelsProducts: Domain.PricebookBundle[] | undefined;
}

const SubscriptionSection: React.FC<SubscriptionSectionProps> = ({
  subscriptionInfoData,
  threeNickelsProducts,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isMobile, isTablet } = useDeviceType();
  const mobile = isMobile || isTablet;

  if (!subscriptionInfoData || subscriptionInfoData.length === 0)
    return (
      <Card className='card-row' sx={{ padding: '10px', marginTop: '20px' }}>
        <Grid container display='flex' alignItems='center'>
          <Grid item xs={2.5} />
          <Grid
            item
            xs={7}
            style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
            <Typography variant='p16' color='secondary'>
              {t('YouDontHaveAnySubscriptions')}
            </Typography>
          </Grid>
          <Grid item xs={0.5} />
          <Grid item xs={2} paddingRight={1} style={{ textAlign: 'end' }}>
            <Link variant='p12' onClick={() => navigate('/settings/manage-subscription')} noWrap>
              {t('ViewCurrentPlan')}
            </Link>
          </Grid>
        </Grid>
      </Card>
    );

  return (
    <>
      {!mobile && (
        <Grid container mt={3} className='subscription-table-row'>
          <Grid item className='button-section' />
          <Grid item className='description-section'>
            <Typography variant='p14' color='primary'>
              {t('Description')}
            </Typography>
          </Grid>
          <Grid item className='price-section'>
            <Typography variant='p14' color='primary'>
              {t('Price')}
            </Typography>
          </Grid>
          <Grid item className='duration-section'>
            <Typography variant='p14' color='primary'>
              {t('Duration')}
            </Typography>
          </Grid>
          <Grid item className='origin-section'>
            <Typography variant='p14' color='primary'>
              {t('Origin')}
            </Typography>
          </Grid>
          <Grid item className='date-section'>
            <Typography variant='p14' color='primary'>
              {t('Date')}
            </Typography>
          </Grid>
        </Grid>
      )}
      {subscriptionInfoData.map((subscription, index) => {
        return (
          <SubscriptionCard
            key={index}
            subscription={subscription}
            threeNickelsProducts={threeNickelsProducts}
          />
        );
      })}
    </>
  );
};

interface SubscriptionCardProps {
  subscription: Domain.SubscriptionInfo;
  threeNickelsProducts: Domain.PricebookBundle[] | undefined;
}

const SubscriptionCard: React.FC<SubscriptionCardProps> = ({
  subscription,
  threeNickelsProducts,
}) => {
  const { t } = useTranslation();
  const { isMobile, isTablet } = useDeviceType();
  const mobile = isMobile || isTablet;
  const [modalOpen, setModalOpen] = useState(false);
  const [actionSheetOpen, setActionSheetOpen] = useState<boolean>(false);
  const name = getName(subscription, threeNickelsProducts);
  const status = getSubscriptionStatus(subscription);
  const orgPays = subscription.paidBy === Domain.PaidByEnum.ORG;
  const deletable = status.isActive && !orgPays;
  const freeTrial = subscription.origin === Domain.PaymentOriginEnum.FREE_TRIAL;

  return mobile ? (
    <>
      <UnsubscribeModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        subscription={subscription}
        threeNickelsProducts={threeNickelsProducts}
      />
      <Grid
        mb={1.5}
        sx={{
          borderRadius: '7px',
          padding: '20px',
          border: `solid 1px ${Colors.primaryLightest}`,
          backgroundColor: Colors.tertiaryBase,
        }}>
        <Row style={{ justifyContent: 'space-between' }}>
          <Typography mb={1} variant='p16Bold' color='secondary'>
            {name}
          </Typography>
          {deletable && <Svgs.ActionSmallEllipsis onClick={() => setActionSheetOpen(true)} />}
        </Row>
        <Typography mb={1} variant='p16Bold' color={status.isActive ? 'primary' : 'error.main'}>
          {status.isActive ? t('Active') : status.cancelled ? t('Cancelled') : t('Expired')}
        </Typography>
        <Typography mb={1} variant='p16' color='secondary'>
          {orgPays ? '$0*' : `${formatDecimalDollars(subscription.price / 100)}`}/
          {t(subscription.interval?.toLowerCase())}
        </Typography>

        <Typography mb={1} variant='p16' color='secondary'>
          {subscription.origin === Domain.PaymentOriginEnum.ANDROID
            ? 'Google'
            : convertToTitleCase(subscription.origin ?? '')}
        </Typography>
        <Typography variant='p16' color='secondary'>
          {status.isActive && !freeTrial
            ? t('Renews')
            : (status.isActive && freeTrial) || status.cancelled
            ? t('Expires')
            : t('Expired')}
          &nbsp;
          {formatDateMMDDYYYY(subscription.expirationDate)}
        </Typography>
      </Grid>
      {orgPays && (
        <Typography variant='p12' color='secondary'>
          *{t('YourOrganizationIsPayingForYourSubscription')}
        </Typography>
      )}
      <ActionSheet open={actionSheetOpen} setOpen={setActionSheetOpen}>
        <Grid sx={{ padding: '20px' }}>
          <Typography
            onClick={() => {
              setModalOpen(true);
              setActionSheetOpen(false);
            }}
            mb={3}
            color='primary'
            variant='p14SemiBold'>
            {t('Unsubscribe')}
          </Typography>
          <Typography
            onClick={() => {
              setActionSheetOpen(false);
            }}
            color='primary'
            variant='p14SemiBold'>
            {t('Cancel')}
          </Typography>
        </Grid>
      </ActionSheet>
    </>
  ) : (
    <>
      <UnsubscribeModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        subscription={subscription}
        threeNickelsProducts={threeNickelsProducts}
      />
      <Card className='card-row' sx={{ paddingY: '10px', marginTop: '10px', marginBottom: '10px' }}>
        <Grid container className='subscription-table-row'>
          <Grid item className='button-section'>
            {deletable && (
              <IconButton
                className='show-on-hover'
                sx={{ opacity: 0, padding: 0 }}
                onClick={() => {
                  setModalOpen(true);
                }}
                disableRipple>
                <Svgs.IpmIconDelete />
              </IconButton>
            )}
          </Grid>
          <Grid item className='description-section'>
            <Typography variant='p16Bold' color='secondary'>
              {name}
              <Typography
                ml={3}
                variant='p16Bold'
                color={status.isActive ? 'primary' : 'error.main'}
                sx={{ display: 'inline' }}>
                {status.isActive ? t('Active') : status.cancelled ? t('Cancelled') : t('Expired')}
              </Typography>
            </Typography>
          </Grid>
          <Grid item className='price-section'>
            <Typography variant='p16Bold' color='secondary'>
              {orgPays ? '$0*' : `${formatDecimalDollars(subscription.price / 100)}`}
            </Typography>
          </Grid>
          <Grid item className='duration-section'>
            <Typography variant='p16' color='secondary'>
              {t('StartPeriodToEndPeriod', {
                startPeriod: t(convertToTitleCase(subscription.interval ?? '')),
                endPeriod: t(subscription.interval?.toLowerCase()),
              })}
            </Typography>
          </Grid>
          <Grid item className='origin-section'>
            <Typography variant='p16' color='secondary'>
              {subscription.origin === Domain.PaymentOriginEnum.ANDROID
                ? 'Google'
                : convertToTitleCase(subscription.origin ?? '')}
            </Typography>
          </Grid>
          <Grid item className='date-section'>
            <Typography variant='p16' color='secondary'>
              {status.isActive && !freeTrial
                ? t('Renews')
                : (status.isActive && freeTrial) || status.cancelled
                ? t('Expires')
                : t('Expired')}
              &nbsp;
              {formatDateMMDDYYYY(subscription.expirationDate)}
            </Typography>
          </Grid>
        </Grid>
      </Card>
      {orgPays && (
        <Typography variant='p12' color='secondary'>
          *{t('YourOrganizationIsPayingForYourSubscription')}
        </Typography>
      )}
    </>
  );
};

export default SubscriptionSection;
