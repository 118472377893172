/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Data, Domain, Hooks, formatWholeDollars } from '@3nickels/data-modules';
import {
  BudgetSectionProps,
  TransportFormData,
  calcHousingPayment,
  getAdjustedRealEstateDebtPayment,
} from '../../Types';
import { Divider, Grid, Typography } from '@mui/material';
import { CollapsiblePanel } from '../../../../components/CollapsiblePanel';
import { Svgs } from '../../../../assets/svg';
import FormContent from '../../../../components/form/FormContent';
import EmbeddedList from '../../../../components/EmbeddedList';
import { useNavigate } from 'react-router';
import { Colors } from '../../../../themes';
import { LeaveBudgetDetailsModal } from '../../BudgetDetailsLayout';
import { SpendingProps } from '../Types';
import { t } from 'i18next';
import { CoversExpensesModal } from './CoversExpensesModal';
import { useMutation } from '@aesop-fables/scrinium';
import { useMessage } from '../../../../hooks/useMessage';
import RemoveAccountModal from '../../../account-details/RemoveAccountModal';
import { mapDebtEnumToTypePath, mapDebtTypeToType } from '../../../account-details/debt/DebtUtils';

export declare type DebtFormProps = BudgetSectionProps;

export const DebtForm: React.FC<DebtFormProps> = ({ mode }) => {
  const { showMessage } = useMessage();
  const navigate = useNavigate();
  const { wizard: debtWizard } = Hooks.useDebtWizard();
  const { wizard: realEstateWizard } = Hooks.useRealEstateWizard();
  const deleteDebt = useMutation(new Data.Debt.Mutations.DeleteDebt());
  const deleteRealEstate = useMutation(new Data.RealEstate.Mutations.DeleteHousingEntity());
  const debts = Hooks.useCombinedDebts();
  const realEstateDebts = Hooks.useCombinedRealEstateData().filter(
    (realEstate) => calcHousingPayment(realEstate) > 0
  );
  const creditCards = useMemo(
    () => debts.filter((x) => x.debtType === Domain.DebtTypeEnum.CREDIT_CARD),
    [debts]
  );
  const medicalDebts = useMemo(
    () => debts.filter((x) => x.debtType === Domain.DebtTypeEnum.MEDICAL_DEBT),
    [debts]
  );
  const studentLoans = useMemo(
    () => debts.filter((x) => x.debtType === Domain.DebtTypeEnum.STUDENT_LOAN),
    [debts]
  );
  const personalLoans = useMemo(
    () => debts.filter((x) => x.debtType === Domain.DebtTypeEnum.PERSONAL_LOAN),
    [debts]
  );
  const otherDebts = useMemo(
    () => debts.filter((x) => x.debtType === Domain.DebtTypeEnum.OTHER),
    [debts]
  );

  const [open, setOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState<
    Domain.RealEstateData | Domain.CreditCardData | Domain.IDebt
  >();
  const [selectedCard, setSelectedCard] = useState<Domain.CreditCardData | undefined>();

  const leaveBudgetDetails = () => {
    navigate('/account-details');
  };

  const handleEditDebt = (x: Domain.IDebt) => {
    debtWizard.start({ id: x.id });
    navigate(`/account-details/debt/summary/${mapDebtEnumToTypePath(x.debtType)}/${x.id}`);
  };

  const handleDeleteDebt = (account: Domain.IDebt) => {
    deleteDebt.action(account);
    showMessage(t('DebtRemoved').toString());
  };

  const handleEditRealEstate = async (x: Domain.RealEstateData) => {
    await realEstateWizard.start({ id: x.id ?? 0, type: Domain.RealEstateTypeEnum.INVESTMENT });
    navigate(`/account-details/investment-real-estate/summary/${x.id}`);
  };

  const handleDeleteRealEstate = (account: Domain.RealEstateData) => {
    deleteRealEstate.action(account.id ?? 0);
    showMessage(t('RealEstateRemoved').toString());
  };

  // TODO -- CardDisplay should be the refactored version: CollapsibleCard
  // (we just need to create it)
  return (
    <Grid className='embedded-form'>
      <LeaveBudgetDetailsModal open={open} setOpen={setOpen} handleSave={leaveBudgetDetails} />
      <CoversExpensesModal
        open={typeof selectedCard !== 'undefined'}
        setOpen={() => setSelectedCard(undefined)}
        creditCard={selectedCard}
      />
      <RemoveAccountModal
        name={selectedAccount?.name}
        type={
          typeof (selectedAccount as Domain.RealEstateData)?.housingType !== 'undefined'
            ? 'InvestmentRealEstate'
            : mapDebtTypeToType((selectedAccount as Domain.IDebt)?.debtType)
        }
        variant={
          typeof (selectedAccount as Domain.RealEstateData)?.housingType !== 'undefined'
            ? 'Investment'
            : 'Debt'
        }
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        handleSave={() => {
          if (selectedAccount) {
            if (typeof (selectedAccount as Domain.RealEstateData)?.housingType !== 'undefined') {
              handleDeleteRealEstate(selectedAccount as Domain.RealEstateData);
            } else {
              handleDeleteDebt(selectedAccount as Domain.IDebt);
            }
          }
        }}
      />
      <EmbeddedList<Domain.CreditCardData>
        items={creditCards}
        keyFn={(x) => x.id?.toString() ?? ''}
        header={t('CreditCards')}
        emptyHeader={t('NoCreditCardsAdded')}
        titleFn={(x) => (
          <Grid container flexDirection='column' rowGap='5px'>
            {x.name ?? ''}
            <Typography
              className='clickable'
              variant='p12'
              color='primary'
              fontWeight='normal'
              onClick={(e) => {
                e.stopPropagation();
                setSelectedCard(x);
              }}>
              {x.coversExpenses ? t('CoversBudgetExpenses') : t('NotCoverBudgetExpenses')}
            </Typography>
          </Grid>
        )}
        summaryFn={(x) =>
          formatWholeDollars(Data.Budget.adjustMonthlyAmount(mode, x.monthlyPayment))
        }
        headingProps={{ endAdornment: () => <Svgs.ActionSmallEdit /> }}
        onClick={() => setOpen(true)}
        onClickElement={(x) => handleEditDebt(x)}
        onDelete={(x) => {
          setSelectedAccount(x);
          setModalOpen(true);
        }}
      />
      <Divider sx={{ borderColor: Colors.primaryLight }} />
      <EmbeddedList<Domain.IDebt>
        items={medicalDebts}
        keyFn={(x) => x.id?.toString() ?? ''}
        header={t('MedicalDebts')}
        emptyHeader={t('NoMedicalDebtsAdded')}
        titleFn={(x) => x.name ?? ''}
        summaryFn={(x) =>
          formatWholeDollars(Data.Budget.adjustMonthlyAmount(mode, x.monthlyPayment))
        }
        headingProps={{ endAdornment: () => <Svgs.ActionSmallEdit /> }}
        onClick={() => setOpen(true)}
        onClickElement={(x) => handleEditDebt(x)}
        onDelete={(x) => {
          setSelectedAccount(x);
          setModalOpen(true);
        }}
      />
      <Divider sx={{ borderColor: Colors.primaryLight }} />
      <EmbeddedList<Domain.IDebt>
        items={studentLoans}
        keyFn={(x) => x.id?.toString() ?? ''}
        header={t('StudentLoans')}
        emptyHeader={t('NoStudentLoansAdded')}
        titleFn={(x) => x.name ?? ''}
        summaryFn={(x) =>
          formatWholeDollars(Data.Budget.adjustMonthlyAmount(mode, x.monthlyPayment))
        }
        headingProps={{ endAdornment: () => <Svgs.ActionSmallEdit /> }}
        onClick={() => setOpen(true)}
        onClickElement={(x) => handleEditDebt(x)}
        onDelete={(x) => {
          setSelectedAccount(x);
          setModalOpen(true);
        }}
      />
      <Divider sx={{ borderColor: Colors.primaryLight }} />
      <EmbeddedList<Domain.IDebt>
        items={personalLoans}
        keyFn={(x) => x.id?.toString() ?? ''}
        header={t('PersonalLoans')}
        emptyHeader={t('NoPersonalLoansAdded')}
        titleFn={(x) => x.name ?? ''}
        summaryFn={(x) =>
          formatWholeDollars(Data.Budget.adjustMonthlyAmount(mode, x.monthlyPayment))
        }
        headingProps={{ endAdornment: () => <Svgs.ActionSmallEdit /> }}
        onClick={() => setOpen(true)}
        onClickElement={(x) => handleEditDebt(x)}
        onDelete={(x) => {
          setSelectedAccount(x);
          setModalOpen(true);
        }}
      />
      <Divider sx={{ borderColor: Colors.primaryLight }} />
      <EmbeddedList<Domain.RealEstateData>
        items={realEstateDebts}
        keyFn={(x) => x.id?.toString() ?? ''}
        header={t('RealEstateDebts')}
        emptyHeader={t('NoRealEstateDebtsAdded')}
        titleFn={(x) => x.name ?? ''}
        summaryFn={(x) => formatWholeDollars(getAdjustedRealEstateDebtPayment(x, mode))}
        headingProps={{ endAdornment: () => <Svgs.ActionSmallEdit /> }}
        onClick={() => setOpen(true)}
        onClickElement={(x) => handleEditRealEstate(x)}
        onDelete={(x) => {
          setSelectedAccount(x);
          setModalOpen(true);
        }}
      />
      <Divider sx={{ borderColor: Colors.primaryLight }} />
      <EmbeddedList<Domain.IDebt>
        items={otherDebts}
        keyFn={(x) => x.id?.toString() ?? ''}
        header={t('OtherDebts')}
        emptyHeader={t('NoOtherDebtsAdded')}
        titleFn={(x) => x.name ?? ''}
        summaryFn={(x) =>
          formatWholeDollars(Data.Budget.adjustMonthlyAmount(mode, x.monthlyPayment))
        }
        headingProps={{ endAdornment: () => <Svgs.ActionSmallEdit /> }}
        onClick={() => setOpen(true)}
        onClickElement={(x) => handleEditDebt(x)}
        onDelete={(x) => {
          setSelectedAccount(x);
          setModalOpen(true);
        }}
      />
    </Grid>
  );
};

export const DebtSpendingSection: React.FC<BudgetSectionProps & SpendingProps> = ({
  mode,
  spendingSummary,
}) => {
  const methods = useForm<TransportFormData>();

  const summary = useMemo(
    () => Data.Budget.adjustMonthlyAmount(mode, spendingSummary.debts),
    [spendingSummary, mode]
  );

  return (
    <CollapsiblePanel
      variant='compressed'
      header={t('Debt')}
      expandIconPosition='relative'
      icon={<Svgs.DisplaySmallCustomDebt />}
      summary={formatWholeDollars(summary)}>
      <Grid className='embedded-content'>
        <FormContent formProviderProps={methods}>
          <DebtForm mode={mode} />
        </FormContent>
      </Grid>
    </CollapsiblePanel>
  );
};
