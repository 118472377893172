import { Card, Grid, Link, Typography } from '@mui/material';
import { PageContentWithBackground } from '../layout/PageContentWithBackground';
import { Column, Row, Spacer } from '../../components';
import { mobileMargin } from '../../App';
import { useDeviceType } from '../../hooks/useDeviceType';
import { t } from 'i18next';
import { Button } from '../../components/buttons/Button';
import { Colors, Margins, Spacing } from '../../themes';
import BulletedList from '../../components/BulletedList';
import { Svgs } from '../../assets/svg';
import { Api, Domain, Hooks } from '@3nickels/data-modules';
import { useService } from '@aesop-fables/containr-react';
import { Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL } from '../../configuration/Environment';
import Cookies from 'js-cookie';
import { useEffect, useMemo, useState } from 'react';
import React from 'react';
import { advisorMan } from '../../assets/png';

const MigrateAcctView: React.FC = () => {
  const navigate = useNavigate();
  const organizationData = Hooks.useOrganizationData();
  const organizationApi = useService<Api.OrganizationApi>(Api.ApiKeys.Organization);
  const { availableProductsData: pricebookData } = Hooks.useAvailableProducts();
  const { isMobile, isTablet } = useDeviceType();
  const mobile = isMobile || isTablet;
  const [memberPaidOptions, setMemberPaidOptions] = useState<Domain.PricebookBundle[]>([]);
  const [orgPaidOptions, setOrgPaidOptions] = useState<Domain.PricebookBundle[]>([]);

  const totalPricebookOptions = useMemo(() => {
    return memberPaidOptions.length + orgPaidOptions.length;
  }, [memberPaidOptions, orgPaidOptions]);
  const nextButtonText = useMemo(() => {
    return totalPricebookOptions === 1 ? 'NextReviewPackage' : 'NextChoosePackage';
  }, [totalPricebookOptions]);

  const orgName = organizationData?.name ?? '';
  const subjectLine = `Moving My 3Nickels Account to ${orgName}`;
  const emailHref = `mailto:help@3nickels.com?subject=${encodeURI(subjectLine)}`;

  useEffect(() => {
    const getPricebookOptions = async () => {
      if (pricebookData) {
        const member = pricebookData.filter(
          (bundle) =>
            bundle.pricebook.pricebookName.includes('3Nickels') &&
            bundle.pricebook.paidBy === 'USER'
        );
        const org = pricebookData.filter(
          (bundle) =>
            bundle.pricebook.pricebookName.includes('3Nickels') && bundle.pricebook.paidBy === 'ORG'
        );
        setMemberPaidOptions(member);
        setOrgPaidOptions(org);
      }
    };

    getPricebookOptions();
  }, [pricebookData]);

  const onNext = () => {
    navigate('/migrate/choose-package');
  };
  const formRef = React.useRef<HTMLFormElement>(null);

  return (
    <PageContentWithBackground useContainer={false}>
      <Column
        style={{
          margin: mobile ? mobileMargin : '25px 100px',
        }}>
        {organizationData?.logoUri ? (
          <Row style={{ justifyContent: mobile ? 'center' : 'flex-start', height: '100px' }}>
            <img
              src={organizationApi.getLogo()}
              alt={organizationData?.name}
              style={{
                height: '60%',
                marginBottom: '60px',
                alignSelf: mobile ? 'center' : undefined,
              }}
            />
          </Row>
        ) : (
          <Spacer height={mobile ? 'sm' : 'md'} />
        )}

        <Typography
          variant={mobile ? 'p22Bold' : 'p30Bold'}
          color={mobile ? 'secondary' : 'primary'}>
          {t('YoureMovingAcctTo', { orgName: orgName })}
        </Typography>
        <Typography variant='p16' color='secondary' mt={'10px'} mb={'30px'}>
          {t('YourAcctWillInclude', { orgName: orgName })}
        </Typography>

        <Row
          style={{
            width: mobile ? '100%' : '50%',
            alignItems: 'center',
            justifyContent: mobile ? 'center' : undefined,
          }}>
          <img src={advisorMan} alt='adivsor art' width={'90px'} height={'86px'} />
          <Svgs.Arrow style={{ margin: '0px 30px' }} />
          <Svgs.ArtBusiness />
        </Row>

        <Card
          sx={{
            border: `solid 1px ${Colors.primaryLightest}`,
            backgroundColor: Colors.tertiaryBase,
            padding: mobile ? '10px' : '20px',
            marginTop: '30px',
            marginBottom: mobile ? '20px' : '50px',
            boxShadow: 'none',
          }}>
          <Typography variant='p16' color='secondary'>
            {t(`BeforeContinuing`)}
          </Typography>

          <Typography
            display='inline-block'
            variant='p16'
            color='secondary'
            mt={'20px'}
            mb={'10px'}>
            <Trans
              i18nKey='IfYouHaveExistingSubscription'
              components={{
                Styled: <Typography display='inline' variant='p16Bold' />,
              }}
            />
          </Typography>

          <BulletedList
            style={Margins.mt_xs}
            children={[
              <Trans
                i18nKey='IfYourPaymentWillBeLess'
                components={{
                  Styled: <Typography display='inline' fontStyle='italic' />,
                }}
              />,
              <Trans
                i18nKey='IfYourOrgWillPay'
                components={{
                  Styled: <Typography display='inline' fontStyle='italic' />,
                }}
              />,
            ]}
          />

          <Typography display='inline-block' variant='p16' color='secondary' mt={'20px'}>
            <Trans
              i18nKey='IfYoureMovingFromFree'
              components={{
                Styled: <Typography display='inline' variant='p16Bold' />,
              }}
            />
          </Typography>

          <Typography display='inline-block' variant='p16' color='secondary' mt={'20px'}>
            {mobile ? (
              <Trans
                i18nKey='QuestionsOrConcernsMobile'
                components={{
                  Email: <Link display='inline' href={emailHref} />,
                  Phone: <Link display='inline' href={'tel:+18007747459'} />,
                }}
              />
            ) : (
              <Trans
                i18nKey='QuestionsOrConcerns'
                components={{
                  Email: <Link display='inline' href={emailHref} />,
                }}
              />
            )}
          </Typography>
        </Card>
        {mobile ? (
          <Grid
            className='wizard-footer'
            item
            display='flex'
            direction={'column'}
            justifyContent='space-between'
            sm={12}>
            <Button
              label={nextButtonText}
              type={'submit'}
              variant={'contained'}
              color={'secondary'}
              onClick={onNext}
            />
            <form method='post' action={`${API_BASE_URL}/logout`}>
              <Button
                onClick={() => formRef.current?.submit()}
                sx={{
                  width: '100%',
                  marginTop: '20px',
                }}
                label={'CancelAndLogOut'}
                variant={'outlined'}
                color={'secondary'}
              />
              <input type='hidden' name='_csrf' value={Cookies.get('XSRF-TOKEN')} readOnly />
            </form>
          </Grid>
        ) : (
          <Grid
            className='wizard-footer'
            item
            display='flex'
            direction={'row'}
            justifyContent='space-between'
            sm={12}
            mt={Spacing.xxs}>
            <form ref={formRef} method='post' action={`${API_BASE_URL}/logout`}>
              <Button
                onClick={() => formRef.current?.submit()}
                label={'CancelAndLogOut'}
                variant={'outlined'}
                color={'secondary'}
              />
              <input type='hidden' name='_csrf' value={Cookies.get('XSRF-TOKEN')} readOnly />
            </form>

            <Button
              label={nextButtonText}
              type={'submit'}
              variant={'contained'}
              color={'secondary'}
              onClick={onNext}
            />
          </Grid>
        )}
      </Column>
    </PageContentWithBackground>
  );
};

export default MigrateAcctView;
