/* eslint-disable @typescript-eslint/no-explicit-any */
import { Typography, Button, IconButton } from '@mui/material';
import { Column, RightDrawer, Row, Spacer } from '../components';
import { logo3NSmall } from '../assets/png';
import { Svgs } from '../assets/svg';
import { useDeviceType } from '../hooks/useDeviceType';
import { PackageCard } from './LearnMoreAdviceAndThreeNickelsUDrawer';
import { Domain } from '@3nickels/data-modules';
import { t } from 'i18next';
import { Trans } from 'react-i18next';

export interface LearnMoreDrawerProps {
  drawerOpen: boolean;
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  pricebookBundle: Domain.PricebookBundle;
  pricebookMetadata: any;
}

const LearnMoreAdviceDrawer: React.FC<LearnMoreDrawerProps> = ({
  drawerOpen,
  setDrawerOpen,
  pricebookBundle,
  pricebookMetadata,
}) => {
  const { isMobile } = useDeviceType();

  return (
    <RightDrawer open={drawerOpen} setOpen={setDrawerOpen}>
      <Row>
        <Column style={{ width: isMobile ? '100%' : '90%' }}>
          {!isMobile && (
            <Typography color='secondary' variant='p14'>
              {t('3NickelsPackages')}
            </Typography>
          )}
          <Spacer height='xxs' />

          <Typography color='primary' variant={isMobile ? 'p24Bold' : 'p30Bold'}>
            {pricebookBundle.pricebook.pricebookName}
          </Typography>
          <Spacer height='xxs' />
        </Column>
        {isMobile ? (
          <IconButton onClick={() => setDrawerOpen(false)}>
            <Svgs.IconNavAlertDefault />
          </IconButton>
        ) : (
          <img src={logo3NSmall} alt='logo' style={{ height: '40px' }} />
        )}
      </Row>
      <PackageCard pricebookBundle={pricebookBundle} pricebookMetadata={pricebookMetadata} />
      {isMobile && (
        <>
          <Spacer height='xs' />
          <Column style={{ alignItems: 'center' }}>
            <Svgs.ArtPieChart />
          </Column>
        </>
      )}
      <Spacer height='xs' />
      <Typography color='secondary' variant='p18Bold'>
        {t('Description')}
      </Typography>
      <Spacer height='xxs' />
      <Row>
        <Column style={{ width: '100%' }}>
          <Typography color='secondary' variant='p16'>
            {t('ExperienceTheFinancialAdvisorInYourPocket')}
          </Typography>
          <Spacer height='xxs' />
          <Typography color='secondary' variant='p16'>
            {t('ConnectAllYOurFinancesInOnePlace')}
          </Typography>
        </Column>
        {!isMobile && <Svgs.ArtPieChart style={{ marginLeft: '20px', marginRight: '20px' }} />}
      </Row>
      <Spacer height='xxs' />
      <Typography color='secondary' variant='p18Bold'>
        {t('AtAGlance...')}
      </Typography>
      <Column style={{ width: '85%' }}>
        <Spacer height='xs' />
        <Row style={{ alignItems: 'center' }}>
          <Svgs.BulletBook
            style={{ width: isMobile ? '70px' : '50px', height: 'auto', flexShrink: 0 }}
          />
          <Typography
            className='link-wrapper'
            style={{ marginLeft: '20px' }}
            variant='body1'
            color='secondary'>
            <Trans i18nKey='AccessibleEducationAtEveryLevel' />
          </Typography>
        </Row>

        <Spacer height='xs' />
        <Row style={{ alignItems: 'center' }}>
          <Svgs.BulletCalculator
            style={{ width: isMobile ? '70px' : '50px', height: 'auto', flexShrink: 0 }}
          />
          <Typography
            className='link-wrapper'
            style={{ marginLeft: '20px' }}
            variant='body1'
            color='secondary'>
            <Trans i18nKey='CustomizableToolsToHelpYou' />
          </Typography>
        </Row>

        <Spacer height='xs' />
        <Row style={{ alignItems: 'center' }}>
          <Svgs.BulletChecklist
            style={{ width: isMobile ? '70px' : '50px', height: 'auto', flexShrink: 0 }}
          />
          <Typography
            className='link-wrapper'
            style={{ marginLeft: '20px' }}
            variant='body1'
            color='secondary'>
            <Trans i18nKey='ActionableFinancialPlanningToReachYourGoals' />
          </Typography>
        </Row>

        <Spacer height='xs' />
        <Row style={{ alignItems: 'center' }}>
          <Svgs.BulletInvestment
            style={{ width: isMobile ? '70px' : '50px', height: 'auto', flexShrink: 0 }}
          />
          <Typography
            className='link-wrapper'
            style={{ marginLeft: '20px' }}
            variant='body1'
            color='secondary'>
            <Trans i18nKey='ObjectiveInvestmentAdvice' />
          </Typography>
        </Row>
      </Column>

      {!isMobile && (
        <>
          <Spacer height='xs' />
          <Row style={{ justifyContent: 'space-between' }}>
            <Button
              onClick={() => setDrawerOpen(false)}
              className='xs'
              variant='outlined'
              color='primary'>
              {t('Close')}
            </Button>
          </Row>
        </>
      )}
      <Spacer height='md' />
    </RightDrawer>
  );
};

export default LearnMoreAdviceDrawer;
