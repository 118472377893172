import { Backdrop } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { MainContent } from '../dashboard/style';
import { useDeviceType } from '../hooks/useDeviceType';
import { Loading, useLoading } from '../hooks/useLoading';
import { AccountOriginProvider } from '../hooks/useAccountOrigin';
import { useService, useServiceContainer } from '@aesop-fables/containr-react';
import { Authentication, Data, Domain, Hooks } from '@3nickels/data-modules';
import { useAuthenticatedFlag, useAuthenticationStatus, useNeedsOnboarding } from '../hooks';
import { RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import SignUpRoutes from './SignUpRoutes';
import { createAdviceRoutes } from './AdviceRoutes';
import { ContentViewProvider } from '../pages/layout/ContentViewContext';
import { createAdviceOnboardingRoutes } from './AdviceOnboardingRoutes';
import { createFreeRoutes } from './FreeRoutes';
import { useMessage } from '../hooks/useMessage';
import { createMobileRoutes } from './MobilesRoutes';
import SessionTimeoutModal from '../pages/SessionTimeoutModal';
import { t } from 'i18next';

function AppGateway() {
  const { loading } = useLoading();
  const authContext = useService<Authentication.IAuthenticationContext>(
    Authentication.AuthenticationServices.Context
  );
  const isAuthenticated = useAuthenticatedFlag();
  const needsOnboarding = useNeedsOnboarding();
  const productType = Hooks.useProductType();
  const freeTrialInfo = Hooks.useFreeTrialInfo();
  const principalUser = Hooks.usePrincipalUser();
  const { accountId } = principalUser ?? {};
  const { isReady, isSessionExpired } = useAuthenticationStatus();
  const { showMessage } = useMessage();
  const { isMobile, isTablet } = useDeviceType();
  const container = useServiceContainer();
  const logger = Hooks.useLogger();

  useEffect(() => {
    if (isReady && isSessionExpired) {
      authContext.setIsAuthenticated(false);
      showMessage(t('YouHaveBeenLoggedOutDueToInactivity') as string, 'error');
    }
  }, [isSessionExpired, isReady]);

  // Web crawlers don’t always execute JavaScript code when examining a webpage, so if you want to make sure that they read your meta tags, you need to set them up before the browser receives the page.
  const router = useMemo(() => {
    if (!isAuthenticated) {
      return createBrowserRouter(createRoutesFromElements(SignUpRoutes()));
    }

    // wait for user data to load before determining free/advice/mobile routes
    if (!accountId || typeof needsOnboarding === 'undefined') {
      return createBrowserRouter([
        {
          path: '*',
          element: <Loading />,
        },
      ]);
    }

    if (isMobile || isTablet) {
      return createBrowserRouter(createMobileRoutes(container));
    }

    if (freeTrialInfo?.showTrialExpiredScreen) {
      return createBrowserRouter(createFreeRoutes(container));
    }

    if (productType === Domain.ProductTypeEnum.advice) {
      return createBrowserRouter(
        needsOnboarding ? createAdviceOnboardingRoutes(container) : createAdviceRoutes(container)
      );
    }

    return createBrowserRouter(createFreeRoutes(container));
  }, [
    isAuthenticated,
    accountId,
    needsOnboarding,
    isMobile,
    isTablet,
    productType,
    freeTrialInfo?.showTrialExpiredScreen,
  ]);

  useEffect(() => {
    logger.debug(`AppGateway loading: ${loading}`);
  }, [loading]);

  return (
    <MainContent isMobile={isMobile || isTablet}>
      <Backdrop open={loading}>
        <Loading />
      </Backdrop>
      {isReady && (
        <ContentViewProvider>
          <Hooks.PlaidWizardProvider>
            <Data.Transactions.TransactionsSummaryProvider>
              <AccountOriginProvider>
                <Data.Charity.CharityProvider>
                  <RouterProvider router={router} />
                  {isAuthenticated && <SessionTimeoutModal />}
                </Data.Charity.CharityProvider>
              </AccountOriginProvider>
            </Data.Transactions.TransactionsSummaryProvider>
          </Hooks.PlaidWizardProvider>
        </ContentViewProvider>
      )}
    </MainContent>
  );
}

export default AppGateway;
