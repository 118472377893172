import { useTranslation } from 'react-i18next';
import { SummaryCardProps } from '../../Types';
import { Grid, Typography } from '@mui/material';
import { capitalizeFirstLetter, formatWholeDollars } from '../../../../helpers/utilityFunctions';
import CardDisplay from '../../../../components/panels/CardDisplay';
import { OtherAssetFormData } from './Types';

export interface OtherAssetCardProps extends SummaryCardProps {
  asset: OtherAssetFormData;
  includeSpouse?: boolean;
}

const OtherAssetCard: React.FC<OtherAssetCardProps> = ({ onEdit, asset, includeSpouse }) => {
  const { t } = useTranslation();

  return (
    <CardDisplay headerKey='AccountBasics' actionContent='Edit' actionClick={onEdit}>
      <Grid container spacing={2}>
        <Grid item xs>
          <Typography variant='h3' component='h3' color='primary'>
            {t('AccountType')}
          </Typography>
          <Typography color='secondary'>{t('OtherAssetAccountType')}</Typography>
        </Grid>
        {includeSpouse && (
          <Grid item xs>
            <Typography variant='h3' component='h3' color='primary'>
              {t('AccountOwner')}
            </Typography>
            <Typography color='secondary'>
              {t(capitalizeFirstLetter(asset?.owner ?? ''))}
            </Typography>
          </Grid>
        )}
        <Grid item xs>
          <Typography variant='h3' component='h3' color='primary'>
            {t('CurrentValueOfAsset')}
          </Typography>
          <Typography color='secondary'>{formatWholeDollars(asset.value ?? 0)}</Typography>
        </Grid>
        <Grid item xs />
        <Grid item xs />
        {!includeSpouse && <Grid item xs />}
      </Grid>
    </CardDisplay>
  );
};

export default OtherAssetCard;
