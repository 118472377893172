/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo, useState } from 'react';
import i18next, { t } from 'i18next';
import { Card, Grid, Link, styled, Typography } from '@mui/material';
import { Data, Domain } from '@3nickels/data-modules';
import { useService } from '@aesop-fables/containr-react';
import { PaymentApi } from '../../../api/apis/PaymentApi';
import { ApiKeys } from '../../../api/apis/ApiKeys';
import { formatDateMMDDYYYY, formatDecimalDollars } from '../../../helpers/utilityFunctions';
import { campingBanner_v1, university } from '../../../assets/png';
import { Button } from '../../../components/buttons/Button';
import { Colors } from '../../../themes';
import LearnMoreAdviceDrawer from '../../../sign-up/LearnMoreAdviceDrawer';
import LearnMoreAdviceAndThreeNickelsUDrawer from '../../../sign-up/LearnMoreAdviceAndThreeNickelsUDrawer';
import LearnMoreFreeDrawer from '../../../sign-up/LearnMoreFreeDrawer';
import UnsubscribeModal from './UnsubscribeModal';
import { useDeviceType } from '../../../hooks/useDeviceType';
import { getSubscriptionStatus } from './functions';

function getDrawerAndDescription(
  plan: Domain.PricebookBundle,
  pricebookMetadata: any,
  drawerOpen: boolean,
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>
) {
  const memberPays = plan.pricebook.paidBy === Domain.PaidByEnum.USER;
  const free = plan.prices[0].price === 0 && memberPays;
  const nickelsU = plan.pricebook.productType === Domain.PricebookProductTypeEnum.NICKELSU;

  let drawer: React.ReactNode | null = null;
  let description: string | null = null;

  if (free) {
    drawer = (
      <LearnMoreFreeDrawer
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        pricebookBundle={plan}
        pricebookMetadata={pricebookMetadata}
      />
    );
    description = t('ChecklistsAndCalculatorsToCoverTheBasics');
  } else if (nickelsU) {
    drawer = (
      <LearnMoreAdviceAndThreeNickelsUDrawer
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        pricebookBundle={plan}
        pricebookMetadata={pricebookMetadata}
      />
    );
    description = t('OneOnOneCoachingFinancialEducationAndAdvice');
  } else {
    drawer = (
      <LearnMoreAdviceDrawer
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        pricebookBundle={plan}
        pricebookMetadata={pricebookMetadata}
      />
    );
    description = t('ExpertAdviceOnYourWholeFinancialPicture');
  }

  return { drawer, description };
}

function getIntervalText(intervalType: Domain.PaymentIntervalEnum) {
  switch (intervalType) {
    case Domain.PaymentIntervalEnum.DAY:
      return t('day');
    case Domain.PaymentIntervalEnum.WEEK:
      return t('week');
    case Domain.PaymentIntervalEnum.MONTH:
      return t('month');
    case Domain.PaymentIntervalEnum.YEAR:
      return t('year');
    default:
      '';
  }
}

function getSubtextsAndActionButton(
  plan: Domain.PricebookBundle,
  paymentApi: PaymentApi,
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  subscriptionInfo: Domain.SubscriptionInfo | undefined,
  manageSubscription: boolean | undefined,
  inAdviceFreeTrial: boolean,
  freeTrialStatus: Data.Users.FreeTrialInfoDto | undefined,
  isCurrentPlan: boolean,
  orgDoesNotOfferFreePricebook: boolean | undefined,
  userNeverSubscribedToExclusivePricebook: boolean | undefined,
  mobile: boolean
) {
  const { language } = i18next;
  const orgPays = plan.pricebook.paidBy === Domain.PaidByEnum.ORG;
  const memberPays = plan.pricebook.paidBy === Domain.PaidByEnum.USER;
  const free = plan.prices[0].price === 0 && memberPays;
  const promotion = plan.prices.find((price) => price.introductionPeriodInDays > 1);
  const nickelsU = plan.pricebook.productType === Domain.PricebookProductTypeEnum.NICKELSU;
  const inNickelsUPromo = subscriptionInfo?.status === 'trialing' && nickelsU;
  const resubscribe = subscriptionInfo?.status === 'cancelled' && isCurrentPlan;
  const unsubscribe =
    orgDoesNotOfferFreePricebook &&
    (subscriptionInfo?.status === 'active' || subscriptionInfo?.status === 'trialing') &&
    isCurrentPlan;
  const downgrade = free && !isCurrentPlan;
  const freeTrialDaysLeft = freeTrialStatus?.expirationDate
    ? Math.ceil(
        (new Date(freeTrialStatus.expirationDate).getTime() - Date.now()) / (1000 * 60 * 60 * 24)
      )
    : 0;

  let paymentSubtext: string | null = null;
  let descriptionSubtext: string | null = null;
  let descriptionSubtextColor = 'secondary';
  let actionButton: React.ReactNode | null = null;

  if (manageSubscription) {
    if (inNickelsUPromo) {
      paymentSubtext = t('afterPromoPeriod');
      descriptionSubtext = t('NextPaymentOfPriceOnDate', {
        price: formatDecimalDollars(subscriptionInfo?.price / 100, language),
        date: formatDateMMDDYYYY(subscriptionInfo?.expirationDate),
      });
    } else if (inAdviceFreeTrial) {
      paymentSubtext = t('afterFreeTrial');
      descriptionSubtext =
        freeTrialDaysLeft === 1
          ? t('1DayLeftOfFreeTrial')
          : t('XDaysLeftOfFreeTrial', {
              x: freeTrialDaysLeft,
            });
    } else if (subscriptionInfo?.status === 'active' && !orgPays) {
      descriptionSubtext = t('NextPaymentOfPriceOnDate', {
        price: formatDecimalDollars(subscriptionInfo?.price / 100, language),
        date: formatDateMMDDYYYY(subscriptionInfo?.expirationDate),
      });
    } else if (subscriptionInfo) {
      const status = getSubscriptionStatus(subscriptionInfo);
      if (status.cancelled || status.expired) {
        const text = status.expired ? t('Expired') : t('Expires');
        descriptionSubtext = `${text} ${formatDateMMDDYYYY(subscriptionInfo?.expirationDate)}`;
        descriptionSubtextColor = 'error.main';
      }
    } else if (userNeverSubscribedToExclusivePricebook) {
      descriptionSubtext = t('NotCurrentlySubscribed');
      descriptionSubtextColor = 'error.main';
    }
  } else {
    if (promotion) {
      paymentSubtext = t('oneTimePayment');
    }
    if (unsubscribe || downgrade) {
      actionButton = (
        <Button
          fullWidth={mobile}
          style={{ width: mobile ? undefined : '70%', marginTop: '10px' }}
          color='primary'
          variant='outlined'
          label={unsubscribe ? (t('Unsubscribe') as string) : (t('Downgrade') as string)}
          onClick={() => setModalOpen(true)}></Button>
      );
    } else if (resubscribe || !isCurrentPlan) {
      actionButton = (
        <Button
          style={{
            width: mobile ? undefined : '70%',
            marginTop: '10px',
          }}
          color='primary'
          variant='contained'
          fullWidth={mobile}
          label={resubscribe ? (t('Resubscribe') as string) : (t('SelectPlan') as string)}
          onClick={async () => {
            const stripeUrl = await paymentApi.createSession(plan.pricebook.id);
            window.location.replace(stripeUrl.data);
          }}></Button>
      );
    }
  }

  if (orgPays) {
    descriptionSubtext = t('YourOrganizationIsPayingForYourSubscription');
    descriptionSubtextColor = 'secondary';
    paymentSubtext = null;
    actionButton = null;
  }

  return { paymentSubtext, descriptionSubtext, descriptionSubtextColor, actionButton };
}

const Banner = styled('div')({
  position: 'absolute',
  left: 0,
  bottom: 0,
  height: '15%',
  width: '100%',
  backgroundColor: Colors.primaryLightest,
  zIndex: 1,
});
const MobileBanner = styled('div')({
  left: 0,
  bottom: 0,
  height: '15%',
  width: '100%',
  backgroundColor: Colors.primaryLightest,
  borderRadius: '0 0 10px 10px',
  zIndex: 1,
});

interface PlanCardProps {
  plan: Domain.PricebookBundle;
  pricebookMetadata: any;
  subscriptionInfo: Domain.SubscriptionInfo | undefined;
  threeNickelsProducts: Domain.PricebookBundle[] | undefined;
  inAdviceFreeTrial: boolean;
  freeTrialStatus?: Data.Users.FreeTrialInfoDto | undefined;
  footnoteIndex?: number;
  manageSubscription?: boolean;
  currentPlan?: Domain.PricebookBundle | undefined;
  orgDoesNotOfferFreePricebook?: boolean | undefined;
  userNeverSubscribedToExclusivePricebook?: boolean;
  mobileCardStyle?: React.CSSProperties;
}

const PlanCard: React.FC<PlanCardProps> = ({
  plan,
  pricebookMetadata,
  subscriptionInfo,
  threeNickelsProducts,
  inAdviceFreeTrial,
  freeTrialStatus,
  footnoteIndex,
  manageSubscription,
  currentPlan,
  orgDoesNotOfferFreePricebook,
  userNeverSubscribedToExclusivePricebook,
  mobileCardStyle,
}) => {
  const { language } = i18next;
  const paymentApi = useService<PaymentApi>(ApiKeys.Payment);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const isCurrentPlan = plan.pricebook.id === currentPlan?.pricebook.id;
  const orgPays = plan.pricebook.paidBy === Domain.PaidByEnum.ORG;
  const memberPays = plan.pricebook.paidBy === Domain.PaidByEnum.USER;
  const free = plan.prices[0].price === 0 && memberPays;
  const nickelsU = plan.pricebook.productType === Domain.PricebookProductTypeEnum.NICKELSU;
  const promotion = plan.prices.find((price) => price.introductionPeriodInDays > 1);
  const { isMobile, isTablet } = useDeviceType();
  const mobile = isMobile || isTablet;

  const metadata = useMemo(() => {
    if (
      typeof pricebookMetadata === 'undefined' ||
      Object.keys(pricebookMetadata ?? {}).length === 0
    )
      return;
    return pricebookMetadata.find((metadata: any) => metadata.id === plan.pricebook.id);
  }, [plan, pricebookMetadata]);

  const { drawer, description } = getDrawerAndDescription(
    plan,
    metadata,
    drawerOpen,
    setDrawerOpen
  );

  const { paymentSubtext, descriptionSubtext, descriptionSubtextColor, actionButton } =
    getSubtextsAndActionButton(
      plan,
      paymentApi,
      setModalOpen,
      subscriptionInfo,
      manageSubscription,
      inAdviceFreeTrial,
      freeTrialStatus,
      isCurrentPlan,
      orgDoesNotOfferFreePricebook,
      userNeverSubscribedToExclusivePricebook,
      mobile
    );
  const usePriceAfterPromo = manageSubscription && nickelsU && promotion;
  const index = usePriceAfterPromo ? 1 : 0;
  const price = formatDecimalDollars(plan.prices[index].price / 100, language);
  const intervalText = getIntervalText(plan.prices[index].intervalType);

  return (
    <>
      {drawer}
      {subscriptionInfo && (
        <UnsubscribeModal
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          subscription={subscriptionInfo}
          threeNickelsProducts={threeNickelsProducts}
        />
      )}
      {mobile ? (
        <Grid
          sx={{
            borderRadius: '10px',
            border: `solid 1px ${Colors.primaryLightest}`,
            backgroundColor: Colors.tertiaryBase,
            ...mobileCardStyle,
          }}>
          <Grid
            display='flex'
            direction='column'
            sx={{
              padding: '20px',
              alignItems: 'center',
            }}>
            <Typography mb={1} variant='p18Bold' color='secondary'>
              {free ? 'Basic Tools' : plan.pricebook.pricebookName}
            </Typography>
            <Typography
              sx={{ width: '80%', textAlign: 'center' }}
              mb={1}
              variant='p16'
              color='secondary'>
              {description}
            </Typography>
            <Link mb={3} variant='p16' onClick={() => setDrawerOpen(true)}>
              {t('SeeDetails')}
            </Link>
            {!orgPays ? (
              free ? (
                <Typography mb={1} variant='p22Bold' color='primary'>
                  {t('FREE')}
                </Typography>
              ) : (
                <>
                  <Typography mb={1} variant='p22Bold' color='primary'>
                    {price}
                    {footnoteIndex && footnoteIndex > 0 ? '*'.repeat(footnoteIndex) : null}&nbsp;
                    {intervalText && (
                      <Typography variant='p12' color='primary' fontWeight='normal'>
                        / {intervalText}
                      </Typography>
                    )}
                  </Typography>
                  {paymentSubtext && (
                    <Typography mb={1} variant='p12' color='primary'>
                      {paymentSubtext}
                    </Typography>
                  )}
                </>
              )
            ) : null}
            {descriptionSubtext && (
              <Typography variant='p12' color={descriptionSubtextColor} mb={2}>
                {descriptionSubtext}
              </Typography>
            )}
            {actionButton && actionButton}
          </Grid>

          {!free && (
            <Grid display='flex' direction='row' justifyContent='flex-end'>
              <div
                style={{
                  width: '68%',
                  height: 'auto',
                  backgroundImage: `url(${nickelsU ? university : campingBanner_v1})`,
                  backgroundSize: '100%',
                  backgroundRepeat: 'no-repeat',
                  borderRadius: isCurrentPlan ? undefined : '10px',
                  paddingBottom: '35%',
                  opacity: 0.3,
                }}
              />
            </Grid>
          )}
          {isCurrentPlan && (
            <MobileBanner>
              <Typography variant='p14Bold' color='secondary' style={{ padding: '10px 20px' }}>
                {t('CurrentPlan')}
              </Typography>
            </MobileBanner>
          )}
        </Grid>
      ) : (
        <Card className='plan-card' style={{ position: 'relative', overflow: 'hidden' }}>
          <Grid container style={{ position: 'relative', zIndex: 2, minHeight: '210px' }}>
            <Grid xs={6} container direction='column' justifyContent='space-between' rowGap={1}>
              <Typography variant='p18Bold' color='secondary'>
                {free ? 'Basic Tools' : plan.pricebook.pricebookName}
              </Typography>
              <Typography variant='p16' color='secondary'>
                {description}
              </Typography>
              <Link mt={1} variant='p16' onClick={() => setDrawerOpen(true)}>
                {t('SeeDetails')}
              </Link>
              {actionButton && actionButton}
              <div style={{ flexGrow: 1 }} />
              {descriptionSubtext && (
                <Typography
                  variant='p12'
                  color={descriptionSubtextColor}
                  style={{ paddingBottom: '25px' }}>
                  {descriptionSubtext}
                </Typography>
              )}
            </Grid>

            <Grid
              item
              xs={6}
              container
              direction='column'
              style={{ height: '100%', alignItems: 'flex-end', minHeight: '210px' }}
              rowGap='5px'>
              {!orgPays ? (
                free ? (
                  <Typography variant='p22Bold' color='primary' style={{ paddingRight: '20px' }}>
                    {t('FREE')}
                  </Typography>
                ) : (
                  <>
                    <Typography variant='p22Bold' color='primary' style={{ paddingRight: '20px' }}>
                      {price}
                      {footnoteIndex && footnoteIndex > 0 ? '*'.repeat(footnoteIndex) : null}&nbsp;
                      {intervalText && (
                        <Typography variant='p12' color='primary' fontWeight='normal'>
                          / {intervalText}
                        </Typography>
                      )}
                    </Typography>
                    <Typography variant='p12' color='primary' style={{ paddingRight: '20px' }}>
                      {paymentSubtext}
                    </Typography>
                  </>
                )
              ) : (
                <div />
              )}
              <div style={{ flexGrow: 1 }} />
              {!free && (
                <div
                  style={{
                    width: '100%',
                    paddingBottom: '50%',
                    backgroundImage: `url(${nickelsU ? plan.pricebook.artUrl : campingBanner_v1})`,
                    backgroundSize: nickelsU ? '155%' : '100%',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                  }}
                />
              )}
            </Grid>
          </Grid>
          {isCurrentPlan && (
            <Banner>
              <Typography variant='p14Bold' color='secondary' style={{ padding: '10px 20px' }}>
                {t('CurrentPlan')}
              </Typography>
            </Banner>
          )}
        </Card>
      )}
    </>
  );
};

export default PlanCard;
