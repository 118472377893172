/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Drawer, Grid, IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { Data, Domain, Hooks } from '@3nickels/data-modules';
import { useNavigate } from 'react-router-dom';
import { useObservable } from '@aesop-fables/scrinium';
import { LayoutMeta, withLayoutMeta } from '../../../../types/LayoutMeta';
import { WizardFooter } from '../../../../components/form/WizardFooter';
import { Loading } from '../../../../hooks/useLoading';
import { combineLatest } from 'rxjs';
import { GoalsLayoutMeta } from '../../GoalsLayout';
import { getWizardFooterProps } from '../../../EditViewUtils';
import { GroupEdit } from '../../../../components/GroupEdit';
import { Add } from '@mui/icons-material';
import { Spacing } from '../../../../themes';
import VolunteeringGoalEditView from './VolunteeringGoalEditView';
import { useDeleteGoal } from '../../../../hooks/useMutations/useGoalsMutations';
import { t } from 'i18next';
import { convertToTranslationKey } from '../../../../helpers/utilityFunctions';

const VolunteeringGoalsListView: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { wizard } = Hooks.useVolunteeringGoalWizard();
  const goals = Hooks.useVolunteeringGoals();
  const deleteGoal = useDeleteGoal();
  const [open, setOpen] = useState(false);

  return (
    <>
      <Box>
        <Typography className='title' color='primary' component='h1' variant='h1'>
          {t('GivingYourTime')}
        </Typography>
        <Typography className='subtitle' color='secondary' variant='p16'>
          {t('SoManyWaysToVolunteer')}
        </Typography>
        <Grid container justifyContent='center' mt={2}>
          <Grid item sm={10}>
            <GroupEdit<Domain.HolisticVolunteeringGoalData>
              className='account-list'
              items={goals}
              keyFn={(x) => `${x.id ?? 0}`}
              mainContentStyle={{
                flex: 1,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                marginRight: '10px',
              }}
              mainContentFn={(x) => (
                <Grid container>
                  <Typography
                    className='hover-clickable'
                    color='secondary'
                    style={{
                      fontWeight: 'bold',
                      width: '90%',
                      display: 'block',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                    }}
                    onClick={() => {
                      wizard.start({ id: x.id });
                      wizard.selectStep('basic');
                      setOpen(true);
                    }}>
                    {x.goalName}
                  </Typography>
                </Grid>
              )}
              centerContentFn={(x) => (
                <Grid>
                  <Typography color='secondary' justifyContent='center'>
                    {t(convertToTranslationKey(x.typeOfPlace?.toString() ?? ''))}
                  </Typography>
                </Grid>
              )}
              summaryContentStyle={{
                flex: 1,
                justifyContent: 'flex-end',
              }}
              summaryFn={(x) => (
                <Grid>
                  <Typography color='secondary'>{`${x.numberOfHours} ${
                    x.numberOfHours === 1 ? t('hour') : t('hours')
                  }/${
                    x.frequencyType === Domain.VolunteeringFrequencyEnum.Weekly
                      ? t('week')
                      : t('month')
                  }`}</Typography>
                </Grid>
              )}
              onDelete={(x) => deleteGoal.action({ id: x.id, type: x.goalType })}
              addItemComponent={
                <AddVolunteeringGoalRow
                  onAdd={() => {
                    wizard.start({});
                    wizard.selectStep('basic');
                    setOpen(true);
                  }}
                />
              }
            />
          </Grid>
        </Grid>
        <WizardDrawer
          open={open}
          onClose={() => {
            setOpen(false);
            wizard.selectStep(
              undefined as unknown as keyof Data.Goals.VolunteeringGoalWizardRegistry
            );
          }}
        />
        <WizardFooter
          disableBack={false}
          onBack={() => navigate(-1)}
          onDone={() => navigate('/goals')}
          {...getWizardFooterProps(meta.nextLocaleKey, false)}
        />
      </Box>
    </>
  );
};

interface WizardDrawerProps {
  open: boolean;
  onClose: () => void;
}

const WizardDrawer: React.FC<WizardDrawerProps> = ({ onClose, open }) => {
  return (
    <Drawer anchor='right' open={open} onClose={onClose}>
      <Box p={Spacing.xxs}>
        <WizardForm onBack={onClose} />
      </Box>
    </Drawer>
  );
};

interface WizardFormProps {
  onBack: () => void;
}

const WizardForm: React.FC<WizardFormProps> = ({ onBack }) => {
  const { wizard } = Hooks.useVolunteeringGoalWizard();
  const [initialized, current] = useObservable(
    combineLatest([wizard.initialized$, wizard.current$])
  ) ?? [false, undefined];
  const loading = !initialized;
  if (loading) {
    return <Loading />;
  }

  const key = current?.key;

  if (key === 'basic') {
    return <VolunteeringGoalEditView editing onBack={onBack} />;
  }

  return null;
};

interface AddVolunteeringGoalRowProps {
  onAdd: () => void;
}

const AddVolunteeringGoalRow: React.FC<AddVolunteeringGoalRowProps> = ({ onAdd }) => {
  return (
    <Grid
      pl={4}
      width='100%'
      display='inline-flex'
      justifyContent='space-between'
      flexDirection='row'
      className='add-investment'
      style={{ cursor: 'pointer' }}
      onClick={() => {
        onAdd();
      }}>
      <Typography variant='p16Bold' color='primary'>
        {t('AddAVolunteeringGoal')}
      </Typography>
      <IconButton sx={{ padding: '0' }}>
        <Add color='primary' fontSize='large' />
      </IconButton>
    </Grid>
  );
};

const meta = {
  nextLocaleKey: 'Done',
  title: 'VolunteeringGoal',
} satisfies LayoutMeta<GoalsLayoutMeta>;

export default withLayoutMeta(VolunteeringGoalsListView, meta);
