/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import { t } from 'i18next';
import { Grid, MenuItem } from '@mui/material';
import { Api, Domain } from '@3nickels/data-modules';
import { UseFormReturn } from 'react-hook-form';
import SelectInput from '../../../../components/form/SelectInput';
import { Button } from '../../../../components/buttons/Button';
import ToggleRadioButtonGroup from '../../../../components/form/ToggleRadioButtonGroup';
import { convertToTranslationKey } from '../../../../helpers/utilityFunctions';

type CostOfCollegeFormProps = {
  methods: UseFormReturn<Domain.CcInfoFormData, any, undefined>;
  results: Api.CollegeCostRest | undefined;
  onSubmit: (values: Domain.CcInfoFormData) => Promise<void>;
  defaultValues: Domain.CcInfoFormData;
};

const CostOfCollegeForm: React.FC<CostOfCollegeFormProps> = ({
  methods,
  onSubmit,
  results,
  defaultValues,
}) => {
  const { formState, setValue } = methods;

  const onClick = methods.handleSubmit((data) => {
    // TODO add loading spinner for calculate button
    onSubmit(data);
  });

  useEffect(() => {
    if (defaultValues) {
      setValue('state', defaultValues.state);
      setValue('collegeType', defaultValues.collegeType);
      setValue('onCampus', defaultValues.onCampus);
    }
  }, [defaultValues]);

  return (
    <Grid className='tool-form-container'>
      <SelectInput<Domain.CcInfoFormData>
        error={formState.errors.state !== undefined}
        helperText={formState.errors.state?.message?.toString()}
        name='state'
        placeholder='ChooseState'
        label='InWhatStateWillYouAttendCollege'>
        {Domain.UsStatesListItems.map((x) => (
          <MenuItem value={x.key}>{x.value}</MenuItem>
        ))}
      </SelectInput>
      <SelectInput<Domain.CcInfoFormData>
        error={formState.errors.collegeType !== undefined}
        helperText={formState.errors.collegeType?.message?.toString()}
        name='collegeType'
        placeholder='ChooseCollegeType'
        label='TypeOfCollege'>
        {Domain.collegeTypes.map((x) => (
          <MenuItem value={x.key}>{t(convertToTranslationKey(x.value))}</MenuItem>
        ))}
      </SelectInput>
      <ToggleRadioButtonGroup<Domain.CcInfoFormData>
        error={formState.errors.onCampus !== undefined}
        helperText={formState.errors.onCampus?.message?.toString()}
        label='WhereWillYouLive'
        name='onCampus'
        row
        items={[
          { label: 'On-Campus', value: 'true' },
          { label: 'Off-Campus', value: 'false' },
        ]}
      />
      <Grid container justifyContent='flex-end'>
        <Button
          label={results ? 'Recalculate' : 'Calculate'}
          color='primary'
          style={{ minWidth: 'fit-content', width: '35%' }}
          onClick={onClick}
        />
      </Grid>
    </Grid>
  );
};

export default CostOfCollegeForm;
