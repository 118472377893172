import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Button } from './buttons/Button';
import { Svgs } from '../assets/svg';
import { HelpOutline } from '@mui/icons-material';
import Tippy from '@tippyjs/react';
import { theme } from '../themes/ThemeWrapper';
import { CSSProperties } from 'styled-components';

interface ToolHeaderProps {
  icon?: JSX.Element;
  title: string | JSX.Element;
  onDownload?: () => void;
  helpContent?: JSX.Element;
  subtitle?: string;
  subtitleStyle?: CSSProperties;
}

const ToolHeader: React.FC<ToolHeaderProps> = ({
  icon,
  title,
  onDownload,
  helpContent,
  subtitle,
  subtitleStyle,
}) => {
  const { t } = useTranslation();
  return (
    <Grid display='flex' columnGap='20px' justifyContent='space-between'>
      <Grid display='flex' justifyContent='flex-start' columnGap='30px'>
        {icon && icon}
        <Grid display='flex' justifyContent='flex-start' columnGap='10px' alignItems='center'>
          <Grid>
            {typeof title === 'string' ? (
              <Typography variant='p30Bold' component='h1' color='primary'>
                {t(title)}
              </Typography>
            ) : (
              title
            )}
            {subtitle && (
              <Typography sx={{ ...subtitleStyle }} color='secondary' variant='p16'>
                {t(subtitle)}
              </Typography>
            )}
          </Grid>

          {helpContent && (
            <Tippy placement='right' content={helpContent} theme='light-nickels-theme'>
              <HelpOutline style={{ color: theme.palette.primary.main }} />
            </Tippy>
          )}
        </Grid>
      </Grid>
      {onDownload && (
        <Button
          style={{ alignSelf: 'center' }}
          sx={{ typography: { fontSize: 14 } }}
          variant='outlined'
          color='primary'
          label='DownloadResults'
          onClick={onDownload}
          endIcon={<Svgs.IpmIconDownload />}
        />
      )}
    </Grid>
  );
};

export default ToolHeader;
