import React, { useEffect, useMemo } from 'react';
import { Grid, Typography, Box } from '@mui/material';
import { Data, Hooks } from '@3nickels/data-modules';
import { Tabs } from '../../components/Tabs';
import { useTranslation } from 'react-i18next';
import IncomeSection from './sections/IncomeSection';
import SavingsSection from './sections/SavingsSection';
import SpendingSection from './sections/SpendingSection';
import GivingSection from './sections/GivingSection';
import BudgetDetailsSidePanel from './BudgetDetailsSidePanel';
import { useNeedsOnboarding } from '../../hooks';
import { BudgetDetailsSidePanelSkeleton } from './sections/BudgetDetailsSidePanelSkeleton';
import { useSubject } from '@aesop-fables/scrinium';
import { IncomeReadySubject } from '../../subjects/AppReadySubject';
import { BudgetSectionSkeleton } from './sections/BudgetSectionSkeleton';

export const BudgetDetailsManager: React.FC = () => {
  const { t } = useTranslation();
  const { accountId } = Hooks.usePersonData() ?? {};
  const commands = Hooks.useCommands();
  const budget = Hooks.useBudgetSummary();
  const userConfig = Hooks.useUserConfig();
  const needsOnboarding = useNeedsOnboarding();
  const [value, setValue] = React.useState(0);
  const mode = value === 0 ? 'monthly' : 'annual';
  const sidePanelWidth = 370;
  const { ready, monthlySpending } = Hooks.useMonthlySpending();
  const housingReady = Hooks.useHousingEntitiesInitialized();
  const spendingSummary = Hooks.useBudgetSpendingSummary();
  const savingsReady = Hooks.useSavingsInitialized();
  const hsaReady = Hooks.useHsaInitialized();
  const investmentsReady = Hooks.useInvestmentAccountsInitialized();
  const goalsReady = Hooks.useGoalCompartmentInitialized();
  const incomeReady = useSubject<boolean>(IncomeReadySubject);
  const { loadingSummary } = Hooks.useTransactionsSummary();
  const plaidReady = Hooks.usePlaidInitialized();
  const transactionsReady = Hooks.useTransactionsInitialized();
  const budgetInitialized = Hooks.useBudgetInitialized();
  const personInitialized = Hooks.usePersonInitialized();
  const userConfigInitialized = Hooks.useUserInitialized();

  const cachesLoading = useMemo(
    () =>
      !accountId ||
      !ready ||
      !housingReady ||
      !savingsReady ||
      !hsaReady ||
      !investmentsReady ||
      !goalsReady ||
      !incomeReady ||
      !housingReady ||
      loadingSummary ||
      !plaidReady ||
      !transactionsReady ||
      !budgetInitialized ||
      !personInitialized ||
      !userConfigInitialized,
    [
      accountId,
      ready,
      housingReady,
      savingsReady,
      hsaReady,
      investmentsReady,
      goalsReady,
      incomeReady,
      housingReady,
      loadingSummary,
      plaidReady,
      transactionsReady,
      budgetInitialized,
      personInitialized,
      userConfigInitialized,
    ]
  );

  useEffect(() => {
    if (accountId && !userConfig?.budgetDetailsReviewed) {
      commands.executeMutation(new Data.Users.Mutations.CompleteBudgetDetails(), null);
    }
  }, [userConfig?.budgetDetailsReviewed, accountId, commands]);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box>
      <Box
        sx={{
          maxWidth: `calc(100% - ${sidePanelWidth}px)`,
        }}>
        <Typography variant='h1' color='primary' sx={{ mb: 2 }}>
          {t(needsOnboarding ? 'BudgetDetails' : 'Budget')}
        </Typography>
        <Typography variant='p16' color='secondary' sx={{ mb: 3 }}>
          {t('EnterMonthlyOrAnnual')}
        </Typography>
        <Tabs
          labels={[t('Monthly'), t('Annual')]}
          value={value}
          onChange={handleChange}
          aria-label='budget period selector'
          sx={{ mb: 3 }}
        />
      </Box>
      {typeof monthlySpending === 'undefined' ||
      typeof spendingSummary === 'undefined' ||
      cachesLoading ? (
        <Grid container spacing={5}>
          <Grid item xs>
            <BudgetSectionSkeleton />
          </Grid>
          <Grid item sx={{ width: `${sidePanelWidth}px` }}>
            <BudgetDetailsSidePanelSkeleton />
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={5}>
          <Grid item xs>
            <IncomeSection budget={budget} mode={mode} />
            <SavingsSection budget={budget} mode={mode} />
            <SpendingSection
              budget={budget}
              mode={mode}
              monthlySpending={monthlySpending}
              spendingSummary={spendingSummary}
            />
            <GivingSection budget={budget} mode={mode} monthlySpending={monthlySpending} />
          </Grid>
          <Grid item sx={{ width: `${sidePanelWidth}px` }}>
            <BudgetDetailsSidePanel />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};
