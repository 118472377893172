import { Grid, Button } from '@mui/material';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';
import { MedicalBenefitLayoutMeta } from './MedicalBenefitLayout';
import { LayoutMeta } from '../../../../types/LayoutMeta';
import { Colors } from '../../../../themes';
import { Svgs } from '../../../../assets/svg';

interface MedicalBenefitsFooterProps {
  meta: LayoutMeta<MedicalBenefitLayoutMeta>;
}

const MedicalBenefitsFooter: React.FC<MedicalBenefitsFooterProps> = ({ meta }) => {
  const navigate = useNavigate();

  const onBack = () => {
    if (meta.onBack) {
      meta.onBack(navigate);
    } else {
      if (meta.stepNumber === 1) navigate('/tools/medical');
      else navigate(-1);
    }
  };

  const onNext = () => {
    if (meta.onNext) {
      meta.onNext();
    } else {
      if (meta.stepNumber === 5) {
        navigate('/tools/medical/medical-benefit-selector-tool/summary');
      } else if (meta.stepNumber === 6) {
        onExitClicked();
      } else {
        navigate(`/tools/medical/medical-benefit-selector-tool/${meta.stepNumber + 1}`);
      }
    }
  };

  const onExitClicked = () => {
    navigate('/tools/medical');
  };

  return (
    <>
      <Grid gridArea='sidebar' display='flex' flexDirection='column' justifyContent='flex-end'>
        <Grid sx={{ backgroundColor: Colors.tertiaryBase, paddingY: '25px', paddingX: '20px' }}>
          <Button
            variant='ghost'
            size='large'
            onClick={onExitClicked}
            sx={{
              display: { xl: 'inline', lg: 'inline' },
              padding: '5px !important',
            }}>
            <Grid display='flex' alignItems='center' columnGap='10px'>
              <Svgs.ActionSmallMenuClose />
              {t('Exit')}
            </Grid>
          </Button>
        </Grid>
      </Grid>
      <Grid
        className='footer-content'
        gridArea='footer'
        display='flex'
        flexDirection='column'
        justifyContent='flex-end'>
        <Grid
          className='content'
          sx={{
            backgroundColor: Colors.tertiaryBase,
            paddingY: '25px',
          }}>
          <Grid className='children' width='100%' display='flex' justifyContent='space-between'>
            <Button
              color='secondary'
              variant='outlined'
              onClick={onBack}
              sx={{ minWidth: '200px' }}>
              {t(meta.backLocaleKey ?? 'Back')}
            </Button>

            <Button
              color='secondary'
              onClick={onNext}
              sx={{ minWidth: '200px', visibility: meta.showNext ? undefined : 'hidden' }}
              disabled={meta.disableNextButton}>
              {t(meta.nextLocaleKey ?? 'Continue')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default MedicalBenefitsFooter;
