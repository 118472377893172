import { Card, Grid } from '@mui/material';
import { Svgs } from '../assets/svg';
import { PropsWithChildren } from 'react';

interface InfoCardProps extends PropsWithChildren {
  renderIcon?: boolean;
  icon?: React.ReactNode;
  onClick?: () => void;
}

const InfoCard: React.FC<InfoCardProps> = ({ onClick, children, ...props }) => {
  const renderIcon = props.renderIcon ?? true;
  const icon = props.icon ?? <Svgs.ArtLightbulbSmall style={{ flexShrink: 0 }} />;
  return (
    <Grid className='info-card-border info-card' onClick={onClick}>
      <Card>
        {renderIcon && icon}
        {children}
        {typeof onClick !== 'undefined' && <Svgs.IconArrowRight style={{ flexShrink: 0 }} />}
      </Card>
    </Grid>
  );
};

export default InfoCard;
