import { IServiceContainer } from '@aesop-fables/containr';
import { DataStore, ScriniumServices } from '@aesop-fables/scrinium';
import { redirect, json } from 'react-router-dom';
import { firstValueFrom } from 'rxjs';
import { Data, DataModuleTokens, Domain } from '@3nickels/data-modules';
import { waitFor } from '../utils';
import { ChatMetadataCompartments } from '@3nickels/data-modules/lib/data/gpt/chat';

export async function chatBotLoader(container: IServiceContainer) {
  const dataStore = container.get<DataStore>(ScriniumServices.DataStore);
  const chatMetadataCache = dataStore.cache<ChatMetadataCompartments>(
    DataModuleTokens.ChatMetadata
  );

  await waitFor(chatMetadataCache.initialized$);

  const userDataCache = Data.Users.retrieveUserData(dataStore);
  const features = await firstValueFrom(userDataCache.observe$<Domain.FeatureMap>('features'));
  if (!features?.heyNic) {
    return redirect('/');
  }

  return json('heyNic enabled');
}
