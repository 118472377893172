import { NavigateFunction, useOutletContext } from 'react-router-dom';
import { HexagonStepProps } from '../components/HexagonStep';
import React from 'react';
import { Newable } from '@aesop-fables/containr';

export interface ILayoutNavigationOperation<T> {
  onNext(values: T, navigate: NavigateFunction): Promise<void>;
  onPrevious(navigate: NavigateFunction): Promise<void>;
}

export type LayoutMeta<T = object> = {
  showBack?: boolean;
  showNext?: boolean;
  onBack?: (navigate: NavigateFunction) => void;
  nextLocaleKey?: string;
  backLocaleKey?: string;
  hexSteps?: HexagonStepProps;
  header?: React.ReactNode;
  disableNextButton?: boolean;
  background?: React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  operation?: Newable<ILayoutNavigationOperation<any>>;
} & T;

export interface LayoutContext {
  meta: Partial<LayoutMeta>;
  setMeta: React.Dispatch<React.SetStateAction<Partial<LayoutMeta>>>;
}

export const withLayoutMeta =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (Component: React.FC, meta: LayoutMeta) => (props: any) => {
    const { setMeta } = useOutletContext<LayoutContext>();

    React.useEffect(() => {
      setMeta(meta);
    }, [meta, setMeta]);

    return <Component {...props} />;
  };
