import { Card, Grid, Skeleton, Stack } from '@mui/material';
import { Percentages, Spacing } from '../../../themes';
import HorizontalLineIcon from '../../../components/HorizontalLineIcon';

export const BudgetDetailsSidePanelSkeleton: React.FC = () => {
  return (
    <Stack spacing={2} mt={3 - Spacing.xxs}>
      <Grid className='group-panel-container'>
        <BudgetDetailsSidePanelSkeletonCard />
        <BudgetDetailsSidePanelSkeletonCard />
      </Grid>
    </Stack>
  );
};

const BudgetDetailsSidePanelSkeletonCard: React.FC = () => {
  return (
    <Grid
      className='group-panels'
      container
      display='flex'
      flexWrap='wrap'
      flexDirection='row'
      alignItems='stretch'
      gap={Spacing.xxs - 3}
      mt={Spacing.xxs - 3}
      sx={{ width: '330px', height: '200px' }}>
      <Grid className='panel-popover' item xs={12}>
        <Card
          elevation={4}
          sx={{
            height: Percentages.Full,
          }}>
          <Grid container p={Spacing.xxxs + 1} flexDirection='column'>
            <Grid item pb={Spacing.xxxs + 1}>
              <HorizontalLineIcon>
                <Skeleton height='30px' width='30px' variant='circular' />
              </HorizontalLineIcon>
            </Grid>
            <Grid item>
              <Skeleton height='20px' width='200px' variant='rounded' />
              <Skeleton height='15px' width='280px' variant='rounded' sx={{ marginTop: '10px' }} />
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};
