import React from 'react';
import LinkAccountHeader from '../../headers/LinkAccountHeader';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { LayoutMeta, withLayoutMeta } from '../../../../types/LayoutMeta';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { WizardFooter } from '../../../../components/form/WizardFooter';
import { getWizardFooterProps } from '../../../EditViewUtils';
import { Domain } from '@3nickels/data-modules';
import ToggleRadioButtonGroup from '../../../../components/form/ToggleRadioButtonGroup';
import { Spacing } from '../../../../themes';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormContent from '../../../../components/form/FormContent';
import { t } from 'i18next';

export interface HousingTypeForm {
  type?: Domain.RealEstateTypeEnum;
}

const formSchema = () =>
  Yup.object({
    type: Yup.string().required(t('Required') as string),
  });

const AccountTypeView: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onSubmit = async (values: HousingTypeForm) => {
    if (values.type === Domain.RealEstateTypeEnum.PERSONAL) {
      navigate(`/account-details/home/basics`);
    } else {
      navigate(`/account-details/investment-real-estate/basics`);
    }
  };

  const methods = useForm<HousingTypeForm>({
    resolver: yupResolver(formSchema()),
  });

  return (
    <FormContent formProviderProps={methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Grid container flexDirection='column'>
          <Typography className='title' variant='h1' component='h1' color='primary'>
            {t('Link a Mortgage')}
          </Typography>
          <Grid container justifyContent='center'>
            <Grid item pt={Spacing.xxxs + 1} sm={6}>
              <Box>
                <Stack spacing={Spacing.xxs}>
                  <ToggleRadioButtonGroup<HousingTypeForm>
                    error={methods.formState.errors.type !== undefined}
                    helperText={methods.formState.errors.type?.message?.toString()}
                    label='MortgageType'
                    name='type'
                    row
                    items={[
                      { label: 'Home', value: Domain.RealEstateTypeEnum.PERSONAL },
                      { label: 'Investment', value: Domain.RealEstateTypeEnum.INVESTMENT },
                    ]}
                  />
                </Stack>
              </Box>
            </Grid>
            <WizardFooter {...getWizardFooterProps(meta.nextLocaleKey)} />
          </Grid>
        </Grid>
      </form>
    </FormContent>
  );
};

const meta = {
  nextLocaleKey: 'Continue',
  header: <LinkAccountHeader />,
} satisfies LayoutMeta;

export default withLayoutMeta(AccountTypeView, meta);
