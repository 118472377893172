import { Button, Card, Link, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { Form } from 'react-final-form';
import { useNavigate, Link as DomLink } from 'react-router-dom';
import Cookies from 'js-cookie';
import { Column, Row, Spacer } from '../components';
import { logo3NSmall } from '../assets/png';
import { useDeviceType } from '../hooks/useDeviceType';
import { PaymentApi } from '../api/apis/PaymentApi';
import { mobileMargin } from '../App';
import { LicenseWizard, licenseWizardKey } from '../services/license';
import { ApiKeys } from '../api/apis/ApiKeys';
import { useObservable } from '@aesop-fables/scrinium';
import { useService } from '@aesop-fables/containr-react';
import { Api, Domain, Hooks } from '@3nickels/data-modules';
import { PageContentWithBackground } from '../pages/layout/PageContentWithBackground';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import { API_URL, API_BASE_URL } from '../configuration/Environment';
import { usePaymentStatus } from '../hooks/payment/usePaymentStatus';
import { ProductOptions } from './components/ProductOptions';

const Review = () => {
  const navigate = useNavigate();
  const { isMobile, isTablet } = useDeviceType();

  const termsApi = useService<Api.TermsAndConditionsApi>(Api.ApiKeys.TermsAndConditions);
  const paymentApi = useService<PaymentApi>(ApiKeys.Payment);

  const licenseWizard = useService<LicenseWizard>(licenseWizardKey);
  const licenseState = useObservable(licenseWizard.state$);

  const organizationData = Hooks.useOrganizationData();
  const organizationMetadata = Hooks.useOrganizationMetadata();

  const paymentStatus = usePaymentStatus();

  const [memberPaidOptions, setMemberPaidOptions] = useState<Domain.PricebookBundle[]>([]);
  const [orgPaidOptions, setOrgPaidOptions] = useState<Domain.PricebookBundle[]>([]);
  const [tosLink, setTosLink] = useState<string>();

  const promotion = licenseState?.pricebook?.prices.find(
    (price) => price.introductionPeriodInDays > 1
  );
  const introPeriodInMonths = Math.floor((promotion?.introductionPeriodInDays ?? 0) / 30);

  const pricebook = licenseState?.pricebook;
  const checkOut = !paymentStatus?.hasActiveLicense;

  const pricebookMetadata = useMemo(() => {
    return typeof organizationMetadata !== 'undefined' &&
      Object.keys(organizationMetadata.pricebookMetadata ?? {}).length > 0
      ? JSON.parse(organizationMetadata?.pricebookMetadata?.pricebook ?? '')
      : undefined;
  }, [organizationMetadata]);

  useEffect(() => {
    if (!pricebook) return;
    const member = pricebook.pricebook.paidBy === 'USER' ? [pricebook] : [];
    const org = pricebook.pricebook.paidBy === 'ORG' ? [pricebook] : [];
    setMemberPaidOptions(member);
    setOrgPaidOptions(org);
  }, [pricebook]);

  useEffect(() => {
    const getTermsLink = async () => {
      const res = await termsApi.getDocs();
      const termsObject = res.data.find((doc) => doc.docDescription === 'Terms of Service');
      if (!termsObject) {
        return;
      }
      const url = termsApi.getDocString(termsObject.id);
      setTosLink(url);
    };
    getTermsLink();
  }, [termsApi]);

  const onSubmit = async () => {
    if (!pricebook) {
      return;
    }

    try {
      if (checkOut === false) {
        if (isMobile || isTablet) {
          navigate('/accept-terms');
        } else {
          navigate('/congrats');
        }
      } else {
        const stripeUrl = await paymentApi.createSession(pricebook.pricebook.id);
        window.location.replace(stripeUrl.data);
      }
    } catch (error) {
      console.error('Failed to select pricebook with id: ' + pricebook.pricebook.id);
    }
  };

  return (
    <PageContentWithBackground useContainer={false}>
      <Form onSubmit={onSubmit}>
        {({ handleSubmit }) => (
          <>
            <Column style={{ margin: isMobile || isTablet ? mobileMargin : '15px 100px' }}>
              <Row
                style={{ alignItems: 'center', justifyContent: 'space-between', height: '75px' }}>
                {organizationData ? (
                  organizationData.logoUri ? (
                    <img
                      src={`${API_URL}organization/logo`}
                      alt={organizationData?.name}
                      style={{ height: '60%' }}
                    />
                  ) : (
                    <Typography variant='p25Bold' color='secondary'>
                      {organizationData?.name}
                    </Typography>
                  )
                ) : (
                  <Card variant='ghost' />
                )}
                <DomLink style={{ height: '70%' }} to='/'>
                  <img src={logo3NSmall} alt='logo' style={{ height: '100%' }} />
                </DomLink>
              </Row>
              <Typography variant='p30Bold' color='primary'>
                {t('ReviewAnd', { action: checkOut ? t(`CheckOut`) : t(`Confirm`) })}
              </Typography>
              <Spacer height='xs' />
              <Typography variant='h2' color='secondary'>
                {t(
                  checkOut
                    ? t('YoureAlmostThereReviewBeforeCheckingOut')
                    : t('YoureAlmostThereTakeASecondToReview')
                )}
              </Typography>
              <Spacer height='xs' />
              <ProductOptions
                memberPaidOptions={memberPaidOptions}
                orgPaidOptions={orgPaidOptions}
                pricebookMetadata={pricebookMetadata}
              />
              {!isMobile && <Spacer height='xxxs' />}
              {checkOut ? (
                <>
                  <Trans
                    i18nKey='PleaseSeeTOSandCancellationPolicy'
                    components={{
                      TermsOfService: <Link href={tosLink} target='_blank' />,
                      CancellationPolicy: <Link href='/cancellation-policy' target='_blank' />,
                    }}
                  />
                  {promotion && (
                    <>
                      <Spacer height='xs' />
                      <Typography variant='p14' color='secondary'>
                        *{' '}
                        {t('AfterXMonthsAdviceSubscriptionAutoRenewsAt', {
                          x: introPeriodInMonths,
                        })}
                      </Typography>
                      {isMobile && <Spacer height='sm' />}
                    </>
                  )}
                </>
              ) : (
                <>
                  <Trans
                    i18nKey='PleaseSeeTOS'
                    components={{
                      TermsOfService: <Link href={tosLink} target='_blank' />,
                    }}
                  />
                  {(isMobile || isTablet) && <Spacer height='sm' />}
                </>
              )}
              {isMobile ? (
                <Column style={{ justifyContent: 'space-between' }}>
                  {checkOut ? (
                    <Button onClick={handleSubmit} color='secondary'>
                      {t('CheckOut')}
                    </Button>
                  ) : (
                    <Button onClick={handleSubmit} color='secondary'>
                      {t('Confirm')}
                    </Button>
                  )}
                  <Spacer height='xs' />
                  <form method='post' action={`${API_BASE_URL}/logout`}>
                    <Button
                      type='submit'
                      variant='outlined'
                      color='secondary'
                      style={{ width: '100%' }}>
                      {t('Cancel&LogOut')}
                    </Button>
                    <input type='hidden' name='_csrf' value={Cookies.get('XSRF-TOKEN')} readOnly />
                  </form>
                </Column>
              ) : (
                <>
                  <Spacer height='sm' />
                  <Row style={{ justifyContent: 'space-between' }}>
                    <form method='post' action={`${API_BASE_URL}/logout`}>
                      <Button type='submit' variant='outlined' color='secondary'>
                        {t('Cancel&LogOut')}
                      </Button>
                      <input
                        type='hidden'
                        name='_csrf'
                        value={Cookies.get('XSRF-TOKEN')}
                        readOnly
                      />
                    </form>
                    {checkOut ? (
                      <Button
                        className='sm'
                        onClick={handleSubmit}
                        type='submit'
                        color='secondary'
                        style={{ width: 'fit-content' }}>
                        {t('CheckOut')}
                      </Button>
                    ) : (
                      <Button
                        className='sm'
                        onClick={handleSubmit}
                        color='secondary'
                        type='submit'
                        style={{ width: 'fit-content' }}>
                        {t('Confirm')}
                      </Button>
                    )}
                  </Row>
                </>
              )}
              <Spacer height='sm' />
            </Column>
          </>
        )}
      </Form>
    </PageContentWithBackground>
  );
};

export default Review;
