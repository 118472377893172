import { Api } from '@3nickels/data-modules';
import { Link, Typography } from '@mui/material';
import React from 'react';
import { Trans } from 'react-i18next';

export const getLegalDocByDescription = async (
  termsApi: Api.ITermsAndConditionsApi,
  legalDocs: Api.TermsDoc[] | undefined,
  description: string
) => {
  if (legalDocs) {
    const termsObject = legalDocs.find((doc) => doc.docDescription === description);
    if (!termsObject) {
      return;
    }
    const response = await termsApi.getDoc(termsObject.id);
    const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
    return url;
  }
};

interface SeeLegalDocsProps {
  termsApi: Api.ITermsAndConditionsApi;
  legalDocs: Api.TermsDoc[] | undefined;
  linkStyle?: React.CSSProperties;
}

export const SeeLegalDocs: React.FC<SeeLegalDocsProps> = ({ termsApi, legalDocs, linkStyle }) => {
  const openTOS = async () => {
    const url = await getLegalDocByDescription(termsApi, legalDocs, 'Terms of Service');
    window.open(url);
  };

  const openPrivacyPolicy = async () => {
    const url = await getLegalDocByDescription(termsApi, legalDocs, 'Privacy Policy');
    window.open(url);
  };
  return (
    <Typography className='link-wrapper' variant='p12' color='secondary'>
      <Trans
        i18nKey='PleaseSeeTOSAndPrivacyPolicy'
        components={{
          TermsOfService: <Link style={linkStyle} onClick={() => openTOS()} />,
          PrivacyPolicy: <Link style={linkStyle} onClick={() => openPrivacyPolicy()} />,
        }}
      />
    </Typography>
  );
};

interface FootnoteProps {
  index: number;
  text: string;
  termsApi: Api.ITermsAndConditionsApi;
  legalDocs: Api.TermsDoc[] | undefined;
}

const Footnote: React.FC<FootnoteProps> = ({ index, text, termsApi, legalDocs }) => {
  return (
    <Typography variant='p12' color='secondary' style={{ display: 'inline' }}>
      {'*'.repeat(index + 1)}
      {text}&nbsp;
      {index === 0 && <SeeLegalDocs termsApi={termsApi} legalDocs={legalDocs} />}
    </Typography>
  );
};

export default Footnote;
