/* eslint-disable @typescript-eslint/no-explicit-any */
import { Backdrop, Box, Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { Data, Hooks, ObservableGuard, useObservableGuardCondition } from '@3nickels/data-modules';
import { FormLoader } from '../../../components/FormLoader';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import FormContent from '../../../components/form/FormContent';
import { WizardStep, useObservable } from '@aesop-fables/scrinium';
import { WizardFooter } from '../../../components/form/WizardFooter';
import { Loading, useLoading } from '../../../hooks/useLoading';
import { EditViewProps, getWizardFooterProps } from '../../EditViewUtils';
import { firstValueFrom, map } from 'rxjs';
import { LayoutMeta, withLayoutMeta } from '../../../types/LayoutMeta';
import { AccountDetailsLayoutMeta } from '../AccountDetailsLayout';
import { t } from 'i18next';
import AnnuityPayoutForm from './AnnuityPayoutForm';
import * as Yup from 'yup';
import { cleanWholeNumberStr } from '../../../helpers/utilityFunctions';
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';

const detailsSchema = () =>
  Yup.object({
    distOption: Yup.string()
      .required(t('Required') as string)
      .test('distOption', t('Required') as string, (distOption) => {
        if (distOption === '') return false;
        return true;
      }),
    annuityStartAge: Yup.string()
      .required(t('Required') as string)
      .test('annuityStartAge', t('Required') as string, (age) => {
        const cleanNumber = cleanWholeNumberStr(age);
        return cleanNumber !== 0;
      })
      .test('annuityStartAge', t('MaximumAge99') as string, (age) => {
        const cleanNumber = cleanWholeNumberStr(age);
        return cleanNumber <= 99;
      })
      .test('annuityStartAge', t('MinimumAge30') as string, (age) => {
        const cleanNumber = cleanWholeNumberStr(age);
        return cleanNumber >= 30;
      }),
    annuityEndAge: Yup.string().when('distOption', {
      is: (distOption: string) =>
        distOption === Data.Annuities.AnnuityDistMethodsEnum['Period Certain'].toString() ||
        distOption === Data.Annuities.AnnuityDistMethodsEnum['Life with Period Certain'].toString(),
      then: (schema) =>
        schema
          .required(t('Required') as string)
          .test('annuityEndAge', t('Required') as string, (age) => {
            const cleanNumber = cleanWholeNumberStr(age);
            return cleanNumber !== 0;
          })
          .test('annuityEndAge', t('MaximumAge120') as string, (age) => {
            const cleanNumber = cleanWholeNumberStr(age);
            return cleanNumber <= 99;
          })
          .test('annuityEndAge', t('MinimumAge30') as string, (age) => {
            const cleanNumber = cleanWholeNumberStr(age);
            return cleanNumber >= 30;
          })
          .test({
            name: 'endAge greater than startAge',
            test: function (annuityEndAge, { createError, path, parent }) {
              const { annuityStartAge } = parent;
              if (annuityEndAge <= annuityStartAge) {
                return createError({
                  message: t('EndYearMustComeAfterStartYear', {
                    annuityEndAge,
                  }) as string,
                  path,
                });
              }

              return true;
            },
          }),
    }),
    monthlyIncome: Yup.string()
      .required(t('Required') as string)
      .test('monthlyIncome', t('MonthlyPaymentMustBeGreaterThan0') as string, (amount) => {
        const cleanNumber = cleanWholeNumberStr(amount);
        return cleanNumber > 0;
      }),
    survivorFraction: Yup.string().when('distOption', {
      is: (distOption: string) =>
        distOption === Data.Annuities.AnnuityDistMethodsEnum['Joint and Survivor'].toString() ||
        distOption === Data.Annuities.AnnuityDistMethodsEnum['Joint Life'].toString(),
      then: (schema) =>
        schema
          .required(t('Required') as string)
          .test('survivorFraction', t('Required') as string, (survivorFraction) => {
            const cleanNumber = cleanWholeNumberStr(survivorFraction);
            return cleanNumber > 0;
          }),
    }),
    inflationOption: Yup.string()
      .required(t('Required') as string)
      .test('inflationOption', t('Required') as string, (inflationOption) => {
        if (inflationOption === '') return false;
        return true;
      }),
    colaAmtRate: Yup.string().when('inflationOption', {
      is: Data.Annuities.AnnuityInflationProtectionEnum['Fixed Annual Increases'].toString(),
      then: (schema) => schema.required(t('Required') as string),
    }),
    cashOrConvert: Yup.string().required(t('Required') as string),
    cost: Yup.string().when('cashOrConvert', {
      is: 'convert',
      then: (schema) =>
        schema.required(t('Required') as string).test('cost', t('Required') as string, (cost) => {
          const cleanNumber = cleanWholeNumberStr(cost);
          return cleanNumber !== 0;
        }),
    }),
  });

export const AnnuityPayoutEditViewWrapper: React.FC<EditViewProps> = ({ editing, onBack }) => {
  const navigate = useNavigate();
  const { loading, currentStep, wizard, params } =
    Hooks.useAnnuityWizard<Data.Annuities.AnnuityFormData>();
  const isStarted = useObservable(wizard.isStarted$);
  const { id } = useParams();
  useEffect(() => {
    if (typeof isStarted !== 'undefined' && !isStarted) {
      if (!id) {
        navigate('/account-details/fixed-annuity/basics', { replace: true });
      }
      wizard.start({ id: parseInt(id ?? '') });
    } else if (isStarted) {
      wizard.selectStep('basic');
    }
  }, [isStarted]);

  return (
    <ObservableGuard
      predicate$={wizard.current$.pipe(map((current) => current?.key === 'basic'))}
      loadingFn={() => (
        <Backdrop open>
          <Loading />
        </Backdrop>
      )}>
      {currentStep?.key === 'basic' && params && (
        <FormLoader loading={loading}>
          <AnnuityPayoutEditView
            currentStep={currentStep}
            wizard={wizard}
            editing={editing}
            onBack={onBack}
            params={params}
          />
        </FormLoader>
      )}
    </ObservableGuard>
  );
};

interface AnnuityPayoutEditViewProps extends EditViewProps {
  currentStep: WizardStep<Data.Annuities.AnnuityFormData, Data.Annuities.AnnuityWizardParams>;
  wizard: Data.Annuities.AnnuityWizard;
  params: Data.Annuities.AnnuityWizardParams;
}

const AnnuityPayoutEditView: React.FC<AnnuityPayoutEditViewProps> = ({
  currentStep,
  wizard,
  editing,
  onBack,
}) => {
  const navigate = useNavigate();
  const ready = useObservableGuardCondition();
  const methods = useForm<Data.Annuities.AnnuityFormData>({
    defaultValues: currentStep.model,
    resolver: yupResolver(detailsSchema()),
  });
  const { setLoading } = useLoading();
  const personalInfo = Hooks.usePersonData() ?? {};
  const spouseInfo = Hooks.useSpouseData() ?? {};
  const today = dayjs();
  const currentYear = today.year();
  const currentAge = today.diff(
    currentStep.model.owner === 'me' ? personalInfo.birthDate : spouseInfo.birthDate,
    'year'
  );
  const formatInputs = (values: any) => {
    const beginYearsInFuture = Number(values.annuityStartAge) - currentAge;
    const beginYear = currentYear + beginYearsInFuture;
    const endYearsInFuture = Number(values.annuityEndAge) - currentAge;
    const endYear = currentYear + endYearsInFuture;
    const formatted: Partial<Data.Annuities.AnnuityFormData> = {
      ...values,
      annuityStartAge: Number(values.annuityStartAge),
      beginYear: beginYear,
      endYear: endYear,
      distOption: Number(values.distOption),
      distOptionName: Data.Annuities.annuityDistOptionNames[values.distOption],
      inflationOption: Number(values.inflationOption),
      colaWithCpi:
        Number(values.inflationOption) ===
        Data.Annuities.AnnuityInflationProtectionEnum['Inflation-Indexed or COLA'],
      cost: cleanWholeNumberStr(values.cost),
      monthlyIncome: cleanWholeNumberStr(values.monthlyIncome),
      survivorFraction: cleanWholeNumberStr(values.survivorFraction),
      colaAmtRate: cleanWholeNumberStr(values.colaAmtRate),
    };

    return formatted;
  };

  const onSubmit = async (values: any) => {
    setLoading(true);

    try {
      const cleanedValues = formatInputs(values);
      currentStep.save(cleanedValues);
      await wizard.commitStep('basic');

      if (editing && onBack) {
        onBack();
        return;
      }

      const wizardParams = await firstValueFrom(wizard.params$);
      navigate(`/account-details/fixed-annuity/summary/${wizardParams?.id ?? 0}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {ready && (
        <FormContent formProviderProps={methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Box>
              <Typography className='title' color='primary' component='h1' variant='h1'>
                {t('PayoutDetails')}
              </Typography>
              <Typography
                mt={1}
                className='subheader'
                color='secondary'
                component='h2'
                variant='p18Bold'>
                {currentStep.model.annuityName}
              </Typography>
              <Grid container justifyContent='center' mt={2}>
                <Grid item sm={editing ? 10 : 6}>
                  <AnnuityPayoutForm payout={currentStep.model} />
                </Grid>
              </Grid>
            </Box>
            <WizardFooter
              color={editing ? 'primary' : undefined}
              disableBack={false}
              onBack={onBack}
              {...getWizardFooterProps('Next', editing)}
            />
          </form>
        </FormContent>
      )}
    </>
  );
};

const meta = {
  nextLocaleKey: 'Next',
  hexSteps: {
    steps: 2,
    currentStep: 2,
  },
  headerVariant: 'hex',
} satisfies LayoutMeta<AccountDetailsLayoutMeta>;

export default withLayoutMeta(AnnuityPayoutEditViewWrapper, meta);
