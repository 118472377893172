/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { t } from 'i18next';
import { Grid, MenuItem } from '@mui/material';
import { Api, Domain } from '@3nickels/data-modules';
import { useFormContext, UseFormReturn } from 'react-hook-form';
import SelectInput from '../../../../components/form/SelectInput';
import { Button } from '../../../../components/buttons/Button';
import SliderPercent from '../../../../components/form/SliderPercent';

type HowMuchHouseCalculatorFormProps = {
  methods: UseFormReturn<Api.AdviceHouseAffordabilityRest, any, undefined>;
  onSubmit: (values: Api.AdviceHouseAffordabilityRest) => Promise<void>;
  results: Api.HouseAffordabilityResultRest | undefined;
  defaultValues: Api.AdviceHouseAffordabilityRest;
};

const HowMuchHouseCalculatorForm: React.FC<HowMuchHouseCalculatorFormProps> = ({
  methods,
  onSubmit,
  results,
  defaultValues,
}) => {
  const { formState } = useFormContext();

  const onClick = methods.handleSubmit((data) => {
    onSubmit(data);
  });

  return (
    <Grid className='tool-form-container'>
      <SliderPercent<Api.AdviceHouseAffordabilityRest>
        error={formState.errors.cashForDownPayment !== undefined}
        helperText={formState.errors.cashForDownPayment?.message?.toString()}
        label='AvailableCashDown'
        name='cashForDownPayment'
        type='dollar'
        initialMax={1000000}
        step={10000}
        defaultValue={defaultValues.cashForDownPayment}
      />
      <SliderPercent<Api.AdviceHouseAffordabilityRest>
        error={formState.errors.interestRate !== undefined}
        helperText={formState.errors.interestRate?.message?.toString()}
        label='InterestRate'
        name='interestRate'
        type='percent'
        precision={3}
        defaultValue={defaultValues.interestRate}
      />
      <SelectInput<Api.AdviceHouseAffordabilityRest>
        error={formState.errors.mortgageTermType !== undefined}
        helperText={formState.errors.mortgageTermType?.message?.toString()}
        defaultValue={defaultValues.mortgageTermType}
        label='MortgageTerm'
        placeholder='ChooseMortgageTerm'
        name='mortgageTermType'>
        {Domain.mortgageTermTypes.map((x) => (
          <MenuItem value={x.key}>{t(x.value)}</MenuItem>
        ))}
      </SelectInput>
      <Grid container justifyContent='flex-end'>
        <Button
          label={results ? 'Recalculate' : 'Calculate'}
          color='primary'
          style={{ minWidth: 'fit-content', width: '35%' }}
          onClick={onClick}
        />
      </Grid>
    </Grid>
  );
};

export default HowMuchHouseCalculatorForm;
