import { Box, Drawer, Grid, MenuItem, Stack, Typography } from '@mui/material';
import React, { ChangeEvent, useEffect, useState } from 'react';
import TextInput from '../../../components/form/TextInput';
import { Colors, Spacing } from '../../../themes';
import { Data, Hooks } from '@3nickels/data-modules';
import { useFormContext } from 'react-hook-form';
import SelectInput from '../../../components/form/SelectInput';
import { convertToTranslationKey } from '../../../helpers/utilityFunctions';
import { t } from 'i18next';
import ToggleRadioButtonGroup from '../../../components/form/ToggleRadioButtonGroup';
import SliderPercent from '../../../components/form/SliderPercent';
import HelpPopover, { HelpPopoverText } from '../../../components/HelpPopover';
import dayjs from 'dayjs';
import { Row, Spacer } from '../../../components';
import { HelpOutline } from '@mui/icons-material';
import { theme } from '../../../themes/ThemeWrapper';
import { Button } from '../../../components/buttons/Button';

export type AnnuityPayoutFormProps = {
  payout: Data.Annuities.AnnuityFormData;
};

const AnnuityPayoutForm: React.FC<AnnuityPayoutFormProps> = ({ payout }) => {
  return (
    <Box>
      <Stack spacing={Spacing.xxs}>
        <PayoutForm payout={payout} />
      </Stack>
    </Box>
  );
};

declare type PayoutFormProps = {
  payout: Data.Annuities.AnnuityFormData;
};

const PayoutForm: React.FC<PayoutFormProps> = ({ payout }) => {
  const { formState, watch } = useFormContext();
  const [distOption, setDistOption] = useState<string | undefined>(
    payout.distOptionName ?? undefined
  );
  const [beginYear, setBeginYear] = useState<number | undefined>(payout.beginYear ?? undefined);
  const [endYear, setEndYear] = useState<number | undefined>(payout.endYear ?? undefined);
  const [inflationOption, setInflationOption] = useState<string | undefined>(
    payout.inflationOption
      ? Data.Annuities.annuityInflationProtectionNames[payout.inflationOption]
      : undefined
  );
  const [cashOrConvert, setCashOrConvert] = useState<string | undefined>(
    payout.cashOrConvert ?? undefined
  );
  const [open, setOpen] = useState<boolean>(false);
  const [warningMsg, setWarningMsg] = useState<string | undefined>(undefined);
  const personalInfo = Hooks.usePersonData() ?? {};
  const spouseInfo = Hooks.useSpouseData() ?? {};
  const includeSpouse = Hooks.useIncludeSpouse();
  const distOptionsList = Data.Annuities.AnnuityDistMethodOptions.filter((dist) => {
    if (includeSpouse) return true;
    return !dist.value.includes('Joint');
  });
  const today = dayjs();
  const currentYear = today.year();
  const currentAge = today.diff(
    payout.owner === 'me' ? personalInfo.birthDate : spouseInfo.birthDate,
    'year'
  );
  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === 'distOption') {
        setDistOption(Data.Annuities.annuityDistOptionNames[value.distOption]);
      } else if (name === 'inflationOption') {
        setInflationOption(Data.Annuities.annuityInflationProtectionNames[value.inflationOption]);
      } else if (name === 'cashOrConvert') {
        setCashOrConvert(value.cashOrConvert);
      } else if (name === 'colaAmtRate') {
        if (value.colaAmtRate > 4) setWarningMsg(t('AnnualRateIsHigh') as string);
        else setWarningMsg(undefined);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const onStartAgeChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const newStartAge = Number(event.target.value);
    const yearsInFuture = newStartAge - currentAge;
    setBeginYear(currentYear + yearsInFuture);
  };

  const onEndAgeChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const newEndAge = Number(event.target.value);
    const yearsInFuture = newEndAge - currentAge;
    setEndYear(currentYear + yearsInFuture);
  };

  return (
    <>
      <SelectInput<Data.Annuities.AnnuityFormData>
        error={formState.errors.distOption !== undefined}
        helperText={formState.errors.distOption?.message?.toString()}
        defaultValue={payout.distOption}
        name='distOption'
        label='WhatIsDistributionTypeOfAnnuity'
        placeholder='ChooseType'
        autoFocus>
        {distOptionsList.map((x) => (
          <MenuItem key={x.key} value={x.key}>
            {t(convertToTranslationKey(x.value))}
          </MenuItem>
        ))}
      </SelectInput>
      <Grid
        className='help-text'
        item
        display='flex'
        sx={{
          textDecoration: 'underline',
          cursor: 'pointer',
        }}
        onClick={() => setOpen(true)}>
        <Typography variant='p16' color='primary'>
          {t('WhatAreTheDiffDistTypes')}
        </Typography>
        <HelpOutline
          style={{
            color: theme.palette.primary.main,
            alignSelf: 'center',
          }}
        />
      </Grid>

      {distOption && (
        <>
          <Row>
            <TextInput<Data.Annuities.AnnuityFormData>
              error={formState.errors.annuityStartAge !== undefined}
              helperText={formState.errors.annuityStartAge?.message?.toString()}
              defaultValue={payout?.annuityStartAge}
              max={120}
              name='annuityStartAge'
              onChange={onStartAgeChanged}
              label={'AgePaymentsBegin'}
              type='integer'
              fieldStyle={{ width: '50%' }}
            />
            <Spacer width='sm' />

            <Grid
              sx={{
                width: '45%',
                border: `1px solid ${Colors.primaryLighter}`,
                borderRadius: '12px',
                padding: '12px 20px',
                flexDirection: 'column',
              }}>
              <Typography variant='p14Bold' color='secondary'>
                {t('Year')}
              </Typography>
              <Typography variant='p16' color='secondary'>
                {beginYear}
              </Typography>
            </Grid>
          </Row>

          {distOption.includes('Period') && (
            <Row>
              <TextInput<Data.Annuities.AnnuityFormData>
                error={formState.errors.annuityEndAge !== undefined}
                helperText={formState.errors.annuityEndAge?.message?.toString()}
                defaultValue={payout?.annuityEndAge}
                max={120}
                onChange={onEndAgeChanged}
                name='annuityEndAge'
                label={'AgePaymentsEnd'}
                type='integer'
                fieldStyle={{ width: '50%' }}
              />

              <Spacer width='sm' />

              <Grid
                sx={{
                  width: '45%',
                  border: `1px solid ${Colors.primaryLighter}`,
                  borderRadius: '12px',
                  padding: '12px 20px',
                }}>
                <Typography variant='p14Bold' color='secondary'>
                  {t('Year')}
                </Typography>
                <Typography variant='p16' color='secondary'>
                  {endYear}
                </Typography>
              </Grid>
            </Row>
          )}

          <Typography
            mt={1}
            className='subheader'
            color='secondary'
            component='h2'
            variant='p16Bold'>
            {t('PaymentDetails')}
          </Typography>

          <TextInput<Data.Annuities.AnnuityFormData>
            error={formState.errors.monthlyIncome !== undefined}
            helperText={formState.errors.monthlyIncome?.message?.toString()}
            defaultValue={payout?.monthlyIncome}
            name='monthlyIncome'
            label={'WhatIsTheMonthlyPayment'}
            type='dollar'
          />

          {distOption.includes('Joint') && (
            <SliderPercent<Data.Annuities.AnnuityFormData>
              error={formState.errors.survivorFraction !== undefined}
              helperText={formState.errors.survivorFraction?.message?.toString()}
              name='survivorFraction'
              label='WhatIsTheSurvivorBenefit'
              defaultValue={payout.survivorFraction}
              min={0}
              max={100}
              step={1}
              precision={0}
              type='percent'
              placeholder='0'
              inputProps={{
                onKeyDown: (e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    e.stopPropagation();
                  }
                  if (e.key === '.' || e.key === 'Decimal') {
                    e.preventDefault();
                  }
                },
              }}
            />
          )}

          <SelectInput<Data.Annuities.AnnuityFormData>
            error={formState.errors.inflationOption !== undefined}
            helperText={formState.errors.inflationOption?.message?.toString()}
            defaultValue={payout.id ? payout.inflationOption : undefined}
            name='inflationOption'
            label='DoAnyInflationProtectionFeaturesApply'
            placeholder='ChooseOption'
            helpContext={
              <HelpPopover title={t('InflationProtectionFeatures')}>
                <HelpPopoverText style={{ display: 'inline-block' }}>
                  {t('WhileMostAnnuitiesWontGuarantee')}
                </HelpPopoverText>
              </HelpPopover>
            }>
            {Data.Annuities.AnnuityInflationProtectionOptions.map((x) => (
              <MenuItem key={x.key} value={x.key}>
                {t(convertToTranslationKey(x.value))}
              </MenuItem>
            ))}
          </SelectInput>

          {inflationOption?.includes('Fixed') && (
            <SliderPercent<Data.Annuities.AnnuityFormData>
              error={formState.errors.colaAmtRate !== undefined}
              helperText={formState.errors.colaAmtRate?.message?.toString() ?? warningMsg}
              name='colaAmtRate'
              label='WhatIsTheAnnualRateIncrease'
              defaultValue={payout.colaAmtRate}
              min={0}
              max={4}
              disableTextInputMax
              step={0.1}
              precision={1}
              type='percent'
              placeholder='0'
              inputProps={{
                onKeyDown: (e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    e.stopPropagation();
                  }
                },
              }}
            />
          )}

          {inflationOption && (
            <>
              <ToggleRadioButtonGroup<Data.Annuities.AnnuityFormData>
                error={formState.errors.cashOrConvert !== undefined}
                helperText={formState.errors.cashOrConvert?.message?.toString()}
                label='PayCashOrConvertMoney'
                name='cashOrConvert'
                row
                defaultValue={payout?.cashOrConvert}
                items={[
                  { label: 'Cash', value: 'cash' },
                  { label: 'Converted', value: 'convert' },
                ]}
              />

              {cashOrConvert?.includes('convert') && (
                <TextInput<Data.Annuities.AnnuityFormData>
                  error={formState.errors.cost !== undefined}
                  helperText={formState.errors.cost?.message?.toString()}
                  defaultValue={payout?.cost}
                  name='cost'
                  label={'CostOfAnnuity'}
                  type='dollar'
                  helpContext={
                    <HelpPopover title={t('CostOfAnnuity')}>
                      <HelpPopoverText style={{ display: 'inline-block' }}>
                        {t('DoNotRecallHowMuchPaidForAnnuity')}
                      </HelpPopoverText>
                    </HelpPopover>
                  }
                />
              )}
            </>
          )}
        </>
      )}
      <WhatAreDistTypesDrawer open={open} onClose={() => setOpen(false)} />
    </>
  );
};
interface WhatAreDistTypesDrawerProps {
  open: boolean;
  onClose: () => void;
}
const WhatAreDistTypesDrawer: React.FC<WhatAreDistTypesDrawerProps> = ({ open, onClose }) => {
  return (
    <Drawer anchor='right' open={open} onClose={onClose}>
      <Box p={Spacing.xxs}>
        <Typography variant='p30Bold' color='primary'>
          {t('AnnuityDistributionTypes')}
        </Typography>
        <Typography mt={1} variant='p16' color='secondary'>
          {t('NotSureOfDistType')}
        </Typography>
        <Typography mt={2} variant='p18Bold' color='secondary'>
          {t('SingleLife')}
        </Typography>
        <Typography mt={1} variant='p16' color='secondary'>
          {t('SingleLifeDescription')}
        </Typography>
        <Typography mt={2} variant='p18Bold' color='secondary'>
          {t('JointAndSurvivor')}
        </Typography>
        <Typography mt={1} variant='p16' color='secondary'>
          {t('JointAndSurvivorDescription')}
        </Typography>
        <Typography mt={2} variant='p18Bold' color='secondary'>
          {t('JointLife')}
        </Typography>
        <Typography mt={1} variant='p16' color='secondary'>
          {t('JointLifeDescription')}
        </Typography>
        <Typography mt={2} variant='p18Bold' color='secondary'>
          {t('LifeWithPeriodCertain')}
        </Typography>
        <Typography mt={1} variant='p16' color='secondary'>
          {t('LifeWithPeriodCertainDescription')}
        </Typography>
        <Typography mt={2} variant='p18Bold' color='secondary'>
          {t('PeriodCertain')}
        </Typography>
        <Typography mt={1} variant='p16' color='secondary'>
          {t('PeriodCertainDescription')}
        </Typography>
        <Button
          sx={{ marginTop: '30px' }}
          label='Close'
          variant='outlined'
          color='primary'
          onClick={onClose}
        />
      </Box>
    </Drawer>
  );
};

export default AnnuityPayoutForm;
