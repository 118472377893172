import { Grid, Typography } from '@mui/material';
import { universitySchoolBrown } from '../../../../assets/png';
import { useTranslation } from 'react-i18next';
import { Hooks } from '@3nickels/data-modules';
import { NextLessonContent } from '../../../university/NextLessonCard';
import { Svgs } from '../../../../assets/svg';
import { Colors } from '../../../../themes';
import { useNavigate } from 'react-router-dom';

const UniversitySection: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const course = Hooks.useUniversityCourse();

  return (
    <Grid className='advice-section' item>
      <Typography className='section-header' variant='p18Bold' color='primary'>
        {t('3NickelsU')}
      </Typography>
      <Grid
        className='3NU-section'
        style={{
          background: `linear-gradient(${Colors.primaryLight} 0 0) bottom left / ${
            course?.progress ?? 0
          }% 8px no-repeat, white`,
        }}>
        <Grid className='content-section'>
          <Grid display='flex' flexDirection='column' alignItems='center' width='30%' rowGap='10px'>
            <img src={universitySchoolBrown} width='80%' />
            <Svgs.NickelsULogoSecondary width='60%' />
          </Grid>
          <NextLessonContent />
          <Typography
            className='clickable'
            variant='p12'
            color='primary'
            alignSelf='baseline'
            onClick={() => navigate('/learning/university')}>
            {t('ViewCourse')}
          </Typography>
        </Grid>
        <Typography variant='p12' color='primary' fontWeight='bold' my='20px'>
          {t('PercentComplete', { percentage: Math.round(course?.progress ?? 0) })}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default UniversitySection;
