import { Box, Stack } from '@mui/material';
import React from 'react';
import { Spacing } from '../../../../themes';
import { Data } from '@3nickels/data-modules';
import { useFormContext } from 'react-hook-form';
import TextInput from '../../../../components/form/TextInput';
import HelpPopover, { HelpPopoverText } from '../../../../components/HelpPopover';
import { t } from 'i18next';
import { Spacer } from '../../../../components';
import { Trans } from 'react-i18next';

export type RetirementPlanEmployerContributionsFormProps = {
  contributions?: Data.InvestmentAccounts.EmployerContributionsFormData;
  params: Data.InvestmentAccounts.InvestmentAccountWizardParams;
  onSubmit: (values: Data.InvestmentAccountBasicFormData) => Promise<void>;
};

const RetirementPlanEmployerContributionsForm: React.FC<
  RetirementPlanEmployerContributionsFormProps
> = ({ contributions, params }) => {
  const { formState } = useFormContext();

  return (
    <Box>
      <Stack spacing={Spacing.xxs}>
        <TextInput<Data.InvestmentAccounts.EmployerContributionsFormData>
          error={formState.errors.perDollarEmployerMatchRate !== undefined}
          helperText={formState.errors.perDollarEmployerMatchRate?.message?.toString()}
          defaultValue={contributions?.perDollarEmployerMatchRate}
          InputProps={{ readOnly: params.institutionalAccount }}
          label='PerDollarEmployerMatchRate'
          name='perDollarEmployerMatchRate'
          type='percent'
          precision={0}
          max={1000}
          helpContext={
            <HelpPopover title={t('PerDollarEmployerMatchRate')}>
              <HelpPopoverText>{t('YourEmployerWillMatch')}</HelpPopoverText>
              <Spacer height='xxs' />
              <HelpPopoverText>
                <i>{t('AnEmployerMightMatch50CentsForEveryDollar')}</i>
              </HelpPopoverText>
            </HelpPopover>
          }
        />
        <TextInput<Data.InvestmentAccounts.EmployerContributionsFormData>
          error={formState.errors.maxPercentEmployerMatch !== undefined}
          helperText={formState.errors.maxPercentEmployerMatch?.message?.toString()}
          defaultValue={contributions?.maxPercentEmployerMatch}
          InputProps={{ readOnly: params.institutionalAccount }}
          label='MaxPercentForMatch'
          name='maxPercentEmployerMatch'
          type='percent'
          precision={0}
          helpContext={
            <HelpPopover title={t('MaxPercentForMatch')}>
              <HelpPopoverText>{t('YourEmployersMatchMayBeCapped')}</HelpPopoverText>
              <Spacer height='xxs' />
              <HelpPopoverText>
                <i>{t('AnEmployerMightMatch50PercentUpTo5Percent')}</i>
              </HelpPopoverText>
              <Spacer height='xxs' />
              <HelpPopoverText>
                <div>
                  <Trans
                    i18nKey='IfYourEmployerDoesntSetAMaximum'
                    components={{
                      Styled: <strong />,
                    }}
                  />
                </div>
              </HelpPopoverText>
            </HelpPopover>
          }
        />
        {!params.institutionalAccount && (
          <TextInput<Data.InvestmentAccounts.EmployerContributionsFormData>
            error={formState.errors.maxDollarEmployerMatch !== undefined}
            helperText={formState.errors.maxDollarEmployerMatch?.message?.toString()}
            defaultValue={contributions?.maxDollarEmployerMatch}
            InputProps={{ readOnly: params.institutionalAccount }}
            label='MaxDollarAmountForMatch'
            name='maxDollarEmployerMatch'
            type='dollar'
            helpContext={
              <HelpPopover title={t('MaxDollarAmountForMatch')}>
                <HelpPopoverText>{t('YourEmployerMatchMayBeCappedDollar')}</HelpPopoverText>
                <Spacer height='xxs' />
                <HelpPopoverText>
                  <i>{t('AnEmployerMightMatch50PercentUpToDollars')}</i>
                </HelpPopoverText>
                <Spacer height='xxs' />
                <HelpPopoverText>
                  <div>
                    <Trans
                      i18nKey='IfYourEmployerDoesntSetAMaximum'
                      components={{
                        Styled: <strong />,
                      }}
                    />
                  </div>
                </HelpPopoverText>
              </HelpPopover>
            }
          />
        )}
        <TextInput<Data.InvestmentAccounts.EmployerContributionsFormData>
          error={formState.errors.otherEmployerContrib !== undefined}
          helperText={formState.errors.otherEmployerContrib?.message?.toString()}
          defaultValue={contributions?.otherEmployerContrib}
          InputProps={{ readOnly: params.institutionalAccount }}
          label='FixedEmployerContibutionsOrProfitSharing'
          name='otherEmployerContrib'
          type='percent'
          precision={0}
          helpContext={
            <HelpPopover title={t('FixedEmployerContibutionsOrProfitSharing')}>
              <Spacer height='xxxs' />
              <HelpPopoverText>
                <strong>{t('FixedEmployerContribution')}</strong>
              </HelpPopoverText>
              <Spacer height='xxxs' />
              <HelpPopoverText>{t('YourEmployerMayContributeAFixedAmount')}</HelpPopoverText>
              <Spacer height='xxs' />
              <HelpPopoverText>
                <strong>{t('ProfitSharing')}</strong>
              </HelpPopoverText>
              <Spacer height='xxxs' />
              <HelpPopoverText>{t('SomeEmployersShareAPortionOfProfits')}</HelpPopoverText>
              <Spacer height='xxs' />
              <HelpPopoverText>
                <div>
                  <Trans
                    i18nKey='IfYourEmployerDoesntOfferThese'
                    components={{
                      Styled: <strong />,
                    }}
                  />
                </div>
              </HelpPopoverText>
            </HelpPopover>
          }
        />
      </Stack>
    </Box>
  );
};

export default RetirementPlanEmployerContributionsForm;
