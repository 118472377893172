import { Grid, IconButton, Typography } from '@mui/material';
import React, { useState } from 'react';
import { ChatData, ChatMetadata } from '@3nickels/data-modules/lib/data/gpt/chat';
import CloseIcon from '@mui/icons-material/Close';
import { t } from 'i18next';
import { Colors } from '../../themes';

export interface ChatHistoryItemProps {
  chatMetadata: ChatMetadata;
  chat: ChatData;
  disableInteractions: boolean;
  handleChatMetadataClick: (chatMetadata: ChatMetadata) => void;
  handleDeleteChatClick: (chatMetadata: ChatMetadata) => void;
}

const ChatHistoryItem: React.FC<ChatHistoryItemProps> = ({
  chatMetadata,
  chat,
  disableInteractions,
  handleChatMetadataClick,
  handleDeleteChatClick,
}) => {
  const [hovered, setHovered] = useState(false);

  const formatChatTime = () => {
    if (!chatMetadata.mostRecentMessageTimestamp) return '';

    const date = chatMetadata.mostRecentMessageTimestamp;

    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    const isToday = date.toDateString() === today.toDateString();
    const isYesterday = date.toDateString() === yesterday.toDateString();

    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear().toString().slice(-2);
    const hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHour = hours % 12 || 12;

    const formattedTime = `${formattedHour}:${minutes} ${ampm}`;
    if (isToday) {
      return `${t('Today')} | ${formattedTime}`;
    } else if (isYesterday) {
      return `${t('Yesterday')} | ${formattedTime}`;
    }
    return `${month}/${day}/${year} | ${formattedTime}`;
  };

  return (
    <Grid
      container
      direction='row'
      width='100%'
      flexWrap='nowrap'
      justifyContent={'space-between'}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      style={{
        paddingLeft: '20px',
        paddingRight: '20px',
      }}>
      <Grid
        container
        display='flex'
        direction='row'
        sx={{
          padding: '5px',
          justifyContent: 'space-between',
          borderRadius: '10px',
          backgroundColor: chatMetadata.id === chat.id ? `${Colors.secondaryLighter}` : '',
        }}>
        <Grid item xs display='flex' direction='column' overflow='hidden'>
          <Typography
            variant='p16Bold'
            onClick={() => !disableInteractions && handleChatMetadataClick(chatMetadata)}
            color={chatMetadata.id === chat.id ? 'secondary' : 'primary'}
            sx={{
              'overflow': 'hidden',
              'textOverflow': 'ellipsis',
              'whiteSpace': 'nowrap',
              'display': 'block',
              'cursor': disableInteractions ? 'default' : 'pointer',
              '&:hover': {
                textDecoration: disableInteractions ? '' : 'underline',
              },
            }}>
            {chatMetadata.title}
          </Typography>
          <Typography variant='p12' color={chatMetadata.id === chat.id ? 'secondary' : 'primary'}>
            {formatChatTime()}
          </Typography>
        </Grid>
        {hovered && !disableInteractions && (
          <IconButton
            size='small'
            onClick={(e) => {
              e.stopPropagation();
              handleDeleteChatClick(chatMetadata);
            }}
            sx={{
              flexShrink: 0,
            }}>
            <CloseIcon />
          </IconButton>
        )}
      </Grid>
    </Grid>
  );
};

export default ChatHistoryItem;
