import { Domain, Api, Data } from '@3nickels/data-modules';

export interface IncomeFormData {
  annualIncome?: number;
  otherAnnualIncome?: number;
  spouseAnnualIncome?: number;
  spouseAnnualOtherIncome?: number;
  unearnedIncome?: number;
}

export interface OtherSavingsFormData {
  otherSavings?: number;
}

export interface GivingFormData {
  cashDonations?: number;
  gifts?: number;
  otherGifts?: number;
}

// should these be moved to dm?
export function mapIncomeFormToIncomeRest(
  mode: BudgetDetailsMode,
  selfData: Domain.SelfData,
  data: IncomeFormData
): Api.IncomeRest {
  return {
    accountId: selfData.accountId,
    personId: selfData.personId,
    income: Data.Budget.convertMonthlyOrAnnual('annual', mode, data.annualIncome),
    otherIncome: Data.Budget.convertMonthlyOrAnnual('annual', mode, data.otherAnnualIncome),
    unearnedIncome: Data.Budget.convertMonthlyOrAnnual('annual', mode, data.unearnedIncome),
    ...(selfData.includeSpouse === true
      ? {
          spouseIncome: Data.Budget.convertMonthlyOrAnnual('annual', mode, data.spouseAnnualIncome),
          spouseOtherIncome: Data.Budget.convertMonthlyOrAnnual(
            'annual',
            mode,
            data.spouseAnnualOtherIncome
          ),
        }
      : {}),
  };
}

export declare type BudgetDetailsMode = 'monthly' | 'annual';

export interface BudgetSectionProps {
  mode: BudgetDetailsMode;
  budget?: Api.BudgetRest;
}

export interface UtilityFormData {
  electricity?: number;
  water?: number;
  cellPhone?: number;
  internet?: number;
  naturalGas?: number;
  trash?: number;
  otherUtilities?: number;
}

export interface TransportFormData {
  transportation?: number;
  carInsurance?: number;
  fuel?: number;
  carMaintenance?: number;
  otherTransportation?: number;
}

export interface FoodFormData {
  grocery?: number;
  restaurants?: number;
  otherFood?: number;
}

export interface MiscFormData {
  entertainment?: number;
  otherExpenses?: number;
}

export function mapUtilitiesFormToMonthlySpendingRest(
  mode: BudgetDetailsMode,
  data: UtilityFormData
): Api.MonthlySpendingRest {
  return {
    electricity: Data.Budget.convertMonthlyOrAnnual('monthly', mode, data.electricity),
    water: Data.Budget.convertMonthlyOrAnnual('monthly', mode, data.water),
    cellPhone: Data.Budget.convertMonthlyOrAnnual('monthly', mode, data.cellPhone),
    internet: Data.Budget.convertMonthlyOrAnnual('monthly', mode, data.internet),
    naturalGas: Data.Budget.convertMonthlyOrAnnual('monthly', mode, data.naturalGas),
    trash: Data.Budget.convertMonthlyOrAnnual('monthly', mode, data.trash),
    otherUtilities: Data.Budget.convertMonthlyOrAnnual('monthly', mode, data.otherUtilities),
  };
}

export function mapTransportFormToMontlySpendingRest(
  mode: BudgetDetailsMode,
  data: TransportFormData
): Api.MonthlySpendingRest {
  return {
    otherTransportation: Data.Budget.convertMonthlyOrAnnual(
      'monthly',
      mode,
      data.otherTransportation
    ),
    transportation: Data.Budget.convertMonthlyOrAnnual('monthly', mode, data.transportation),
    carMaintenance: Data.Budget.convertMonthlyOrAnnual('monthly', mode, data.carMaintenance),
    carInsurance: Data.Budget.convertMonthlyOrAnnual('monthly', mode, data.carInsurance),
    fuel: Data.Budget.convertMonthlyOrAnnual('monthly', mode, data.fuel),
  };
}

export function mapFoodFormToMonthlySpendingRest(
  mode: BudgetDetailsMode,
  data: FoodFormData
): Api.MonthlySpendingRest {
  return {
    grocery: Data.Budget.convertMonthlyOrAnnual('monthly', mode, data.grocery),
    restaurants: Data.Budget.convertMonthlyOrAnnual('monthly', mode, data.restaurants),
    otherFood: Data.Budget.convertMonthlyOrAnnual('monthly', mode, data.otherFood),
  };
}

export function mapMiscFormToMonthlySpendingRest(
  mode: BudgetDetailsMode,
  data: MiscFormData
): Api.MonthlySpendingRest {
  return {
    entertainment: Data.Budget.convertMonthlyOrAnnual('monthly', mode, data.entertainment),
    otherExpenses: Data.Budget.convertMonthlyOrAnnual('monthly', mode, data.otherExpenses),
  };
}

export function mapGivingFormToMontlySpendingRest(
  mode: BudgetDetailsMode,
  data: GivingFormData
): Api.MonthlySpendingRest {
  return {
    cashDonations: Data.Budget.convertMonthlyOrAnnual('monthly', mode, data.cashDonations),
    gifts: Data.Budget.convertMonthlyOrAnnual('monthly', mode, data.gifts),
    otherGifts: Data.Budget.convertMonthlyOrAnnual('monthly', mode, data.otherGifts),
  };
}

export const calcContributions = (plan: Domain.PlanData, income: number): number => {
  const annualAfterTax =
    plan.contribMethod === Domain.EligibleContributionTypeEnum.Dollar
      ? plan.annualContribAfterTaxDollar ?? 0
      : (income * (plan.annualContribAfterTaxPercent ?? 0)) / 100;

  const annualPreTax =
    plan.contribMethod === Domain.EligibleContributionTypeEnum.Dollar
      ? plan.annualContribPreTaxDollar ?? 0
      : (income * (plan.annualContribPreTaxPercent ?? 0)) / 100;

  const annualRoth =
    plan.contribMethod === Domain.EligibleContributionTypeEnum.Dollar
      ? plan.annualContribRothDollar ?? 0
      : (income * (plan.annualContribRothPercent ?? 0)) / 100;

  return annualAfterTax + annualPreTax + annualRoth;
};

export const calcHousingPayment = (owned: Domain.HousingData): number => {
  return (
    (owned.debt?.minimumPayment ? owned.debt?.minimumPayment * 12 : 0) +
    (owned.monthlyInsurance ? owned.monthlyInsurance * 12 : 0) +
    (owned.monthlyHoaFees ? owned.monthlyHoaFees * 12 : 0) +
    (owned.annualHomeRepairCosts ? owned.annualHomeRepairCosts : 0) +
    (owned.annualPropertyTax ? owned.annualPropertyTax : 0) +
    (owned.annualOtherHousingCosts ? owned.annualOtherHousingCosts : 0)
  );
};

export const getAdjustedHousingPayment = (
  home: Domain.HousingData,
  mode: BudgetDetailsMode
): number => {
  return Data.Budget.adjustAnnualAmount(mode, calcHousingPayment(home));
};

export const calcRentPayment = (rental: Domain.RentData): number => {
  return (
    (rental.monthlyPayment ? rental.monthlyPayment : 0) +
    (rental.monthlyInsurance ? rental.monthlyInsurance : 0)
  );
};

export const getAdjustedRentPayment = (
  rental: Domain.RentData,
  mode: BudgetDetailsMode
): number => {
  return Data.Budget.adjustMonthlyAmount(mode, calcRentPayment(rental));
};

// income minus cost excluding debt payment
export const calcNetRealEstateIncome = (realEstate: Domain.RealEstateData): number => {
  const income = (realEstate.monthlyRentIncome ?? 0) * 12;
  const costs =
    (realEstate.monthlyInsurance ? realEstate.monthlyInsurance * 12 : 0) +
    (realEstate.monthlyHoaFees ? realEstate.monthlyHoaFees * 12 : 0) +
    (realEstate.annualHomeRepairCosts ? realEstate.annualHomeRepairCosts : 0) +
    (realEstate.annualPropertyTax ? realEstate.annualPropertyTax : 0) +
    (realEstate.annualOtherHousingCosts ? realEstate.annualOtherHousingCosts : 0);
  return income - costs;
};

export const getAdjustedNetRealEstateIncome = (
  realEstate: Domain.RealEstateData,
  mode: BudgetDetailsMode
): number => {
  return Data.Budget.adjustAnnualAmount(mode, calcNetRealEstateIncome(realEstate));
};

export const calcRealEstateDebtPayment = (owned: Domain.HousingData): number => {
  return (
    (owned.debt?.minimumPayment ? owned.debt?.minimumPayment * 12 : 0) +
    (owned.monthlyInsurance ? owned.monthlyInsurance * 12 : 0) +
    (owned.monthlyHoaFees ? owned.monthlyHoaFees * 12 : 0) +
    (owned.annualHomeRepairCosts ? owned.annualHomeRepairCosts : 0) +
    (owned.annualPropertyTax ? owned.annualPropertyTax : 0) +
    (owned.annualOtherHousingCosts ? owned.annualOtherHousingCosts : 0)
  );
};

export const getAdjustedRealEstateDebtPayment = (
  realEstate: Domain.HousingData,
  mode: BudgetDetailsMode
): number => {
  return Data.Budget.adjustAnnualAmount(mode, calcRealEstateDebtPayment(realEstate));
};

export const getAdjustedCarLoanPayment = (
  carData: Domain.CarData,
  mode: BudgetDetailsMode
): number => {
  return Data.Budget.adjustMonthlyAmount(mode, carData.carLoan?.monthlyPayment);
};
