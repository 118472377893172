import { useCallback, useEffect } from 'react';
import { useNavigate, useOutletContext } from 'react-router';
import { Grid } from '@mui/material';
import { Hooks } from '@3nickels/data-modules';
import { LayoutContext, withLayoutMeta } from '../../../../../types/LayoutMeta';
import { MedicalBenefitsContent } from '../MedicalBenefitsContent';
import CardButton from '../components/CardButton';

const StepTwoWrapper: React.FC = () => {
  const navigate = useNavigate();
  const { meta, setMeta } = useOutletContext<LayoutContext>();
  const { significantRoutineMedicalCare, calculateSuggestedBenefits } =
    Hooks.useMedicalBenefitsContext();

  const onSubmit = useCallback(() => {
    if (significantRoutineMedicalCare) {
      updateContext(true);
    } else {
      updateContext(false);
    }
  }, [significantRoutineMedicalCare]);

  const updateContext = async (saveToApi: boolean) => {
    try {
      if (saveToApi) {
        calculateSuggestedBenefits(2);
        navigate('/tools/medical/medical-benefit-selector-tool/summary');
      } else {
        navigate('/tools/medical/medical-benefit-selector-tool/3');
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    setMeta(() => ({
      stepNumber: 2,
      showNext: true,
      onNext: onSubmit,
      disableNextButton: significantRoutineMedicalCare === undefined,
    }));
  }, [significantRoutineMedicalCare]);

  const MedicalBenfitsStepTwo = withLayoutMeta(MedicalBenefitsSelectorStepTwo, meta);
  return <MedicalBenfitsStepTwo />;
};

const MedicalBenefitsSelectorStepTwo: React.FC = () => {
  const { significantRoutineMedicalCare, setSignificantRoutineMedicalCare } =
    Hooks.useMedicalBenefitsContext();

  const onClickYes = () => {
    if (significantRoutineMedicalCare) {
      setSignificantRoutineMedicalCare(undefined);
    } else {
      setSignificantRoutineMedicalCare(true);
    }
  };

  const onClickNo = () => {
    if (significantRoutineMedicalCare === undefined || significantRoutineMedicalCare) {
      setSignificantRoutineMedicalCare(false);
    } else {
      setSignificantRoutineMedicalCare(undefined);
    }
  };

  return (
    <MedicalBenefitsContent
      questionNumber={2}
      questionTitle={'DoYouExpectFrequentHealthcare'}
      questionContent={
        <Grid display='flex' flexDirection='row' width='100%'>
          <Grid container display='flex' flexDirection='column' width='100%' alignItems='center'>
            <CardButton
              checked={significantRoutineMedicalCare}
              onClick={onClickYes}
              label={'Yes'}
            />
            <CardButton
              checked={significantRoutineMedicalCare === false}
              onClick={onClickNo}
              label={'No'}
            />
          </Grid>
        </Grid>
      }
    />
  );
};

export default StepTwoWrapper;
