/* eslint-disable @typescript-eslint/no-explicit-any */
import { Alignments, Colors } from '../themes';
import { Button, ButtonProps } from './buttons/Button';
import { Check } from '@mui/icons-material';
import React from 'react';
import { Card, CardHeader, CardProps, Grid, Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Svgs } from '../assets/svg';
import { MenuLink, PopoverDropdown } from './menu/DropDownMenu';

export type AdornmentVariant = 'Button' | 'Checkmark' | undefined;

export interface LabelProps {
  label: string;
  color: string;
}

export interface CheckmarkAdornment {
  color?: string;
  size?: 'small' | 'medium' | 'large';
}

const Item = styled(Paper)(({ color }) => ({
  color: color ?? '#000',
  boxShadow: 'none',
}));

export interface StatusCardProps {
  icon?: React.ReactNode;
  label: LabelProps | string;
  subLabel?: LabelProps | string;
  disabled?: boolean;
  endAdornmentVariant?: AdornmentVariant;
  endAdornmentProps?: ButtonProps | CheckmarkAdornment;
  containerProps?: CardProps;
  menuLinks?: MenuLink[];
}

const StatusCard: React.FC<StatusCardProps> = (props) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<undefined | HTMLElement>(undefined);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(undefined);
  };

  const endAdornment = () => {
    switch (props.endAdornmentVariant) {
      case 'Button':
        return (
          <Grid item minWidth={{ sm: 173 }}>
            <Button
              {...(props.endAdornmentProps as ButtonProps)}
              onMouseEnter={(e) => e.stopPropagation()}
              fullWidth
              disabled={props.disabled}
            />
          </Grid>
        );
      case 'Checkmark':
        return (
          <Grid item sx={[Alignments.verticalAlignCenter]} width={40} height={50}>
            <Check
              fontSize={props.endAdornmentProps?.size}
              htmlColor={props.endAdornmentProps?.color}
            />
          </Grid>
        );
      default:
        return undefined;
    }
  };

  const labelProp: LabelProps =
    typeof props.label === 'string' ? { label: props.label, color: 'primary' } : props.label;

  const subLabelProp: LabelProps | undefined =
    props.subLabel && typeof props.subLabel === 'string'
      ? { label: props.subLabel, color: Colors.errorBase }
      : (props.subLabel as LabelProps | undefined);

  return (
    <Card
      {...props.containerProps}
      className='rectangle-panel'
      variant={props.containerProps?.raised ? 'elevation' : 'outlined'}>
      <CardHeader
        className='status-card'
        disableTypography
        title={
          <Grid container display='flex' alignItems='center'>
            <Typography
              component='h3'
              variant='h6'
              color={props.disabled ? Colors.disabledDark : labelProp.color}>
              {t(labelProp.label)}
            </Typography>
            {props.menuLinks && props.menuLinks.length > 0 && (
              <Grid
                item
                className='show-on-hover'
                display='flex'
                marginLeft='10px'
                sx={{ cursor: 'pointer' }}>
                <Svgs.ActionSmallEllipsis onClick={(e) => handleMenu(e as any)} />

                <PopoverDropdown
                  horizontalPosition={-50}
                  onClose={handleClose}
                  element={anchorEl}
                  menuLinks={props.menuLinks}
                />
              </Grid>
            )}
          </Grid>
        }
        subheader={
          <Item color={props.disabled ? Colors.disabledDark : subLabelProp?.color}>
            <Typography variant='body2' gutterBottom>
              {subLabelProp ? t(subLabelProp.label) : ''}
            </Typography>
          </Item>
        }
        avatar={props.icon}
        action={endAdornment()}
      />
    </Card>
  );
};

export default StatusCard;
