import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Data, Domain, Hooks } from '@3nickels/data-modules';
import { Box, Grid, Typography } from '@mui/material';
import GroupPanels, { Group } from '../../components/GroupPanels';
import { Svgs } from '../../assets/svg';
import { Spacing } from '../../themes';
import { LayoutMeta, withLayoutMeta } from '../../types/LayoutMeta';
import { AccountSettingsLayoutMeta } from './AccountSettingsLayout';
import { targetEnvs } from '../../services/accountSettings';
import { useMutation, useSubject } from '@aesop-fables/scrinium';
import { domain } from '../../configuration/Environment';

export const resolveShowManagePayments = (
  orgPaysPricebook: Domain.PricebookBundle | undefined,
  addOns: boolean,
  orgOnlyOffersFreePricebook: boolean,
  subscriptionInfoData: Domain.SubscriptionInfo[] | undefined
) => {
  const orgWithNoAddOns = orgPaysPricebook && !addOns;
  const orgWithAddOns = orgPaysPricebook && addOns;
  const noSubscriptionHistory = !subscriptionInfoData || subscriptionInfoData.length === 0;
  if (orgWithNoAddOns) {
    return false;
  } else if (orgOnlyOffersFreePricebook) {
    return false;
  } else if (noSubscriptionHistory && !orgWithAddOns) {
    return false;
  } else {
    return true;
  }
};

const AccountSettingsView: React.FC = () => {
  const { t } = useTranslation();
  const commands = Hooks.useCommands();
  const subscriptionInfoData = Hooks.useSubscriptionInfo();
  const authMethods = Hooks.useTwoFactorMethods();
  const { orgOnlyOffersFreePricebook, orgPaysPricebook } = Hooks.useAvailableProducts();
  const { productType } = Hooks.usePrincipalUser() ?? Domain.ProductTypeEnum.free;
  const { adviceOnboardingComplete } = Hooks.useOnboardingInfo() ?? {
    adviceOnboardingComplete: false,
    onboardingComplete: false,
  };
  const demoAccount = useSubject(Data.Accounts.IsDemoAccountQuery, {});
  const resetAdviceOnboarding = useMutation(new Data.Users.Mutations.ResetAdviceOnboarding());
  const completeAdviceOnboarding = useMutation(new Data.Users.Mutations.CompleteAdviceOnboarding());

  const addOns = false;
  const showManagePayments = resolveShowManagePayments(
    orgPaysPricebook,
    addOns,
    orgOnlyOffersFreePricebook,
    subscriptionInfoData
  );
  const showPasswordAnd2fa = useMemo(() => (authMethods?.length ?? 0) > 0, [authMethods]);
  const enableProductSwitch = targetEnvs.some((env) => domain.includes(env)) || demoAccount;
  const newProduct =
    productType === Domain.ProductTypeEnum.free
      ? Domain.ProductTypeEnum.advice
      : Domain.ProductTypeEnum.free;

  const accountSettingsOptions: Group[] = [];

  accountSettingsOptions.push(
    {
      textKey: 'ManageSubscription',
      subTextKey: 'ManageSubscriptionSubtext',
      icon: <Svgs.DisplaySmallCustomCreditCard2 />,
      url: '/settings/manage-subscription',
    },
    {
      textKey: 'Alerts',
      subTextKey: 'AlertsSubtext',
      icon: <Svgs.DisplaySmallCustomAlerts />,
      url: '/settings/alerts',
    }
  );
  if (showPasswordAnd2fa) {
    accountSettingsOptions.push(
      {
        textKey: 'UpdatePassword',
        subTextKey: 'UpdatePasswordSubtext',
        icon: <Svgs.DisplaySmallCustomLock />,
        url: '/settings/update-password',
      },
      {
        textKey: 'TwoFactorAuth',
        subTextKey: 'TwoFactorAuthenticationSubtext',
        icon: <Svgs.DisplaySmallCustomShield />,
        url: '/settings/two-factor-authentication',
      }
    );
  }

  accountSettingsOptions.push(
    {
      textKey: 'Legal',
      subTextKey: 'LegalSubtext',
      icon: <Svgs.DisplaySmallCustomScale />,
      url: '/settings/legal',
    },
    {
      textKey: 'IconAuthors',
      subTextKey: 'IconAuthorsSubtext',
      icon: <Svgs.DisplaySmallCustomBook />,
      url: '/settings/icon-authors',
    }
  );

  if (showManagePayments) {
    accountSettingsOptions.splice(1, 0, {
      textKey: 'ManagePayments',
      subTextKey: 'ManagePaymentsSubtext',
      icon: <Svgs.DisplaySmallCustomWallet />,
      url: '/settings/manage-payments',
    });
  }

  if (enableProductSwitch) {
    accountSettingsOptions.push({
      textKey: productType === Domain.ProductTypeEnum.free ? 'SwitchToAdvice' : 'SwitchToFree',
      url: ' ',
      onClick: async () => {
        await commands.execute(Data.Accounts.Commands.ChangeProductType, {
          productType: newProduct,
        });
      },
    });

    if (productType === Domain.ProductTypeEnum.advice)
      accountSettingsOptions.push({
        textKey: adviceOnboardingComplete ? 'BackToOnboarding' : 'CompleteOnboarding',
        url: ' ',
        onClick: async () =>
          adviceOnboardingComplete
            ? await resetAdviceOnboarding.action()
            : await completeAdviceOnboarding.action(),
      });
  }

  return (
    <Box>
      <Grid container flexDirection='column'>
        <Typography variant='p40Bold' component='h1' color='primary'>
          {t('Settings')}
        </Typography>
        <Grid container pt={Spacing.xxxs}>
          <GroupPanels variant='Popover' groups={accountSettingsOptions} />
        </Grid>
      </Grid>
    </Box>
  );
};

const meta = {
  showBack: false,
} satisfies LayoutMeta<AccountSettingsLayoutMeta>;

export default withLayoutMeta(AccountSettingsView, meta);
