import { Dispatch, SetStateAction } from 'react';
import { Grid, Typography } from '@mui/material';
import { t } from 'i18next';
import { Modal, Spacer } from '../../components';
import { convertToTranslationKey } from '../../helpers/utilityFunctions';

interface RemoveAccountModalProps {
  name: string | undefined;
  type: string | undefined;
  variant?: 'Car' | 'Debt' | 'Home' | 'Investment';
  modalOpen: boolean;
  setModalOpen: Dispatch<SetStateAction<boolean>>;
  handleSave: () => void | undefined;
}

const getText = (variant: string | undefined) => {
  let title = t('RemoveAccount?');
  let subTitle = t('ConfirmRemoveAccount');
  let primaryButtonText = t('NoKeepAccount');
  let secondaryButtonText = t('YesRemoveAccount');

  if (typeof variant !== 'undefined') {
    if (variant === 'Investment') {
      subTitle = t('ThisWillRemoveAllTheInfoForThisInvestment');
    } else {
      title = t('RemoveAccountType', { type: variant });
      primaryButtonText = t('NoKeepAccountType', { type: variant });
      secondaryButtonText = t('YesRemoveAccountType', { type: variant });
    }
  }

  return { title, subTitle, primaryButtonText, secondaryButtonText };
};

const RemoveAccountModal: React.FC<RemoveAccountModalProps> = ({
  name,
  type,
  variant,
  modalOpen,
  setModalOpen,
  handleSave,
}) => {
  const { title, subTitle, primaryButtonText, secondaryButtonText } = getText(variant);
  const accountType = type ?? '';
  const accountTypeLabel =
    accountType.includes('Rollover') || accountType.includes('Deductible')
      ? convertToTranslationKey(accountType)
      : accountType.replace(/ /g, '');

  return (
    <Grid display='none'>
      <Modal
        title={title.toString()}
        primaryButtonText={primaryButtonText.toString()}
        secondaryButtonText={secondaryButtonText.toString()}
        swapButtonFunctionality
        open={modalOpen}
        setOpen={setModalOpen}
        handleSave={handleSave}>
        <Typography variant='p16' color='secondary'>
          {subTitle.toString()}
        </Typography>
        <Spacer height='xs' />
        <Typography variant='p16Bold' color='secondary'>
          {name}
        </Typography>
        <Typography variant='p16Bold' color='secondary'>
          {t(accountTypeLabel)}
        </Typography>
      </Modal>
    </Grid>
  );
};

export default RemoveAccountModal;
