import { Grid, Typography } from '@mui/material';
import { MobileScreenWrapper } from './MobileScreenWrapper';
import { useTranslation } from 'react-i18next';
import { Domain, Hooks } from '@3nickels/data-modules';
import SubscriptionSection from '../settings/manage-payments/SubscriptionSection';
import { useService } from '@aesop-fables/containr-react';
import { PaymentApi, PaymentMethodsData } from '../../api/apis/PaymentApi';
import { ApiKeys } from '../../api/apis/ApiKeys';
import { Svgs } from '../../assets/svg';
import PaymentMethodsSection from '../settings/manage-payments/PaymentMethodsSection';
import { useState } from 'react';
import { useDefaultPaymentMethod } from '../../hooks/payment/useDefaultPaymentMethod';
import { usePaymentHistory } from '../../hooks/payment/usePaymentHistory';
import { usePaymentMethods } from '../../hooks/payment/usePaymentMethods';
import { usePaymentMutations } from '../../hooks/useMutations/usePaymentMutations';
import PaymentHistorySection from '../settings/manage-payments/PaymentHistorySection';
import { useNavigate } from 'react-router-dom';
import { useReceiptUrl } from '../../hooks/payment/useReceiptUrl';

export const MobileManagePaymentsView: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const paymentApi = useService<PaymentApi>(ApiKeys.Payment);
  const subscriptionInfoData = Hooks.useSubscriptionInfo();
  const { threeNickelsProducts } = Hooks.useAvailableProducts();
  const stripeCustomer = subscriptionInfoData?.some(
    (sub) => sub.origin === Domain.PaymentOriginEnum.STRIPE && sub.paidBy === Domain.PaidByEnum.USER
  );
  const paymentMethodsData = usePaymentMethods();
  const defaultPaymentMethod = useDefaultPaymentMethod();
  const stripeTransactionsData = usePaymentHistory();
  const { viewReceipt } = useReceiptUrl();
  const [activeMethod, setActiveMethod] = useState<PaymentMethodsData>();
  const { setDefaultPaymentMethod, removePaymentMethod } = usePaymentMutations(activeMethod);

  const onAddPayment = async () => {
    const setupIntentUrl = await paymentApi.addPaymentMethod();
    window.location.replace(setupIntentUrl.data);
  };

  return (
    <MobileScreenWrapper>
      <Grid mb={2} display='flex' direction='row' sx={{ alignItems: 'center' }}>
        <Svgs.IconArrowLeft onClick={() => navigate('/')} />
        <Typography ml={1} color='primary' variant='p22Bold'>
          {t('ManagePayments')}
        </Typography>
      </Grid>
      <Typography mb={1} color='secondary' variant='p18Bold'>
        {t('Subscription')}
      </Typography>
      <SubscriptionSection
        subscriptionInfoData={subscriptionInfoData}
        threeNickelsProducts={threeNickelsProducts}
      />

      <Grid
        container
        mt={3}
        mb={1}
        flexDirection='row'
        justifyContent='space-between'
        alignItems='center'>
        <Typography variant='p18Bold' color='secondary'>
          {t('PaymentMethods')}
        </Typography>
        {stripeCustomer && <Svgs.ActionSmallPlus onClick={onAddPayment} />}
      </Grid>

      <PaymentMethodsSection
        paymentMethodsData={paymentMethodsData}
        defaultPaymentMethod={defaultPaymentMethod}
        setDefaultPaymentMethod={setDefaultPaymentMethod}
        removePaymentMethod={removePaymentMethod}
        setActiveMethod={setActiveMethod}
      />

      <Typography mt={3} mb={1} variant='p18Bold' color='secondary'>
        {t('PaymentHistory')}
      </Typography>
      <PaymentHistorySection
        stripeTransactionsData={stripeTransactionsData}
        subscriptionInfoData={subscriptionInfoData}
        onViewReceipt={viewReceipt}
      />
    </MobileScreenWrapper>
  );
};
