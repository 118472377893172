import { t } from 'i18next';
import { ChartPieVariableRadius } from '../../../components/ChartPieVariableRadius';
import { formatWholeDollars } from '../../../helpers/utilityFunctions';
import { Colors } from '../../../themes';

export class PieChartData {
  label?: string;
  amount?: number;
}

interface SaveSpendGivePieChartProps {
  data: PieChartData[];
  colors: string[];
  leftoverAmount: number;
  showSkeleton: boolean;
}

export const defaultValues = [
  { label: 'Save', amount: 400 },
  { label: 'Spend', amount: 200 },
  { label: 'Give', amount: 400 },
];

export const SaveSpendGivePieChart: React.FC<SaveSpendGivePieChartProps> = ({
  data,
  colors,
  leftoverAmount,
  showSkeleton,
}) => {
  return (
    <ChartPieVariableRadius
      data={data.map((slice, index) => ({
        x: t(slice.label ?? '') as string,
        y: slice.amount ?? 0,
        color: colors[index],
      }))}
      innerRadius={80}
      innerLabelStyle={[
        {
          fontSize: 18,
          fill: leftoverAmount < 0 ? Colors.errorBase : Colors.secondaryBase,
          fontFamily: 'Roboto',
          fontWeight: 'bold',
        },
        {},
        { fontSize: 12, fill: Colors.primaryBase, fontFamily: 'Roboto' },
      ]}
      innerLabelText={
        showSkeleton
          ? []
          : [`${formatWholeDollars(leftoverAmount)}/${t('mo')}`, '\u200B', t('AmountLeftover')]
      }
      tooltipText={({ datum }) => {
        return [`${datum.x}`, '\u200B', `${formatWholeDollars(datum.y)}/${t('mo')}`];
      }}
      showSkeleton={showSkeleton}
    />
  );
};
