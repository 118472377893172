/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo, useState } from 'react';
import { Data, Domain, Hooks, formatWholeDollars } from '@3nickels/data-modules';
import EmbeddedList from '../../../../../components/EmbeddedList';
import {
  BudgetSectionProps,
  calcHousingPayment,
  getAdjustedHousingPayment,
  getAdjustedRentPayment,
} from '../../../Types';
import { Svgs } from '../../../../../assets/svg';
import { CollapsiblePanel } from '../../../../../components/CollapsiblePanel';
import { Box, Divider, Drawer, Grid } from '@mui/material';
import { Colors, Spacing } from '../../../../../themes';
import { LeaveBudgetDetailsModal } from '../../../BudgetDetailsLayout';
import { useNavigate } from 'react-router';
import { HomeRentEditViewWrapper } from './HomeRentEditView';
import { useMutation } from '@aesop-fables/scrinium';
import { useMessage } from '../../../../../hooks/useMessage';
import { SpendingProps } from '../../Types';
import { t } from 'i18next';
import RemoveAccountModal from '../../../../account-details/RemoveAccountModal';

export const HomeSpendingSection: React.FC<BudgetSectionProps & SpendingProps> = ({
  mode,
  spendingSummary,
}) => {
  const navigate = useNavigate();
  const houses = Hooks.useCombinedHousingData().filter((house) => calcHousingPayment(house) > 0);
  const { wizard: rentWizard } = Hooks.useRentWizard();
  const { wizard: homeWizard } = Hooks.useHomeWizard();
  const rentData = Hooks.useRentData();
  const deleteRent = useMutation(new Data.RealEstate.Mutations.DeleteRent());
  const deleteHome = useMutation(new Data.RealEstate.Mutations.DeleteHousingEntity());
  const { showMessage } = useMessage();
  const [open, setOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState<Domain.HousingData>();

  const summary = useMemo(
    () => Data.Budget.adjustMonthlyAmount(mode, spendingSummary.housing),
    [spendingSummary, mode]
  );

  const handleEditHome = (x: Domain.HousingData) => {
    homeWizard.start({ id: x.id, type: Domain.RealEstateTypeEnum.PERSONAL });
    navigate(`/account-details/home/summary/${x.id}`);
  };

  const handleDeleteHome = (account: Domain.HousingData) => {
    deleteHome.action(account.id ?? 0);
    showMessage(t('HomeRemoved').toString());
  };

  const removeRentHandler = (id?: number) => {
    if (id) {
      deleteRent.action(id);
      showMessage(t('RentalRemoved') as string);
    }
  };

  const leaveBudgetDetails = () => {
    navigate('/account-details');
  };

  return (
    <CollapsiblePanel
      variant='compressed'
      header={t('Home')}
      expandIconPosition='relative'
      icon={<Svgs.DisplaySmallCustomHouse />}
      summary={formatWholeDollars(summary)}>
      <LeaveBudgetDetailsModal open={open} setOpen={setOpen} handleSave={leaveBudgetDetails} />
      <RemoveAccountModal
        name={selectedAccount?.name}
        type='Home'
        variant='Home'
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        handleSave={() => {
          if (selectedAccount) {
            handleDeleteHome(selectedAccount);
          }
        }}
      />
      <HomeRentalDrawer open={drawerOpen} closeDrawer={() => setDrawerOpen(false)} />
      <Grid className='embedded-content'>
        <EmbeddedList<Domain.HousingData>
          items={houses}
          keyFn={(x) => x.id?.toString() ?? ''}
          header={t('Home')}
          emptyHeader={t('NoHomesAdded')}
          titleFn={(x) => x.name ?? ''}
          summaryFn={(x) => formatWholeDollars(getAdjustedHousingPayment(x, mode))}
          headingProps={{ endAdornment: () => <Svgs.ActionSmallEdit /> }}
          onClick={() => setOpen(true)}
          onClickElement={(x) => handleEditHome(x)}
          onDelete={(x) => {
            setSelectedAccount(x);
            setModalOpen(true);
          }}
        />
        <Divider sx={{ borderColor: Colors.primaryLight }} />
        <EmbeddedList<Domain.RentData>
          items={rentData}
          keyFn={(x) => x.id?.toString() ?? ''}
          header={t('HomesIRent')}
          emptyHeader={t('AddAHomeYouRent')}
          titleFn={(x) => x.name ?? ''}
          summaryFn={(x) => formatWholeDollars(getAdjustedRentPayment(x, mode))}
          headingProps={{ endAdornment: () => <Svgs.IconAdd /> }}
          onClick={() => {
            rentWizard.start({});
            setDrawerOpen(true);
          }}
          onClickElement={(x) => {
            rentWizard.start({ id: x.id });
            setDrawerOpen(true);
          }}
          onDelete={(x) => removeRentHandler(x.id)}
        />
      </Grid>
    </CollapsiblePanel>
  );
};

interface HomeRentalDrawerProps {
  open: boolean;
  closeDrawer: () => void;
}

const HomeRentalDrawer: React.FC<HomeRentalDrawerProps> = ({ closeDrawer, open }) => {
  return (
    <Drawer anchor='right' open={open} onClose={closeDrawer}>
      <Box p={Spacing.xxs}>
        <HomeRentEditViewWrapper editing={true} onBack={closeDrawer} />
      </Box>
    </Drawer>
  );
};
