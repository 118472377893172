import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import { Tabs } from '../../../../components/Tabs';
import { PopupInfoCard } from '../../../../components';
import HelpPopover, { HelpPopoverText } from '../../../../components/HelpPopover';
import BulletedList from '../../../../components/BulletedList';

interface MostPopularCarsHeaderProps {
  value: number;
  setValue: React.Dispatch<React.SetStateAction<number>>;
}

const MostPopularCarsHeader: React.FC<MostPopularCarsHeaderProps> = ({ value, setValue }) => {
  const { t } = useTranslation();

  return (
    <Grid container className='most-popular-car-header'>
      <Grid item>
        <Typography mb={2} variant='p30Bold' component='h1' color='primary'>
          {t('MostPopularCars')}
        </Typography>
        <Tabs
          labels={['CarsNoNumber', 'SUVs', 'Trucks']}
          value={value}
          onChange={(_event: React.SyntheticEvent, newValue: number) => {
            setValue(newValue);
          }}
          sx={{
            '& .MuiTabs-scroller': {
              justifyContent: 'flex-start',
            },
          }}
        />
      </Grid>
      <Grid item xs={3.25}>
        <PopupInfoCard popupContext={<FiveYearOwnershipCostPopup />}>
          <Typography color='secondary' variant='p16'>
            {t('KeepThe5YearOwnershipCostInMind')}
          </Typography>
        </PopupInfoCard>
      </Grid>
    </Grid>
  );
};

export default MostPopularCarsHeader;

const FiveYearOwnershipCostPopup: React.FC = () => {
  const { t } = useTranslation();

  return (
    <HelpPopover title={t('5YearOwnershipCost')}>
      <HelpPopoverText style={{ display: 'inline-block' }}>
        {t('ThereAreALotOfCosts')}
      </HelpPopoverText>
      <BulletedList
        style={{ marginTop: '6px' }}
        children={[
          t('Insurance'),
          t('Maintenance'),
          t('Repairs'),
          t('TaxesAndFees'),
          t('Financing'),
          t('Depreciation'),
          t('Fuel'),
        ]}
      />
    </HelpPopover>
  );
};
