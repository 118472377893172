import { createContext, PropsWithChildren, useContext, useMemo, useState } from 'react';

export type AccountOriginType = 'account-details' | 'budget-details';

export interface IAccountOriginContext {
  accountOrigin: AccountOriginType;
  setAccountOrigin: React.Dispatch<React.SetStateAction<AccountOriginType>>;
}

const AccountOriginContext = createContext<IAccountOriginContext>({
  accountOrigin: 'account-details',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setAccountOrigin: () => {},
});

export const AccountOriginProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [accountOrigin, setAccountOrigin] = useState<AccountOriginType>('account-details');

  const value = useMemo(
    () => ({ accountOrigin, setAccountOrigin }),
    [accountOrigin, setAccountOrigin]
  );

  return <AccountOriginContext.Provider value={value}>{children}</AccountOriginContext.Provider>;
};

export const useAccountOrigin = () => {
  const context = useContext(AccountOriginContext);
  if (!context) {
    throw new Error('useAccountOrigin must be used within AccountOriginProvider');
  }
  return context;
};
