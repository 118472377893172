import { Data, formatWholeDollars } from '@3nickels/data-modules';
import { Divider, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CardDisplay from '../../../components/panels/CardDisplay';
import { SummaryCardProps } from '../Types';
import { formatPercent } from '../../../helpers/utilityFunctions';

export interface AnnuityPayoutCardProps extends SummaryCardProps {
  data: Data.Annuities.AnnuityFormData;
}

const AnnuityPayoutCard: React.FC<AnnuityPayoutCardProps> = ({ data, onEdit }) => {
  const { t } = useTranslation();
  const hasEndAge = data.distOptionName?.includes('Period');
  const isJointDist = data.distOptionName?.includes('Joint');
  const isFixedForInflation =
    data.inflationOption ===
    Data.Annuities.AnnuityInflationProtectionEnum['Fixed Annual Increases'];

  return (
    <CardDisplay headerKey='PayoutDetails' actionContent='Edit' actionClick={onEdit}>
      <Grid container spacing={2}>
        <Grid item xs>
          <Typography variant='p16Bold' color='secondary'>
            {t('DistributionType')}
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography variant='h3' component='h3' color='primary'>
            {t('DistributionType')}
          </Typography>
          <Typography color='secondary'>{data.distOptionName}</Typography>
        </Grid>
        <Grid item xs>
          <Typography variant='h3' component='h3' color='primary'>
            {t('PaymentStartAge')}
          </Typography>
          <Typography color='secondary'>
            {`Age ${data.annuityStartAge} (${data.beginYear})`}
          </Typography>
        </Grid>
        {hasEndAge && (
          <Grid item xs>
            <Typography variant='h3' component='h3' color='primary'>
              {t('PaymentEndAge')}
            </Typography>
            <Typography color='secondary'>
              {`Age ${data.annuityEndAge} (${data.endYear})`}{' '}
            </Typography>
          </Grid>
        )}
        <Grid item xs />
        {!hasEndAge && <Grid item xs />}
      </Grid>

      <Grid container my={2}>
        <Grid item xs={12}>
          <Divider color='primary.light' />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs>
          <Typography variant='p16Bold' color='secondary'>
            {t('PaymentDetails')}
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography variant='h3' component='h3' color='primary'>
            {t('MonthlyPayment')}
          </Typography>
          <Typography color='secondary'>{formatWholeDollars(data.monthlyIncome ?? 0)}</Typography>
        </Grid>
        {isJointDist && (
          <Grid item xs>
            <Typography variant='h3' component='h3' color='primary'>
              {t('SurvivorBenefit')}
            </Typography>
            <Typography color='secondary'>{formatPercent(data.survivorFraction ?? 0)}</Typography>
          </Grid>
        )}
        <Grid item xs>
          <Typography variant='h3' component='h3' color='primary'>
            {t('InflationProtectionFeatures')}
          </Typography>
          <Typography color='secondary'>
            {data.inflationOption === 2
              ? 'None'
              : Data.Annuities.annuityInflationProtectionNames[data.inflationOption ?? 0]}
          </Typography>
        </Grid>
        {isFixedForInflation && (
          <Grid item xs>
            <Typography variant='h3' component='h3' color='primary'>
              {t('AnnualRateIncrease')}
            </Typography>
            <Typography color='secondary'>{formatPercent(data.colaAmtRate ?? 0)}</Typography>
          </Grid>
        )}
        {!isJointDist && <Grid item xs />}
        {!isFixedForInflation && <Grid item xs />}
      </Grid>
    </CardDisplay>
  );
};

export default AnnuityPayoutCard;
