import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Stack, Typography } from '@mui/material';
import { Spacing } from '../../../../themes';
import { KeyValuePair, Spacer } from '../../../../components';
import { Api, Domain } from '@3nickels/data-modules';
import { formatPercent, formatWholeDollars } from '../../../../helpers/utilityFunctions';
import InfoCard from '../../../../components/InfoCard';
import { CarPurchaseMethod } from './Types';
import { useGoalEditor } from '../../../goals/useGoalEditor';

type HowMuchCarCalculatorResultsProps = {
  results: Api.AdviceCarAffordabilityResultRest | undefined;
  purchaseMethod: CarPurchaseMethod;
};

const HowMuchCarCalculatorResults: React.FC<HowMuchCarCalculatorResultsProps> = ({
  results,
  purchaseMethod,
}) => {
  const { t } = useTranslation();
  const editGoal = useGoalEditor();

  return (
    <Grid container direction='column'>
      <Grid item>
        <Stack spacing={Spacing.xxxs + 0.5}>
          <Spacer height='xxs' />
          {purchaseMethod === 'Cash' ? (
            <>
              <KeyValuePair
                label={t('AvailableCash')}
                value={
                  results ? formatWholeDollars(results?.cashResult?.availableCash ?? 0) : '– – –'
                }
              />
              <KeyValuePair
                label={t('ValueOfTradeIn')}
                value={
                  results ? formatWholeDollars(results?.cashResult?.valueOfTradeIn ?? 0) : '– – –'
                }
              />
            </>
          ) : purchaseMethod === 'Loan' ? (
            <>
              <KeyValuePair
                label={t('LikelyInterest')}
                value={
                  results ? formatPercent(results?.loanResult?.likelyInterestRate ?? 0) : '– – –'
                }
              />
              <KeyValuePair
                label={t('IdealMonthly')}
                value={
                  results
                    ? formatWholeDollars(results?.loanResult?.idealMonthlyPayment ?? 0)
                    : '– – –'
                }
              />
              <KeyValuePair
                label={t('MaxMonthly')}
                value={
                  results
                    ? formatWholeDollars(results?.loanResult?.maximumMonthlyPayment ?? 0)
                    : '– – –'
                }
              />
            </>
          ) : (
            <>
              <KeyValuePair
                label={t('LikelyInterest')}
                value={
                  results ? formatPercent(results?.leaseResult?.likelyInterestRate ?? 0) : '– – –'
                }
              />
              <KeyValuePair
                label={t('IdealMonthly')}
                value={
                  results
                    ? formatWholeDollars(results?.leaseResult?.idealMonthlyPayment ?? 0)
                    : '– – –'
                }
              />
              <KeyValuePair
                label={t('MaxMonthly')}
                value={
                  results
                    ? formatWholeDollars(results?.leaseResult?.maximumMonthlyPayment ?? 0)
                    : '– – –'
                }
              />
            </>
          )}

          {results && (
            <>
              <Spacer height='xxxs' />
              <InfoCard
                onClick={() => {
                  editGoal(Domain.GoalTypeEnum.Car);
                }}>
                <Typography variant='p16' color='secondary'>
                  {t('ReadyToSaveForACar')}
                </Typography>
              </InfoCard>
            </>
          )}
        </Stack>
      </Grid>
    </Grid>
  );
};

export default HowMuchCarCalculatorResults;
