/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Card, Grid, Link, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useEffect, useMemo, useState, useRef } from 'react';
import { useService } from '@aesop-fables/containr-react';
import { Api, Domain, Hooks } from '@3nickels/data-modules';
import { Trans, useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { API_URL } from '../../configuration/Environment';
import { PageContentWithBackground } from '../layout/PageContentWithBackground';
import { Column, Row, Spacer } from '../../components';
import { mobileMargin } from '../../App';
import InfoCard from '../../components/InfoCard';
import { universityAdvisor } from '../../assets/png';
import { formatDecimalDollars } from '../../helpers/utilityFunctions';
import { useDeviceType } from '../../hooks/useDeviceType';
import { Svgs } from '../../assets/svg';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { ProductOptions } from '../../sign-up/components/ProductOptions';
import Cookies from 'js-cookie';

const ChoosePackageView = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { language } = i18next;
  const { isMobile, isTablet } = useDeviceType();
  const mobile = isMobile || isTablet;
  const { height } = useWindowDimensions();
  const INIT_LOGO_HEIGHT = 100;
  const organizationData = Hooks.useOrganizationData();
  const organizationMetadata = Hooks.useOrganizationMetadata();
  const signupPageText = organizationMetadata?.signupPageText;
  const formRef = useRef<HTMLFormElement>(null);

  const termsApi = useService<Api.TermsAndConditionsApi>(Api.ApiKeys.TermsAndConditions);
  const docs = Hooks.useTermsAndConditionsDocs();
  const [tosLink, setTosLink] = useState<string>();

  const [logoHeight, setLogoHeight] = useState<number>(INIT_LOGO_HEIGHT);
  const hasLogo = Boolean(organizationData?.logoUri);

  const { availableProductsData: pricebookData } = Hooks.useAvailableProducts();
  const [memberPaidOptions, setMemberPaidOptions] = useState<Domain.PricebookBundle[]>([]);
  const [orgPaidOptions, setOrgPaidOptions] = useState<Domain.PricebookBundle[]>([]);
  const [selectedPricebook, setSelectedPricebook] = useState<Domain.PricebookBundle | undefined>(
    undefined
  );
  const memOptions = memberPaidOptions.length;
  const orgOptions = orgPaidOptions.length;

  const isOnlyOneOption = useMemo(() => {
    return memOptions === 1 || orgOptions === 1;
  }, [memOptions, orgOptions]);

  const pricebookMetadata = useMemo(() => {
    return typeof organizationMetadata !== 'undefined' &&
      Object.keys(organizationMetadata.pricebookMetadata ?? {}).length > 0
      ? JSON.parse(organizationMetadata?.pricebookMetadata?.pricebook ?? '')
      : undefined;
  }, [organizationMetadata]);

  const promotion = useMemo(
    () =>
      pricebookData?.filter((bundle) =>
        bundle.prices.find((price) => price.introductionPeriodInDays > 1)
      ),
    [pricebookData]
  );
  const introPeriod = useMemo(
    () =>
      promotion
        ?.map((bundle) => bundle.prices)
        .map((price) =>
          price.map((arr) => arr.introductionPeriodInDays).find((promo) => promo > 1)
        ),
    [promotion]
  );
  const introPeriodInMonths = useMemo(
    () => introPeriod?.map((promo) => Math.floor((promo ?? 0) / 30)),
    [introPeriod]
  );
  const specialPromoOffer = useMemo(
    () => pricebookData?.find((p) => p.pricebook.metadata?.subHeader === 'Special Promo Offer!*'),
    [pricebookData]
  );
  const specialNonProfitOffer = useMemo(
    () =>
      pricebookData?.find((p) => p.pricebook.metadata?.subHeader === 'Special Non-Profit Offer*'),
    [pricebookData]
  );

  const headerText = useMemo(() => {
    return isOnlyOneOption ? t('ReviewYourPackage') : t('ChooseAPackage');
  }, [isOnlyOneOption]);

  useEffect(() => {
    const getTermsLink = async () => {
      if (docs) {
        const termsObject = docs.find((doc) => doc.docDescription === 'Terms of Service');
        if (!termsObject) {
          return;
        }
        const url = termsApi.getDocString(termsObject.id);
        setTosLink(url);
      }
    };
    getTermsLink();
  }, [docs, termsApi]);

  useEffect(() => {
    const getPricebookOptions = async () => {
      if (pricebookData) {
        const member = pricebookData.filter(
          (bundle) =>
            bundle.pricebook.pricebookName.includes('3Nickels') &&
            bundle.pricebook.paidBy === 'USER'
        );
        const org = pricebookData.filter(
          (bundle) =>
            bundle.pricebook.pricebookName.includes('3Nickels') && bundle.pricebook.paidBy === 'ORG'
        );
        setMemberPaidOptions(member);
        setOrgPaidOptions(org);
      }
    };

    getPricebookOptions();
  }, [pricebookData]);

  useEffect(() => {
    if (!memberPaidOptions || !orgPaidOptions) {
      return;
    }
    if (isOnlyOneOption) {
      if (memOptions === 1) {
        setSelectedPricebook(memberPaidOptions[0]);
      } else {
        setSelectedPricebook(orgPaidOptions[0]);
      }
    }
  }, [isOnlyOneOption, memOptions, memberPaidOptions, orgPaidOptions]);

  useEffect(() => {
    const img = new Image();
    img.src = `${API_URL}organization/logo`;

    img.onload = () => {
      const aspectRatio = img.naturalWidth / img.naturalHeight;
      const rateDecrease = -30; // slope
      const baseHeight = 123; // y-intercept
      const height = rateDecrease * Math.log(aspectRatio) + baseHeight;
      setLogoHeight(height);
    };
  }, []);

  const onSetUpTime = () => {
    window
      .open(
        'https://outlook.office365.com/owa/calendar/beta@guidedchoice.com/bookings/s/ag4FCPYR0USkWp4Rf49o6Q2',
        '_blank'
      )
      ?.focus();
  };

  const onBack = () => {
    navigate('/migrate');
  };

  return (
    <PageContentWithBackground
      sx={{ height: mobile ? height : undefined, overflowX: 'hidden' }}
      useContainer={false}>
      <Column
        style={{
          margin: mobile ? mobileMargin : '25px 100px',
        }}>
        <Row
          style={{
            alignItems: mobile ? undefined : 'center',
            alignSelf: mobile ? 'center' : undefined,
            marginTop: mobile && hasLogo ? '20px' : undefined,
            justifyContent: 'space-between',
            height: hasLogo ? '75px' : '0px',
          }}>
          {organizationData ? (
            hasLogo ? (
              <Box
                style={{
                  height: '75px',
                  visibility: logoHeight === INIT_LOGO_HEIGHT ? 'hidden' : 'visible',
                }}>
                <img
                  src={`${API_URL}organization/logo`}
                  alt={organizationData?.name}
                  style={{
                    height: `${logoHeight}%`,
                    width: 'auto',
                    maxWidth: '100%',
                    objectFit: 'contain',
                  }}
                />
              </Box>
            ) : (
              <Box />
            )
          ) : (
            <Card variant='ghost' />
          )}

          {!mobile && (
            <Grid marginTop={hasLogo ? '25px' : '100px'}>
              <InfoCard onClick={onSetUpTime} icon={<img src={universityAdvisor} width='60px' />}>
                <Typography color='secondary' variant='p16' maxWidth='185px' ml='20px' mr='5px'>
                  {t('HaveAnyQuestions?SetUpATime')}
                </Typography>
              </InfoCard>
            </Grid>
          )}
        </Row>
        <Spacer height='xs' />
        <Column
          style={{
            maxWidth: mobile ? '100%' : 'calc(100% - 350px)',
          }}>
          {mobile ? (
            <Row>
              <Svgs.IconNavMenuDefault onClick={onBack} style={{ transform: 'rotate(180deg)' }} />
              <Typography ml={2} variant={'p22Bold'} color={'secondary'}>
                {headerText}
              </Typography>
            </Row>
          ) : (
            <Typography variant={'h1'} color={'primary'}>
              {headerText}
            </Typography>
          )}

          {signupPageText && (
            <Column>
              <Spacer height='xs' />
              <Typography variant='p16' color='secondary'>
                {signupPageText}
              </Typography>
            </Column>
          )}
        </Column>

        <Spacer height={!hasLogo && !mobile ? 'xl' : 'xs'} />
        <ProductOptions
          memberPaidOptions={memberPaidOptions}
          orgPaidOptions={orgPaidOptions}
          pricebookMetadata={pricebookMetadata}
          selectedPricebook={selectedPricebook}
          setSelectedPricebook={memOptions > 1 || orgOptions > 1 ? setSelectedPricebook : undefined}
          customSubmitButton={
            mobile
              ? {
                  label: `${isOnlyOneOption ? t('MoveMyAccount') : t('ChooseAndMove')}`,
                  style: { width: '100%', marginTop: '20px', marginBottom: '-10px' },
                  url: `${API_URL}account-migration?pricebookId=`,
                }
              : undefined
          }
        />
        {!specialPromoOffer && !specialNonProfitOffer && (
          <Typography className='link-wrapper' variant='p14' color='secondary'>
            <Trans
              i18nKey='PleaseSeeTOSandCancellationPolicy'
              components={{
                TermsOfService: <Link href={tosLink} target='_blank' />,
                CancellationPolicy: <Link href='/cancellation-policy' target='_blank' />,
              }}
            />{' '}
            <Trans
              i18nKey='HaveAnyQuestions?WereAvailablePhoneAndEmail'
              values={{ emailAddress: 'help@3nickels.com' }}
              components={{
                Email: <Link href={'mailto:help@3nickels.com'} />,
              }}
            />
            {t('', {})}
          </Typography>
        )}

        {introPeriodInMonths?.length ?? 0 > 1 ? (
          <>
            {!isTablet && <Spacer height='xxxs' />}
            <Typography variant='p14' color='secondary'>
              *{' '}
              {t('AfterThePeriodOfFreeAccessEndsAdviceSubscriptionAutoRenewsAt', {
                price: `${formatDecimalDollars(14.99, language)}/${t('month')}`,
              })}
            </Typography>
          </>
        ) : (
          introPeriodInMonths?.map((x, index) => (
            <>
              {!isTablet && <Spacer height='xxxs' />}
              <Typography key={index} variant='p14' color='secondary'>
                *{' '}
                {t('AfterXMonthsAdviceSubscriptionAutoRenewsAt', {
                  x,
                  price: `${formatDecimalDollars(14.99, language)}/${t('month')}`,
                })}
              </Typography>
            </>
          ))
        )}

        {(specialPromoOffer || specialNonProfitOffer) && (
          <>
            <Typography className='link-wrapper' variant='p14' color='secondary'>
              * {t('TermsAndConditionsApply')}{' '}
              {t('PromotionalPricingDiscountIsOffStandardMonthlyPricing')}{' '}
              {t(
                'WeReserveTheRightToValidateEligibilityAndToModifyOrEndThisPromotionalOfferAtAnyTime'
              )}{' '}
              <Trans
                i18nKey='PleaseSeeTOSandCancellationPolicy'
                components={{
                  TermsOfService: <Link href={tosLink} target='_blank' />,
                  CancellationPolicy: <Link href='/cancellation-policy' target='_blank' />,
                }}
              />
            </Typography>
          </>
        )}

        <Spacer height={mobile ? 'xs' : 'xxl'} />
        {!mobile && (
          <Row style={{ justifyContent: 'space-between' }}>
            <Button
              style={{ minWidth: 'fit-content' }}
              className='md'
              onClick={onBack}
              variant='outlined'
              color='secondary'>
              {t('Back')}
            </Button>

            <form
              ref={formRef}
              method='post'
              action={`${API_URL}account-migration?pricebookId=${selectedPricebook?.pricebook.id}`}>
              <Button
                onClick={() => formRef.current?.submit()}
                disabled={isOnlyOneOption ? false : !selectedPricebook}
                style={{ minWidth: 'fit-content' }}
                className='md'
                color='secondary'>
                {t('MoveMyAccount')}
              </Button>
              <input type='hidden' name='_csrf' value={Cookies.get('XSRF-TOKEN')} readOnly />
            </form>
          </Row>
        )}

        {mobile && (
          <>
            <Spacer height='xs' />
            <InfoCard onClick={onSetUpTime} icon={<img src={universityAdvisor} width='60px' />}>
              <Typography color='secondary' variant='p16' ml='20px' mr='5px'>
                {t('HaveAnyQuestions?SetUpATime')}
              </Typography>
            </InfoCard>
          </>
        )}

        <Spacer height='lg' />
      </Column>
    </PageContentWithBackground>
  );
};

export default ChoosePackageView;
