import { Data, Domain } from '@3nickels/data-modules';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CardDisplay from '../../../components/panels/CardDisplay';
import { capitalizeFirstLetter } from '../../../helpers/utilityFunctions';
import { SummaryCardProps } from '../Types';

export interface AnnuityBasicsCardProps extends SummaryCardProps {
  data: Data.Annuities.AnnuityFormData;
  includeSpouse?: boolean;
}

const AnnuityBasicsCard: React.FC<AnnuityBasicsCardProps> = ({ data, includeSpouse, onEdit }) => {
  const { t } = useTranslation();

  return (
    <CardDisplay headerKey='AccountBasics' actionContent='Edit' actionClick={onEdit}>
      <Grid container spacing={2}>
        <Grid item xs>
          <Typography variant='h3' component='h3' color='primary'>
            {t('TypeOfAccount')}
          </Typography>
          <Typography color='secondary'>{t('RetirementPlan')}</Typography>
        </Grid>
        <Grid item xs>
          <Typography variant='h3' component='h3' color='primary'>
            {t('TypeOfRetirementPlan')}
          </Typography>
          <Typography color='secondary'>
            {Domain.planTypeToAccountLabel[Domain.PlanTypeEnum['Annuity | Fixed']]}
          </Typography>
        </Grid>
        {includeSpouse && (
          <Grid item xs>
            <Typography variant='h3' component='h3' color='primary'>
              {t('AccountOwner')}
            </Typography>
            <Typography color='secondary'>{capitalizeFirstLetter(data.owner ?? '')}</Typography>
          </Grid>
        )}
        <Grid item xs />
        <Grid item xs />
        {!includeSpouse && <Grid item xs />}
      </Grid>
    </CardDisplay>
  );
};

export default AnnuityBasicsCard;
