import React from 'react';
import { Grid, Typography } from '@mui/material';
import SpendingSummaryTimeframeSelector from '../SpendingSummaryTimeframeSelector';

const SkeletonSpendingSummaryTotal: React.FC = () => {
  return (
    <Grid display='flex' justifyContent='flex-end' columnGap='30px' alignItems='center'>
      <SpendingSummaryTimeframeSelector />
      {/* eslint-disable-next-line i18next/no-literal-string */}
      <Typography fontFamily='Montserrat' variant='p40Bold' color='secondary'>
        $X,XXX
      </Typography>
    </Grid>
  );
};

export default SkeletonSpendingSummaryTotal;
