import React from 'react';
import { Circle, HelpOutline, Straight } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';
import { Row, Column, Spacer } from '../../../components';
import { Colors } from '../../../themes';
import { formatWholeDollars } from '../../../helpers/utilityFunctions';
import { Domain } from '@3nickels/data-modules';
import { t } from 'i18next';
import Tippy from '@tippyjs/react';
import HelpPopover, { HelpPopoverText } from '../../../components/HelpPopover';
import { Trans } from 'react-i18next';

interface BudgetSubsectionProps {
  type: string;
  originalBudget: Domain.BudgetOverview;
  recommendedBudget: Domain.BudgetOverview;
  color: string;
  showCurrent: boolean;
  showSubsection: boolean;
}

const BudgetSubsection: React.FC<BudgetSubsectionProps> = ({
  type,
  originalBudget,
  recommendedBudget,
  color,
  showCurrent,
  showSubsection,
}) => {
  const current = Math.round(originalBudget[type as keyof Domain.BudgetOverview] ?? 0);
  const recommended = Math.round(recommendedBudget[type as keyof Domain.BudgetOverview] ?? 0);
  const recommendation = current < recommended ? 'increasing' : 'decreasing';
  const diff = Math.abs(current - recommended);

  function getSubsectionLabel(type: string) {
    switch (type) {
      case 'monthlySaving':
        return 'Save';
      case 'monthlySpending':
        return 'Spend';
      case 'monthlyGiving':
        return 'Give';
      default:
        return '';
    }
  }

  if (!showSubsection) return <></>;

  return (
    <React.Fragment>
      <Grid item sm={1} />
      <Grid item sm={4}>
        <Row style={{ justifyContent: 'space-between', height: '100%' }}>
          <Column style={{ alignItems: 'center', justifyContent: 'center' }}>
            <Circle style={{ color: color }} />
          </Column>
          <Column style={{ width: '85%' }}>
            <Grid display='flex' columnGap='5px'>
              <Typography variant='p18Bold' color='secondary'>
                {t(getSubsectionLabel(type))}
              </Typography>
              {type === 'monthlySpending' && current !== recommended && (
                <Tippy
                  placement='right'
                  content={
                    <HelpPopover title={t('DebtPaymentsInSpending')}>
                      <HelpPopoverText>{t('IfWeRecommendChangingYourSpending')}</HelpPopoverText>
                    </HelpPopover>
                  }
                  theme='light-nickels-theme'>
                  <HelpOutline style={{ color: Colors.primaryBase, alignSelf: 'center' }} />
                </Tippy>
              )}
            </Grid>
            <Spacer height='xxs' />
            {current === recommended ? (
              <Typography variant='p16' color='secondary.light'>
                {t('NoChangesNeeded')}
              </Typography>
            ) : (
              <Row
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}>
                <Straight
                  style={{
                    color: Colors.primaryBase,
                    transform: current > recommended ? 'rotate(180deg)' : 'rotate(0deg)',
                    height: 'auto',
                    width: '30px',
                  }}
                />
                <Spacer width='xxxs' />
                <Typography color='secondary.light' variant='p16'>
                  {t('BudgetRecommendation', {
                    recommendation: t(recommendation),
                    type: type === 'monthlySpending' ? t('DebtPayments') : '',
                    difference: formatWholeDollars(diff),
                  })}
                </Typography>
              </Row>
            )}
          </Column>
        </Row>
      </Grid>
      <Grid
        item
        sm={3}
        style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        {showCurrent && (
          <Row style={{ justifyContent: 'center' }}>
            <Typography
              color={Colors.tertiaryDark}
              variant='p20'
              fontFamily='Montserrat'
              style={{ display: 'inline' }}>
              <Trans
                i18nKey='StyledX/Period'
                values={{
                  x: formatWholeDollars(current),
                  period: t('mo'),
                }}
                components={{
                  Styled: (
                    <Typography
                      color={Colors.tertiaryDark}
                      variant='p30'
                      fontFamily='Montserrat'
                      style={{ display: 'inline' }}
                    />
                  ),
                }}
              />
            </Typography>
          </Row>
        )}
      </Grid>
      <Grid item sm={1} />
      <Grid
        item
        sm={3}
        style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        <Row style={{ justifyContent: 'center' }}>
          <Typography
            color='secondary'
            variant='p20'
            fontFamily='Montserrat'
            fontWeight={300}
            style={{ display: 'inline' }}>
            <Trans
              i18nKey='StyledX/Period'
              values={{
                x: formatWholeDollars(recommended),
                period: t('mo'),
              }}
              components={{
                Styled: (
                  <Typography
                    color='secondary'
                    variant='h1'
                    fontFamily='Montserrat'
                    style={{ display: 'inline' }}
                  />
                ),
              }}
            />
          </Typography>
        </Row>
      </Grid>
      <Grid item mt={7} sm={12} />
    </React.Fragment>
  );
};

export default BudgetSubsection;
