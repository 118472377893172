import { Hooks, formatWholeDollars, Data } from '@3nickels/data-modules';
import { ChartPieVariableRadius } from '../../components/ChartPieVariableRadius';
import { CSSProperties } from 'react';
import { SpendingSummaryBreakdownProps } from './SpendingSummaryBreakdown';
import { TransactionsSummaryChartColors } from './SpendingSummaryOverview';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';
import { convertToTranslationKey } from '../../helpers/utilityFunctions';

interface SpendingSummaryChartProps extends SpendingSummaryBreakdownProps {
  style?: CSSProperties;
  emptyState?: boolean;
  selectCategory?: (category?: Data.Transactions.TransactionCategoryEnum | undefined) => void;
}

const SpendingSummaryChart: React.FC<SpendingSummaryChartProps> = ({
  transactionsSummary,
  colors = TransactionsSummaryChartColors,
  repeatColorIndex = colors.length - 1,
  style = { minWidth: '200px' },
  emptyState,
  selectCategory,
}) => {
  const { loadingSummary } = Hooks.useTransactionsSummary();
  const navigate = useNavigate();

  return (
    <ChartPieVariableRadius
      tooltipText={({ datum }) => {
        if (emptyState) return null;
        return [`${datum.x}`, `\n`, `${formatWholeDollars(datum.y)}`];
      }}
      data={transactionsSummary.map((transaction, index) => {
        const x = convertToTranslationKey(transaction.primaryCategory?.toString() ?? '') ?? '';
        return {
          x: t(`${x}`) as string,
          y: transaction.amount ?? 0,
          color: colors[index > repeatColorIndex ? repeatColorIndex : index],
          meta: transaction.primaryCategory ?? '',
        };
      })}
      innerRadius={50}
      style={style}
      showSkeleton={loadingSummary}
      onClick={(event, { datum }) => {
        return emptyState
          ? undefined
          : selectCategory
          ? selectCategory(datum.meta)
          : navigate('spending-summary');
      }}
      emptyState={emptyState}
    />
  );
};

export default SpendingSummaryChart;
