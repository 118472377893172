import { Box, Grid, IconButton, Typography } from '@mui/material';
import React, { ReactNode, useEffect, useState } from 'react';
import { Spacing } from '../themes';
import { Svgs } from '../assets/svg';
import { ShowGroupDeleteOnHover } from '../themes/mui';
import Spacer from './Spacer';
import { t } from 'i18next';

export type variant = 'compressed' | 'default';

export interface GroupEditProps<T> {
  className?: string;
  title?: string | React.JSX.Element;
  subtitleFn?: (i: T) => string;
  subtitleAdornment?: React.JSX.Element;
  items: T[];
  keyFn: (i: T) => string;
  mainContentFn: ((i: T) => string) | ((i: T) => React.JSX.Element);
  centerContentFn?: ((i: T) => string) | ((i: T) => React.JSX.Element);
  summaryFn?: ((i: T) => string) | ((i: T) => React.JSX.Element);
  mainContentStyle?: React.CSSProperties;
  centerContentStyle?: React.CSSProperties;
  summaryContentStyle?: React.CSSProperties;
  editLabel?: (i: T) => string;
  needsAttention?: (i: T) => boolean | undefined;
  stackEndContent?: boolean;
  zeroItemsComponent?: React.JSX.Element;
  addItemComponent?: React.JSX.Element;
  variant?: variant;

  onDelete?: (i: T) => void;
  disableDelete?: (i: T) => boolean;
  onEdit?: (i: T) => void;
}

export interface FormHeaderProps {
  text: string;
}

function CompressedRow<T>(props: GroupEditProps<T>): ReactNode {
  const {
    items,
    keyFn,
    mainContentFn,
    centerContentFn,
    summaryFn,
    editLabel,
    needsAttention,
    onDelete,
    disableDelete = () => false,
    onEdit,
  } = props;
  const showDeleteIcon = typeof onDelete !== 'undefined';

  return items.map((i: T) => (
    <Row key={keyFn(i)} needsAttention={needsAttention ? needsAttention(i) : undefined}>
      <Grid
        container
        sx={[ShowGroupDeleteOnHover]}
        justifyContent='space-between'
        alignItems='center'
        flexDirection='row'>
        <Grid item>
          <Grid container alignItems='center' flexDirection='row'>
            {showDeleteIcon && (
              <Grid item className='group-edit-delete'>
                {!disableDelete(i) ? (
                  <IconButton>
                    <Svgs.IpmIconDelete onClick={() => onDelete(i)} />
                  </IconButton>
                ) : (
                  <Svgs.IpmIconDelete opacity='0' />
                )}
              </Grid>
            )}
            <Grid item pl={1}>
              <Typography color='secondary' fontWeight='bold'>
                {mainContentFn(i)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          {typeof centerContentFn !== 'undefined' && (
            <Typography color='secondary' fontSize={16} mr={2}>
              {centerContentFn(i)}
            </Typography>
          )}
        </Grid>
        <Box display='inline-flex' flexDirection='row' alignItems='center'>
          {typeof summaryFn !== 'undefined' && (
            <Typography color='secondary' fontSize={16} mr={2}>
              {summaryFn(i)}
            </Typography>
          )}

          {typeof onEdit !== 'undefined' && (
            <Typography
              className='clickable'
              variant='p12'
              color='primary'
              onClick={() => onEdit(i)}>
              {editLabel ? editLabel(i) : t('Edit')}
            </Typography>
          )}
        </Box>
      </Grid>
    </Row>
  ));
}

declare type ItemRowProps<T> = {
  item: T;
} & Omit<Omit<GroupEditProps<T>, 'items'>, 'keyFn'>;

export function ItemRow<T>(props: ItemRowProps<T>): ReactNode {
  const {
    item,
    mainContentFn,
    centerContentFn,
    stackEndContent,
    summaryFn,
    editLabel,
    needsAttention,
    onDelete,
    disableDelete = () => false,
    onEdit,
  } = props;
  const showDeleteIcon = typeof onDelete !== 'undefined';
  const mainContent = mainContentFn(item);
  const centerContent = typeof centerContentFn !== 'undefined' ? centerContentFn(item) : undefined;
  const summaryContent = typeof summaryFn !== 'undefined' ? summaryFn(item) : undefined;
  return (
    <Row needsAttention={needsAttention ? needsAttention(item) : undefined}>
      <Grid
        container
        sx={[ShowGroupDeleteOnHover]}
        justifyContent='space-between'
        alignItems='center'
        flexDirection='row'>
        <Grid item>
          <Grid container alignItems='center' flexDirection='row'>
            {showDeleteIcon && (
              <Grid item className='group-edit-delete'>
                {!disableDelete(item) ? (
                  <IconButton>
                    <Svgs.IpmIconDelete onClick={() => onDelete(item)} />
                  </IconButton>
                ) : (
                  <Svgs.IpmIconDelete opacity='0' />
                )}
              </Grid>
            )}
            <Grid item pl={1}>
              {typeof mainContent === 'string' ? (
                <Typography color='secondary' fontWeight='bold'>
                  {mainContent}
                </Typography>
              ) : (
                mainContent
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          {typeof centerContent !== 'undefined' && typeof centerContent === 'string' ? (
            <Typography color='secondary' fontSize={16} mr={2}>
              {centerContent}
            </Typography>
          ) : (
            centerContent
          )}
        </Grid>
        <Grid
          className='row-edit'
          display='inline-flex'
          flexDirection={stackEndContent ? 'column' : 'row'}
          alignItems={stackEndContent ? 'end' : 'center'}>
          {typeof summaryContent !== 'undefined' && typeof summaryContent === 'string' ? (
            <Typography color='secondary' fontSize={16} mr={2}>
              {summaryContent}
            </Typography>
          ) : (
            summaryContent
          )}
          {stackEndContent && <Spacer height='xxxs' />}
          {!stackEndContent && <Spacer width='xxxs' />}
          {typeof onEdit !== 'undefined' && (
            <Typography
              className='clickable'
              variant='p12'
              color='primary'
              onClick={() => onEdit(item)}>
              {editLabel ? editLabel(item) : t('Edit')}
            </Typography>
          )}
        </Grid>
      </Grid>
    </Row>
  );
}

function DefaultRow<T>(props: GroupEditProps<T>): ReactNode {
  const {
    items,
    keyFn,
    mainContentFn,
    centerContentFn,
    stackEndContent,
    summaryFn,
    mainContentStyle,
    centerContentStyle,
    summaryContentStyle,
    editLabel,
    needsAttention,
    onDelete,
    disableDelete = () => false,
    onEdit,
  } = props;
  const showDeleteIcon = typeof onDelete !== 'undefined';

  return items.map((i) => {
    const mainContent = mainContentFn(i);
    const centerContent = typeof centerContentFn !== 'undefined' ? centerContentFn(i) : undefined;
    const summaryContent = typeof summaryFn !== 'undefined' ? summaryFn(i) : undefined;
    return (
      <Row key={keyFn(i)} needsAttention={needsAttention ? needsAttention(i) : undefined}>
        <Grid
          container
          sx={[ShowGroupDeleteOnHover]}
          justifyContent='space-between'
          alignItems='center'
          flexDirection='row'>
          <Grid item style={mainContentStyle}>
            <Grid container alignItems='center' flexDirection='row' wrap='nowrap'>
              {showDeleteIcon && (
                <Grid item className='group-edit-delete'>
                  {!disableDelete(i) ? (
                    <IconButton>
                      <Svgs.IpmIconDelete onClick={() => onDelete(i)} />
                    </IconButton>
                  ) : (
                    <Svgs.IpmIconDelete opacity='0' />
                  )}
                </Grid>
              )}
              <Grid item pl={1} width='100%'>
                {typeof mainContent === 'string' ? (
                  <Typography color='secondary' fontWeight='bold'>
                    {mainContent}
                  </Typography>
                ) : (
                  mainContent
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item style={centerContentStyle}>
            {typeof centerContent !== 'undefined' && typeof centerContent === 'string' ? (
              <Typography color='secondary' fontSize={16} mr={2}>
                {centerContent}
              </Typography>
            ) : (
              centerContent
            )}
          </Grid>
          <Grid
            style={summaryContentStyle}
            className='row-edit'
            display='inline-flex'
            flexDirection={stackEndContent ? 'column' : 'row'}
            alignItems={stackEndContent ? 'end' : 'center'}>
            {typeof summaryContent !== 'undefined' && typeof summaryContent === 'string' ? (
              <Typography color='secondary' fontSize={16} mr={2}>
                {summaryContent}
              </Typography>
            ) : (
              summaryContent
            )}
            {stackEndContent && <Spacer height='xxxs' />}
            {!stackEndContent && <Spacer width='xxxs' />}
            {typeof onEdit !== 'undefined' && (
              <Typography
                className='clickable'
                variant='p12'
                color='primary'
                onClick={() => onEdit(i)}>
                {editLabel ? editLabel(i) : t('Edit')}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Row>
    );
  });
}

export const FormHeader: React.FC<FormHeaderProps> = ({ text }) => {
  return (
    <Grid container justifyContent='space-between' alignItems='center'>
      <Typography variant='p18Bold' color='primary'>
        {text}
      </Typography>
    </Grid>
  );
};

export const Row: React.FC<{ children: ReactNode; needsAttention?: boolean }> = ({
  children,
  needsAttention,
}) => {
  return (
    <Grid container justifyContent='center'>
      <Grid
        item
        sm={6}
        mt={Spacing.xxxs}
        padding={1}
        // pl={4}
        className={needsAttention ? 'group-edit-item-needs-attention' : 'group-edit-item'}
        display='inline-flex'
        justifyContent='space-between'
        flexDirection='row'>
        {children}
      </Grid>
    </Grid>
  );
};

interface Group<T> {
  name?: string;
  items: T[];
}

export function GroupEdit<T>(props: GroupEditProps<T>): ReactNode {
  const {
    className,
    title,
    subtitleFn,
    subtitleAdornment,
    items,
    zeroItemsComponent,
    addItemComponent,
    variant,
  } = props;
  const [groups, setGroups] = useState<Group<T>[]>([]);

  useEffect(() => {
    const groupMap = new Map<string, T[]>();
    items.forEach((i) => {
      if (subtitleFn) {
        const key = subtitleFn(i);
        const group = groupMap.get(key) ?? [];
        groupMap.set(key, [...group, i]);
        return;
      }
      const group = groupMap.get('') ?? []; // if no subtitle function, all items in the same group
      groupMap.set('', [...group, i]);
    });
    const groupArray = Array.from(groupMap.entries()).map(([name, items]): Group<T> => {
      return { name, items };
    });
    setGroups(groupArray);
  }, [items, subtitleFn]);

  return (
    <Box>
      <Grid className={className} container justifyContent='center'>
        {(items.length === 0 && typeof zeroItemsComponent !== 'undefined') ||
          (items.length !== 0 && typeof title !== 'undefined' && (
            <Grid container justifyContent='center'>
              {items.length === 0 && typeof zeroItemsComponent !== 'undefined' && (
                <Grid
                  item
                  sm={6}
                  mt={Spacing.xxxs}
                  className='group-edit-item'
                  display='inline-flex'
                  justifyContent='center'
                  flexDirection='row'>
                  {zeroItemsComponent}
                </Grid>
              )}
              {items.length !== 0 &&
                typeof title !== 'undefined' &&
                (typeof title === 'string' ? (
                  <Grid
                    item
                    sm={6}
                    mt={Spacing.xxxs}
                    className='group-edit-count'
                    display='inline-flex'
                    justifyContent='space-between'
                    flexDirection='row'>
                    <FormHeader text={`${title} (${items.length})`} />
                  </Grid>
                ) : (
                  title
                ))}
            </Grid>
          ))}

        {groups.map((g, index) => (
          <React.Fragment key={g.name}>
            {g.name && g.name.length > 0 && (
              <Grid
                key={index}
                item
                // sm={6}
                mt={Spacing.xxxs}
                className='group-edit-count'
                display='inline-flex'
                justifyContent='space-between'
                flexDirection='row'>
                <Typography variant='h2' color='secondary'>
                  {g.name}
                </Typography>
                {index === 0 && subtitleAdornment}
              </Grid>
            )}
            {variant === 'compressed' && <CompressedRow {...props} items={g.items} />}
            {(variant === 'default' || !variant) && <DefaultRow {...props} items={g.items} />}
          </React.Fragment>
        ))}

        {typeof addItemComponent !== 'undefined' && (
          <Grid
            item
            sm={6}
            mt={Spacing.xxxs}
            className='group-edit-item'
            display='inline-flex'
            justifyContent='center'
            flexDirection='row'>
            {addItemComponent}
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
