/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo } from 'react';
import { Card, Grid, Stack, Typography } from '@mui/material';
import { t } from 'i18next';
import { Chart, Colors, Spacing } from '../../../../themes';
import { Spacer } from '../../../../components';
import { Domain } from '@3nickels/data-modules';
import { convertToTranslationKey, formatPercent } from '../../../../helpers/utilityFunctions';
import { ChartBar, ChartBarData } from '../../../../components/ChartBar';
import { VictoryLabel } from 'victory';

type IraFeeCheckerResultsProps = {
  results: Domain.AdviceFeeCheckerPositionResultData | undefined;
};

const IraFeeCheckerResults: React.FC<IraFeeCheckerResultsProps> = ({ results }) => {
  const yourFixedIRA = useMemo(() => {
    return Number((results?.yourIra ?? 0).toFixed(2));
  }, [results]);
  const yourIRAColor = getYourIRAColor(yourFixedIRA, results?.averageIra ?? 0);

  const chartData: ChartBarData[] = [
    {
      x: t(convertToTranslationKey(Domain.IRAChartBarEnum['Your IRA'])) as string,
      y: results?.yourIra ?? 0,
      color: yourIRAColor,
      meta: Domain.IRAChartBarEnum['Your IRA'],
    },
    {
      x: t(convertToTranslationKey(Domain.IRAChartBarEnum['Average IRA'])) as string,
      y: results?.averageIra ?? 0,
      color: Colors.primaryBase,
      meta: Domain.IRAChartBarEnum['Average IRA'],
    },
  ];

  const BarLabel = (props: any) => (
    <VictoryLabel
      {...props}
      style={{
        ...Chart.theme.bar.style.labels,
        fontWeight: '900',
        fill:
          props.datum.meta === Domain.IRAChartBarEnum['Your IRA']
            ? yourIRAColor
            : Colors.primaryBase,
      }}
    />
  );

  return (
    <Grid container direction='column'>
      <Grid item>
        <Stack spacing={Spacing.xxxs + 0.5}>
          <Typography color='secondary' variant='p18Bold'>
            {t('TotalAnnualFees')}
          </Typography>

          <Typography variant='p16' color='secondary'>
            {typeof results === 'undefined'
              ? t('FillOutYourIraFeeDetails')
              : t('WeveCalculatedTheTotalAnnualFees')}
          </Typography>
          <Spacer height='xxxs' />
          <Card className='tools-chart-output-box'>
            <Grid mt={2} container direction='column'>
              <ChartBar
                height={200}
                width={300}
                formatY={typeof results !== 'undefined' ? formatPercent : () => ''}
                yAxisLabel={t('PercentOfAccountValue') as string}
                yAxisLabelOffset={35}
                barLabel={<BarLabel />}
                formatLabel={formatPercent}
                xTickLabelsStyle={{
                  fontWeight: 'bold',
                  fontSize: 10,
                }}
                data={chartData}
              />
            </Grid>
          </Card>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default IraFeeCheckerResults;

const getYourIRAColor = (yourIRA: number, averageIRA: number): string => {
  if (yourIRA > averageIRA) return Colors.errorBase;
  else if (yourIRA < averageIRA) return Colors.secondaryBase;
  return Colors.primaryBase;
};
