import React from 'react';
import { Api, Hooks } from '@3nickels/data-modules';
import { TableContainer, Table, TableBody, Grid, Typography } from '@mui/material';
import { Button } from '../../../../components/buttons/Button';
import { t } from 'i18next';
import SkeletonChurchResults, { SkeletonChurch } from './skeletons/SkeletonChurchResults';

export interface ChurchResultsProps {
  churches: Api.TithelyResults[];
}

const ChurchResults: React.FC<ChurchResultsProps> = ({ churches }) => {
  const {
    displayedChurches,
    loadingChurches,
    loadingMoreChurches,
    searchString,
    canLoadMore,
    loadNextChurches,
  } = Hooks.useTithely();

  if ((searchString?.length ?? 0) < 2) return <></>;

  if (loadingChurches) {
    return (
      <Grid>
        <Typography mb={2} variant='p18Bold' color='primary'>
          {t('Results')}
        </Typography>
        <SkeletonChurchResults />
      </Grid>
    );
  }

  if (churches.length === 0) {
    return (
      <Grid>
        <NoChurchesFound />
      </Grid>
    );
  }

  return (
    <Grid>
      <Typography mb={2} variant='p18Bold' color='primary'>
        {t('Results(X)', { x: churches.length.toLocaleString() })}
      </Typography>
      <TableContainer className='search-results-container'>
        <Table className='search-results-table'>
          <TableBody className='search-results-table-body'>
            {displayedChurches.map((church) => {
              return (
                <Grid className='search-results-row'>
                  <Typography
                    className='hover-clickable'
                    variant='p16'
                    color='secondary'
                    onClick={() => {
                      if (church.forms) {
                        const formData = church.forms[0];
                        return window.open(formData?.url ?? '');
                      }
                    }}>
                    {church.organization?.name ?? ''}
                  </Typography>
                </Grid>
              );
            })}
            {loadingMoreChurches && <SkeletonChurch />}
            {canLoadMore && !loadingMoreChurches && (
              <Button
                style={{ marginTop: '10px' }}
                fullWidth
                variant='ghost'
                color='primary'
                label={t('LoadMoreResults') as string}
                onClick={loadNextChurches}
              />
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

const NoChurchesFound: React.FC = () => {
  return (
    <TableContainer className='search-results-container'>
      <Table className='search-results-table'>
        <TableBody
          className='search-results-table-body'
          style={{ display: 'flex', justifyContent: 'center' }}>
          <Typography variant='p16' color='secondary'>
            {t('WeCouldntFindAnyChurches')}
          </Typography>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ChurchResults;
