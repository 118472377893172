import { Grid } from '@mui/material';
import { LayoutContext, withLayoutMeta } from '../../../../../types/LayoutMeta';
import { MedicalBenefitsContent } from '../MedicalBenefitsContent';
import CardButton from '../components/CardButton';
import { useNavigate, useOutletContext } from 'react-router';
import { Hooks } from '@3nickels/data-modules';
import { useCallback, useEffect } from 'react';

const StepThreeWrapper: React.FC = () => {
  const navigate = useNavigate();
  const { meta, setMeta } = useOutletContext<LayoutContext>();
  const { age, significantMedicalNeeds, calculateSuggestedBenefits } =
    Hooks.useMedicalBenefitsContext();

  const onSubmit = useCallback(() => {
    if (significantMedicalNeeds) {
      updateContext(true);
    } else {
      if (age >= 46) {
        updateContext(true);
      } else {
        updateContext(false);
      }
    }
  }, [significantMedicalNeeds]);

  const updateContext = async (saveToApi: boolean) => {
    if (saveToApi) {
      calculateSuggestedBenefits(3);
      navigate('/tools/medical/medical-benefit-selector-tool/summary');
    } else {
      navigate('/tools/medical/medical-benefit-selector-tool/4');
    }
  };

  useEffect(() => {
    setMeta(() => ({
      stepNumber: age >= 46 ? 2 : 3,
      showNext: true,
      onNext: onSubmit,
      disableNextButton: significantMedicalNeeds === undefined,
    }));
  }, [significantMedicalNeeds]);

  const MedicalBenfitsStepThree = withLayoutMeta(MedicalBenefitsSelectorStepThree, meta);
  return <MedicalBenfitsStepThree />;
};

const MedicalBenefitsSelectorStepThree: React.FC = () => {
  const { age, significantMedicalNeeds, setSignificantMedicalNeeds } =
    Hooks.useMedicalBenefitsContext();

  const onClickYes = () => {
    if (significantMedicalNeeds) {
      setSignificantMedicalNeeds(undefined);
    } else {
      setSignificantMedicalNeeds(true);
    }
  };

  const onClickNo = () => {
    if (significantMedicalNeeds === undefined || significantMedicalNeeds) {
      setSignificantMedicalNeeds(false);
    } else {
      setSignificantMedicalNeeds(undefined);
    }
  };

  return (
    <MedicalBenefitsContent
      questionNumber={age >= 46 ? 2 : 3}
      questionTitle={'DoYouAnticipateSpecializedTreatments'}
      questionContent={
        <Grid display='flex' flexDirection='row' width='100%'>
          <Grid container display='flex' flexDirection='column' width='100%' alignItems='center'>
            <CardButton checked={significantMedicalNeeds} onClick={onClickYes} label={'Yes'} />
            <CardButton
              checked={significantMedicalNeeds === false}
              onClick={onClickNo}
              label={'No'}
            />
          </Grid>
        </Grid>
      }
    />
  );
};

export default StepThreeWrapper;
