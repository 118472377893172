/* eslint-disable @typescript-eslint/no-explicit-any */
import { Backdrop, Box, Grid, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import {
  Data,
  Domain,
  Hooks,
  ObservableGuard,
  useObservableGuardCondition,
} from '@3nickels/data-modules';
import { FormLoader } from '../../../../components/FormLoader';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import FormContent from '../../../../components/form/FormContent';
import { WizardStep, useObservable } from '@aesop-fables/scrinium';
import { WizardFooter } from '../../../../components/form/WizardFooter';
import { Loading, useLoading } from '../../../../hooks/useLoading';
import { EditViewProps, getWizardFooterProps } from '../../../EditViewUtils';
import { map } from 'rxjs';
import RetirementPlanContributionsForm from './RetirementPlanContributionsForm';
import { LayoutMeta, withLayoutMeta } from '../../../../types/LayoutMeta';
import { AccountDetailsLayoutMeta } from '../../AccountDetailsLayout';
import { cleanWholeNumberStr } from '../../../../helpers/utilityFunctions';
import { Margins, Spacing } from '../../../../themes';
import { useRetirementPlanMeta } from '../../../../hooks/useRetirementPlanMeta';
import { IraContributionsEditView } from './ira/IraContributionsEditView';
import { useRetirementPlanPath } from '../../../../hooks/useRetirementPlanPath';
import HelpTitle from '../../../../components/HelpTitle';
import HelpPopover, { HelpPopoverText } from '../../../../components/HelpPopover';
import BulletedList from '../../../../components/BulletedList';
import { Spacer } from '../../../../components';
import { useMessage } from '../../../../hooks/useMessage';
import { SpouseIncomeEditView } from './SpouseIncomeEditView';
import { t } from 'i18next';

const accountContributionsSchema = (eligibility: Domain.AccountEligibility) => {
  const anyEligibility =
    eligibility.paycheckContribution?.preTax ||
    eligibility.paycheckContribution?.roth ||
    eligibility.paycheckContribution?.postTax;
  return Yup.object({
    eligibleForContributions: anyEligibility
      ? Yup.string()
          .required(t('Required') as string)
          .test('eligibleForContributions', t('Required') as string, (eligibleForContributions) => {
            return eligibleForContributions === 'true' || eligibleForContributions === 'false';
          })
      : Yup.string().notRequired(),
    paycheckContributions: Yup.string().when('eligibleForContributions', {
      is: 'true',
      then: (schema) =>
        schema
          .required(t('Required') as string)
          .test('paycheckContributions', t('Required') as string, (paycheckContributions) => {
            return paycheckContributions === 'true' || paycheckContributions === 'false';
          }),
    }),
    contributionType: Yup.string().when(['eligibleForContributions', 'paycheckContributions'], {
      is: (eligibleForContributions: string, paycheckContributions: string) =>
        eligibleForContributions === 'true' && paycheckContributions === 'true',
      then: (schema) =>
        schema
          .required(t('Required') as string)
          .test('contributionType', t('Required') as string, (contributionType) => {
            return (
              contributionType === Domain.EligibleContributionTypeEnum.Dollar ||
              contributionType === Domain.EligibleContributionTypeEnum.Percent
            );
          }),
    }),
    rothContributionsAllowed: eligibility.eligiblePlanTaxTypes?.roth
      ? Yup.string()
          .required(t('Required') as string)
          .test('rothContributionsAllowed', t('Required') as string, (rothContributionsAllowed) => {
            return rothContributionsAllowed === 'true' || rothContributionsAllowed === 'false';
          })
      : Yup.string().notRequired(),

    afterTaxContributionsAllowed: eligibility.eligiblePlanTaxTypes?.postTax
      ? Yup.string()
          .required(t('Required') as string)
          .test(
            'afterTaxContributionsAllowed',
            t('Required') as string,
            (afterTaxContributionsAllowed) => {
              return (
                afterTaxContributionsAllowed === 'true' || afterTaxContributionsAllowed === 'false'
              );
            }
          )
      : Yup.string().notRequired(),
    annualContribPreTaxDollar: Yup.string().notRequired(),
    annualContribPreTaxPercent: Yup.string().notRequired(),
    annualContribRothDollar: Yup.string().notRequired(),
    annualContribRothPercent: Yup.string().notRequired(),
    annualContribAfterTaxDollar: Yup.string().notRequired(),
    annualContribAfterTaxPercent: Yup.string().notRequired(),
  });
};

export const RetirementPlanContributionsEditViewWrapper: React.FC<EditViewProps> = ({
  editing,
  onBack,
}) => {
  const { loading, currentStep, wizard, params } =
    Hooks.useRetirementWizard<Data.InvestmentAccounts.AccountContributionsFormData>();
  const {
    basic: { name, owner },
  } = Hooks.useRetirementWizardData();
  const navigationState = Hooks.useRetirementWizardNavigation();
  const isStarted = useObservable(wizard.isStarted$);
  const { id, type: planType } = useParams();
  const type =
    planType && Object.keys(Domain.accountTypeKeyToPlanTypeId).includes(planType)
      ? Domain.accountTypeKeyToPlanTypeId[
          planType as keyof typeof Domain.accountTypeKeyToPlanTypeId
        ]
      : params?.type ?? Domain.PlanTypeEnum['#457'];
  const isIra = Domain.isIRA(params?.type);
  useRetirementPlanMeta(editing);

  const isSpouse = owner === 'spouse';
  const spouseData = Hooks.useSpouseData();
  const contributions = currentStep?.model;
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const [submitForm, setSubmitForm] = useState<() => void>(() => () => {});
  const [disableNext, setDisableNext] = useState<boolean>();
  const [spouseEligibleZeroIncome, setSpouseEligibleZeroIncome] = useState<boolean>(false);
  const [eligibleForContributions, setEligibleForContributions] = useState<boolean | undefined>(
    undefined
  );

  const handleFormSubmit = () => {
    submitForm && submitForm();
  };

  useEffect(() => {
    setEligibleForContributions(contributions?.eligibleForContributions);
  }, []);

  useEffect(() => {
    if (isSpouse && (spouseData?.annualIncome ?? 0) <= 0 && eligibleForContributions) {
      setSpouseEligibleZeroIncome(true);
    } else {
      setSpouseEligibleZeroIncome(false);
    }
  }, [spouseData?.annualIncome, eligibleForContributions, isSpouse]);

  useEffect(() => {
    // ensure type is initialized
    if (
      typeof isStarted !== 'undefined' &&
      !isStarted &&
      type &&
      Domain.PlanTypeEnumMap.get(type)
    ) {
      wizard.start({ id: parseInt(id ?? ''), type });
    } else if (isStarted) {
      wizard.selectStep('contributions');
    }
  }, [isStarted, type]);

  return (
    <ObservableGuard
      predicate$={wizard.current$.pipe(
        // guard against incorrect step to prevent incorrect default values when navigating from another step
        map((current) => current?.key === 'contributions')
      )}
      loadingFn={() => (
        <Backdrop open>
          <Loading />
        </Backdrop>
      )}>
      {currentStep?.key === 'contributions' && // don't even mount until correct step is set
        params &&
        navigationState?.eligibility && (
          // load params before mounting to prevent flickering
          <FormLoader loading={loading}>
            {isIra ? (
              <IraContributionsEditView
                currentStep={currentStep}
                wizard={wizard}
                editing={editing}
                onBack={onBack}
                params={params}
                name={name}
              />
            ) : (
              <RetirementPlanContributionsEditView
                currentStep={currentStep}
                wizard={wizard}
                editing={editing}
                onBack={onBack}
                params={params}
                name={name}
                isSpouse={isSpouse}
                eligibility={navigationState.eligibility}
                eligibleForContributions={eligibleForContributions}
                setEligibleForContributions={setEligibleForContributions}
                setSubmitForm={setSubmitForm}
                setDisableNext={setDisableNext}
                spouseEligibleZeroIncome={spouseEligibleZeroIncome}
              />
            )}
            {spouseEligibleZeroIncome && (
              <SpouseIncomeEditView
                editing={editing}
                spouseEligibleZeroIncome={spouseEligibleZeroIncome}
              />
            )}
            {!isIra && (
              <WizardFooter
                color={editing ? 'primary' : undefined}
                disableBack={false}
                onBack={onBack}
                disableNext={spouseEligibleZeroIncome || disableNext}
                onDone={handleFormSubmit}
                {...getWizardFooterProps('Next', editing)}
              />
            )}
          </FormLoader>
        )}
    </ObservableGuard>
  );
};

interface RetirementPlanContributionsEditViewProps extends EditViewProps {
  currentStep: WizardStep<
    Data.InvestmentAccounts.AccountContributionsFormData,
    Data.InvestmentAccounts.InvestmentAccountWizardParams
  >;
  wizard: Data.InvestmentAccounts.RetirementWizard;
  params: Data.InvestmentAccounts.InvestmentAccountWizardParams;
  eligibility: Domain.AccountEligibility;
  eligibleForContributions?: boolean;
  setEligibleForContributions: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  setSubmitForm: React.Dispatch<React.SetStateAction<() => void>>;
  setDisableNext: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  spouseEligibleZeroIncome: boolean;
}

const RetirementPlanContributionsEditView: React.FC<RetirementPlanContributionsEditViewProps> = ({
  currentStep,
  wizard,
  params,
  name,
  isSpouse,
  eligibility,
  eligibleForContributions,
  setEligibleForContributions,
  setSubmitForm,
  setDisableNext,
  spouseEligibleZeroIncome,
  editing,
  onBack,
}) => {
  const { t } = useTranslation();
  const { setLoading } = useLoading();
  const navigate = useNavigate();
  const ready = useObservableGuardCondition();
  const path = useRetirementPlanPath();
  const methods = useForm<Data.InvestmentAccounts.AccountContributionsFormData>({
    defaultValues: { ...currentStep?.model },
    resolver: yupResolver(accountContributionsSchema(eligibility)),
  });
  const { showMessage, hideMessage } = useMessage();

  const formatInputs = (values: any) => {
    const eligibleForContributions = values['eligibleForContributions'] === 'true';
    const rothContributionsAllowed = values['rothContributionsAllowed'] === 'true';
    const afterTaxContributionsAllowed = values['afterTaxContributionsAllowed'] === 'true';
    const annualContribPreTaxDollar = cleanWholeNumberStr(
      values['annualContribPreTaxDollar'] ?? ''
    );
    const annualContribPreTaxPercent = cleanWholeNumberStr(
      values['annualContribPreTaxPercent'] ?? ''
    );
    const annualContribRothDollar = cleanWholeNumberStr(values['annualContribRothDollar'] ?? '');
    const annualContribRothPercent = cleanWholeNumberStr(values['annualContribRothPercent'] ?? '');
    const annualContribAfterTaxDollar = cleanWholeNumberStr(
      values['annualContribAfterTaxDollar'] ?? ''
    );
    const annualContribAfterTaxPercent = cleanWholeNumberStr(
      values['annualContribAfterTaxPercent'] ?? ''
    );
    const paycheckContributions =
      values['paycheckContributions'] === 'true' &&
      (values.contributionType === Domain.EligibleContributionTypeEnum.Percent
        ? annualContribPreTaxPercent > 0 ||
          (rothContributionsAllowed && annualContribRothPercent > 0) ||
          (afterTaxContributionsAllowed && annualContribAfterTaxPercent > 0)
        : annualContribPreTaxDollar > 0 ||
          (rothContributionsAllowed && annualContribRothDollar > 0) ||
          (afterTaxContributionsAllowed && annualContribAfterTaxDollar > 0));
    let formatted: Partial<Data.InvestmentAccounts.AccountContributionsFormData> = {
      eligibleForContributions,
      preTaxContributionsAllowed: eligibleForContributions,
      paycheckContributions,
      annualContribPreTaxDollar,
      annualContribPreTaxPercent,
      annualContribRothDollar,
      annualContribRothPercent,
      annualContribAfterTaxDollar,
      annualContribAfterTaxPercent,
    };

    // only send roth/afterTax allowed if different from currentValue
    if (currentStep.model.rothContributionsAllowed !== values['rothContributionsAllowed']) {
      formatted = {
        ...formatted,
        rothContributionsAllowed: values['rothContributionsAllowed'] === 'true',
      };
    }
    if (currentStep.model.afterTaxContributionsAllowed !== values['afterTaxContributionsAllowed']) {
      formatted = {
        ...formatted,
        afterTaxContributionsAllowed: values['afterTaxContributionsAllowed'] === 'true',
      };
    }

    return formatted;
  };

  const onSubmit = async (values: any) => {
    setLoading(true);

    try {
      const cleanedValues = formatInputs(values);
      currentStep.save({
        ...values,
        ...cleanedValues,
        paycheckContributions: values.paycheckContributions,
      });
      await wizard.commitStep('contributions');

      if (editing && onBack) {
        onBack();
        return;
      }

      const wizardPlanType =
        Domain.planTypeIdToAccountKey[params.type as keyof typeof Domain.planTypeIdToAccountKey];

      navigate(`/account-details/retirement-savings-plan/${path}/${wizardPlanType}/${params.id}`);
    } finally {
      setLoading(false);
    }
  };

  const handleEligiblityChange = (isEligible: boolean, spouseIncome: number) => {
    if (isSpouse && isEligible && spouseIncome <= 0) {
      showMessage(t('PleaseAddSpousesIncome') as string, 'warning');
    } else {
      hideMessage();
    }
  };

  useEffect(() => {
    setSubmitForm(() => methods.handleSubmit(onSubmit));
  }, [methods, onSubmit, setSubmitForm]);

  return (
    <>
      {ready && (
        <FormContent formProviderProps={methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Box>
              {/* Should be about 30px */}
              <HelpTitle
                text='AnnualContributions'
                helpContext={
                  <HelpPopover title={t('AnnualContributions')}>
                    <HelpPopoverText>
                      {t('ThereAreManyDifferentTypesOfRetirementPlans')}
                    </HelpPopoverText>
                    <Spacer height='xxxs' />
                    <BulletedList
                      style={Margins.mt_xxs}
                      children={[
                        t('TheThreeTypesOfContributions'),
                        t('NotAllPlansAllowForAllTypesOfContributions'),
                        <Trans
                          i18nKey='SaveAPercentageOfYourIncomeStyled'
                          components={{
                            Styled: <strong />,
                          }}
                        />,
                      ]}
                    />
                    <Spacer height='xxs' />
                    <HelpPopoverText>
                      {t('WeUseTheContributionsInOurProjectedSimulations')}
                    </HelpPopoverText>
                  </HelpPopover>
                }
              />
              <Typography variant='p18Bold' color='secondary' mt={Spacing.xxxs}>
                {name}
              </Typography>

              <Grid container justifyContent='center' mt={2}>
                <Grid item sm={editing ? 10 : 6}>
                  <RetirementPlanContributionsForm
                    contributions={currentStep.model}
                    isSpouse={isSpouse}
                    eligibility={eligibility}
                    onEligibilityChange={handleEligiblityChange}
                    eligibleForContributions={eligibleForContributions}
                    setEligibleForContributions={setEligibleForContributions}
                    spouseEligibleZeroIncome={spouseEligibleZeroIncome}
                    currentStep={currentStep}
                    params={params}
                    editing={editing}
                    setDisableNext={setDisableNext}
                  />
                </Grid>
              </Grid>
            </Box>
          </form>
        </FormContent>
      )}
    </>
  );
};

const meta = {
  nextLocaleKey: 'Next',
  headerVariant: 'hex',
} satisfies LayoutMeta<AccountDetailsLayoutMeta>;

export default withLayoutMeta(RetirementPlanContributionsEditViewWrapper, meta);
