import React from 'react';
import { Domain, Hooks } from '@3nickels/data-modules';
import { TableContainer, Table, TableBody, Grid, Typography } from '@mui/material';
import { t } from 'i18next';
import SkeletonChurchResults, {
  SkeletonChurch,
} from '../gifts/give-to-your-church/skeletons/SkeletonChurchResults';
import LessonResultCard from './LessonResultCard';

export interface LessonResultsProps {
  displayedLessons: Domain.LessonDto[];
}

const LessonResults: React.FC<LessonResultsProps> = ({ displayedLessons }) => {
  const { loadingLessons } = Hooks.useFilteredLessonsContext();
  const lmsCourses = Hooks.useAllCourses();

  if (loadingLessons || typeof lmsCourses === 'undefined') {
    return (
      <Grid>
        <Typography mb={2} variant='p18Bold' color='primary'>
          {t('Lessons')}
        </Typography>
        <SkeletonChurchResults />
      </Grid>
    );
  }

  if (displayedLessons.length === 0) {
    return (
      <Grid>
        <NoLessonsFound />
      </Grid>
    );
  }

  return (
    <Grid>
      <Typography variant='p18Bold' color='primary'>
        {t('Lessons(X)', { x: displayedLessons.length.toLocaleString() })}
      </Typography>
      {displayedLessons.map((lesson) => {
        return <LessonResultCard lesson={lesson} courses={lmsCourses} />;
      })}
      {loadingLessons && <SkeletonChurch />}
    </Grid>
  );
};

const NoLessonsFound: React.FC = () => {
  const pathnameSegments = location.pathname.split('/');
  const toolsIndex = pathnameSegments.indexOf('tools');
  const module = pathnameSegments[toolsIndex + 1];

  return (
    <TableContainer className='search-results-container'>
      <Table className='search-results-table'>
        <TableBody
          className='search-results-table-body'
          style={{ display: 'flex', justifyContent: 'center' }}>
          <Typography variant='p16' color='secondary'>
            {t('ThereAreNoLessons', { module: module })}
          </Typography>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default LessonResults;
