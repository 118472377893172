import { Button, Card, Divider, FormGroup, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { logo3NSmall } from '../assets/png';
import { Column, PasswordRequirements, Row, Spacer } from '../components';
import { ProfileData } from '../models/ProfileData';
import { useEffect, useState } from 'react';
import {
  AuthWizardStateDefault,
  SignUpLoginWizard,
  signUpLoginWizardKey,
} from '../services/signUpLogin';
import dayjs from 'dayjs';
import { mobileMargin } from '../App';
import { useDeviceType } from '../hooks/useDeviceType';
import { AxiosError } from 'axios';
import { useService } from '@aesop-fables/containr-react';
import { Api, Domain, formatDateForApi, Hooks } from '@3nickels/data-modules';
import AppleLogin from '../login/AppleLogin';
import GoogleLogin from '../login/GoogleLogin';
import { useMessage } from '../hooks/useMessage';
import * as Yup from 'yup';
import TextInput from '../components/form/TextInput';
import FormContent from '../components/form/FormContent';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { formatMalformedDate, isValidDate } from '../helpers/utilityFunctions';
import { t } from 'i18next';
import { PricebookApi } from '../api/apis/PricebookApi';
import { ApiKeys } from '../api/apis/ApiKeys';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import { Colors } from '../themes';
import { useObservable } from '@aesop-fables/scrinium';

interface MemberSignupToken {
  firstName: string;
  lastName: string;
  birthDate: string;
  email: string;
  orgId: number;
  memberSignupLeadId: number;
  transactionId: string;
}

export const passwordRegex =
  /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[*!@#$%^&(){}[\]:;<>,.?/~_+\-=|'"]).{8,32}$/;

const checkIfDomainMatches = (inputDomain: string, expectedDomain: string) => {
  inputDomain = inputDomain.trim().toUpperCase();
  expectedDomain = expectedDomain.trim().toUpperCase();
  if (expectedDomain.startsWith('*')) {
    const suffix = expectedDomain.substring(1);
    return inputDomain.endsWith(suffix);
  } else return expectedDomain === inputDomain;
};

export interface PasswordConfirmData {
  password?: string;
  rePassword?: string;
}
const CreateAccount = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isMobile, isTablet } = useDeviceType();
  const { showMessage } = useMessage();

  const memberRestrictions = Hooks.useOrganizationMemberRestrictions();

  const emailMustMatchDomainMsg =
    t('EmailMustMatchDomain').toString() + memberRestrictions?.domains?.at(0);
  const emailMustMatchOneOfDomainsMsg =
    t('EmailMustMatchOneOfDomains').toString() + memberRestrictions?.domains?.join(', ');

  const [hasMinChar, setHasMinChar] = useState(false);
  const [hasANumber, setHasANumber] = useState(false);
  const [hasUpperCase, setHasUpperCase] = useState(false);
  const [hasLowerCase, setHasLowerCase] = useState(false);
  const [hasSpecialChar, setHasSpecialChar] = useState(false);
  const [tokenData, setTokenData] = useState<MemberSignupToken | null>(null);
  const [emailHelperText, setEmailHelperText] = useState<string | undefined>(undefined);

  const schema = () =>
    Yup.object({
      firstName: Yup.string().required(t('Required') as string),
      lastName: Yup.string().required(t('Required') as string),
      birthdate: Yup.string()
        .required(t('Required') as string)
        .test('validDate', t('MustBeAValidDate') as string, (birthdate) => {
          return isValidDate(birthdate);
        })
        .test('birthdate', t('MustBe12YearsOrOlder') as string, (birthdate) => {
          const date = formatMalformedDate(birthdate);
          return dayjs().subtract(12, 'y').isSameOrAfter(dayjs(date), 'day');
        })
        .test('birthdate', t('MustBe109YearsOrYounger') as string, (birthdate) => {
          const date = formatMalformedDate(birthdate);
          return dayjs().subtract(109, 'y').isSameOrBefore(dayjs(date), 'day');
        }),
      emailAddress: Yup.string()
        .test(
          'meets-domain-member-restriction',
          (memberRestrictions?.domains?.length ?? 0) > 1
            ? emailMustMatchOneOfDomainsMsg
            : emailMustMatchDomainMsg,
          (value) => {
            if (memberRestrictions?.memberAccess !== Domain.RestrictionEnum.DOMAIN) return true;
            if (!value) return false;

            const inputDomain = value.substring(value.indexOf('@') + 1);
            return memberRestrictions?.domains?.some((expectedDomain) =>
              checkIfDomainMatches(inputDomain, expectedDomain)
            );
          }
        )
        .required(t('Required') as string)
        .test('valid-email', t('InvalidEmail') as string, (value) => {
          return /\S+@\S+\.\S+/.test(value);
        }),
      password: Yup.string()
        .required(t('Required') as string)
        .min(8, t('FollowPasswordLength') as string)
        .max(32, t('FollowPasswordLength') as string)
        .matches(/[0-9]/, t('FollowPasswordLength') as string)
        .matches(/[A-Z]/, t('FollowPasswordLength') as string)
        .matches(/[a-z]/, t('FollowPasswordLength') as string)
        .matches(/[*!@#$%^&(){}[\]:;<>,.?/~_+\-=|'"]/, t('FollowPasswordLength') as string)
        .test('password-requirements', t('FollowPasswordLength') as string, (value) => {
          return passwordRegex.test(value);
        }),
      rePassword: Yup.string()
        .required(t('Required') as string)
        .oneOf([Yup.ref('password')], t('PasswordDoesNotMatch') as string),
    });

  const methods = useForm<ProfileData>({
    resolver: yupResolver(schema()),
  });

  const setEmailHelperTextBasedOnMemberAccess = () => {
    switch (memberRestrictions?.memberAccess) {
      case Domain.RestrictionEnum.DOMAIN:
        if (!memberRestrictions.domains || memberRestrictions.domains.length === 0) return;

        if (memberRestrictions.domains?.length === 1) {
          setEmailHelperText(emailMustMatchDomainMsg);
        } else {
          setEmailHelperText(emailMustMatchOneOfDomainsMsg);
        }
        break;
      case Domain.RestrictionEnum.LIST:
        setEmailHelperText(t('EmailMustMatchListOfMemberEmails').toString());
        break;
      default:
        setEmailHelperText(undefined);
        break;
    }
  };

  useEffect(() => {
    setEmailHelperTextBasedOnMemberAccess();
  }, [memberRestrictions]);

  useEffect(() => {
    if (methods.formState.errors.emailAddress?.message) {
      setEmailHelperText(methods.formState.errors.emailAddress?.message?.toString());
    } else {
      setEmailHelperTextBasedOnMemberAccess();
    }
  }, [methods.formState.errors.emailAddress?.message]);

  useEffect(() => {
    const queryString = location.search;
    const urlParams = new URLSearchParams(queryString);
    const pricebookId = urlParams.get('pricebookId');
    if (pricebookId) {
      const selectPricebook = async () => await pricebookApi.selectPricebook(parseInt(pricebookId));
      selectPricebook();
    }
  }, [location.search]);

  const updatePasswordRequirements = (passwordInput: string) => {
    setHasMinChar(passwordInput.length >= 8 && passwordInput.length <= 32);
    setHasANumber(/[0-9]/.test(passwordInput));
    setHasUpperCase(/[A-Z]/.test(passwordInput));
    setHasLowerCase(/[a-z]/.test(passwordInput));
    setHasSpecialChar(/[*!@#$%^&(){}[\]:;<>,.?/~_+\-=|'"]/.test(passwordInput));
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPassword = event.target.value;
    updatePasswordRequirements(newPassword);
  };

  const signUpLoginWizard = useService<SignUpLoginWizard>(signUpLoginWizardKey);
  const state = useObservable(signUpLoginWizard.state$) ?? AuthWizardStateDefault;
  const userInfo = state.registerData;

  const organizationApi = useService<Api.OrganizationApi>(Api.ApiKeys.Organization);
  const pricebookApi = useService<PricebookApi>(ApiKeys.Pricebook);
  const organizationData = Hooks.useOrganizationData();

  useEffect(() => {
    const loadMemberSignupToken = () => {
      try {
        const token = Cookies.get('memberSignupToken');

        if (!token) {
          setTokenData(null);
          return;
        }

        const decoded = jwtDecode<MemberSignupToken>(token);
        setTokenData(decoded);
      } catch (err) {
        console.error('Error decoding memberSignupToken:', err);
      }
    };

    loadMemberSignupToken();
  }, []);

  useEffect(() => {
    if (tokenData) {
      const birthdate = tokenData.birthDate
        ? dayjs(tokenData.birthDate).format('MM/DD/YYYY')
        : undefined;

      methods.setValue('firstName', tokenData.firstName);
      methods.setValue('lastName', tokenData.lastName);
      methods.setValue('birthdate', birthdate);
      methods.setValue('emailAddress', tokenData.email);
    }
  }, [tokenData, methods]);

  useEffect(() => {
    if (userInfo) {
      const birthdate = userInfo.birthdate
        ? dayjs(userInfo.birthdate).format('MM/DD/YYYY')
        : undefined;
      methods.setValue('firstName', userInfo.firstName);
      methods.setValue('lastName', userInfo.lastName);
      methods.setValue('birthdate', birthdate);
      methods.setValue('emailAddress', userInfo.emailAddress);
      methods.setValue('password', userInfo.password);
      methods.setValue('rePassword', userInfo.password);
      updatePasswordRequirements(userInfo.password ?? '');
    }
  }, [userInfo]);

  const navBack = () => {
    navigate(-1);
  };

  const onSubmit = async (values: ProfileData) => {
    const { firstName, lastName, emailAddress, password } = values;
    try {
      await signUpLoginWizard.setAccountInfoAndAttemptSignUp(
        firstName ?? '',
        lastName ?? '',
        formatDateForApi(values.birthdate ?? ''),
        emailAddress ?? '',
        password ?? ''
      );
      navigate('/secure-account');
    } catch (err) {
      const error = err as AxiosError;
      if (error?.response?.status === 400) {
        showMessage(t('InvalidEmailPleaseTryLoggingIn') as string, 'error');
      }
    }
  };

  return (
    <FormContent formProviderProps={methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Column style={{ margin: isMobile || isTablet ? mobileMargin : '15px 100px' }}>
          <Row style={{ alignItems: 'center', justifyContent: 'space-between', height: '75px' }}>
            {organizationData ? (
              organizationData.logoUri ? (
                <img
                  src={organizationApi.getLogo()}
                  alt={organizationData?.name}
                  style={{ height: '60%' }}
                />
              ) : (
                <Typography variant='p25Bold' color='secondary'>
                  {organizationData?.name}
                </Typography>
              )
            ) : (
              <Card variant='ghost' />
            )}
            <img src={logo3NSmall} alt='logo' style={{ height: '70%' }} />
          </Row>

          <Typography variant='p30Bold' color='primary'>
            {t('CreateYourAccount')}
          </Typography>
          <Spacer height='xs' />
          <Typography variant='h2' color='secondary'>
            {t('LetsSetUpYour3NickelsAccount')}
          </Typography>
          <Spacer height='xs' />
          {isMobile || isTablet ? (
            <>
              <Column style={{ justifyContent: 'center' }}>
                <AppleLogin />
                <Spacer height='xs' />
                <GoogleLogin />
              </Column>
              <Spacer height='xs' />
              <Divider color='secondary' flexItem>
                <Typography variant='p18Bold' color='secondary'>
                  OR
                </Typography>
              </Divider>
              <Spacer height='xs' />
              <FormGroup
                style={{
                  alignSelf: 'center',
                  width: isMobile || isTablet ? '90%' : '70%',
                  maxWidth: isMobile ? '' : '650px',
                }}>
                <TextInput<ProfileData>
                  error={methods.formState.errors.firstName !== undefined}
                  helperText={methods.formState.errors.firstName?.message?.toString()}
                  inputProps={{ maxLength: 32 }}
                  placeholder={t('FirstName').toString()}
                  label='FirstName'
                  name='firstName'
                  autoFocus
                />
                <Spacer height='sm' />
                <TextInput<ProfileData>
                  error={methods.formState.errors.lastName !== undefined}
                  helperText={methods.formState.errors.lastName?.message?.toString()}
                  inputProps={{ maxLength: 32 }}
                  placeholder={t('LastName').toString()}
                  label='LastName'
                  name='lastName'
                />
                <Spacer height='sm' />
                <TextInput<ProfileData>
                  error={methods.formState.errors.birthdate !== undefined}
                  helperText={methods.formState.errors.birthdate?.message?.toString()}
                  helpContext={<HelpText />}
                  label='DateOfBirth'
                  name='birthdate'
                  type='dateInput'
                />
                <Spacer height='sm' />
                <TextInput<ProfileData>
                  autoComplete='new-password'
                  error={methods.formState.errors.emailAddress !== undefined}
                  helperText={emailHelperText}
                  FormHelperTextProps={{
                    style: {
                      color:
                        methods.formState.errors.emailAddress !== undefined
                          ? Colors.errorBase
                          : Colors.secondaryBase,
                    },
                  }}
                  placeholder={t('EmailAddress').toString()}
                  label='EmailAddress'
                  name='emailAddress'
                />
                <Spacer height='sm' />
                <TextInput<ProfileData>
                  error={methods.formState.errors.password !== undefined}
                  helperText={methods.formState.errors.password?.message?.toString()}
                  onChange={handlePasswordChange}
                  inputProps={{ width: '100%' }}
                  placeholder={t('EnterNewPassword').toString()}
                  label='EnterNewPassword'
                  name='password'
                  type='password'
                />
                <Spacer height='sm' />
                <PasswordRequirements
                  hasMinChar={hasMinChar}
                  hasSpecialChar={hasSpecialChar}
                  hasANumber={hasANumber}
                  hasUpperCase={hasUpperCase}
                  hasLowerCase={hasLowerCase}
                />
                <Spacer height='sm' />
                <TextInput<ProfileData>
                  error={methods.formState.errors.rePassword !== undefined}
                  helperText={methods.formState.errors.rePassword?.message?.toString()}
                  inputProps={{ width: '100%' }}
                  placeholder={t('ReEnterNewPassword').toString()}
                  label='ReEnterNewPassword'
                  name='rePassword'
                  type='password'
                />
              </FormGroup>
              <Spacer height='lg' />
              <Column style={{ justifyContent: 'space-between' }}>
                <Button type='submit' color='secondary'>
                  {t('NextSecureYourAccount')}
                </Button>
                <Spacer height='xs' />
                <Button onClick={navBack} variant='outlined' color='secondary'>
                  {t('Cancel')}
                </Button>
              </Column>
            </>
          ) : (
            <>
              <Row>
                <FormGroup
                  style={{
                    alignSelf: 'center',
                    width: isMobile || isTablet ? '90%' : '70%',
                    maxWidth: isMobile ? '' : '650px',
                  }}>
                  <Row style={{ width: '100%' }}>
                    <Column style={{ width: '100%' }}>
                      <TextInput<ProfileData>
                        error={methods.formState.errors.firstName !== undefined}
                        helperText={methods.formState.errors.firstName?.message?.toString()}
                        inputProps={{ maxLength: 32, width: '100%' }}
                        placeholder={t('FirstName').toString()}
                        label='FirstName'
                        name='firstName'
                        autoFocus
                      />
                    </Column>
                    <Spacer height='sm' />
                    <Column style={{ marginLeft: '15px', width: '100%' }}>
                      <TextInput<ProfileData>
                        error={methods.formState.errors.lastName !== undefined}
                        helperText={methods.formState.errors.lastName?.message?.toString()}
                        inputProps={{ maxLength: 32, width: '100%' }}
                        placeholder={t('LastName').toString()}
                        label='LastName'
                        name='lastName'
                      />
                    </Column>
                  </Row>
                  <Spacer height='sm' />
                  <TextInput<ProfileData>
                    error={methods.formState.errors.birthdate !== undefined}
                    helperText={methods.formState.errors.birthdate?.message?.toString()}
                    helpContext={<HelpText />}
                    label='DateOfBirth'
                    name='birthdate'
                    type='dateInput'
                  />
                  <Spacer height='sm' />
                  <TextInput<ProfileData>
                    autoComplete='new-password'
                    error={methods.formState.errors.emailAddress !== undefined}
                    helperText={emailHelperText}
                    FormHelperTextProps={{
                      style: {
                        color:
                          methods.formState.errors.emailAddress !== undefined
                            ? Colors.errorBase
                            : Colors.secondaryBase,
                      },
                    }}
                    placeholder={t('EmailAddress').toString()}
                    label='EmailAddress'
                    name='emailAddress'
                  />
                  <Spacer height='sm' />
                  <TextInput<ProfileData>
                    error={methods.formState.errors.password !== undefined}
                    helperText={methods.formState.errors.password?.message?.toString()}
                    onChange={handlePasswordChange}
                    inputProps={{ width: '100%' }}
                    placeholder={t('EnterNewPassword').toString()}
                    label='EnterNewPassword'
                    name='password'
                    type='password'
                  />
                  <Spacer height='sm' />
                  <PasswordRequirements
                    hasMinChar={hasMinChar}
                    hasSpecialChar={hasSpecialChar}
                    hasANumber={hasANumber}
                    hasUpperCase={hasUpperCase}
                    hasLowerCase={hasLowerCase}
                  />
                  <Spacer height='sm' />
                  <TextInput<ProfileData>
                    error={methods.formState.errors.rePassword !== undefined}
                    helperText={methods.formState.errors.rePassword?.message?.toString()}
                    inputProps={{ width: '100%' }}
                    placeholder={t('ReEnterNewPassword').toString()}
                    label='ReEnterNewPassword'
                    name='rePassword'
                    type='password'
                  />
                  <Spacer height='sm' />
                  <Row style={{ justifyContent: 'space-between' }}>
                    <Button onClick={navBack} variant='outlined' color='secondary'>
                      {t('Cancel')}
                    </Button>
                    <Button type='submit' color='secondary'>
                      {t('NextSecureYourAccount')}
                    </Button>
                  </Row>
                </FormGroup>
                <Divider
                  style={{ margin: '0px 75px' }}
                  color='secondary'
                  orientation='vertical'
                  flexItem>
                  <Typography variant='p18Bold' color='secondary'>
                    OR
                  </Typography>
                </Divider>
                <Column style={{ justifyContent: 'center', alignItems: 'center' }}>
                  <Typography variant='p18Bold' color='secondary'>
                    {t('SignUpAnotherWay')}
                  </Typography>
                  <Spacer height='sm' />
                  <AppleLogin />
                  <Spacer height='xs' />
                  <GoogleLogin />
                </Column>
              </Row>
            </>
          )}
          <Spacer height='lg' />
        </Column>
      </form>
    </FormContent>
  );
};

export const HelpText = () => {
  return (
    <Column style={{ padding: '20px' }}>
      <Typography variant='p16Bold' color='secondary'>
        {t('DateOfBirth')}
      </Typography>
      <Spacer height='xxs' />
      <Typography variant='p16' color='secondary'>
        <li>{t('UsedToOptimizeSavingsAndWithdrawals')}</li>
      </Typography>
      <Spacer height='xxs' />
      <Typography variant='p16' color='secondary'>
        <li>{t('CertainAgesAreImportant')}</li>
      </Typography>
      <Spacer height='xxs' />
      <Typography variant='p16' color='secondary'>
        {t('SoundsBizarreThankOurTaxLaws')}
      </Typography>
    </Column>
  );
};

export default CreateAccount;
