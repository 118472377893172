import { Box, Stack } from '@mui/material';
import React from 'react';
import TextInput from '../../../../components/form/TextInput';
import ToggleRadioButtonGroup from '../../../../components/form/ToggleRadioButtonGroup';
import { Spacing } from '../../../../themes';
import { Data, Hooks } from '@3nickels/data-modules';
import { useFormContext } from 'react-hook-form';
import { t } from 'i18next';

export type HomeBasicsFormProps = {
  homeBasics?: Data.RealEstate.HousingEntityBasicFormData;
  onSubmit: (values: Data.RealEstate.HousingEntityBasicFormData) => Promise<void>;
};

const HomeBasicsForm: React.FC<HomeBasicsFormProps> = (props) => {
  const { formState } = useFormContext();
  const includeSpouse = Hooks.useIncludeSpouse();

  return (
    <Box>
      <Stack spacing={Spacing.xxs}>
        <TextInput<Data.RealEstate.HousingEntityBasicFormData>
          error={formState.errors.name !== undefined}
          helperText={formState.errors.name?.message?.toString()}
          defaultValue={props.homeBasics?.name}
          inputProps={{ maxLength: 64 }}
          label={'HomeNickname'}
          name='name'
          autoFocus
        />
        {includeSpouse && (
          <ToggleRadioButtonGroup<Data.RealEstate.HousingEntityBasicFormData>
            error={formState.errors.owner !== undefined}
            helperText={formState.errors.owner?.message?.toString()}
            label='HomeOwner'
            name='owner'
            row
            defaultValue={props.homeBasics?.owner}
            items={[
              { label: t('Me'), value: 'me' },
              { label: t('Spouse'), value: 'spouse' },
            ]}
          />
        )}
        <TextInput<Data.RealEstate.HousingEntityBasicFormData>
          error={formState.errors.value !== undefined}
          helperText={formState.errors.value?.message?.toString()}
          defaultValue={props.homeBasics?.value}
          label='CurrentHomeValue'
          name='value'
          type='dollar'
        />
      </Stack>
    </Box>
  );
};

export default HomeBasicsForm;
