import { useTranslation } from 'react-i18next';
import { SummaryCardProps } from '../../../Types';
import { Grid, Typography } from '@mui/material';
import { capitalizeFirstLetter } from '../../../../../helpers/utilityFunctions';
import CardDisplay from '../../../../../components/panels/CardDisplay';
import { InvestmentAccountBasicFormData } from '../../Types';

export interface HSABasicsCardProps extends SummaryCardProps {
  accountBasics: InvestmentAccountBasicFormData;
  includeSpouse?: boolean;
}

const HSABasicsCard: React.FC<HSABasicsCardProps> = ({ accountBasics, includeSpouse }) => {
  const { t } = useTranslation();

  return (
    <CardDisplay headerKey='AccountBasics'>
      <Grid container spacing={2}>
        <Grid item xs>
          <Typography variant='h3' component='h3' color='primary'>
            {t('HSANickname')}
          </Typography>
          <Typography color='secondary'>{accountBasics.name}</Typography>
        </Grid>
        {includeSpouse && (
          <Grid item xs>
            <Typography variant='h3' component='h3' color='primary'>
              {t('AccountOwner')}
            </Typography>
            <Typography color='secondary'>
              {t(capitalizeFirstLetter(accountBasics?.owner ?? ''))}
            </Typography>
          </Grid>
        )}
        <Grid item xs />
        <Grid item xs />
        <Grid item xs />
        {!includeSpouse && <Grid item xs />}
      </Grid>
    </CardDisplay>
  );
};

export default HSABasicsCard;
