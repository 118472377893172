/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo, useState } from 'react';
import { Box, Typography } from '@mui/material';
import * as Yup from 'yup';
import { LayoutMeta, withLayoutMeta } from '../../../../types/LayoutMeta';
import { ToolsLayoutMeta } from '../../ToolsLayout';
import FormContent from '../../../../components/form/FormContent';
import { useForm } from 'react-hook-form';
import { Data, Domain, Hooks } from '@3nickels/data-modules';
import { yupResolver } from '@hookform/resolvers/yup';
import { cleanWholeNumberStr, formatWholeDollars } from '../../../../helpers/utilityFunctions';
import { useMessage } from '../../../../hooks/useMessage';
import { useLoading } from '../../../../hooks/useLoading';
import ToolTemplate from '../../../../components/ToolTemplate';
import ToolHeader from '../../../../components/ToolHeader';
import InflationCalculatorForm from './InflationCalculatorForm';
import InflationCalculatorResults from './InflationCalculatorResults';
import { SavedFormsKeyEnum } from '../../../../types/SavedForms';
import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';
import { t } from 'i18next';

const formSchema = () =>
  Yup.object({
    baseValue: Yup.string().notRequired(),
    annualContribution: Yup.string().notRequired(),
    growthRate: Yup.string().notRequired(),
    horizon: Yup.string().required(t('Required') as string),
  });

const InflationCalculatorView: React.FC = () => {
  const { t } = useTranslation();
  const { showMessage } = useMessage();
  const { setLoading } = useLoading();
  const commands = Hooks.useCommands();
  const [pdfParams, setPdfParams] = useState<Domain.GoalsInflationData | undefined>(undefined);
  const [results, setResults] = useState<Domain.GoalsInflationResultData | undefined>(undefined);
  const formService = Hooks.useFormService();
  const formData = Hooks.useSavedFormData<Domain.GoalsInflationData>(
    SavedFormsKeyEnum.InflationCalculator
  );

  const defaultValues = useMemo(() => {
    return {
      baseValue: formData?.baseValue ?? 0,
      annualContribution: (formData?.annualContribution ?? 0) / 12, // frontend asks for a monthly value
      growthRate: formData?.growthRate ?? 0,
      horizon: formData?.horizon,
    };
  }, [formData]);

  const methods = useForm<Domain.GoalsInflationData>({
    resolver: yupResolver(formSchema()),
    defaultValues,
  });

  const formatInputs = (values: any): Domain.GoalsInflationData => {
    const formData: Domain.GoalsInflationData = {
      baseValue: cleanWholeNumberStr(values.baseValue ?? 0),
      annualContribution: cleanWholeNumberStr(values.annualContribution ?? 0) * 12,
      growthRate: cleanWholeNumberStr(values.growthRate ?? 0, { float: true, precision: 3 }),
      horizon: cleanWholeNumberStr(values.horizon ?? 0),
    };

    return formData;
  };

  const onSubmit = async (values: Domain.GoalsInflationData) => {
    setLoading(true);

    try {
      const cleanedValues = formatInputs(values);
      const resultData = await commands.execute(Data.Goals.InflationCalculator, cleanedValues);
      setPdfParams(cleanedValues);
      await formService.addOrUpdateSavedForm({
        toolName: SavedFormsKeyEnum.InflationCalculator,
        formData: cleanedValues,
      });
      setResults(resultData);
    } catch (err) {
      showMessage(t('WeWerentBankingOnThat') as string, 'error');
    } finally {
      setLoading(false);
    }
  };

  const downloadPdf = async () => {
    if (pdfParams) {
      try {
        setLoading(true);
        const response = await commands.execute(Data.Goals.InflationCalculatorPdf, pdfParams);
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: 'application/pdf' })
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'InflationCalculatorResults.pdf');
        document.body.appendChild(link);
        link.click();
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    }
  };

  const initialYear = Domain.currentYear - 1 + (results?.assetRealValues?.length ?? 1);

  if (typeof formData === 'undefined') return null;

  return (
    <FormContent formProviderProps={methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Box>
          <ToolTemplate
            header={
              results && (
                <ToolHeader
                  title={
                    <Typography variant='p30' color='primary' display='inline'>
                      <Trans
                        i18nKey='HeresHowInflationImpactsYourSavings'
                        values={{ initialYear }}
                      />
                    </Typography>
                  }
                  onDownload={downloadPdf}
                />
              )
            }
            inputColumn={
              <InflationCalculatorForm
                methods={methods}
                defaultValues={defaultValues}
                results={results}
                onSubmit={onSubmit}
              />
            }
            outputColumn={<InflationCalculatorResults results={results} />}
            boxItems={[
              {
                boxTitle: 'FutureSavingsValue',
                boxResult: results ? formatWholeDollars(results?.futureDollars ?? 0) : undefined,
              },
              {
                boxTitle: 'SavingsWillFeelLike',
                boxResult: results ? formatWholeDollars(results?.todayDollars ?? 0) : undefined,
              },
            ]}
          />
        </Box>
      </form>
    </FormContent>
  );
};

const meta = {
  background: <img src={undefined} />,
} satisfies LayoutMeta<ToolsLayoutMeta>;

export default withLayoutMeta(InflationCalculatorView, meta);
