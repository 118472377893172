import React, { useEffect, useMemo, useState } from 'react';
import TextInput, { TextInputProps } from './form/TextInput';
import { CircularProgress, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Check } from '@mui/icons-material';
import { useAutoSaveStatus } from '../hooks/useAutoSaveStatus';

interface EmbeddedFormInputProps<T> extends TextInputProps<T> {
  label: string;
}

function EmbeddedFormInput<T>(props: EmbeddedFormInputProps<T>) {
  const { label, ...rest } = props;
  const { t } = useTranslation();
  const [scheduled, setScheduled] = useState<NodeJS.Timeout | undefined>();

  const { getStatus, clearStatus } = useAutoSaveStatus();

  const status = useMemo(() => getStatus(props.name as string), [getStatus, props.name]);

  useEffect(() => {
    if (status === 'completed') {
      const t = setTimeout(() => clearStatus([props.name as string]), 1000);
      setScheduled(t);
    } else if (status === 'saving') {
      if (scheduled) {
        clearTimeout(scheduled);
        setScheduled(undefined);
      }
    }
  }, [status]);

  const onChange = () => {
    // setSaving([props.name as string]);
  };

  return (
    <Grid
      container
      className='embedded-form-input'
      onClick={() => document.getElementById(props.name as string)?.focus()}>
      <Typography variant='p16' color='secondary'>
        {t(label)}
      </Typography>
      <Grid item display='flex'>
        {status && (
          <Grid className='auto-save-indicator' item display='flex'>
            {status === 'saving' && (
              <>
                <CircularProgress />
                <Typography variant='p12' color='primary'>
                  Saving...
                </Typography>
              </>
            )}
            {status === 'completed' && (
              <>
                <Check />
                <Typography variant='p12' color='primary'>
                  {t('ChangesSaved')}
                </Typography>
              </>
            )}
          </Grid>
        )}
        <TextInput {...rest} onChange={onChange} id={props.name as string} />
      </Grid>
    </Grid>
  );
}

export default EmbeddedFormInput;
