import { json } from 'react-router-dom';
import { inject, IServiceContainer } from '@aesop-fables/containr';
import { ICommandExecutor, IRelayCommand } from '@aesop-fables/scrinium';
import { SharedServices } from '@3nickels/data-modules';
import { AuthTokenApi } from '../../api/apis/AuthTokenApi';
import { ApiKeys } from '../../api/apis/ApiKeys';

class GenerateAuthTokenCookie implements IRelayCommand {
  constructor(@inject(ApiKeys.AuthToken) private readonly api: AuthTokenApi) {}

  async execute(): Promise<void> {
    await this.api.generateCookie();
  }
}

export async function authTokenCookieLoader(container: IServiceContainer) {
  const commands = container.get<ICommandExecutor>(SharedServices.CommandExecutor);
  await commands.relay(GenerateAuthTokenCookie);

  return json('auth-token-ready');
}
