import { Data } from '@3nickels/data-modules';
import { useMutation, MutationStatus } from '@aesop-fables/scrinium';
import { useEffect } from 'react';
import { useLoading } from '../useLoading';
import { useMessage } from '../useMessage';
import { t } from 'i18next';

export function useDeleteAccountMutations() {
  const { setLoading } = useLoading();
  const { showMessage } = useMessage();

  const deleteCashAccount = useMutation(new Data.CashAccounts.Mutations.DeleteCashAccount());
  const deleteRetirementPlan = useMutation(
    new Data.InvestmentAccounts.Mutations.DeleteInvestmentAccount()
  );
  const deleteInvestmentAccount = useMutation(
    new Data.InvestmentAccounts.Mutations.DeleteInvestmentAccount()
  );
  const deleteCar = useMutation(new Data.Cars.Mutations.DeleteCar());
  const deleteHome = useMutation(new Data.RealEstate.Mutations.DeleteHousingEntity());
  const deleteDebt = useMutation(new Data.Debt.Mutations.DeleteDebt());

  useEffect(() => {
    if (deleteCashAccount.status === MutationStatus.Complete) {
      setLoading(false);
      showMessage(t('CashAccountRemoved').toString());
    }
  }, [deleteCashAccount.status]);

  useEffect(() => {
    if (deleteRetirementPlan.status === MutationStatus.Complete) {
      setLoading(false);
      showMessage(t('RetirementPlanRemoved').toString());
    }
  }, [deleteRetirementPlan.status]);

  useEffect(() => {
    if (deleteInvestmentAccount.status === MutationStatus.Complete) {
      setLoading(false);
      showMessage(t('InvestmentAccountRemoved').toString());
    }
  }, [deleteInvestmentAccount.status]);

  useEffect(() => {
    if (deleteCar.status === MutationStatus.Complete) {
      setLoading(false);
      showMessage(t('CarRemoved').toString());
    }
  }, [deleteCar.status]);

  useEffect(() => {
    if (deleteHome.status === MutationStatus.Complete) {
      setLoading(false);
      showMessage(t('HomeRemoved').toString());
    }
  }, [deleteHome.status]);

  useEffect(() => {
    if (deleteDebt.status === MutationStatus.Complete) {
      setLoading(false);
      showMessage(t('DebtRemoved').toString());
    }
  }, [deleteDebt.status]);

  return {
    deleteCashAccount,
    deleteRetirementPlan,
    deleteInvestmentAccount,
    deleteCar,
    deleteHome,
    deleteDebt,
  };
}
