import { Box, Stack } from '@mui/material';
import React, { useEffect } from 'react';
import ToggleRadioButtonGroup from '../../../../components/form/ToggleRadioButtonGroup';
import { Spacing } from '../../../../themes';
import { Domain, Hooks } from '@3nickels/data-modules';
import { useFormContext } from 'react-hook-form';
import { LinkAccountFormData } from '../Types';
import { t } from 'i18next';

export type AccountOwnerFormProps = {
  owner?: Domain.Owner;
  onSubmit: (values: LinkAccountFormData) => Promise<void>;
};

const AccountOwnerForm: React.FC<AccountOwnerFormProps> = ({ owner }) => {
  const methods = useFormContext();
  const wizard = Hooks.usePlaidWizard();

  useEffect(() => {
    const subscription = methods.watch((values, { name }) => {
      if (name === 'owner') {
        wizard.chooseOwner(values[name]);
      }
    });
    return () => subscription.unsubscribe();
  }, [methods.watch]);

  return (
    <Box>
      <Stack spacing={Spacing.xxs}>
        <ToggleRadioButtonGroup<LinkAccountFormData>
          error={methods.formState.errors.owner !== undefined}
          helperText={methods.formState.errors.owner?.message?.toString()}
          defaultValue={owner}
          label='WhoOwnsThisAccount'
          name='owner'
          row
          items={[
            { label: t('Me'), value: 'me' },
            { label: t('Spouse'), value: 'spouse' },
          ]}
        />
      </Stack>
    </Box>
  );
};

export default AccountOwnerForm;
