import { Typography, Grid, Divider } from '@mui/material';
import { t } from 'i18next';
import React from 'react';
import CardDisplay from '../../../components/panels/CardDisplay';
import { Data, Domain } from '@3nickels/data-modules';
import { SummaryCardProps } from '../../account-details/Types';
import { convertToTranslationKey } from '../../../helpers/utilityFunctions';

export interface FinancialInfoCardProps extends SummaryCardProps {
  financialInfo?: Data.People.BasicFinancialFormData;
  includeSpouse?: boolean;
}

const FinancialInfoCard: React.FC<FinancialInfoCardProps> = (props) => {
  const taxFilingStatus =
    Object.keys(Domain.TaxFilingStatusEnum).find(
      (key, index) =>
        Object.values(Domain.TaxFilingStatusEnum)[index] === props.financialInfo?.taxFilingStatus
    ) ?? '';
  const investmentPreference =
    Object.keys(Domain.InvestmentPreferencesEnum).find(
      (key, index) =>
        Object.values(Domain.InvestmentPreferencesEnum)[index] ===
        props.financialInfo?.investmentPreference
    ) ?? '';
  const taxFilingStatusLabel = convertToTranslationKey(taxFilingStatus);
  const investmentPreferenceLabel = convertToTranslationKey(investmentPreference);

  return (
    <CardDisplay headerKey='MyBasicFinancialInfo' actionContent='Edit' actionClick={props.onEdit}>
      <Grid container mb={2} spacing={2}>
        {props.includeSpouse && (
          <Grid item xs={12} mb={1}>
            <Typography variant='p16Bold' color='secondary'>
              {t('MyBasicFinances')}
            </Typography>
          </Grid>
        )}
        {!props.includeSpouse && (
          <Grid item xs>
            <Typography variant='p16Bold' color='secondary'>
              {t('IncomeAndTaxInfo')}
            </Typography>
          </Grid>
        )}
        <Grid item xs>
          <Typography variant='h3' component='h3' color='primary'>
            {t('GrossAnnualIncome')}
          </Typography>
          <Typography color='secondary'>
            {new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
              maximumFractionDigits: 0,
            }).format(props.financialInfo?.annualIncome ?? 0)}
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography variant='h3' component='h3' color='primary'>
            {t('AnnualOtherIncome')}
          </Typography>
          <Typography color='secondary'>
            {new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
              maximumFractionDigits: 0,
            }).format(props.financialInfo?.otherAnnualIncome ?? 0)}
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography variant='h3' component='h3' color='primary'>
            {t('TaxFilingStatus')}
          </Typography>
          <Typography color='secondary'>{t(taxFilingStatusLabel)}</Typography>
        </Grid>
        <Grid item xs>
          <Typography variant='h3' component='h3' color='primary'>
            {t('StateIFileIn')}
          </Typography>
          <Typography color='secondary'>{props.financialInfo?.taxFilingState}</Typography>
        </Grid>
        {props.includeSpouse && <Grid item xs />}
      </Grid>
      <Divider color='primary.light' />
      <Grid container mt={2} spacing={2}>
        {!props.includeSpouse && (
          <Grid item xs>
            <Typography variant='p16Bold' color='secondary'>
              {t('OtherInfo')}
            </Typography>
          </Grid>
        )}
        <Grid item xs>
          <Typography variant='h3' component='h3' color='primary'>
            {t('MyCreditScore')}
          </Typography>
          <Typography color='secondary'>{props.financialInfo?.creditScore}</Typography>
        </Grid>
        <Grid item xs>
          <Typography variant='h3' component='h3' color='primary'>
            {t('InvestingPreference')}
          </Typography>
          <Typography color='secondary'>{t(investmentPreferenceLabel)}</Typography>
        </Grid>
        <Grid item xs>
          <Typography variant='h3' component='h3' color='primary'>
            {t('EligibleForHSA')}
          </Typography>
          <Typography color='secondary'>
            {t(props.financialInfo?.eligibleForHsa ? 'Yes' : 'No')}
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography variant='h3' component='h3' color='primary'>
            {t('SelfEmployed')}
          </Typography>
          <Typography color='secondary'>
            {t(props.financialInfo?.selfEmployed ? 'Yes' : 'No')}
          </Typography>
        </Grid>
        {props.includeSpouse && <Grid item xs />}
      </Grid>
      {props.includeSpouse && (
        <Grid container mt={4} mb={2} spacing={2}>
          <Grid item xs={12} mb={1}>
            <Typography variant='p16Bold' color='secondary'>
              {t('SpouseBasicFinances')}
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography variant='h3' component='h3' color='primary'>
              {t('SpouseAnnualIncome')}
            </Typography>
            <Typography color='secondary'>
              {new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                maximumFractionDigits: 0,
              }).format(props.financialInfo?.spouseAnnualIncome ?? 0)}
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography variant='h3' component='h3' color='primary'>
              {t('SpouseOtherIncome')}
            </Typography>
            <Typography color='secondary'>
              {new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                maximumFractionDigits: 0,
              }).format(props.financialInfo?.spouseAnnualOtherIncome ?? 0)}
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography variant='h3' component='h3' color='primary'>
              {t('SpouseCreditScore')}
            </Typography>
            <Typography color={!props.financialInfo?.spouseCreditScore ? 'error' : 'secondary'}>
              {props.financialInfo?.spouseCreditScore ?? t('AnswerNeeded')}
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography variant='h3' component='h3' color='primary'>
              {t('SpouseEligibleForHSA')}
            </Typography>
            <Typography color='secondary'>
              {t(props.financialInfo?.spouseEligibleForHsa ? 'Yes' : 'No')}
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography variant='h3' component='h3' color='primary'>
              {t('SpouseSelfEmployed')}
            </Typography>
            <Typography color='secondary'>
              {t(props.financialInfo?.spouseSelfEmployed ? 'Yes' : 'No')}
            </Typography>
          </Grid>
        </Grid>
      )}
    </CardDisplay>
  );
};

export default FinancialInfoCard;
