/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { Api, Data, Domain, formatDateForApi, Hooks } from '@3nickels/data-modules';
import { FormLoader } from '../../../components/FormLoader';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import FormContent from '../../../components/form/FormContent';
import { WizardStep, useObservable } from '@aesop-fables/scrinium';
import { LayoutMeta } from '../../../types/LayoutMeta';
import { WizardFooter } from '../../../components/form/WizardFooter';
import { useLoading } from '../../../hooks/useLoading';
import { EditViewProps, getWizardFooterProps } from '../../EditViewUtils';
import { GoalsLayoutMeta } from '../GoalsLayout';
import CollegeGoalDetailsForm from './CollegeGoalDetailsForm';
import {
  cleanWholeNumberStr,
  formatMalformedDate,
  isValidDate,
} from '../../../helpers/utilityFunctions';
import { GoalFormTitle } from '../../../components/FormTitle';
import dayjs from 'dayjs';
import CollegeGoalBasicForm from './CollegeGoalBasicForm';
import { useMessage } from '../../../hooks/useMessage';
import InfoCard from '../../../components/InfoCard';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';

const formSchema = () =>
  Yup.object({
    name: Yup.string().required(t('Required') as string),
    beneficiaryType: Yup.string().required(t('Required') as string),
    targetDate: Yup.string().when('beneficiaryType', {
      is: (beneficiaryType: string) =>
        beneficiaryType !== Domain.BeneficiaryTypeEnum.DEPENDENT.toString(),
      then: (schema) =>
        schema
          .required(t('Required') as string)
          .test('validDate', t('MustBeAValidDate') as string, (targetDate) => {
            return isValidDate(targetDate);
          })
          .test('targetDateFuture', t('DateMustBeInTheFuture') as string, (targetDate) => {
            const date = formatMalformedDate(targetDate);
            return dayjs(date).isAfter(dayjs(), 'day');
          })
          .test(
            'targetDateWithin100Years',
            t('DateMustBeLessThan100Years') as string,
            (targetDate) => {
              const date = formatMalformedDate(targetDate);
              return dayjs(date).isBefore(dayjs().add(100, 'year'), 'day');
            }
          ),
    }),
    tuitionType: Yup.string().required(t('Required') as string),
    annualTuitionCost: Yup.string().required(t('Required') as string),
    annualTuitionCostCoveragePercent: Yup.string().when('beneficiaryType', {
      is: (beneficiaryType: string) =>
        beneficiaryType === Domain.BeneficiaryTypeEnum.DEPENDENT.toString(),
      then: (schema) =>
        schema
          .required(t('Required') as string)
          .test(
            'annualTuitionCostCoveragePercent',
            t('Required') as string,
            (annualTuitionCostCoveragePercent) => {
              const cleanNumber = Number(annualTuitionCostCoveragePercent.replace(/[%]/g, ''));
              return cleanNumber > 0;
            }
          ),
    }),
  });

export const CollegeGoalCombinedEditViewWrapper: React.FC<EditViewProps> = ({
  editing,
  onBack,
}) => {
  const { loading, currentStep, wizard } = Hooks.useCollegeGoalWizard();
  const isStarted = useObservable(wizard.isStarted$);
  const params = useObservable(wizard.params$);
  const averageCosts = Hooks.useAverageCollegeCosts();

  useEffect(() => {
    if (isStarted) {
      wizard.selectStep('basic');
    }
  }, [isStarted]);

  return (
    <>
      {params &&
        averageCosts && ( // load params before mounting to prevent flickering
          <FormLoader loading={loading}>
            <CollegeGoalCombinedEditView
              averageCosts={averageCosts}
              currentStep={currentStep}
              wizard={wizard}
              params={params}
              editing={editing}
              onBack={onBack}
            />
          </FormLoader>
        )}
    </>
  );
};

interface CollegeGoalCombinedEditViewProps extends EditViewProps {
  averageCosts: Api.CollegeFundSelfAverageCostsResultRest;
  currentStep: WizardStep<Data.Goals.CollegeGoalFormData, Data.Goals.WizardParamsWithId>;
  wizard: Data.Goals.CollegeGoalWizard;
  params: Data.Goals.WizardParamsWithId;
}

const CollegeGoalCombinedEditView: React.FC<CollegeGoalCombinedEditViewProps> = ({
  averageCosts,
  currentStep,
  wizard,
  editing,
  onBack,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const methods = useForm<Data.Goals.CollegeGoalFormData>({
    defaultValues: currentStep.model,
    resolver: yupResolver(formSchema()),
  });
  const { setLoading } = useLoading();
  const { showMessage } = useMessage();
  const participantContext = Hooks.useParticipantContext();
  const [addDependentOpen, setAddDependentOpen] = React.useState<boolean>(false);

  const onSubmit = async (values: any) => {
    if (values.beneficiaryType === Domain.BeneficiaryTypeEnum.DEPENDENT && !values.beneficiaryId) {
      showMessage(t('PleaseChooseADependent') as string, 'warning', 3000);
      return;
    }
    setLoading(true);

    let beneficiaryId = values.beneficiaryId ?? participantContext?.self.participantId;
    if (values.beneficiaryType === Domain.BeneficiaryTypeEnum.SPOUSE) {
      beneficiaryId = participantContext?.spouse?.participantId;
    }

    try {
      currentStep.save({
        ...values,
        beneficiaryId,
        targetDate: formatDateForApi(values.targetDate),
        annualTuitionCost: cleanWholeNumberStr(values.annualTuitionCost),
        annualTuitionCostCoveragePercent: parseInt(values.annualTuitionCostCoveragePercent ?? '0'),
      });
      await wizard.commitStep('basic');

      if (editing && onBack) {
        onBack();
        return;
      }

      navigate('/goals');
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormContent formProviderProps={methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Box>
          <GoalFormTitle editing={editing} type={Domain.GoalTypeEnum.College} />

          <Typography variant='p16Bold' color='secondary' mt={2}>
            {t('GoalBasics')}
          </Typography>
          <Grid container justifyContent='center' mt={2}>
            <Grid item sm={editing ? 10 : 6}>
              <CollegeGoalBasicForm
                model={currentStep.model}
                onSubmit={onSubmit}
                addDependentOpen={addDependentOpen}
                setAddDependentOpen={setAddDependentOpen}
                editing={editing}
              />
            </Grid>
          </Grid>
          <Typography variant='p16Bold' color='secondary' mt={2}>
            {t('CollegeInfo')}
          </Typography>
          <Grid container justifyContent='center' mt={2}>
            <Grid item sm={editing ? 10 : 6}>
              <CollegeGoalDetailsForm
                averageCosts={averageCosts}
                model={currentStep.model}
                onSubmit={onSubmit}
              />
            </Grid>
            <Grid item sm={10} mt={2}>
              <InfoCard onClick={() => navigate('/tools/college/cost-of-college')}>
                <Typography color='secondary' variant='p16'>
                  {t('DoYouKnowHowMuchCollegeWillCost')}
                </Typography>
              </InfoCard>
            </Grid>
          </Grid>
        </Box>
        <WizardFooter
          color={editing ? 'primary' : undefined}
          disableBack={false}
          onBack={onBack}
          {...getWizardFooterProps(meta.nextLocaleKey, editing)}
        />
      </form>
    </FormContent>
  );
};

const meta = {
  nextLocaleKey: 'Save&Continue',
  title: 'College',
} satisfies LayoutMeta<GoalsLayoutMeta>;
