import React from 'react';
import { useLayoutMeta } from '../../../../hooks/useLayoutMeta';
import { useContentView } from '../../../layout/ContentViewContext';
import { Outlet } from 'react-router-dom';
import ContentView from '../../../layout/Content';
import MedicalBenefitsFooter from './MedicalBenefitsFooter';
import { Data } from '@3nickels/data-modules';
import { Breadcrumbs } from '../../../../components';

export type MedicalBenefitLayout = Record<string, unknown>;

export declare type MedicalBenefitLayoutMeta = {
  centerComponent?: React.JSX.Element;
  onNext?: () => void;
  stepNumber: number;
  background?: React.ReactNode;
};

const breadcrumbs = {
  '/tools': 'Tools',
  '/tools/medical': 'Medical',
  '/tools/medical/medical-benefit-selector-tool': 'MedicalBenefitSelectorTool',
};

const MedicalBenefitLayout: React.FC = () => {
  const { meta, setMeta } = useLayoutMeta<MedicalBenefitLayoutMeta>();
  const { sideNav } = useContentView();
  const pathnameSegments = location.pathname.split('/');
  const filteredSegments = pathnameSegments.filter((segment) => {
    return (
      segment !== '2' &&
      segment !== '3' &&
      segment !== '4' &&
      segment !== '5' &&
      segment !== 'summary'
    );
  });

  return (
    <Data.MedicalBenefits.MedicalBenefitsProvider>
      <ContentView
        hideSideNav
        background={meta.background}
        leftChildren={
          <Breadcrumbs pathnameSegments={filteredSegments} breadcrumbNameMap={breadcrumbs} />
        }
        sideNavMenuItems={sideNav('Tools')}
        footer={<MedicalBenefitsFooter meta={meta} />}>
        <Outlet context={{ meta, setMeta }} />
      </ContentView>
    </Data.MedicalBenefits.MedicalBenefitsProvider>
  );
};

export default MedicalBenefitLayout;
