import { Grid, Typography, Card, Divider, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Svgs } from '../../../../assets/svg';
import BulletedList from '../../../../components/BulletedList';
import { Row } from '../../../../components';
import { Colors, Spacing } from '../../../../themes';
import { useNavigate } from 'react-router-dom';

export const ThingsToConsider: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Grid container className='content-card-container' flexDirection='column'>
      <Grid container className='content-card-header'>
        <Row style={{ justifyContent: 'center', width: '15%' }}>
          <Svgs.ArtDonation />
        </Row>
        <Typography variant='p22Bold' color='primary'>
          {t('DevelopGivingPlan')}
        </Typography>
      </Grid>

      <Card className='content-card'>
        <Grid container direction='row'>
          <Grid item xs={9}>
            <BulletedList
              style={{ marginTop: '6px' }}
              color='secondary'
              children={[
                'WhatDoYouValue',
                'WhatIsYourPassion',
                'WhatAreYourGivingGoals',
                'WhoCanITrust',
              ]}
            />
          </Grid>
          <Divider
            orientation='vertical'
            sx={{
              borderColor: Colors.primaryLight,
              margin: '0px 20px',
              height: 'auto',
            }}
            flexItem
          />
          <Grid item xs={2}>
            <Stack spacing={Spacing.xxxs + 0.5}>
              <Row style={{ alignItems: 'center' }}>
                <Svgs.ArtLightbulbSmall />
                <Typography color='secondary' variant='p18Bold' sx={{ marginLeft: 1 }}>
                  {t('Related')}
                </Typography>
              </Row>
              <Typography
                color='primary'
                variant='p16Bold'
                className='hover-clickable'
                sx={{ marginLeft: 1 }}
                onClick={() => navigate('/goals/giving')}>
                {t('CreateGivingPlan')}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
};
