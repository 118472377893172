import { Svgs } from '../../../../assets/svg';
import { useTranslation } from 'react-i18next';
import ToolsCard from '../../ToolsCard';

const MedicalBenefitSelectorToolCard: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ToolsCard
      icon={<Svgs.DisplaySmallCustomMedicalUmbrella />}
      label={t('MedicalBenefitSelectorTool')}
      content={t('SeeTheProsAndCons')}
    />
  );
};

export default MedicalBenefitSelectorToolCard;
