import { Grid } from '@mui/material';
import ContentView from '../../layout/Content';
import NetWorthSection from './net-worth/NetWorthSection';
import ExploreSection from './explore/ExploreSection';
import { Spacing } from '../../../themes';
import WalkThroughAdviceCard from './WalkThroughAdviceCard';
import GoalsSection from './goals/GoalsSection';
import { Hooks } from '@3nickels/data-modules';
import SpendingOverviewSection from './spending-overview/SpendingOverviewSection';
import { useContentView } from '../../layout/ContentViewContext';
import CoursesSection from './courses/CoursesSection';
import CoachingSection from './university/CoachingSection';

const HolisticAdviceView: React.FC = () => {
  const holisticAdvice = Hooks.useHolisticAdviceOverview();
  const universityEnabled = Hooks.useUniversityEnabled();
  const goals = holisticAdvice?.goalSummaries ?? [];
  const ssi = holisticAdvice?.ssiIncomeFromRetirementAdvise ?? 0;
  const { sideNav } = useContentView();

  return (
    <ContentView sideNavMenuItems={sideNav('Home')}>
      <Grid rowSpacing={Spacing.lg}>
        <WalkThroughAdviceCard />
        <Grid
          className='holistic-advice-view-content'
          display='flex'
          width='100%'
          justifyContent='space-between'>
          <Grid className='left-container' flexDirection='column'>
            <NetWorthSection />
            <GoalsSection goals={goals} ssi={ssi} />
            {universityEnabled && <CoachingSection />}
          </Grid>
          <Grid className='right-container' flexDirection='column'>
            <SpendingOverviewSection />
            <CoursesSection />
            <ExploreSection />
          </Grid>
        </Grid>
      </Grid>
    </ContentView>
  );
};

export default HolisticAdviceView;
