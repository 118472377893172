import { Box, Stack } from '@mui/material';
import React, { useEffect } from 'react';
import TextInput from '../../../components/form/TextInput';
import ToggleRadioButtonGroup from '../../../components/form/ToggleRadioButtonGroup';
import { Margins, Spacing } from '../../../themes';
import { Data, Domain, Hooks } from '@3nickels/data-modules';
import { useFormContext } from 'react-hook-form';
import HelpPopover from '../../../components/HelpPopover';
import { t } from 'i18next';
import BulletedList from '../../../components/BulletedList';
import { Trans } from 'react-i18next';

export type AnnuityBasicsFormProps = {
  planBasics?: Data.Annuities.AnnuityFormData;
  wizard: Data.Annuities.AnnuityWizard;
  params: Data.Annuities.AnnuityWizardParams;
  onSubmit: (values: Data.Annuities.AnnuityFormData) => Promise<void>;
};

const AnnuityBasicsForm: React.FC<AnnuityBasicsFormProps> = ({ planBasics, wizard, params }) => {
  const { formState, watch } = useFormContext();
  const includeSpouse = Hooks.useIncludeSpouse();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onOwnerChange = (owner: Domain.Owner) => {
    wizard.updateParams({ ...params });
  };

  useEffect(() => {
    const subscription = watch((values, { name }) => {
      if (name === 'owner') {
        onOwnerChange(values[name]);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, params]);

  return (
    <Box>
      <Stack spacing={Spacing.xxs}>
        <TextInput<Data.Annuities.AnnuityFormData>
          error={formState.errors.name !== undefined}
          helperText={formState.errors.name?.message?.toString()}
          defaultValue={planBasics?.annuityName}
          inputProps={{ maxLength: 32 }}
          label='AnnuityNickname'
          name='annuityName'
          helpContext={
            <HelpPopover title={t('AccountNickname')}>
              <BulletedList
                style={Margins.mt_xxs}
                children={[
                  t('UsedToIdentifyYourAnnuityPlans'),
                  <Trans
                    i18nKey='CallItWhateverYouWantStyled'
                    components={{
                      Styled: <strong />,
                    }}
                  />,
                ]}
              />
            </HelpPopover>
          }
        />
        {includeSpouse && (
          <ToggleRadioButtonGroup<Data.Annuities.AnnuityFormData>
            error={formState.errors.owner !== undefined}
            helperText={formState.errors.owner?.message?.toString()}
            label='WhoOwnsThisAccount'
            name='owner'
            row
            defaultValue={planBasics?.owner}
            items={[
              { label: t('Me'), value: 'me' },
              { label: t('Spouse'), value: 'spouse' },
            ]}
          />
        )}
      </Stack>
    </Box>
  );
};

export default AnnuityBasicsForm;
