import { Box, Stack } from '@mui/material';
import React from 'react';
import TextInput from '../../../components/form/TextInput';
import { Spacing } from '../../../themes';
import { Data } from '@3nickels/data-modules';
import { useFormContext } from 'react-hook-form';
import BeneficiaryPicker from '../../../components/form/BeneficiaryPicker';

export type CollegeGoalBasicFormProps = {
  model?: Data.Goals.CollegeGoalFormData;
  onSubmit: (values: Data.Goals.CollegeGoalFormData) => Promise<void>;
  addDependentOpen: boolean;
  setAddDependentOpen: React.Dispatch<React.SetStateAction<boolean>>;
  editing?: boolean;
};

const CollegeGoalBasicForm: React.FC<CollegeGoalBasicFormProps> = (props) => {
  const { formState } = useFormContext();
  const { model } = props;

  return (
    <Box>
      <Stack spacing={Spacing.xxs}>
        <TextInput<Data.Goals.CollegeGoalFormData>
          error={formState.errors.name !== undefined}
          helperText={formState.errors.name?.message?.toString()}
          defaultValue={props.model?.name}
          inputProps={{ maxLength: 64 }}
          label='GoalName'
          name='name'
          autoFocus
        />
        <BeneficiaryPicker label='WhoIsthisCollegeGoalFor' defaultValues={model} {...props} />
      </Stack>
    </Box>
  );
};

export default CollegeGoalBasicForm;
