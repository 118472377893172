import { Api, Domain, Hooks } from '@3nickels/data-modules';
import { useService } from '@aesop-fables/containr-react';
import { Typography, Button, Grid, GridProps, ButtonProps } from '@mui/material';
import { PaymentApi } from '../../../api/apis/PaymentApi';
import { ApiKeys } from '../../../api/apis/ApiKeys';
import { SeeLegalDocs } from '../../settings/manage-subscription/Footnote';
import { Colors } from '../../../themes';
import { useCallback, useMemo } from 'react';
import { getInterval } from '../../settings/manage-subscription/functions';
import { t } from 'i18next';
import { formatDecimalDollars, formatWholeDollars } from '../../../helpers/utilityFunctions';

const DEFAULT_LINK_COLOR = Colors.primaryBase;

type SubscriptionDetailsProps = GridProps & {
  linkColor?: string;
  buttonProps?: ButtonProps;
};

export const SubscriptionDetails: React.FC<SubscriptionDetailsProps> = ({
  linkColor = DEFAULT_LINK_COLOR,
  buttonProps,
  ...gridProps
}) => {
  const termsApi = useService<Api.TermsAndConditionsApi>(Api.ApiKeys.TermsAndConditions);
  const paymentApi = useService<PaymentApi>(ApiKeys.Payment);
  const legalDocs = Hooks.useTermsAndConditions();
  const { sortedPaidProducts } = Hooks.useAvailableProducts();

  const selectedPlan = useMemo(() => sortedPaidProducts[0], [sortedPaidProducts]);
  const intervalType = selectedPlan?.prices[0].intervalType;
  const oneTimePayment = intervalType === Domain.PaymentIntervalEnum.NONE;

  const formatPrice = (price: number | undefined) => {
    if (typeof price === 'undefined' || price === null) return;
    const formattedPrice = price / 100;
    const decimalDollarsPrice = formattedPrice.toString().endsWith('.99');
    return decimalDollarsPrice
      ? formatDecimalDollars(formattedPrice)
      : formatWholeDollars(formattedPrice);
  };

  const primaryPrice = useMemo(() => {
    const price = selectedPlan?.prices[0]?.price;
    return formatPrice(price);
  }, [selectedPlan]);

  const secondaryPrice = useMemo(() => {
    const price = selectedPlan?.prices[1]?.price;
    return formatPrice(price);
  }, [selectedPlan]);

  const secondaryInterval = useMemo(() => {
    return getInterval(selectedPlan?.prices[1]?.intervalType);
  }, [selectedPlan]);

  const promotionalPeriodInMonths = useMemo(
    () =>
      selectedPlan?.prices[1]?.introductionPeriodInDays &&
      Math.round(selectedPlan.prices[1]?.introductionPeriodInDays / 30),
    [selectedPlan]
  );

  const buttonText = oneTimePayment
    ? t('PurchaseFor', { price: primaryPrice })
    : t('SubscribeFor', { price: primaryPrice, interval: t(intervalType) });

  const description = oneTimePayment
    ? t('InitiallyAOneTimePayment', {
        promotionalPeriodInMonths: promotionalPeriodInMonths,
        price: secondaryPrice,
        interval: secondaryInterval,
      })
    : t('ThisSubscriptionRenewsAutomatically', { interval: getInterval(intervalType) });

  const handleButtonClick = useCallback(async () => {
    if (selectedPlan) {
      const stripeUrl = await paymentApi.createSession(selectedPlan.pricebook.id);
      window.location.replace(stripeUrl.data);
    }
  }, [selectedPlan, paymentApi]);

  return (
    <Grid {...gridProps} flexDirection='column' textAlign='center' justifyContent='center'>
      <Grid item mb={2}>
        <Typography className='link-wrapper' variant='p12' color='secondary'>
          {description}{' '}
          <SeeLegalDocs
            termsApi={termsApi}
            legalDocs={legalDocs}
            linkStyle={{
              color: linkColor,
              textDecorationColor: linkColor,
              fontWeight: linkColor === DEFAULT_LINK_COLOR ? 'normal' : 'bold',
            }}
          />
        </Typography>
      </Grid>
      <Button {...buttonProps} onClick={handleButtonClick}>
        {buttonText}
      </Button>
    </Grid>
  );
};
