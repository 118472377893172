/* tslint:disable */
import { LoaderFunction } from 'react-router-dom';
import { RouteObject } from 'react-router';
import { commonRoutes, createCommonRoutes } from './CommonRoutes';
import { IServiceContainer } from '@aesop-fables/containr';
import { appReadyLoader } from './loaders/AppReadyLoader';
import { redirectLoader } from './loaders/RedirectLoader';
import { wireUpLoaders } from './utils';
import FreeView from '../pages/free/home/FreeView';
import { authTokenCookieLoader } from './loaders/AuthTokenCookieLoader';
import LandingPage from '../dashboard/LandingPage';
import { UserEventLogger } from './UserEventLogger';
import { Hooks } from '@3nickels/data-modules';
import { learningLoader } from './loaders/LearningLoader';
import { universityLoader } from './loaders/UniversityLoader';
import { Loading } from '../hooks/useLoading';

export const FreeHomePage = () => {
  const { sortedPaidProducts, sortedProducts } = Hooks.useAvailableProducts();
  const availableProductsInitialized = Hooks.useAvailableProductsInitialized();
  const universityEnabled = Hooks.useUniversityEnabled();

  if (!availableProductsInitialized || typeof universityEnabled === 'undefined') return null;

  return sortedPaidProducts.length > 0 ? (
    <FreeView sortedProducts={sortedProducts} universityEnabled={universityEnabled} />
  ) : (
    <LandingPage />
  );
};

export const freeRoutes: RouteObject[] = [
  {
    path: '',
    element: <UserEventLogger />,
    children: [
      {
        path: '/',
        element: <FreeHomePage />,
      },
      // define 3nu route so universityLoader fires
      {
        path: 'learning/university',
        element: <Loading />,
      },
      ...commonRoutes,
    ],
  },
];

export function createFreeRoutes(container: IServiceContainer) {
  const commonRoutes = createCommonRoutes(container);

  const loaders = new Map<string, LoaderFunction>();
  loaders.set('/', async () => {
    await appReadyLoader(container);

    // redirects need to be fired last and returned
    return redirectLoader(container);
  });
  loaders.set('settings', () => authTokenCookieLoader(container));
  loaders.set('settings/update-password', () => authTokenCookieLoader(container));
  loaders.set('settings/two-factor-authentication', () => authTokenCookieLoader(container));
  loaders.set('learning/university', () => universityLoader(container));
  loaders.set('learning', () => learningLoader(container));

  return [...commonRoutes, ...wireUpLoaders(freeRoutes, loaders)];
}
