import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Api, Data, Hooks } from '@3nickels/data-modules';
import { Card, CardHeader, Grid, Typography } from '@mui/material';
import { Check } from '@mui/icons-material';
import { Modal } from '../../../components';
import { useMessage } from '../../../hooks/useMessage';
import { Colors } from '../../../themes';
import { Svgs } from '../../../assets/svg';
import { convertToTitleCase, formatPhoneNumber } from '../../../helpers/utilityFunctions';
import { useDeviceType } from '../../../hooks/useDeviceType';

interface RemoveMethodModalProps {
  selected: Api.AuthTwoFactorData | undefined;
  modalOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const RemoveMethodModal: React.FC<RemoveMethodModalProps> = ({
  selected,
  modalOpen,
  setModalOpen,
}) => {
  const { t } = useTranslation();
  const { showMessage } = useMessage();
  const commands = Hooks.useCommands();

  const handleSave = async () => {
    if (selected) {
      if (selected.defaultSelection) {
        showMessage(t('YouCannotDeleteTwoFactor') as string, 'warning');
        return;
      }
      try {
        await commands.execute(Data.TwoFactor.Commands.DeleteTwoFactorMethod, {
          id: selected.id,
          type: selected.twoFactorType,
        });
        showMessage(t('AuthenticationMethodRemoved') as string);
      } catch (err) {
        showMessage(t('ErrorRemovingAuthenticationMethod') as string, 'error');
      }
    }
  };

  return (
    <Modal
      title={t('RemoveAuthenticationMethod') as string}
      primaryButtonText='NoKeepMethod'
      secondaryButtonText='YesRemoveMethod'
      swapButtonFunctionality
      open={modalOpen}
      setOpen={setModalOpen}
      handleSave={handleSave}>
      <Typography variant='p16' color='secondary'>
        {t('ConfirmRemoveAuthenticationMethod')}
      </Typography>
      <Typography mt={2} variant='p16Bold' color='secondary'>
        {selected?.info}
      </Typography>
      <Typography variant='p16Bold' color='secondary'>
        {t(convertToTitleCase(selected?.twoFactorType ?? ''))}
      </Typography>
    </Modal>
  );
};

interface ChangeMethodModalProps {
  methods: Api.AuthTwoFactorData[];
  modalOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ChangeMethodModal: React.FC<ChangeMethodModalProps> = ({
  methods,
  modalOpen,
  setModalOpen,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useDeviceType();
  const { showMessage } = useMessage();
  const commands = Hooks.useCommands();
  const [selected, setSelected] = useState<Api.AuthTwoFactorData | undefined>();

  useEffect(() => {
    setSelected(methods.find((method) => method.defaultSelection) ?? methods[0]);
  }, [modalOpen]);

  const handleSave = async () => {
    try {
      if (selected) {
        await commands.execute(Data.TwoFactor.Commands.SetDefaultTwoFactorMethod, {
          id: selected.id,
          type: selected.twoFactorType,
        });
        showMessage(t('DefaultAuthenticationMethodUpdated') as string);
      }
    } catch (err) {
      showMessage(t('ErrorUpdatingDefaultAuthenticationMethod') as string, 'error');
    }
  };

  return (
    <Modal
      title={
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Typography
            variant={isMobile ? 'p18Bold' : 'p24Bold'}
            color='secondary.main'
            style={{ textAlign: 'center', justifyContent: 'center' }}>
            <Trans i18nKey='ChangeDefaultBRAuthenticationMethod' />
          </Typography>
        </div>
      }
      primaryButtonText='Submit'
      open={modalOpen}
      setOpen={setModalOpen}
      handleSave={handleSave}>
      <Grid container mt={-3} display='flex'>
        {methods.map((method) => {
          const checked = selected && selected.id === method.id;
          const email = method.twoFactorType?.toLowerCase() === Api.TwoFactorTypeEnum.Email;
          return (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
              }}>
              <Card
                className={checked ? 'check-select-checked' : 'check-select'}
                sx={{
                  minHeight: '51px',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  paddingRight: '10px',
                }}
                onClick={() => {
                  setSelected(checked ? undefined : method);
                }}
                key={method.id}
                elevation={0}>
                <CardHeader
                  disableTypography
                  sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  title={
                    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                      {email ? (
                        checked ? (
                          <Svgs.ActionSmallEnvelopeTertiaryDefault />
                        ) : (
                          <Svgs.DisplaySmallCustomEnvelope />
                        )
                      ) : checked ? (
                        <Svgs.ActionSmallPhone2TertiaryDefault />
                      ) : (
                        <Svgs.DisplaySmallCustomPhone2 />
                      )}
                      <Typography
                        ml={1}
                        color={checked ? Colors.tertiaryBase : Colors.secondaryBase}
                        variant={checked ? 'p16Bold' : 'p16'}>
                        {email ? method.info : formatPhoneNumber(method.info ?? '')}
                      </Typography>
                    </div>
                  }
                  action={
                    checked && (
                      <Grid item mt='5px'>
                        <Check htmlColor={Colors.tertiaryBase} />
                      </Grid>
                    )
                  }
                />
              </Card>
            </div>
          );
        })}
      </Grid>
    </Modal>
  );
};

export { RemoveMethodModal, ChangeMethodModal };
