import { Box, Drawer, Grid, IconButton, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DependentForm } from './DependentForm';
import { Spacing } from '../../../themes';
import { LayoutMeta, withLayoutMeta } from '../../../types/LayoutMeta';
import { WizardFooter } from '../../../components/form/WizardFooter';
import { useNavigate } from 'react-router-dom';
import { useSubject } from '@aesop-fables/scrinium';
import { Data, Domain } from '@3nickels/data-modules';
import { useLoading } from '../../../hooks/useLoading';
import { GroupEdit } from '../../../components/GroupEdit';
import { Modal } from '../../../components';
import { Add, Close } from '@mui/icons-material';
import {
  useDeleteDependent,
  useSaveDependent,
} from '../../../hooks/useMutations/useDependentMutations';

export interface AddDependentRowProps {
  onAdd?: () => void;
  onClose?: () => void;
  open?: boolean;
}

export const AddDependentRow: React.FC<AddDependentRowProps> = ({ onAdd, onClose, open }) => {
  const { t } = useTranslation();
  return (
    <Grid
      pl={4}
      width='100%'
      display='inline-flex'
      justifyContent='space-between'
      flexDirection='row'
      className='add-dependent'
      style={{ cursor: 'pointer' }}
      onClick={() => {
        if (!open && onAdd) onAdd();
        if (open && onClose) onClose();
      }}>
      <Typography variant='p16Bold' color={open ? 'secondary' : 'primary'}>
        {t('AddDependent')}
      </Typography>
      {!open && (
        <IconButton>
          <Add color='primary' fontSize='large' />
        </IconButton>
      )}
      {open && (
        <IconButton>
          <Close color='primary' fontSize='large' />
        </IconButton>
      )}
    </Grid>
  );
};

export type DependentViewProps = {
  dependents?: Domain.DependentData[];
  onEdit?: (d: Domain.DependentData) => void;
};

export type DependentDrawerProps = {
  dependent?: Domain.DependentData;
  open: boolean;
};

const DependentList: React.FC<DependentViewProps> = ({ dependents = [] }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setLoading } = useLoading();
  const [editing, setEditing] = useState(false);
  const [drawerOpen, setDrawerOpen] = React.useState<DependentDrawerProps>({
    open: false,
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [activeDependent, setActiveDependent] = useState<number>();
  const saveDependent = useSaveDependent(editing, () => {
    setDrawerOpen({ open: false });
    setEditing(false);
  });
  const deleteDependent = useDeleteDependent(editing);

  const handleEdit = (d: Domain.DependentData) => {
    setEditing(true);
    setDrawerOpen({ dependent: d, open: true });
  };

  const handleSaveDependent = (values: Domain.DependentData) => {
    setLoading(true);
    saveDependent.action(values);
  };

  const handleDeleteDependent = (values: number) => {
    setLoading(true);
    deleteDependent.action(values);
  };

  return (
    <>
      <div style={{ display: 'none' }}>
        <Modal
          title={t('RemoveAccountType', { type: t('Dependent') }).toString()}
          primaryButtonText={t('NoKeepAccountType', { type: t('Dependent') }).toString()}
          secondaryButtonText={t('YesRemoveAccountType', { type: t('Dependent') }).toString()}
          swapButtonFunctionality
          open={modalOpen}
          setOpen={setModalOpen}
          handleSave={() => {
            handleDeleteDependent(activeDependent ?? 0);
          }}>
          <Typography variant='p16' color='secondary'>
            {t('ThisWillRemoveAllDependentInformation')}
          </Typography>
        </Modal>
      </div>
      <Grid container display='flex' flexDirection='column'>
        <Grid container display='flex' flexDirection='column' mb={Spacing.xxxs}>
          <Typography color='primary' component='h1' variant='h1'>
            {t('Dependents')}
          </Typography>
          <Typography color='secondary'>{t('OnlyDependentsYouCanClaim')}</Typography>
        </Grid>
        <GroupEdit<Domain.DependentData>
          variant='compressed'
          title='Dependents'
          items={dependents}
          keyFn={(x) => `${x.id ?? 0}`}
          mainContentFn={(x) => (
            <Typography
              className='hover-clickable'
              fontWeight='bold'
              color='secondary'
              onClick={() => handleEdit(x)}>
              {x.name ?? ''}
            </Typography>
          )}
          summaryFn={(x) => t('BornYear', { year: x.birthYear }).toString()}
          addItemComponent={<AddDependentRow onAdd={() => setDrawerOpen({ open: true })} />}
          onEdit={(d) => {
            handleEdit(d);
          }}
          onDelete={(d) => {
            setActiveDependent(d.id ?? 0);
            setModalOpen(true);
          }}
        />
        <Drawer
          anchor='right'
          open={drawerOpen.open}
          onClose={() => {
            setDrawerOpen({ open: false });
          }}>
          <Box p={Spacing.xxs}>
            <DependentForm
              dependent={drawerOpen.dependent}
              edit={editing}
              onCancel={() => {
                setDrawerOpen({ open: false });
              }}
              onSave={handleSaveDependent}
            />
          </Box>
        </Drawer>
      </Grid>
      <WizardFooter
        disableBack={false}
        nextLabel='Done'
        onDone={() => navigate('/personal-details/summary')}
      />
    </>
  );
};

const meta = {
  nextLocaleKey: 'Done',
  showBack: true,
  showNext: true,
  hexSteps: {
    steps: 5,
    currentStep: 5,
  },
} satisfies LayoutMeta;

const ConfiguredDependentList = withLayoutMeta(DependentList, meta);

const DependentManager: React.FC = () => {
  const dependents = useSubject<Data.People.Projections.DependentDataSubject>(
    Data.People.PersonServices.DependentData
  );
  return <ConfiguredDependentList dependents={dependents} />;
};

export default DependentManager;
