import { Card, CardHeader, Grid, Typography } from '@mui/material';
import { t } from 'i18next';
import { CSSProperties } from 'styled-components';

interface CardButtonProps {
  label: string;
  checked: boolean | undefined;
  onClick: () => void;
  cardStyle?: CSSProperties;
}
const CardButton: React.FC<CardButtonProps> = ({ checked, onClick, label, cardStyle }) => {
  return (
    <Card
      className={checked ? 'checkbox-card-checked' : 'checkbox-card-unchecked'}
      onClick={onClick}
      elevation={0}
      sx={{
        width: '50%',
        padding: '30px',
        display: 'flex',
        ...cardStyle,
      }}>
      <CardHeader
        disableTypography
        title={
          <Grid display='flex' flexDirection='row' alignItems='center'>
            <Typography color='secondary' variant='p16'>
              {t(label)}
            </Typography>
          </Grid>
        }
      />
    </Card>
  );
};

export default CardButton;
