import { useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { mapDebtTypePathToAccountType } from '../pages/account-details/debt/DebtUtils';

export function useAccountHeaderContent() {
  const { t } = useTranslation();
  const location = useLocation();
  const { type } = useParams();

  if (location.pathname.includes('/cash-account/')) {
    return t('CashAccount');
  } else if (location.pathname.includes('/retirement-savings-plan/')) {
    return t('RetirementSavingsPlan');
  } else if (location.pathname.includes('/pension-plan/')) {
    return t('PensionPlan');
  } else if (location.pathname.includes('/fixed-annuity/')) {
    return t('Annuity|Fixed');
  } else if (location.pathname.includes('/car/')) {
    return t('Car');
  } else if (location.pathname.includes('/home/')) {
    return t('Home');
  } else if (location.pathname.includes('/brokerage-account/')) {
    return t('BrokerageAccount');
  } else if (location.pathname.includes('/college-savings-plan/')) {
    return t('CollegeSavingsPlan');
  } else if (location.pathname.includes('/health-savings-account/')) {
    return t('HSA');
  } else if (location.pathname.includes('/business/')) {
    return t('Business');
  } else if (location.pathname.includes('/investment-real-estate/')) {
    return t('InvestmentRealEstate');
  } else if (location.pathname.includes('/other-asset/')) {
    return t('OtherAsset');
  } else if (location.pathname.includes('/debt/')) {
    return t(mapDebtTypePathToAccountType(type ?? ''));
  }
}
