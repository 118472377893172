/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { Grid, InputLabel, RadioGroup, Typography } from '@mui/material';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useTranslation } from 'react-i18next';
import { theme } from '../../themes/ThemeWrapper';
import { Controller, useFormContext } from 'react-hook-form';
import { HelpOutline } from '@mui/icons-material';
import Tippy from '@tippyjs/react';
import '../../themes/tippy-light-nickels.css';

export type RadioInputProps = {
  label: string;
  value: string;
  disabled?: boolean;
};

export type ToggleRadioButtonGroupChanged = (target: HTMLInputElement) => void;

export type ToggleRadioButtonGroupProps<T> = {
  items: RadioInputProps[];
  name: keyof T;
  defaultValue?: string;
  row: boolean;
  disabled?: boolean;
  label?: string;
  helpContext?: React.ReactNode;
  onChange?: ToggleRadioButtonGroupChanged;
  required?: boolean;
  error?: boolean;
  helperText?: string;
  autoFocus?: boolean;
};

export default function ToggleRadioButtonGroup<T>(props: ToggleRadioButtonGroupProps<T>) {
  const { t } = useTranslation();
  const [checkedValue, setCheckedValue] = React.useState(props.defaultValue);
  const methods = useFormContext();

  const handleRadioClicked = (e: React.MouseEvent<HTMLElement>) => {
    const target = e.target as HTMLInputElement;
    if (target.checked) {
      if (props.onChange) {
        props.onChange(target);
        return;
      }
    }
  };

  if (methods === null) {
    return (
      // eslint-disable-next-line i18next/no-literal-string
      <strong>
        ToggleRadioButtonGroup: *** This Component needs to be wrapped in FormLoader ***
      </strong>
    );
  }

  React.useEffect(() => {
    const subscription = methods.watch((value, { name }) => {
      if (name === props.name) {
        const newVal = value[name];
        setCheckedValue(newVal?.toString());
      }
    });
    return () => subscription.unsubscribe();
  }, [methods.watch]);

  const text = props.label ? t(props.label) : '';
  return (
    <Grid display='flex' flexDirection='column'>
      <Grid
        item
        flexDirection='row'
        justifyContent='space-between'
        alignItems='center'
        display='flex'
        mb='6px'>
        <InputLabel
          shrink={false}
          disableAnimation
          required={props.required}
          htmlFor={props.name.toString()}>
          <Typography style={{ whiteSpace: 'normal' }} variant='p16Bold' color='primary'>
            {text}
          </Typography>
        </InputLabel>
        {props.helpContext && (
          <Grid item display='flex' justifyContent='flex-end'>
            <Tippy placement='right' content={props.helpContext} theme='light-nickels-theme'>
              <HelpOutline style={{ color: theme.palette.primary.main }} />
            </Tippy>
          </Grid>
        )}
      </Grid>
      <Controller
        rules={{ required: true }}
        control={methods.control}
        name={props.name.toString()}
        render={({ field }) => (
          <RadioGroup
            className={props.error ? 'radio-error' : ''}
            autoFocus={props.autoFocus}
            row={props.row}
            {...field}
            onChange={(e) => {
              field.onChange(e);
              handleRadioClicked(e as unknown as React.MouseEvent<HTMLElement>);
            }}>
            {props.items.map((i, k) => (
              <FormControlLabel
                className={
                  i.value === checkedValue ? 'lblContainerChecked' : 'lblContainerUnchecked'
                }
                key={k}
                disabled={props.disabled || i.disabled}
                label={t(i.label)}
                value={i.value}
                control={<Radio checked={i.value === checkedValue} disabled={props.disabled} />}
              />
            ))}
          </RadioGroup>
        )}
      />
      <Typography className='helper-text' color='error'>
        {props.helperText}
      </Typography>
    </Grid>
  );
}
