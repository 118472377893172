/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Grid, GridProps, SxProps, Typography } from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import SideNavigation, { type NickelsMenuItem } from '../../components/toc/SideNavigation';
import { Spacing } from '../../themes';
import { Header } from './Header';
import { AnimatePresence } from 'framer-motion';
import AnimatedTransition from '../../components/AnimatedTransiton';
import { useContentView } from './ContentViewContext';
import { t } from 'i18next';
import { ArrowDropDown } from '@mui/icons-material';

export type ContentProps = {
  headerChildren?: React.ReactNode;
  leftChildren?: React.ReactNode;
  progressIndicator?: React.ReactNode;
  progressIndicatorPosition?: 'start' | 'end';
  sideNavMenuItems: NickelsMenuItem[];
  hideSideNav?: boolean;
  sideComponent?: React.ReactNode;
  sideComponentStyles?: SxProps;
  footer?: React.ReactNode;
  background?: React.ReactNode;
  children: React.ReactNode;
  containerProps?: GridProps;
  autoScrollContent?: boolean;
  scrollToBottomButton?: boolean;
  scrollToBottom?: boolean;
  setScrollToBottom?: React.Dispatch<React.SetStateAction<boolean>>;
};

const ContentView: React.FC<ContentProps> = ({
  headerChildren,
  leftChildren,
  progressIndicator,
  progressIndicatorPosition = 'start',
  sideNavMenuItems,
  hideSideNav,
  sideComponent,
  sideComponentStyles,
  footer,
  background,
  children,
  containerProps,
  autoScrollContent,
  scrollToBottomButton,
  scrollToBottom,
  setScrollToBottom,
}) => {
  const { expanded, id } = useContentView();
  const contentId = useMemo(() => {
    if (typeof id !== 'undefined') return id;
    if (expanded) return 'expanded';
    return '';
  }, [sideComponent, expanded, id]); // can prob remove expanded and just use id

  const [isScrolledUp, setIsScrolledUp] = useState(false);

  const childrenListRef = useRef<HTMLDivElement | null>(null);
  const childrenListEndRef = useRef<HTMLDivElement | null>(null);

  const handleScroll = () => {
    if (childrenListRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = childrenListRef.current;
      const atBottom = scrollTop + clientHeight >= scrollHeight - 50;
      setIsScrolledUp(!atBottom);
    }
  };

  useEffect(() => {
    const debounceScroll = debounce(handleScroll, 50);

    const childrenListElement = childrenListRef.current;
    if (childrenListElement) {
      childrenListElement.addEventListener('scroll', debounceScroll);
    }

    return () => {
      if (childrenListElement) {
        childrenListElement.removeEventListener('scroll', debounceScroll);
      }
    };
  }, []);

  useEffect(() => {
    handleScroll();
  }, [children]);

  useEffect(() => {
    if (scrollToBottom) {
      scrollToBottomFn();
      if (setScrollToBottom) setScrollToBottom(false);
    }
  }, [scrollToBottom]);

  useEffect(() => {
    if (!autoScrollContent) return;

    if (childrenListEndRef.current && !isScrolledUp) {
      childrenListEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [children]);

  const scrollToBottomFn = () => {
    if (childrenListEndRef.current) {
      childrenListEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    setIsScrolledUp(false);
  };

  const debounce = (func: (...args: any[]) => void, wait: number) => {
    let timeout: NodeJS.Timeout;
    return (...args: any[]) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), wait);
    };
  };

  return (
    <Grid
      container
      flexDirection='row'
      className='content-wrapper'
      id={contentId}
      {...containerProps}>
      <Header leftChildren={leftChildren}>{headerChildren}</Header>
      {!hideSideNav && (
        <Grid gridArea='sidebar' sx={sideComponentStyles}>
          {sideComponent ?? <SideNavigation menuItems={sideNavMenuItems} />}
        </Grid>
      )}
      <Grid
        className='content-view'
        gridArea='content'
        ref={childrenListRef}
        sx={{ overflow: 'scroll', maxHeight: '90vh' }}>
        {background}
        <AnimatePresence mode='wait'>
          <AnimatedTransition>
            <Grid className='content' item flexDirection='column'>
              {progressIndicatorPosition === 'start' && (
                <Grid pt={Spacing.xxs} pl={Spacing.xxxs}>
                  {progressIndicator}
                </Grid>
              )}
              <Grid className='children' p={Spacing.xxs}>
                {children}
                {autoScrollContent && <div ref={childrenListEndRef} />}
              </Grid>
              {scrollToBottomButton && isScrolledUp && (
                <Grid
                  container
                  display='flex'
                  justifyContent={'center'}
                  style={{
                    padding: '50px 0px',
                    backgroundImage: 'linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff 45%)',
                    position: 'fixed',
                    bottom: '70px',
                    zIndex: 1000,
                  }}>
                  <Button onClick={scrollToBottomFn} variant='ghost' style={{ background: 'none' }}>
                    <Typography variant='p16'>{t('ScrollToBottom')}</Typography>
                    <ArrowDropDown />
                  </Button>
                </Grid>
              )}
              {progressIndicatorPosition === 'end' && (
                <Grid pt={Spacing.xxs} pr={Spacing.xxxs}>
                  {progressIndicator}
                </Grid>
              )}
            </Grid>
          </AnimatedTransition>
        </AnimatePresence>
      </Grid>
      {footer}
    </Grid>
  );
};

export default ContentView;
