import { Grid, Stack, Typography } from '@mui/material';
import { t } from 'i18next';
import { Svgs } from '../../../../assets/svg';
import { Row } from '../../../../components';
import BulletedList from '../../../../components/BulletedList';

export const ComprehensiveInsurance: React.FC = () => {
  return (
    <Stack spacing={2}>
      <Row style={{ justifyContent: 'flex-start', alignItems: 'center' }}>
        <Svgs.ArtComprehensiveInsurance />
        <Grid ml={3}>
          <Typography color='secondary' variant='p18Bold'>
            {t('ComprehensiveInsurance')}
          </Typography>
        </Grid>
      </Row>
      <Typography variant='p16' color='secondary'>
        {t('ComprehensiveCovers')}
      </Typography>
      <BulletedList
        style={{ marginTop: 2 }}
        children={[
          t('YourCarBeingStolen'),
          t('DamageFromWeather'),
          t('FloodDamage'),
          t('FireDamage'),
          t('FallingObjects'),
          t('Explosions'),
          t('HittingAnAnimal'),
          t('DamageFromRiots'),
        ]}
      />
    </Stack>
  );
};
