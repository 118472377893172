import { Card, Grid, Typography } from '@mui/material';
import {
  advisorTransparent,
  checkmark,
  play,
  magnifyingGlass,
  certificate,
} from '../../../assets/png';
import { Svgs } from '../../../assets/svg';
import { Spacer } from '../../../components';
import { ReactElement } from 'react';
import { t } from 'i18next';

export type CarouselVariant = 'default' | 'nickelsU';

export const getCarouselItems = (variant: CarouselVariant): ReactElement[] => {
  const defaultCarouselItems: ReactElement[] = [
    <Card className='tools-carousel-card'>
      <Grid mt={1}>
        <Svgs.ArtRetirementSmall />
      </Grid>
      <Spacer height='xxxs' />
      <Typography variant='p14' color='secondary' textAlign='center'>
        {t('PlanForAnEarlyRetirement')}
      </Typography>
    </Card>,

    <Card className='tools-carousel-card'>
      <Grid mt={-1.5}>
        <Svgs.ArtDebtSmall />
      </Grid>
      <Spacer height='xxxs' />
      <Typography variant='p14' color='secondary' textAlign='center'>
        {t('Payoffdebt')}
      </Typography>
    </Card>,

    <Card className='tools-carousel-card'>
      <Grid mt={1}>
        <Svgs.ArtUmbrella />
      </Grid>
      <Spacer height='xxxs' />
      <Typography variant='p14' color='secondary' textAlign='center'>
        {t('StartARainyDayFund')}
      </Typography>
    </Card>,

    <Card className='tools-carousel-card'>
      <Grid
        container
        mb={-3}
        mt={2}
        style={{
          alignItems: 'flex-start',
        }}>
        <Svgs.ArtPieChart height='65%' />
      </Grid>

      <Spacer height='xxxs' />
      <Typography variant='p14' color='secondary' textAlign='center' width='50%'>
        {t('LearnInvesting')}
      </Typography>
    </Card>,

    <Card className='tools-carousel-card'>
      <Grid>
        <Svgs.ArtMoneySmall />
      </Grid>
      <Spacer height='xxxs' />
      <Typography variant='p14' color='secondary' textAlign='center'>
        {t('AnalyzeYourSpending')}
      </Typography>
    </Card>,

    <Card className='tools-carousel-card'>
      <Grid mt={1.5}>
        <Svgs.ArtBusiness />
      </Grid>
      <Spacer height='xxxs' />
      <Typography variant='p14' color='secondary' textAlign='center'>
        {t('LinkYourAccounts')}
      </Typography>
    </Card>,

    <Card className='tools-carousel-card'>
      <Spacer height='xxs' />
      <Grid>
        <Svgs.ArtLightbulbMedium />
      </Grid>
      <Spacer height='xxxs' />
      <Typography variant='p14' color='secondary' textAlign='center'>
        {t('LearnAtYourOwnPace')}
      </Typography>
    </Card>,
  ];

  const nickelsUCarouselItems: ReactElement[] = [
    <Card className='tools-carousel-card'>
      <Grid mt={1.5}>
        <img src={advisorTransparent} />
      </Grid>
      <Spacer height='xxxs' />
      <Typography variant='p14' color='secondary' textAlign='center'>
        {t('OneOnOneCoaching')}
      </Typography>
    </Card>,

    <Card className='tools-carousel-card'>
      <Grid mt={1.3}>
        <img src={checkmark} width='100px' />
      </Grid>
      <Spacer height='xxxs' />
      <Typography variant='p14' color='secondary' textAlign='center' width='70%'>
        {t('RecurringQuizzes')}
      </Typography>
    </Card>,

    <Card className='tools-carousel-card'>
      <Grid mt={1.5}>
        <img src={play} />
      </Grid>
      <Spacer height='xxxs' />
      <Typography variant='p14' color='secondary' textAlign='center'>
        {t('EnagingVideoContent')}
      </Typography>
    </Card>,

    <Card className='tools-carousel-card'>
      <Spacer height='xxs' />
      <Grid>
        <img src={magnifyingGlass} />
      </Grid>
      <Spacer height='xxxs' />
      <Typography variant='p14' color='secondary' textAlign='center'>
        {t('DetailedTopics')}
      </Typography>
    </Card>,

    <Card className='tools-carousel-card'>
      <Grid mt={1.5}>
        <img src={certificate} />
      </Grid>
      <Spacer height='xxxs' />
      <Typography variant='p14' color='secondary' textAlign='center'>
        {t('GraduationCertificate')}
      </Typography>
    </Card>,

    <Card className='tools-carousel-card'>
      <Grid mt={1}>
        <Svgs.ArtRetirementSmall />
      </Grid>
      <Spacer height='xxxs' />
      <Typography variant='p14' color='secondary' textAlign='center'>
        {t('PlanForAnEarlyRetirement')}
      </Typography>
    </Card>,

    <Card className='tools-carousel-card'>
      <Grid mt={-1.5}>
        <Svgs.ArtDebtSmall />
      </Grid>
      <Spacer height='xxxs' />
      <Typography variant='p14' color='secondary' textAlign='center'>
        {t('Payoffdebt')}
      </Typography>
    </Card>,

    <Card className='tools-carousel-card'>
      <Grid mt={1}>
        <Svgs.ArtUmbrella />
      </Grid>
      <Spacer height='xxxs' />
      <Typography variant='p14' color='secondary' textAlign='center'>
        {t('StartARainyDayFund')}
      </Typography>
    </Card>,

    <Card className='tools-carousel-card'>
      <Grid
        container
        mb={-3}
        mt={2}
        style={{
          alignItems: 'flex-start',
        }}>
        <Svgs.ArtPieChart height='65%' />
      </Grid>

      <Spacer height='xxxs' />
      <Typography variant='p14' color='secondary' textAlign='center' width='50%'>
        {t('LearnInvesting')}
      </Typography>
    </Card>,

    <Card className='tools-carousel-card'>
      <Grid>
        <Svgs.ArtMoneySmall />
      </Grid>
      <Spacer height='xxxs' />
      <Typography variant='p14' color='secondary' textAlign='center'>
        {t('AnalyzeYourSpending')}
      </Typography>
    </Card>,

    <Card className='tools-carousel-card'>
      <Grid mt={1.5}>
        <Svgs.ArtBusiness />
      </Grid>
      <Spacer height='xxxs' />
      <Typography variant='p14' color='secondary' textAlign='center'>
        {t('LinkYourAccounts')}
      </Typography>
    </Card>,

    <Card className='tools-carousel-card'>
      <Spacer height='xxs' />
      <Grid>
        <Svgs.ArtLightbulbMedium />
      </Grid>
      <Spacer height='xxxs' />
      <Typography variant='p14' color='secondary' textAlign='center'>
        {t('LearnAtYourOwnPace')}
      </Typography>
    </Card>,
  ];

  return variant === 'default' ? defaultCarouselItems : nickelsUCarouselItems;
};
