import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Grid, Link, Stack, Typography } from '@mui/material';
import { LayoutMeta, withLayoutMeta } from '../../../types/LayoutMeta';
import { AccountSettingsLayoutMeta } from '../AccountSettingsLayout';

const FairUseNoticeView: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Box>
      <Typography mb={2} variant='p30Bold' component='h1' color='primary'>
        {t('FairUseNotice')}
      </Typography>
      <Grid sm={9}>
        <Stack spacing={2}>
          <Typography variant='p16' color='secondary'>
            {t('The3NickelsAppMayContainCopyrightedMaterial')}
          </Typography>
          <Typography variant='p16' color='secondary'>
            {t('IfYouWishToUseCopyrightedMaterial')}
          </Typography>
          <Typography className='link-wrapper' variant='p16' color='secondary'>
            <Trans
              i18nKey='IfYouBelieveContentViolatesIp'
              components={{ Link: <Link color='secondary' href={'mailto:help@3nickels.com'} /> }}
            />
          </Typography>
        </Stack>
      </Grid>
    </Box>
  );
};

const meta = {
  showBack: true,
} satisfies LayoutMeta<AccountSettingsLayoutMeta>;

export default withLayoutMeta(FairUseNoticeView, meta);
