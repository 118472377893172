/* tslint:disable */
import AccountDetailsLayout from '../pages/account-details/AccountDetailsLayout';
import { RouteObject } from 'react-router';
import BudgetDetailsLayout from '../pages/budget-details/BudgetDetailsLayout';
import GoalListScreen from '../pages/goals';
import GoalsLayout from '../pages/goals/GoalsLayout';
import CarGoalEditView from '../pages/goals/car/CarGoalEditView';
import OtherGoalEditView from '../pages/goals/other/OtherGoalEditView';
import VacationGoalEditView from '../pages/goals/vacation/VacationGoalEditView';
import LegacyGiving from '../pages/goals/legacy/LegacyGiving';
import GivingAssets from '../pages/goals/giving/assets/GivingAssets';
import GivingCollege from '../pages/goals/giving/college/GivingCollege';
import GivingOnBehalf from '../pages/goals/giving/on-behalf/GivingOnBehalf';
import GivingForTaxBenefits from '../pages/goals/giving/tax-benefits/GivingForTaxBenefits';
import SelectGoalType from '../pages/goals/SelectGoalType';
import SelectGivingGoalTypeView from '../pages/goals/giving/SelectGivingGoalType';
import SelectHouseGoalTypeView from '../pages/goals/house/SelectHouseGoalType';
import HouseGoalEditView from '../pages/goals/house/buy/HouseGoalEditView';
import MortgageGoalView from '../pages/goals/house/mortgage-payoff/MortgageGoalView';
import MortgageGoalSummaryView from '../pages/goals/house/mortgage-payoff/MortgageGoalSummaryView';
import DebtSelectionEditView from '../pages/goals/debt/DebtSelectionEditView';
import EmergencyGoalEditView from '../pages/goals/emergency/EmergencyGoalEditView';
import PresentsGivingGoalEditView from '../pages/goals/giving/presents/PresentsGivingGoalEditView';
import CashGivingGoalBasicEditView from '../pages/goals/giving/cash/CashGivingGoalBasicEditView';
import CashGivingGoalDetailsEditView from '../pages/goals/giving/cash/CashGivingGoalDetailsEditView';
import VolunteeringGoalsListView from '../pages/goals/giving/volunteering/VolunteeringGoals';
import CollegeGoalBasicEditView from '../pages/goals/college/CollegeGoalBasicEditView';
import CollegeGoalDetailsEditView from '../pages/goals/college/CollegeGoalDetailsEditView';
import RetirementGoalEditView from '../pages/goals/retirement/RetirementGoalEditView';
import HolisticFinancialAdviceLayout from '../pages/holistic-financial-advice/HolisticFinancialAdviceLayout';
import HolisticAdviceView from '../pages/holistic-financial-advice/home/HolisticAdviceView';
import SpendingSummaryOverview from '../pages/spending-summary/SpendingSummaryOverview';
import SpendingSummaryLayout from '../pages/spending-summary/SpendingSummaryLayout';
import SpendingSummaryTransactionsView from '../pages/spending-summary/SpendingSummaryTransactionsView';
import { AccountDetailsRoutes } from './AccountDetailsRoutes';
import { BudgetDetailsRoutes } from './BudgetDetailsRoutes';
import { HolisticFinancialAdviceRoutes } from './HolisticFinancialAdviceRoutes';
import { IServiceContainer } from '@aesop-fables/containr';
import { LoaderFunction } from 'react-router-dom';
import { wireUpLoaders } from './utils';
import { appReadyLoader } from './loaders/AppReadyLoader';
import { profileLoader } from './loaders/PersonalDetailsLoader';
import { incomeReadyLoader } from './loaders/IncomeReadyLoader';
import { accountDetailsLoader, linkAccountLoader } from './loaders/AccountDetailsLoader';
import { redirectLoader } from './loaders/RedirectLoader';
import { commonRoutes, createCommonRoutes } from './CommonRoutes';
import { cashGivingGoalLoader, payoffDebtGoalLoader } from './loaders/GoalsLoader';
import UniversityLayout from '../pages/university/UniversityLayout';
import UniversityOverview from '../pages/university/UniversityOverview';
import { universityLoader } from './loaders/UniversityLoader';
import UniversityLessonView from '../pages/university/UniversityLessonView';
import { authTokenCookieLoader } from './loaders/AuthTokenCookieLoader';
import LifestyleView from '../pages/lifestyle/LifestyleView';
import ToolsLayout from '../pages/tools/ToolsLayout';
import ToolsView from '../pages/tools/ToolsView';
import BudgetToolStartView from '../pages/tools/budget/BudgetToolStartView';
import CreditCardToolStartView from '../pages/tools/credit-card/CreditCardToolStartView';
import DebtToolStartView from '../pages/tools/debt/DebtToolStartView';
import GoalsToolStartView from '../pages/tools/goals/GoalsToolStartView';
import CollegeToolStartView from '../pages/tools/college/CollegeToolStartView';
import LoansToolsStartView from '../pages/tools/loans/LoansToolStartView';
import InvestmentToolStartView from '../pages/tools/investment/InvestmentToolStartView';
import MedicalToolStartView from '../pages/tools/medical/MedicalToolStartView';
import RetirementToolStartView from '../pages/tools/retirement/RetirementToolStartView';
import PersonalLoanCalculatorView from '../pages/tools/loans/personal-loan-calculator/PersonalLoanCalculatorView';
import GiftsToolStartView from '../pages/tools/gifts/GiftsToolStartView';
import CarToolsStartView from '../pages/tools/car/CarToolsStartView';
import HowMuchHouseCalculatorView from '../pages/tools/house/how-much-house/HowMuchHouseCalculatorView';
import HouseToolStartView from '../pages/tools/house/HouseToolStartView';
import MortgageRatesView from '../pages/tools/house/mortgage-rates/MortgageRatesView';
import HowMuchCarCalculatorView from '../pages/tools/car/how-much-car/HowMuchCarCalculatorView';
import CarBuyingChecklistView from '../pages/tools/car/car-buying-checklist/CarBuyingChecklistView';
import MostPopularCarsView from '../pages/tools/car/most-popular-cars/MostPopularCarsView';
import MortgageRefinanceChecklistView from '../pages/tools/house/mortgage-refinance-checklist/MortgageRefinanceChecklistView';
import PersonalLoanChecklistView from '../pages/tools/loans/personal-loan-checklist/PersonalLoanChecklistView';
import CollisionInsuranceView from '../pages/tools/car/collision-insurance/CollisionInsuranceView';
import { collisionInsuranceLoader } from './loaders/CollisionInsuranceLoader';
import FinanceOrPayCashCaclulatorView from '../pages/tools/car/finance-or-pay-cash/FinanceOrPayCashCaclulatorView';
import HouseBuyingChecklistView from '../pages/tools/house/house-buying-checklist/HouseBuyingChecklistView';
import HomeSaleProceedsCalculatorView from '../pages/tools/house/home-sale-net-proceeds/HomeSaleProceedsCalculatorView';
import MortgageRefinanceCalculatorView from '../pages/tools/house/mortgage-refinance-calculator/MortgageRefinanceCalculatorView';
import { mortgageLoader } from './loaders/MortgageLoader';
import RentOrBuyView from '../pages/tools/house/rent-or-buy/RentOrBuyView';
import CostOfLivingView from '../pages/tools/house/cost-of-living/CostOfLivingView';
import InflationCalculatorView from '../pages/tools/goals/inflation-calculator/InflationCalculatorView';
import CollegeSavingsChartsView from '../pages/tools/college/college-savings-charts/CollegeSavingsChartsView';
import CostOfCollegeView from '../pages/tools/college/cost-of-college/CostOfCollegeView';
import { HouseSellingChecklistView } from '../pages/tools/house/sell-house-checklist/HouseSellingChecklistView';
import CollegeSavingsPlansView from '../pages/tools/college/college-savings-plans/CollegeSavingsPlansView';
import HealthInsuranceTypesView from '../pages/tools/medical/types-of-health-insurance/HealthInsuranceTypesView';
import CreditCardGotchasView from '../pages/tools/credit-card/credit-card-gotchas/CreditCardGotchasView';
import AutoInsuranceNeedToKnowView from '../pages/tools/car/auto-insurance/AutoInsuranceNeedToKnowView';
import RealCostOfDebtCalculatorView from '../pages/tools/debt/real-cost-of-debt/RealCostOfDebtCalculatorView';
import DebtConsolidationView from '../pages/tools/debt/debt-consolidation/DebtConsolidationView';
import HowMuchDebtCalculatorView from '../pages/tools/debt/how-much-debt/HowMuchDebtCalculatorView';
import IraFeeCheckerView from '../pages/tools/retirement/ira-fee-checker/IraFeeCheckerView';
import { iraFeeCheckerLoader } from './loaders/IraFeeCheckerLoader';
import HealthInsuranceChecklistView from '../pages/tools/medical/health-insurance-checklist/HealthInsuranceChecklistView';
import IntroToGivingView from '../pages/tools/gifts/intro-to-giving/IntroToGivingView';
import GiveToYourChurchViewWithTithelyContext from '../pages/tools/gifts/give-to-your-church/GiveToYourChurchView';
import HowToOpenAnAccountView from '../pages/holistic-financial-advice/investments/HowToOpenAnAccountView';
import ShopAccountOptionsView from '../pages/holistic-financial-advice/investments/ShopAccountOptionsView';
import CutSpendingView from '../pages/tools/budget/cut-spending/CutSpendingView';
import TaxBenefitsCalculatorView from '../pages/tools/gifts/tax-benefits-donations/TaxBenefitsCalculatorView';
import SearchForCharityView from '../pages/tools/gifts/search-for-charity/SearchForCharityView';
import SearchForCharityDetailsView from '../pages/tools/gifts/search-for-charity/SearchForCharityDetailsView';
import BasicInvestingTermsView from '../pages/tools/investment/basic-investing-terms/BasicInvestingTermsView';
import LifestyleToolsView from '../pages/tools/LifestyleToolsView';
import { UserEventLogger } from './UserEventLogger';
import { holisticAdviceLoader } from './loaders/HolisticAdviceLoader';
import WhySaveNowView from '../pages/tools/retirement/why-save-now/WhySaveNowView';
import { learningLoader } from './loaders/LearningLoader';
import { yourLessonsRoutes } from './YourLessonsRoutes';
import MedicalBenefitLayout from '../pages/tools/medical/medical-benefits-selector-tool/MedicalBenefitLayout';
import MedicalBenefitsSelectorStepOne from '../pages/tools/medical/medical-benefits-selector-tool/steps/MedicalBenefitsSelectorStepOne';
import MedicalBenefitsSelectorStepTwo from '../pages/tools/medical/medical-benefits-selector-tool/steps/MedicalBenefitsSelectorStepTwo';
import MedicalBenefitsSelectorStepThree from '../pages/tools/medical/medical-benefits-selector-tool/steps/MedicalBenefitsSelectorStepThree';
import MedicalBenefitsSelectorStepFive from '../pages/tools/medical/medical-benefits-selector-tool/steps/MedicalBenefitsSelectorStepFive';
import MedicalBenefitsSelectorStepFour from '../pages/tools/medical/medical-benefits-selector-tool/steps/MedicalBenefitsSelectorStepFour';
import MedicalBenefitSummaryView from '../pages/tools/medical/medical-benefits-selector-tool/MedicalBenefitSummaryView';

const adviceRoutes: RouteObject[] = [
  {
    path: '',
    element: <UserEventLogger />,
    children: [
      {
        path: '/',
        element: <HolisticAdviceView />,
      },
      {
        path: 'account-details',
        element: <AccountDetailsLayout headerVariant='addAccount' />,
        children: AccountDetailsRoutes,
      },
      {
        path: 'budget-details',
        element: <BudgetDetailsLayout />,
        children: BudgetDetailsRoutes,
      },
      {
        path: 'holistic-advice',
        element: <HolisticFinancialAdviceLayout />,
        children: HolisticFinancialAdviceRoutes,
      },
      {
        path: 'goals',
        element: <GoalsLayout />,
        children: [
          {
            index: true,
            element: <GoalListScreen />,
          },
          {
            path: 'select',
            element: <SelectGoalType />,
          },
          {
            path: 'car/:id?',
            element: <CarGoalEditView />,
          },
          {
            path: 'giving',
            children: [
              { index: true, element: <SelectGivingGoalTypeView /> },
              {
                path: 'assets',
                element: <GivingAssets />,
              },
              {
                path: 'cash',
                children: [
                  {
                    path: 'basics',
                    element: <CashGivingGoalBasicEditView />,
                  },
                  {
                    path: 'details',
                    element: <CashGivingGoalDetailsEditView />,
                  },
                ],
              },
              {
                path: 'college',
                element: <GivingCollege />,
              },
              {
                path: 'presents',
                element: <PresentsGivingGoalEditView />,
              },
              {
                path: 'on-behalf',
                element: <GivingOnBehalf />,
              },
              {
                path: 'tax-benefits',
                element: <GivingForTaxBenefits />,
              },
              {
                path: 'volunteering',
                element: <VolunteeringGoalsListView />,
              },
            ],
          },
          {
            path: 'house',
            children: [
              { index: true, element: <SelectHouseGoalTypeView /> },
              { path: 'buy/:id?', element: <HouseGoalEditView /> },
              {
                path: 'pay-off-mortgage',
                children: [
                  { index: true, element: <MortgageGoalView /> },
                  { path: ':id', element: <MortgageGoalSummaryView /> },
                ],
              },
            ],
          },
          {
            path: 'debt',
            element: <DebtSelectionEditView />,
          },
          {
            path: 'legacy',
            element: <LegacyGiving />,
          },
          {
            path: 'other/:id?',
            element: <OtherGoalEditView />,
          },
          {
            path: 'emergency/:id?',
            element: <EmergencyGoalEditView />,
          },
          {
            path: 'retirement/:id?',
            element: <RetirementGoalEditView />,
          },
          {
            path: 'vacation/:id?',
            element: <VacationGoalEditView />,
          },
          { path: 'college/basics/:id?', element: <CollegeGoalBasicEditView /> },
          { path: 'college/details/:id?', element: <CollegeGoalDetailsEditView /> },
        ],
      },
      {
        path: 'tools/medical/medical-benefit-selector-tool',
        element: <MedicalBenefitLayout />,
        children: [
          { index: true, element: <MedicalBenefitsSelectorStepOne /> },
          { path: '2', element: <MedicalBenefitsSelectorStepTwo /> },
          { path: '3', element: <MedicalBenefitsSelectorStepThree /> },
          { path: '4', element: <MedicalBenefitsSelectorStepFour /> },
          { path: '5', element: <MedicalBenefitsSelectorStepFive /> },
          { path: 'summary', element: <MedicalBenefitSummaryView /> },
        ],
      },
      {
        path: 'tools',
        element: <ToolsLayout />,
        children: [
          { index: true, element: <ToolsView /> },
          {
            path: 'budget',
            children: [
              { index: true, element: <BudgetToolStartView /> },
              {
                path: 'cut-spending',
                element: <CutSpendingView />,
              },
              {
                path: 'lifestyle',
                element: <LifestyleToolsView />,
              },
              ...yourLessonsRoutes,
            ],
          },
          {
            path: 'car',
            children: [
              { index: true, element: <CarToolsStartView /> },
              { path: 'how-much-car', element: <HowMuchCarCalculatorView /> },
              { path: 'finance-or-pay-cash', element: <FinanceOrPayCashCaclulatorView /> },
              { path: 'car-buying-checklist', element: <CarBuyingChecklistView /> },
              { path: 'collision-insurance-breakeven', element: <CollisionInsuranceView /> },
              { path: 'most-popular-cars', element: <MostPopularCarsView /> },
              { path: 'auto-insurance-need-to-know', element: <AutoInsuranceNeedToKnowView /> },
              {
                path: 'lifestyle',
                element: <LifestyleToolsView />,
              },
              ...yourLessonsRoutes,
            ],
          },
          {
            path: 'college',
            children: [
              { index: true, element: <CollegeToolStartView /> },
              {
                path: 'cost-of-college',
                element: <CostOfCollegeView />,
              },
              { path: 'college-savings-plans', element: <CollegeSavingsPlansView /> },
              { path: 'college-savings-charts', element: <CollegeSavingsChartsView /> },
              {
                path: 'lifestyle',
                element: <LifestyleToolsView />,
              },
              ...yourLessonsRoutes,
            ],
          },
          {
            path: 'credit-card',
            children: [
              { index: true, element: <CreditCardToolStartView /> },
              {
                path: 'credit-card-gotchas',
                element: <CreditCardGotchasView />,
              },
              {
                path: 'lifestyle',
                element: <LifestyleToolsView />,
              },
              ...yourLessonsRoutes,
            ],
          },
          {
            path: 'debt',
            children: [
              { index: true, element: <DebtToolStartView /> },
              { path: 'real-cost-of-debt', element: <RealCostOfDebtCalculatorView /> },
              { path: 'debt-consolidation-tool', element: <DebtConsolidationView /> },
              { path: 'how-much-debt', element: <HowMuchDebtCalculatorView /> },
              {
                path: 'lifestyle',
                element: <LifestyleToolsView />,
              },
              ...yourLessonsRoutes,
            ],
          },
          {
            path: 'gifts',
            children: [
              { index: true, element: <GiftsToolStartView /> },
              { path: 'introduction-to-giving', element: <IntroToGivingView /> },
              { path: 'give-to-your-church', element: <GiveToYourChurchViewWithTithelyContext /> },
              { path: 'tax-benefits-of-donations', element: <TaxBenefitsCalculatorView /> },
              {
                path: 'search-for-charity',
                children: [
                  { index: true, element: <SearchForCharityView /> },
                  { path: ':id', element: <SearchForCharityDetailsView /> },
                ],
              },
              {
                path: 'lifestyle',
                element: <LifestyleToolsView />,
              },
              ...yourLessonsRoutes,
            ],
          },
          {
            path: 'goals',
            children: [
              { index: true, element: <GoalsToolStartView /> },
              {
                path: 'inflation-calculator',
                element: <InflationCalculatorView />,
              },
              {
                path: 'lifestyle',
                element: <LifestyleToolsView />,
              },
              ...yourLessonsRoutes,
            ],
          },
          {
            path: 'house',
            children: [
              {
                index: true,
                element: <HouseToolStartView />,
              },
              {
                path: 'rent-or-buy',
                element: <RentOrBuyView />,
              },
              {
                path: 'how-much-house',
                element: <HowMuchHouseCalculatorView />,
              },
              { path: 'home-sale-net-proceeds', element: <HomeSaleProceedsCalculatorView /> },
              {
                path: 'mortgage-rates',
                element: <MortgageRatesView />,
              },
              { path: 'house-buying-checklist', element: <HouseBuyingChecklistView /> },
              { path: 'mortgage-refinance-checklist', element: <MortgageRefinanceChecklistView /> },
              {
                path: 'mortgage-refinance-calculator',
                element: <MortgageRefinanceCalculatorView />,
              },
              { path: 'cost-of-living-comparison', element: <CostOfLivingView /> },
              { path: 'house-selling-checklist', element: <HouseSellingChecklistView /> },
              {
                path: 'lifestyle',
                element: <LifestyleToolsView />,
              },
              ...yourLessonsRoutes,
            ],
          },
          {
            path: 'investment',
            children: [
              { index: true, element: <InvestmentToolStartView /> },
              {
                path: 'basic-investing-terms',
                element: <BasicInvestingTermsView />,
              },
              {
                path: 'investment-account-checklist',
                element: <HowToOpenAnAccountView />,
              },
              {
                path: 'shop-investment-accounts',
                element: <ShopAccountOptionsView />,
              },
              {
                path: 'lifestyle',
                element: <LifestyleToolsView />,
              },
              ...yourLessonsRoutes,
            ],
          },
          {
            path: 'loans',
            children: [
              { index: true, element: <LoansToolsStartView /> },
              { path: 'personal-loan-calculator', element: <PersonalLoanCalculatorView /> },
              { path: 'personal-loan-checklist', element: <PersonalLoanChecklistView /> },
              {
                path: 'lifestyle',
                element: <LifestyleToolsView />,
              },
              ...yourLessonsRoutes,
            ],
          },
          {
            path: 'medical',
            children: [
              { index: true, element: <MedicalToolStartView /> },
              { path: 'types-of-health-insurance', element: <HealthInsuranceTypesView /> },
              { path: 'health-insurance-checklist', element: <HealthInsuranceChecklistView /> },
              {
                path: 'lifestyle',
                element: <LifestyleToolsView />,
              },
              ...yourLessonsRoutes,
            ],
          },

          {
            path: 'retirement',
            children: [
              { index: true, element: <RetirementToolStartView /> },
              { path: 'ira-fee-checker', element: <IraFeeCheckerView /> },
              { path: 'why-save-now', element: <WhySaveNowView /> },
              {
                path: 'lifestyle',
                element: <LifestyleToolsView />,
              },
              ...yourLessonsRoutes,
            ],
          },
        ],
      },
      {
        path: 'spending-summary',
        element: <SpendingSummaryLayout />,
        children: [
          { index: true, element: <SpendingSummaryOverview /> },
          {
            path: 'transactions',
            element: <SpendingSummaryTransactionsView />,
          },
        ],
      },
      {
        path: 'learning/university',
        element: <UniversityLayout />,
        children: [
          { index: true, element: <UniversityOverview /> },
          {
            path: ':title',
            element: <UniversityLessonView />,
          },
        ],
      },
      {
        path: 'lifestyle',
        element: <LifestyleView />,
      },
      ...commonRoutes,
    ],
  },
];

export function createAdviceRoutes(container: IServiceContainer) {
  const commonRoutes = createCommonRoutes(container);
  const loaders = new Map<string, LoaderFunction>();
  // load advice on homescreen
  loaders.set('/', async () => {
    await Promise.all([
      appReadyLoader(container),
      universityLoader(container),
      holisticAdviceLoader(container),
    ]);

    // redirects need to be fired last and returned
    return redirectLoader(container);
  });
  loaders.set('profile', () =>
    Promise.all([profileLoader(container), incomeReadyLoader(container)])
  );
  // might need to add a plaid redirect loader
  loaders.set('account-details', () =>
    Promise.all([incomeReadyLoader(container), accountDetailsLoader(container)])
  );
  loaders.set('account-details/link-account', ({ request }) =>
    linkAccountLoader(request, container)
  );
  loaders.set('budget-details', () => Promise.all([incomeReadyLoader(container)]));
  loaders.set('goals/giving/cash/basics', () =>
    Promise.all([cashGivingGoalLoader(container), incomeReadyLoader(container)])
  );
  loaders.set('goals/debt', () => payoffDebtGoalLoader(container));
  loaders.set('learning/university', () => universityLoader(container));
  loaders.set('learning', () => learningLoader(container));
  loaders.set('settings', () => authTokenCookieLoader(container));
  loaders.set('settings/update-password', () => authTokenCookieLoader(container));
  loaders.set('settings/two-factor-authentication', () => authTokenCookieLoader(container));

  loaders.set('tools/car/collision-insurance-breakeven', () => collisionInsuranceLoader(container));
  loaders.set('tools/house/mortgage-refinance-calculator', () => mortgageLoader(container));
  loaders.set('tools/retirement/ira-fee-checker', () => iraFeeCheckerLoader(container));

  return [...commonRoutes, ...wireUpLoaders(adviceRoutes, loaders)];
}
