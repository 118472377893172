import { Box, Stack } from '@mui/material';
import React from 'react';
import TextInput from '../../../components/form/TextInput';
import ToggleRadioButtonGroup from '../../../components/form/ToggleRadioButtonGroup';
import { Spacing } from '../../../themes';
import { Domain, Hooks } from '@3nickels/data-modules';
import { useFormContext } from 'react-hook-form';
import { InvestmentAccountBasicFormData } from './Types';
import { t } from 'i18next';

export type InvestmentAccountBasicsFormProps = {
  accountBasics?: InvestmentAccountBasicFormData;
  onSubmit: (values: InvestmentAccountBasicFormData) => Promise<void>;
  type: Domain.PlanTypeEnum;
};

function resolveNameLabel(type: Domain.PlanTypeEnum) {
  switch (type) {
    case Domain.PlanTypeEnum['Brokerage Account']:
      return 'BrokerageAccountNickname';
    case Domain.PlanTypeEnum.HSA:
      return 'HSANickname';
    default:
      return 'AccountNickname';
  }
}

const InvestmentAccountBasicsForm: React.FC<InvestmentAccountBasicsFormProps> = (props) => {
  const { formState } = useFormContext();
  const includeSpouse = Hooks.useIncludeSpouse();

  return (
    <Box>
      <Stack spacing={Spacing.xxs}>
        <TextInput<InvestmentAccountBasicFormData>
          error={formState.errors.name !== undefined}
          helperText={formState.errors.name?.message?.toString()}
          defaultValue={props.accountBasics?.name}
          InputProps={{ readOnly: props.accountBasics?.name !== undefined }}
          inputProps={{ maxLength: 64 }}
          label={resolveNameLabel(props.type)}
          name='name'
          autoFocus
        />
        {includeSpouse && (
          <ToggleRadioButtonGroup<InvestmentAccountBasicFormData>
            error={formState.errors.owner !== undefined}
            helperText={formState.errors.owner?.message?.toString()}
            label='WhoOwnsThisAccount'
            name='owner'
            row
            disabled={props.accountBasics?.owner !== undefined}
            defaultValue={props.accountBasics?.owner}
            items={[
              { label: t('Me'), value: 'me' },
              { label: t('Spouse'), value: 'spouse' },
            ]}
          />
        )}
      </Stack>
    </Box>
  );
};

export default InvestmentAccountBasicsForm;
