import { Domain, formatWholeDollars, Hooks } from '@3nickels/data-modules';
import { useMemo, useState } from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { Loading } from '../../../../hooks/useLoading';
import { Colors } from '../../../../themes';
import { LayoutMeta, withLayoutMeta } from '../../../../types/LayoutMeta';
import { ChartStateHoneycombCellData } from '../../../../components/ChartStateHoneycomb';
import { ToolsLayoutMeta } from '../../ToolsLayout';
import { ChartLineSeries } from '../../../../components/ChartLine';
import { FundGrowthView } from './FundGrowthView';
import { CostByStateView } from './CostByStateView';
import { t } from 'i18next';

const CollegeSavingsChartsView: React.FC = () => {
  const averageLoans = Hooks.useAverageLoans();
  const tuitions = Hooks.useTuitionCostComparision();
  const fundGrowth = Hooks.useCollegeFundGrowth();
  const initialized = useMemo(
    () => averageLoans && tuitions && fundGrowth,
    [averageLoans, tuitions, fundGrowth]
  );
  type CscDisplay = 'CostByState' | 'FundGrowth';
  const [selectedTab, onTabSelected] = useState<CscDisplay>('CostByState');
  const tabs: CscDisplay[] = ['CostByState', 'FundGrowth'];
  const chartData: ChartLineSeries[] = createChartData(fundGrowth ?? {});

  const segmentColors = [
    Colors.primaryLightest,
    Colors.primaryLighter,
    Colors.primaryLight,
    Colors.primaryBase,
    Colors.primaryDark,
  ];

  const segmentTextColors = [
    Colors.secondaryBase,
    Colors.secondaryBase,
    Colors.tertiaryBase,
    Colors.tertiaryBase,
    Colors.tertiaryBase,
  ];

  const data: ChartStateHoneycombCellData[] | undefined = averageLoans?.map((x) => {
    const segmentIndex = getStateTheme(x.studentLoans ?? 0);
    const state = x.abbreviation ?? '';

    return {
      stateAbbreviation: state,
      textColor: segmentTextColors[segmentIndex],
      color: segmentColors[segmentIndex],
      stateName: x.name ?? '',
      stateValue: formatWholeDollars(x.studentLoans ?? 0),
    };
  });

  if (!initialized || !data) {
    return <Loading />;
  }

  return (
    <Grid marginTop='-20px' display='flex' justifyContent='space-between' alignItems='center'>
      <Grid container flexDirection='column'>
        <Stack className='csc-container' spacing={3}>
          <Grid
            item
            container
            direction='row'
            justifyContent='flex-start'
            alignSelf='center'
            mb={3}>
            {tabs.map((tab) => (
              <Box
                onClick={() => onTabSelected(tab)}
                sx={{ borderBottom: selectedTab === tab ? 3 : 0, cursor: 'pointer' }}>
                <Typography
                  mb={2}
                  mr={3}
                  ml={3}
                  variant='p18Bold'
                  color={selectedTab === tab ? 'secondary' : 'primary'}>
                  {t(tab)}
                </Typography>
              </Box>
            ))}
          </Grid>
          {selectedTab === 'FundGrowth' ? (
            <FundGrowthView chartData={chartData} />
          ) : (
            <CostByStateView
              data={data}
              segmentColors={segmentColors}
              averageLoans={averageLoans ?? []}
              tuitions={tuitions ?? []}
            />
          )}
        </Stack>
      </Grid>
    </Grid>
  );
};

const getStateTheme = (amount: number): number => {
  if (amount < 32000) return 0;
  if (amount < 35000) return 1;
  if (amount < 37000) return 2;
  if (amount < 40000) return 3;
  return 4;
};

function createChartData(resultData: Domain.CollegeFundGrowthData): ChartLineSeries[] {
  return [
    {
      color: Colors.alternateBase,
      data:
        resultData[50]?.map((y, i) => {
          return {
            x: i,
            y: y,
          };
        }) ?? [],
    },
    {
      color: Colors.accentBase,
      data:
        resultData[100]?.map((y, i) => {
          return {
            x: i,
            y: y,
          };
        }) ?? [],
    },
    {
      color: Colors.primaryBase,
      data:
        resultData[250]?.map((y, i) => {
          return {
            x: i,
            y: y,
          };
        }) ?? [],
    },
    {
      color: Colors.secondaryBase,
      data:
        resultData[500]?.map((y, i) => {
          return {
            x: i,
            y: y,
          };
        }) ?? [],
    },
  ];
}

const meta = {
  background: <img src={undefined} />,
} satisfies LayoutMeta<ToolsLayoutMeta>;

export default withLayoutMeta(CollegeSavingsChartsView, meta);
