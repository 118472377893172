import { Data, Domain, Hooks } from '@3nickels/data-modules';
import { useObservable } from '@aesop-fables/scrinium';
import { Box, Grid, Drawer, Stack } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { WizardFooter } from '../../../components/form/WizardFooter';
import { Loading } from '../../../hooks/useLoading';
import { Spacing } from '../../../themes';
import { LayoutMeta, withLayoutMeta } from '../../../types/LayoutMeta';
import { AccountDetailsLayoutMeta } from '../AccountDetailsLayout';
import { SummaryView } from '../../../components/SummaryHeader';
import { useMessage } from '../../../hooks/useMessage';
import { t } from 'i18next';
import AnnuityBasicsEditView from './AnnuityBasicsEditView';
import AnnuityPayoutEditView from './AnnuityPayoutEditView';
import AnnuityBasicsCard from './AnnuityBasicsCard';
import AnnuityPayoutCard from './AnnuityPayoutCard';

declare type AnnuitySummaryDrawerType = 'basic' | 'payout';

const AnnuitySummaryViewWrapper: React.FC = () => {
  const { wizard, params } = Hooks.useAnnuityWizard();
  const { id } = useParams();
  const isStarted = useObservable(wizard.isStarted$) ?? false;

  const annuityId = parseInt(id ?? '');
  useEffect(() => {
    if (typeof isStarted !== undefined && !isStarted) {
      wizard.start({ id: annuityId });
    }
  }, [isStarted]);

  if (!isStarted || !params) {
    return <Loading />;
  }

  return <AnnuitySummaryView wizard={wizard} params={params} />;
};

export interface InvestmentAccountSummaryViewProps {
  wizard: Data.Annuities.AnnuityWizard;
  params: Data.Annuities.AnnuityWizardParams;
}

// We need guards because the hooks are getting tripped up
const AnnuitySummaryView: React.FC<InvestmentAccountSummaryViewProps> = ({ wizard, params }) => {
  const data = Hooks.useAnnuityWizardData();
  const commands = Hooks.useCommands();
  const { showMessage } = useMessage();
  const includeSpouse = Hooks.useIncludeSpouse();
  const [drawerKey, setDrawerKey] = useState<AnnuitySummaryDrawerType | undefined>(undefined);
  const [open, setOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const { retirementPlans, spouseRetirementPlans } = Hooks.useRetirementPlanData();
  const accounts = spouseRetirementPlans
    ? [...retirementPlans, ...spouseRetirementPlans]
    : retirementPlans;
  const account = useMemo(
    () => accounts.find((x) => x.id === params?.id) as Domain.PlanData,
    [accounts, params]
  );

  const openDrawer = (key?: AnnuitySummaryDrawerType) => {
    wizard.selectStep('basic');
    setDrawerKey(key);
    setOpen(true);
  };

  const removeAccountHandler = async () => {
    if (!account.id) {
      showMessage(t('SomethingWentWrong').toString(), 'error');
      return;
    }

    try {
      await commands.execute(Data.Annuities.Commands.DeleteAnnuity, account.id);
      showMessage(t('AnnuityRemoved').toString(), 'success');
      navigate('/account-details');
    } catch (err) {
      showMessage(t('SomethingWentWrong').toString(), 'error');
    }
  };

  return (
    <SummaryView
      accountName={data.basic.annuityName ?? ''}
      balance={data.basic.monthlyIncome ?? 0}
      onRemoveAccount={removeAccountHandler}
      accountType={Domain.FinancialAccountTypeEnum.InvestmentAccount}
      accountSubType={Domain.planTypeToAccountLabel[Domain.PlanTypeEnum['Annuity | Fixed']]}
      balanceText={'MonthlyPayout'}>
      <Grid item mt={2}>
        <Stack spacing={2}>
          <AnnuityBasicsCard
            data={data.basic}
            includeSpouse={includeSpouse}
            onEdit={() => openDrawer('basic')}
          />
        </Stack>
      </Grid>
      <Grid item mt={2}>
        <Stack spacing={2}>
          <AnnuityPayoutCard data={data.basic} onEdit={() => openDrawer('payout')} />
        </Stack>
      </Grid>

      <WizardDrawer
        drawerKey={drawerKey ?? 'basic'}
        open={open}
        onClose={() => {
          setOpen(false);
          wizard.selectStep('basic');
        }}
      />
      <WizardFooter nextLabel='Done' onDone={() => navigate('/account-details')} />
    </SummaryView>
  );
};

interface WizardDrawerProps {
  open: boolean;
  drawerKey: AnnuitySummaryDrawerType;
  onClose: () => void;
}

const WizardDrawer: React.FC<WizardDrawerProps> = ({ drawerKey, onClose, open }) => {
  return (
    <Drawer anchor='right' open={open} onClose={onClose}>
      <Box p={Spacing.xxs}>
        <WizardForm drawerKey={drawerKey} onBack={onClose} />
      </Box>
    </Drawer>
  );
};

interface WizardFormProps {
  drawerKey: AnnuitySummaryDrawerType;
  onBack: () => void;
}

const WizardForm: React.FC<WizardFormProps> = ({ drawerKey, onBack }) => {
  const { wizard, params } = Hooks.useAnnuityWizard();
  const initialized = useObservable(wizard.initialized$) ?? false;
  const loading = !initialized;
  if (loading) {
    return <Loading />;
  }

  if (!params) return null;

  if (drawerKey === 'basic') {
    return <AnnuityBasicsEditView editing onBack={onBack} />;
  } else if (drawerKey === 'payout') {
    return <AnnuityPayoutEditView editing onBack={onBack} />;
  }

  return null;
};

export interface AnnuitySummaryProps {
  onEdit: () => void;
  needsAttention?: boolean;
}

const meta = {
  nextLocaleKey: 'Done',
  headerVariant: 'summary',
} satisfies LayoutMeta<AccountDetailsLayoutMeta>;

export default withLayoutMeta(AnnuitySummaryViewWrapper, meta);
