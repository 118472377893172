import { Typography } from '@mui/material';
import { Svgs } from '../assets/svg';
import { appStore, googlePlay, qrCodeScanMe } from '../assets/png';
import { Column, Row, Spacer, TopNavBar } from '../components';
import { useDeviceType } from '../hooks/useDeviceType';
import { mobileMargin } from '../App';
import { Hooks } from '@3nickels/data-modules';
import { useContentView } from '../pages/layout/ContentViewContext';
import ContentView from '../pages/layout/Content';
import { t } from 'i18next';

const LandingPage = () => {
  const { sideNav } = useContentView();

  const personData = Hooks.useAccountAndPersonData();
  const { isMobile } = useDeviceType();

  return (
    <ContentView sideNavMenuItems={sideNav('Home')}>
      <TopNavBar />
      <Column
        style={{
          margin: isMobile ? mobileMargin : '15px 100px 150px 100px',
          alignItems: 'center',
        }}>
        <Spacer height='lg' />
        <Typography variant='p45Bold' color='primary' align='center'>
          {t('WelcomeDownloadTheApp', { name: personData ? personData.firstName : `FirstName` })}
        </Typography>
        <Spacer height='sm' />
        <Typography variant='p20' color='secondary'>
          {t('JustDownloadThe3NickelsAppAndLogIn')}
        </Typography>
        <Spacer height='xs' />
        <Spacer height='lg' />
        <Column>
          <Row style={{ justifyContent: 'space-between' }}>
            <Column style={{ width: '50%', justifyContent: 'space-between' }}>
              <img
                src={qrCodeScanMe}
                alt='qr code'
                style={{
                  maxWidth: '115px',
                  minWidth: '50px',
                }}
              />
            </Column>
            <Column style={{ alignItems: 'center', justifyContent: 'center' }}>
              <a href='https://apps.apple.com/us/app/3nickels/id1544362343'>
                <img src={appStore} alt='appStore' height='50px' />
              </a>
              <a href='https://play.google.com/store/apps/details?id=com.guidedchoice.iiinickels'>
                <img src={googlePlay} alt='googlePlay' height='50px' />
              </a>
            </Column>
          </Row>
          <Spacer height='sm' />
          <Svgs.ArtThumbsUp />
          <Spacer height='lg' />
        </Column>
      </Column>
    </ContentView>
  );
};

export default LandingPage;
