import { Button, Divider, Grid, Typography } from '@mui/material';
import { Spacer } from '../../components';
import ChatHistoryItem from './ChatHistoryItem';
import { t } from 'i18next';
import React from 'react';
import { ChatData, ChatMetadata } from '@3nickels/data-modules/lib/data/gpt/chat';
import { Colors } from '../../themes';
import { Svgs } from '../../assets/svg';
import { useNavigate } from 'react-router-dom';

export interface ChatHistoryProps {
  chatMetadatas: ChatMetadata[];
  chat: ChatData;
  handleChatMetadataClick: (chatMetadata: ChatMetadata) => void;
  handleDeleteChatClick: (chatMetadata: ChatMetadata) => void;
  handleNewChatClick: () => void;
}

const ChatHistory: React.FC<ChatHistoryProps> = ({
  chatMetadatas,
  chat,
  handleChatMetadataClick,
  handleDeleteChatClick,
  handleNewChatClick,
}) => {
  const navigate = useNavigate();
  const messageLoading = chat.messages?.at(chat.messages.length - 1)?.loading;
  const onExitClicked = () => {
    navigate(-1);
  };

  return (
    <Grid
      container
      direction='column'
      style={{
        height: '100%',
        zIndex: 2,
        backgroundColor: Colors.tertiaryBase,
        boxShadow: '5px 5px 10px 0 rgba(142, 148, 180, 0.23)',
      }}>
      <Typography
        color='primary'
        variant='p30Bold'
        style={{ marginLeft: '20px', marginTop: '25px' }}>
        {t('Messages')}
      </Typography>
      <Spacer height='xxs' />
      <Grid
        container
        direction='column'
        flexWrap='nowrap'
        style={{
          flex: 1,
          overflowY: 'auto',
          opacity: messageLoading ? '20%' : '100%',
        }}>
        {chatMetadatas.map((chatMetadata, index) => (
          <React.Fragment key={index}>
            <ChatHistoryItem
              chatMetadata={chatMetadata}
              chat={chat}
              disableInteractions={messageLoading ?? false}
              handleChatMetadataClick={handleChatMetadataClick}
              handleDeleteChatClick={handleDeleteChatClick}
            />
            {index < chatMetadatas.length - 1 && (
              <Divider
                style={{
                  borderColor: Colors.primaryLighter,
                  margin: '10px 20px',
                }}
              />
            )}
          </React.Fragment>
        ))}
      </Grid>
      <Grid
        item
        style={{
          flexShrink: 0,
          padding: '25px 20px',
        }}
        display='flex'
        justifyContent={'space-between'}>
        <Button
          variant='ghost'
          size='large'
          onClick={onExitClicked}
          sx={{
            display: { xl: 'inline', lg: 'inline' },
            padding: '0px !important',
          }}>
          <Grid display='flex' alignItems='center' columnGap='10px'>
            <Svgs.ActionSmallMenuClose />
            {t('Exit')}
          </Grid>
        </Button>
        <Button
          variant='outlined'
          color='secondary'
          disabled={messageLoading}
          onClick={handleNewChatClick}>
          {t('StartNewChat')}
        </Button>
      </Grid>
    </Grid>
  );
};

export default ChatHistory;
