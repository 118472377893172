import { Hooks } from '@3nickels/data-modules';
import { useEffect } from 'react';
import { useMessage } from './useMessage';
import { t } from 'i18next';

export function useInstitutionalAccountReviewStatus() {
  const { institutionalAccount, institutionalAccountRequiresReview } =
    Hooks.useInstitutionalAccount();
  const { showMessage } = useMessage();

  useEffect(() => {
    if (institutionalAccount?.active && institutionalAccountRequiresReview) {
      showMessage(
        t('YourAccountXRequiresReview', { x: institutionalAccount.name }).toString(),
        'warning'
      );
    }
  }, [institutionalAccount, institutionalAccountRequiresReview]);
}
