import { useCallback, useEffect } from 'react';
import { Data, Hooks } from '@3nickels/data-modules';
import { useNavigate, useOutletContext } from 'react-router';
import { t } from 'i18next';
import dayjs from 'dayjs';
import { Divider, Grid, Typography } from '@mui/material';
import { LayoutContext, LayoutMeta, withLayoutMeta } from '../../../../../types/LayoutMeta';
import { MedicalBenefitLayoutMeta } from '../MedicalBenefitLayout';
import { MedicalBenefitsContent } from '../MedicalBenefitsContent';
import MultipleCheckbox from '../../../../../components/MultipleCheckbox';
import CardButton from '../components/CardButton';
import { mapAbbrevToDisplayAbbrev, mapShortNameToSubtitle } from '../maps';

const MedicalBenefitsSelectorStepOne: React.FC = () => {
  const navigate = useNavigate();
  const { setMeta } = useOutletContext<LayoutContext>();
  const {
    age,
    availablePlanOptions,
    setAvailablePlanOptions,
    calculateSuggestedBenefits,
    setAge,
    iDontKnow,
    setIDontKnow,
  } = Hooks.useMedicalBenefitsContext();
  const personalInfo = Hooks.usePersonData() ?? {};
  const today = dayjs();
  const currentAge = today.diff(personalInfo.birthDate, 'year');
  const plansWithoutHsa = Data.MedicalBenefits.ALL_PLANS.slice(0, 4);
  const plansWithHsa = Data.MedicalBenefits.ALL_PLANS.slice(4);

  useEffect(() => {
    if (age === 0) setAge(currentAge);
  }, [currentAge, age]);

  useEffect(() => {
    setMeta(() => ({
      stepNumber: 1,
      showNext: true,
      onNext: onSubmit,
      disableNextButton: availablePlanOptions.length === 0 && !iDontKnow,
    }));
  }, [currentAge, availablePlanOptions, iDontKnow]);

  const updateContext = async (saveToApi: boolean) => {
    try {
      if (saveToApi) {
        calculateSuggestedBenefits(1);
        navigate('/tools/medical/medical-benefit-selector-tool/summary');
      } else {
        if (age >= 46 || currentAge >= 46) {
          navigate('/tools/medical/medical-benefit-selector-tool/3');
        } else {
          navigate('/tools/medical/medical-benefit-selector-tool/2');
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const onSubmit = useCallback(() => {
    if (availablePlanOptions.length === 1) {
      updateContext(true);
    } else if (age >= 27 || currentAge >= 27) {
      updateContext(false);
    } else {
      updateContext(true);
    }
  }, [availablePlanOptions, iDontKnow, age]);

  const onClickIDontKnow = () => {
    if (!iDontKnow) {
      setAvailablePlanOptions([]);
    }
    setIDontKnow((prev) => !prev);
  };

  const onClickPlanOption = (x: Data.MedicalBenefits.HealthPlanOptions, checked: boolean) => {
    if (checked) {
      if (iDontKnow) setIDontKnow(false);
      setAvailablePlanOptions((prev) => [...prev, x]);
    } else {
      setAvailablePlanOptions((prev) => prev.filter((y) => y !== x));
    }
  };

  const valueFn = (x: Data.MedicalBenefits.HealthPlanOptions) => availablePlanOptions.includes(x);
  const keyFn = (x: Data.MedicalBenefits.HealthPlanOptions) => x;
  const titleFn = (x: Data.MedicalBenefits.HealthPlanOptions) => mapAbbrevToDisplayAbbrev(x);
  const subTitleFn = (x: Data.MedicalBenefits.HealthPlanOptions) => mapShortNameToSubtitle(x);

  return (
    <MedicalBenefitsContent
      questionNumber={1}
      questionTitle={'WhichTypesAvailable'}
      questionSubtitle={'SelectAllThatApply'}
      questionContent={
        <Grid display='flex' flexDirection={'column'}>
          <Grid mb={3} display='flex' flexDirection='row' width='100%'>
            <Grid display='flex' flexDirection='column' width='40%'>
              <Typography variant='p14Bold' color='secondary'>
                {t('PlansWithoutHSA')}
              </Typography>
              <MultipleCheckbox
                items={plansWithoutHsa}
                keyFn={keyFn}
                titleFn={titleFn}
                subTitleFn={subTitleFn}
                valueFn={valueFn}
                onChange={onClickPlanOption}
              />
            </Grid>
            <Grid ml={15} display='flex' flexDirection='column' width='40%'>
              <Typography variant='p14Bold' color='secondary'>
                {t('PlansWithHSA')}
              </Typography>
              <MultipleCheckbox
                items={plansWithHsa}
                keyFn={keyFn}
                titleFn={titleFn}
                subTitleFn={subTitleFn}
                valueFn={valueFn}
                onChange={onClickPlanOption}
              />
            </Grid>
          </Grid>
          <Divider className='or-divider' color='secondary' flexItem>
            <Typography variant='p18Bold' color='secondary'>
              OR
            </Typography>
          </Divider>

          <CardButton
            label={'Idk'}
            checked={iDontKnow}
            onClick={onClickIDontKnow}
            cardStyle={{ width: '100%', justifyContent: 'center' }}
          />
        </Grid>
      }
    />
  );
};

const meta = {
  showNext: true,
  stepNumber: 1,
} satisfies LayoutMeta<MedicalBenefitLayoutMeta>;

export default withLayoutMeta(MedicalBenefitsSelectorStepOne, meta);
