import { Domain } from '@3nickels/data-modules';
import { Grid, Typography } from '@mui/material';
import { t } from 'i18next';
import { useMemo } from 'react';

interface LmsUnitProgressHeaderProps {
  unit: Domain.UnitDto;
  selected?: boolean;
  variant?: string;
}

const LmsUnitProgressHeader: React.FC<LmsUnitProgressHeaderProps> = ({
  unit,
  selected,
  variant,
}) => {
  const progress = useMemo(() => {
    let completedLessons = 0;
    unit.lessons?.forEach((lesson) => {
      if (lesson.complete) completedLessons++;
    });
    return completedLessons;
  }, [unit]);
  const duration = useMemo(() => {
    const totalMins = Math.floor(
      unit.lessons?.reduce((acc, lesson) => acc + (lesson.minutes ?? 0), 0) ?? 0
    );

    if (totalMins >= 60) {
      const hours = Math.floor(totalMins / 60);
      const mins = totalMins % 60;
      return t('xUnit1YUnit2', {
        x: hours,
        y: mins,
        unit1: hours === 1 ? 'hr' : 'hrs',
        unit2: 'min',
      });
    } else {
      return t('xMin', { x: totalMins });
    }
  }, [unit]);

  return (
    <Grid id={unit.unitId?.toString()} overflow='hidden'>
      <Typography
        variant='p18Bold'
        color={selected || variant === 'contained' ? 'secondary' : 'primary'}
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          display: 'block',
        }}>
        {unit.name}
      </Typography>
      <Grid container gap='10px'>
        <Typography
          variant='p12'
          color={selected || variant === 'contained' ? 'secondary' : 'primary'}
          fontWeight='normal'>
          {progress}/{unit.lessons?.length ?? 0}
        </Typography>
        <Typography
          variant='p12'
          color={selected || variant === 'contained' ? 'secondary' : 'primary'}
          fontWeight='normal'>
          |
        </Typography>
        <Typography
          variant='p12'
          color={selected || variant === 'contained' ? 'secondary' : 'primary'}
          fontWeight='normal'>
          {duration}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default LmsUnitProgressHeader;
