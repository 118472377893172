/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo } from 'react';
import { BudgetSectionProps, GivingFormData } from '../Types';
import FormContent from '../../../components/form/FormContent';
import { GivingForm } from './GivingForm';
import { useForm } from 'react-hook-form';
import { CollapsiblePanel } from '../../../components/CollapsiblePanel';
import { Api, Data, formatWholeDollars } from '@3nickels/data-modules';
import { Svgs } from '../../../assets/svg';
import { Grid } from '@mui/material';
import { t } from 'i18next';

interface GivingSectionProps extends BudgetSectionProps {
  monthlySpending: Api.MonthlySpendingRest;
}

const GivingSection: React.FC<GivingSectionProps> = ({ budget, mode, monthlySpending }) => {
  const summary = useMemo(
    () => Data.Budget.adjustMonthlyAmount(mode, budget?.totalGiving),
    [budget?.totalGiving, mode]
  );
  const defaultValues = useMemo(() => {
    return {
      cashDonations: Data.Budget.adjustMonthlyAmount(mode, monthlySpending?.cashDonations),
      gifts: Data.Budget.adjustMonthlyAmount(mode, monthlySpending?.gifts),
      otherGifts: Data.Budget.adjustMonthlyAmount(mode, monthlySpending?.otherGifts),
    };
  }, [monthlySpending, mode]);

  const methods = useForm<GivingFormData>({ defaultValues });
  return (
    <CollapsiblePanel
      header={t('GIVING')}
      icon={<Svgs.DisplaySmallCustomGifts />}
      summary={formatWholeDollars(summary)}>
      <Grid className='embedded-content'>
        <FormContent formProviderProps={methods}>
          <GivingForm defaultValues={defaultValues} mode={mode} />
        </FormContent>
      </Grid>
    </CollapsiblePanel>
  );
};

export default GivingSection;
