import { t } from 'i18next';
import BulletedText from '../../../../components/BulletedText';
import HelpPopover, { HelpPopoverText } from '../../../../components/HelpPopover';
import { Margins } from '../../../../themes';
import { Stack } from '@mui/material';
import { Trans } from 'react-i18next';

export const CostofLivingModal: React.FC = () => {
  return (
    <HelpPopover title={t('CostofLiving')}>
      <HelpPopoverText style={{ display: 'inline', ...Margins.mb_sm }}>
        <Trans i18nKey='CostOfLivingIsComparedUsingTheIndex' />
      </HelpPopoverText>
      <Stack mr={2} ml={2}>
        <BulletedText>{t('AnyStateHigherThan100')}</BulletedText>
        <BulletedText>{t('AnyStateLowerThan100')}</BulletedText>
      </Stack>
    </HelpPopover>
  );
};
