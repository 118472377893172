import 'reflect-metadata';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import ThemeWrapper from './themes/ThemeWrapper';
import App from './App';
import { bootstrap } from './configuration';
import { ServiceProvider } from '@aesop-fables/containr-react';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import { Backdrop } from '@mui/material';
import { Loading } from './hooks/useLoading';
import { SubmitOnEnter } from './pages/layout/SubmitOnEnter';
require('dayjs/locale/es');

const container = bootstrap();
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const SuspenseHandler = () => {
  return (
    <Backdrop open={true}>
      <Loading />
    </Backdrop>
  );
};

root.render(
  <ServiceProvider rootContainer={container}>
    <Suspense fallback={<SuspenseHandler />}>
      <I18nextProvider i18n={i18n}>
        <SubmitOnEnter>
          <ThemeWrapper>
            <App />
          </ThemeWrapper>
        </SubmitOnEnter>
      </I18nextProvider>
    </Suspense>
  </ServiceProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
