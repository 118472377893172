import { Domain } from '@3nickels/data-modules';
import { CollapsiblePanel } from '../../../components/CollapsiblePanel';
import { Grid, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import UnitProgressHeader from './UnitProgressHeader';
import { Svgs } from '../../../assets/svg';
import { PlayCircleOutline } from '@mui/icons-material';
import { Colors } from '../../../themes';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';

interface UnitProgressCardProps {
  unit: Domain.Unit;
  selectedLesson?: Domain.Lesson;
  unitSelected?: boolean;
  variant?: 'compressed' | 'contained';
}

const UnitProgressCard: React.FC<UnitProgressCardProps> = ({
  unit,
  selectedLesson,
  variant = 'contained',
}) => {
  const unitSelected = useMemo(() => {
    const found = unit.lessons.find((x) => x.title === selectedLesson?.title);
    return typeof found !== 'undefined';
  }, [selectedLesson]);

  if (variant === 'compressed') {
    return (
      <CompressedUnitProgressCard
        unit={unit}
        selectedLesson={selectedLesson}
        unitSelected={unitSelected}
        variant={variant}
      />
    );
  }

  return (
    <CollapsiblePanel
      initiallyExpanded={unitSelected}
      collapsedHeaderStyle={{
        background: `linear-gradient(${
          unit.progress === 100 ? Colors.secondaryLight : Colors.primaryLight
        } 0 0) bottom left / ${unit.progress ?? 0}% 2px no-repeat, white`,
      }}
      variant='contained'
      header={<UnitProgressHeader unit={unit} selected={unitSelected} />}
      summary={unit.progress === 100 && <Svgs.IconCheckmark />}>
      <Grid container gap={variant === 'contained' ? '20px' : undefined}>
        {unit.lessons.map((lesson) => (
          <UnitProgressLesson lesson={lesson} selected={lesson.title === selectedLesson?.title} />
        ))}
      </Grid>
    </CollapsiblePanel>
  );
};

const CompressedUnitProgressCard: React.FC<UnitProgressCardProps> = ({
  unit,
  unitSelected,
  selectedLesson,
}) => {
  return (
    <CollapsiblePanel
      initiallyExpanded={unitSelected}
      variant='compressed'
      header={<UnitProgressHeader unit={unit} selected={unitSelected} />}
      expandIconPosition='end'>
      <Grid container gap={undefined}>
        {unit.lessons.map((lesson) => (
          <UnitProgressLesson
            lesson={lesson}
            selected={lesson.title === selectedLesson?.title}
            variant='compressed'
          />
        ))}
      </Grid>
    </CollapsiblePanel>
  );
};

interface UnitProgressLessonProps {
  lesson: Domain.Lesson;
  variant?: 'compressed' | 'contained';
  selected?: boolean;
}

const UnitProgressLesson: React.FC<UnitProgressLessonProps> = ({
  lesson,
  selected,
  variant = 'contained',
}) => {
  const navigate = useNavigate();

  const duration = useMemo(() => {
    return Math.round((lesson.duration ?? 0) / 60);
  }, [lesson]);

  const startLesson = () => {
    navigate(`/learning/university/${encodeURIComponent(lesson.title)}`);
  };

  return (
    <Grid
      container
      className='unit-progress-card-lesson'
      style={{
        background: `linear-gradient(${
          lesson.complete ? Colors.secondaryLight : Colors.primaryLight
        } 0 0) bottom left / ${lesson.progress ?? 0}% 2px no-repeat, ${Colors.secondaryLighter}`,
      }}
      onClick={startLesson}>
      <Grid className='unit-progress-card-details'>
        <Svgs.DisplaySmallCustomVideoCamera
          className='hide-on-hover'
          style={{ minWidth: '30px', minHeight: '30px' }}
        />
        <PlayCircleOutline
          className='show-on-hover'
          color='primary'
          style={{ minWidth: '30px', minHeight: '30px' }}
        />
        <Grid display='flex' flexDirection='column' rowGap='5px'>
          <Typography
            className='lesson-title'
            variant={variant === 'compressed' ? 'p12' : 'p16'}
            color={selected ? 'secondary' : 'primary'}
            fontWeight={selected ? 'bold' : undefined}>
            {lesson.title}
          </Typography>
          <Typography
            variant='p12'
            color={selected ? 'secondary' : 'primary'}
            fontWeight={selected ? 'bold' : undefined}>
            {t('xMin', { x: duration })}
          </Typography>
        </Grid>
      </Grid>
      {variant !== 'compressed' && lesson.complete && <Svgs.IconCheckmark />}
    </Grid>
  );
};

export default UnitProgressCard;
