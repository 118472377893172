import React from 'react';
import { Card, CardHeader, Checkbox, Grid, Typography } from '@mui/material';
import { Colors } from '../themes';
import { t } from 'i18next';

export interface CheckboxItemProps<T> {
  item: T;
  checked: boolean;
  title: React.ReactNode;
  subTitle?: string;
  onClick?: (i: T, checked: boolean) => void;
}

export interface MultipleCheckboxProps<T> {
  items: T[];
  keyFn: (i: T) => string;
  titleFn: (i: T) => React.ReactNode;
  subTitleFn?: (i: T) => string | undefined;
  onChange?: (i: T, checked: boolean) => void;
  valueFn: (i: T) => boolean;
}

function CheckboxItem<T>(props: CheckboxItemProps<T>): React.ReactNode {
  const { checked, item, title, subTitle, onClick } = props;

  return (
    <Card
      className={checked ? 'checkbox-card-checked' : 'checkbox-card-unchecked'}
      onClick={() => {
        if (typeof onClick === 'function') {
          onClick(item, !checked);
        }
      }}
      elevation={0}>
      <CardHeader
        disableTypography
        title={
          <Grid display='flex' flexDirection='row' alignItems='center'>
            <Checkbox
              sx={{
                '& .MuiSvgIcon-root': {
                  fontSize: 28,
                  fill: checked ? Colors.primaryBase : Colors.primaryLight,
                },
              }}
              checked={checked}
              size='medium'
            />
            <Grid display='flex' flexDirection='column'>
              <Typography color='primary' variant='p14Bold'>
                {title}
              </Typography>
              {subTitle && (
                <Typography mt={'3px'} color='primary' variant='p12'>
                  {t(subTitle)}
                </Typography>
              )}
            </Grid>
          </Grid>
        }
      />
    </Card>
  );
}

export default function MultipleCheckbox<T>(props: MultipleCheckboxProps<T>) {
  const { items, keyFn, valueFn, titleFn, subTitleFn, onChange } = props;
  return items?.map((i) => (
    <CheckboxItem<T>
      key={keyFn(i)}
      checked={valueFn(i)}
      item={i}
      title={titleFn(i)}
      subTitle={subTitleFn ? subTitleFn(i) : undefined}
      onClick={onChange}
    />
  ));
}
