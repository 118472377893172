import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import {
  Card,
  ClickAwayListener,
  Grid,
  IconButton,
  Link,
  Popover,
  Typography,
} from '@mui/material';
import React, { CSSProperties, ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Colors, Percentages, Spacing } from '../themes';

// Default will be Popover
export type GroupPanelVariant = 'Collapsible' | 'Popover';

export type Item = {
  textKey: string;
  url: string;
  onClick?: () => void | Promise<void>;
};

export type Group = {
  icon?: React.ReactNode;
  textKey?: string;
  subTextKey?: string;
  content?: ReactNode;
  style?: CSSProperties;
  url?: string;
  items?: Item[];
  onClick?: () => void | Promise<void>;
};

export interface GroupPanelsProps {
  groups: Group[];
  defaultGroupRows?: Group[][];
  groupsPerRow?: number;
  variant?: GroupPanelVariant;
}

const GroupChildView: React.FC<Item> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Grid
      item
      display='flex'
      p={Spacing.xxxs + 1}
      onClick={async () => {
        if (props.onClick) {
          await props.onClick();
        }
        if (props.url) {
          navigate(props.url);
        }
      }}>
      <Typography color='secondary' fontWeight='bold'>
        {t(props.textKey)}
      </Typography>
    </Grid>
  );
};

const CollapsibleGroupView: React.FC<Group> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [childIsOpen, setChildIsOpen] = React.useState<Record<string, boolean>>({});
  const { textKey = 'hidden' } = props;

  const toggleChild = (textKey: string) => {
    setChildIsOpen((val) => {
      const newVal = val && !val[textKey];
      val[textKey] = newVal;
      return Object.assign({}, val);
    });
  };

  return (
    <Card
      className='panel'
      elevation={4}
      style={{ visibility: textKey === 'hidden' ? 'hidden' : undefined, ...props.style }}>
      {typeof props.content !== 'undefined' ? (
        props.content
      ) : (
        <Grid container p={Spacing.xxxs + 1} alignItems='center'>
          <Grid item xs={2}>
            {props.icon}
          </Grid>
          <Grid item xs={9}>
            <Typography
              variant='h6'
              component='h3'
              color='primary'
              onClick={async () => {
                if (props.items) {
                  toggleChild(textKey);
                } else {
                  if (props.onClick) {
                    await props.onClick();
                  }
                  if (props.url) {
                    navigate(props.url);
                  }
                }
              }}>
              {t(textKey)}
            </Typography>
          </Grid>
          <Grid item xs={1} display='flex' justifyContent='right'>
            {props.items && props.items.length > 0 && !childIsOpen[textKey] && (
              <IconButton size='small' onClick={() => toggleChild(textKey)}>
                <KeyboardArrowDown fontSize='large' color='primary' />
              </IconButton>
            )}
            {props.items && props.items.length > 0 && childIsOpen[textKey] && (
              <IconButton size='small' onClick={() => toggleChild(textKey)}>
                <KeyboardArrowUp fontSize='large' color='primary' />
              </IconButton>
            )}
          </Grid>

          {childIsOpen[textKey] && props.items && (
            <Grid
              container
              className='group-panel-options'
              display='flex'
              borderTop={`1px solid ${Colors.primaryLight}`}
              pt={Spacing.xxxs}
              flexDirection='column'
              pl={{ sm: Spacing.xxs }}>
              {props.items.map((i) => (
                <GroupChildView key={i.textKey} {...i} />
              ))}
            </Grid>
          )}
        </Grid>
      )}
    </Card>
  );
};

const PopoverGroupView: React.FC<Group> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [childIsOpen, setChildIsOpen] = React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const { textKey = 'hidden' } = props;

  return (
    <ClickAwayListener
      onClickAway={() => {
        setChildIsOpen(false);
        setAnchorEl(null);
      }}>
      <Grid
        className='panel-popover'
        visibility={textKey === 'hidden' ? 'hidden' : undefined}
        item
        xs={3}
        onClick={async (event: React.MouseEvent<HTMLDivElement>) => {
          if (props.items) {
            setAnchorEl(event.currentTarget);
            setChildIsOpen(!childIsOpen);
          } else {
            if (props.onClick) {
              await props.onClick();
            }
            if (props.url) {
              navigate(props.url);
            }
          }
        }}>
        <Card
          elevation={4}
          sx={{
            ':hover': {
              backgroundColor: Colors.disabledLight,
              cursor: 'pointer',
            },
            'height': Percentages.Full,
            ...props.style,
          }}>
          {typeof props.content !== 'undefined' ? (
            props.content
          ) : (
            <Grid container p={Spacing.xxxs + 1} flexDirection='column'>
              <Grid item pb={Spacing.xxxs + 1}>
                {props.icon}
              </Grid>
              <Grid item>
                <Typography variant='p18Bold' color='secondary'>
                  {t(textKey)}
                </Typography>
                {props.subTextKey && (
                  <Typography variant='p16' mt={2} color='secondary'>
                    {t(props.subTextKey)}
                  </Typography>
                )}
              </Grid>

              {props.items && (
                <Popover
                  slotProps={{
                    paper: {
                      elevation: 2,
                      sx: {
                        paddingY: 1,
                        width: anchorEl?.clientWidth,
                        maxWidth: '300px',
                        mt: '-4px',
                        ul: {
                          listStyle: 'none',
                          li: {
                            '&:hover': {
                              backgroundColor: 'rgba(0, 0, 0, 0.04)',
                              cursor: 'pointer',
                            },
                            'a': {
                              textDecoration: 'none',
                              display: 'block',
                            },
                          },
                        },
                      },
                    },
                  }}
                  anchorEl={anchorEl}
                  open={childIsOpen}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}>
                  <ul>
                    {props.items.map((m, index) => (
                      <li key={index}>
                        <Link>
                          <Typography
                            color='primary'
                            sx={{
                              display: 'block',
                              paddingX: 2,
                              paddingY: 1,
                            }}
                            onClick={async () => {
                              if (m.onClick) {
                                await m.onClick();
                              }
                              if (m.url) {
                                navigate(m.url);
                              }
                            }}>
                            {t(m.textKey)}
                          </Typography>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </Popover>
              )}
            </Grid>
          )}
        </Card>
      </Grid>
    </ClickAwayListener>
  );
};

const GroupPanels: React.FC<GroupPanelsProps> = (props) => {
  const { groupsPerRow, groups } = props;
  const [groupRows, setGroupRows] = useState<Group[][]>(props.defaultGroupRows ?? []);

  useEffect(() => {
    const rows = [];
    if (groupsPerRow) {
      const rowCount = Math.ceil(groups.length / groupsPerRow);
      for (let i = 0; i < rowCount; i++) {
        const start = i * groupsPerRow;
        const row = groups.slice(start, start + groupsPerRow);
        for (let j = row.length; j < groupsPerRow; j++) {
          row.push({ textKey: 'hidden' });
        }
        rows.push(row);
      }
    } else {
      rows.push(groups);
    }
    setGroupRows(rows);
  }, [groups, groupsPerRow]);

  return (
    <Grid className='group-panel-container'>
      {groupRows.map((groups, index) => (
        <Grid
          className='group-panels'
          key={index}
          item={props.variant === 'Collapsible'}
          container={props.variant === 'Popover'}
          display='flex'
          flexWrap={props.variant === 'Collapsible' ? 'inherit' : 'wrap'}
          flexDirection={props.variant === 'Collapsible' ? 'column' : 'row'}
          alignItems='stretch'
          gap={Spacing.xxs - 3}
          mt={Spacing.xxs - 3}>
          {groups.map((g) =>
            props.variant === 'Collapsible' ? (
              <CollapsibleGroupView key={g.textKey} {...g} />
            ) : (
              <PopoverGroupView key={g.textKey} {...g} />
            )
          )}
        </Grid>
      ))}
    </Grid>
  );
};

export default GroupPanels;
