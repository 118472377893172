import React, { useEffect, useState } from 'react';
import { IconButton } from '@mui/material';
import { Hooks } from '@3nickels/data-modules';
import { useFormContext } from 'react-hook-form';
import { Close, Search } from '@mui/icons-material';
import { useAutoSaveHandler } from '../../../hooks/useAutoSaveHandler';
import TextInput from '../../../components/form/TextInput';
import { useMessage } from '../../../hooks/useMessage';
import { useTranslation } from 'react-i18next';

const LessonSearcher: React.FC = () => {
  const {
    searchString: lessonSearchString,
    setSearchString,
    error,
    setError,
  } = Hooks.useFilteredLessonsContext();
  const { t } = useTranslation();
  const { showMessage } = useMessage();
  const methods = useFormContext();
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const searchString = methods.getValues('searchString');
  const showClose = (isFocused || isHovered) && searchString && searchString.length > 0;

  const { onChange } = useAutoSaveHandler({
    mode: 'full',
    onSave: async (data: { searchString: string }) => {
      setSearchString(data.searchString);
    },
  });

  useEffect(() => {
    if (error) {
      showMessage(t('ErrorSearchingForLessons') as string, 'error');
      setError(false);
    }
  }, [error]);

  return (
    <form onChange={onChange}>
      <TextInput
        name='searchString'
        placeholder={t('SearchByKeyword').toString()}
        defaultValue={lessonSearchString}
        onBlur={() => setIsFocused(false)}
        onMouseDown={(e) => {
          (e.target as HTMLElement).focus();
          setIsFocused(true);
        }}
        onMouseEnter={() => {
          setIsHovered(true);
        }}
        onMouseLeave={() => setIsHovered(false)}
        InputProps={{
          startAdornment: <Search color='primary' style={{ marginRight: '14px' }} />,
          endAdornment: (
            <IconButton
              style={{
                padding: '0px',
                marginLeft: '14px',
                visibility: showClose ? 'visible' : 'hidden',
              }}
              onClick={() => {
                methods.setValue('searchString', '', { shouldDirty: true });
                setSearchString('');
              }}>
              <Close color='primary' />
            </IconButton>
          ),
        }}
      />
    </form>
  );
};

export default LessonSearcher;
