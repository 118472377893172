import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import ContentView from '../layout/Content';
import { Grid, Typography } from '@mui/material';
import { Button } from '../../components/buttons/Button';
import { Spacing } from '../../themes';
import { useLayoutMeta } from '../../hooks/useLayoutMeta';
import { useTranslation } from 'react-i18next';
import { Modal } from '../../components';
import { useContentView } from '../layout/ContentViewContext';
import HexHeader from '../layout/header-variants/HexHeader';

export type GoalsLayout = Record<string, unknown>;

export declare type GoalsLayoutMeta = {
  title?: string;
  headerVariant?: 'addGoal' | 'title' | 'hex';
};

interface LeaveGoalDetailsModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleSave: (data?: any) => void;
}

export const LeaveGoalDetailsModal: React.FC<LeaveGoalDetailsModalProps> = (props) => {
  const { t } = useTranslation();
  return (
    <Modal
      titleStyle={{ paddingLeft: '16px' }}
      title={t('LeaveGoalDetails?').toString()}
      primaryButtonText={t('LeaveGoalDetails').toString()}
      secondaryButtonText={t('StayHere').toString()}
      {...props}>
      <Grid pl={2} pr={12} mb={4}>
        <Typography variant='p16' color='secondary'>
          {t('GoToAccountDetails')}
        </Typography>
      </Grid>
    </Modal>
  );
};

export const LeaveGoalModal: React.FC<LeaveGoalDetailsModalProps> = (props) => {
  const { t } = useTranslation();
  return (
    <Modal
      titleStyle={{ paddingLeft: '16px' }}
      title={t('LeaveGoal?') as string}
      primaryButtonText='LeaveGoal'
      secondaryButtonText='StayHere'
      {...props}>
      <Grid pl={2} pr={12} mb={4}>
        <Typography variant='p16' color='secondary'>
          {t('GoToAccountDetails')}
        </Typography>
      </Grid>
    </Modal>
  );
};

const GoalsHeader: React.FC<GoalsLayoutMeta> = (props) => {
  const { t } = useTranslation();
  const { title } = props;

  return (
    <Typography variant='p18Bold' color='primary' alignSelf='end'>
      {title ? t('GoalsGoalType', { type: t(title) }) : t('Goals')}
    </Typography>
  );
};

const GoalsLayout: React.FC<GoalsLayout> = () => {
  const { meta, setMeta } = useLayoutMeta<GoalsLayoutMeta>();
  const navigate = useNavigate();
  const { sideNav } = useContentView();

  const onBack = () => {
    if (meta.onBack) {
      meta.onBack(navigate);
    } else {
      navigate(-1);
    }
  };

  const headerVariableControl = {
    addGoal: (
      <Button variant='contained' label='PlusAddAGoal' onClick={() => navigate('/goals/select')} />
    ),
    hex: <HexHeader stepProps={meta.hexSteps} startAdornment={<GoalsHeader {...meta} />} />,
    title: <GoalsHeader {...meta} />,
  };

  return (
    <ContentView
      sideNavMenuItems={sideNav('Goals')}
      headerChildren={meta.header ?? headerVariableControl[meta.headerVariant ?? 'title']}>
      <Outlet context={{ setMeta }} />
      {meta.showBack && (
        <Grid container display='flex' justifyContent='space-between' mt={Spacing.xxs}>
          <Grid item sm={2}>
            <Button fullWidth label='Back' variant='outlined' color='secondary' onClick={onBack} />
          </Grid>
        </Grid>
      )}
    </ContentView>
  );
};

export default GoalsLayout;
