/* eslint-disable @typescript-eslint/no-explicit-any */
import { Backdrop, Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';
import {
  Data,
  Domain,
  Hooks,
  ObservableGuard,
  useObservableGuardCondition,
} from '@3nickels/data-modules';
import { FormLoader } from '../../../../components/FormLoader';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import FormContent from '../../../../components/form/FormContent';
import { WizardStep, useObservable } from '@aesop-fables/scrinium';
import { WizardFooter } from '../../../../components/form/WizardFooter';
import { Loading, useLoading } from '../../../../hooks/useLoading';
import { EditViewProps, getWizardFooterProps } from '../../../EditViewUtils';
import { map } from 'rxjs';
import { LayoutMeta, withLayoutMeta } from '../../../../types/LayoutMeta';
import { AccountDetailsLayoutMeta } from '../../AccountDetailsLayout';
import { cleanWholeNumberStr } from '../../../../helpers/utilityFunctions';
import RetirementPlanEmployerContributionsForm from './RetirementPlanEmployerContributionsForm';
import { Spacing } from '../../../../themes';
import RetirementPlanMultiTierEmployerContributionsForm from './RetirementPlanMultiTierEmployerContributionsForm';
import HelpTitle from '../../../../components/HelpTitle';
import HelpPopover from '../../../../components/HelpPopover';
import BulletedList from '../../../../components/BulletedList';

const accountContributionsSchema = () =>
  Yup.object({
    perDollarEmployerMatchRate: Yup.string().notRequired(),
    maxPercentEmployerMatch: Yup.string().notRequired(),
    maxDollarEmployerMatch: Yup.string().notRequired(),
    otherEmployerContrib: Yup.string().notRequired(),
  });

export const RetirementPlanEmployerContributionsEditViewWrapper: React.FC<EditViewProps> = ({
  editing,
  onBack,
}) => {
  const { loading, currentStep, wizard, params } =
    Hooks.useRetirementWizard<Data.InvestmentAccounts.EmployerContributionsFormData>();
  const {
    basic: { name },
  } = Hooks.useRetirementWizardData();
  const isStarted = useObservable(wizard.isStarted$);
  const { id, type: planType } = useParams();
  const type =
    planType && Object.keys(Domain.accountTypeKeyToPlanTypeId).includes(planType)
      ? Domain.accountTypeKeyToPlanTypeId[
          planType as keyof typeof Domain.accountTypeKeyToPlanTypeId
        ]
      : params?.type ?? Domain.PlanTypeEnum['#457'];

  useEffect(() => {
    // ensure type is initialized
    if (
      typeof isStarted !== 'undefined' &&
      !isStarted &&
      type &&
      Domain.PlanTypeEnumMap.get(type)
    ) {
      wizard.start({ id: parseInt(id ?? ''), type });
    } else if (isStarted) {
      wizard.selectStep('employerContributions');
    }
  }, [isStarted, type]);

  return (
    <ObservableGuard
      predicate$={wizard.current$.pipe(
        // guard against incorrect step to prevent incorrect default values when navigating from another step
        map((current) => current?.key === 'employerContributions')
      )}
      loadingFn={() => (
        <Backdrop open>
          <Loading />
        </Backdrop>
      )}>
      {currentStep?.key === 'employerContributions' && // don't even mount until correct step is set
        params &&
        name && ( // load params before mounting to prevent flickering
          <FormLoader loading={loading}>
            <RetirementPlanEmployerContributionsEditView
              currentStep={currentStep}
              wizard={wizard}
              editing={editing}
              onBack={onBack}
              params={params}
              name={name}
            />
          </FormLoader>
        )}
    </ObservableGuard>
  );
};

interface RetirementPlanEmployerContributionsEditViewProps extends EditViewProps {
  currentStep: WizardStep<
    Data.InvestmentAccounts.EmployerContributionsFormData,
    Data.InvestmentAccounts.InvestmentAccountWizardParams
  >;
  wizard: Data.InvestmentAccounts.RetirementWizard;
  params: Data.InvestmentAccounts.InvestmentAccountWizardParams;
}

const RetirementPlanEmployerContributionsEditView: React.FC<
  RetirementPlanEmployerContributionsEditViewProps
> = ({ currentStep, wizard, params, name, editing, onBack }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const ready = useObservableGuardCondition();
  const methods = useForm<Data.InvestmentAccounts.EmployerContributionsFormData>({
    defaultValues: { ...currentStep.model },
    resolver: yupResolver(accountContributionsSchema()),
  });
  const { setLoading } = useLoading();

  const formatInputs = (values: any) => {
    const perDollarEmployerMatchRate = cleanWholeNumberStr(values['perDollarEmployerMatchRate']);
    const maxPercentEmployerMatch = cleanWholeNumberStr(values['maxPercentEmployerMatch']);
    const maxDollarEmployerMatch = cleanWholeNumberStr(values['maxDollarEmployerMatch']);
    const otherEmployerContrib = cleanWholeNumberStr(values['otherEmployerContrib']);
    let contributionTiers = values['contributionTiers'] as Domain.ContributionTier[];
    if (contributionTiers?.length) {
      contributionTiers = contributionTiers.map((tier) => {
        const from = cleanWholeNumberStr(tier.from?.toString() ?? '');
        const to = cleanWholeNumberStr(tier.to?.toString() ?? '');
        const percentContribution = cleanWholeNumberStr(tier.percentContribution?.toString() ?? '');
        return { from, to, percentContribution } as Domain.ContributionTier;
      });
    }
    return {
      perDollarEmployerMatchRate,
      maxPercentEmployerMatch,
      maxDollarEmployerMatch,
      otherEmployerContrib,
      contributionTiers,
    };
  };

  const onSubmit = async (values: any) => {
    setLoading(true);

    try {
      const cleanedValues = formatInputs(values);
      currentStep.save({ ...values, ...cleanedValues });
      await wizard.commitStep('employerContributions');

      if (editing && onBack) {
        onBack();
        return;
      }

      const wizardPlanType =
        Domain.planTypeIdToAccountKey[params.type as keyof typeof Domain.planTypeIdToAccountKey];

      navigate(
        `/account-details/retirement-savings-plan/investments/${wizardPlanType}/${params.id}`
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {ready && (
        <FormContent formProviderProps={methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Box>
              {/* Should be about 30px */}
              <HelpTitle
                text={t('EmployerMatchOtherContributions')}
                helpContext={
                  <HelpPopover title={t('EmployerMatch')}>
                    <BulletedList
                      children={[
                        t('UsedForProjections'),
                        t('SomeEmployersMatch'),
                        t('SomeEmployersContributeEvenIfYouDont'),
                        t('WeAlreadyIncludedYourEmployersSocialSecurity'),
                      ]}
                    />
                  </HelpPopover>
                }></HelpTitle>
              <Typography variant='p18Bold' color='secondary' mt={Spacing.xxxs}>
                {name}
              </Typography>

              <Grid container justifyContent='center' mt={2}>
                <Grid item sm={editing ? 10 : 6}>
                  {(currentStep.model.contributionTiers?.length ?? 0) > 1 ? (
                    <RetirementPlanMultiTierEmployerContributionsForm
                      contributions={currentStep.model}
                      params={params}
                      onSubmit={onSubmit}
                    />
                  ) : (
                    <RetirementPlanEmployerContributionsForm
                      contributions={currentStep.model}
                      params={params}
                      onSubmit={onSubmit}
                    />
                  )}
                </Grid>
              </Grid>
            </Box>
            <WizardFooter
              color={editing ? 'primary' : undefined}
              disableBack={false}
              onBack={onBack}
              {...getWizardFooterProps('Next', editing)}
            />
          </form>
        </FormContent>
      )}
    </>
  );
};

const meta = {
  nextLocaleKey: 'Next',
  hexSteps: {
    steps: 4,
    currentStep: 3,
  },
  headerVariant: 'hex',
  planTypeLabel: 'RetirementSavingsPlan',
} satisfies LayoutMeta<AccountDetailsLayoutMeta>;

export default withLayoutMeta(RetirementPlanEmployerContributionsEditViewWrapper, meta);
