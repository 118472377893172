/* eslint-disable @typescript-eslint/no-explicit-any */
import { Card, CardContent, Typography, Button } from '@mui/material';
import i18next, { t } from 'i18next';
import React, { CSSProperties, useState, useMemo, useEffect, useRef } from 'react';
import { Trans } from 'react-i18next';
import { Svgs } from '../../assets/svg';
import { Column, Spacer, Row } from '../../components';
import BulletedList from '../../components/BulletedList';
import { formatDecimalDollars, formatWholeDollars } from '../../helpers/utilityFunctions';
import { useDeviceType } from '../../hooks/useDeviceType';
import { LicenseData } from '../../models/LicenseData';
import { Margins } from '../../themes';
import LearnMoreAdviceAndThreeNickelsUDrawer from '../LearnMoreAdviceAndThreeNickelsUDrawer';
import LearnMoreAdviceDrawer from '../LearnMoreAdviceDrawer';
import LearnMoreGuidedChoiceDrawer from '../LearnMoreGuidedChoiceDrawer';
import { Domain } from '@3nickels/data-modules';
import Cookies from 'js-cookie';

interface ProductOptionsProps {
  memberPaidOptions: Domain.PricebookBundle[];
  orgPaidOptions: Domain.PricebookBundle[];
  pricebookMetadata: any;
  selectedPricebook?: Domain.PricebookBundle;
  values?: LicenseData;
  setSelectedPricebook?: React.Dispatch<React.SetStateAction<Domain.PricebookBundle | undefined>>;
  onSubmit?: (values: LicenseData) => Promise<void>;
  customSubmitButton?: {
    label: string;
    url: string;
    style?: CSSProperties;
  };
}

export const ProductOptions: React.FC<ProductOptionsProps> = ({
  memberPaidOptions,
  orgPaidOptions,
  pricebookMetadata,
  selectedPricebook,
  values,
  setSelectedPricebook,
  onSubmit,
  customSubmitButton,
}) => {
  const { isTablet, isMobile } = useDeviceType();

  return (
    <>
      {memberPaidOptions.length > 0 &&
        (isTablet || isMobile ? (
          <Column style={{ justifyContent: 'space-between' }}>
            {memberPaidOptions.map((bundle) => (
              <React.Fragment key={bundle.pricebook.id}>
                <BundleCard
                  key={bundle.pricebook.id}
                  pricebookBundle={bundle}
                  pricebookMetadata={pricebookMetadata}
                  values={values}
                  selected={bundle === selectedPricebook}
                  setSelectedPricebook={setSelectedPricebook}
                  onSubmit={onSubmit}
                  customSubmitButton={customSubmitButton}
                />
                <Spacer height='sm' />
              </React.Fragment>
            ))}
          </Column>
        ) : (
          <Row style={{ flexWrap: 'wrap' }}>
            {memberPaidOptions.map((bundle, index) => (
              <BundleCard
                style={{
                  marginBottom: '20px',
                  marginRight:
                    isTablet || isMobile || index + 1 >= memberPaidOptions.length ? '' : '100px',
                }}
                key={bundle.pricebook.id}
                pricebookBundle={bundle}
                pricebookMetadata={pricebookMetadata}
                selected={bundle === selectedPricebook}
                setSelectedPricebook={setSelectedPricebook}
              />
            ))}
          </Row>
        ))}
      {orgPaidOptions.length > 0 &&
        (isTablet || isMobile ? (
          <Column style={{ justifyContent: 'space-between' }}>
            {orgPaidOptions.map((bundle) => (
              <React.Fragment key={bundle.pricebook.id}>
                <BundleCard
                  pricebookBundle={bundle}
                  pricebookMetadata={pricebookMetadata}
                  values={values}
                  selected={bundle === selectedPricebook}
                  setSelectedPricebook={setSelectedPricebook}
                  onSubmit={onSubmit}
                  customSubmitButton={customSubmitButton}
                />
                <Spacer height='sm' />
              </React.Fragment>
            ))}
          </Column>
        ) : (
          <Row style={{ justifyContent: 'space-between', flexWrap: 'wrap' }}>
            {orgPaidOptions.map((bundle, index) => (
              <React.Fragment key={bundle.pricebook.id}>
                <BundleCard
                  style={{
                    marginBottom: '20px',
                    marginRight:
                      isTablet || isMobile || index + 1 >= memberPaidOptions.length ? '' : '100px',
                  }}
                  pricebookBundle={bundle}
                  pricebookMetadata={pricebookMetadata}
                  selected={bundle === selectedPricebook}
                  setSelectedPricebook={setSelectedPricebook}
                />
                <Spacer height='sm' />
              </React.Fragment>
            ))}
          </Row>
        ))}
    </>
  );
};

interface BundleCardProps {
  pricebookBundle: Domain.PricebookBundle;
  pricebookMetadata: any;
  selected: boolean;
  current?: boolean;
  values?: LicenseData;
  setSelectedPricebook?: React.Dispatch<React.SetStateAction<Domain.PricebookBundle | undefined>>;
  onSubmit?: (values: LicenseData) => Promise<void>;
  style?: React.CSSProperties;
  customSubmitButton?: {
    label: string;
    url: string;
    style?: CSSProperties;
  };
}

export const BundleCard: React.FC<BundleCardProps> = ({
  pricebookBundle,
  pricebookMetadata,
  selected,
  current,
  values,
  setSelectedPricebook,
  onSubmit,
  style,
  customSubmitButton,
}) => {
  const { language } = i18next;
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { isMobile, isTablet } = useDeviceType();
  const selectedStyle = selected && setSelectedPricebook;
  const pricebook = pricebookBundle.prices[0];
  const freeTrial30Day = pricebookBundle.pricebook.metadata?.freeTrial30Day === 'true';
  const orgPays = pricebookBundle.pricebook.paidBy === 'ORG';
  const subHeader = pricebookBundle.pricebook.metadata?.subHeader;
  const price = pricebook.price / 100.0;
  const decimalDollarsPrice = price.toString().endsWith('.99');
  const formRef = useRef<HTMLFormElement>(null);

  const metadata = useMemo(() => {
    if (
      typeof pricebookMetadata === 'undefined' ||
      Object.keys(pricebookMetadata ?? {}).length === 0
    )
      return;
    return pricebookMetadata.find((metadata: any) => metadata.id === pricebookBundle.pricebook.id);
  }, [pricebookBundle, pricebookMetadata]);

  useEffect(() => {
    if (setSelectedPricebook && onSubmit && selected && values) {
      onSubmit(values);
    }
  }, [selected]);

  return (
    <>
      <Card
        style={{
          width: isTablet || isMobile ? '100%' : '45%',
          maxWidth: isTablet || isMobile ? '' : '538px',
          minWidth: isTablet || isMobile ? undefined : '430px',
          ...style,
        }}
        variant={selectedStyle && !isMobile && !isTablet ? 'selected' : undefined}
        color='primary'>
        {pricebookBundle.pricebook.productType === 'NICKELS' && (
          <LearnMoreAdviceDrawer
            drawerOpen={drawerOpen}
            setDrawerOpen={setDrawerOpen}
            pricebookBundle={pricebookBundle}
            pricebookMetadata={metadata}
          />
        )}
        {pricebookBundle.pricebook.productType === 'NICKELSU' && (
          <LearnMoreAdviceAndThreeNickelsUDrawer
            drawerOpen={drawerOpen}
            setDrawerOpen={setDrawerOpen}
            pricebookBundle={pricebookBundle}
            pricebookMetadata={metadata}
          />
        )}
        {pricebookBundle.pricebook.productType === 'GUIDED_CHOICE' && (
          <LearnMoreGuidedChoiceDrawer
            drawerOpen={drawerOpen}
            setDrawerOpen={setDrawerOpen}
            pricebookBundle={pricebookBundle}
            pricebookMetadata={metadata}
          />
        )}
        <CardContent className='packageCard' style={{ height: '100%' }}>
          {isTablet || isMobile ? (
            <Column style={{ alignItems: 'center' }}>
              <img src={pricebookBundle.pricebook.logoUrl} alt='Product Logo' height='50px' />
              <Spacer height='sm' />
              <img
                src={pricebookBundle.pricebook.artUrl}
                alt='Product Art'
                style={{ height: '90px' }}
              />
              <Spacer height='xs' />
              <Typography variant='p20SemiBold' color='secondary'>
                {pricebookBundle.pricebook.pricebookName}
              </Typography>
              {subHeader && (
                <>
                  <Spacer height='xxs' />
                  <Typography variant='p14Bold' color='primary'>
                    {t(subHeader)}
                  </Typography>
                </>
              )}
              <Spacer height='xxs' />
              <Column style={{ justifyContent: 'space-between', width: '100%' }}>
                <BulletedList
                  children={pricebookBundle.pricebook.description}
                  style={{ ...Margins.mt_xxxs }}
                  variant='body1'
                  color='secondary'
                />
              </Column>
              <Spacer height='xs' />
              <Card variant='innerCard' style={{ width: '100%' }}>
                <CardContent className='centered-inner'>
                  <Column style={{ alignItems: 'center' }}>
                    {metadata ? (
                      <Typography variant='p16Bold' color='primary' textAlign='center'>
                        {t(metadata.innerCard)}
                      </Typography>
                    ) : orgPays ? (
                      <Typography variant='p16Bold' color='primary'>
                        <Trans i18nKey='PaidByYourBROrganization' />
                      </Typography>
                    ) : (
                      <>
                        <Row
                          key={pricebook.id}
                          style={{ alignItems: 'baseline', justifyContent: 'center' }}>
                          <Typography variant='p20SemiBold' color='secondary'>
                            {freeTrial30Day
                              ? t('30DaysFree')
                              : `${
                                  decimalDollarsPrice
                                    ? formatDecimalDollars(price, language)
                                    : formatWholeDollars(price, language)
                                }`}
                            {pricebook.intervalType !== 'NONE' && !freeTrial30Day && (
                              <Typography variant='h3'>/{t(pricebook.intervalType)}</Typography>
                            )}
                          </Typography>
                        </Row>
                        <Spacer height='xxxs' />
                        <Typography variant='p12SemiBold' color='primary'>
                          {freeTrial30Day
                            ? t('thenPricePeriod', {
                                price: formatDecimalDollars(14.99, language),
                                period: t('mo'),
                              })
                            : price === 0
                            ? t('free')
                            : pricebook.intervalType === 'MONTH'
                            ? t('autoRenewsMonthly')
                            : pricebook.intervalType === 'YEAR'
                            ? t('autoRenewsAnnually')
                            : t('oneTimePayment')}
                        </Typography>
                      </>
                    )}
                  </Column>
                </CardContent>
              </Card>
              {customSubmitButton ? (
                <form
                  ref={formRef}
                  method='post'
                  action={`${customSubmitButton.url}${pricebookBundle.pricebook.id}`}>
                  <Button
                    onClick={() => formRef.current?.submit()}
                    style={{ ...customSubmitButton.style }}
                    className='md'
                    color='secondary'>
                    {customSubmitButton.label}
                  </Button>
                  <input type='hidden' name='_csrf' value={Cookies.get('XSRF-TOKEN')} readOnly />
                </form>
              ) : setSelectedPricebook && onSubmit && values ? (
                <>
                  <Spacer height='xs' />
                  <Button
                    disabled={current}
                    onClick={() => {
                      setSelectedPricebook(pricebookBundle);
                    }}
                    style={{ width: '100%' }}
                    color='secondary'>
                    {t('Choose&SignUp')}
                  </Button>
                </>
              ) : null}
              <Spacer height='xs' />
              <Button
                onClick={() => setDrawerOpen(true)}
                style={{ width: '100%' }}
                color='primary'
                className='learnMore'>
                {t('LearnMore')}
              </Button>
            </Column>
          ) : (
            <Row style={{ justifyContent: 'space-between', height: '100%' }}>
              {/* Left side */}
              <Column style={{ width: '45%', justifyContent: 'space-between' }}>
                <Column>
                  <Typography variant='p20SemiBold' color={selectedStyle ? `white` : `secondary`}>
                    {pricebookBundle.pricebook.pricebookName}
                  </Typography>
                  {subHeader && (
                    <>
                      <Spacer height='xxs' />
                      <Typography variant='p14Bold' color='primary'>
                        {t(subHeader)}
                      </Typography>
                    </>
                  )}
                  <Spacer height='s15' />
                  <BulletedList
                    children={pricebookBundle.pricebook.description}
                    variant='p14'
                    color={selectedStyle ? `white` : `secondary`}
                  />
                </Column>
                <Spacer height='xs' />
                <Column>
                  {setSelectedPricebook && (
                    <Button
                      disabled={current}
                      onClick={() => {
                        if (setSelectedPricebook) {
                          setSelectedPricebook((prevState) =>
                            prevState !== pricebookBundle ? pricebookBundle : undefined
                          );
                        }
                      }}
                      color={'primary'}
                      variant={selected ? `outlined` : undefined}>
                      {selected ? t('Selected') : t('Select')}
                    </Button>
                  )}
                  <Spacer height='xxxs' />
                  <Button
                    onClick={() => setDrawerOpen(true)}
                    color='primary'
                    className={selectedStyle ? 'activeLearnMore' : 'learnMore'}>
                    {t('LearnMore')}
                  </Button>
                </Column>
              </Column>
              {/* Right side */}
              <Column
                style={{ width: '50%', alignItems: 'center', justifyContent: 'space-between' }}>
                {selectedStyle ? (
                  <Svgs.DisplayLargeCustomCheckmark
                    style={{ width: '112px', height: 'auto', flexGrow: 1 }}
                  />
                ) : (
                  <>
                    <img
                      src={pricebookBundle.pricebook.logoUrl}
                      alt='Product Logo'
                      height='auto'
                      width='100%'
                    />
                    <img src={pricebookBundle.pricebook.artUrl} alt='Product Art' height='90' />
                  </>
                )}
                <Card
                  variant={selectedStyle ? 'selected' : 'innerCard'}
                  style={{
                    width: '70%',
                    minWidth: 'fit-content',
                  }}>
                  <CardContent className='centered-inner'>
                    <Column style={{ alignItems: 'center' }}>
                      {metadata ? (
                        <Typography variant='p16Bold' color='primary' textAlign='center'>
                          {t(metadata.innerCard)}
                        </Typography>
                      ) : orgPays ? (
                        <Typography variant='p16Bold' color='primary'>
                          <Trans i18nKey='PaidByYourBROrganization' />
                        </Typography>
                      ) : (
                        <>
                          <Row
                            key={pricebook.id}
                            style={{ alignItems: 'baseline', justifyContent: 'center' }}>
                            <Typography
                              variant='p20SemiBold'
                              color={selectedStyle ? `white` : `secondary`}>
                              {freeTrial30Day
                                ? t('30DaysFree')
                                : `${
                                    decimalDollarsPrice
                                      ? formatDecimalDollars(price, language)
                                      : formatWholeDollars(price, language)
                                  }`}
                              {pricebook.intervalType !== 'NONE' && !freeTrial30Day && (
                                <Typography variant='h3'>/{t(pricebook.intervalType)}</Typography>
                              )}
                            </Typography>
                          </Row>
                          <Spacer height='xxxs' />
                          <Typography
                            variant='p12SemiBold'
                            color={selectedStyle ? `white` : `primary`}
                            textAlign='center'>
                            {freeTrial30Day
                              ? t('thenPricePeriod', {
                                  price: formatDecimalDollars(14.99, language),
                                  period: t('mo'),
                                })
                              : price === 0
                              ? t('free')
                              : pricebook.intervalType === 'MONTH'
                              ? t('autoRenewsMonthly')
                              : pricebook.intervalType === 'YEAR'
                              ? t('autoRenewsAnnually')
                              : t('oneTimePayment')}
                          </Typography>
                        </>
                      )}
                    </Column>
                  </CardContent>
                </Card>
              </Column>
            </Row>
          )}
        </CardContent>
      </Card>
    </>
  );
};
