import { Box, MenuItem, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import TextInput from '../../../../../components/form/TextInput';
import ToggleRadioButtonGroup from '../../../../../components/form/ToggleRadioButtonGroup';
import { Spacing } from '../../../../../themes';
import { useFormContext } from 'react-hook-form';
import { Data, Domain } from '@3nickels/data-modules';
import SelectInput from '../../../../../components/form/SelectInput';
import { t } from 'i18next';
import HelpPopover, { HelpPopoverText } from '../../../../../components/HelpPopover';
import { Trans } from 'react-i18next';

export type RealEstateDetailsFormProps = {
  realEstateDetails?: Data.RealEstate.HousingEntityDetailsFormData;
  onSubmit: (values: Data.RealEstate.HousingEntityDetailsFormData) => Promise<void>;
};

const RealEstateDetailsForm: React.FC<RealEstateDetailsFormProps> = (props) => {
  const { formState, setValue } = useFormContext();
  const [hasLoan, setHasLoan] = useState<boolean | undefined>(undefined);

  const setLoanHandler = (value: boolean) => {
    setHasLoan(value);
    if (value !== undefined && value !== null) {
      setValue('hasLoan', value ? 'true' : 'false');
    }
  };

  useEffect(() => {
    if (
      props.realEstateDetails?.hasLoan !== undefined &&
      props.realEstateDetails?.hasLoan !== null
    ) {
      setLoanHandler(props.realEstateDetails?.hasLoan);
    }
  }, [props.realEstateDetails?.hasLoan]);

  return (
    <Box>
      <Stack spacing={Spacing.xxs}>
        <ToggleRadioButtonGroup<Data.RealEstate.HousingEntityDetailsFormData>
          error={formState.errors.hasLoan !== undefined}
          helperText={formState.errors.hasLoan?.message?.toString()}
          label='DoYouHaveAMortgageRealEstate'
          name='hasLoan'
          row
          autoFocus
          onChange={(target) => {
            setLoanHandler(target.value === 'true');
          }}
          defaultValue={props.realEstateDetails?.hasLoan?.toString()}
          items={[
            { label: 'Yes', value: 'true' },
            { label: 'No', value: 'false' },
          ]}
        />
        {hasLoan && (
          <>
            <TextInput<Data.RealEstate.HousingEntityDetailsFormData>
              error={formState.errors.amountOwed !== undefined}
              helperText={formState.errors.amountOwed?.message?.toString()}
              defaultValue={props.realEstateDetails?.amountOwed}
              label='AmountIOwe'
              name='amountOwed'
              type='dollar'
              placeholder='$0'
            />
            <TextInput<Data.RealEstate.HousingEntityDetailsFormData>
              error={formState.errors.interestRate !== undefined}
              helperText={formState.errors.interestRate?.message?.toString()}
              defaultValue={props.realEstateDetails?.interestRate}
              label='InterestRate'
              name='interestRate'
              type='percent'
              precision={3}
              placeholder='0%'
            />
            <SelectInput<Data.RealEstate.HousingEntityDetailsFormData>
              error={formState.errors.mortgageTerm !== undefined}
              helperText={formState.errors.mortgageTerm?.message?.toString()}
              defaultValue={
                props.realEstateDetails?.mortgageTerm ??
                Domain.MortgageTermTypeEnum['30-Year Fixed']
              }
              label='MortgageTerm'
              name='mortgageTerm'>
              {Domain.mortgageTermTypes.map((x) => (
                <MenuItem value={x.key}>{t(x.value)}</MenuItem>
              ))}
            </SelectInput>
            <TextInput<Data.RealEstate.HousingEntityDetailsFormData>
              error={formState.errors.minimumPayment !== undefined}
              helperText={formState.errors.minimumPayment?.message?.toString()}
              defaultValue={props.realEstateDetails?.minimumPayment}
              label='MonthlyMortgagePayment'
              name='minimumPayment'
              type='dollar'
              placeholder='$0'
              helpContext={
                <HelpPopover title={t('MonthlyMortgagePayment')}>
                  <HelpPopoverText style={{ display: 'inline-block' }}>
                    <Trans
                      i18nKey='IncludeOnlyTheAmountPaidTowardPrincipalAndInterestStyled'
                      components={{
                        Styled: <span style={{ fontWeight: 'bold' }} />,
                      }}
                    />
                  </HelpPopoverText>
                </HelpPopover>
              }
            />
          </>
        )}
      </Stack>
    </Box>
  );
};

export default RealEstateDetailsForm;
