import { Box, Grid, IconButton, Stack, Typography } from '@mui/material';
import { ReactNode, useMemo } from 'react';
import { EmbeddedListItem } from '../themes/mui';
import { Colors } from '../themes';
import { Svgs } from '../assets/svg';

export type HeaderAdornmentProps = {
  startAdornment?: () => ReactNode | string;
  endAdornment?: () => ReactNode | string;
  startClassName?: string;
  endClassName?: string;
};
export interface EmbeddedListProps<T> {
  header?: ReactNode | string;
  emptyHeader?: ReactNode | string;
  keyFn: (x: T) => string;
  titleFn: (x: T) => ReactNode | string;
  headingProps?: HeaderAdornmentProps;
  summaryFn: (x: T) => ReactNode | string;
  items?: T[];
  onClick?: () => void;
  onClickElement?: (i: T) => void;
  onDelete?: (i: T) => void;
  disableDelete?: (i: T) => boolean;
}

export default function EmbeddedList<T>(props: EmbeddedListProps<T>) {
  const {
    items,
    keyFn,
    titleFn,
    headingProps,
    header,
    emptyHeader,
    summaryFn,
    onClick,
    onClickElement,
    onDelete,
    disableDelete = () => false,
  } = props;
  const count = items?.length ?? 0;
  const showDeleteIcon = typeof onDelete !== 'undefined';
  const interactiveElement = showDeleteIcon || onClickElement;
  const headerComponent = useMemo(() => {
    if (count > 0) {
      if (typeof header === 'string') {
        return (
          <Typography variant='p16Bold' color='primary'>
            {`${header}  (${count})`}
          </Typography>
        );
      } else {
        return header;
      }
    } else {
      if (typeof emptyHeader === 'string') {
        return (
          <Typography variant='p16Bold' color='primary'>
            {emptyHeader}
          </Typography>
        );
      } else {
        return emptyHeader;
      }
    }
  }, [count, header, emptyHeader]);

  return (
    <Grid className='embedded-list' container>
      <Stack className='embedded-list-header' direction='row' spacing={2} onClick={onClick}>
        {headingProps && headingProps.startAdornment && (
          <Box className={headingProps.startClassName} display='grid' alignItems='center'>
            {headingProps.startAdornment()}
          </Box>
        )}
        {headerComponent}
        {headingProps && headingProps.endAdornment && (
          <Box
            className={headingProps.endClassName ?? 'show-on-hover'}
            display='grid'
            alignItems='center'>
            {headingProps.endAdornment()}
          </Box>
        )}
      </Stack>

      {items?.map((i) => {
        return (
          <Grid
            className='embedded-list-content'
            container
            mt='10px'
            key={keyFn(i)}
            sx={[
              EmbeddedListItem,
              {
                '&:hover': {
                  backgroundColor: interactiveElement
                    ? Colors.primaryLightest
                    : Colors.secondaryLighter,
                },
              },
            ]}
            justifyContent='space-between'>
            <Grid
              container
              onClick={onClickElement ? () => onClickElement(i) : undefined}
              sx={{
                'paddingLeft': '0px',
                '& :hover': { cursor: onClickElement ? 'pointer' : undefined },
              }}>
              <Grid
                className='embedded-list-content-title-container'
                item
                pl={showDeleteIcon ? 1 : '38px'}
                display='flex'
                width='100%'
                justifyContent='space-between'
                flexGrow={1}>
                <Grid display='flex' item alignItems='center' width='100%'>
                  {showDeleteIcon && (
                    <>
                      {!disableDelete(i) ? (
                        <IconButton
                          size='small'
                          style={{ paddingTop: '0px', paddingBottom: '0px' }}>
                          <Svgs.IpmIconDelete
                            onClick={(e) => {
                              e.stopPropagation();
                              onDelete(i);
                            }}
                          />
                        </IconButton>
                      ) : (
                        <Svgs.IpmIconDelete opacity='0' style={{ marginRight: '8px' }} />
                      )}
                    </>
                  )}
                  <Typography
                    className='embedded-list-content-title'
                    variant='p16Bold'
                    display='inline-block'
                    maxWidth='80%'
                    alignSelf='center'
                    justifyContent='flex-start'
                    overflow='hidden'
                    whiteSpace='nowrap'
                    textOverflow='ellipsis'
                    color={interactiveElement ? Colors.secondaryBase : Colors.disabledDark}>
                    {titleFn(i)}
                  </Typography>
                </Grid>
                <Typography
                  className='embedded-list-content-summary'
                  variant='p16'
                  color={interactiveElement ? Colors.secondaryBase : Colors.disabledDark}>
                  {summaryFn(i)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
}
