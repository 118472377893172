import { Grid, Typography } from '@mui/material';
import { t } from 'i18next';
import { Spacing } from '../../../../../themes';
import React from 'react';
import { Svgs } from '../../../../../assets/svg';

const KeyTerms: React.FC = () => {
  return (
    <Grid display='flex'>
      <Grid display='flex' flexDirection='column' width='50%'>
        <Typography variant='p30Bold' color='secondary'>
          {t('KeyTerms')}
        </Typography>
        <KeyTerm number={1} term={'Deductible'} description={'DeductibleDesc'} />
        <KeyTerm number={2} term={'OopMax'} description={'OopMaxDesc'} />
        <KeyTerm number={3} term={'Premium'} description={'PremiumDesc'} />
        <KeyTerm number={4} term={'Copayment'} description={'CopaymentDesc'} />
        <KeyTerm
          number={5}
          term={'Coinsurance'}
          description={'CoinsuranceDesc'}
          style={{ marginBottom: Spacing.xxs }}
        />
        <KeyTerm number={6} term={'Network'} description={'NetworkDesc'} />
        <KeyTerm number={7} term={'InNetwork'} description={'InNetworkDesc'} />
        <KeyTerm number={8} term={'Out-of-Network'} description={'OutNetworkDesc'} />
        <KeyTerm number={9} term={'Referral'} description={'ReferralDesc'} />
        <KeyTerm
          number={10}
          term={'Pcp'}
          description={'PcpDesc'}
          style={{ marginBottom: Spacing.xxs }}
        />
        <KeyTerm number={11} term={'Epo'} description={'EpoDesc'} />
        <KeyTerm number={12} term={'Ppo'} description={'PpoDesc'} />
        <KeyTerm number={13} term={'Hmo'} description={'HmoDesc'} />
        <KeyTerm number={14} term={'Pos'} description={'PosDesc'} />
        <KeyTerm number={15} term={'Fsa'} description={'FsaDesc'} />
        <KeyTerm number={16} term={'Hdhp'} description={'HdhpDesc'} />
        <KeyTerm number={17} term={'Hsa'} description={'HsaDesc'} />
      </Grid>

      <Grid ml={5} mt={30} width='45%'>
        <Svgs.ArtMedicalSupplies style={{ position: 'sticky', top: '50px', width: '100%' }} />
      </Grid>
    </Grid>
  );
};

interface KeyTermProps {
  number: number;
  term: string;
  description: string;
  style?: React.CSSProperties;
}
const KeyTerm: React.FC<KeyTermProps> = ({ number, term, description, style }) => {
  return (
    <Grid item sx={style}>
      <Typography mt={3} variant='p16Bold' color='primary'>
        {number}. {t(term)}
      </Typography>
      <Typography mt={1} variant='p16' color='secondary'>
        {t(description)}
      </Typography>
    </Grid>
  );
};

export default KeyTerms;
