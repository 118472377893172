import React from 'react';
import HelpPopover, { HelpPopoverText } from '../../../../components/HelpPopover';
import { Trans, useTranslation } from 'react-i18next';
import { Margins } from '../../../../themes';
import BulletedText from '../../../../components/BulletedText';
import { Stack } from '@mui/material';

const ClosingCostsHelp: React.FC = () => {
  const { t } = useTranslation();

  return (
    <HelpPopover title={t('Closingcosts')}>
      <Trans
        i18nKey='ClosingCostsHelpContent'
        components={{
          HelpPopoverText: <HelpPopoverText style={Margins.mb_sm} />,
          Stack: <Stack mr={2} ml={2} />,
          BulletedText: <BulletedText />,
        }}
      />
    </HelpPopover>
  );
};

export default ClosingCostsHelp;
