/* eslint-disable @typescript-eslint/no-explicit-any */
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import FormContent from '../../../components/form/FormContent';
import { Api, Data, Domain, Hooks, formatWholeDollars } from '@3nickels/data-modules';
import { Divider, Grid, Typography } from '@mui/material';
import { useMutation } from '@aesop-fables/scrinium';
import { BudgetSectionProps, IncomeFormData, getAdjustedNetRealEstateIncome } from '../Types';
import { IncomeForm, IncomeFormProps } from './BudgetIncomeForm';
import EmbeddedList from '../../../components/EmbeddedList';
import { CollapsiblePanel } from '../../../components/CollapsiblePanel';
import { Svgs } from '../../../assets/svg';
import { Colors } from '../../../themes';
import { LeaveBudgetDetailsModal } from '../BudgetDetailsLayout';
import { useNavigate } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import RemoveAccountModal from '../../account-details/RemoveAccountModal';
import { useMessage } from '../../../hooks/useMessage';
import * as Yup from 'yup';
import { cleanWholeNumberStr } from '../../../helpers/utilityFunctions';

const incomeSchema = () =>
  Yup.object({
    annualIncome: Yup.string()
      .required(t('Required') as string)
      .test('annualIncome', t('AnnualIncomeMustBeGreaterThan0') as string, (income) => {
        const cleanNumber = cleanWholeNumberStr(income);
        return cleanNumber > 0;
      }),
    otherAnnualIncome: Yup.string().notRequired(),
    unearnedIncome: Yup.string().notRequired(),
    spouseAnnualIncome: Yup.string().notRequired(),
    spouseAnnualOtherIncome: Yup.string().notRequired(),
  });

const IncomeSectionWrapper: React.FC<BudgetSectionProps> = ({ budget, mode }) => {
  const financialInfo = Hooks.useFinancialInfo();
  const includeSpouse = Hooks.useIncludeSpouse();

  const defaultValues: IncomeFormData = useMemo(() => {
    return {
      annualIncome: Data.Budget.adjustAnnualAmount(mode, financialInfo?.income),
      otherAnnualIncome: Data.Budget.adjustAnnualAmount(mode, financialInfo?.otherIncome),
      unearnedIncome: Data.Budget.adjustAnnualAmount(mode, financialInfo?.unearnedIncome),
      spouseAnnualIncome: includeSpouse
        ? Data.Budget.adjustAnnualAmount(mode, financialInfo?.spouseIncome)
        : undefined,
      spouseAnnualOtherIncome: includeSpouse
        ? Data.Budget.adjustAnnualAmount(mode, financialInfo?.spouseOtherIncome)
        : undefined,
    };
  }, [includeSpouse, financialInfo, mode]);

  return <IncomeSection defaultValues={defaultValues} mode={mode} budget={budget} />;
};

export const IncomeSection: React.FC<
  Omit<IncomeFormProps, 'incomeType'> & BudgetSectionProps & { budget?: Api.BudgetRest }
> = ({ budget, defaultValues, mode }) => {
  const { showMessage } = useMessage();
  const navigate = useNavigate();
  const { wizard } = Hooks.useRealEstateWizard();
  const deleteRealEstate = useMutation(new Data.RealEstate.Mutations.DeleteHousingEntity());
  const methods = useForm<IncomeFormData>({
    resolver: yupResolver(incomeSchema()),
    defaultValues,
  });
  const housing = Hooks.useCombinedRealEstateData();
  const taxes = Hooks.useEstimatedTaxes(mode);
  const incomeSummary = useMemo(
    () => Data.Budget.adjustAnnualAmount(mode, budget?.afterTaxHouseholdIncome),
    [budget?.afterTaxHouseholdIncome, mode]
  );

  const [open, setOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState<Domain.RealEstateData>();

  const leaveBudgetDetails = () => {
    navigate('/account-details');
  };

  const handleEditRealEstate = async (x: Domain.RealEstateData) => {
    await wizard.start({ id: x.id ?? 0, type: Domain.RealEstateTypeEnum.INVESTMENT });
    navigate(`/account-details/investment-real-estate/summary/${x.id}`);
  };

  const handleDeleteRealEstate = (account: Domain.RealEstateData) => {
    deleteRealEstate.action(account.id ?? 0);
    showMessage(t('RealEstateRemoved').toString());
  };

  return (
    <CollapsiblePanel
      header={t('INCOME')}
      footnote={`(${t('AfterTax')})`}
      icon={<Svgs.DisplaySmallCustomWallet />}
      summary={formatWholeDollars(incomeSummary)}>
      <LeaveBudgetDetailsModal open={open} setOpen={setOpen} handleSave={leaveBudgetDetails} />
      <RemoveAccountModal
        name={selectedAccount?.name}
        type='InvestmentRealEstate'
        variant='Investment'
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        handleSave={() => {
          if (selectedAccount) {
            handleDeleteRealEstate(selectedAccount);
          }
        }}
      />
      <Grid className='embedded-content'>
        <Typography variant='p18Bold' color='secondary' marginY='10px'>
          <Trans
            i18nKey='EarnedIncomeBeforeTax'
            components={{
              Styled: (
                <Typography variant='p12' color='primary' fontWeight='regular' marginLeft='5px' />
              ),
            }}
          />
        </Typography>
        <FormContent formProviderProps={methods}>
          <IncomeForm defaultValues={defaultValues} mode={mode} incomeType='earned' />
        </FormContent>
        <Typography variant='p18Bold' color='secondary' marginY='20px'>
          <Trans
            i18nKey='UnearnedIncomeBeforeTax'
            components={{
              Styled: (
                <Typography variant='p12' color='primary' fontWeight='regular' marginLeft='5px' />
              ),
            }}
          />
        </Typography>
        <EmbeddedList<Domain.RealEstateData>
          items={housing}
          keyFn={(x) => x.id?.toString() ?? ''}
          header={t('NetRealEstateIncome')}
          emptyHeader={t('NoRealEstateInvestmentsAdded')}
          titleFn={(x) => x.name ?? ''}
          summaryFn={(x) => formatWholeDollars(getAdjustedNetRealEstateIncome(x, mode))}
          headingProps={{ endAdornment: () => <Svgs.ActionSmallEdit /> }}
          onClick={() => setOpen(true)}
          onClickElement={(x) => handleEditRealEstate(x)}
          onDelete={(x) => {
            setSelectedAccount(x);
            setModalOpen(true);
          }}
        />
        <Divider sx={{ borderColor: Colors.primaryLight }} />
        <FormContent formProviderProps={methods}>
          <IncomeForm defaultValues={defaultValues} mode={mode} incomeType='unearned' />
        </FormContent>
        <Grid
          container
          display='flex'
          justifyContent='space-between'
          marginTop='20px'
          paddingRight='20px'>
          <Typography variant='p18Bold' color='secondary'>
            {t('EstimatedTaxes')}
          </Typography>
          <Grid item display='flex'>
            <Typography variant='p16Bold' color='error'>
              -{formatWholeDollars(taxes)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </CollapsiblePanel>
  );
};

export default IncomeSectionWrapper;
