import React, { useMemo } from 'react';
import { HomeSpendingSection } from './spending/home/HomeSpendingSection';
import { BudgetSectionProps } from '../Types';
import { UtilitySpendingSection } from './spending/UtilitiesSpendingSection';
import { CollapsiblePanel } from '../../../components/CollapsiblePanel';
import { Api, Data, formatWholeDollars } from '@3nickels/data-modules';
import { Svgs } from '../../../assets/svg';
import { TransportSpendingSection } from './spending/TransportSpendingSection';
import { FoodSpendingSection } from './spending/FoodSpendingSection';
import { DebtSpendingSection } from './spending/DebtSpendingSection';
import { MiscellaneousSpendingSection } from './spending/MiscellaneousSpendingSection';
import { t } from 'i18next';

interface SpendingSectionProps extends BudgetSectionProps {
  monthlySpending: Api.MonthlySpendingRest;
  spendingSummary: Data.Budget.BudgetSpendingSummary;
}

export const SpendingSection: React.FC<SpendingSectionProps> = ({
  budget,
  mode,
  monthlySpending,
  spendingSummary,
}) => {
  const summary = useMemo(
    () => Data.Budget.adjustMonthlyAmount(mode, budget?.totalSpending),
    [budget?.totalSpending, mode]
  );

  return (
    <CollapsiblePanel
      header={t('SPENDING')}
      icon={<Svgs.DisplaySmallCustomBudget />}
      summary={formatWholeDollars(summary)}>
      <HomeSpendingSection
        mode={mode}
        monthlySpending={monthlySpending}
        spendingSummary={spendingSummary}
      />
      <UtilitySpendingSection
        mode={mode}
        monthlySpending={monthlySpending}
        spendingSummary={spendingSummary}
      />
      <TransportSpendingSection
        mode={mode}
        monthlySpending={monthlySpending}
        spendingSummary={spendingSummary}
      />
      <FoodSpendingSection
        mode={mode}
        monthlySpending={monthlySpending}
        spendingSummary={spendingSummary}
      />
      <DebtSpendingSection
        mode={mode}
        monthlySpending={monthlySpending}
        spendingSummary={spendingSummary}
      />
      <MiscellaneousSpendingSection
        mode={mode}
        monthlySpending={monthlySpending}
        spendingSummary={spendingSummary}
      />
    </CollapsiblePanel>
  );
};

export default SpendingSection;
