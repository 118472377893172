/* eslint-disable @typescript-eslint/no-explicit-any */
import { Backdrop, Box, Card, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useMemo, useState } from 'react';
import { Data, Domain, Hooks, ObservableGuard, formatWholeDollars } from '@3nickels/data-modules';
import { useNavigate, useParams } from 'react-router-dom';
import { LayoutMeta, withLayoutMeta } from '../../../../types/LayoutMeta';
import { GoalsLayoutMeta, LeaveGoalDetailsModal } from '../../GoalsLayout';
import { Svgs } from '../../../../assets/svg';
import { WizardStep, useObservable } from '@aesop-fables/scrinium';
import { map } from 'rxjs';
import { Loading } from '../../../../hooks/useLoading';
import { FormLoader } from '../../../../components/FormLoader';
import { formatPercent } from '../../../../helpers/utilityFunctions';
import { WizardFooter } from '../../../../components/form/WizardFooter';
import { getWizardFooterProps } from '../../../EditViewUtils';

export const MortgageGoalSummaryViewWrapper: React.FC = () => {
  const { loading, currentStep, wizard } = Hooks.useMortgageGoalWizard();
  const isStarted = useObservable(wizard.isStarted$);
  const params = useObservable(wizard.params$);
  const { id } = useParams();

  useEffect(() => {
    if (typeof isStarted !== 'undefined' && !isStarted) {
      wizard.start({ id: parseInt(id ?? '') });
    } else if (isStarted) {
      wizard.selectStep('basic');
    }
  }, [isStarted]);

  return (
    <ObservableGuard
      predicate$={wizard.current$.pipe(
        // guard against incorrect step to prevent incorrect default values when navigating from another step
        map((current) => current?.key === 'basic')
      )}
      loadingFn={() => (
        <Backdrop open>
          <Loading />
        </Backdrop>
      )}>
      {currentStep?.key === 'basic' && // don't even mount until correct step is set
        params && ( // load params before mounting to prevent flickering
          <FormLoader loading={loading}>
            <MortgageGoalSummaryView currentStep={currentStep} />
          </FormLoader>
        )}
    </ObservableGuard>
  );
};

interface MortgageGoalSummaryViewProps {
  currentStep: WizardStep<Data.Goals.MortgageGoalFormData>;
}

const MortgageGoalSummaryView: React.FC<MortgageGoalSummaryViewProps> = ({ currentStep }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const houses = Hooks.useCombinedHousingData().filter((house) => house.hasMortgage);
  const goal = currentStep.model;
  const principleLeft = useMemo(() => 0 - (goal.principalLeft ?? 0), [goal]);
  const mortgage = useMemo(
    () => houses.find((x) => x.debt?.id === goal.mortgageId)?.debt,
    [houses, goal]
  );

  const [open, setOpen] = useState(false);

  const leaveGoalDetails = () => {
    navigate('/account-details');
  };

  return (
    <Box>
      <LeaveGoalDetailsModal open={open} setOpen={setOpen} handleSave={leaveGoalDetails} />
      <Grid
        container
        display='inline-flex'
        justifyContent='space-between'
        whiteSpace='nowrap'
        flexWrap='nowrap'>
        <Grid container display='inline-flex' overflow='hidden'>
          <Svgs.ArtHouseLarge />
          <Grid item display='flex' flex={1} flexDirection='column' ml='20px' overflow='hidden'>
            <Typography
              className='title'
              display='inline-block'
              maxWidth='95%'
              color='primary'
              component='h1'
              variant='h1'
              overflow='hidden'
              textOverflow='ellipsis'>
              {goal.goalName}
            </Typography>
            <Typography className='subtitle' color='secondary' variant='p18Bold'>
              {t('PayOffMortgage')}
            </Typography>
          </Grid>
        </Grid>
        <Grid item display='inline-flex' pt='10px'>
          <Typography color='primary' variant='p16'>
            {t('CurrentBalance')}
          </Typography>
          <Typography
            ml='10px'
            fontFamily='Montserrat'
            variant='p40Bold'
            color={principleLeft >= 0 ? 'secondary' : 'error'}>
            {formatWholeDollars(principleLeft)}
          </Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent='center' mt={2}>
        <Grid container className='goal-summary' sm={6}>
          <Card>
            <Typography color='secondary' variant='p16'>
              {t('YourInterestRate')}
            </Typography>
            <Typography mt='10px' fontFamily='Montserrat' variant='p30Bold' color='secondary'>
              {formatPercent(goal.interestRate ?? 0)}
            </Typography>
          </Card>
          <Card>
            <Typography color='secondary' variant='p16'>
              {t('YourMortgageTerm')}
            </Typography>
            <Typography mt='10px' fontFamily='Montserrat' variant='p30Bold' color='secondary'>
              {t(
                Domain.MortgageTermTypeEnumMap.get(
                  Domain.getMortgageTermTypeEnumOfYears(mortgage?.loanTermYears ?? 0)
                ) ?? ''
              )}
            </Typography>
          </Card>
          <Card>
            <Typography color='secondary' variant='p16'>
              {t('YourMonthlyMortgagePayment')}
            </Typography>
            <Typography mt='10px' fontFamily='Montserrat' variant='p30Bold' color='secondary'>
              {formatWholeDollars(goal.monthlyPayment ?? 0)}
            </Typography>
          </Card>
        </Grid>
      </Grid>
      <WizardFooter
        hideBack
        onDone={() => navigate('/goals')}
        {...getWizardFooterProps(meta.nextLocaleKey, false)}
      />
    </Box>
  );
};

const meta = {
  nextLocaleKey: 'Done',
  title: 'House',
} satisfies LayoutMeta<GoalsLayoutMeta>;

export default withLayoutMeta(MortgageGoalSummaryViewWrapper, meta);
