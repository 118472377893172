import { Domain } from '@3nickels/data-modules';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Colors } from '../../themes';

interface LmsNextLessonContentProps {
  nextLesson?: Domain.LessonDto;
  variant: 'main' | 'alt';
}

const LmsNextLessonContent: React.FC<LmsNextLessonContentProps> = ({ nextLesson, variant }) => {
  const { t } = useTranslation();

  return (
    <>
      <Typography variant='p16' color='secondary' mt='10px' display='inline-block'>
        <span
          style={{
            fontWeight: 'bold',
            color: variant === 'alt' ? Colors.primaryBase : undefined,
          }}>
          {t('NextUp')}
        </span>
        : {nextLesson?.name}
      </Typography>
      <Typography mt='5px' variant='p12' color='primary'>
        {t('xMin', { x: nextLesson?.minutes })}
      </Typography>
    </>
  );
};

export default LmsNextLessonContent;
