import { DataStoreToken } from '@aesop-fables/scrinium';

function tokenFor(path: string) {
  return new DataStoreToken(`@3nickels/web/data/${path}`);
}

export const WebDataTokens = {
  Auth: tokenFor('auth'),
  Payment: tokenFor('payment'),
};
