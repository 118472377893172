/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useMemo, useState } from 'react';
import { useForm, useFormContext } from 'react-hook-form';
import { useAutoSaveHandler } from '../../../../hooks/useAutoSaveHandler';
import { Data, Domain, Hooks, formatWholeDollars } from '@3nickels/data-modules';
import {
  BudgetSectionProps,
  TransportFormData,
  getAdjustedCarLoanPayment,
  mapTransportFormToMontlySpendingRest,
} from '../../Types';
import * as Yup from 'yup';
import EmbeddedFormInput from '../../../../components/EmbeddedFormInput';
import { Grid } from '@mui/material';
import { cleanWholeNumberStr } from '../../../../helpers/utilityFunctions';
import { SpendingProps } from '../Types';
import { CollapsiblePanel } from '../../../../components/CollapsiblePanel';
import { Svgs } from '../../../../assets/svg';
import FormContent from '../../../../components/form/FormContent';
import EmbeddedList from '../../../../components/EmbeddedList';
import { useNavigate } from 'react-router';
import { LeaveBudgetDetailsModal } from '../../BudgetDetailsLayout';
import _ from 'lodash';
import { t } from 'i18next';
import { useMessage } from '../../../../hooks/useMessage';
import { useMutation } from '@aesop-fables/scrinium';
import RemoveAccountModal from '../../../account-details/RemoveAccountModal';

const schema: () => Yup.Schema = () =>
  Yup.object({
    otherTransportation: Yup.string().notRequired(),
    transportation: Yup.string().notRequired(),
    carMaintenance: Yup.string().notRequired(),
    carInsurance: Yup.string().notRequired(),
    fuel: Yup.string().notRequired(),
  });

export declare type TransportFormProps = BudgetSectionProps & { defaultValues: TransportFormData };

export const TransportForm: React.FC<TransportFormProps> = ({ defaultValues, mode }) => {
  const { showMessage } = useMessage();
  const navigate = useNavigate();
  const { formState } = useFormContext<TransportFormData>();
  const { wizard } = Hooks.useCarWizard();
  const { person } = Hooks.useCombinedSelfData();
  const commands = Hooks.useCommands();
  const cars = Hooks.useCombinedCars().filter((car) => (car.carLoan?.monthlyPayment ?? 0) > 0);
  const deleteCar = useMutation(new Data.Cars.Mutations.DeleteCar());

  const [open, setOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState<Domain.CarData>();

  const handleEditCar = (x: Domain.CarData) => {
    wizard.start({ id: x.id });
    navigate(`/account-details/car/summary/${x.id}`);
  };

  const handleDeleteCar = (account: Domain.CarData) => {
    deleteCar.action(account.id ?? 0);
    showMessage(t('CarRemoved').toString());
  };

  const leaveBudgetDetails = () => {
    navigate('/account-details');
  };

  const max = useMemo(() => {
    if (mode === 'monthly') {
      return 999999;
    }

    return 999999 * 12;
  }, [mode]);

  const formatInputs = (data: any): TransportFormData => {
    const transportation = cleanWholeNumberStr(data.transportation ?? '', { max });
    const carInsurance = cleanWholeNumberStr(data.carInsurance ?? '', { max });
    const fuel = cleanWholeNumberStr(data.fuel ?? '', { max });
    const carMaintenance = cleanWholeNumberStr(data.carMaintenance ?? '', { max });
    const otherTransportation = cleanWholeNumberStr(data.otherTransportation ?? '', { max });

    return { transportation, carInsurance, fuel, carMaintenance, otherTransportation };
  };

  const saveHandler = useCallback(
    async (data: TransportFormData) => {
      const params = mapTransportFormToMontlySpendingRest(mode, data);
      const filtered = _.pickBy(params, (x) => typeof x !== 'undefined');
      await commands.execute(Data.Budget.Commands.UpdateMonthlySpending, filtered);
    },
    [person, mode, commands]
  );

  const { onChange } = useAutoSaveHandler({
    defaultValues,
    mode: 'partial',
    onSave: saveHandler,
    schema: schema(),
    transformValues: formatInputs,
  });

  // TODO -- CardDisplay should be the refactored version: CollapsibleCard
  // (we just need to create it)
  return (
    <form onChange={onChange}>
      <Grid className='embedded-form'>
        <LeaveBudgetDetailsModal open={open} setOpen={setOpen} handleSave={leaveBudgetDetails} />
        <RemoveAccountModal
          name={selectedAccount?.name}
          type='Car'
          variant='Car'
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          handleSave={() => {
            if (selectedAccount) {
              handleDeleteCar(selectedAccount);
            }
          }}
        />
        <EmbeddedList<Domain.CarData>
          items={cars}
          keyFn={(x) => x.id?.toString() ?? ''}
          header={t('CarLoans')}
          emptyHeader={t('NoCarLoansAdded')}
          titleFn={(x) => x.name ?? ''}
          summaryFn={(x) => formatWholeDollars(getAdjustedCarLoanPayment(x, mode))}
          headingProps={{ endAdornment: () => <Svgs.ActionSmallEdit /> }}
          onClick={() => setOpen(true)}
          onClickElement={(x) => handleEditCar(x)}
          onDelete={(x) => {
            setSelectedAccount(x);
            setModalOpen(true);
          }}
        />
        <EmbeddedFormInput<TransportFormData>
          label='Rideshare/Transit'
          placeholder='$0'
          autoComplete='off'
          name='transportation'
          type='dollar'
          max={max}
          defaultValue={defaultValues.transportation}
          error={formState.errors.transportation !== undefined}
          helperText={formState.errors.transportation?.message?.toString()}
        />
        <EmbeddedFormInput<TransportFormData>
          label='Insurance'
          placeholder='$0'
          autoComplete='off'
          name='carInsurance'
          type='dollar'
          max={max}
          defaultValue={defaultValues.carInsurance}
          error={formState.errors.carInsurance !== undefined}
          helperText={formState.errors.carInsurance?.message?.toString()}
        />
        <EmbeddedFormInput<TransportFormData>
          label='Fuel'
          placeholder='$0'
          autoComplete='off'
          name='fuel'
          type='dollar'
          max={max}
          defaultValue={defaultValues.fuel}
          error={formState.errors.fuel !== undefined}
          helperText={formState.errors.fuel?.message?.toString()}
        />
        <EmbeddedFormInput<TransportFormData>
          label='Maintenance'
          placeholder='$0'
          autoComplete='off'
          name='carMaintenance'
          type='dollar'
          max={max}
          defaultValue={defaultValues.carMaintenance}
          error={formState.errors.carMaintenance !== undefined}
          helperText={formState.errors.carMaintenance?.message?.toString()}
        />
        <EmbeddedFormInput<TransportFormData>
          label='OtherCar/TransitCosts'
          placeholder='$0'
          autoComplete='off'
          name='otherTransportation'
          type='dollar'
          max={max}
          defaultValue={defaultValues.otherTransportation}
          error={formState.errors.otherTransportation !== undefined}
          helperText={formState.errors.otherTransportation?.message?.toString()}
        />
      </Grid>
    </form>
  );
};

export const TransportSpendingSection: React.FC<BudgetSectionProps & SpendingProps> = ({
  mode,
  monthlySpending,
  spendingSummary,
}) => {
  const defaultValues = useMemo(() => {
    return {
      otherTransportation: Data.Budget.adjustMonthlyAmount(
        mode,
        monthlySpending?.otherTransportation
      ),
      transportation: Data.Budget.adjustMonthlyAmount(mode, monthlySpending?.transportation),
      carMaintenance: Data.Budget.adjustMonthlyAmount(mode, monthlySpending?.carMaintenance),
      carInsurance: Data.Budget.adjustMonthlyAmount(mode, monthlySpending?.carInsurance),
      fuel: Data.Budget.adjustMonthlyAmount(mode, monthlySpending?.fuel),
    };
  }, [monthlySpending, mode]);

  const summary = useMemo(
    () =>
      Data.Budget.adjustMonthlyAmount(mode, spendingSummary.carTransit + spendingSummary.carLoan),
    [spendingSummary, mode]
  );

  const methods = useForm<TransportFormData>({ defaultValues });
  return (
    <CollapsiblePanel
      variant='compressed'
      header={t('CarTransit')}
      expandIconPosition='relative'
      icon={<Svgs.DisplaySmallCustomCar />}
      summary={formatWholeDollars(summary)}>
      <Grid className='embedded-content'>
        <FormContent formProviderProps={methods}>
          <TransportForm defaultValues={defaultValues} mode={mode} />
        </FormContent>
      </Grid>
    </CollapsiblePanel>
  );
};
