/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { sortData } from '../helpers/utilityFunctions';
import {
  Card,
  CardContent,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Grid,
} from '@mui/material';
import React from 'react';
import Spacer from './Spacer';
import { Caret } from './Caret';
import { Borders, Colors } from '../themes';
import Row from './Row';
import { t } from 'i18next';

type PrimitiveType = React.ReactNode;

function objectValues<T extends object>(obj: T) {
  return Object.keys(obj)
    .filter((objKey) => objKey !== 'id') // filter out ids
    .map((objKey) => obj[objKey as keyof T]);
}

function isPrimitive(value: any): value is PrimitiveType {
  return (
    typeof value === 'string' ||
    typeof value === 'number' ||
    typeof value === 'boolean' ||
    typeof value === 'object'
  );
}

interface SortingTableProps {
  headers: {
    label: string;
    title: string;
  }[];
  data: {
    id?: number | string;
  }[];
  emptyStateText?: string;
  defaultSortColumn?: string;
}

function SortingTable(props: SortingTableProps) {
  const { headers, data, emptyStateText, defaultSortColumn } = props;
  const [sortedData, setSortedData] = useState<object[]>([...data]);
  const [sortDirection, setSortDirection] = useState(true);
  const [sortBy, setSortBy] = useState(defaultSortColumn || headers[0].label || null);

  useEffect(() => {
    setSortedData([...data]);
  }, [data]);

  return (
    <Grid container>
      {sortedData.length > 0 ? (
        <Grid mt={0} display='flex' flexDirection='column' width='100%' rowGap='10px'>
          {/* Headers */}
          <TableContainer sx={{ padding: '0px 40px' }}>
            <Table className='spending-overview-categories' aria-label='table headers'>
              <TableBody className='spending-overview-view' style={{ padding: '0px' }}>
                <TableRow>
                  {headers.map((header, index) => (
                    <TableCell
                      width={index === 0 ? '15%' : '20%'}
                      key={index}
                      color={Colors.primaryBase}
                      style={{
                        userSelect: 'none',
                        cursor: 'pointer',
                        fontSize: 14,
                        fontFamily: 'Roboto',
                        textDecoration: 'none',
                        color: Colors.primaryBase,
                        border: 'none',
                        padding: 0,
                        textAlign: `${index === 0 ? 'left' : 'right'}`,
                      }}
                      onClick={() => {
                        sortData(sortedData, header.label, sortDirection, setSortedData, setSortBy);
                        setSortDirection((prev) => !prev);
                      }}>
                      <Row
                        style={{
                          alignItems: 'center',
                          justifyContent: `${index === 0 ? 'flex-start' : 'flex-end'}`,
                        }}>
                        {t(header.title)}
                        {sortBy === header.label ? <Caret sortDirection={sortDirection} /> : null}
                      </Row>
                    </TableCell>
                  ))}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          {/* Body */}
          <TableContainer
            sx={{
              padding: '0px 40px',
              alignItems: 'baseline',
              border: `solid 1px ${Colors.primaryLightest}`,
              backgroundColor: Colors.tertiaryBase,
              maxHeight: '50vh',
              ...Borders.borderRadius_10,
            }}>
            <Table className='spending-overview-categories' aria-label='simple table'>
              <TableBody className='spending-overview-view'>
                {sortedData.map((row: any, i: number) => (
                  <TableRow key={i} sx={{ borderBottom: `1px solid ${Colors.primaryLightest}` }}>
                    {objectValues(row).map((entry, j) => {
                      return typeof entry === 'boolean' ? null : (
                        <TableCell
                          width={j === 0 ? '15%' : '20%'}
                          key={j}
                          variant={j === 0 ? 'dataBodyBold' : undefined}
                          sx={{
                            textAlign: `${j === 0 ? 'left' : 'right'}`,
                          }}>
                          {isPrimitive(entry) ? entry : 'Error Reading Table Entry'}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      ) : (
        <Card variant='ghost' color='primary'>
          <CardContent sx={{ textAlign: 'center' }} className={'dataTable'}>
            <Typography variant='p20SemiBold' color='secondary.main'>
              {emptyStateText}
            </Typography>
          </CardContent>
        </Card>
      )}
      <Spacer height='lg' />
    </Grid>
  );
}

export default SortingTable;
