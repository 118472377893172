/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo, useState } from 'react';
import EmbeddedList from '../../../components/EmbeddedList';
import { Data, Domain, Hooks, formatWholeDollars } from '@3nickels/data-modules';
import { BudgetSectionProps, OtherSavingsFormData, calcContributions } from '../Types';
import { useForm } from 'react-hook-form';
import FormContent from '../../../components/form/FormContent';
import { OtherSavingsForm, OtherSavingsFormProps } from './OtherSavingsForm';
import { useMutation, useSubject } from '@aesop-fables/scrinium';
import { CollapsiblePanel } from '../../../components/CollapsiblePanel';
import { Svgs } from '../../../assets/svg';
import { Divider, Typography } from '@mui/material';
import { Colors } from '../../../themes';
import { useNavigate } from 'react-router-dom';
import { LeaveBudgetDetailsModal } from '../BudgetDetailsLayout';
import Grid from '@mui/system/Unstable_Grid/Grid';
import { t } from 'i18next';
import { ReviewInstitutionalAccountModal } from '../../account-details/retirement-plan/RetirementPlanView';
import RemoveAccountModal from '../../account-details/RemoveAccountModal';
import { useMessage } from '../../../hooks/useMessage';

export const displayedGoalTypes: Domain.GoalTypeEnum[] = [
  Domain.GoalTypeEnum['Emergency Fund'],
  Domain.GoalTypeEnum.Car,
  Domain.GoalTypeEnum.House,
  Domain.GoalTypeEnum.College,
  Domain.GoalTypeEnum.Other,
  Domain.GoalTypeEnum.Gift,
  Domain.GoalTypeEnum.Vacation,
];

const SavingsSectionWrapper: React.FC<BudgetSectionProps> = ({ mode, budget }) => {
  const saving = useSubject<Domain.SavingData>(Data.Savings.SavingsServices.SavingsData);
  const defaultValues = useMemo(() => {
    return {
      otherSavings: Data.Budget.adjustAnnualAmount(mode, saving?.otherSavings),
    };
  }, [mode, saving]);

  return <SavingsSection defaultValues={defaultValues} mode={mode} budget={budget} />;
};

export const SavingsSection: React.FC<OtherSavingsFormProps> = ({
  budget,
  defaultValues,
  mode,
}) => {
  const { showMessage } = useMessage();
  const navigate = useNavigate();
  const { wizard: retirementWizard } = Hooks.useRetirementWizard();
  const { wizard: pensionWizard } = Hooks.usePensionWizard();
  const { wizard: investmentWizard } = Hooks.useInvestmentAccountWizard();
  const hsas = Hooks.useCombinedHsaData();
  const { retirementPlans, spouseRetirementPlans } = Hooks.useRetirementPlanData();
  const { institutionalAccount, institutionalAccountRequiresReview } =
    Hooks.useInstitutionalAccount();
  const duplicateAccountService = Hooks.useDuplicateAccountService();
  const goals = Hooks.useFlattenedGoalData();
  const { person, includeSpouse } = Hooks.useCombinedSelfData();
  const spouseData = Hooks.useSpouseData();
  const planTypes = Hooks.useAvailablePlanTypes('me');
  const spousePlanTypes = Hooks.useAvailablePlanTypes('spouse');
  const deleteRetirement = useMutation(
    new Data.InvestmentAccounts.Mutations.DeleteRetirementAccount()
  );
  const deleteHsa = useMutation(new Data.InvestmentAccounts.Mutations.DeleteHsa());
  const adjustedIncome = useMemo(() => {
    return person?.annualIncome ?? 0;
  }, [mode, person]);
  const adjustedSpouseIncome = useMemo(() => {
    return spouseData?.annualIncome ?? 0;
  }, [mode, spouseData]);
  // need to filter by active contributions
  const accounts = useMemo(
    () =>
      retirementPlans.filter(
        (x) =>
          x.planType !== Domain.PlanTypeEnum['IRA | Rollover'] &&
          x.planType !== Domain.PlanTypeEnum.Pension &&
          (x as Domain.PlanData).contributionEligibility &&
          (x as Domain.PlanData).employeeContrib &&
          calcContributions(x, adjustedIncome) > 0
      ),
    [retirementPlans, adjustedIncome]
  );
  const spouseAccounts = useMemo(
    () =>
      spouseRetirementPlans?.filter(
        (x) =>
          x.planType !== Domain.PlanTypeEnum['IRA | Rollover'] &&
          x.planType !== Domain.PlanTypeEnum.Pension &&
          (x as Domain.PlanData).contributionEligibility &&
          (x as Domain.PlanData).employeeContrib &&
          calcContributions(x, adjustedSpouseIncome) > 0
      ),
    [spouseRetirementPlans, adjustedSpouseIncome]
  );
  const investments = useMemo(
    () =>
      hsas.filter(
        (x) => x.planType === Domain.PlanTypeEnum.HSA && (x.annualContribPreTaxDollar ?? 0) > 0
      ),
    [hsas]
  );
  const filteredGoals = useMemo(
    () =>
      goals.filter(
        (x) => displayedGoalTypes.indexOf(x.type ?? Domain.GoalTypeEnum['Emergency Fund']) !== -1
      ),
    [goals]
  );
  const methods = useForm<OtherSavingsFormData>({ defaultValues });
  const summary = useMemo(
    () => Data.Budget.adjustMonthlyAmount(mode, budget?.totalSaving), // total saving is monthly
    [budget?.totalSaving, mode]
  );

  const [open, setOpen] = useState<'' | 'accounts' | 'goals'>('');
  const [modalOpen, setModalOpen] = useState(false);
  const [reviewInstitutionalAccountOpen, setReviewInstitutionalAccountOpen] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState<Domain.PlanData>();

  const leaveBudgetDetails = () => {
    if (open === 'goals') {
      navigate('/goals');
    } else {
      navigate('/account-details');
    }
  };

  const handleEditRetirement = (x: Domain.PlanData) => {
    const isSpouse = x.owner === 'spouse';
    const type =
      x.planType ??
      (isSpouse && spousePlanTypes.length > 0
        ? (spousePlanTypes[0].key as number)
        : (planTypes[0].key as number));

    if (x.id === institutionalAccount?.id && institutionalAccountRequiresReview) {
      setSelectedAccount({
        ...Data.InvestmentAccounts.mapPlanDataToInvestmentAccount(x),
        planType: x.planType,
        participantId: x.participantId,
      });
      setReviewInstitutionalAccountOpen(true);
      return;
    }

    if (x.planType === Domain.PlanTypeEnum.Pension) {
      pensionWizard.start({ id: x.id }).then(() => {
        navigate(`/account-details/pension-plan/summary/${x.id}`);
      });
      return;
    }

    retirementWizard.start({ id: x.id, type, isSpouse });
    navigate(
      `/account-details/retirement-savings-plan/summary/${
        Domain.planTypeIdToAccountKey[x.planType as keyof typeof Domain.planTypeIdToAccountKey]
      }/${x.id}`
    );
  };

  const handleEditHsa = async (x: Domain.PlanData) => {
    await investmentWizard.start({ id: x.id ?? 0, type: Domain.PlanTypeEnum['HSA'] });
    navigate(`/account-details/health-savings-account/summary/${x.id}`);
  };

  const startInstitutionalAccount = async () => {
    if (selectedAccount && selectedAccount.planType) {
      const duplicate = await duplicateAccountService.checkForInstitutionalAccountDuplicates();
      if (duplicate?.planType) {
        retirementWizard.start({
          id: duplicate.id,
          type: duplicate.planType,
          isSpouse: false,
        });
        navigate(
          `/account-details/retirement-savings-plan/duplicate/${
            Domain.planTypeIdToAccountKey[
              duplicate.planType as keyof typeof Domain.planTypeIdToAccountKey
            ]
          }/${duplicate.id}`
        );
        return;
      }
      retirementWizard.start({
        id: selectedAccount.id,
        type: selectedAccount.planType,
        isSpouse: false,
      });
      navigate(
        `/account-details/retirement-savings-plan/basics/${
          Domain.planTypeIdToAccountKey[
            selectedAccount.planType as keyof typeof Domain.planTypeIdToAccountKey
          ]
        }/${selectedAccount.id}`
      );
    }
  };

  const handleDeleteRetirement = (account: Domain.PlanData) => {
    deleteRetirement.action({ id: account.id ?? 0, participantId: account?.participantId ?? 0 });
    showMessage(t('RetirementPlanRemoved').toString());
  };

  const handleDeleteHsa = (account: Domain.PlanData) => {
    deleteHsa.action(account.id ?? 0);
    showMessage(t('HsaRemoved').toString());
  };

  return (
    <CollapsiblePanel
      header={t('SAVINGS')}
      icon={<Svgs.PiggyBank />}
      summary={formatWholeDollars(summary)}>
      <LeaveBudgetDetailsModal
        open={open !== ''}
        setOpen={() => setOpen('')}
        handleSave={leaveBudgetDetails}
        text={open === 'goals' ? 'GoToGoals' : undefined}
      />
      <RemoveAccountModal
        name={
          selectedAccount?.planType === Domain.PlanTypeEnum.HSA
            ? selectedAccount?.nickname
            : selectedAccount?.nickname?.replace('*', '')
        }
        type={selectedAccount?.planType && Domain.PlanTypeEnum[selectedAccount.planType]}
        variant={selectedAccount?.planType === Domain.PlanTypeEnum.HSA ? 'Investment' : undefined}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        handleSave={() => {
          if (selectedAccount) {
            if (selectedAccount.planType === Domain.PlanTypeEnum.HSA) {
              handleDeleteHsa(selectedAccount);
            } else {
              handleDeleteRetirement(selectedAccount);
            }
          }
        }}
      />
      <ReviewInstitutionalAccountModal
        open={reviewInstitutionalAccountOpen}
        setOpen={setReviewInstitutionalAccountOpen}
        onStart={startInstitutionalAccount}
      />
      <Grid className='embedded-content'>
        <Typography variant='p18Bold' color='secondary' marginY='20px' mt='-20px'>
          {t('CurrentRetirementContributions')}
        </Typography>
        <EmbeddedList<Domain.PlanData>
          items={accounts}
          keyFn={(x) => x.id?.toString() ?? ''}
          header={t('MyContributions')}
          emptyHeader={t('NoContributionsAddedMe')}
          titleFn={(x) => x.nickname ?? ''}
          summaryFn={(x) =>
            mode === 'monthly'
              ? formatWholeDollars(calcContributions(x, adjustedIncome) / 12)
              : formatWholeDollars(calcContributions(x, adjustedIncome))
          }
          headingProps={{ endAdornment: () => <Svgs.ActionSmallEdit /> }}
          onClick={() => setOpen('accounts')}
          onClickElement={(x) => handleEditRetirement(x)}
          disableDelete={(x) => x.id === institutionalAccount?.id}
          onDelete={(x) => {
            setSelectedAccount(x);
            setModalOpen(true);
          }}
        />
        {includeSpouse && (
          <>
            <Divider sx={{ borderColor: Colors.primaryLight }} />
            <EmbeddedList<Domain.PlanData>
              items={spouseAccounts}
              keyFn={(x) => x.id?.toString() ?? ''}
              header={t('SpouseContributions')}
              emptyHeader={t('NoContributionsAddedSpouse')}
              titleFn={(x) => x.nickname ?? ''}
              summaryFn={(x) =>
                mode === 'monthly'
                  ? formatWholeDollars(calcContributions(x, adjustedSpouseIncome) / 12)
                  : formatWholeDollars(calcContributions(x, adjustedSpouseIncome))
              }
              headingProps={{ endAdornment: () => <Svgs.ActionSmallEdit /> }}
              onClick={() => setOpen('accounts')}
              onClickElement={(x) => handleEditRetirement(x)}
              disableDelete={(x) => x.id === institutionalAccount?.id}
              onDelete={(x) => {
                setSelectedAccount(x);
                setModalOpen(true);
              }}
            />
          </>
        )}
        <Typography variant='p18Bold' color='secondary' marginTop='30px' marginBottom='20px'>
          {t('Investments')}
        </Typography>
        <EmbeddedList<Domain.PlanData>
          items={investments}
          keyFn={(x) => x.id?.toString() ?? ''}
          header={t('HSA')}
          emptyHeader={t('NoHsaAdded')}
          titleFn={(x) => x.nickname ?? ''}
          summaryFn={(x) =>
            mode === 'monthly'
              ? formatWholeDollars((x.annualContribPreTaxDollar ?? 0) / 12)
              : formatWholeDollars(x.annualContribPreTaxDollar ?? 0)
          }
          headingProps={{ endAdornment: () => <Svgs.ActionSmallEdit /> }}
          onClick={() => setOpen('accounts')}
          onClickElement={(x) => handleEditHsa(x)}
          onDelete={(x) => {
            setSelectedAccount(x);
            setModalOpen(true);
          }}
        />
        <Typography variant='p18Bold' color='secondary' marginTop='30px' marginBottom='20px'>
          {t('OtherSaving')}
        </Typography>
        <EmbeddedList<Domain.HolisticGoalDisplay>
          items={filteredGoals}
          keyFn={(x) => x.id?.toString() ?? ''}
          header={t('OtherSavingGoals')}
          emptyHeader={t('NoGoalsAdded')}
          titleFn={(x) => x.name ?? ''}
          summaryFn={(x) =>
            mode === 'monthly'
              ? formatWholeDollars(x.summary ?? 0)
              : formatWholeDollars((x.summary ?? 0) * 12)
          }
          headingProps={{ endAdornment: () => <Svgs.ActionSmallEdit /> }}
          onClick={() => setOpen('goals')}
        />
        <Divider sx={{ borderColor: Colors.primaryLight }} />
        <FormContent formProviderProps={methods}>
          <OtherSavingsForm defaultValues={defaultValues} mode={mode} />
        </FormContent>
      </Grid>
    </CollapsiblePanel>
  );
};

export default SavingsSectionWrapper;
