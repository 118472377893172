import { Box, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import { Spacing } from '../../../../themes';
import { Data, Domain } from '@3nickels/data-modules';
import { useFieldArray, useFormContext } from 'react-hook-form';
import TextInput from '../../../../components/form/TextInput';
import { useTranslation } from 'react-i18next';

export type RetirementPlanEmployerContributionsFormProps = {
  contributions?: Data.InvestmentAccounts.EmployerContributionsFormData;
  params: Data.InvestmentAccounts.InvestmentAccountWizardParams;
  onSubmit: (values: Data.InvestmentAccountBasicFormData) => Promise<void>;
};

const RetirementPlanMultiTierEmployerContributionsForm: React.FC<
  RetirementPlanEmployerContributionsFormProps
> = ({ contributions, params }) => {
  const { formState } = useFormContext<Data.InvestmentAccounts.EmployerContributionsFormData>();
  const { fields } = useFieldArray({ name: 'contributionTiers' });

  return (
    <Box>
      <Stack spacing={Spacing.xxs}>
        {fields.map((field, index) => (
          <TieredEmployerMatchInput key={field.id} index={index} params={params} />
        ))}
        <TextInput<Data.InvestmentAccounts.EmployerContributionsFormData>
          error={formState.errors.otherEmployerContrib !== undefined}
          helperText={formState.errors.otherEmployerContrib?.message?.toString()}
          defaultValue={contributions?.otherEmployerContrib}
          disabled={params.institutionalAccount}
          label='FixedEmployerContibutionsOrProfitSharing'
          name='otherEmployerContrib'
          type='percent'
        />
      </Stack>
    </Box>
  );
};

interface TieredEmployerMatchInputProps {
  index: number;
  params: Data.InvestmentAccounts.InvestmentAccountWizardParams;
}

const TieredEmployerMatchInput: React.FC<TieredEmployerMatchInputProps> = ({ index, params }) => {
  const { t } = useTranslation();
  const { formState } = useFormContext<Data.InvestmentAccounts.EmployerContributionsFormData>();

  return (
    <>
      <Grid item>
        <Typography variant='p16Bold' color='secondary' marginBottom='10px'>
          {t('TierX', { x: index + 1 })}
        </Typography>
        <TextInput
          defaultValue={formState.defaultValues?.contributionTiers?.[index]?.percentContribution}
          disabled={params.institutionalAccount}
          label={t('TieredPerDollarEmployerMatchRate', { tier: index + 1 }).toString()}
          name={`contributionTiers.${index}.percentContribution`}
          type='percent'
          resolver={(values) => {
            const tiers = values['contributionTiers'] as Domain.ContributionTier[];
            const percentContribution = tiers[index].percentContribution;
            return percentContribution?.toString() ?? '';
          }}
        />
      </Grid>
      <TextInput
        defaultValue={formState.defaultValues?.contributionTiers?.[index]?.to}
        disabled={params.institutionalAccount}
        label={t('TieredMaxPercentForMatch', { tier: index + 1 }).toString()}
        name={`contributionTiers.${index}.to`}
        type='percent'
        resolver={(values) => {
          const tiers = values['contributionTiers'] as Domain.ContributionTier[];
          const percentContribution = tiers[index].to;
          return percentContribution?.toString() ?? '';
        }}
      />
    </>
  );
};

export default RetirementPlanMultiTierEmployerContributionsForm;
