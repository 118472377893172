import { useTranslation } from 'react-i18next';
import { SummaryCardProps } from '../../../Types';
import { Grid, Typography } from '@mui/material';
import { capitalizeFirstLetter, formatWholeDollars } from '../../../../../helpers/utilityFunctions';
import CardDisplay from '../../../../../components/panels/CardDisplay';
import { Data } from '@3nickels/data-modules';

export interface RealEstateBasicsCardProps extends SummaryCardProps {
  propertyBasics: Data.RealEstate.HousingEntityBasicFormData;
  includeSpouse?: boolean;
}

const RealEstateBasicsCard: React.FC<RealEstateBasicsCardProps> = ({
  onEdit,
  propertyBasics,
  includeSpouse,
}) => {
  const { t } = useTranslation();

  return (
    <CardDisplay headerKey='AccountBasics' actionContent='Edit' actionClick={onEdit}>
      <Grid container spacing={2}>
        <Grid item xs>
          <Typography variant='h3' component='h3' color='primary'>
            {t('RealEstateNickname')}
          </Typography>
          <Typography color='secondary'>{propertyBasics.name}</Typography>
        </Grid>
        {includeSpouse && (
          <Grid item xs>
            <Typography variant='h3' component='h3' color='primary'>
              {t('AccountOwner')}
            </Typography>
            <Typography color='secondary'>
              {t(capitalizeFirstLetter(propertyBasics?.owner ?? ''))}
            </Typography>
          </Grid>
        )}
        <Grid item xs>
          <Typography variant='h3' component='h3' color='primary'>
            {t('CurrentRealEstateValue')}
          </Typography>
          <Typography color='secondary'>{formatWholeDollars(propertyBasics.value ?? 0)}</Typography>
        </Grid>
        <Grid item xs>
          <Typography variant='h3' component='h3' color='primary'>
            {t('MonthlyRentalIncomeForThisProperty')}
          </Typography>
          <Typography color='secondary'>
            {formatWholeDollars(propertyBasics.rentalIncome ?? 0)}
          </Typography>
        </Grid>
        <Grid item xs />
        {!includeSpouse && <Grid item xs />}
      </Grid>
    </CardDisplay>
  );
};

export default RealEstateBasicsCard;
