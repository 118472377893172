/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { t } from 'i18next';
import { Card, Divider, Grid, IconButton, MenuItem, Stack, Typography } from '@mui/material';
import { Data, Domain, formatWholeDollars, Hooks, Services } from '@3nickels/data-modules';
import { useFormContext, UseFormReturn } from 'react-hook-form';
import { Button } from '../../../../components/buttons/Button';
import SelectInput from '../../../../components/form/SelectInput';
import { useTranslation } from 'react-i18next';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useMessage } from '../../../../hooks/useMessage';
import { Colors } from '../../../../themes';
import SliderPercent from '../../../../components/form/SliderPercent';
import ToggleRadioButtonGroup from '../../../../components/form/ToggleRadioButtonGroup';
import { Modal, Row } from '../../../../components';
import { useSubject } from '@aesop-fables/scrinium';
import { Add } from '@mui/icons-material';
import { TaxInvestmentDonationsDrawer } from './TaxInvestmentDonationsDrawer';
import { Svgs } from '../../../../assets/svg';
import { SavedFormsKeyEnum } from '../../../../types/SavedForms';
import { convertToTranslationKey } from '../../../../helpers/utilityFunctions';

type TaxBenefitsCalculatorFormProps = {
  methods: UseFormReturn<Domain.GtbdFormData, any, undefined>;
  onSubmit: (values: Domain.GtbdFormData) => Promise<void>;
  results: Domain.TaxBenefitsData | undefined;
  setResults: Dispatch<SetStateAction<Domain.TaxBenefitsData | undefined>>;
  activeTaxId?: number;
  defaultValues: Domain.GtbdFormData;
  setActiveTaxId: Dispatch<SetStateAction<number | undefined>>;
};

const TaxBenefitsCalculatorForm: React.FC<TaxBenefitsCalculatorFormProps> = ({
  methods,
  onSubmit,
  results,
  setResults,
  activeTaxId,
  defaultValues: defaultValuesObj,
  setActiveTaxId,
}) => {
  const { t } = useTranslation();
  const taxes = Hooks.useTaxes() ?? [];
  const taxesInitialized = Hooks.useTaxDeductionInitialized();
  const incomes = Hooks.useIncomeData();
  const includeSpouse = Hooks.useIncludeSpouse();
  const dispatcher = Hooks.useObservableQueryDispatcher();
  const selfData = Hooks.usePersonData();
  const spouseData = Hooks.useSpouseData();
  const participant = useSubject<Services.ParticipantContext>(Services.Services.ParticipantContext);
  const commands = Hooks.useCommands();
  const { showMessage } = useMessage();
  const { formState } = useFormContext();
  const formService = Hooks.useFormService();
  const financialInfo = useSubject(Data.People.Projections.FinancialInfoSubject);
  const [taxYear, setTaxYear] = useState<number | undefined>(undefined);
  const [willItemize, setWillItemize] = useState<boolean | undefined>(undefined);
  const [willProvide, setWillProvide] = useState<boolean | undefined>(undefined);
  const [showTaxInfo, setShowTaxInfo] = useState<boolean>(false);
  const [showDeductions, setShowDeductions] = useState<boolean>(false);
  const [disableDeductions, setDisableDeductions] = useState<boolean>(true);
  const [willDonateInvestments, setWillDonateInvestments] = useState<boolean | undefined>(
    undefined
  );
  const [taxInfoIncomplete, setTaxInfoIncomplete] = useState<boolean>(false);
  const [investments, setInvestments] = useState<Domain.GtdbTaxDeductionData[] | undefined>(
    undefined
  );
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [activeInvestment, setActiveInvestment] = useState<Domain.GtdbTaxDeductionData | undefined>(
    undefined
  );
  const [activeStandardDeduction, setActiveStandardDeduction] =
    useState<Domain.StandardDeductionData>();
  const [defaultValues, setDefaultValues] = useState<Domain.GtbdFormData>(defaultValuesObj);

  useEffect(() => {
    if (!taxesInitialized) return;
    if (defaultValuesObj.taxYear && defaultValuesObj.taxYear !== taxYear)
      onSelectTaxYear(defaultValuesObj.taxYear);
  }, [defaultValuesObj.id, defaultValuesObj.taxYear, taxesInitialized]);

  const mapGtbdTaxDataToGtbdFormData = async (
    taxData: Domain.GtbdTaxData,
    selfData: Domain.SelfData,
    spouseData: Domain.SpouseData | undefined
  ): Promise<Domain.GtbdFormData> => {
    const investmentsCount =
      (await dispatcher.execute(
        Data.TaxDeduction.Queries.FindInvestmentsCountByTaxId,
        taxData.id ?? 0
      )) ?? 0;
    return {
      personId: selfData.personId,
      accountId: taxData.accountId,
      spouseId: spouseData?.spouseId,
      cashDonations: taxData.cashDonations,
      id: taxData.id,
      itemizeDeductions: taxData.itemizeDeductions,
      participantId: taxData.participantId,
      totalOtherItemizedDeduction: taxData.totalOtherItemizedDeduction,
      selfAnnualIncome: taxData.annualIncome,
      selfOtherAnnualIncome: taxData.otherIncome,
      spouseAnnualIncome: taxData.spouseAnnualIncome,
      spouseOtherAnnualIncome: taxData.spouseOtherIncome,
      taxFilingState: taxData.taxFilingState,
      taxFilingStatus: Domain.mapTaxFilingStatusEnumOfTaxFilingStatusSaved(
        String(taxData.taxFilingStatus)
      ),
      taxYear: taxData.taxYear,
      donatedItemValue: taxData.donatedItemValue,
      mileageValue: taxData.mileageValue,
      estimatedDonationsForYear: taxData.estimatedDonationsForYear,
      provideDonationDetails:
        taxData.itemizeDeductions === false
          ? undefined
          : typeof taxData.estimatedDonationsForYear === 'undefined' ||
            taxData.estimatedDonationsForYear === 0 ||
            investmentsCount > 0,
    };
  };

  const savedYears = taxes.map((taxYearData) => ({
    key: taxYearData.taxYear,
    value: String(taxYearData.taxYear),
  }));
  const recentTwoYears = Domain.taxYears
    .filter((t) => !taxes.map((x) => x.taxYear ?? 0).includes(t))
    .map((x) => ({
      key: x,
      value: String(x),
    }));
  const yearsList = [...savedYears, ...recentTwoYears].sort((a, b) => (a.key ?? 0) - (b.key ?? 0));

  function getStandardDeductionByFilingStatus(taxFilingStatus?: Domain.TaxFilingStatusEnum) {
    switch (taxFilingStatus) {
      case Domain.TaxFilingStatusEnum['Head of Household']:
        return activeStandardDeduction?.headOfHousehold ?? 0;
      case Domain.TaxFilingStatusEnum['Married Filing Jointly']:
        return activeStandardDeduction?.marriedFilingJointly ?? 0;
      case Domain.TaxFilingStatusEnum['Married Filing Separately']:
        return activeStandardDeduction?.marriedFilingSeparately ?? 0;
      default:
        return activeStandardDeduction?.single ?? 0;
    }
  }

  const onClick = methods.handleSubmit(
    async (data) => {
      if (
        !data.itemizeDeductions &&
        data.estimatedDonationsForYear &&
        data.estimatedDonationsForYear > getStandardDeductionByFilingStatus(data.taxFilingStatus)
      ) {
        showMessage(
          t('BecauseYouPlanToGive', {
            standardDeduction: formatWholeDollars(
              getStandardDeductionByFilingStatus(data.taxFilingStatus)
            ),
          }) as string,
          'warning'
        );
        return;
      }

      // TODO add loading spinner for calculate button
      await onSubmit(data);
    },
    async (err) => {
      if (err.totalOtherItemizedDeduction) {
        throw 'totalOtherItemizedDeduction';
      }
    }
  );

  const onClickTaxInfo = () => {
    if (!taxYear) {
      showMessage(t('PleaseChooseTaxYear') as string, 'warning');
      return;
    }
    setShowTaxInfo((prev) => !prev);
  };

  const onClickDeductions = () => {
    if (!taxYear) {
      showMessage(t('PleaseChooseTaxYear') as string, 'warning');
      return;
    }
    if (disableDeductions) {
      showMessage(t('PleaseFillOutIncomeInfo') as string, 'warning');
      return;
    }
    setShowDeductions((prev) => !prev);
  };

  const onSelectTaxYear = async (newTaxYear: number) => {
    setResults(undefined);
    setWillProvide(undefined);
    setTaxYear(newTaxYear);

    const savedInfoForYear = taxes.find(
      (savedTaxYearInfo) => savedTaxYearInfo.taxYear === newTaxYear
    );
    const taxYearDataExists = savedInfoForYear !== undefined;
    setShowTaxInfo(true);
    const standardDeduction = await dispatcher.execute(
      Data.StandardDeduction.Queries.GetStandardDeductionByYear,
      newTaxYear
    );
    setActiveStandardDeduction(standardDeduction);

    if (taxYearDataExists) {
      const investmentsResult = await dispatcher.execute(
        Data.TaxDeduction.Queries.FindInvestmentsByTaxId,
        savedInfoForYear.id
      );
      setInvestments(investmentsResult);
      const mappedSavedData = await mapGtbdTaxDataToGtbdFormData(
        savedInfoForYear,
        selfData ?? {},
        spouseData
      );
      setDefaultValues(mappedSavedData);
      setActiveTaxId(mappedSavedData.id);
      methods.reset(mappedSavedData);
      setWillItemize(mappedSavedData.itemizeDeductions);
      methods.setValue('itemizeDeductions', mappedSavedData.itemizeDeductions); // methods.reset doesnt always reset this field for some reason.
      if (mappedSavedData.itemizeDeductions) {
        setWillProvide(mappedSavedData.provideDonationDetails);
        setWillDonateInvestments(investmentsResult.length > 0);
      }
      setTaxInfoIncomplete(false);
      setDisableDeductions(false);
      setShowDeductions(true);
    } else {
      const newFormValues = {
        id: undefined,
        taxYear: newTaxYear,
        personId: selfData?.personId ?? 0,
        spouseId: spouseData?.spouseId,
        selfAnnualIncome: incomes?.income,
        selfOtherAnnualIncome: incomes?.otherIncome,
        spouseAnnualIncome: incomes?.spouseIncome,
        spouseOtherAnnualIncome: incomes?.spouseOtherIncome,
        taxFilingStatus: financialInfo?.taxFilingStatus,
        taxFilingState: financialInfo?.taxFilingState,
        itemizeDeductions: undefined,
        provideDonationDetails: undefined,
        estimatedDonationsForYear: undefined,
        donatedItemValue: undefined,
        cashDonations: undefined,
        mileageValue: undefined,
        totalOtherItemizedDeduction: undefined,
      };
      setDefaultValues(newFormValues);
      methods.reset(newFormValues);
      setWillItemize(undefined);
      setWillProvide(undefined);
      setWillDonateInvestments(undefined);
      setTaxInfoIncomplete(true);
      setDisableDeductions(true);
      setShowDeductions(false);
    }
  };

  const cleanTaxInfoValues = (values: Domain.GtbdFormData) => {
    return {
      id: values.id,
      taxFilingStatus: Number(values.taxFilingStatus),
      taxFilingState: values.taxFilingState,
      taxYear: Number(values.taxYear),
      itemizeDeductions: values.itemizeDeductions,
      provideDonationDetails: values.provideDonationDetails,
      selfAnnualIncome: Number((values.selfAnnualIncome?.toString() ?? '').replace(/[$,]/g, '')),
      selfOtherAnnualIncome: Number(
        (values.selfOtherAnnualIncome?.toString() ?? '').replace(/[$,]/g, '')
      ),
      spouseAnnualIncome: Number(
        (values.spouseAnnualIncome?.toString() ?? '').replace(/[$,]/g, '')
      ),
      spouseOtherAnnualIncome: Number(
        (values.spouseOtherAnnualIncome?.toString() ?? '').replace(/[$,]/g, '')
      ),
      estimatedDonationsForYear: Number(
        (values.estimatedDonationsForYear?.toString() ?? '').replace(/[$,]/g, '')
      ),
      donatedItemValue: Number((values.donatedItemValue?.toString() ?? '').replace(/[$,]/g, '')),
      cashDonations: Number((values.cashDonations?.toString() ?? '').replace(/[$,]/g, '')),
      mileageValue: Number((values.mileageValue?.toString() ?? '').replace(/[$,]/g, '')),
      totalOtherItemizedDeduction: Number(
        (values.totalOtherItemizedDeduction?.toString() ?? '').replace(/[$,]/g, '')
      ),
    };
  };

  const onNext = async () => {
    const values = methods.getValues();
    const readyToMoveOn =
      values.taxFilingStatus !== undefined && values.taxFilingState !== undefined;
    const cleanedValues = cleanTaxInfoValues({ ...defaultValues, ...values });
    if (readyToMoveOn) {
      try {
        await formService.addOrUpdateSavedForm({
          toolName: SavedFormsKeyEnum.TaxBenefitsOfDonations,
          formData: cleanedValues,
        });
        const results = await commands.execute(
          Data.TaxDeduction.Commands.AddOrUpdateTaxData,
          Domain.mapGtbdFormDataToGtbdTaxData({
            ...cleanedValues,
            accountId: selfData?.accountId,
            participantId: participant?.self.participantId,
            personId: selfData?.personId,
            spouseId: spouseData?.spouseId,
          })
        );
        setActiveTaxId(results.id);
        setDefaultValues({ ...values, id: results.id });
        showMessage(t('TaxInfoSaved') as string, 'success');
        setTaxInfoIncomplete(false);
        setShowTaxInfo(false);
        setDisableDeductions(false);
        setShowDeductions(true);
      } catch (err) {
        console.error(err);
      }
    } else {
      if (!values.taxFilingStatus) methods.setError('taxFilingStatus', { message: 'Required' });
      if (!values.taxFilingState) methods.setError('taxFilingState', { message: 'Required' });
    }
  };

  const onAddInvestment = async () => {
    if (!defaultValues.id) {
      try {
        await onClick();
        const storedTaxData = await dispatcher.execute(
          Data.TaxDeduction.Queries.FindTaxByYear,
          taxYear
        );
        setDefaultValues((prev) => ({ ...prev, id: storedTaxData?.id }));
        setActiveInvestment({ taxDeductionId: storedTaxData?.id });
        setDrawerOpen(true);
      } catch (err) {
        if (err === 'totalOtherItemizedDeduction') {
          showMessage(t('PleaseFillOutRequiredFieldsFirst') as string, 'error');
        }
        methods.trigger('totalOtherItemizedDeduction');
        console.error('Error Retrieving TaxId before Adding Investments', err);
      }
    } else {
      setActiveInvestment({ taxDeductionId: defaultValues.id });
      setDrawerOpen(true);
    }
  };

  const onEditInvestment = (investment: Domain.GtdbTaxDeductionData) => {
    setActiveInvestment(investment);
    setDrawerOpen(true);
  };

  const removeAllInvestments = async () => {
    setActiveInvestment({});
    setWillDonateInvestments(false);
    await commands.execute(Data.TaxDeduction.Commands.DeleteAllInvestments, defaultValues.id);
    setInvestments([]);
  };

  const onRemoveInvestment = async (investment: Domain.GtdbTaxDeductionData) => {
    setActiveInvestment({});
    await commands.execute(Data.TaxDeduction.Commands.DeleteInvestment, {
      taxInfoId: defaultValues.id ?? activeTaxId,
      investmentId: investment.id,
    });
    showMessage(t('InvestmentRemoved') as string, 'success');
    setInvestments((prev) => prev?.filter((x) => x.id !== investment.id));
  };

  return (
    <Grid className='tool-form-container'>
      <TaxInvestmentDonationsDrawer
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        investment={activeInvestment}
        taxDeductionId={defaultValues.id ?? activeTaxId ?? 0}
        setInvestments={setInvestments}
      />
      <Modal
        title={t('RemoveAccountType', { type: t('Investment') }).toString()}
        primaryButtonText={t('NoKeepAccountType', { type: t('Item') }).toString()}
        secondaryButtonText={t('YesRemoveAccountType', { type: t('Item') }).toString()}
        swapButtonFunctionality
        open={modalOpen}
        setOpen={setModalOpen}
        handleSave={() => {
          if (activeInvestment) {
            onRemoveInvestment(activeInvestment);
          }
        }}>
        <Typography variant='p16' color='secondary'>
          {t('ThisWillRemoveAllTheInfoForThisInvestment')}
        </Typography>
        <Typography mt={2} variant='p16Bold' color='secondary'>
          {activeInvestment?.investmentName}
        </Typography>
        <Typography mt={1} variant='p16Bold' color='secondary'>
          {formatWholeDollars(activeInvestment?.currentTotalValue ?? 0)}
        </Typography>
      </Modal>
      <SelectInput<Domain.GtbdFormData>
        error={formState.errors.taxYear !== undefined}
        helperText={formState.errors.taxYear?.message?.toString()}
        onChange={(e) => onSelectTaxYear(Number(e.target.value))}
        placeholder='ChooseTaxYear'
        label='TaxYear'
        name='taxYear'>
        {yearsList.map((x) => (
          <MenuItem value={x.key}>{x.value}</MenuItem>
        ))}
      </SelectInput>

      <Card
        sx={{
          padding: '10px 26px',
          backgroundColor: taxYear ? Colors.tertiaryBase : Colors.disabledLight,
          border: `1px solid ${Colors.primaryLight}`,
          borderRadius: '10px',
        }}>
        <Grid direction='column'>
          <Row onClick={onClickTaxInfo} style={{ justifyContent: 'flex-start' }}>
            <Typography
              className='no-underline-clickable'
              variant='p18Bold'
              color={taxYear ? 'secondary' : Colors.disabledDark}
              mr={1}
              display='flex'
              justifyContent='space-between'>
              {t('TaxInfo')}
            </Typography>
            {showTaxInfo ? (
              <KeyboardArrowUpIcon fontSize='large' color='primary' />
            ) : (
              <KeyboardArrowDownIcon fontSize='large' color='primary' />
            )}
          </Row>

          {showTaxInfo && (
            <Grid mt={2} direction='column'>
              <Stack spacing={2}>
                <SliderPercent<Domain.GtbdFormData>
                  error={formState.errors.selfAnnualIncome !== undefined}
                  helperText={formState.errors.selfAnnualIncome?.message?.toString()}
                  label='GrossAnnualIncome'
                  name='selfAnnualIncome'
                  type='dollar'
                  defaultValue={defaultValues.selfAnnualIncome ?? 0}
                  initialMax={500000}
                  step={5000}
                />
                <SliderPercent<Domain.GtbdFormData>
                  error={formState.errors.selfOtherAnnualIncome !== undefined}
                  helperText={formState.errors.selfOtherAnnualIncome?.message?.toString()}
                  label='AnnualOtherIncome'
                  name='selfOtherAnnualIncome'
                  type='dollar'
                  defaultValue={defaultValues.selfOtherAnnualIncome}
                  initialMax={500000}
                  step={5000}
                />
                <SelectInput<Domain.GtbdFormData>
                  error={formState.errors.taxFilingStatus !== undefined}
                  helperText={formState.errors.taxFilingStatus?.message?.toString()}
                  label='TaxFilingStatus'
                  name='taxFilingStatus'
                  placeholder='ChooseTaxFilingStatus'
                  defaultValue={defaultValues.taxFilingStatus}>
                  {Domain.TaxFilingStatusOptions.map((x) => (
                    <MenuItem value={x.key} key={x.key}>
                      {t(convertToTranslationKey(x.value))}
                    </MenuItem>
                  ))}
                </SelectInput>
                <SelectInput<Domain.GtbdFormData>
                  error={formState.errors.taxFilingState !== undefined}
                  helperText={formState.errors.taxFilingState?.message?.toString()}
                  label='StateIFileIn'
                  name='taxFilingState'
                  placeholder='ChooseState'
                  defaultValue={defaultValues.taxFilingState}>
                  {Domain.UsStatesListItems.map((x) => (
                    <MenuItem value={x.key} key={x.key}>
                      {x.value}
                    </MenuItem>
                  ))}
                </SelectInput>
                {includeSpouse && (
                  <>
                    <SliderPercent<Domain.GtbdFormData>
                      error={formState.errors.spouseAnnualIncome !== undefined}
                      helperText={formState.errors.spouseAnnualIncome?.message?.toString()}
                      label='SpouseAnnualIncome'
                      name='spouseAnnualIncome'
                      type='dollar'
                      defaultValue={defaultValues.spouseAnnualIncome}
                      initialMax={500000}
                      step={5000}
                    />
                    <SliderPercent<Domain.GtbdFormData>
                      error={formState.errors.spouseAnnualOtherIncome !== undefined}
                      helperText={formState.errors.spouseAnnualOtherIncome?.message?.toString()}
                      label='SpouseOtherIncome'
                      name='spouseOtherAnnualIncome'
                      type='dollar'
                      defaultValue={defaultValues.spouseOtherAnnualIncome}
                      initialMax={500000}
                      step={5000}
                    />
                  </>
                )}
                <Grid container justifyContent='flex-end'>
                  <Button
                    label={taxInfoIncomplete ? 'Next' : 'Save'}
                    color='primary'
                    style={{ minWidth: 'fit-content', width: '35%' }}
                    onClick={onNext}
                  />
                </Grid>
              </Stack>
            </Grid>
          )}
        </Grid>
      </Card>

      <Card
        sx={{
          padding: '10px 26px',
          backgroundColor:
            taxYear && !disableDeductions ? Colors.tertiaryBase : Colors.disabledLight,
          border: `1px solid ${Colors.primaryLight}`,
          borderRadius: '10px',
        }}>
        <Grid direction='column'>
          <Row onClick={onClickDeductions} style={{ justifyContent: 'flex-start' }}>
            <Typography
              className='no-underline-clickable'
              variant='p18Bold'
              color={taxYear && !disableDeductions ? 'secondary' : Colors.disabledDark}
              display='flex'
              justifyContent='space-between'>
              {t('YourDeductions')}
            </Typography>
            {showDeductions && !disableDeductions ? (
              <KeyboardArrowUpIcon fontSize='large' color='primary' />
            ) : (
              <KeyboardArrowDownIcon fontSize='large' color='primary' />
            )}
          </Row>

          {showDeductions && !disableDeductions && (
            <Grid mt={2} direction='column'>
              <Stack spacing={2}>
                <ToggleRadioButtonGroup<Domain.GtbdFormData>
                  error={formState.errors.itemizeDeductions !== undefined}
                  helperText={formState.errors.itemizeDeductions?.message?.toString()}
                  label='WillYouItemize'
                  name='itemizeDeductions'
                  defaultValue={
                    defaultValues.itemizeDeductions === true
                      ? 'true'
                      : defaultValues.itemizeDeductions === false
                      ? 'false'
                      : undefined
                  }
                  row
                  onChange={(target) => {
                    setWillItemize(target.value === 'true');
                    if (target.value === 'false') {
                      setWillProvide(undefined);
                      setWillDonateInvestments(undefined);
                    } else {
                      setWillProvide(defaultValues.provideDonationDetails);
                      setWillDonateInvestments((investments?.length ?? 0) > 0);
                      methods.setValue(
                        'totalOtherItemizedDeduction',
                        defaultValues.totalOtherItemizedDeduction
                      );
                    }
                  }}
                  items={[
                    { label: 'Yes', value: 'true' },
                    { label: 'No', value: 'false' },
                  ]}
                />
                {willItemize === true && (
                  <ToggleRadioButtonGroup<Domain.GtbdFormData>
                    error={formState.errors.provideDonationDetails !== undefined}
                    helperText={formState.errors.provideDonationDetails?.message?.toString()}
                    label='WillYouProvide'
                    name='provideDonationDetails'
                    defaultValue={
                      defaultValues.provideDonationDetails === true
                        ? 'true'
                        : defaultValues.provideDonationDetails === false
                        ? 'false'
                        : undefined
                    }
                    row
                    onChange={(target) => {
                      setWillProvide(target.value === 'true');
                      if (target.value === 'false') {
                        methods.setValue(
                          'totalOtherItemizedDeduction',
                          defaultValues.totalOtherItemizedDeduction
                        );
                      }
                    }}
                    items={[
                      { label: 'Yes', value: 'true' },
                      { label: 'No', value: 'false' },
                    ]}
                  />
                )}
                {(willItemize === false || willProvide === false) && (
                  <SliderPercent<Domain.GtbdFormData>
                    error={formState.errors.estimatedDonationsForYear !== undefined}
                    helperText={formState.errors.estimatedDonationsForYear?.message?.toString()}
                    label='EstimatedDonationsThisYear'
                    name='estimatedDonationsForYear'
                    type='dollar'
                    defaultValue={defaultValues.estimatedDonationsForYear}
                    initialMax={10000}
                    step={100}
                  />
                )}
                {willProvide && (
                  <>
                    <SliderPercent<Domain.GtbdFormData>
                      error={formState.errors.donatedItemValue !== undefined}
                      helperText={formState.errors.donatedItemValue?.message?.toString()}
                      label='ValueDonated'
                      name='donatedItemValue'
                      type='dollar'
                      defaultValue={defaultValues.donatedItemValue}
                      initialMax={10000}
                      step={100}
                    />
                    <SliderPercent<Domain.GtbdFormData>
                      error={formState.errors.cashDonations !== undefined}
                      helperText={formState.errors.cashDonations?.message?.toString()}
                      label='Cash'
                      name='cashDonations'
                      type='dollar'
                      defaultValue={defaultValues.cashDonations}
                      initialMax={10000}
                      step={100}
                    />
                    <SliderPercent<Domain.GtbdFormData>
                      error={formState.errors.mileageValue !== undefined}
                      helperText={formState.errors.mileageValue?.message?.toString()}
                      label='MileageDeduction'
                      name='mileageValue'
                      type='dollar'
                      defaultValue={defaultValues.mileageValue}
                      helpContext={
                        <MileageHelpContent taxYear={taxYear ?? 0} charityMileage={0.14} />
                      }
                      initialMax={1000}
                      step={50}
                    />
                    <ToggleRadioButtonGroup
                      error={formState.errors.willDonateInvestments !== undefined}
                      helperText={formState.errors.willDonateInvestments?.message?.toString()}
                      label='WillYouDonate'
                      name='willDonateInvestments'
                      defaultValue={
                        willDonateInvestments ? 'true' : defaultValues.id ? 'false' : undefined
                      }
                      row
                      onChange={(target) => {
                        if (target.value === 'false') {
                          removeAllInvestments();
                        }
                        setWillDonateInvestments(target.value === 'true');
                      }}
                      items={[
                        { label: 'Yes', value: 'true' },
                        { label: 'No', value: 'false' },
                      ]}
                    />
                    {willDonateInvestments && (
                      <>
                        {(investments?.length ?? 0) === 0 ? (
                          <Grid
                            pl={4}
                            width='100%'
                            display='inline-flex'
                            justifyContent='space-between'
                            flexDirection='row'
                            className='add-investment'
                            style={{ cursor: 'pointer', borderRadius: '10px', padding: '10px' }}
                            onClick={onAddInvestment}>
                            <Typography variant='p16Bold' color='primary'>
                              {t('AddAnInvestment')}
                            </Typography>
                            <IconButton sx={{ padding: '0' }}>
                              <Add color='primary' fontSize='large' />
                            </IconButton>
                          </Grid>
                        ) : (
                          <Grid
                            className='add-investment-tax-benefits'
                            direction='row'
                            justifyContent='flex-start'
                            display='flex'>
                            <Typography mr={1} variant='p16Bold' color='primary'>
                              {t('InvestmentsCount', { count: investments?.length })}
                            </Typography>
                            <IconButton
                              className='show-on-hover'
                              sx={{ padding: 0 }}
                              onClick={onAddInvestment}
                              disableRipple>
                              <Svgs.IconAdd />
                            </IconButton>
                          </Grid>
                        )}
                        {investments?.map((investment) => {
                          return (
                            <Grid
                              className='investments-giving-row'
                              direction='row'
                              justifyContent='flex-start'
                              display='flex'>
                              <IconButton
                                className='show-on-hover'
                                sx={{ padding: 0 }}
                                onClick={() => {
                                  setActiveInvestment(investment);
                                  setModalOpen(true);
                                }}
                                disableRipple>
                                <Svgs.IpmIconDelete />
                              </IconButton>
                              <Grid
                                width='100%'
                                onClick={() => onEditInvestment(investment)}
                                direction='row'
                                display='flex'
                                sx={{ cursor: 'pointer', justifyContent: 'space-between' }}>
                                <Typography width='70%' ml={1} variant='p16Bold' color='secondary'>
                                  {investment.investmentName}
                                </Typography>
                                <Typography width='20%' variant='p16' color='secondary'>
                                  {formatWholeDollars(investment.currentTotalValue ?? 0)}
                                </Typography>
                              </Grid>
                            </Grid>
                          );
                        })}
                      </>
                    )}
                  </>
                )}
                {(willProvide === false || willItemize === true) && (
                  <SliderPercent<Domain.GtbdFormData>
                    error={formState.errors.totalOtherItemizedDeduction !== undefined}
                    helperText={formState.errors.totalOtherItemizedDeduction?.message?.toString()}
                    label='AllOtherItemized'
                    name='totalOtherItemizedDeduction'
                    type='dollar'
                    defaultValue={defaultValues.totalOtherItemizedDeduction}
                    initialMax={100000}
                    step={1000}
                  />
                )}
              </Stack>
            </Grid>
          )}
        </Grid>
      </Card>

      <Grid container justifyContent='flex-end'>
        <Button
          label={results ? 'Recalculate' : 'Calculate'}
          disabled={!taxYear || taxInfoIncomplete}
          color='primary'
          style={{ minWidth: 'fit-content', width: '35%' }}
          onClick={onClick}
        />
      </Grid>
    </Grid>
  );
};

interface MileageHelpContentProps {
  taxYear: number;
  charityMileage: number;
}
const MileageHelpContent: React.FC<MileageHelpContentProps> = ({ taxYear, charityMileage }) => {
  return (
    <Grid>
      <Typography variant='p16Bold' color='secondary'>
        {t('MileageDeduction')}
      </Typography>
      <Typography variant='p16' color='secondary' mt={1}>
        {t('ForTaxYearTheIrsAllowsYouToDeductMileage', { taxYear, charityMileage })}
      </Typography>
      <Typography variant='p16Bold' color='secondary' mt={1} mb={1}>
        {t('IfYouDrove1000MilesYouWouldEnterMileage', { charityMileage: 1000 * charityMileage })}
      </Typography>
      <Grid
        sx={{
          padding: '10px',
          border: `solid 1px ${Colors.primaryLighter}`,
          borderRadius: '10px',
          backgroundColor: Colors.secondaryLighter,
          boxShadow: 'none',
        }}>
        <Stack spacing={1}>
          <Row style={{ justifyContent: 'space-between' }}>
            <Typography variant='p16' color='secondary'>
              {t('MileageForThisTaxYear')}
            </Typography>
            <Typography variant='p16Bold' color='primary'>
              1000
            </Typography>
          </Row>
          <Row style={{ justifyContent: 'space-between' }}>
            <Typography variant='p16' color='secondary'>
              {t('DeductionRate')}
            </Typography>
            {/* eslint-disable-next-line i18next/no-literal-string */}
            <Typography variant='p16Bold' color='primary'>
              X ${charityMileage}
            </Typography>
          </Row>
          <Divider sx={{ borderColor: Colors.primaryLight }} />

          <Row style={{ justifyContent: 'space-between' }}>
            <Typography variant='p16' color='secondary'>
              {t('TotalDeduction')}
            </Typography>
            <Typography variant='p16Bold' color='primary'>
              ${1000 * charityMileage}
            </Typography>
          </Row>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default TaxBenefitsCalculatorForm;
