import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, TableContainer, Typography } from '@mui/material';
import { LayoutContext, LayoutMeta, withLayoutMeta } from '../../types/LayoutMeta';
import { SpendingSummaryLayoutMeta } from './SpendingSummaryLayout';
import { Data, Hooks } from '@3nickels/data-modules';
import SpendingSummaryChart from './SpendingSummaryChart';
import { Colors } from '../../themes';
import SpendingSummaryBreakdown from './SpendingSummaryBreakdown';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import SpendingSummaryTotal from './SpendingSummaryTotal';
import { Svgs } from '../../assets/svg';
import { t } from 'i18next';

export const TransactionsSummaryChartColors = [
  Colors.primaryBase,
  Colors.secondaryLight,
  Colors.accentBase,
  Colors.primaryLight,
  Colors.alternateDark,
  Colors.secondaryBase,
  Colors.accentLight,
  Colors.alternateLight,
];

const SpendingSummaryOverview: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { setMeta } = useOutletContext<LayoutContext>();
  const { loadingSummary, setTransactionsCategories } = Hooks.useTransactionsSummary();
  const transactionCategories = Hooks.useTopSpendingCategories();
  const inBudgetDetails = useMemo(() => pathname.includes('/budget-details/'), [pathname]);

  const selectCategory = (category?: Data.Transactions.TransactionCategoryEnum) => {
    if (category) {
      setTransactionsCategories([category]);
    }
    navigate('transactions');
  };

  useEffect(() => {
    setMeta(() => ({
      onBack: (navigate) => (inBudgetDetails ? navigate(-1) : navigate('/')),
    }));
  }, [navigate, pathname]);

  return (
    <Grid>
      <Typography variant='p30Bold' color='primary'>
        {t('MonthlySpendingOverview')}
      </Typography>
      {!loadingSummary && transactionCategories.length === 0 ? (
        <EmptySpendingSummaryOverview />
      ) : (
        <Grid display='flex'>
          <Grid>
            <SpendingSummaryChart
              transactionsSummary={transactionCategories}
              selectCategory={selectCategory}
              colors={TransactionsSummaryChartColors}
              repeatColorIndex={5}
            />
          </Grid>
          <Grid sm={1} />
          <SpendingSummaryBreakdown
            transactionsSummary={transactionCategories}
            onSelectCategory={selectCategory}
            colors={TransactionsSummaryChartColors}
            repeatColorIndex={5}
          />
        </Grid>
      )}
    </Grid>
  );
};

const EmptySpendingSummaryOverview: React.FC = () => {
  return (
    <Grid display='flex' height='350px'>
      <Grid display='flex' flexDirection='column' width='60%' rowGap='20px' flexGrow={1}>
        <SpendingSummaryTotal transactionsSummary={[]} />
        <TableContainer
          className='spending-overview-categories-container'
          style={{
            padding: '30px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            columnGap: '90px',
          }}>
          <Svgs.ArtCoffee />
          <Grid
            display='flex'
            flexDirection='column'
            alignItems='center'
            rowGap='10px'
            sm={5}
            lg={4}>
            <Typography variant='p16Bold' color='secondary' textAlign='center'>
              {t('WeCouldntFindAnyTransactions')}
            </Typography>
            <Typography variant='p16' color='secondary' textAlign='center'>
              {t('IfYouJustLinkedAnAccountGrapACoffee')}
            </Typography>
          </Grid>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

const meta = {
  showBack: true,
} satisfies LayoutMeta<SpendingSummaryLayoutMeta>;

export default withLayoutMeta(SpendingSummaryOverview, meta);
