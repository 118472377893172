import Typography from '@mui/material/Typography';
import Modal from '../../../../components/Modal';
import { t } from 'i18next';
import { useCallback, useEffect, useState } from 'react';
import { Card, Grid, Switch } from '@mui/material';
import { Data, Domain, Hooks } from '@3nickels/data-modules';
import { useLoading } from '../../../../hooks/useLoading';

interface CoversExpensesModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  creditCard?: Domain.CreditCardData;
}

export const CoversExpensesModal: React.FC<CoversExpensesModalProps> = (props) => {
  const commands = Hooks.useCommands();
  const { setLoading } = useLoading();
  const [coversExpenses, setCoversExpenses] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    setCoversExpenses(props.creditCard?.coversExpenses);
  }, [props.creditCard?.coversExpenses]);

  const coversExpensesHandler = useCallback(async () => {
    if (coversExpenses !== props.creditCard?.coversExpenses) {
      setLoading(true);
      try {
        await commands.execute(Data.CreditCards.Commands.SaveCreditCardCommand, {
          ...props.creditCard,
          coversExpenses: coversExpenses,
        });
        await commands.relay(Data.Budget.Commands.ReloadBudgetCache);
      } finally {
        setLoading(false);
      }
    }
  }, [commands, props.creditCard, coversExpenses]);

  return (
    <Modal
      title={t('CoversBudgetExpenses?') as string}
      primaryButtonText={t('Save').toString()}
      secondaryButtonText={t('Close').toString()}
      handleSave={coversExpensesHandler}
      {...props}>
      <Grid container rowGap='20px'>
        <Typography variant='p16' color='secondary'>
          {t('DoYouUseThisCardForEverydayPurchases?')}
        </Typography>
        <Typography variant='p16' color='secondary' fontStyle='italic'>
          {t('IfYouUseThisCardForGroceries')}
        </Typography>
        <Card className='covers-expenses-card'>
          <Typography variant='p16Bold' color='primary'>
            {t('CoversBudgetExpenses?')}
          </Typography>
          <Switch
            color='primary'
            onChange={(e) => setCoversExpenses(e.target.checked)}
            checked={coversExpenses}
          />
        </Card>
      </Grid>
    </Modal>
  );
};
