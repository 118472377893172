import React, { useEffect } from 'react';
import { BudgetDetailsManager } from './BudgetDetailsManager';
import { BudgetDetailsLayoutMeta } from './BudgetDetailsLayout';
import { LayoutContext, LayoutMeta, withLayoutMeta } from '../../types/LayoutMeta';
import { useNeedsOnboarding } from '../../hooks';
import { useOutletContext } from 'react-router-dom';
import { useAccountOrigin } from '../../hooks/useAccountOrigin';

const BudgetDetailsHomeWrapper: React.FC = () => {
  const { setMeta } = useOutletContext<LayoutContext>();
  const needsOnboarding = useNeedsOnboarding();
  const { setAccountOrigin } = useAccountOrigin();

  useEffect(() => {
    setAccountOrigin('budget-details');
  }, []);

  useEffect(() => {
    setMeta((prev) => ({
      ...prev,
      showBack: needsOnboarding,
    }));
  }, [needsOnboarding]);

  return <BudgetDetailsManager />;
};

const meta = {
  showNext: false,
  headerVariant: 'home',
} satisfies LayoutMeta<BudgetDetailsLayoutMeta>;

export default withLayoutMeta(BudgetDetailsHomeWrapper, meta);
