import { Grid, Typography } from '@mui/material';
import { Spacing } from '../../../../themes';
import { t } from 'i18next';
import { LayoutMeta, withLayoutMeta } from '../../../../types/LayoutMeta';
import { MedicalBenefitLayoutMeta } from './MedicalBenefitLayout';
import { Data, Hooks } from '@3nickels/data-modules';
import { Loading } from '../../../../hooks/useLoading';
import { Row } from '../../../../components';
import React, { useEffect } from 'react';
import { KeyTerms, PlanHelpModal, PlanRankingCard } from './components';
import { Svgs } from '../../../../assets/svg';
import { mapAbbrevToDisplayAbbrev, mapShortNameToLongName } from './maps';
import { useNavigate } from 'react-router';

const MedicalBenefitSummaryViewWrapper: React.FC = () => {
  const { suggestedBenefits } = Hooks.useMedicalBenefitsContext();
  const navigate = useNavigate();

  useEffect(() => {
    const handleBeforeUnload = () => {
      sessionStorage.setItem('pageRefreshed', 'true');
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    const isRefreshed = sessionStorage.getItem('pageRefreshed');
    if (isRefreshed) {
      sessionStorage.removeItem('pageRefreshed');
      navigate('/tools/medical/medical-benefit-selector-tool');
    }
  }, []);

  if (suggestedBenefits === undefined || suggestedBenefits.topPlanOptions.length === 0) {
    return <Loading />;
  } else if (suggestedBenefits.topPlanOptions.length === 1) {
    return (
      <SingleMedicalBenefitSummaryView
        topPlanOption={suggestedBenefits.topPlanOptions[0]}
        planHelpTip={suggestedBenefits.planHelpTip}
      />
    );
  } else {
    return (
      <MultipleMedicalBenefitSummaryView
        topPlanOptions={suggestedBenefits.topPlanOptions}
        planHelpTip={suggestedBenefits.planHelpTip}
      />
    );
  }
};

interface MultipleMedicalBenefitSummaryViewProps {
  topPlanOptions: Data.MedicalBenefits.HealthPlanOptions[];
  planHelpTip: Data.MedicalBenefits.HelpTipOptions;
}

const MultipleMedicalBenefitSummaryView: React.FC<MultipleMedicalBenefitSummaryViewProps> = ({
  topPlanOptions,
  planHelpTip,
}) => {
  return (
    <Grid display='flex' flexDirection='column' rowSpacing={Spacing.lg} justifyContent='flex-start'>
      <Typography mb={1} variant='p30Bold' color='primary'>
        {t('OurRankings')}
      </Typography>
      <Row style={{ justifyContent: 'space-between' }}>
        <Typography sx={{ width: '60%' }} variant='p16' color='secondary'>
          {t('TwoPlanTypesMightBeBest')}
        </Typography>
        <PlanHelpModal planHelpTip={planHelpTip} />
      </Row>
      <Row
        style={{
          justifyContent: 'space-between',
          alignItems: 'stretch',
          marginBottom: Spacing.xxl,
          marginTop: Spacing.xxl,
          gap: 1,
        }}>
        <PlanRankingCard rank={1} planOption={topPlanOptions[0]} />
        <PlanRankingCard
          rank={2}
          planOption={topPlanOptions[1]}
          style={{ marginLeft: Spacing.xs }}
        />
      </Row>

      <Svgs.ArtPiggyBank3 style={{ alignSelf: 'center' }} />
      <Typography
        mt={1}
        mb={4}
        sx={{ textAlign: 'center', width: '35%', alignSelf: 'center' }}
        variant='p14Bold'
        color='primary'>
        {t('RegardlessOfPlanChoice')}
      </Typography>

      <KeyTerms />
    </Grid>
  );
};

interface SingleMedicalBenefitSummaryViewProps {
  topPlanOption: Data.MedicalBenefits.HealthPlanOptions;
  planHelpTip: Data.MedicalBenefits.HelpTipOptions;
}

const SingleMedicalBenefitSummaryView: React.FC<SingleMedicalBenefitSummaryViewProps> = ({
  topPlanOption,
  planHelpTip,
}) => {
  const planShortText = mapAbbrevToDisplayAbbrev(topPlanOption);
  const { longName, article, planText } = mapShortNameToLongName(topPlanOption);

  return (
    <Grid display='flex' flexDirection='column' rowSpacing={Spacing.lg}>
      <Typography mb={1} variant='p30Bold' color='primary'>
        {t('AboutYourPlan')}&nbsp;{planShortText}
      </Typography>
      <Typography variant='p16' color='secondary'>
        {t(`YouHaveAccessToThisPlan`, {
          article: t(article),
          longName: t(longName),
          plan: t(planText),
        })}
      </Typography>

      <Row
        style={{
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: Spacing.xxl,
          marginTop: Spacing.xxl,
          gap: 1,
        }}>
        <PlanRankingCard planOption={topPlanOption} />
        <PlanHelpModal planHelpTip={planHelpTip} />
      </Row>

      <KeyTerms />
    </Grid>
  );
};

const meta = {
  showNext: true,
  nextLocaleKey: 'Done',
  stepNumber: 6,
} satisfies LayoutMeta<MedicalBenefitLayoutMeta>;

export default withLayoutMeta(MedicalBenefitSummaryViewWrapper, meta);
