import { Domain, formatWholeDollars } from '@3nickels/data-modules';
import { Grid } from '@mui/material';
import { GoalBannerIcon } from '../../../../goals';
import dayjs from 'dayjs';
import { CollapsiblePanel } from '../../../../../components/CollapsiblePanel';
import { GoalCardProps } from './Types';
import GoalCardHeader from './components/GoalCardHeader';
import GoalCardSummary from './components/GoalCardSummary';
import GoalCardDetails from './components/GoalCardDetails';
import { t } from 'i18next';

const SavingGoalCard: React.FC<GoalCardProps> = ({ goal }) => {
  const { goalPlan, advisedGoalPlan, goalStatus } = goal;

  const getApplicableTypes = (type: Domain.GoalTypeEnum | undefined) => {
    switch (type) {
      case Domain.GoalTypeEnum.Car:
      case Domain.GoalTypeEnum.College:
      case Domain.GoalTypeEnum['Emergency Fund']:
      case Domain.GoalTypeEnum.Gift:
      case Domain.GoalTypeEnum.House:
      case Domain.GoalTypeEnum.Vacation:
      case Domain.GoalTypeEnum.Other:
        return true;
      default:
        return false;
    }
  };

  const isApplicableType = getApplicableTypes(goalPlan.goalType);
  const shouldShow = isApplicableType
    ? goalStatus === Domain.GoalStatusEnum.PartiallyFunded && advisedGoalPlan.monthlySavings !== 0
    : goalStatus === Domain.GoalStatusEnum.PartiallyFunded;

  return (
    <CollapsiblePanel
      variant='shadow'
      header={<GoalCardHeader goal={goal} />}
      expandIconPosition='end'
      icon={<GoalBannerIcon type={goalPlan.goalType} />}>
      <Grid container gap='20px'>
        <GoalCardSummary
          mainContent={<strong>{formatWholeDollars(goalPlan.amountNeeded ?? 0)}</strong>}
          subContent={t('SavingsGoalAmount')}
        />
        {(goalStatus === Domain.GoalStatusEnum.OnTarget || shouldShow) && (
          <GoalCardDetails
            details={[
              {
                key: t('MonthlySavings'),
                value: formatWholeDollars(advisedGoalPlan.monthlySavings ?? 0),
              },
              {
                key: t('TargetDate'),
                value: dayjs(advisedGoalPlan.goalDate).format('MM/DD/YYYY'),
              },
            ]}
          />
        )}
      </Grid>
    </CollapsiblePanel>
  );
};

export default SavingGoalCard;
