import { useTranslation } from 'react-i18next';
import { SummaryCardProps } from '../../Types';
import { Grid, Typography } from '@mui/material';
import CardDisplay from '../../../../components/panels/CardDisplay';
import { capitalizeFirstLetter, formatWholeDollars } from '../../../../helpers/utilityFunctions';
import { Data } from '@3nickels/data-modules';

export interface CarBasicsCardProps extends SummaryCardProps {
  carBasics: Data.Cars.CarBasicFormData;
  includeSpouse?: boolean;
}

const CarBasicsCard: React.FC<CarBasicsCardProps> = ({ onEdit, carBasics, includeSpouse }) => {
  const { t } = useTranslation();

  return (
    <CardDisplay headerKey='CarBasics' actionContent='Edit' actionClick={onEdit}>
      <Grid container spacing={2}>
        <Grid item xs>
          <Typography variant='h3' component='h3' color='primary'>
            {t('CarNickname')}
          </Typography>
          <Typography color='secondary'>{carBasics.name}</Typography>
        </Grid>
        {includeSpouse && (
          <Grid item xs>
            <Typography variant='h3' component='h3' color='primary'>
              {t('AccountOwner')}
            </Typography>
            <Typography color='secondary'>
              {t(capitalizeFirstLetter(carBasics?.owner ?? ''))}
            </Typography>
          </Grid>
        )}
        <Grid item xs>
          <Typography variant='h3' component='h3' color='primary'>
            {t('CurrentCarValue')}
          </Typography>
          <Typography color='secondary'>{formatWholeDollars(carBasics?.value ?? 0)}</Typography>
        </Grid>
        <Grid item xs />
        <Grid item xs />
        {!includeSpouse && <Grid item xs />}
      </Grid>
    </CardDisplay>
  );
};

export default CarBasicsCard;
