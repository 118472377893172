/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import { t } from 'i18next';
import { Grid, MenuItem, Typography } from '@mui/material';
import SliderPercent from '../../../../components/form/SliderPercent';
import { Domain } from '@3nickels/data-modules';
import { UseFormReturn } from 'react-hook-form';
import SelectInput from '../../../../components/form/SelectInput';
import { Button } from '../../../../components/buttons/Button';
import { cleanWholeNumberStr } from '../../../../helpers/utilityFunctions';

type InflationCalculatorFormProps = {
  methods: UseFormReturn;
  defaultValues: Domain.GoalsInflationData;
  results: Domain.GoalsInflationResultData | undefined;
  onSubmit: (values: Domain.GoalsInflationData) => Promise<void>;
};

const InflationCalculatorForm: React.FC<InflationCalculatorFormProps> = ({
  methods,
  defaultValues,
  results,
  onSubmit,
}) => {
  const { formState, setValue } = methods;

  const onClick = methods.handleSubmit((data) => {
    // TODO add loading spinner for calculate button
    onSubmit(data);
  });

  useEffect(() => {
    if (defaultValues) {
      setValue('horizon', defaultValues.horizon);
    }
  }, [defaultValues]);

  return (
    <Grid className='tool-form-container'>
      <SliderPercent<Domain.GoalsInflationData>
        error={formState.errors.baseValue !== undefined}
        helperText={formState.errors.baseValue?.message?.toString()}
        name='baseValue'
        label='CurrentSavingsBalance'
        type='dollar'
        min={0}
        initialMax={100000}
        max={10000000}
        step={1000}
        defaultValue={defaultValues.baseValue}
      />
      <SliderPercent<Domain.GoalsInflationData>
        error={formState.errors.annualContribution !== undefined}
        helperText={formState.errors.annualContribution?.message?.toString()}
        name='annualContribution'
        label='HowMuchCanYouSavePerMonth'
        type='dollar'
        min={0}
        initialMax={1000}
        max={10000000}
        step={50}
        defaultValue={defaultValues.annualContribution}
      />
      <SliderPercent<Domain.GoalsInflationData>
        error={formState.errors.growthRate !== undefined}
        helperText={formState.errors.growthRate?.message?.toString()}
        name='growthRate'
        label='YourSavingsInterestRate'
        type='percent'
        precision={3}
        defaultValue={defaultValues.growthRate}
      />
      <SelectInput<Domain.GoalsInflationData>
        error={formState.errors.horizon !== undefined}
        helperText={formState.errors.horizon?.message?.toString()}
        name='horizon'
        label='CalculationDateRange'
        placeholder='ChooseYear'
        prefixContent={
          <Typography variant='p16Bold' color='secondary'>{`${Domain.currentYear} -`}</Typography>
        }
        prefixWidth={15}>
        {Domain.calcDateRangeData.map((x) => {
          const key = typeof x.key === 'string' ? cleanWholeNumberStr(x.key) : x.key;
          return (
            <MenuItem value={x.key}>
              {x.value + ` (${x.key} ${key > 1 ? t('years') : t('year')})`}
            </MenuItem>
          );
        })}
      </SelectInput>

      <Grid container justifyContent='flex-end'>
        <Button
          label={results ? 'Recalculate' : 'Calculate'}
          color='primary'
          style={{ minWidth: 'fit-content', width: '35%' }}
          onClick={onClick}
        />
      </Grid>
    </Grid>
  );
};

export default InflationCalculatorForm;
