import React from 'react';
import {
  Grid,
  Skeleton,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import SpendingSummaryTimeframeSelector from '../SpendingSummaryTimeframeSelector';

const SkeletonSpendingSummaryBreakdown: React.FC = () => {
  return (
    <Grid display='flex' flexDirection='column' width='60%' rowGap='20px' flexGrow={1}>
      <Grid display='flex' justifyContent='flex-end' columnGap='30px' alignItems='center'>
        <SpendingSummaryTimeframeSelector />
        {/* eslint-disable-next-line i18next/no-literal-string */}
        <Typography fontFamily='Montserrat' variant='p40Bold' color='secondary'>
          $X,XXX
        </Typography>
      </Grid>
      <TableContainer className='spending-overview-categories-container'>
        <Table className='spending-overview-categories'>
          <TableBody className='spending-overview-view'>
            <SkeletonSpendingSummaryBreakdownRow />
            <SkeletonSpendingSummaryBreakdownRow />
            <SkeletonSpendingSummaryBreakdownRow />
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

const SkeletonSpendingSummaryBreakdownRow: React.FC = () => {
  return (
    <TableRow className='category-row' style={{ paddingTop: '10px', paddingBottom: '10px' }}>
      <Grid className='category-section'>
        <Skeleton variant='circular' width={20} />
        <Skeleton variant='rectangular' width='50%' />
      </Grid>
      <Grid className='amount-section' display='flex' width='50%'>
        <Grid>
          <Skeleton variant='rectangular' width='50%' />
        </Grid>
        <Grid>
          <Skeleton variant='rectangular' width='50%' />
        </Grid>
      </Grid>
    </TableRow>
  );
};

export default SkeletonSpendingSummaryBreakdown;
