import { Button, Card, Collapse, Grid, Typography } from '@mui/material';
import Spacer from '../../../../components/Spacer';
import TextInput from '../../../../components/form/TextInput';
import { Data, Hooks } from '@3nickels/data-modules';
import { useForm, useFormContext } from 'react-hook-form';
import FormContent from '../../../../components/form/FormContent';
import { useLoading } from '../../../../hooks/useLoading';
import { cleanWholeNumberStr } from '../../../../helpers/utilityFunctions';
import { useEffect, useState } from 'react';
import { WizardStep } from '@aesop-fables/scrinium/lib/Wizards';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMessage } from '../../../../hooks/useMessage';
import { useTranslation } from 'react-i18next';
import { BootstrapHeader } from '../../../../components/Modal';
import { SpouseData } from '@3nickels/data-modules/lib/domain';
import { t } from 'i18next';
import { Colors } from '../../../../themes';

interface SpouseIncomeDropdownWrapperProps {
  editing?: boolean;
  spouseEligibleZeroIncome: boolean;
}

export const SpouseIncomeEditView: React.FC<SpouseIncomeDropdownWrapperProps> = ({
  editing,
  spouseEligibleZeroIncome,
}) => {
  const { currentStep, wizard } =
    Hooks.usePersonalDetailsWizard<Data.People.BasicFinancialFormData>();
  useEffect(() => {
    wizard.selectStep('financialInfo');
  }, []);

  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

  return (
    <Grid container justifyContent='center' mt={3}>
      <Grid item sm={editing ? 10 : 6}>
        {!dropdownOpen && spouseEligibleZeroIncome && (
          <Card className='content-card'>
            <Grid justifyContent='space-between'>
              <Typography color={Colors.secondaryBase}>{t('ToMakeContributions')}</Typography>
              <Grid mt={2} textAlign='center'>
                <Button onClick={() => setDropdownOpen(true)} variant='ghost'>
                  {t('AddSpousesIncome')}
                </Button>
              </Grid>
            </Grid>
          </Card>
        )}
        {dropdownOpen && (
          <SpouseIncomeDropdown
            currentStep={currentStep}
            wizard={wizard}
            dropdownOpen={dropdownOpen}
            setDropdownOpen={setDropdownOpen}
          />
        )}
      </Grid>
    </Grid>
  );
};

interface SpouseIncomeDropdownProps {
  currentStep: WizardStep<Data.People.BasicFinancialFormData, Data.People.NoOpParams>;
  wizard: Data.People.IPersonWizard;
  dropdownOpen: boolean;
  setDropdownOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const SpouseIncomeDropdown: React.FC<SpouseIncomeDropdownProps> = ({
  currentStep,
  wizard,
  dropdownOpen,
  setDropdownOpen,
}) => {
  const { t } = useTranslation();
  const spouseData = Hooks.useSpouseData();
  const { setLoading } = useLoading();
  const { showMessage } = useMessage();
  const personData = Hooks.usePersonData();
  const methods = useForm<Data.People.BasicFinancialFormData>({
    defaultValues: {
      ...currentStep?.model,
    },
    resolver: yupResolver(
      Yup.object({
        spouseAnnualIncome: Yup.string()
          .required(t('Required') as string)
          .test('spouseAnnualIncome', t('Required') as string, (income) => {
            const cleanNumber = cleanWholeNumberStr(income);
            return cleanNumber > 0;
          }),
      })
    ),
  });

  const onSubmit = methods.handleSubmit(async (values) => {
    setLoading(true);
    const spouseAnnualIncome = cleanWholeNumberStr(values.spouseAnnualIncome?.toString() ?? '');
    currentStep.save({
      ...personData,
      spouseAnnualIncome,
      spouseAnnualOtherIncome: spouseData?.otherAnnualIncome,
      spouseCreditScore: spouseData?.creditScore,
      spouseEligibleForHsa: spouseData?.eligibleForHsa,
      spouseSelfEmployed: spouseData?.selfEmployed,
    });
    await wizard.commitStep('financialInfo');

    setLoading(false);
    setDropdownOpen(false);
    showMessage(t('SpousesIncomeSaved') as string);
  });

  return (
    <FormContent formProviderProps={methods}>
      <form onSubmit={onSubmit}>
        <Card className='dropdown-card'>
          <Collapse in={dropdownOpen}>
            <div style={{ float: 'right' }}>
              <BootstrapHeader id='header' onClose={() => setDropdownOpen(false)} />
            </div>

            <Grid item p={3}>
              <Typography variant='p16Bold' color='secondary'>
                {t('AddSpousesIncome')}
              </Typography>
              <Spacer height='xxs' />
              <Typography variant='p16' color='secondary'>
                {t('ToMakeContributions')}
              </Typography>
              <Spacer height='xs' />
              <SpouseIncomeForm spouseData={spouseData} />

              <Spacer height='md' />
              <Grid container justifyContent='center'>
                <Button type='submit' style={{ width: '150px' }}>
                  {t('Save')}
                </Button>
              </Grid>
            </Grid>
            <Spacer height='xxs' />
          </Collapse>
        </Card>
      </form>
    </FormContent>
  );
};

export default SpouseIncomeDropdown;

interface SpouseIncomeFormProps {
  spouseData?: SpouseData;
}

const SpouseIncomeForm: React.FC<SpouseIncomeFormProps> = ({ spouseData }) => {
  const { formState } = useFormContext();

  return (
    <Grid item width='100%'>
      <TextInput<Data.People.BasicFinancialFormData>
        error={formState.errors.spouseAnnualIncome !== undefined}
        helperText={formState.errors.spouseAnnualIncome?.message?.toString()}
        defaultValue={spouseData?.annualIncome}
        inputProps={{ maxLength: 11 }}
        name='spouseAnnualIncome'
        label='SpouseAnnualIncome'
        type='dollar'
        placeholder='$0'
        autoFocus
      />
    </Grid>
  );
};
