import { Data, Domain } from '@3nickels/data-modules';
import { Grid, Typography, Box } from '@mui/material';
import { Svgs } from '../../../assets/svg';
import { GroupEdit } from '../../../components/GroupEdit';
import { useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { MutatorState } from '@aesop-fables/scrinium';
import { useLoading } from '../../../hooks/useLoading';
import { useTranslation } from 'react-i18next';
import { InvestmentSvg } from '../../../components/images/InvestmentSvg';
import { cleanWholeNumberStr } from '../../../helpers/utilityFunctions';
import { IServiceContainer } from '@aesop-fables/containr';
import { useServiceContainer } from '@aesop-fables/containr-react';
import { usePlaidCredentialRefresh } from '../../../hooks/usePlaidCredentialRefresh';
import RemoveAccountModal from '../RemoveAccountModal';

interface InvestmentAccountView {
  accounts: Domain.IInvestmentAccount[];
  deleteAccount: MutatorState<Domain.IInvestmentAccount>;
}

interface EditInvestmentAccountHandler {
  handle(
    account: Domain.IInvestmentAccount,
    navigate: NavigateFunction,
    container: IServiceContainer
  ): Promise<void>;
}

const editBrokerageHandler: EditInvestmentAccountHandler = {
  async handle(account, navigate, container) {
    const wizard = container.get<Data.InvestmentAccounts.InvestmentAccountWizard>(
      Data.InvestmentAccounts.InvestmentAccountServices.InvestmentAccountWizard
    );
    await wizard.start({ id: account.id ?? 0, type: Domain.PlanTypeEnum['Brokerage Account'] });
    navigate(`/account-details/brokerage-account/summary/${account.id}`);
  },
};

const editInvestmentAccountHandler: EditInvestmentAccountHandler = {
  async handle(account, navigate, container) {
    const wizard = container.get<Data.InvestmentAccounts.InvestmentAccountWizard>(
      Data.InvestmentAccounts.InvestmentAccountServices.InvestmentAccountWizard
    );
    await wizard.start({ id: account.id ?? 0, type: Domain.PlanTypeEnum['HSA'] });
    navigate(`/account-details/health-savings-account/summary/${account.id}`);
  },
};

const editCollegeSavingsPlansHandler: EditInvestmentAccountHandler = {
  async handle(account, navigate, container) {
    const wizard = container.get<Data.CollegeSavingsPlans.CollegeSavingsPlanWizard>(
      Data.CollegeSavingsPlans.CollegeSavingsPlanServices.CollegeSavingsPlanWizard
    );
    await wizard.start({ id: account.id ?? 0 });
    navigate(`/account-details/college-savings-plan/summary/${account.id}`);
  },
};

const editBusinessHandler: EditInvestmentAccountHandler = {
  async handle(account, navigate, container) {
    const wizard = container.get<Data.Business.BusinessWizard>(
      Data.Business.BusinessServices.BusinessWizard
    );
    await wizard.start({ id: account.id ?? 0 });
    navigate(`/account-details/business/summary/${account.id}`);
  },
};

const editOtherAssetHandler: EditInvestmentAccountHandler = {
  async handle(account, navigate, container) {
    const wizard = container.get<Data.OtherAssets.OtherAssetWizard>(
      Data.OtherAssets.OtherAssetServices.OtherAssetWizard
    );
    await wizard.start({ id: account.id ?? 0 });
    navigate(`/account-details/other-asset/summary/${account.id}`);
  },
};

const editRealEstateHandler: EditInvestmentAccountHandler = {
  async handle(account, navigate, container) {
    const wizard = container.get<Data.RealEstate.RealEstateWizard>(
      Data.RealEstate.HousingEntityServices.RealEstateWizard
    );
    await wizard.start({ id: account.id ?? 0, type: Domain.RealEstateTypeEnum.INVESTMENT });
    navigate(`/account-details/investment-real-estate/summary/${account.id}`);
  },
};

function getEditHandler(type: Domain.InvestmentAccountTypeEnum) {
  switch (type) {
    case Domain.InvestmentAccountTypeEnum.BrokerageAccount:
      return editBrokerageHandler;
    case Domain.InvestmentAccountTypeEnum.Business:
      return editBusinessHandler;
    case Domain.InvestmentAccountTypeEnum.CollegeSavingsPlan:
      return editCollegeSavingsPlansHandler;
    case Domain.InvestmentAccountTypeEnum.HSA:
      return editInvestmentAccountHandler;
    case Domain.InvestmentAccountTypeEnum.InvestmentRealEstate:
      return editRealEstateHandler;
    case Domain.InvestmentAccountTypeEnum.OtherAsset:
      return editOtherAssetHandler;
    default:
      throw new Error('Unsupported account type');
  }
}

export const InvestmentAccountView: React.FC<InvestmentAccountView> = ({
  accounts,
  deleteAccount,
}) => {
  const { t } = useTranslation();
  const { setLoading } = useLoading();
  const navigate = useNavigate();
  const container = useServiceContainer();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState<Domain.IInvestmentAccount>();
  const { setIdAndUpdateLogin } = usePlaidCredentialRefresh();

  const handleEdit = (x: Domain.IInvestmentAccount) => {
    const handler = getEditHandler(x.accountType);
    handler.handle(x, navigate, container);
  };

  const handleDeleteAccount = (account: Domain.IInvestmentAccount) => {
    setLoading(true);
    deleteAccount.action(account);
  };

  if (accounts.length === 0) return null;

  return (
    <>
      <RemoveAccountModal
        name={selectedAccount?.name}
        type={
          selectedAccount?.accountType
            ? Domain.InvestmentAccountTypeEnum[selectedAccount?.accountType]
            : 'InvestmentAccount'
        }
        variant='Investment'
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        handleSave={() => {
          if (selectedAccount) {
            handleDeleteAccount(selectedAccount);
          }
        }}
      />
      <Grid item xs={12} className='account-view'>
        <Typography
          className='account-type-header'
          display='flex'
          alignItems='center'
          color='primary'
          variant='p18Bold'>
          {InvestmentSvg({ fontSize: 'large' })}
          {t('InvestmentAccounts', { number: accounts.length })}
        </Typography>
        <Box>
          <GroupEdit<Domain.IInvestmentAccount>
            className='account-list'
            items={accounts}
            keyFn={(x) => `${x.id ?? 0}`}
            mainContentFn={(x) => (
              <Grid container>
                <Grid marginRight='10px'>
                  <Typography
                    className='hover-clickable'
                    color='secondary'
                    fontWeight='bold'
                    onClick={() => handleEdit(x)}>
                    {x.name}
                  </Typography>
                  <Grid direction='row' display='flex' alignItems='center'>
                    <Typography marginTop='5px' color='secondary' variant='p12'>
                      {x.account.linkedInstitution?.institutionName ?? t('ManuallyAdded')}
                    </Typography>

                    {x.account.linkedInstitution?.requiresLogin && (
                      <>
                        <Typography ml={'10px'} marginTop='5px' color='secondary' variant='p12'>
                          |
                        </Typography>
                        <Typography
                          onClick={() => {
                            setIdAndUpdateLogin(x.account.linkedInstitution?.accessId ?? 0);
                          }}
                          sx={{
                            '&:hover': {
                              textDecoration: 'underline',
                              cursor: 'pointer',
                            },
                          }}
                          ml={'10px'}
                          marginTop='5px'
                          color='error'
                          component='span'
                          variant='p12'>
                          {t('LoginExpired')}
                        </Typography>
                      </>
                    )}
                  </Grid>
                </Grid>
                <Grid className='account-view-status-icon' height='20px'>
                  {x.account.isLinked && (
                    <Grid display='flex' alignItems='center'>
                      <Svgs.LinkPaperClip />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}
            summaryFn={(x) => (
              <Grid>
                <Typography
                  color={cleanWholeNumberStr(x.summary ?? '') >= 0 ? 'secondary' : 'error'}>
                  {x.summary}
                </Typography>
              </Grid>
            )}
            needsAttention={(x) => x.account.linkedInstitution?.requiresLogin}
            stackEndContent
            onDelete={(x) => {
              setSelectedAccount(x);
              setModalOpen(true);
            }}
            onEdit={(x) => {
              handleEdit(x);
            }}
          />
        </Box>
      </Grid>
    </>
  );
};
