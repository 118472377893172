import { Card, Grid, Typography } from '@mui/material';
import { t } from 'i18next';
import { Svgs } from '../../../../../assets/svg';
import { HelpOutline } from '@mui/icons-material';
import Tippy from '@tippyjs/react';
import { theme } from '../../../../../themes/ThemeWrapper';
import { Data } from '@3nickels/data-modules';
import HelpContent from './HelpContent';
import { mapPlanHelpTipToHelpContext } from '../maps';
import React from 'react';

interface PlanHelpModalProps {
  planHelpTip: Data.MedicalBenefits.HelpTipOptions;
}
const PlanHelpModal: React.FC<PlanHelpModalProps> = ({ planHelpTip }) => {
  const { cardTitle, modalTitle, modalCopy } = mapPlanHelpTipToHelpContext(planHelpTip);

  return (
    <Grid className='mb-plan-help-card'>
      <Tippy
        placement='left'
        content={<HelpContent modalTitle={modalTitle} modalCopy={modalCopy} />}
        theme='light-nickels-theme'>
        <Card>
          <Svgs.ArtLightbulbSmall style={{ flexShrink: 0 }} />
          <Typography variant='p16' color='secondary'>
            {t(cardTitle)}
          </Typography>
          <Grid item display='flex' justifyContent='flex-start' alignSelf='center' ml='5px'>
            <HelpOutline style={{ color: theme.palette.primary.main }} />
          </Grid>
        </Card>
      </Tippy>
    </Grid>
  );
};

export default PlanHelpModal;
