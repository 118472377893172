import { Box, Card, Grid, Stack, Typography } from '@mui/material';
import { useState, type FC, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AccountSvg, AccountSvgDark } from '../../components/images/AccountSvg';
import { BudgetSvgDark } from '../../components/images/BudgetSvg';
import { PersonSvg } from '../../components/images/PersonSvg';
import { Button } from '../../components/buttons/Button';
import { OnboardingSummaryCard } from './OnboardingSummaryCardButton';
import { Api, Hooks } from '@3nickels/data-modules';
import { useService } from '@aesop-fables/containr-react';
import { SubLabelProps } from '../../components/panels/RectanglePanel';
import { useMessage } from '../../hooks/useMessage';
import { Svgs } from '../../assets/svg';
import { getLegalDocByDescription } from '../settings/manage-subscription/Footnote';
import { theme } from '../../themes/ThemeWrapper';

export type OnboardingDetailsProps = {
  loading: boolean;
  personalDetailsComplete: boolean;
  accountDetailsComplete: boolean;
  budgetDetailsComplete: boolean;
};

const OnboardingDetailsView: FC<OnboardingDetailsProps> = ({
  personalDetailsComplete,
  accountDetailsComplete,
  budgetDetailsComplete,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { wizard } = Hooks.usePersonalDetailsWizard();
  const spouse = Hooks.useSpouseData();
  const includeSpouse = Hooks.useIncludeSpouse();
  const legalDocs = Hooks.useTermsAndConditions();
  const termsApi = useService<Api.ITermsAndConditionsApi>(Api.ApiKeys.TermsAndConditions);
  const { showMessage } = useMessage();
  const [PDNeedsAttention, setPDNeedsAttention] = useState<SubLabelProps | undefined>(undefined);

  useEffect(() => {
    if (includeSpouse && spouse?.spouseId && !spouse?.creditScore) {
      setPDNeedsAttention({
        label: t('MissingSpousesInfo') as string,
        color: theme.palette.error.main,
      });
      showMessage(t('WereMissingSomeInfo') as string, 'warning');
    }
  }, [includeSpouse, spouse]);

  const calculateAdvice = async () => {
    navigate('/holistic-advice/budget');
  };

  const openPrivacyPolicy = async () => {
    const url = await getLegalDocByDescription(termsApi, legalDocs, 'Privacy Policy');
    window.open(url);
  };

  return (
    <Grid container justifyContent='center'>
      <Grid item sm={11} ml={10}>
        <Typography color='primary' fontWeight='bold' component='h1' variant='h1'>
          {t('WelcomeToHolistic')}
        </Typography>
        <Typography display='inline-block' mt={1} color='secondary'>
          <Trans i18nKey='WeDontSellYourData' />
        </Typography>
        <Grid item mt={3} sm={9}>
          <Stack spacing={3}>
            <Grid className='info-card'>
              <Card
                className='content-card'
                style={{ padding: '10px' }}
                onClick={() => openPrivacyPolicy()}>
                <Svgs.DisplaySmallCustomInfo />
                <Typography ml={1} color='primary'>
                  {t('CheckOutOurPrivacyPolicyForMoreInfo')}
                </Typography>
              </Card>
            </Grid>
            <OnboardingSummaryCard
              label='PersonalDetails'
              subLabel={PDNeedsAttention}
              complete={personalDetailsComplete}
              disabled={false}
              icon={<PersonSvg fontSize='large' />}
              onClick={
                personalDetailsComplete
                  ? () => {
                      navigate('personal-details/summary');
                    }
                  : () => {
                      navigate('personal-details/personal-info');
                      wizard.start({}).then(() => {
                        wizard.selectStep('personalInfo');
                      });
                    }
              }
            />

            <OnboardingSummaryCard
              label='AccountDetails'
              complete={accountDetailsComplete}
              disabled={!personalDetailsComplete}
              icon={
                personalDetailsComplete ? (
                  <AccountSvg fontSize='large' />
                ) : (
                  <AccountSvgDark fontSize='large' />
                )
              }
              onClick={() => {
                navigate('account-details');
              }}
            />

            <OnboardingSummaryCard
              label='BudgetDetails'
              complete={budgetDetailsComplete}
              disabled={!personalDetailsComplete || !accountDetailsComplete}
              icon={
                accountDetailsComplete ? (
                  <Svgs.DisplaySmallCustomBudget height='35px' width='auto' />
                ) : (
                  <BudgetSvgDark fontSize='large' />
                )
              }
              onClick={() => {
                navigate('budget-details');
              }}
            />

            <Box display='flex' justifyContent='center'>
              <Grid item sm={5}>
                <Button
                  label='CalculateMyAdvice'
                  variant='contained'
                  disabled={
                    !personalDetailsComplete || !accountDetailsComplete || !budgetDetailsComplete
                  }
                  color='secondary'
                  fullWidth
                  onClick={calculateAdvice}
                />
              </Grid>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OnboardingDetailsView;
