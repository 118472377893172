import { Card, Grid, Skeleton } from '@mui/material';

const SectionSkeleton: React.FC = () => {
  return (
    <Card className='collapsible-panel-skeleton' variant='contained'>
      <Grid
        container
        className='budget-sections-skeleton'
        display='flex'
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        height='59px'
        padding='15px 20px'>
        <Grid display='flex' direction='row' justifyContent='flex-start' alignItems='center'>
          <Skeleton height='30px' width='30px' variant='circular' />
          <Skeleton height='25px' width='80px' variant='rounded' sx={{ marginLeft: '15px' }} />
        </Grid>
        <Skeleton height='32px' width='80px' variant='rounded' />
      </Grid>
    </Card>
  );
};

export const BudgetSectionSkeleton: React.FC = () => {
  return (
    <>
      <SectionSkeleton />
      <SectionSkeleton />
      <SectionSkeleton />
      <SectionSkeleton />
    </>
  );
};
