import { Data } from '@3nickels/data-modules';
import { Card, Grid, Typography } from '@mui/material';
import { t } from 'i18next';
import { Row } from '../../../../../components';
import BulletedList from '../../../../../components/BulletedList';
import { Spacing } from '../../../../../themes';
import { Svgs } from '../../../../../assets/svg';
import Tippy from '@tippyjs/react';
import { HelpOutline } from '@mui/icons-material';
import HelpContent from './HelpContent';
import { theme } from '../../../../../themes/ThemeWrapper';
import { mapPlanOptionToContext } from '../maps';

interface PlanRankingCardProps {
  rank?: number;
  planOption: Data.MedicalBenefits.HealthPlanOptions;
  style?: React.CSSProperties;
}
const PlanRankingCard: React.FC<PlanRankingCardProps> = ({ rank, planOption, style }) => {
  const { blurb, pros, cons, fullName } = mapPlanOptionToContext(planOption);
  const isHdhp = planOption.includes('HDHP');
  const rankText = rank ? `${rank}. ` : '';

  return (
    <Card className='mb-ranked-plan-card' sx={{ flex: 1, width: '45%', ...style }}>
      <Typography variant='p18Bold' color='secondary'>
        {rankText}
        {t(`${fullName}`)}
      </Typography>
      <Grid mt={1} display='flex' flexDirection='row'>
        <Typography
          display={isHdhp ? 'inline !important' : undefined}
          variant='p16'
          color='secondary'>
          {t(blurb)}
          {isHdhp && (
            <Tippy
              inlinePositioning
              placement='right'
              content={<HelpContent modalTitle={'Hsas'} modalCopy={'HsasOfferTaxFree'} />}
              theme='light-nickels-theme'>
              <HelpOutline
                style={{
                  color: theme.palette.primary.main,
                  height: '20px',
                  width: '20px',
                  marginBottom: '-5px',
                  marginLeft: '5px',
                }}
              />
            </Tippy>
          )}
        </Typography>
      </Grid>

      <Row style={{ alignItems: 'center', marginBottom: Spacing.xxs, marginTop: Spacing.lg }}>
        <Svgs.DisplaySmallCustomThumbsUp />
        <Typography ml={1} variant='p16SemiBold' color='secondary'>
          {t('Pros')}
        </Typography>
      </Row>
      <BulletedList children={pros} style={{ marginTop: Spacing.xxs }} />

      <Row style={{ alignItems: 'center', marginBottom: Spacing.xxs, marginTop: Spacing.xl }}>
        <Svgs.DisplaySmallCustomThumbsDown />
        <Typography ml={1} variant='p16SemiBold' color='secondary'>
          {t('Cons')}
        </Typography>
      </Row>
      <BulletedList children={cons} style={{ marginTop: Spacing.xxs }} />
    </Card>
  );
};

export default PlanRankingCard;
