import { useEffect, useState } from 'react';
import ChatHistory from './ChatHistory';
import ChatView from './ChatView';
import { Data, Hooks } from '@3nickels/data-modules';
import { useChatById } from './ChatHooks';
import { ChatMetadata } from '@3nickels/data-modules/lib/data/gpt/chat';
import ContentView from '../layout/Content';
import { useContentView } from '../layout/ContentViewContext';
import ChatInput from './ChatInput';

export interface ChatBotViewProps {
  chatMetadatas: ChatMetadata[];
  chatId?: number;
  handleChatMetadataClick: (chatMetadata: ChatMetadata) => void;
  handleDeleteChatClick: (chatMetadata: ChatMetadata) => void;
  handleNewChatClick: () => void;
}

const ChatBotView: React.FC<ChatBotViewProps> = ({
  chatMetadatas,
  chatId,
  handleChatMetadataClick,
  handleDeleteChatClick,
  handleNewChatClick,
}) => {
  const { sideNav } = useContentView();

  const chat = useChatById(chatId);
  const commands = Hooks.useCommands();

  const [chatHasPreviousHumanMessages, setChatHasPreviousHumanMessages] = useState(false);
  const [scrollToBottom, setScrollToBottom] = useState(false);

  useEffect(() => {
    const humanMessages = chat?.messages?.filter((message) => message.sender === 'human');
    if ((humanMessages?.length ?? 0) > 0) {
      setChatHasPreviousHumanMessages(true);
      if (humanMessages?.length === 1) {
        const humanMessage = humanMessages[0];
        const chatMetadata = chatMetadatas.find(
          (chatMetadata) => chatMetadata.id === humanMessage.chatId
        );
        if (chatMetadata?.title !== humanMessage.text) {
          commands.execute(Data.Chat.Commands.UpdateChatTitle, {
            title: humanMessage.text,
            chatId: humanMessage.chatId,
          });
        }
      }
    } else {
      setChatHasPreviousHumanMessages(false);
    }
  }, [setChatHasPreviousHumanMessages, chat?.messages]);

  return (
    <ContentView
      sideNavMenuItems={sideNav('Messages')}
      containerProps={{ gridTemplateColumns: '30% 1fr' }}
      sideComponent={
        <ChatHistory
          chatMetadatas={chatMetadatas ?? []}
          chat={chat ?? {}}
          handleChatMetadataClick={handleChatMetadataClick}
          handleDeleteChatClick={handleDeleteChatClick}
          handleNewChatClick={handleNewChatClick}
        />
      }
      sideComponentStyles={{
        zIndex: 2,
        gridTemplateColumns: `350px auto`,
      }}
      autoScrollContent
      scrollToBottomButton
      scrollToBottom={scrollToBottom}
      setScrollToBottom={setScrollToBottom}
      footer={
        <ChatInput
          hasPreviousMessages={chatHasPreviousHumanMessages}
          chat={chat ?? {}}
          setScrollToBottom={setScrollToBottom}
        />
      }>
      <ChatView chat={chat} loading={!chat} />
    </ContentView>
  );
};

export default ChatBotView;
