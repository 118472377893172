import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Checkbox, Drawer, Grid, Typography } from '@mui/material';
import { Hooks } from '@3nickels/data-modules';
import { useTranslation } from 'react-i18next';
import { Check } from '@mui/icons-material';
import { t } from 'i18next';
import { Svgs } from '../../../../assets/svg';
import { CategoryIcon } from './CategoryIcon';
import { WizardFooter } from '../../../../components/form/WizardFooter';
import { Spacing } from '../../../../themes';
import { convertToTranslationKey } from '../../../../helpers/utilityFunctions';

interface CharityCategoriesSelectorProps {
  disabled?: boolean;
}

const CharityCategoriesSelector: React.FC<CharityCategoriesSelectorProps> = ({ disabled }) => {
  const { categories } = Hooks.useCharitySearcher();
  const filterCategories = Hooks.useCharityFilterCategories();
  const [open, setOpen] = useState(false);
  const [displayCount, setDisplayCount] = useState(2);
  const containerRef = useRef<HTMLDivElement>(null);
  const textMeasureRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    const container = containerRef.current;
    const textMeasure = textMeasureRef.current;

    if (!container || !textMeasure) return;

    const resizeObserver = new ResizeObserver(() => {
      let totalWidth = 0;
      let count = 0;
      const spacing = 10; // 10px spacing between categories
      const othersText = t('AndXOthers', { x: (categories.length - 1).toLocaleString() });
      textMeasure.textContent = othersText;
      const othersWidth = textMeasure.offsetWidth + spacing;

      // Measure each category name individually
      for (const key of categories) {
        const category = filterCategories.find((cat) => cat.key === key);
        if (!category) continue;

        const categoryName = category.value;
        const displayedCategoryName = t(`${convertToTranslationKey(categoryName)}`) as string;
        textMeasure.textContent = displayedCategoryName; // Set text content to measure
        const categoryWidth = textMeasure.offsetWidth + spacing;

        if (totalWidth + categoryWidth + othersWidth <= container.offsetWidth) {
          totalWidth += categoryWidth;
          count++;
        } else {
          break; // Stop adding more categories if they won't fit
        }
      }

      setDisplayCount(count);
    });

    resizeObserver.observe(container);

    return () => resizeObserver.disconnect();
  }, [categories.length, containerRef.current, filterCategories]);

  const content = useMemo(() => {
    if (categories.length === 0) return t('NoFiltersApplied');
    if (categories.length === 1) {
      const targetCategory = filterCategories.find((x) => x.key === categories[0]) ?? {
        key: '',
        value: '',
      };
      const categoryName = targetCategory.value.toString();
      return t(`${convertToTranslationKey(categoryName)}`);
    }
    if (categories.length === filterCategories.length) return t('AllCategories');

    const categoryNames = categories.map((categoryKey) => {
      const category = filterCategories.find((category) => category.key === categoryKey);
      const name = category ? category.value.toString() : '';
      return t(`${convertToTranslationKey(name)}`) as string;
    });

    if (categoryNames.length > displayCount) {
      const displayedNames = categoryNames.slice(0, displayCount).join(', ');
      const othersCount = categoryNames.length - displayCount;
      if (othersCount > 1) {
        return `${displayedNames}, ${t('AndXOthers', { x: othersCount.toLocaleString() })}`;
      } else {
        return `${displayedNames}, ${t('AndOneOther')}`;
      }
    } else {
      return categoryNames.join(', ');
    }
  }, [categories, filterCategories, displayCount]);

  return (
    <>
      <CategoriesDrawer open={open} onClose={() => setOpen(false)} />
      <Grid width='25%' className='transactions-selector-panel' onClick={() => setOpen(true)}>
        <Svgs.ActionSmallFilter />
        <div
          ref={containerRef}
          style={{ width: '100%', position: 'relative', textAlign: 'center' }}>
          {/* Visible text */}
          <Typography
            variant='p12'
            color='secondary'
            style={{ color: disabled ? 'rgba(0, 0, 0, 0.38)' : undefined }}>
            {content}
          </Typography>

          {/* Hidden text for measurement */}
          <Typography
            ref={textMeasureRef}
            component='span'
            variant='p12'
            style={{
              position: 'absolute',
              visibility: 'hidden',
              whiteSpace: 'nowrap',
              left: '0',
              top: '0',
            }}>
            {categories
              .map((key) => filterCategories.find((cat) => cat.key === key)?.value)
              .join(', ')}
          </Typography>
        </div>
      </Grid>
    </>
  );
};

interface CategoriesDrawerProps {
  open: boolean;
  onClose: () => void;
}

const CategoriesDrawer: React.FC<CategoriesDrawerProps> = ({ open, onClose }) => {
  const { t } = useTranslation();

  const { categories, setCategories } = Hooks.useCharitySearcher();
  const filterCategories = Hooks.useCharityFilterCategories();
  const [selectedCategories, setSelectedCategories] = useState<Set<string>>(new Set());

  const content = useMemo(() => {
    if (selectedCategories.size === 0) return t('NoFiltersApplied');
    return `${selectedCategories.size} ${
      selectedCategories.size === 1 ? t('CategorySelected') : t('CategoriesSelected')
    } `;
  }, [selectedCategories.size]);

  useEffect(() => {
    setSelectedCategories(new Set(categories));
  }, [categories, open]);

  const toggleCategory = (category: string) => {
    setSelectedCategories((previous) => {
      const set = new Set(previous);
      if (set.delete(category)) {
        return set;
      }
      set.add(category);
      return set;
    });
  };

  const selectAllCategories = () => {
    setSelectedCategories((previous) => {
      if (previous.size === (filterCategories?.length ?? 0)) {
        return new Set();
      }

      return new Set(filterCategories.map((x) => x.key as string));
    });
  };

  const saveCategories = () => {
    setCategories([...selectedCategories]);
    onClose();
  };

  return (
    <Drawer anchor='right' open={open} onClose={onClose}>
      <Grid p={Spacing.xxs}>
        <Typography className='title' color='primary' component='h1' variant='h1'>
          {t('FilterByCategory')}
        </Typography>
        <Typography className='subtitle' color='secondary' variant='p16'>
          {content}
        </Typography>
        <Grid
          className='category-select-all'
          onClick={selectAllCategories}
          mt={2}
          mb='10px'
          style={{ border: 'solid 1px transparent' }}>
          <Grid display='flex' columnGap='10px'>
            <Checkbox
              checked={selectedCategories.size === (filterCategories?.length ?? 0)}
              indeterminate={
                selectedCategories.size > 0 &&
                selectedCategories.size < (filterCategories?.length ?? 0)
              }
            />
            <Typography variant='p14Bold' color='secondary'>
              {t('AllCategories')}
            </Typography>
          </Grid>
        </Grid>
        <Grid container className='category-select-container'>
          {filterCategories
            .sort((a, b) => {
              return a.value.toString().localeCompare(b.value.toString());
            })
            .map((category) => {
              const selected = selectedCategories.has(category.key as string);
              return (
                <CategoryCard
                  category={category.value as string}
                  selected={selected}
                  toggleCategory={() => toggleCategory(category.key as string)}
                />
              );
            })}
        </Grid>
        <WizardFooter
          color='primary'
          onBack={onClose}
          onDone={saveCategories}
          backLabel='Cancel'
          nextLabel={
            selectedCategories.size > 0
              ? selectedCategories.size === 1
                ? t('ApplyFilter')
                : t('ApplyFilters')
              : 'Done'
          }
        />
      </Grid>
    </Drawer>
  );
};

interface CategoryCardProps {
  category: string;
  toggleCategory: (category: string) => void;
  selected?: boolean;
}

const CategoryCard: React.FC<CategoryCardProps> = ({ category, toggleCategory, selected }) => {
  const categoryName = convertToTranslationKey(category);

  return (
    <Grid
      className={selected ? 'category-select-selected' : 'category-select'}
      onClick={() => toggleCategory(category)}>
      <Grid display='flex' columnGap='10px'>
        <Checkbox checked={selected} checkedIcon={<Check />} />
        <Typography variant='p14Bold'>{t(`${categoryName}`)}</Typography>
      </Grid>
      <CategoryIcon category={category} selected={selected} />
    </Grid>
  );
};

export default CharityCategoriesSelector;
