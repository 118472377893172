/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useAutoSaveHandler } from '../../../hooks/useAutoSaveHandler';
import { Data, Hooks } from '@3nickels/data-modules';
import { BudgetSectionProps, GivingFormData, mapGivingFormToMontlySpendingRest } from '../Types';
import * as Yup from 'yup';
import { Grid } from '@mui/material';
import EmbeddedFormInput from '../../../components/EmbeddedFormInput';
import { cleanWholeNumberStr } from '../../../helpers/utilityFunctions';
import _ from 'lodash';

const schema: Yup.Schema = Yup.object({
  cashDonations: Yup.string().notRequired(),
  gifts: Yup.string().notRequired(),
  otherGifts: Yup.string().notRequired(),
});

export declare type GivingFormProps = BudgetSectionProps & { defaultValues: GivingFormData };

export const GivingForm: React.FC<GivingFormProps> = ({ defaultValues, mode }) => {
  const { formState } = useFormContext<GivingFormData>();
  const { person } = Hooks.useCombinedSelfData();
  const commands = Hooks.useCommands();

  const max = useMemo(() => {
    if (mode === 'monthly') {
      return 999999;
    }

    return 999999 * 12;
  }, [mode]);

  const formatInputs = (data: any): GivingFormData => {
    const cashDonations = cleanWholeNumberStr(data.cashDonations ?? '', { max });
    const gifts = cleanWholeNumberStr(data.gifts ?? '', { max });
    const otherGifts = cleanWholeNumberStr(data.otherGifts ?? '', { max });

    return {
      cashDonations,
      gifts,
      otherGifts,
    };
  };

  const saveHandler = useCallback(
    async (data: GivingFormData) => {
      const params = mapGivingFormToMontlySpendingRest(mode, data);
      const filtered = _.pickBy(params, (x) => typeof x !== 'undefined');
      await commands.execute(Data.Budget.Commands.UpdateMonthlySpending, filtered);
    },
    [person, mode, commands]
  );

  const { onChange } = useAutoSaveHandler({
    defaultValues,
    mode: 'partial',
    onSave: saveHandler,
    schema,
    transformValues: formatInputs,
  });

  return (
    <form onChange={onChange}>
      <Grid className='embedded-form'>
        <EmbeddedFormInput<GivingFormData>
          label='CashDonations'
          placeholder='$0'
          autoComplete='off'
          name='cashDonations'
          type='dollar'
          max={max}
          defaultValue={defaultValues.cashDonations}
          error={formState.errors.cashDonations !== undefined}
          helperText={formState.errors.cashDonations?.message?.toString()}
        />
        <EmbeddedFormInput<GivingFormData>
          label='GiftsEtc'
          placeholder='$0'
          autoComplete='off'
          name='gifts'
          type='dollar'
          max={max}
          defaultValue={defaultValues.gifts}
          error={formState.errors.gifts !== undefined}
          helperText={formState.errors.gifts?.message?.toString()}
        />
        <EmbeddedFormInput<GivingFormData>
          label='OtherGiving'
          placeholder='$0'
          autoComplete='off'
          name='otherGifts'
          type='dollar'
          max={max}
          defaultValue={defaultValues.otherGifts}
          error={formState.errors.otherGifts !== undefined}
          helperText={formState.errors.otherGifts?.message?.toString()}
        />
      </Grid>
    </form>
  );
};
