import { Grid } from '@mui/material';
import { LayoutContext, withLayoutMeta } from '../../../../../types/LayoutMeta';
import { MedicalBenefitsContent } from '../MedicalBenefitsContent';
import { useNavigate, useOutletContext } from 'react-router';
import CardButton from '../components/CardButton';
import { t } from 'i18next';
import HelpPopover, { HelpPopoverText } from '../../../../../components/HelpPopover';
import { Spacer } from '../../../../../components';
import { Hooks } from '@3nickels/data-modules';
import { useCallback, useEffect } from 'react';

const StepFiveWrapper: React.FC = () => {
  const navigate = useNavigate();
  const { meta, setMeta } = useOutletContext<LayoutContext>();
  const { preference, calculateSuggestedBenefits } = Hooks.useMedicalBenefitsContext();

  const onSubmit = useCallback(() => {
    calculateSuggestedBenefits(5);
    navigate('/tools/medical/medical-benefit-selector-tool/summary');
  }, [preference]);

  useEffect(() => {
    setMeta(() => ({
      stepNumber: 5,
      showNext: true,
      onNext: onSubmit,
      disableNextButton: preference === undefined,
    }));
  }, [preference]);

  const MedicalBenfitsStepFive = withLayoutMeta(MedicalBenefitsSelectorStepFive, meta);
  return <MedicalBenfitsStepFive />;
};

const MedicalBenefitsSelectorStepFive: React.FC = () => {
  const { preference, setPreference } = Hooks.useMedicalBenefitsContext();

  const onClickLowerPremium = () => {
    if (preference !== 'LOWER_PREMIUMS') {
      setPreference('LOWER_PREMIUMS');
    } else {
      setPreference(undefined);
    }
  };

  const onClickOutOfNetwork = () => {
    if (preference !== 'OUT_OF_NETWORK_FLEXIBILITY') {
      setPreference('OUT_OF_NETWORK_FLEXIBILITY');
    } else {
      setPreference(undefined);
    }
  };

  return (
    <MedicalBenefitsContent
      questionNumber={5}
      questionTitle={'DoYouPreferLowerPremiums'}
      helpContext={
        <HelpPopover title={t('ChoosingFamilyPlan')}>
          <HelpPopoverText children={t('FamilyHasDiverseNeeds')} />
          <Spacer height='xxs' />
          <HelpPopoverText children={t('InNetworkReduceCosts')} />
        </HelpPopover>
      }
      questionContent={
        <Grid display='flex' flexDirection='row' width='100%'>
          <Grid container display='flex' flexDirection='column' width='100%' alignItems='center'>
            <CardButton
              checked={preference === 'LOWER_PREMIUMS'}
              onClick={onClickLowerPremium}
              label={'LowerPremiums'}
            />
            <CardButton
              checked={preference === 'OUT_OF_NETWORK_FLEXIBILITY'}
              onClick={onClickOutOfNetwork}
              label={'OutOfNetworkFlexibility'}
            />
          </Grid>
        </Grid>
      }
    />
  );
};

export default StepFiveWrapper;
