import { Box, Stack } from '@mui/material';
import React from 'react';
import TextInput from '../../../../components/form/TextInput';
import ToggleRadioButtonGroup from '../../../../components/form/ToggleRadioButtonGroup';
import { Spacing } from '../../../../themes';
import { Data, Domain, Hooks } from '@3nickels/data-modules';
import { useFormContext } from 'react-hook-form';
import { mapDebtTypeToLabelNickname } from '../DebtUtils';
import { t } from 'i18next';

export type DebtBasicsFormProps = {
  type: Domain.DebtTypeEnum;
  debtBasics?: Data.Debt.DebtBasicFormData;
  onSubmit: (values: Data.Debt.DebtBasicFormData) => Promise<void>;
};

const DebtBasicsForm: React.FC<DebtBasicsFormProps> = (props) => {
  const { formState } = useFormContext();
  const includeSpouse = Hooks.useIncludeSpouse();

  return (
    <Box>
      <Stack spacing={Spacing.xxs}>
        <TextInput<Data.Debt.DebtBasicFormData>
          error={formState.errors.name !== undefined}
          helperText={formState.errors.name?.message?.toString()}
          defaultValue={props.debtBasics?.name}
          label={mapDebtTypeToLabelNickname(props.type)}
          inputProps={{ maxLength: 64 }}
          name='name'
          autoFocus
        />
        {includeSpouse && (
          <ToggleRadioButtonGroup<Data.Debt.DebtBasicFormData>
            error={formState.errors.owner !== undefined}
            helperText={formState.errors.owner?.message?.toString()}
            label='DebtOwner'
            name='owner'
            row
            defaultValue={props.debtBasics?.owner}
            items={[
              { label: t('Me'), value: 'me' },
              { label: t('Spouse'), value: 'spouse' },
            ]}
          />
        )}
      </Stack>
    </Box>
  );
};

export default DebtBasicsForm;
