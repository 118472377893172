import { Button, Grid, InputAdornment, Typography } from '@mui/material';
import { t } from 'i18next';
import FormContent from '../../components/form/FormContent';
import TextInput from '../../components/form/TextInput';
import { Colors } from '../../themes';
import { Api, Data, Hooks } from '@3nickels/data-modules';
import { useForm } from 'react-hook-form';
import { Svgs } from '../../assets/svg';
import { ChatData } from '@3nickels/data-modules/lib/data/gpt/chat';
import { API_URL } from '../../configuration/Environment';
import { KeyboardEvent } from 'react';

interface ChatInputProps {
  hasPreviousMessages: boolean;
  chat: ChatData;
  setScrollToBottom: React.Dispatch<React.SetStateAction<boolean>>;
}

const ChatInput: React.FC<ChatInputProps> = ({ hasPreviousMessages, chat, setScrollToBottom }) => {
  const commands = Hooks.useCommands();

  const disableInput = chat.messages?.at(chat.messages.length - 1)?.loading;
  const methods = useForm<Data.Chat.MessageData>();

  const getResponse = (chatResponseId: string, attempt = 0) => {
    const eventSource = new EventSource(`${API_URL}sse?emitterId=${chatResponseId}`, {
      withCredentials: true,
    });

    eventSource.onmessage = (event) => {
      const botResponse: Api.BotResponse = JSON.parse(event.data);
      if (botResponse.eventType === Api.EventType.CLOSING) {
        eventSource.close();
      }
      commands.execute(Data.Chat.Commands.BuildChatResponse, { chatId: chat.id, botResponse });
    };

    eventSource.onerror = (error) => {
      console.error('EventSource failed:', error);
      eventSource.close();

      if (attempt < 10) {
        setTimeout(() => {
          getResponse(chatResponseId, attempt + 1);
        }, Math.pow(2, attempt) * 1000); // Exponential backoff
      }
    };
  };

  const onSubmit = (values: Data.Chat.MessageData) => {
    if (!values.text || values.text?.trim().length === 0) return;

    setScrollToBottom(true);

    const sendMessage = async () => {
      values.chatId = chat.id;
      methods.resetField('text');
      const message = await commands.execute(Data.Chat.Commands.SendMessage, values);
      if (!message.chatResponseId) throw 'Chat response id missing when sending human message';
      getResponse(message.chatResponseId);
    };
    sendMessage();
  };

  const onKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (!disableInput) {
        methods.handleSubmit(onSubmit)();
      }
    }
  };

  return (
    <Grid
      container
      className='footer-content'
      style={{ backgroundColor: Colors.tertiaryBase }}
      padding={'20px 0px'}
      zIndex={1}>
      <Grid container className='content'>
        <Grid item className='children'>
          <Typography color='primary' variant='p16' mb={'10px'}>
            {t('AiDisclaimer')}
          </Typography>
          <FormContent formProviderProps={methods}>
            <form className='children' onSubmit={methods.handleSubmit(onSubmit)}>
              <TextInput
                autoComplete='off'
                multiline
                onKeyDown={onKeyDown}
                maxRows={6}
                placeholder={
                  hasPreviousMessages
                    ? t('ContinueChatting').toString()
                    : t('AskAQuestion').toString()
                }
                name='text'
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderRadius: '20px',
                    },
                    '&.Mui-focused fieldset': {
                      border: `1px solid ${Colors.primaryBase}`,
                    },
                  },
                }}
                InputProps={{
                  style: { height: '100%' },
                  endAdornment: (
                    <InputAdornment
                      position='end'
                      style={{ alignSelf: 'flex-end', height: 'inherit' }}>
                      <Button
                        type='submit'
                        disabled={disableInput}
                        style={{ width: 'max-content' }}>
                        <Grid container direction='row' justifyContent='center' alignItems='center'>
                          <Typography>{t('Send')}</Typography>
                          <Svgs.SendArrow />
                        </Grid>
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />
            </form>
          </FormContent>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ChatInput;
