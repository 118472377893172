import { MutationStatus, useMutation } from '@aesop-fables/scrinium';
import { useLoading } from '../useLoading';
import { useMessage } from '../useMessage';
import { Data } from '@3nickels/data-modules';
import { useEffect } from 'react';
import { t } from 'i18next';

export function useDeleteGoal(cleanUp?: () => void) {
  const { setLoading } = useLoading();
  const { showMessage } = useMessage();

  const deleteGoal = useMutation(new Data.Goals.DeleteGoal());

  useEffect(() => {
    if (deleteGoal.status === MutationStatus.Complete) {
      setLoading(false);
      showMessage(t('GoalRemoved').toString());
      if (cleanUp) {
        cleanUp();
      }
    }
  }, [deleteGoal.status]);

  return deleteGoal;
}
