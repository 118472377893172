import { Column, Row, Spacer } from '../components';
import { mobileMargin } from '../App';
import { useDeviceType } from '../hooks/useDeviceType';
import { Link as DomLink } from 'react-router-dom';
import {
  Typography,
  Card,
  Link,
  CardContent,
  FormControlLabel,
  Button,
  Checkbox,
} from '@mui/material';
import { logo3NSmall } from '../assets/png';
import { useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { AcceptTerms } from '../data/mutations';
import { useLoading } from '../hooks/useLoading';
import { useNavigate } from 'react-router-dom';
import { MutationStatus, useMutation } from '@aesop-fables/scrinium';
import { useService } from '@aesop-fables/containr-react';
import { Api, Hooks } from '@3nickels/data-modules';
import BulletedList from '../components/BulletedList';
import { Margins } from '../themes';
import { PageContentWithBackground } from '../pages/layout/PageContentWithBackground';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { t } from 'i18next';
import { Trans } from 'react-i18next';

const TermsAndConditions = () => {
  const navigate = useNavigate();
  const organizationData = Hooks.useOrganizationData();
  const acceptTerms = useMutation(new AcceptTerms());
  const docs = Hooks.useTermsAndConditionsDocs();
  const termsApi = useService<Api.TermsAndConditionsApi>(Api.ApiKeys.TermsAndConditions);
  const organizationApi = useService<Api.OrganizationApi>(Api.ApiKeys.Organization);

  const [terms, setTerms] = useState<string>('');
  const [disclosure, setDisclosure] = useState<string>('');
  const [privacyPolicy, setPrivacyPolicy] = useState<string>('');
  const [checked, setChecked] = useState(false);

  const { isMobile, isTablet } = useDeviceType();
  const mobile = isMobile || isTablet;
  const { setLoading } = useLoading();
  const { height } = useWindowDimensions();

  useEffect(() => {
    if (docs) {
      docs.forEach((doc) => {
        const url = termsApi.getDocString(doc.id);
        if (doc.docDescription === 'Terms of Service') {
          setTerms(url);
        } else if (doc.docDescription === 'Disclosure') {
          setDisclosure(url);
        } else if (doc.docDescription === 'Privacy Policy') {
          setPrivacyPolicy(url);
        }
      });
    }
  }, [docs, termsApi]);

  useEffect(() => {
    if (acceptTerms.status === MutationStatus.Complete) {
      navigate('/');
      setLoading(false);
    }
  }, [acceptTerms.status]);

  // TODO -- We probably need an error toast or something here

  const onSubmit = async () => {
    setLoading(true);
    acceptTerms.action();
  };

  return (
    <PageContentWithBackground sx={{ height: mobile ? height : undefined }} useContainer={false}>
      <Form onSubmit={onSubmit}>
        {({ handleSubmit }) => (
          <Column style={{ margin: mobile ? mobileMargin : '15px 100px' }}>
            <Row style={{ alignItems: 'center', justifyContent: 'space-between', height: '75px' }}>
              {organizationData ? (
                organizationData.logoUri ? (
                  <img
                    src={organizationApi.getLogo()}
                    alt={organizationData?.name}
                    style={{ height: '60%' }}
                  />
                ) : (
                  <Typography variant='p25Bold' color='secondary'>
                    {organizationData?.name}
                  </Typography>
                )
              ) : (
                <Card variant='ghost' />
              )}
              <DomLink style={{ height: '70%' }} to='/'>
                <img src={logo3NSmall} alt='logo' style={{ height: '100%' }} />
              </DomLink>
            </Row>
            <Column style={{ alignItems: 'center' }}>
              <Typography variant='p30Bold' color='primary'>
                {t('TermsOfServiceDisclosureAndPrivacyPolicy')}
              </Typography>
              <Spacer height='xs' />
              <Typography variant='p20' color='secondary'>
                {t('PleaseTakeAFewMinutesToReviewOurTermsOfServiceDisclosureAndPrivacyPolicy')}
              </Typography>
            </Column>
            <Spacer height='sm' />
            <Column>
              <Typography variant='p20Bold' color='secondary'>
                {t('TermsOfServiceAndDisclosure')}
              </Typography>
              <Spacer height='xxs' />
              <Typography className='link-wrapper' variant='p16' color='secondary'>
                <Trans
                  i18nKey='BeforeWeCanGetStartedReviewTOSDisclosue'
                  components={{
                    TermsOfService: (
                      <Link href={terms} target='_blank' variant='p16Bold'>
                        {t('TermsOfService')}
                      </Link>
                    ),
                    Disclosure: (
                      <Link href={disclosure} target='_blank' variant='p16Bold'>
                        {t('Disclosure')}
                      </Link>
                    ),
                  }}
                />
              </Typography>
            </Column>
            <Spacer height='sm' />
            <Card variant='innerCard'>
              <CardContent className='pad20'>
                <FormControlLabel
                  style={{ margin: 0 }}
                  color='secondary'
                  control={
                    <Checkbox
                      style={{ marginRight: '20px' }}
                      checked={checked}
                      color='secondary'
                      onChange={(e) => setChecked(e.target.checked)}
                    />
                  }
                  label={t('IHaveReadAndAgreedToTermsAndDisclosure')}
                />
                <Spacer height='xxs' />
                <Typography variant='p16' color='secondary'>
                  {t('TheDisclosureDocumentAppliesTo3NickelsOnly')}
                </Typography>
              </CardContent>
            </Card>
            <Spacer height='sm' />
            <Column>
              <Typography variant='p20Bold' color='secondary'>
                {t('TermsOfServiceAndDisclosure')}
              </Typography>
              <Spacer height='xxs' />
              <Typography variant='p16Bold' color='secondary'>
                {t('CCPANoticeAtCollection')}
              </Typography>
              <Spacer height='xxs' />
              <Typography variant='p16' color='secondary'>
                {t('UnderCCPA')}
              </Typography>
            </Column>
            <Spacer height='xs' />
            <Card variant='innerCard'>
              <CardContent className='pad20'>
                <Row style={{ justifyContent: 'space-between' }}>
                  <Column style={{ maxWidth: '50%' }}>
                    <Typography variant='p16Bold' color='secondary'>
                      {t('CategoriesOfPersonalInformationCollected')}
                    </Typography>

                    <BulletedList
                      style={{ ...Margins.mt_xs }}
                      children={[
                        t('IdentifiersIncludingButNotLimitedTo'),
                        t('FinancialInformationIncludingSalaryInvestmentsAndAccountBalances'),
                        t('ProfessionalAndEmploymentRelatedInformationIncludingCompanyName'),
                      ]}
                    />
                  </Column>
                  <Column style={{ maxWidth: '50%' }}>
                    <Typography variant='p16Bold' color='secondary'>
                      {t('PurposesForWhichItWillBeUsed')}
                    </Typography>
                    <BulletedList
                      style={{ ...Margins.mt_xs }}
                      children={[
                        t('ProvidingFinancialAdviceAndGuidance'),
                        t('TrackingOurVisitors'),
                        t('ImprovingOurWebsites'),
                        t('ComplianceAuditsAnAssitance'),
                        t('CompleteACorporateTransaction'),
                      ]}
                    />
                  </Column>
                </Row>
              </CardContent>
            </Card>
            <Spacer height='sm' />
            <Column>
              <Typography variant='p16' color='secondary'>
                {t('InCasesWhereInvestmentAdviceHasBeenProvided')}
              </Typography>
              <Spacer height='xs' />
              <Typography className='link-wrapper' variant='p16' color='secondary'>
                {t(
                  'GuidedchoiceIsCommittedToProtectingThePersonalInformationThatOurCustomersProvideToUs'
                )}{' '}
                <Typography display='contents' variant='p16Bold'>
                  <Trans
                    i18nKey='PleaseReviewOurPrivacyPolicy'
                    components={{
                      PrivacyPolicy: (
                        <Link
                          href={privacyPolicy}
                          target='_blank'
                          display='contents'
                          variant='p16Bold'
                        />
                      ),
                    }}
                  />
                </Typography>
              </Typography>
            </Column>
            <Spacer height='md' />
            <Button
              style={{ width: '250px', alignSelf: 'center' }}
              color='secondary'
              disabled={!checked}
              onClick={handleSubmit}>
              {t('IAgree')}
            </Button>
          </Column>
        )}
      </Form>
    </PageContentWithBackground>
  );
};

export default TermsAndConditions;
