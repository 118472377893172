import { Grid } from '@mui/material';
import { LayoutContext, withLayoutMeta } from '../../../../../types/LayoutMeta';
import { MedicalBenefitsContent } from '../MedicalBenefitsContent';
import { useNavigate, useOutletContext } from 'react-router';
import CardButton from '../components/CardButton';
import { Hooks } from '@3nickels/data-modules';
import { useCallback, useEffect } from 'react';

const StepFourWrapper: React.FC = () => {
  const navigate = useNavigate();
  const { meta, setMeta } = useOutletContext<LayoutContext>();
  const { familyCoverage, calculateSuggestedBenefits } = Hooks.useMedicalBenefitsContext();

  const onSubmit = useCallback(() => {
    if (familyCoverage) {
      updateContext(false);
    } else {
      updateContext(true);
    }
  }, [familyCoverage]);

  const updateContext = async (saveToApi: boolean) => {
    try {
      if (saveToApi) {
        calculateSuggestedBenefits(4);
        navigate('/tools/medical/medical-benefit-selector-tool/summary');
      } else {
        navigate('/tools/medical/medical-benefit-selector-tool/5');
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    setMeta(() => ({
      stepNumber: 4,
      showNext: true,
      onNext: onSubmit,
      disableNextButton: familyCoverage === undefined,
    }));
  }, [familyCoverage]);

  const MedicalBenefitsStepFour = withLayoutMeta(MedicalBenefitsSelectorStepFour, meta);
  return <MedicalBenefitsStepFour />;
};

const MedicalBenefitsSelectorStepFour: React.FC = () => {
  const { familyCoverage, setFamilyCoverage } = Hooks.useMedicalBenefitsContext();

  const onClickYes = () => {
    if (familyCoverage) {
      setFamilyCoverage(undefined);
    } else {
      setFamilyCoverage(true);
    }
  };

  const onClickNo = () => {
    if (familyCoverage === undefined || familyCoverage) {
      setFamilyCoverage(false);
    } else {
      setFamilyCoverage(undefined);
    }
  };

  return (
    <MedicalBenefitsContent
      questionNumber={4}
      questionTitle={'DoYouNeedFamilyCoverage'}
      questionContent={
        <Grid display='flex' flexDirection='row' width='100%'>
          <Grid container display='flex' flexDirection='column' width='100%' alignItems='center'>
            <CardButton checked={familyCoverage} onClick={onClickYes} label={'Yes'} />
            <CardButton checked={familyCoverage === false} onClick={onClickNo} label={'No'} />
          </Grid>
        </Grid>
      }
    />
  );
};

export default StepFourWrapper;
