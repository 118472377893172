import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import { Hooks } from '@3nickels/data-modules';
import TextInput from '../../components/form/TextInput';
import { useFormContext } from 'react-hook-form';
import { Close, Search } from '@mui/icons-material';
import { useAutoSaveHandler } from '../../hooks/useAutoSaveHandler';
import { t } from 'i18next';

const SpendingSummaryTransactionsSearch: React.FC = () => {
  const { transactionsSearchString, setTransactionsSearchString } = Hooks.useTransactions();
  const methods = useFormContext();
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isHovered, setIsHovered] = useState<boolean>(false); // New state for hover
  const searchString = methods.getValues('searchString');
  const showClose = (isFocused || isHovered) && searchString && searchString.length > 0;

  const { onChange } = useAutoSaveHandler({
    mode: 'full',
    onSave: async (data: { searchString: string }) => {
      setTransactionsSearchString(data.searchString);
    },
  });

  return (
    <form onChange={onChange}>
      <TextInput
        className='ghost-input'
        name='searchString'
        placeholder={t('SearchForATransaction').toString()}
        defaultValue={transactionsSearchString}
        onBlur={() => setIsFocused(false)}
        onMouseDown={(e) => {
          e.preventDefault();
          (e.target as HTMLElement).focus();
          setIsFocused(true);
        }}
        onMouseEnter={(e) => {
          e.preventDefault();
          setIsHovered(true);
        }}
        onMouseLeave={() => setIsHovered(false)}
        InputProps={{
          startAdornment: <Search color='primary' style={{ marginRight: '14px' }} />,
          endAdornment: (
            <IconButton
              style={{
                padding: '0px',
                marginLeft: '14px',
                visibility: showClose ? 'visible' : 'hidden',
              }}
              onClick={() => {
                methods.setValue('searchString', '', { shouldDirty: true });
                setTransactionsSearchString('');
              }}>
              <Close color='primary' />
            </IconButton>
          ),
        }}
      />
    </form>
  );
};

export default SpendingSummaryTransactionsSearch;
