import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Hooks } from '@3nickels/data-modules';
import CoachingSessionPanel from '../../../university/components/CoachingSessionPanel';

const CoachingSection: React.FC = () => {
  const { t } = useTranslation();
  const coachingSessionUrl = Hooks.useCoachingSessionUrl();

  return (
    <Grid className='advice-section' item>
      <Typography
        className='section-header'
        variant='p18Bold'
        color='primary'
        marginBottom={'-12px'}>
        {t('Coaching')}
      </Typography>
      <CoachingSessionPanel coachingSessionUrl={coachingSessionUrl} />
    </Grid>
  );
};

export default CoachingSection;
