import { Grid, Stack, Typography } from '@mui/material';
import { Svgs } from '../../../../assets/svg';
import { Row } from '../../../../components';
import { Trans, useTranslation } from 'react-i18next';
import { errorBase } from '../../../../themes/colors';

export const UsefulTip: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Stack spacing={2}>
      <Row style={{ justifyContent: 'flex-start', alignItems: 'center' }}>
        <Svgs.ArtLightbulbMedium />
        <Grid ml={3}>
          <Typography color='secondary' variant='p18Bold'>
            {t('UsefulTip')}
          </Typography>
        </Grid>
      </Row>
      <Typography display='inline' variant='p16' color='secondary'>
        <Trans
          i18nKey='TheSecondNumberMustBeHighEnough'
          components={{
            Styled: <span style={{ fontWeight: 'bold', display: 'inline' }} />,
          }}
        />
      </Typography>
      <Typography sx={{ color: errorBase, alignSelf: 'center' }} variant='p24Bold'>
        100/300/100
      </Typography>
    </Stack>
  );
};
