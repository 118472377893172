import { Hooks } from '@3nickels/data-modules';
import GroupPanels, { Group } from '../../components/GroupPanels';
import { LmsLayoutMeta } from './LmsLayout';
import { LayoutMeta, withLayoutMeta } from '../../types/LayoutMeta';
import { Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Svgs } from '../../assets/svg';
import { Button } from '../../components/buttons/Button';
import { park } from '../../assets/png';
import { Row } from '../../components';
import { Spacing } from '../../themes';
import { useMemo } from 'react';
import { LmsCourseCard } from './LmsCourseCard';
import { useTranslation } from 'react-i18next';

const LmsCoursesView: React.FC = () => {
  const { t } = useTranslation();
  const courses = Hooks.useAllCourses();
  const universityEnabled = Hooks.useUniversityEnabled();
  const universityCourse = Hooks.useUniversityCourse();
  const units = Hooks.useUniversityUnits();
  const navigate = useNavigate();
  courses?.sort((a, b) => {
    if ((a.name ?? '') < (b.name ?? '')) return -1;
    else if ((a.name ?? '') > (b.name ?? '')) return 1;
    return 0;
  });
  const lessonStats = useMemo(() => {
    let totalCount = 0;
    let completeCount = 0;
    units.map((unit) => {
      totalCount += unit.lessons.length;
      const filtered = unit.lessons.filter((lesson) => lesson.complete);
      completeCount += filtered.length;
    });
    return { totalLessons: totalCount, completedLessons: completeCount };
  }, [units]);
  const universityGroup = {
    textKey: universityCourse?.title,
    content: (
      <LmsCourseCard
        university
        course={{
          name: universityCourse?.title,
          courseId: universityCourse?.id,
          completionPercentage: universityCourse?.progress,
          lessonsCompleted: lessonStats.completedLessons,
          totalLessons: lessonStats.totalLessons,
        }}
      />
    ),
    onClick: () => navigate('/learning/university'),
  };
  const universityStatus = !universityEnabled
    ? 'inactive'
    : universityCourse?.complete
    ? 'complete'
    : (universityCourse?.progress ?? 0) !== 0
    ? 'progress'
    : 'notStarted';
  const moreThanTwoCourses =
    (courses?.length ?? 0) > 2 || (universityEnabled && (courses?.length ?? 0) > 1);
  const courseGroups: Group[] =
    courses?.map((course) => {
      return {
        textKey: course.name,
        content: <LmsCourseCard course={course} />,
        onClick: () => navigate(`${course?.courseId}`),
      };
    }) ?? [];
  const inProgressCourses =
    courses
      ?.filter(
        (course) => (course.completionPercentage ?? 0) !== 0 && course.completionPercentage !== 100
      )
      .map((course) => {
        return {
          textKey: course.name,
          content: <LmsCourseCard course={course} />,
          onClick: () => navigate(`${course?.courseId}`),
        };
      }) ?? [];
  const notStartedCourses =
    courses
      ?.filter((course) => (course.completionPercentage ?? 0) === 0)
      .map((course) => {
        return {
          textKey: course.name,
          content: <LmsCourseCard course={course} />,
          onClick: () => navigate(`${course?.courseId}`),
        };
      }) ?? [];
  const finishedCourses =
    courses
      ?.filter((course) => course.completionPercentage === 100)
      .map((course) => {
        return {
          textKey: course.name,
          content: <LmsCourseCard course={course} />,
          onClick: () => navigate(`${course?.courseId}`),
        };
      }) ?? [];

  if (universityEnabled) {
    if (!moreThanTwoCourses) courseGroups.unshift(universityGroup);
    else
      switch (universityStatus) {
        case 'complete':
          finishedCourses.unshift(universityGroup);
          break;
        case 'notStarted':
          notStartedCourses.unshift(universityGroup);
          break;
        case 'progress':
          inProgressCourses.unshift(universityGroup);
          break;
        default:
          break;
      }
  }

  return (
    <Grid>
      <Grid display='flex' justifyContent='space-between' alignItems='center'>
        <Grid display='flex' columnGap='20px' justifyContent='flex-start'>
          <Svgs.DisplaySmallCustomBook style={{ height: '80px', width: '80px' }} />
          <Typography variant='p50Bold' color='secondary'>
            {t('YourCourses')}
          </Typography>
        </Grid>
        <Button
          style={{ height: 'fit-content' }}
          variant='ghost'
          color='secondary'
          onClick={() => navigate('/')}>
          {'<'} {t('BackToHome')}
        </Button>
      </Grid>
      <Grid container className='lms-course-panels'>
        {moreThanTwoCourses ? (
          <>
            {inProgressCourses.length > 0 && (
              <CourseSection groups={inProgressCourses} sectionTitle={t('KeepLearning')} />
            )}
            {notStartedCourses.length > 0 && (
              <CourseSection groups={notStartedCourses} sectionTitle={t('ExploreNewCourses')} />
            )}
            {finishedCourses.length > 0 && (
              <CourseSection groups={finishedCourses} sectionTitle={t('YourAchievements')} />
            )}
          </>
        ) : (
          <GroupPanels variant='Popover' groups={courseGroups} groupsPerRow={2} />
        )}
      </Grid>
    </Grid>
  );
};

interface CourseSectionProps {
  groups: Group[];
  sectionTitle: string;
}
const CourseSection: React.FC<CourseSectionProps> = ({ groups, sectionTitle }) => {
  return (
    <>
      <Row style={{ justifyContent: 'flex-start', width: '100%', marginTop: Spacing.lg }}>
        <Typography variant='p18Bold' color='primary'>
          {sectionTitle}
        </Typography>
      </Row>

      <GroupPanels variant='Popover' groups={groups} groupsPerRow={2} />
    </>
  );
};

const meta = {
  showBack: false,
  background: <img src={park} className='tools-start-view-background' />,
} satisfies LayoutMeta<LmsLayoutMeta>;

export default withLayoutMeta(LmsCoursesView, meta);
