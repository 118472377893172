import { Data, Hooks } from '@3nickels/data-modules';
import ChatBotView from './ChatBotView';
import { useEffect, useState } from 'react';
import { ChatMetadata } from '@3nickels/data-modules/lib/data/gpt/chat';
import { useNavigate, useParams } from 'react-router-dom';
import { useLoading } from '../../hooks/useLoading';
import { useMessage } from '../../hooks/useMessage';
import { t } from 'i18next';
const ChatBotManager: React.FC = () => {
  const navigate = useNavigate();

  const { setLoading } = useLoading();
  const { showMessage } = useMessage();

  const chatMetadatasInitialized = Hooks.useChatMetadatasInitialized();
  const chatMetadatas = Hooks.useChatMetadata();
  const commands = Hooks.useCommands();
  const { chatId: chatIdStr } = useParams();

  const [chatId, setChatId] = useState<number | undefined>(undefined);

  const openChat = (chatId?: number) => {
    setChatId(undefined);
    navigate(`/chat-bot/${chatId}`, { replace: true });
  };

  const createAndOpenNewChat = async () => {
    const newChat = await commands.execute(Data.Chat.Commands.CreateChat, {});
    openChat(newChat.id);
  };

  const chatIsEarlierThan24HoursAgo = (chatMetadata: ChatMetadata): boolean => {
    if (!chatMetadata.mostRecentMessageTimestamp) return false;

    const twentyFourHoursAgo = new Date(new Date().getTime() - 24 * 60 * 60 * 1000);
    return chatMetadata.mostRecentMessageTimestamp > twentyFourHoursAgo;
  };

  useEffect(() => {
    if (chatIdStr) {
      setChatId(parseInt(chatIdStr));
      return;
    }
  }, [chatIdStr]);

  useEffect(() => {
    if (!chatMetadatasInitialized || chatIdStr) return;

    if (!chatMetadatas || chatMetadatas.length === 0) {
      createAndOpenNewChat();
      return;
    }

    const mostRecentChatInteractedWith = chatMetadatas.at(0);
    if (mostRecentChatInteractedWith && chatIsEarlierThan24HoursAgo(mostRecentChatInteractedWith)) {
      openChat(mostRecentChatInteractedWith.id);
    } else {
      const chatMetadata = chatMetadatas.at(0);
      if (chatMetadata?.messageCount === 1) {
        openChat(chatMetadata.id);
      } else {
        createAndOpenNewChat();
      }
    }
  }, [chatMetadatas, setChatId]);

  const handleChatMetadataClick = (chatMetadata: ChatMetadata) => {
    setLoading(true);
    openChat(chatMetadata.id);
    setLoading(false);
  };

  const handleDeleteChatClick = (chatMetadata: ChatMetadata) => {
    const deleteChat = async () => {
      commands.execute(Data.Chat.Commands.DeleteChat, chatMetadata.id);
      showMessage(t('ChatRemoved').toString());
      if (chatId !== chatMetadata.id) return;

      const newChatMetadatList = chatMetadatas?.filter(
        (metadata) => metadata.id !== chatMetadata.id
      );
      if ((newChatMetadatList?.length ?? 0) > 0) {
        openChat(newChatMetadatList?.at(0)?.id);
      } else {
        createAndOpenNewChat();
      }
    };
    deleteChat();
  };

  const handleNewChatClick = () => {
    if (chatMetadatas?.length === 1 && chatMetadatas?.at(0)?.messageCount === 1) return;
    else if (chatMetadatas?.at(0)?.messageCount === 1) {
      openChat(chatMetadatas.at(0)?.id);
    } else {
      createAndOpenNewChat();
    }
  };

  return (
    <ChatBotView
      chatMetadatas={chatMetadatas ?? []}
      chatId={chatId}
      handleChatMetadataClick={handleChatMetadataClick}
      handleDeleteChatClick={handleDeleteChatClick}
      handleNewChatClick={handleNewChatClick}
    />
  );
};

export default ChatBotManager;
