import { Card, Grid, Skeleton, Stack, Typography } from '@mui/material';
import { t } from 'i18next';
import { Row } from '../../../components';
import { formatWholeDollars } from '../../../helpers/utilityFunctions';
import { Colors } from '../../../themes';
import { Domain } from '@3nickels/data-modules';
import { useMemo } from 'react';
import { Trans } from 'react-i18next';

interface LeftoverCardProps {
  originalBudget: Domain.BudgetOverview | undefined;
  recommendedBudget: Domain.BudgetOverview | undefined;
  showCurrent: boolean;
}

const LeftoverCard: React.FC<LeftoverCardProps> = ({
  originalBudget,
  recommendedBudget,
  showCurrent,
}) => {
  const adviceLoaded =
    typeof recommendedBudget !== 'undefined' && typeof originalBudget !== 'undefined';
  const leftover = useMemo(() => {
    return recommendedBudget?.leftOverAmount === 0
      ? 'zero'
      : recommendedBudget?.leftOverAmount && recommendedBudget?.leftOverAmount < 0
      ? 'negative'
      : 'positive';
  }, [recommendedBudget?.leftOverAmount]);

  return (
    <Stack spacing={3}>
      <Card className='leftover-card' style={{ minHeight: '100px' }}>
        <Grid item sm={11} display='flex' flexDirection='column' alignItems='center'>
          <Grid container flexDirection='row'>
            <Grid item mt={3} sm={1} />
            <Grid item mt={3} sm={3}>
              <Typography color='secondary' variant='p18Bold'>
                {t('YourLeftoverAmount')}
              </Typography>
            </Grid>
            <Grid item mt={3} sm={8} />
            <Grid item mt={3} sm={1} />
            <Grid mt={2} item sm={4}>
              {adviceLoaded ? (
                <>
                  {leftover === 'zero' && (
                    <Typography color='secondary' variant='p16'>
                      {t('ZeroLeftover')}
                    </Typography>
                  )}
                  {leftover === 'positive' && (
                    <Typography color='secondary' variant='p16'>
                      {t('PositiveLeftover')}
                    </Typography>
                  )}
                  {leftover === 'negative' && (
                    <Typography color='error' variant='p16'>
                      {t('NegativeLeftover')}
                    </Typography>
                  )}
                </>
              ) : (
                <Skeleton variant='rounded' height='38px' />
              )}
            </Grid>

            <Grid item mt={2} sm={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Row style={{ justifyContent: 'center' }}>
                {adviceLoaded && showCurrent && (
                  <Typography
                    color={Colors.tertiaryDark}
                    variant='p20'
                    fontFamily='Montserrat'
                    style={{ display: 'inline' }}>
                    <Trans
                      i18nKey='StyledX/Period'
                      values={{
                        x: formatWholeDollars(originalBudget?.leftOverAmount ?? 0),
                        period: t('mo'),
                      }}
                      components={{
                        Styled: (
                          <Typography
                            color={Colors.tertiaryDark}
                            variant='p30'
                            fontFamily='Montserrat'
                            style={{ display: 'inline' }}
                          />
                        ),
                      }}
                    />
                  </Typography>
                )}
              </Row>
            </Grid>
            <Grid item mt={2} sm={1} />
            <Grid
              item
              mt={2}
              sm={3}
              style={{
                display: adviceLoaded ? 'flex' : undefined,
                justifyContent: 'flex-end',
              }}>
              {adviceLoaded ? (
                <Row style={{ justifyContent: 'center' }}>
                  <Typography
                    color={leftover === 'negative' ? Colors.errorBase : 'secondary'}
                    variant='p20'
                    fontFamily='Montserrat'
                    fontWeight={300}
                    style={{ display: 'inline' }}>
                    <Trans
                      i18nKey='StyledX/Period'
                      values={{
                        x: formatWholeDollars(recommendedBudget?.leftOverAmount ?? 0),
                        period: t('mo'),
                      }}
                      components={{
                        Styled: (
                          <Typography
                            color={leftover === 'negative' ? Colors.errorBase : 'secondary'}
                            variant='h1'
                            fontFamily='Montserrat'
                            style={{ display: 'inline' }}
                          />
                        ),
                      }}
                    />
                  </Typography>
                </Row>
              ) : (
                <Skeleton variant='rounded' height='38px' />
              )}
            </Grid>
            <Grid item mt={3} sm={12} />
          </Grid>
        </Grid>
      </Card>
    </Stack>
  );
};

export default LeftoverCard;
