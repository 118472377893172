import { Box, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import TextInput from '../../../../components/form/TextInput';
import ToggleRadioButtonGroup from '../../../../components/form/ToggleRadioButtonGroup';
import { Spacing } from '../../../../themes';
import { Data, Domain } from '@3nickels/data-modules';
import { useFormContext } from 'react-hook-form';
import { t } from 'i18next';

export type PensionPlanDetailsFormProps = {
  details?: Data.Pensions.PensionFormData;
  onSubmit: (values: Data.InvestmentAccountBasicFormData) => Promise<void>;
};

const PensionPlanDetailsForm: React.FC<PensionPlanDetailsFormProps> = ({ details }) => {
  return (
    <Box>
      <Stack spacing={Spacing.xxs}>
        <DetailForm
          details={{
            ...details,
            payoutMethod: details?.payoutMethod ?? Domain.PensionPayoutMethodEnum.Monthly,
          }}
        />
      </Stack>
    </Box>
  );
};

declare type DetailFormProps = {
  details?: Data.Pensions.PensionFormData;
};

function useFormSubscription<Model extends object, Prop>(
  key: keyof Model,
  handler: (val: Prop) => void
) {
  const { watch } = useFormContext();
  useEffect(() => {
    const subscription = watch((values, { name }) => {
      if (name === key) {
        handler(values[key as string] as Prop);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, key, handler]);
}

const DetailForm: React.FC<DetailFormProps> = ({ details }) => {
  const { formState, watch } = useFormContext();
  const [payoutMethod, setPayoutMethod] = useState(
    details?.payoutMethod ?? Domain.PensionPayoutMethodEnum.Monthly
  );

  useFormSubscription<Data.Pensions.PensionFormData, Domain.PensionPayoutMethodEnum>(
    'payoutMethod',
    setPayoutMethod
  );

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === 'payoutMethod') {
        setPayoutMethod(value.payoutMethod);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const ageLabel =
    payoutMethod === Domain.PensionPayoutMethodEnum.Monthly
      ? t('AgePaymentsBegin')
      : t('AgeLumpSumPaymentExpected');
  const paymentLabel =
    payoutMethod === Domain.PensionPayoutMethodEnum.Monthly
      ? t('ExpectedMonthlyPayments')
      : t('ExpectedLumpSumPensionIncome');

  return (
    <>
      <ToggleRadioButtonGroup<Data.Pensions.PensionFormData>
        error={formState.errors.payoutMethod !== undefined}
        helperText={formState.errors.payoutMethod?.message?.toString()}
        label='HowWillYouReceiveYourPayout'
        name='payoutMethod'
        row
        defaultValue={details?.payoutMethod}
        items={[
          { label: 'Monthly', value: Domain.PensionPayoutMethodEnum.Monthly },
          { label: 'LumpSum', value: Domain.PensionPayoutMethodEnum.LumpSum },
        ]}
      />
      <TextInput<Data.Pensions.PensionFormData>
        error={formState.errors.agePensionBegins !== undefined}
        helperText={formState.errors.agePensionBegins?.message?.toString()}
        defaultValue={details?.agePensionBegins ?? 65}
        max={99}
        name='agePensionBegins'
        label={ageLabel}
        type='integer'
      />
      <TextInput<Data.Pensions.PensionFormData>
        error={formState.errors.expectedMonthlyPayments !== undefined}
        helperText={formState.errors.expectedMonthlyPayments?.message?.toString()}
        defaultValue={details?.expectedMonthlyPayments}
        name='expectedMonthlyPayments'
        label={paymentLabel}
        type='dollar'
      />
      <ToggleRadioButtonGroup<Data.Pensions.PensionFormData>
        error={formState.errors.adjustForInflation !== undefined}
        helperText={formState.errors.adjustForInflation?.message?.toString()}
        label='DoesYourPensionAdjustForInflation'
        name='adjustForInflation'
        row
        defaultValue={details?.adjustForInflation?.toString()}
        items={[
          { label: 'Yes', value: 'true' },
          { label: 'No', value: 'false' },
        ]}
      />
    </>
  );
};

export default PensionPlanDetailsForm;
