import { useService } from '@aesop-fables/containr-react';
import { PaymentApi } from '../../api/apis/PaymentApi';
import { ApiKeys } from '../../api/apis/ApiKeys';
import { useLoading } from '../useLoading';

export const useReceiptUrl = () => {
  const { setLoading } = useLoading();
  const paymentApi = useService<PaymentApi>(ApiKeys.Payment);

  const viewReceipt = async (chargeId: number) => {
    setLoading(true);
    try {
      const { data: url } = await paymentApi.getReceiptUrl(chargeId);
      if (url) {
        window.open(url);
      }
    } catch (error) {
      console.error('Error fetching receipt url:', error);
    } finally {
      setLoading(false);
    }
  };

  return { viewReceipt };
};
