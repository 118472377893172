import React from 'react';
import GroupPanels, { Group } from '../../components/GroupPanels';
import { Stack } from '@mui/material';
import { Svgs } from '../../assets/svg';
import { Hooks } from '@3nickels/data-modules';
import { useNavigate } from 'react-router-dom';
import { Spacing } from '../../themes';
import HorizontalLineIcon from '../../components/HorizontalLineIcon';

const BudgetDetailsSidePanel: React.FC = () => {
  const navigate = useNavigate();
  const plaidWizard = Hooks.usePlaidWizard();
  const linkedAccounts = Hooks.useLinkedAccounts();
  const cardWidth = 330;

  const handleSpendingOverview = () => {
    if (linkedAccounts.length === 0) {
      plaidWizard.reset();
      navigate('/account-details/link-account');
    } else {
      navigate('/budget-details/spending');
    }
  };

  const budgetPanelOptions: Group[] = [
    {
      textKey:
        linkedAccounts.length === 0
          ? 'WannaKnowWhereYourMoneysGoing?'
          : 'AverageSpendingOverThreeMonths',
      subTextKey:
        linkedAccounts.length === 0
          ? 'LinkAccountsToSeeYourOverview'
          : 'ViewMonthlySpendingByCategory',
      icon: (
        <HorizontalLineIcon>
          <Svgs.DisplaySmallCustomLoans />
        </HorizontalLineIcon>
      ),
      style: { minWidth: `${cardWidth}px` },
      onClick: handleSpendingOverview,
    },
    {
      textKey: 'BudgetAverages',
      subTextKey: 'CompareYourBudgetToNationalAverages',
      icon: (
        <HorizontalLineIcon>
          <Svgs.DisplaySmallCustomBudget />
        </HorizontalLineIcon>
      ),
      style: { minWidth: `${cardWidth}px` },
      onClick: () => navigate('averages'),
    },
  ];

  return (
    <Stack spacing={2} mt={3 - Spacing.xxs}>
      <GroupPanels
        variant='Popover'
        groups={budgetPanelOptions}
        groupsPerRow={1}
        defaultGroupRows={[[budgetPanelOptions[0]], [budgetPanelOptions[1]]]}
      />
    </Stack>
  );
};
export default BudgetDetailsSidePanel;
