import { useTranslation } from 'react-i18next';
import { SummaryCardProps } from '../../Types';
import { Grid, Typography } from '@mui/material';
import CardDisplay from '../../../../components/panels/CardDisplay';
import { capitalizeFirstLetter, formatWholeDollars } from '../../../../helpers/utilityFunctions';
import { Data } from '@3nickels/data-modules';

export interface CashAccountBasicsCardProps extends SummaryCardProps {
  cashAccountBasics: Data.CashAccounts.CashAccountBasicFormData;
  includeSpouse?: boolean;
}

const CashAccountBasicsCard: React.FC<CashAccountBasicsCardProps> = ({
  onEdit,
  cashAccountBasics,
  includeSpouse,
}) => {
  const { t } = useTranslation();
  const accountType = Data.CashAccounts.mapCashAccountEnumToType(cashAccountBasics?.accountType);
  const accountTypeLabel = accountType.replace(/ /g, '');

  return (
    <CardDisplay headerKey='AccountBasics' actionContent='Edit' actionClick={onEdit}>
      <Grid container spacing={2}>
        <Grid item xs>
          <Typography variant='h3' component='h3' color='primary'>
            {t('CashAccountNickname')}
          </Typography>
          <Typography color='secondary'>{cashAccountBasics?.name}</Typography>
        </Grid>
        <Grid item xs>
          <Typography variant='h3' component='h3' color='primary'>
            {t('TypeOfCashAccount')}
          </Typography>
          <Typography color='secondary'>{t(accountTypeLabel)}</Typography>
        </Grid>
        {includeSpouse && (
          <Grid item xs>
            <Typography variant='h3' component='h3' color='primary'>
              {t('AccountOwner')}
            </Typography>
            <Typography color='secondary'>
              {t(capitalizeFirstLetter(cashAccountBasics?.owner ?? ''))}
            </Typography>
          </Grid>
        )}
        <Grid item xs>
          <Typography variant='h3' component='h3' color='primary'>
            {t('CurrentBalance')}
          </Typography>
          <Typography color='secondary'>
            {formatWholeDollars(cashAccountBasics?.principle ?? 0)}
          </Typography>
        </Grid>
        <Grid item xs />
        {!includeSpouse && <Grid item xs />}
      </Grid>
    </CardDisplay>
  );
};

export default CashAccountBasicsCard;
