import {
  ConfiguredDataSource,
  DataCache,
  DataCatalogRegistration,
  DataCatalogRegistrations,
  DataCompartmentOptions,
  ISubject,
  Predicate,
  createDataCache,
  createDataModule,
  injectDataCache,
} from '@aesop-fables/scrinium';
import { ApiKeys } from '../../api/apis/ApiKeys';
import {
  ChargeData,
  PaymentApi,
  PaymentMethodsData,
  PaymentStatusData,
} from '../../api/apis/PaymentApi';
import { inject } from '@aesop-fables/containr';
import { Authentication, PredicateListener } from '@3nickels/data-modules';
import { defaultIfEmpty, filter, map, Observable } from 'rxjs';
import { namespacedService } from '../../utils';
import { WebDataTokens } from '../../WebDataTokens';

export const PaymentServices = {
  PaymentStatus: namespacedService('payment/subjects/PaymentStatus'),
  PaymentMethods: namespacedService('payment/subjects/PaymentMethods'),
  DefaultPaymentMethod: namespacedService('payment/subjects/DefaultPaymentMethod'),
  PaymentHistory: namespacedService('payment/subjects/PaymentHistory'),
};

export interface PaymentCompartments {
  paymentStatus: DataCompartmentOptions<PaymentStatusData | undefined>;
  paymentMethods: DataCompartmentOptions<PaymentMethodsData[] | undefined>;
  defaultPaymentMethod: DataCompartmentOptions<string | undefined>;
  paymentHistory: DataCompartmentOptions<ChargeData[] | undefined>;
}

export class PaymentDataListener extends PredicateListener {
  constructor(
    @inject(Authentication.AuthenticationServices.Context)
    context: Authentication.IAuthenticationContext,
    @injectDataCache(WebDataTokens.Payment.key)
    private readonly cache: DataCache<PaymentCompartments>
  ) {
    super(context.isAuthenticated$);
  }

  onNext(): void {
    const keys: (keyof PaymentCompartments)[] = [
      'paymentStatus',
      'paymentMethods',
      'defaultPaymentMethod',
      'paymentHistory',
    ];
    Promise.all([keys.map((key) => this.cache.reload(key))]);
  }
}

class PaymentDataModule implements DataCatalogRegistration {
  constructor(
    @inject(ApiKeys.Payment) private readonly paymentApi: PaymentApi,
    @inject(Authentication.AuthenticationServices.IsAuthenticated)
    private readonly isAuthenticated: Predicate
  ) {}

  defineData(): DataCatalogRegistrations {
    const cache = createDataCache<PaymentCompartments>(WebDataTokens.Payment, {
      paymentStatus: {
        loadingOptions: {
          strategy: 'lazy',
          predicate: this.isAuthenticated,
        },
        source: new ConfiguredDataSource(async () => {
          const { data } = await this.paymentApi.getPaymentStatus();
          return data;
        }),
        defaultValue: undefined,
      },
      paymentMethods: {
        loadingOptions: {
          strategy: 'lazy',
          predicate: this.isAuthenticated,
        },
        source: new ConfiguredDataSource(async () => {
          const { data } = await this.paymentApi.getAllPaymentMethods();
          return data;
        }),
        defaultValue: undefined,
      },
      defaultPaymentMethod: {
        loadingOptions: {
          strategy: 'lazy',
          predicate: this.isAuthenticated,
        },
        source: new ConfiguredDataSource(async () => {
          const { data } = await this.paymentApi.getDefaultPaymentMethod();
          return data;
        }),
        defaultValue: undefined,
      },
      paymentHistory: {
        loadingOptions: {
          strategy: 'lazy',
          predicate: this.isAuthenticated,
        },
        source: new ConfiguredDataSource(async () => {
          const { data } = await this.paymentApi.getTransactionsHistory();
          return data;
        }),
        defaultValue: undefined,
      },
    });
    return {
      caches: [cache],
    };
  }
}

export const withPaymentData = createDataModule(PaymentDataModule);

export class PaymentStatus implements ISubject<PaymentStatusData> {
  constructor(
    @injectDataCache(WebDataTokens.Payment.key)
    private readonly cache: DataCache<PaymentCompartments>
  ) {}
  createObservable(): Observable<PaymentStatusData> {
    return this.cache.observe$<PaymentStatusData>('paymentStatus');
  }
}

export class PaymentMethods implements ISubject<PaymentMethodsData[]> {
  constructor(
    @injectDataCache(WebDataTokens.Payment.key)
    private readonly cache: DataCache<PaymentCompartments>
  ) {}
  createObservable(): Observable<PaymentMethodsData[]> {
    return this.cache.observe$<PaymentMethodsData[]>('paymentMethods');
  }
}

export class PaymentHistory implements ISubject<ChargeData[]> {
  constructor(
    @injectDataCache(WebDataTokens.Payment.key)
    private readonly cache: DataCache<PaymentCompartments>
  ) {}

  createObservable(): Observable<ChargeData[]> {
    return this.cache.observe$<ChargeData[]>('paymentHistory').pipe(
      filter((x) => x !== undefined),
      defaultIfEmpty([]),
      map((x) => {
        return x.sort((a, b) => {
          const dateA = new Date(a.charge.datePaid);
          const dateB = new Date(b.charge.datePaid);
          return dateB.getTime() - dateA.getTime();
        });
      })
    );
  }
}

export class DefaultPaymentMethod implements ISubject<string> {
  constructor(
    @injectDataCache(WebDataTokens.Payment.key)
    private readonly cache: DataCache<PaymentCompartments>
  ) {}

  createObservable(): Observable<string> {
    return this.cache.observe$<string>('defaultPaymentMethod');
  }
}
