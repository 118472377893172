/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import { Api } from '@3nickels/data-modules';
import { useFormContext, UseFormReturn } from 'react-hook-form';
import { Button } from '../../../../components/buttons/Button';
import SliderPercent from '../../../../components/form/SliderPercent';
import ToggleRadioButtonGroup from '../../../../components/form/ToggleRadioButtonGroup';
import HomeImprovementHelp from '../modals/HomeImprovementHelp';
import ClosingCostsHelp from '../modals/ClosingCostsHelp';
import { cleanWholeNumberStr } from '../../../../helpers/utilityFunctions';
import SliderInputTypeToggle from '../../../../components/SliderInputTypeToggle';
import { t } from 'i18next';

type HomeSaleProceedsCalculatorFormProps = {
  methods: UseFormReturn<Api.AdviceHouseSaleNetRest, any, undefined>;
  onSubmit: (values: Api.AdviceHouseSaleNetRest) => Promise<void>;
  results: Api.HouseSaleNetResultRest | undefined;
  defaultValues: Api.AdviceHouseSaleNetRest;
};

const HomeSaleProceedsCalculatorForm: React.FC<HomeSaleProceedsCalculatorFormProps> = ({
  methods,
  onSubmit,
  results,
  defaultValues,
}) => {
  const { formState, watch, setValue } = useFormContext();
  const [agentFeesPercent, setAgentFeesPercent] = useState<boolean>(true);
  const [closingCostPercent, setClosingCostPercent] = useState<boolean>(true);
  const [usedTaxExclusion, setUsedTaxExclusion] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    if (defaultValues) {
      setUsedTaxExclusion(defaultValues.takenHomeSalesTaxExclusion);
      methods.setValue('takenHomeSalesTaxExclusion', defaultValues.takenHomeSalesTaxExclusion);
      methods.setValue('livedAtLeastTwoYears', defaultValues.livedAtLeastTwoYears);
    }
  }, [defaultValues]);

  const onClick = methods.handleSubmit((data) => {
    onSubmit(data);
  });

  // update closingCostDollar
  useEffect(() => {
    const subscription = watch((values, { name }) => {
      if (closingCostPercent && (name === 'closingCostPercentage' || name === 'homeSalePrice')) {
        const homeSalePrice = cleanWholeNumberStr(values.homeSalePrice);
        const closingCostPercent = cleanWholeNumberStr(values.closingCostPercentage);
        const closingCostDollar = (closingCostPercent / 100) * homeSalePrice;
        setValue('closingCostDollar', closingCostDollar);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, closingCostPercent]);

  // update closingCostPercent
  useEffect(() => {
    const subscription = watch((values, { name }) => {
      if (!closingCostPercent && (name === 'closingCostDollar' || name === 'homeSalePrice')) {
        const homeSalePrice = cleanWholeNumberStr(values.homeSalePrice);
        if (homeSalePrice > 0) {
          const closingCostDollar = cleanWholeNumberStr(values.closingCostDollar);
          const closingCostPercent = (closingCostDollar / homeSalePrice) * 100;
          setValue('closingCostPercentage', closingCostPercent);
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, closingCostPercent]);

  // update agentFeesDollar
  useEffect(() => {
    const subscription = watch((values, { name }) => {
      if (agentFeesPercent && (name === 'agentFeesPercentage' || name === 'homeSalePrice')) {
        const homeSalePrice = cleanWholeNumberStr(values.homeSalePrice);
        const agentFeesPercent = cleanWholeNumberStr(values.agentFeesPercentage);
        const agentFeesDollar = (agentFeesPercent / 100) * homeSalePrice;
        setValue('agentFeesDollar', agentFeesDollar);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, agentFeesPercent]);

  // update agentFeesPercent
  useEffect(() => {
    const subscription = watch((values, { name }) => {
      if (!agentFeesPercent && (name === 'agentFeesDollar' || name === 'homeSalePrice')) {
        const homeSalePrice = cleanWholeNumberStr(values.homeSalePrice);
        if (homeSalePrice > 0) {
          const agentFeesDollar = cleanWholeNumberStr(values.agentFeesDollar);
          const agentFeesPercent = (agentFeesDollar / homeSalePrice) * 100;
          setValue('agentFeesPercentage', agentFeesPercent);
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, agentFeesPercent]);

  return (
    <Grid className='tool-form-container'>
      <Typography variant='p18Bold' color='secondary'>
        {t('HomeInfo')}
      </Typography>
      <SliderPercent<Api.AdviceHouseSaleNetRest>
        error={formState.errors.homeSalePrice !== undefined}
        helperText={formState.errors.homeSalePrice?.message?.toString()}
        label='HomeSalePrice'
        name='homeSalePrice'
        type='dollar'
        defaultValue={defaultValues.homeSalePrice}
        initialMax={1000000}
        step={10000}
      />
      <SliderPercent<Api.AdviceHouseSaleNetRest>
        error={formState.errors.homePurchasePrice !== undefined}
        helperText={formState.errors.homePurchasePrice?.message?.toString()}
        label='HomePurchasePrice'
        name='homePurchasePrice'
        type='dollar'
        defaultValue={defaultValues.homePurchasePrice}
        initialMax={1000000}
        step={10000}
      />
      <SliderPercent<Api.AdviceHouseSaleNetRest>
        error={formState.errors.eligibleHomeImprovement !== undefined}
        helperText={formState.errors.eligibleHomeImprovement?.message?.toString()}
        label='EligibleHomeImprovement'
        name='eligibleHomeImprovement'
        type='dollar'
        defaultValue={defaultValues.eligibleHomeImprovement}
        initialMax={100000}
        step={1000}
        helpContext={<HomeImprovementHelp />}
      />
      <Stack>
        <SliderInputTypeToggle
          onToggle={() => setAgentFeesPercent((prev) => !prev)}
          PercentInput={
            <SliderPercent<Api.AdviceHouseSaleNetRest>
              error={formState.errors.agentFeesPercentage !== undefined}
              helperText={formState.errors.agentFeesPercentage?.message?.toString()}
              label='AgentFees'
              name='agentFeesPercentage'
              type='percent'
              precision={3}
              defaultValue={defaultValues?.agentFeesPercentage ?? 6}
            />
          }
          DollarInput={
            <SliderPercent<Api.AdviceHouseSaleNetRest>
              error={formState.errors.agentFeesDollar !== undefined}
              helperText={formState.errors.agentFeesDollar?.message?.toString()}
              label='AgentFees'
              name='agentFeesDollar'
              type='dollar'
              precision={3}
              max={1000000}
              step={10000}
              defaultValue={defaultValues?.agentFeesDollar}
            />
          }
        />
      </Stack>

      <Stack>
        <SliderInputTypeToggle
          onToggle={() => setClosingCostPercent((prev) => !prev)}
          PercentInput={
            <SliderPercent<Api.AdviceHouseSaleNetRest>
              error={formState.errors.closingCostPercentage !== undefined}
              helperText={formState.errors.closingCostPercentage?.message?.toString()}
              label='Closingcosts'
              name='closingCostPercentage'
              type='percent'
              precision={3}
              defaultValue={defaultValues?.closingCostPercentage ?? 3}
              helpContext={<ClosingCostsHelp />}
            />
          }
          DollarInput={
            <SliderPercent<Api.AdviceHouseSaleNetRest>
              error={formState.errors.closingCostDollar !== undefined}
              helperText={formState.errors.closingCostDollar?.message?.toString()}
              label='Closingcosts'
              name='closingCostDollar'
              type='dollar'
              precision={3}
              max={1000000}
              step={10000}
              defaultValue={defaultValues?.closingCostDollar}
              helpContext={<ClosingCostsHelp />}
            />
          }
        />
      </Stack>

      <Typography variant='p18Bold' color='secondary'>
        {t('Taxes')}
      </Typography>

      <ToggleRadioButtonGroup<Api.AdviceHouseSaleNetRest>
        error={formState.errors.takenHomeSalesTaxExclusion !== undefined}
        helperText={formState.errors.takenHomeSalesTaxExclusion?.message?.toString()}
        label='TakenHomeSalesTaxExclusion'
        name='takenHomeSalesTaxExclusion'
        defaultValue={
          defaultValues.takenHomeSalesTaxExclusion === true
            ? 'true'
            : defaultValues.takenHomeSalesTaxExclusion === false
            ? 'false'
            : undefined
        }
        row
        onChange={(target) => {
          setUsedTaxExclusion(target.value === 'true');
          if (target.value === 'true') methods.setValue('livedAtLeastTwoYears', undefined);
          else methods.setValue('livedAtLeastTwoYears', defaultValues.livedAtLeastTwoYears);
        }}
        items={[
          { label: 'Yes', value: 'true' },
          { label: 'No', value: 'false' },
        ]}
      />
      {usedTaxExclusion === false && (
        <ToggleRadioButtonGroup<Api.AdviceHouseSaleNetRest>
          error={formState.errors.livedAtLeastTwoYears !== undefined}
          helperText={formState.errors.livedAtLeastTwoYears?.message?.toString()}
          label='LivedTwoOfFiveYears'
          name='livedAtLeastTwoYears'
          defaultValue={
            defaultValues.livedAtLeastTwoYears === true
              ? 'true'
              : defaultValues.livedAtLeastTwoYears === false
              ? 'false'
              : undefined
          }
          row
          items={[
            { label: 'Yes', value: 'true' },
            { label: 'No', value: 'false' },
          ]}
        />
      )}
      <Grid container justifyContent='flex-end'>
        <Button
          label={results ? 'Recalculate' : 'Calculate'}
          color='primary'
          style={{ minWidth: 'fit-content', width: '35%' }}
          onClick={onClick}
        />
      </Grid>
    </Grid>
  );
};

export default HomeSaleProceedsCalculatorForm;
