import { useTranslation } from 'react-i18next';
import { SummaryCardProps } from '../../../Types';
import { Grid, Typography } from '@mui/material';
import { capitalizeFirstLetter, formatWholeDollars } from '../../../../../helpers/utilityFunctions';
import CardDisplay from '../../../../../components/panels/CardDisplay';
import { Data, Domain } from '@3nickels/data-modules';

export interface CollegeSavingsPlanBasicsCardProps extends SummaryCardProps {
  planBasics: Data.CollegeSavingsPlans.CollegeSavingsPlanBasicFormData;
  includeSpouse?: boolean;
}

const CollegeSavingsPlanBasicsCard: React.FC<CollegeSavingsPlanBasicsCardProps> = ({
  onEdit,
  planBasics,
  includeSpouse,
}) => {
  const { t } = useTranslation();
  const accountType =
    planBasics.accountType && Domain.collegeSavingsAccountLabels[planBasics.accountType];
  const accountTypeLabel = accountType?.replace(/ /g, '') ?? '';

  return (
    <CardDisplay headerKey='AccountBasics' actionContent='Edit' actionClick={onEdit}>
      <Grid container spacing={2}>
        <Grid item xs>
          <Typography variant='h3' component='h3' color='primary'>
            {t('TypeOfCollegeSavingsPlan')}
          </Typography>
          {planBasics.accountType && (
            <Typography color='secondary'>{t(accountTypeLabel)}</Typography>
          )}
        </Grid>
        <Grid item xs>
          <Typography variant='h3' component='h3' color='primary'>
            {t('CollegeSavingsPlanNickname')}
          </Typography>
          <Typography color='secondary'>{planBasics.name}</Typography>
        </Grid>
        {includeSpouse && (
          <Grid item xs>
            <Typography variant='h3' component='h3' color='primary'>
              {t('AccountOwner')}
            </Typography>
            <Typography color='secondary'>
              {t(capitalizeFirstLetter(planBasics?.owner ?? ''))}
            </Typography>
          </Grid>
        )}
        <Grid item xs>
          <Typography variant='h3' component='h3' color='primary'>
            {t('CurrentBalance')}
          </Typography>
          <Typography color='secondary'>{formatWholeDollars(planBasics.balance ?? 0)}</Typography>
        </Grid>
        <Grid item xs />
        {!includeSpouse && <Grid item xs />}
      </Grid>
    </CardDisplay>
  );
};

export default CollegeSavingsPlanBasicsCard;
