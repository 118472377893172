import { Box } from '@mui/material';
import React from 'react';
import { lake } from '../../../assets/png';
import ToolsStartView from '../ToolsStartView';
import { Svgs } from '../../../assets/svg';
import { Group } from '../../../components/GroupPanels';
import TypesOfHealthInsuranceCard from './cards/TypesOfHealthInsuranceCard';
import HealthInsuranceChecklistCard from './cards/HealthInsuranceChecklistCard';
import { LayoutMeta, withLayoutMeta } from '../../../types/LayoutMeta';
import { ToolsLayoutMeta } from '../ToolsLayout';
import { useExploreGroups } from '../../../hooks/useExploreGroups';
import MedicalBenefitSelectorToolCard from './cards/MedicalBenefitSelectorToolCard';

const MedicalToolStartView: React.FC = () => {
  const explore = useExploreGroups();

  const resources: Group[] = [
    {
      textKey: 'medical-benefit-selector-tool',
      url: 'medical-benefit-selector-tool',
      content: <MedicalBenefitSelectorToolCard />,
    },
    {
      textKey: 'types-of-health-insurance',
      url: 'types-of-health-insurance',
      content: <TypesOfHealthInsuranceCard />,
    },
    {
      textKey: 'health-insurance-checklist',
      url: 'health-insurance-checklist',
      content: <HealthInsuranceChecklistCard />,
    },
  ];

  return (
    <Box display='flex' flexDirection='column' alignItems='center'>
      <ToolsStartView
        color='primary'
        variant='p30'
        display='inline'
        icon={<Svgs.DisplayLargeCustomMedical />}
        label='Medical'
        calculators={[]}
        resources={resources}
        explore={explore}
      />
    </Box>
  );
};

const meta = {
  background: <img src={lake} className='tools-start-view-background' />,
} satisfies LayoutMeta<ToolsLayoutMeta>;

export default withLayoutMeta(MedicalToolStartView, meta);
