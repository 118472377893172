import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useMemo } from 'react';
import { LayoutMeta, withLayoutMeta } from '../../../types/LayoutMeta';
import { frontPageOverview } from '../../../assets/png';
import { HolisticFinancialAdviceLayoutMeta } from '../HolisticFinancialAdviceLayout';
import StartView from '../StartView';
import { Hooks } from '@3nickels/data-modules';
import { useAnimatedNavigation } from '../../../hooks/useControlledAnimation';

const GoalsStartView: React.FC = () => {
  const navigate = useAnimatedNavigation();
  const { t } = useTranslation();
  const holisticAdvice = Hooks.useHolisticAdviceOverview();

  const onComplete = useMemo(() => {
    if (holisticAdvice && holisticAdvice.goalSummaries.length > 0) {
      const goal = holisticAdvice?.goalSummaries[0];
      return () => {
        navigate(`/holistic-advice/goals/${goal?.advisedGoalPlan.id}`);
      };
    }

    return () => {
      navigate('');
    };
  }, [holisticAdvice]);

  if (holisticAdvice?.goalSummaries.length === 0) return null;

  return (
    <Box display='flex' flexDirection='column' alignItems='center'>
      <StartView
        imageSrc={frontPageOverview}
        className='start-screen-text'
        color='primary'
        variant='p30'
        display='inline'
        onComplete={onComplete}>
        {t('NowWalkThroughXOneByOne', { x: t('goals') })}
      </StartView>
    </Box>
  );
};

const meta = {
  nextLocaleKey: 'Next',
} satisfies LayoutMeta<HolisticFinancialAdviceLayoutMeta>;

export default withLayoutMeta(GoalsStartView, meta);
