/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import { Grid, MenuItem } from '@mui/material';
import { t } from 'i18next';
import { Api, Domain } from '@3nickels/data-modules';
import { useFormContext, UseFormReturn } from 'react-hook-form';
import { Button } from '../../../../components/buttons/Button';
import SliderPercent from '../../../../components/form/SliderPercent';
import SelectInput from '../../../../components/form/SelectInput';
import { convertToTranslationKey } from '../../../../helpers/utilityFunctions';

type RealCostOfDebtFormProps = {
  methods: UseFormReturn<Api.DebtRealCostRest, any, undefined>;
  onSubmit: (values: Api.DebtRealCostRest) => Promise<void>;
  results: Api.DebtRealCostResultRest | undefined;
  defaultValues: Api.DebtRealCostRest;
};

const RealCostOfDebtForm: React.FC<RealCostOfDebtFormProps> = ({
  methods,
  onSubmit,
  results,
  defaultValues,
}) => {
  const { formState, setValue } = useFormContext();

  useEffect(() => {
    if (defaultValues) {
      setValue('loanTermMonths', defaultValues.loanTermMonths);
    }
  }, [defaultValues]);

  const onClick = methods.handleSubmit((data) => {
    // TODO add loading spinner for calculate button
    onSubmit(data);
  });

  return (
    <Grid className='tool-form-container'>
      <SliderPercent<Api.DebtRealCostRest>
        error={formState.errors.loanAmount !== undefined}
        helperText={formState.errors.loanAmount?.message?.toString()}
        label='LoanAmount'
        name='loanAmount'
        type='dollar'
        defaultValue={defaultValues.loanAmount}
        initialMax={100000}
        step={1000}
      />
      <SliderPercent<Api.DebtRealCostRest>
        error={formState.errors.interestRate !== undefined}
        helperText={formState.errors.interestRate?.message?.toString()}
        label='InterestRate'
        name='interestRate'
        type='percent'
        defaultValue={defaultValues.interestRate}
        precision={3}
      />
      <SelectInput<Api.DebtRealCostRest>
        error={formState.errors.loanTermMonths !== undefined}
        helperText={formState.errors.loanTermMonths?.message?.toString()}
        label='LoanTerm'
        name='loanTermMonths'
        placeholder='ChooseLoanTerm'
        defaultValue={defaultValues.loanTermMonths}>
        {Domain.loanTermMonthsTypes.map((x) => (
          <MenuItem value={x.key}>{t(convertToTranslationKey(x.value))}</MenuItem>
        ))}
      </SelectInput>
      <Grid container justifyContent='flex-end'>
        <Button
          label={results ? 'Recalculate' : 'Calculate'}
          color='primary'
          style={{ minWidth: 'fit-content', width: '35%' }}
          onClick={onClick}
        />
      </Grid>
    </Grid>
  );
};

export default RealCostOfDebtForm;
