import { Card, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../../components/buttons/Button';
import { errorBase } from '../../../../themes/colors';
import { Domain, Hooks } from '@3nickels/data-modules';
import { Colors } from '../../../../themes';
import { ChartPieVariableRadius } from '../../../../components/ChartPieVariableRadius';
import { AdviceDebtIncomePercentageTypeEnum } from './Types';
import { getAdjustedNetRealEstateIncome } from '../../../budget-details/Types';
import {
  convertToTranslationKey,
  formatPercent,
  formatWholeDollars,
} from '../../../../helpers/utilityFunctions';
import { t } from 'i18next';

interface IncomeChartSectionProps {
  noDebts: boolean;
  results: Domain.DebtIncomePercentageData | undefined;
}

export const IncomeChartSection: React.FC<IncomeChartSectionProps> = ({ noDebts, results }) => {
  const navigate = useNavigate();
  const { income, otherIncome, spouseIncome, spouseOtherIncome, unearnedIncome } =
    Hooks.useFinancialInfo() ?? {};
  const housing = Hooks.useCombinedRealEstateData();
  const realEstateIncome = housing.reduce(
    (partialSum, house) => partialSum + getAdjustedNetRealEstateIncome(house, 'annual'),
    0
  );

  const monthlyBeforeTaxIncome =
    ((income ?? 0) +
      (otherIncome ?? 0) +
      (spouseIncome ?? 0) +
      (spouseOtherIncome ?? 0) +
      (unearnedIncome ?? 0) +
      realEstateIncome) /
    12;
  const monthlyDebtPayments =
    (results?.debtToIncomeRatio ?? 0) * ((monthlyBeforeTaxIncome ?? 0) / 100);
  const leftoverIncome = (monthlyBeforeTaxIncome ?? 0) - monthlyDebtPayments;
  const debtSupported =
    (results?.debtToIncomeRatio ?? 0) < AdviceDebtIncomePercentageTypeEnum.DebtToIncome;
  const data = [
    {
      id: 0,
      label: 'My debt',
      value: (results?.debtToIncomeRatio ?? 0) * ((monthlyBeforeTaxIncome ?? 0) / 100),
    },
    { id: 1, label: 'My leftover income', value: leftoverIncome },
  ];
  const colors = [debtSupported ? Colors.secondaryBase : Colors.accentBase, Colors.disabledBase];

  return noDebts ? (
    <Card className='no-debts-how-much-debt'>
      <Grid
        container
        justifyContent='space-between'
        columnGap='10px'
        flexWrap='nowrap'
        alignItems='center'
        sx={{
          maxWidth: { xs: '100%', sm: '80%' },
        }}>
        <Typography variant='p16' sx={{ color: errorBase }}>
          {t('PleaseAddADebtInTheAccounts')}
        </Typography>
        <Button
          label={'Go to Accounts'}
          color='primary'
          onClick={() => navigate('/account-details')}
        />
      </Grid>
    </Card>
  ) : results ? (
    <Grid
      item
      container
      direction='row'
      alignItems='center'
      sx={{
        height: '210px',
      }}>
      <Grid item style={{ height: '210px' }}>
        <ChartPieVariableRadius
          data={data.map((slice) => ({
            x: slice.label ?? '',
            y: slice.value ?? 0,
            color: colors[slice.id],
          }))}
          padAngle={6}
          staticRadius={140}
          innerRadius={80}
          tooltipText={({ slice }) => [
            `${t(convertToTranslationKey(slice.data.xName))}:`,
            '\u200B',
            `${formatWholeDollars(slice.value)}/${t('mo')}`,
          ]}
          tooltipStyles={[
            {
              fill: ({ datum }) =>
                datum.x === 'My leftover income' ? Colors.disabledDark : datum.color,
              fontFamily: 'Roboto',
              fontWeight: 'normal',
              fontSize: '20px',
            },
            {},
            {
              fill: ({ datum }) =>
                datum.x === 'My leftover income' ? Colors.disabledDark : datum.color,
              fontFamily: 'Roboto',
              fontWeight: 'bold',
              fontSize: '25px',
            },
          ]}
        />
      </Grid>

      <Typography
        mr={9}
        ml={8}
        variant='p40Bold'
        color={debtSupported ? 'secondary' : Colors.errorBase}
        fontFamily='Montserrat'>
        {formatPercent(results?.debtToIncomeRatio ?? 0)}
      </Typography>

      <Grid item direction='column' width='40%'>
        <Typography mb={1} variant='p16Bold' color={debtSupported ? 'secondary' : Colors.errorBase}>
          {t('DebtToIncomeRatio')}
        </Typography>
        <Typography variant='p18' color={debtSupported ? 'secondary' : Colors.errorBase}>
          {debtSupported ? t('GreatJobDebtLow') : t('StrainOnIncome')}
        </Typography>
      </Grid>
    </Grid>
  ) : (
    <></>
  );
};
