import * as Page from './style';
import { Spacer } from '../components';
import { logo3NSmall as logo } from '../assets/png';
import { Link } from 'react-router-dom';
import { PHONE_NUMBER } from '../pages/contact-us/ContactOptions';
import { PageContentWithBackground } from '../pages/layout/PageContentWithBackground';
import { t } from 'i18next';

const CancellationPolicy = () => {
  return (
    <PageContentWithBackground useContainer={false}>
      <Page.Container>
        <Page.LogoContainer>
          <Link to='/'>
            <img style={{ maxWidth: '220px' }} src={logo} alt='Logo' />
          </Link>
        </Page.LogoContainer>
        <Spacer height='s25' />
        <Page.Header>{t('CancellationAndRefundPolicy')}</Page.Header>
        <Spacer height='xs' />
        <Page.PolicyText>{t('YouMayCancelAtAnyTime')}</Page.PolicyText>
        <Spacer height='xs' />
        <Page.PolicyText>
          {t('RefundsOnNewSubscriptionsAreAvailableOnlyIfYouHaveNotCompletedTheSetup')}
        </Page.PolicyText>
        <Spacer height='xs' />
        <Page.PolicyText>
          {t('IfYouWouldLikeToRequestARefundContactSupport', { PHONE_NUMBER })}
        </Page.PolicyText>
      </Page.Container>
    </PageContentWithBackground>
  );
};

export default CancellationPolicy;
