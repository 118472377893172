import { useService } from '@aesop-fables/containr-react';
import { Grid, Typography, Card } from '@mui/material';
import dayjs, { duration } from 'dayjs';
import { useState, useEffect, useRef } from 'react';
import { Modal, Spacer } from '../components';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Authentication, Data, Hooks } from '@3nickels/data-modules';
import { API_BASE_URL } from '../configuration/Environment';
import Cookies from 'js-cookie';
import { t } from 'i18next';

dayjs.extend(duration);

const SessionTimeoutModal: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(0);
  const formRef = useRef<HTMLFormElement>(null);
  const commands = Hooks.useCommands();

  const autoLogoutService = useService<Authentication.AutoLogoutService>(
    Authentication.AuthenticationServices.AutoLogoutService
  );
  const authContext = useService<Authentication.WebSessionAuthenticationContext>(
    Authentication.AuthenticationServices.Context
  );

  useEffect(() => {
    const checkInterval = setInterval(() => {
      setTimeRemaining(autoLogoutService.getTimeUntilLogout());
      const shouldBeOpen = autoLogoutService.isInWarningPeriod();
      if (shouldBeOpen !== open) {
        setOpen(shouldBeOpen);
      }
    }, 1000);
    return () => clearInterval(checkInterval);
  }, [autoLogoutService, open]);

  const handleKeepWorking = async (): Promise<void> => {
    await commands.relay(Data.People.Commands.ReloadAccount);
    setOpen(false);
  };

  const handleLogout = (): void => {
    setOpen(false);
    authContext.setIsAuthenticated(false);
    formRef.current?.submit();
  };

  return (
    <>
      <form
        ref={formRef}
        method='post'
        action={`${API_BASE_URL}/logout`}
        style={{ display: 'none' }}>
        <input type='hidden' name='_csrf' value={Cookies.get('XSRF-TOKEN')} readOnly />
      </form>
      <Modal
        title={t('AreYouStillWorking').toString()}
        titleStyle={{ color: 'primary.main' }}
        primaryButtonText={t('KeepWorking').toString()}
        secondaryButtonText={t('SignOut').toString()}
        open={open}
        setOpen={setOpen}
        handleSave={handleKeepWorking}
        handleCancel={handleLogout}
        onClose={handleKeepWorking}>
        <Grid display='flex' flex={1} flexDirection='column' width='500px'>
          <Typography variant='p14' color='secondary'>
            {t('YourSessionIsAboutToExpire')}
          </Typography>
          <Spacer height='sm' />
          <Card variant='innerCard'>
            <Grid display='flex' flex={1} p={2.5} columnGap={1} alignItems='center'>
              <AccessTimeIcon />
              <Grid display='flex' flex={1} alignItems='center' justifyContent='space-between'>
                <Typography variant='p16'>{t('TimeBeforeAutoLogout')}</Typography>
                <Typography variant='p18Bold'>
                  {dayjs.duration(timeRemaining).format('HH:mm:ss')}
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Modal>
    </>
  );
};

export default SessionTimeoutModal;
