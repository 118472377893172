import { Box, Stack } from '@mui/material';
import React, { useEffect } from 'react';
import TextInput from '../../../../components/form/TextInput';
import ToggleRadioButtonGroup from '../../../../components/form/ToggleRadioButtonGroup';
import { Margins, Spacing } from '../../../../themes';
import { Data, Domain, Hooks } from '@3nickels/data-modules';
import { useFormContext } from 'react-hook-form';
import HelpPopover from '../../../../components/HelpPopover';
import { t } from 'i18next';
import BulletedList from '../../../../components/BulletedList';
import { Trans } from 'react-i18next';

export type PensionPlanBasicsFormProps = {
  planBasics?: Data.Pensions.PensionFormData;
  wizard: Data.Pensions.PensionWizard;
  params: Data.Pensions.PensionWizardParams;
  onSubmit: (values: Data.Pensions.PensionFormData) => Promise<void>;
};

const PensionPlanBasicsForm: React.FC<PensionPlanBasicsFormProps> = ({
  planBasics,
  wizard,
  params,
}) => {
  const { formState, watch } = useFormContext();
  const includeSpouse = Hooks.useIncludeSpouse();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onOwnerChange = (owner: Domain.Owner) => {
    wizard.updateParams({ ...params });
    // wizard.updateParams({ ...params, isSpouse: owner === 'spouse' });
  };

  useEffect(() => {
    const subscription = watch((values, { name }) => {
      if (name === 'owner') {
        onOwnerChange(values[name]);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, params]);

  return (
    <Box>
      <Stack spacing={Spacing.xxs}>
        <TextInput<Data.Pensions.PensionFormData>
          error={formState.errors.name !== undefined}
          helperText={formState.errors.name?.message?.toString()}
          defaultValue={planBasics?.name}
          inputProps={{ maxLength: 32 }}
          label='PensionPlanNickname'
          name='name'
          helpContext={
            <HelpPopover title={t('AccountNickname')}>
              <BulletedList
                style={Margins.mt_xxs}
                children={[
                  t('UsedToIdentifyYourPensionPlans'),
                  <Trans
                    i18nKey='CallItWhateverYouWantStyled'
                    components={{
                      Styled: <strong />,
                    }}
                  />,
                ]}
              />
            </HelpPopover>
          }
        />
        {includeSpouse && (
          <ToggleRadioButtonGroup<Data.Pensions.PensionFormData>
            error={formState.errors.owner !== undefined}
            helperText={formState.errors.owner?.message?.toString()}
            label='WhoOwnsThisAccount'
            name='owner'
            row
            defaultValue={planBasics?.owner}
            items={[
              { label: t('Me'), value: 'me' },
              { label: t('Spouse'), value: 'spouse' },
            ]}
          />
        )}
      </Stack>
    </Box>
  );
};

export default PensionPlanBasicsForm;
