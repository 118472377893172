/* eslint-disable @typescript-eslint/no-explicit-any */
import { Backdrop, Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { Data, Domain, Hooks } from '@3nickels/data-modules';
import { useForm } from 'react-hook-form';
import { useRevalidator } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import FormContent from '../../../components/form/FormContent';
import { WizardFooter } from '../../../components/form/WizardFooter';
import { Loading, useLoading } from '../../../hooks/useLoading';
import { EditViewProps, getWizardFooterProps } from '../../EditViewUtils';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import SocialSecurityForm from './SocialSecurityForm';
import { SocialSecurityHelp } from '../../goals/retirement/RetirementGoalForm';
import { Colors } from '../../../themes';
import { t } from 'i18next';

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

const socialSecuritySchema = (includeSpouse: boolean) =>
  Yup.object({
    includeSsi: Yup.boolean().required(t('Required') as string),
    spouseIncludeSsi: includeSpouse
      ? Yup.boolean().required(t('Required') as string)
      : Yup.boolean().notRequired(),
  });

export const SocialSecurityEditWrapper: React.FC<EditViewProps> = ({ editing, onBack }) => {
  const { person } = Hooks.useCombinedSelfData();
  const spouse = Hooks.useSpouseData();

  if (!person?.accountId || (person.includeSpouse && !spouse?.spouseId)) {
    return (
      <Backdrop open>
        <Loading />
      </Backdrop>
    );
  }

  return (
    <SocialSecurityEditView editing={editing} onBack={onBack} person={person} spouse={spouse} />
  );
};

interface SocialSecurityEditProps extends EditViewProps {
  person: Domain.SelfData;
  spouse?: Domain.SpouseData;
}

const SocialSecurityEditView: React.FC<SocialSecurityEditProps> = ({
  editing,
  onBack,
  person,
  spouse,
}) => {
  const { t } = useTranslation();
  const revalidator = useRevalidator();
  const methods = useForm<Data.People.SocialSecurityStatus>({
    defaultValues: {
      includeSsi: person.includeSsi,
      spouseIncludeSsi: spouse?.includeSsi ?? true,
    },
    resolver: yupResolver(socialSecuritySchema(person.includeSpouse ?? false)),
  });
  const { setLoading } = useLoading();
  const commands = Hooks.useCommands();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onSubmit = async (values: any) => {
    setLoading(true);
    await commands.execute(Data.People.Commands.UpdateSocialSecurity, {
      ...values,
    });
    revalidator.revalidate(); // rerun route loader to get latest data
    try {
      if (editing && onBack) {
        onBack();
        return;
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormContent formProviderProps={methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Box>
          <SocialSecurityHelp
            titleStyle={{ fontSize: '30px', color: Colors.primaryBase, textDecoration: 'none' }}
          />
          {!editing && (
            <Typography className='subtitle' color='secondary'>
              {t('MySocialSecuritySub')}
            </Typography>
          )}

          <Grid container justifyContent='center' mt={2}>
            <Grid item sm={editing ? 10 : 6}>
              <SocialSecurityForm
                ssiStatus={{
                  includeSsi: person.includeSsi,
                  spouseIncludeSsi: spouse?.includeSsi ?? true,
                }}
              />
            </Grid>
          </Grid>
        </Box>
        <WizardFooter
          color={editing ? 'primary' : undefined}
          disableBack={false}
          onBack={onBack}
          {...getWizardFooterProps('Save', editing)}
        />
      </form>
    </FormContent>
  );
};

export default SocialSecurityEditView;
