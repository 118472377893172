import { formatWholeDollars, Hooks } from '@3nickels/data-modules';
import {
  ChartStateHoneycomb,
  ChartStateHoneycombCellData,
} from '../../../../components/ChartStateHoneycomb';
import { useMemo } from 'react';
import { Colors } from '../../../../themes';
import { Loading } from '../../../../hooks/useLoading';
import { LayoutMeta, withLayoutMeta } from '../../../../types/LayoutMeta';
import { ToolsLayoutMeta } from '../../ToolsLayout';
import { Grid, Stack, Typography } from '@mui/material';
import ToolHeader from '../../../../components/ToolHeader';
import { CostofLivingModal } from '../modals/CostOfLivingModal';
import { RangeLegend } from '../../../../components/RangeLegend';
import { useTranslation } from 'react-i18next';
import SortingTable from '../../../../components/SortingTable';

const CostOfLivingView: React.FC = () => {
  const { t } = useTranslation();
  const costs = Hooks.useCostOfLivings();
  const rentVersusBuys = Hooks.useRentVersusBuys();
  const initialized = useMemo(() => costs && rentVersusBuys, [costs, rentVersusBuys]);

  const segmentColors = [
    Colors.primaryLightest,
    Colors.primaryLighter,
    Colors.primaryLight,
    Colors.primaryBase,
    Colors.primaryDark,
  ];

  const segmentTextColors = [
    Colors.secondaryBase,
    Colors.secondaryBase,
    Colors.tertiaryBase,
    Colors.tertiaryBase,
    Colors.tertiaryBase,
  ];
  const segmentRange = (costs?.length ?? 0) / segmentColors.length;

  const data: ChartStateHoneycombCellData[] | undefined = costs?.map((x, i) => {
    const segmentIndex = segmentColors.length - Math.floor(i / segmentRange) - 1;
    const state = x.abbreviation ?? '';

    return {
      stateAbbreviation: state,
      textColor: segmentTextColors[segmentIndex],
      color: segmentColors[segmentIndex],
      stateName: x.name ?? '',
      stateValue: x.costIndex?.toString() ?? '',
    };
  });

  if (!initialized || !data) {
    return <Loading />;
  }
  return (
    <Grid display='flex' justifyContent='space-between' alignItems='center'>
      <Grid container flexDirection='column'>
        <Stack className='cost-living-container'>
          <ToolHeader title='CostofLivingbyState' helpContent={<CostofLivingModal />} />
          <Grid
            mt={4}
            className='cost-living-content'
            container
            direction='row'
            justifyContent={'space-between'}>
            <Grid
              className='cost-living-chart'
              width='60%'
              item
              xs={1}
              container
              direction='column'>
              <ChartStateHoneycomb data={data} />
            </Grid>
            <Grid className='cost-living-range' item container width='25%'>
              <RangeLegend
                colors={segmentColors.reverse()}
                topLabel='HigherCostOfLiving'
                bottomLabel='LowerCostOfLiving'
              />
            </Grid>
          </Grid>
          <Grid>
            <SortingTable
              headers={[
                { label: 'state', title: 'State' },
                { label: 'costOfLivingIndex', title: 'CostOfLivingIndex' },
                { label: 'avgMortgage', title: 'AvgMonthlyMortgage' },
                { label: 'avgRent', title: 'AvgMonthlyRent' },
              ]}
              defaultSortColumn='costOfLivingIndex'
              data={
                costs?.map((stateCostIndex, i) => {
                  const stateRentBuyInfo = rentVersusBuys?.find(
                    (x) => stateCostIndex.name === x.name
                  );
                  return {
                    id: i,
                    state: toTitleCase(stateCostIndex.name ?? ''),
                    costOfLivingIndex: stateCostIndex.costIndex,
                    avgMortgage: formatWholeDollars(stateRentBuyInfo?.medianMonthlyMortgage ?? 0),
                    avgRent: formatWholeDollars(stateRentBuyInfo?.medianMonthlyRent ?? 0),
                  };
                }) ?? [{}]
              }
            />
            <Typography mt={3} mb={1} variant='p12' color='primary'>
              {t('CostofLivingWorldPopReview')}
            </Typography>
            <Typography variant='p12' color='primary'>
              {t('MortgageRentInfoHowMuchNet')}
            </Typography>
          </Grid>
        </Stack>
      </Grid>
    </Grid>
  );
};

function toTitleCase(str: string) {
  return str.replace(
    /\w*/g,
    (text) => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
  );
}

const meta = {
  background: <img src={undefined} />,
} satisfies LayoutMeta<ToolsLayoutMeta>;

export default withLayoutMeta(CostOfLivingView, meta);
