import { Grid, Stack, Typography } from '@mui/material';
import { t } from 'i18next';
import { Svgs } from '../../../../assets/svg';
import { Spacing } from '../../../../themes';
import ChecklistTemplate from '../../ChecklistTemplate';
import NumberedListItem from '../../../../components/NumberedListItem';
import { Trans } from 'react-i18next';

export const StepFour: React.FC = () => {
  return (
    <ChecklistTemplate
      stepNumber={4}
      headerIcon={
        <Svgs.DisplayLargeCustomMoneyExchange style={{ width: '80px', height: '80px' }} />
      }
      headerText={'OptionsToSell'}
      body={
        <Stack spacing={Spacing.xxxs + 0.5}>
          <Typography color='secondary' variant='p16'>
            {t('ThreeOptions')}
          </Typography>
          <Grid sx={{ listStyle: 'decimal', pl: 4 }}>
            <NumberedListItem style={{ fontWeight: 'bold' }}>
              <Trans
                i18nKey='HireRealEstateAgentStyled'
                components={{
                  Styled: <Typography sx={{ display: 'inline' }} color='secondary' />,
                }}
              />
            </NumberedListItem>
            <NumberedListItem style={{ fontWeight: 'bold' }}>
              <Trans
                i18nKey='GetCashInstantBuyerStyled'
                components={{
                  Styled: <Typography sx={{ display: 'inline' }} color='secondary' />,
                }}
              />
            </NumberedListItem>
            <NumberedListItem style={{ fontWeight: 'bold' }}>
              <Trans
                i18nKey='TheFSBOOptionMeansYouReceiveLess'
                components={{
                  Styled: <Typography sx={{ display: 'inline' }} color='secondary' />,
                }}
              />
            </NumberedListItem>
          </Grid>
        </Stack>
      }
    />
  );
};
