import { useTranslation } from 'react-i18next';
import { SummaryCardProps } from '../../Types';
import { Grid, Typography } from '@mui/material';
import CardDisplay from '../../../../components/panels/CardDisplay';
import { capitalizeFirstLetter, formatWholeDollars } from '../../../../helpers/utilityFunctions';
import { Data } from '@3nickels/data-modules';

export interface HomeBasicsCardProps extends SummaryCardProps {
  homeBasics: Data.RealEstate.HousingEntityBasicFormData;
  includeSpouse?: boolean;
}

const HomeBasicsCard: React.FC<HomeBasicsCardProps> = ({ onEdit, homeBasics, includeSpouse }) => {
  const { t } = useTranslation();

  return (
    <CardDisplay headerKey='HomeBasics' actionContent='Edit' actionClick={onEdit}>
      <Grid container spacing={2}>
        <Grid item xs>
          <Typography variant='h3' component='h3' color='primary'>
            {t('HomeNickname')}
          </Typography>
          <Typography color='secondary'>{homeBasics.name}</Typography>
        </Grid>
        {includeSpouse && (
          <Grid item xs>
            <Typography variant='h3' component='h3' color='primary'>
              {t('HomeOwner')}
            </Typography>
            <Typography color='secondary'>
              {t(capitalizeFirstLetter(homeBasics?.owner ?? ''))}
            </Typography>
          </Grid>
        )}
        <Grid item xs>
          <Typography variant='h3' component='h3' color='primary'>
            {t('CurrentHomeValue')}
          </Typography>
          <Typography color='secondary'>{formatWholeDollars(homeBasics?.value ?? 0)}</Typography>
        </Grid>
        <Grid item xs />
        <Grid item xs />
        {!includeSpouse && <Grid item xs />}
      </Grid>
    </CardDisplay>
  );
};

export default HomeBasicsCard;
