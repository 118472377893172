import ContentView from '../layout/Content';
import { useContentView } from '../layout/ContentViewContext';
import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import { useLayoutMeta } from '../../hooks/useLayoutMeta';
import { Breadcrumbs } from '../../components';
import { Data, Domain, Hooks } from '@3nickels/data-modules';
import { cleanWholeNumberStr } from '../../helpers/utilityFunctions';
import { useObservableQuery } from '@aesop-fables/scrinium';
import LmsSideView from '../learning-management-system/LmsSideView';
import LmsFooter from '../learning-management-system/LmsFooter';
import _ from 'lodash';
import { ToolTypeEnum } from './Types';
import CourseProgressSideView from '../university/components/CourseProgressSideView';

export declare type ToolsLayoutMeta = {
  background?: React.ReactNode;
};

export const CATEGORY_PATHS: Record<ToolTypeEnum, string> = {
  [ToolTypeEnum.Budget]: 'budget',
  [ToolTypeEnum.Car]: 'car',
  [ToolTypeEnum.College]: 'college',
  [ToolTypeEnum.CreditCard]: 'credit-card',
  [ToolTypeEnum.Debt]: 'debt',
  [ToolTypeEnum.Gifts]: 'gifts',
  [ToolTypeEnum.Goals]: 'goals',
  [ToolTypeEnum.House]: 'house',
  [ToolTypeEnum.Investment]: 'investment',
  [ToolTypeEnum.Loans]: 'loans',
  [ToolTypeEnum.Medical]: 'medical',
  [ToolTypeEnum.Retirement]: 'retirement',
  [ToolTypeEnum.ThreeNickels]: '',
};

export const TOOL_PATHS: Record<ToolTypeEnum, Record<string, string>> = {
  [ToolTypeEnum.Budget]: {
    'cut-spending': '25EasyWaysToCutSpending',
  },
  [ToolTypeEnum.Car]: {
    'how-much-car': 'HowMuchCarCanIAfford',
    'finance-or-pay-cash': 'ShouldIFinanceOrPayCash',
    'car-buying-checklist': 'CarBuyingChecklist',
    'collision-insurance-breakeven': 'CollisionInsuranceBreakevenTool',
    'most-popular-cars': 'MostPopularCars',
    'auto-insurance-need-to-know': 'WhatYouNeedToKnowAutoInsurance',
  },
  [ToolTypeEnum.College]: {
    'cost-of-college': 'CostOfCollege',
    'college-savings-charts': 'CollegeSavingsCharts',
    'college-savings-plans': 'TypesOfCollegeSavingsPlans',
  },
  [ToolTypeEnum.CreditCard]: {
    'credit-card-gotchas': 'CreditCardGotchas',
  },
  [ToolTypeEnum.Debt]: {
    'real-cost-of-debt': 'TheRealCostOfDebt',
    'debt-consolidation-tool': 'DebtConsolidationTool',
    'how-much-debt': 'HowMuchDebtCanYourIncomeSupport',
  },
  [ToolTypeEnum.Gifts]: {
    'introduction-to-giving': 'IntroToGiving',
    'give-to-your-church': 'GiveToYourChurchTithely',
    'tax-benefits-of-donations': 'TaxBenefitsOfDonations',
    'search-for-charity': 'SearchForCharity',
  },
  [ToolTypeEnum.Goals]: {
    'inflation-calculator': 'InflationCalculator',
  },
  [ToolTypeEnum.House]: {
    'rent-or-buy': 'ShouldIRentOrBuy',
    'how-much-house': 'HowMuchHouseCanIAfford',
    'home-sale-net-proceeds': 'HomeSaleNetProceeds',
    'mortgage-rates': 'MortgageRates',
    'house-buying-checklist': 'HouseBuyingChecklist',
    'mortgage-refinance-checklist': 'MortgageRefinanceChecklist',
    'mortgage-refinance-calculator': 'MortgageRefinanceCalculator',
    'cost-of-living-comparison': 'CostOfLivingComparison',
    'house-selling-checklist': 'HouseSellingChecklist',
  },
  [ToolTypeEnum.Investment]: {
    'basic-investing-terms': 'BasicInvestingTerms',
    'investment-account-checklist': 'InvestmentAccountChecklist',
    'shop-investment-accounts': 'ShopInvestmentAccounts',
  },
  [ToolTypeEnum.Loans]: {
    'personal-loan-calculator': 'PersonalLoanCalculator',
    'personal-loan-checklist': 'PersonalLoanChecklist',
  },
  [ToolTypeEnum.Medical]: {
    'types-of-health-insurance': 'TypesOfHealthInsurance',
    'health-insurance-checklist': 'HealthInsuranceChecklist',
  },
  [ToolTypeEnum.Retirement]: {
    'ira-fee-checker': 'IraFeeChecker',
    'why-save-now': 'WhySaveNow',
  },
  [ToolTypeEnum.ThreeNickels]: {},
};

export const getBreadcrumbNameMap = (
  isUniversity: boolean,
  lmsLesson?: Domain.LessonDto,
  universityLesson?: Domain.Lesson,
  charity?: Domain.CharityData
): Record<string, string> => {
  const pathToBreadcrumbMap: Record<string, string> = {
    '/tools': 'Tools',
  };

  Object.entries(CATEGORY_PATHS).forEach(([categoryName, categoryPath]) => {
    pathToBreadcrumbMap[`/tools/${categoryPath}`] = categoryName;
    pathToBreadcrumbMap[`/tools/${categoryPath}/your-lessons`] = 'YourLessons';
    pathToBreadcrumbMap[`/tools/${categoryPath}/lifestyle`] = 'Lifestyle';

    if (isUniversity && universityLesson?.title) {
      const path = `/tools/${categoryPath}/your-lessons/${encodeURIComponent(
        universityLesson.title
      )}`;
      pathToBreadcrumbMap[path] = universityLesson.title;
    } else if (!isUniversity && lmsLesson?.lessonId && lmsLesson?.name) {
      const path = `/tools/${categoryPath}/your-lessons/${lmsLesson.lessonId}`;
      pathToBreadcrumbMap[path] = lmsLesson.name;
    }

    const toolsByCategory = TOOL_PATHS[categoryName as ToolTypeEnum];
    if (toolsByCategory) {
      Object.entries(toolsByCategory).forEach(([toolPath, toolTitle]) => {
        pathToBreadcrumbMap[`/tools/${categoryPath}/${toolPath}`] = toolTitle;
      });
    }
  });

  if (charity?.orgId && charity?.charityName) {
    pathToBreadcrumbMap[`/tools/gifts/search-for-charity/${charity.orgId}`] = charity.charityName;
  }

  return pathToBreadcrumbMap;
};

const ToolsLayout: React.FC = () => {
  const location = useLocation();
  const { meta, setMeta } = useLayoutMeta<ToolsLayoutMeta>();
  const { sideNav } = useContentView();
  const { id: charityId, courseId, unitId, lessonId, quizId, questionId } = useParams();
  const dispatcher = Hooks.useObservableQueryDispatcher();
  const [expanded, setExpanded] = useState(false);
  const [charityData, setCharityData] = useState<Domain.CharityData | undefined>(undefined);

  const universityUnits = Hooks.useUniversityUnits();
  const selectedCourse =
    useObservableQuery(Data.Lms.Queries.FindCourseByCourseId, parseInt(courseId ?? '')) ?? {};
  const lmsLesson =
    useObservableQuery(Data.Lms.Queries.FindLessonByLessonId, parseInt(lessonId ?? '')) ?? {};

  const pathnameSegments = location.pathname.split('/');
  const isUniversityLesson = _.isEmpty(lmsLesson);
  const lessonView = typeof lmsLesson?.lessonId !== 'undefined';

  const universityLesson = universityUnits
    .flatMap((unit) => unit.lessons)
    .find((lesson) => pathnameSegments.includes(encodeURIComponent(lesson.title)));

  const filteredSegments = pathnameSegments.filter((segment) => {
    return (
      segment !== courseId &&
      segment !== unitId &&
      segment !== quizId &&
      segment !== questionId &&
      segment !== 'content' &&
      segment !== 'results'
    );
  });

  const breadcrumbNameMap = getBreadcrumbNameMap(
    isUniversityLesson,
    lmsLesson,
    universityLesson,
    charityData
  );

  useEffect(() => {
    const getCharityDetails = async (id: number) => {
      const charity = await dispatcher.execute(Data.Charity.FindCharityDetails, id);
      setCharityData(charity);
    };

    if (typeof charityId !== 'undefined') {
      getCharityDetails(cleanWholeNumberStr(charityId));
    }
  }, [charityId]);

  return (
    <Data.Lms.FilteredLessonsProvider>
      <Data.Lms.QuizProvider>
        <ContentView
          sideNavMenuItems={sideNav('Tools')}
          sideComponent={
            lessonView ? (
              <LmsSideView
                course={selectedCourse}
                lesson={lmsLesson}
                expanded={expanded}
                setExpanded={setExpanded}
                yourLessonsView
              />
            ) : undefined
          }
          progressIndicator={isUniversityLesson ? <CourseProgressSideView /> : undefined}
          progressIndicatorPosition='end'
          footer={
            lessonView ? (
              <LmsFooter
                course={selectedCourse}
                expanded={expanded}
                setExpanded={setExpanded}
                meta={meta}
                yourLessonsView
              />
            ) : undefined
          }
          containerProps={
            lessonView
              ? { gridTemplateColumns: expanded ? '350px' : { xl: '0px', lg: 'min-content' } }
              : undefined
          }
          background={isUniversityLesson ? undefined : meta.background}
          leftChildren={
            <Breadcrumbs
              pathnameSegments={filteredSegments}
              breadcrumbNameMap={breadcrumbNameMap}
            />
          }>
          <Outlet context={{ setMeta }} />
        </ContentView>
      </Data.Lms.QuizProvider>
    </Data.Lms.FilteredLessonsProvider>
  );
};

export default ToolsLayout;
