/* eslint-disable @typescript-eslint/no-explicit-any */
import { Backdrop, Box, Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { Data, Hooks, ObservableGuard, useObservableGuardCondition } from '@3nickels/data-modules';
import { FormLoader } from '../../../../components/FormLoader';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import FormContent from '../../../../components/form/FormContent';
import { WizardStep } from '@aesop-fables/scrinium';
import { LayoutMeta, withLayoutMeta } from '../../../../types/LayoutMeta';
import { WizardFooter } from '../../../../components/form/WizardFooter';
import { Loading, useLoading } from '../../../../hooks/useLoading';
import { map } from 'rxjs';
import { EditViewProps, getWizardFooterProps } from '../../../EditViewUtils';
import { GoalsLayoutMeta } from '../../GoalsLayout';
import CashGivingGoalDetailsForm from './CashGivingGoalDetailsForm';
import { cleanWholeNumberStr } from '../../../../helpers/utilityFunctions';
import { Colors } from '../../../../themes';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';

const formSchema = () =>
  Yup.object({
    frequencyGivingSelf: Yup.string().required(t('Required') as string),
    currentPersonalGiving: Yup.string()
      .required(t('Required') as string)
      .test(
        'currentPersonalGiving',
        t('AmountICurrentlyGiveMustBeGreaterThan0') as string,
        (amount) => {
          const cleanNumber = cleanWholeNumberStr(amount);
          return cleanNumber > 0;
        }
      ),
  });

export const CashGivingGoalDetailsEditViewWrapper: React.FC<EditViewProps> = ({
  editing,
  onBack,
}) => {
  const { loading, currentStep, wizard } = Hooks.useCashGivingGoalWizard();

  useEffect(() => {
    wizard.start({});
    wizard.selectStep('details');
  }, []);

  return (
    <ObservableGuard
      predicate$={wizard.current$.pipe(
        // guard against incorrect step to prevent incorrect default values when navigating from another step
        map((current) => current?.key === 'details')
      )}
      loadingFn={() => (
        <Backdrop open>
          <Loading />
        </Backdrop>
      )}>
      {currentStep?.key === 'details' && ( // don't even mount until correct step is set
        <FormLoader loading={loading}>
          <CashGivingGoalDetailsEditView
            currentStep={currentStep}
            wizard={wizard}
            editing={editing}
            onBack={onBack}
          />
        </FormLoader>
      )}
    </ObservableGuard>
  );
};

interface CashGivingGoalDetailsEditViewProps extends EditViewProps {
  currentStep: WizardStep<Data.Goals.CashGivingGoalDetailsFormData, Data.Goals.WizardParamsWithId>;
  wizard: Data.Goals.CashGivingGoalWizard;
}

const CashGivingGoalDetailsEditView: React.FC<CashGivingGoalDetailsEditViewProps> = ({
  currentStep,
  wizard,
  editing,
  onBack,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const ready = useObservableGuardCondition();
  const methods = useForm<Data.Goals.CashGivingGoalDetailsFormData>({
    defaultValues: currentStep.model,
    resolver: yupResolver(formSchema()),
  });
  const { setLoading } = useLoading();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formatInputs = (values: any) => {
    const currentPersonalGiving = cleanWholeNumberStr(values.currentPersonalGiving ?? '');

    return { currentPersonalGiving };
  };

  const onSubmit = async (values: any) => {
    setLoading(true);

    try {
      const cleanedValues = formatInputs(values);
      currentStep.save({ ...values, ...cleanedValues });
      await wizard.commitStep('details');

      if (editing && onBack) {
        onBack();
        return;
      }

      navigate('/goals');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {ready && (
        <FormContent formProviderProps={methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Box>
              <Typography
                component='h1'
                variant='h1'
                color={Colors.primaryBase}
                display='inline-block'>
                {t('CurrentGiving')}
              </Typography>
              <Grid container justifyContent='center' mt={2}>
                <Grid item sm={editing ? 10 : 6}>
                  <CashGivingGoalDetailsForm model={currentStep.model} onSubmit={onSubmit} />
                </Grid>
              </Grid>
            </Box>
            <WizardFooter
              color={editing ? 'primary' : undefined}
              disableBack={false}
              onBack={onBack}
              {...getWizardFooterProps(meta.nextLocaleKey, editing)}
            />
          </form>
        </FormContent>
      )}
    </>
  );
};

const meta = {
  nextLocaleKey: 'Save&Continue',
  title: 'Giving',
  headerVariant: 'hex',
  hexSteps: {
    steps: 2,
    currentStep: 2,
  },
} satisfies LayoutMeta<GoalsLayoutMeta>;

export default withLayoutMeta(CashGivingGoalDetailsEditViewWrapper, meta);
