import { Dispatch, MouseEventHandler, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Data, Domain, Hooks } from '@3nickels/data-modules';
import { Card, Grid, Stack, Typography } from '@mui/material';
import { Button } from '../../components/buttons/Button';
import { Svgs } from '../../assets/svg';
import { QuestionAnswer } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

export const PHONE_NUMBER = '1-800-774-7459';

interface ContactOptionsProps {
  featureMap: Domain.FeatureMap;
  coachingSessionUrl: string | undefined;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const ContactOptions: React.FC<ContactOptionsProps> = ({
  featureMap,
  coachingSessionUrl,
  setOpen,
}) => {
  const { t } = useTranslation();
  const { coachingSession, heyNic } = featureMap;

  const onBookASession = () => {
    window.open(`${coachingSessionUrl}`, '_blank');
  };

  return (
    <Grid container mt={2} rowGap={3} flexDirection='column'>
      {coachingSession && (
        <ContactOption
          title='BookACoachingSession'
          icon={<Svgs.DisplaySmallCustomCalendar />}
          description='BookA45MinuteTimeSlot'
          buttonText='BookASession'
          onButtonClick={onBookASession}
        />
      )}
      <ContactOption
        title='SendUsAMessage'
        icon={<Svgs.DisplaySmallCustomEnvelope />}
        description='BrieflyDescribeWhatTheSituationIs'
        buttonText='MessageUs'
        buttonVariant={coachingSession ? 'outlined' : undefined}
        onButtonClick={() => setOpen(true)}
      />
      {heyNic && <HeyNicContactOption />}
      <ContactOption title='CallUs' icon={<Svgs.DisplaySmallCustomPhone2 />}>
        <Card className='card-row' sx={{ padding: '20px 15px' }}>
          <Typography variant='p16' color='secondary'>
            {t('ToSpeakToARepresentative')}&nbsp;<strong>{PHONE_NUMBER}</strong>.
          </Typography>
          <Typography mt={1} variant='p16Italic' color='secondary'>
            {t('BusinessHours')}
          </Typography>
        </Card>
      </ContactOption>
    </Grid>
  );
};

interface ContactOptionProps extends React.PropsWithChildren {
  title: string;
  icon: React.ReactNode;
  description?: string;
  buttonText?: string;
  buttonVariant?:
    | 'link'
    | 'text'
    | 'tertiary'
    | 'outlined'
    | 'contained'
    | 'ghost'
    | 'activeGhost'
    | undefined;
  onButtonClick?: MouseEventHandler<HTMLButtonElement> | undefined;
}

const ContactOption: React.FC<ContactOptionProps> = (props) => {
  const { t } = useTranslation();
  const { title, icon, description, buttonText, buttonVariant, onButtonClick } = props;

  return (
    <Stack spacing={1.5}>
      {icon}
      <Typography variant='p18Bold' color='secondary'>
        {t(`${title}`)}
      </Typography>
      {buttonText && (
        <Card
          className='card-row'
          sx={{ padding: '15px', justifyContent: 'space-between', flexDirection: 'row' }}>
          <Grid
            columnGap='25px'
            sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant='p16' color='secondary'>
              {t(`${description}`)}
            </Typography>
            <Button
              color='primary'
              variant={buttonVariant}
              style={{ width: '25%', minWidth: 'fit-content' }}
              onClick={onButtonClick}>
              {t(buttonText)}
            </Button>
          </Grid>
        </Card>
      )}
      {props.children}
    </Stack>
  );
};

const HeyNicContactOption: React.FC = () => {
  const navigate = useNavigate();
  const chatMetadatas = Hooks.useChatMetadata();
  const commands = Hooks.useCommands();

  const onStartAChat = () => {
    if (chatMetadatas?.at(0)?.messageCount === 1) {
      navigate(`/chat-bot/${chatMetadatas.at(0)?.id}`);
    } else {
      const createAndOpenNewChat = async () => {
        const chat = await commands.execute(Data.Chat.Commands.CreateChat, {});
        navigate(`/chat-bot/${chat.id}`);
      };
      createAndOpenNewChat();
    }
  };

  return (
    <ContactOption
      title='ChatWithUs'
      icon={<QuestionAnswer />}
      description='GetYourQuestionsAnswered'
      buttonText='StartAChat'
      buttonVariant={'outlined'}
      onButtonClick={onStartAChat}
    />
  );
};

export default ContactOptions;
