import { ChatData, MessageData } from '@3nickels/data-modules/lib/data/gpt/chat';
import { Grid, LinearProgress, Typography } from '@mui/material';
import { t } from 'i18next';
import { Colors } from '../../themes';
import { Svgs } from '../../assets/svg';
import Markdown from 'react-markdown';
import { Loading } from '../../hooks/useLoading';

interface MessageProps {
  time?: string;
  text?: string;
  loading?: boolean;
}

const formatTime = (date?: Date): string => {
  if (!date) return '';
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';

  hours = hours % 12;
  hours = hours ? hours : 12;

  const minutesStr = minutes < 10 ? '0' + minutes : minutes.toString();

  return `${hours}:${minutesStr} ${ampm}`;
};

const BotMessage: React.FC<MessageProps> = ({ time, text, loading }) => {
  return (
    <Grid container direction='column' mt='20px' rowGap={'10px'}>
      <Typography variant='p12' marginLeft={'50px'}>
        {time}
      </Typography>
      <Grid container alignItems='flex-start' display='flex' columnGap='10px'>
        <Grid
          item
          display='flex'
          alignItems='center'
          justifyContent='center'
          borderRadius='50%'
          padding='2px'
          style={{ backgroundColor: Colors.tertiaryBase }}>
          <Svgs.ActionNavBarHamburger />
        </Grid>
        <Typography
          variant='p16'
          component='div'
          style={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '20px',
            alignItems: 'start',
            maxWidth: '50%',
            padding: '20px',
            backgroundColor: Colors.tertiaryBase,
            borderRadius: '0px 10px 10px 10px',
          }}>
          <Markdown
            components={{
              code({ children }) {
                return (
                  <pre
                    style={{
                      whiteSpace: 'pre-wrap',
                      backgroundColor: Colors.primaryLight,
                      padding: '10px',
                      borderRadius: '10px',
                      color: Colors.tertiaryBase,
                    }}>
                    {children}
                  </pre>
                );
              },
              ol({ children }) {
                return (
                  <ol
                    style={{
                      paddingLeft: '20px',
                    }}>
                    {children}
                  </ol>
                );
              },
              ul({ children }) {
                return (
                  <ul
                    style={{
                      paddingLeft: '20px',
                    }}>
                    {children}
                  </ul>
                );
              },
            }}>
            {text}
          </Markdown>
          {loading && <LinearProgress style={{ width: '100%' }} />}
        </Typography>
      </Grid>
    </Grid>
  );
};

const HumanMessage: React.FC<MessageProps> = ({ time, text }) => {
  return (
    <Grid container direction='column' alignItems={'flex-end'} mt='20px' rowGap={'10px'}>
      <Typography variant='p12'>{time}</Typography>
      <Typography
        variant='p16'
        color={Colors.tertiaryBase}
        style={{
          maxWidth: '50%',
          padding: '20px',
          backgroundColor: Colors.primaryLight,
          borderRadius: '10px 10px 0px 10px',
        }}>
        {text}
      </Typography>
    </Grid>
  );
};

const getDateLabel = (date?: Date): string => {
  if (!date) return '';
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  const isToday = date.toDateString() === today.toDateString();
  const isYesterday = date.toDateString() === yesterday.toDateString();

  if (isToday) {
    return 'Today';
  } else if (isYesterday) {
    return 'Yesterday';
  } else {
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear().toString().slice(-2);
    return `${month}/${day}/${year}`;
  }
};

export interface ChatViewProps {
  chat?: ChatData;
  loading: boolean;
}

const ChatView: React.FC<ChatViewProps> = ({ chat, loading }) => {
  const categorizedMessages = new Map<string, MessageData[]>();

  chat?.messages?.forEach((message) => {
    const label = getDateLabel(message.timestamp);
    if (!categorizedMessages.has(label)) {
      categorizedMessages.set(label, []);
    }
    categorizedMessages.get(label)?.push(message);
  });

  return loading ? (
    <Loading />
  ) : (
      <Grid container direction='column' rowGap='20px'>
        {Array.from(categorizedMessages.entries()).map(([label, messages]) => (
          <Grid key={label} container>
            <Grid container justifyContent='center'>
              <Typography color={Colors.primaryBase} variant='p16Bold'>
                {t(label)}
              </Typography>
            </Grid>
            {messages.map((message) =>
              message.sender === 'bot' ? (
                <BotMessage
                  key={message.id}
                  time={formatTime(message.timestamp)}
                  text={message.text}
                  loading={message.loading}
                />
              ) : (
                <HumanMessage
                  key={message.id}
                  time={formatTime(message.timestamp)}
                  text={message.text}
                />
              )
            )}
          </Grid>
        ))}
      </Grid>
  );
};

export default ChatView;
