import { Domain } from '@3nickels/data-modules';
import { Grid, Typography } from '@mui/material';
import { t } from 'i18next';

interface LifestyleCardProps {
  product: Domain.LifestyleProduct;
  logoData: string;
}

const LifestyleCard: React.FC<LifestyleCardProps> = ({ product, logoData }) => {
  const { productName, companyName, description } = product;

  return (
    <Grid container p={2} display='flex' flexDirection='row'>
      <Grid
        display='flex'
        flexDirection='column'
        alignItems='center'
        justifyContent='center'
        width='20%'>
        <img
          src={logoData}
          alt='Logo'
          style={{
            width: '50px',
            height: '50px',
          }}
        />
      </Grid>
      <Grid display='flex' flexDirection='column' width='80%' pl={2}>
        <Typography variant='p18Bold' color='secondary' className='one-line'>
          {productName}
        </Typography>
        <Typography mt={0.5} variant='p14Bold' color='primary' className='one-line'>
          {t('ByCompanyName', { companyName })}
        </Typography>
        <Typography mt={1} variant='p16' color='secondary' className='two-lines'>
          {description}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default LifestyleCard;
