import { Grid, Stack, Typography } from '@mui/material';
import { t } from 'i18next';
import { Svgs } from '../../../../assets/svg';
import { Row } from '../../../../components';
import { errorBase } from '../../../../themes/colors';

export const WhenChoosingLiability: React.FC = () => {
  return (
    <Stack spacing={2}>
      <Row style={{ justifyContent: 'flex-start', alignItems: 'center' }}>
        <Svgs.ArtUmbrella2 />
        <Grid ml={3}>
          <Typography sx={{ color: errorBase }} variant='p24Bold'>
            {t('50/100/50')}
          </Typography>
          <Typography mt={1} color='secondary' variant='p18Bold'>
            {t('LiabilityThreeNumbers')}
          </Typography>
        </Grid>
      </Row>
      <Typography variant='p16Bold' color='secondary'>
        {t('FirstNumber')}
      </Typography>
      <Typography variant='p16' color='secondary'>
        {t('LiabilityFirstNumber')}
      </Typography>
      <Typography variant='p16Bold' color='secondary'>
        {t('SecondNumber')}
      </Typography>
      <Typography variant='p16' color='secondary'>
        {t('LiabilitySecondNumber')}
      </Typography>
      <Typography variant='p16Bold' color='secondary'>
        {t('ThirdNumber')}
      </Typography>
      <Typography variant='p16' color='secondary'>
        {t('LiabilityThirdNumber')}
      </Typography>
    </Stack>
  );
};
