import { Typography, Button, IconButton } from '@mui/material';
import { Column, RightDrawer, Row, Spacer } from '../components';
import { logoGcSmall } from '../assets/png';
import { Svgs } from '../assets/svg';
import { PackageCard } from './LearnMoreAdviceAndThreeNickelsUDrawer';
import { LearnMoreDrawerProps } from './LearnMoreAdviceDrawer';
import { useDeviceType } from '../hooks/useDeviceType';
import { t } from 'i18next';
import { Trans } from 'react-i18next';

const LearnMoreGuidedChoiceDrawer: React.FC<LearnMoreDrawerProps> = ({
  drawerOpen,
  setDrawerOpen,
  pricebookBundle,
  pricebookMetadata,
}) => {
  const { isMobile } = useDeviceType();

  return (
    <RightDrawer open={drawerOpen} setOpen={setDrawerOpen}>
      <Row>
        <Column style={{ width: isMobile ? '100%' : '90%' }}>
          {!isMobile && (
            <Typography color='info.main' variant='p14OpenSans'>
              {t('GuidedChoicePackages')}
            </Typography>
          )}
          <Spacer height='xxs' />

          <Typography
            color='guidedChoice.main'
            variant={isMobile ? 'p20OpenSansSemiBold' : 'p30OpenSansLight'}>
            {pricebookBundle.pricebook.pricebookName}
          </Typography>
          <Spacer height='xxs' />
        </Column>
        {isMobile ? (
          <IconButton onClick={() => setDrawerOpen(false)}>
            <Svgs.IconClose />
          </IconButton>
        ) : (
          <img src={logoGcSmall} alt='logo' style={{ height: '40px' }} />
        )}
      </Row>
      <PackageCard pricebookBundle={pricebookBundle} pricebookMetadata={pricebookMetadata} />
      {isMobile && (
        <>
          <Spacer height='xs' />
          <Column style={{ alignItems: 'center' }}>
            <Svgs.ArtRetirementLarge />
          </Column>
        </>
      )}
      <Spacer height='xs' />
      <Typography color='guidedChoice.main' variant='p20OpenSansSemiBold'>
        {t('Description')}
      </Typography>
      <Spacer height='xxs' />
      <Row>
        <Column style={{ width: '100%' }}>
          <Typography color='info.main' variant='p16OpenSans'>
            {t('OneOfTheQuestionsWereAskedTheMostIsHowToCreateASafeAfterRetirementIncomePlan')}
          </Typography>
        </Column>
        {!isMobile && (
          <Svgs.ArtRetirementLarge style={{ marginLeft: '20px', marginRight: '20px' }} />
        )}
      </Row>
      <Spacer height='xxs' />
      <Typography color='guidedChoice.main' variant='p20OpenSansSemiBold'>
        {t('AtAGlance...')}
      </Typography>
      <Column style={{ width: '85%' }}>
        <Spacer height='xs' />
        <Row style={{ alignItems: 'center' }}>
          <Svgs.BulletPiggyBank
            style={{ width: isMobile ? '70px' : '50px', height: 'auto', flexShrink: 0 }}
          />
          <Typography
            className='link-wrapper'
            style={{ marginLeft: '20px' }}
            variant='p16OpenSans'
            color='info.main'>
            <Trans i18nKey='KnowExactlyHowMuchToSave' />
          </Typography>
        </Row>

        <Spacer height='xs' />
        <Row style={{ alignItems: 'center' }}>
          <Svgs.BulletInvestmentGC
            style={{ width: isMobile ? '70px' : '50px', height: 'auto', flexShrink: 0 }}
          />
          <Typography
            className='link-wrapper'
            style={{ marginLeft: '20px' }}
            variant='p16OpenSans'
            color='info.main'>
            <Trans i18nKey='KnowExactlyWhatToInvestIn' />
          </Typography>
        </Row>

        <Spacer height='xs' />
        <Row style={{ alignItems: 'center' }}>
          <Svgs.BulletStatistics
            style={{ width: isMobile ? '70px' : '50px', height: 'auto', flexShrink: 0 }}
          />
          <Typography
            className='link-wrapper'
            style={{ marginLeft: '20px' }}
            variant='p16OpenSans'
            color='info.main'>
            <Trans i18nKey='RetirementIncomeProjectionsFor' />
          </Typography>
        </Row>

        <Spacer height='xs' />
        <Row style={{ alignItems: 'center' }}>
          <Svgs.BulletLoans
            style={{ width: isMobile ? '70px' : '50px', height: 'auto', flexShrink: 0 }}
          />
          <Typography
            className='link-wrapper'
            style={{ marginLeft: '20px' }}
            variant='p16OpenSans'
            color='info.main'>
            <Trans i18nKey='DrawdownRecommendations' />
          </Typography>
        </Row>
      </Column>

      {!isMobile && (
        <>
          <Spacer height='xs' />
          <Row style={{ justifyContent: 'space-between' }}>
            <Button onClick={() => setDrawerOpen(false)} className='gcCancel'>
              {t('Close')}
            </Button>
          </Row>
        </>
      )}
      <Spacer height='md' />
    </RightDrawer>
  );
};

export default LearnMoreGuidedChoiceDrawer;
