import React from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import { Button } from '../../components/buttons/Button';
import { Breadcrumbs } from '../../components';
import { Spacing } from '../../themes';
import { useLayoutMeta } from '../../hooks/useLayoutMeta';
import ContentView from '../layout/Content';
import HexHeader from '../layout/header-variants/HexHeader';
import { useContentView } from '../layout/ContentViewContext';
import { useDeviceType } from '../../hooks/useDeviceType';

export type AccountSettingsLayout = Record<string, unknown>;

export declare type AccountSettingsLayoutMeta = {
  hexHeader?: boolean;
};

const breadcrumbNameMap: { [key: string]: string } = {
  '/settings': 'Settings',
  '/settings/alerts': 'Alerts',
  '/settings/manage-subscription': 'ManageSubscription',
  '/settings/manage-subscription/change-plan': 'ChangePlan',
  '/settings/manage-payments': 'ManagePayments',
  '/settings/update-password': 'UpdatePassword',
  '/settings/two-factor-authentication': 'TwoFactorAuth',
  '/settings/two-factor-authentication/add': 'AddAnAuthenticationMethod',
  '/settings/two-factor-authentication/verify': 'AddAnAuthenticationMethod',
  '/settings/legal': 'Legal',
  '/settings/legal/fair-use-notice': 'FairUseNotice',
  '/settings/icon-authors': 'IconAuthorAttribution',
};

const AccountSettingsLayout: React.FC<AccountSettingsLayout> = () => {
  const { t } = useTranslation();
  const { isMobile } = useDeviceType();
  const navigate = useNavigate();
  const location = useLocation();
  const { meta, setMeta } = useLayoutMeta<AccountSettingsLayoutMeta>();
  const pathnameSegments = location.pathname.split('/');
  const { sideNav } = useContentView();

  const onBack = () => {
    if (meta.onBack) {
      meta.onBack(navigate);
    } else {
      navigate(-1);
    }
  };

  const screenHasMobileVersion = pathnameSegments.find(
    (segment) => segment === 'manage-payments' || segment === 'manage-subscription'
  );
  if (isMobile && screenHasMobileVersion) return <Outlet context={{ setMeta }} />;
  return (
    <ContentView
      sideNavMenuItems={sideNav('Settings')}
      leftChildren={
        <Breadcrumbs pathnameSegments={pathnameSegments} breadcrumbNameMap={breadcrumbNameMap} />
      }
      headerChildren={
        meta.hexHeader && (
          <HexHeader
            stepProps={meta.hexSteps}
            startAdornment={
              <Typography className='hex-header' fontWeight='bold' color='primary'>
                {t('Settings')}
              </Typography>
            }
          />
        )
      }>
      <Outlet context={{ setMeta }} />
      {meta.showBack && (
        <Grid container display='flex' justifyContent='space-between' mt={Spacing.xxs}>
          <Grid item sm={2}>
            <Button fullWidth label='Back' variant='outlined' color='secondary' onClick={onBack} />
          </Grid>
        </Grid>
      )}
    </ContentView>
  );
};

export default AccountSettingsLayout;
