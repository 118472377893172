import { Data } from '@3nickels/data-modules';

export const mapPlanHelpTipToHelpContext = (planHelpTip: Data.MedicalBenefits.HelpTipOptions) => {
  switch (planHelpTip) {
    case 'UNDER_TWENTY_SEVEN_COVERED':
      return {
        cardTitle: 'Nearing26',
        modalTitle: 'DepNearing26',
        modalCopy: 'IfTurn26Soon',
      };
    case 'UNDER_FORTY_SIX_HEALTHY':
    case 'OVER_FORTY_FIVE_HEALTHY':
      return {
        cardTitle: 'HowDoesHealthImpact',
        modalTitle: 'KeepCostsLow',
        modalCopy: 'DontAnticipateSigNeeds',
      };
    case 'UNDER_FORTY_SIX_ROUTINE_CARE':
      return {
        cardTitle: 'ChoosePlanFitsNeeds',
        modalTitle: 'ExpectRoutineCare',
        modalCopy: 'RoutineCareCostEffectiveHmo',
      };
    case 'UNDER_FORTY_SIX_COMPLEX_CARE':
      return {
        cardTitle: 'ChoosePlanFitsNeeds',
        modalTitle: 'ExpectComplexCare',
        modalCopy: 'AnticipateComplexNeeds',
      };
    case 'UNDER_FORTY_SIX_FAMILY_CARE_LOW_PREMIUM':
    case 'UNDER_FORTY_SIX_FAMILY_CARE_OUT_OF_NETWORK':
      return {
        cardTitle: 'FindHealthPlanFamily',
        modalTitle: 'ChoosingPlanFamily',
        modalCopy: 'ForFamilyBalanceLowerCost',
      };
    case 'OVER_FORTY_FIVE_ROUTINE_CARE':
      return {
        cardTitle: 'ChoosePlanFitsNeeds',
        modalTitle: 'ExpectRoutineCare',
        modalCopy: 'HmoCostEffectiveRoutine',
      };
    case 'OVER_FORTY_FIVE_ROUTINE_CARE_HDHP':
      return {
        cardTitle: 'SaveInHsa',
        modalTitle: 'PayingForRoutineCare',
        modalCopy: 'IfYouCanAffordOop',
      };
    case 'OVER_FORTY_FIVE_COMPLEX_CARE':
      return {
        cardTitle: 'ChoosePlanFitsNeeds',
        modalTitle: 'ExpectComplexCare',
        modalCopy: 'ComplexCarePrioritize',
      };
    case 'OVER_FIFTY_FOUR_HEALTHY':
      return {
        cardTitle: 'SaveExtraHsa',
        modalTitle: 'HsaCatchUp',
        modalCopy: 'If55ContributeAdditional',
      };
    default:
      // GENERAL
      return {
        cardTitle: 'SaveInDedicatedAcct',
        modalTitle: 'AvoidMedicalDebt',
        modalCopy: 'SaveForOopExpenses',
      };
  }
};

export const mapPlanOptionToContext = (planOption: Data.MedicalBenefits.HealthPlanOptions) => {
  switch (planOption) {
    case 'HMO':
      return {
        fullName: 'HmoFullName',
        blurb: 'HmoBlurb',
        pros: ['LowestPremiums', 'EfficientCareCoord'],
        cons: ['LimitedToInNetwork'],
      };
    case 'POS':
      return {
        fullName: 'PosFullName',
        blurb: 'PosBlurb',
        pros: ['BalancedCosts', 'AllowsOutNetwork'],
        cons: ['RequiresPcp'],
      };
    case 'PPO':
      return {
        fullName: 'PpoFullName',
        blurb: 'PpoBlurb',
        pros: ['BroadProviderAccess', 'OutNetworkFlex'],
        cons: ['HigherPremiums'],
      };
    case 'EPO':
      return {
        fullName: 'EpoFullName',
        blurb: 'EpoBlurb',
        pros: ['LowPremiums', 'NoReferrals', 'SimplerClaims'],
        cons: ['InNetworkOnly', 'SmallerNetwork'],
      };
    case 'HMOHDHP':
      return {
        fullName: 'HmoHdhpFullName',
        blurb: 'HdhpBlurb',
        pros: ['LowerPremiums', 'HsaEligibilityTaxSavings', 'EfficientCarePcp'],
        cons: ['HigherOutPocket', 'LimitedInNetwork'],
      };
    case 'POSHDHP':
      return {
        fullName: 'PosHdhpFullName',
        blurb: 'HdhpBlurb',
        pros: ['LowerPremiums', 'HsaEligibilityTaxSavings', 'AllowsSomeOutNetwork'],
        cons: ['HigherOutPocket', 'RequiresPcp'],
      };
    case 'PPOHDHP':
      return {
        fullName: 'PpoHdhpFullName',
        blurb: 'HdhpBlurb',
        pros: [
          'LowerPremiums',
          'HsaEligibilityTaxSavings',
          'BroadProviderAccess',
          'OutOfNetworkFlexibility',
        ],
        cons: ['HigherOutPocket'],
      };
    case 'EPOHDHP':
      return {
        fullName: 'EpoHdhpFullName',
        blurb: 'HdhpBlurb',
        pros: ['LowerPremiums', 'HsaEligibilityTaxSavings', 'NoReferrals', 'SimplerClaims'],
        cons: ['HigherOutPocket', 'InNetworkOnly', 'SmallerNetwork'],
      };

    default:
      return { blurb: '', pros: [], cons: [] };
  }
};

export const mapShortNameToLongName = (planOption: Data.MedicalBenefits.HealthPlanOptions) => {
  switch (planOption) {
    case 'PPO':
      return {
        article: 'a',
        longName: 'PpoLongName',
        planText: 'plan',
      };
    case 'HMO':
      return {
        article: 'an',
        longName: 'HmoLongName',
        planText: 'plan',
      };
    case 'POS':
      return {
        article: 'a',
        longName: 'PosLongName',
        planText: 'plan',
      };
    case 'EPO':
      return {
        article: 'an',
        longName: 'EpoLongName',
        planText: 'plan',
      };
    case 'PPOHDHP':
      return {
        article: 'a',
        longName: 'PpoHdhpLongName',
        planText: '',
      };
    case 'HMOHDHP':
      return {
        article: 'an',
        longName: 'HmoHdhpLongName',
        planText: '',
      };
    case 'POSHDHP':
      return {
        article: 'a',
        longName: 'PosHdhpLongName',
        planText: '',
      };
    case 'EPOHDHP':
      return {
        article: 'an',
        longName: 'EpoHdhpLongName',
        planText: '',
      };
    default:
      return {
        article: '',
        longName: '',
        planText: '',
      };
  }
};

export function mapShortNameToSubtitle(
  planOption: Data.MedicalBenefits.HealthPlanOptions
): string | undefined {
  switch (planOption) {
    case 'PPO':
      return 'PpoSubtitle';
    case 'HMO':
      return 'HmoSubtitle';
    case 'POS':
      return 'PosSubtitle';
    case 'EPO':
      return 'EpoSubtitle';
    case 'PPOHDHP':
      return 'HdhpSubtitle';
    case 'HMOHDHP':
      return 'HdhpSubtitle';
    case 'POSHDHP':
      return 'HdhpSubtitle';
    case 'EPOHDHP':
      return 'HdhpSubtitle';
    default:
      return undefined;
  }
}

export const mapAbbrevToDisplayAbbrev = (planOption: Data.MedicalBenefits.HealthPlanOptions) => {
  switch (planOption) {
    case 'PPO':
      return 'PPO';
    case 'HMO':
      return 'HMO';
    case 'POS':
      return 'POS';
    case 'EPO':
      return 'EPO';
    case 'PPOHDHP':
      return 'HDHP - PPO';
    case 'HMOHDHP':
      return 'HDHP - HMO';
    case 'POSHDHP':
      return 'HDHP - POS';
    case 'EPOHDHP':
      return 'HDHP - EPO';
    default:
      return undefined;
  }
};
