/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useMemo } from 'react';
import { LayoutMeta, withLayoutMeta } from '../../../types/LayoutMeta';
import { Svgs } from '../../../assets/svg';
import { useNavigate } from 'react-router-dom';
import { HolisticFinancialAdviceLayoutMeta } from '../HolisticFinancialAdviceLayout';
import InfoCard from '../../../components/InfoCard';
import { WizardFooter } from '../../../components/form/WizardFooter';
import useAdviceResultsNextInvestmentAccount from '../../../hooks/useAdviceResultsNextInvestmentAccount';
import { Hooks } from '@3nickels/data-modules';
import { useBackHandler } from '../progress-indicator/useBackHandler';
import { useUniqueInvMixForNavigation } from '../progress-indicator/useUniqueInvMixForNavigation';

const OpenIraView: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const navigateToNext = useAdviceResultsNextInvestmentAccount();
  const holisticAdvice = Hooks.useHolisticAdviceOverview();
  const spouse = Hooks.useSpouseData();
  const combinedIraStep =
    (holisticAdvice?.investmentMixes.filter((invMix) => invMix.id === 4).length ?? 0) === 2;
  const target = holisticAdvice?.investmentMixes.find((x) => x.id === 4);
  const spouseIraStep = target?.participantId === spouse?.spouseId;

  const backHandler = useBackHandler(holisticAdvice, 'investment', 4);

  const nextId = useMemo(() => {
    const navigationInvMix = useUniqueInvMixForNavigation(holisticAdvice?.investmentMixes ?? []);

    // id === 4 means open an ira
    const index = navigationInvMix.findIndex((x) => x.id === 4);

    if (typeof index === 'undefined' || index < 0) return undefined;

    const next = navigationInvMix[index + 1];

    return next?.id;
  }, [holisticAdvice?.investmentMixes]);
  const nextLabel = nextId ? t('NextAccount') : t('NextMyPlan');

  const openAnAccount = () => {
    navigate('/holistic-advice/investments/open-an-account');
  };

  const shopAccountOptions = () => {
    navigate('/holistic-advice/investments/shop-accounts');
  };

  const onDone = () => {
    navigateToNext(nextId);
  };

  return (
    <Box>
      <Grid container flexWrap='nowrap' justifyContent='space-between' alignItems='center'>
        <Grid item>
          <Typography className='title' color='primary' component='h1' variant='h1'>
            {combinedIraStep
              ? t('OpenNewIRAs')
              : spouseIraStep
              ? t('OpenAnIRAForYourSpouse')
              : t('OpenAnIRA')}
          </Typography>
          <Typography className='subtitle' color='secondary' variant='p16'>
            {t('IfThatsGreekToYou')}
          </Typography>
          <Typography color='secondary' variant='p18Bold' mt='40px'>
            {t('WhatsAnIra?')}
          </Typography>
          <Typography className='subtitle' color='secondary' variant='p16'>
            {t('IraStandsForIndividualRetirementAccount')}
          </Typography>
        </Grid>
        <Grid sm={6} md={10} display='flex' justifyContent='center'>
          <Svgs.ArtPieChartLarge />
        </Grid>
      </Grid>
      <Typography color='secondary' variant='p18Bold' mt='40px'>
        {combinedIraStep ? t('WhyAreWeRecommendingOpeningThem?') : t('WhyAreWeRecommendingOne?')}
      </Typography>
      <Typography className='subtitle' color='secondary' variant='p16'>
        {combinedIraStep
          ? t('ToReachYourRetirementGoalYouAndYourSpouse')
          : spouseIraStep
          ? t('ToReachYourRetirementGoalYourSpouse')
          : t('ItLooksLikeYouNeedToSaveTowardRetirement')}
      </Typography>
      <Grid container className='brokerage-account-info-cards'>
        <InfoCard onClick={openAnAccount}>
          <Typography variant='p16Bold' color='primary'>
            {t('HowToOpenAnAccount')}
          </Typography>
        </InfoCard>
        <InfoCard onClick={shopAccountOptions}>
          <Typography variant='p16Bold' color='primary' minWidth={'175px'}>
            {t('ShopIras')}
          </Typography>
        </InfoCard>
      </Grid>
      <WizardFooter nextLabel={nextLabel} onDone={onDone} onBack={backHandler} />
    </Box>
  );
};

const meta = {
  nextLocaleKey: 'NextAccount',
  showProgress: true,
} satisfies LayoutMeta<HolisticFinancialAdviceLayoutMeta>;

export default withLayoutMeta(OpenIraView, meta);
