/* eslint-disable @typescript-eslint/no-explicit-any */
import { Backdrop, Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';
import PersonalInfoForm from './PersonalInfoForm';
import {
  Data,
  formatDateForApi,
  Hooks,
  ObservableGuard,
  useObservableGuardCondition,
} from '@3nickels/data-modules';
import { FormLoader } from '../../../components/FormLoader';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import FormContent from '../../../components/form/FormContent';
import { WizardStep, useObservable } from '@aesop-fables/scrinium';
import { LayoutMeta, withLayoutMeta } from '../../../types/LayoutMeta';
import { PersonalDetailsLayoutMeta } from '../PersonalDetailsLayout';
import { WizardFooter } from '../../../components/form/WizardFooter';
import { Loading, useLoading } from '../../../hooks/useLoading';
import { EditViewProps, getWizardFooterProps } from '../../EditViewUtils';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { map } from 'rxjs';
import { formatMalformedDate, isValidDate } from '../../../helpers/utilityFunctions';
import { t } from 'i18next';

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

const personalInfoSchema = () =>
  Yup.object({
    firstName: Yup.string().required(t('Required') as string),
    lastName: Yup.string().required(t('Required') as string),
    birthDate: Yup.string()
      .test('validDate', t('MustBeAValidDate') as string, (birthDate) => {
        return isValidDate(birthDate ?? '');
      })
      .test('birthDate', t('MustBe12YearsOrOlder') as string, (birthDate) => {
        const date = formatMalformedDate(birthDate ?? '');
        return dayjs().subtract(12, 'y').isSameOrAfter(dayjs(date), 'day');
      })
      .test('birthDate', t('MustBe109YearsOrYounger') as string, (birthDate) => {
        const date = formatMalformedDate(birthDate ?? '');
        return dayjs().subtract(109, 'y').isSameOrBefore(dayjs(date), 'day');
      }),
    gender: Yup.string()
      .required(t('Required') as string)
      .test(
        'gender',
        t('GenderMustBeMaleOrFemale') as string,
        (gender) => gender === 'M' || gender === 'F'
      ),
    maritalStatus: Yup.boolean().required(t('Required') as string),
    includeSpouse: Yup.boolean().when('maritalStatus', {
      is: true,
      then: (schema) => schema.required(t('Required') as string),
    }),
    spouseFirstName: Yup.string().when('includeSpouse', {
      is: true,
      then: (schema) => schema.required(t('Required') as string),
      otherwise: (schema) => schema.notRequired(),
    }),
    spouseLastName: Yup.string().when('includeSpouse', {
      is: true,
      then: (schema) => schema.required(t('Required') as string),
      otherwise: (schema) => schema.notRequired(),
    }),
    spouseBirthDate: Yup.string().when('includeSpouse', {
      is: true,
      then: (schema) =>
        schema
          .test('validDate', t('MustBeAValidDate') as string, (birthDate) => {
            return isValidDate(birthDate ?? '');
          })
          .test('spouseBirthDate', t('MustBe16YearsOrOlder') as string, (birthDate) => {
            const date = formatMalformedDate(birthDate ?? '');
            return dayjs().subtract(16, 'y').isSameOrAfter(dayjs(date), 'day');
          })
          .test('spouseBirthDate', t('MustBe109YearsOrYounger') as string, (birthDate) => {
            const date = formatMalformedDate(birthDate ?? '');
            return dayjs().subtract(109, 'y').isSameOrBefore(dayjs(date), 'day');
          }),
      otherwise: (schema) => schema.notRequired(),
    }),
    spouseGender: Yup.string().when('includeSpouse', {
      is: true,
      then: (schema) => schema.required(t('Required') as string),
      otherwise: (schema) => schema.notRequired(),
    }),
  });

export const PersonalInfoEditViewWrapper: React.FC<EditViewProps> = ({ editing, onBack }) => {
  const { loading, currentStep, wizard } =
    Hooks.usePersonalDetailsWizard<Data.People.PersonalInfoFormData>();
  const isStarted = useObservable(wizard.isStarted$);
  const navigate = useNavigate();

  const onComplete = () => {
    navigate('/personal-details/contact-info');
  };

  useEffect(() => {
    if (typeof isStarted !== 'undefined' && !isStarted) {
      wizard.start({});
    } else if (isStarted) {
      wizard.selectStep('personalInfo');
    }
  }, [isStarted]);

  return (
    <ObservableGuard
      predicate$={wizard.current$.pipe(
        // guard against incorrect step to prevent incorrect default values when navigating from another step
        map((current) => current?.key === 'personalInfo')
      )}
      loadingFn={() => {
        return (
          <Backdrop open>
            <Loading />
          </Backdrop>
        );
      }}>
      {currentStep?.key === 'personalInfo' && ( // load params before mounting to prevent flickering
        <FormLoader loading={loading}>
          <PersonalInfoEditView
            currentStep={currentStep}
            wizard={wizard}
            editing={editing}
            onBack={onBack}
            onComplete={onComplete}
          />
        </FormLoader>
      )}
    </ObservableGuard>
  );
};

interface PersonalInfoEditViewProps extends EditViewProps {
  currentStep: WizardStep<Data.People.PersonalInfoFormData, Data.People.NoOpParams>;
  wizard: Data.People.IPersonWizard;
  onComplete: () => void;
}

const PersonalInfoEditView: React.FC<PersonalInfoEditViewProps> = ({
  currentStep,
  wizard,
  editing,
  onBack,
  onComplete,
}) => {
  const { t } = useTranslation();
  const methods = useForm<Data.People.PersonalInfoFormData>({
    defaultValues: currentStep.model,
    resolver: yupResolver(personalInfoSchema()),
  });
  const ready = useObservableGuardCondition();
  const { setLoading } = useLoading();

  const formatInputs = (values: any) => {
    const birthDate =
      typeof values.birthDate !== 'undefined' && values.birthDate !== null
        ? formatDateForApi(values.birthDate)
        : values.birthDate;
    const spouseBirthDate =
      typeof values.spouseBirthDate !== 'undefined' && values.spouseBirthDate !== null
        ? formatDateForApi(values.spouseBirthDate)
        : values.spouseBirthDate;
    return { birthDate, spouseBirthDate };
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onSubmit = async (values: any) => {
    setLoading(true);

    try {
      const cleanedValues = formatInputs(values);
      currentStep.save({ ...values, ...cleanedValues });
      await wizard.commitStep('personalInfo');
      if (editing && onBack) {
        onBack();
        return;
      }

      onComplete();
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {ready && (
        <FormContent formProviderProps={methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Box>
              {/* Should be about 30px */}
              <Typography className='title' color='primary' component='h1' variant='h1'>
                {t('MyPersonalInfo')}
              </Typography>
              {!editing && (
                <Typography className='subtitle' color='secondary'>
                  {t('MyPersonalInfoSub')}
                </Typography>
              )}

              <Grid container justifyContent='center' mt={2}>
                <Grid item sm={editing ? 10 : 6}>
                  <PersonalInfoForm personalInfo={currentStep?.model} onSubmit={onSubmit} />
                </Grid>
              </Grid>
            </Box>
            <WizardFooter
              color={editing ? 'primary' : undefined}
              disableBack={false}
              onBack={onBack}
              {...getWizardFooterProps(meta.nextLocaleKey, editing)}
            />
          </form>
        </FormContent>
      )}
    </>
  );
};

// export default PersonalInfoEditViewWrapper;
const meta = {
  nextLocaleKey: 'NextContactInfo',
  showBack: true,
  showNext: true,
  hexSteps: {
    steps: 5,
    currentStep: 1,
  },
  step: 'personalInfo',
} satisfies LayoutMeta<PersonalDetailsLayoutMeta>;

export default withLayoutMeta(PersonalInfoEditViewWrapper, meta);
