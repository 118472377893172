import { Divider, Grid, Typography } from '@mui/material';
import { Colors } from '../../../themes';
import { t } from 'i18next';

interface AssetBreakdownHeaderProps {
  name: string;
  totalAssets: number;
}

const AssetBreakdownHeader: React.FC<AssetBreakdownHeaderProps> = ({ name, totalAssets }) => {
  return (
    <Grid container display='flex' justifySelf='center' alignSelf='center'>
      <Grid item style={{ width: '25%' }}>
        <Typography variant='p18' color='secondary'>
          {name.toLocaleUpperCase()}
        </Typography>
      </Grid>
      <Divider
        orientation='vertical'
        sx={{
          borderColor: Colors.primaryLightest,
          borderWidth: 1,
          margin: '0px 25px',
        }}
        flexItem
      />
      <Grid item style={{ width: '40%' }}>
        <Typography
          variant='p16'
          color='primary'
          fontWeight='normal'
          style={{ whiteSpace: 'nowrap' }}>
          {t('xRecommended', { x: totalAssets === 0 ? t('none') : totalAssets })}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default AssetBreakdownHeader;
