import { Grid, Typography } from '@mui/material';
import { t } from 'i18next';
import { useState } from 'react';

type SliderInputTypeToggleProps = {
  PercentInput: JSX.Element;
  DollarInput: JSX.Element;
  onToggle?: () => void;
};

const SliderInputTypeToggle: React.FC<SliderInputTypeToggleProps> = ({
  PercentInput,
  DollarInput,
  onToggle,
}) => {
  const [active, setActive] = useState<'percent' | 'dollar'>('percent');

  const handleToggle = (value: 'percent' | 'dollar') => {
    if (typeof onToggle !== 'undefined') {
      onToggle();
    }
    setActive(value);
  };

  return (
    <>
      <Grid display={active === 'percent' ? undefined : 'none'}>
        {PercentInput}
        <Typography
          className='clickable'
          variant='p12'
          color='primary'
          mt='10px'
          onClick={() => handleToggle('dollar')}>
          {t('SwitchToDollarAmount')}
        </Typography>
      </Grid>
      <Grid display={active === 'dollar' ? undefined : 'none'}>
        {DollarInput}
        <Typography
          className='clickable'
          variant='p12'
          color='primary'
          mt='10px'
          onClick={() => handleToggle('percent')}>
          {t('SwitchToPercentAmount')}
        </Typography>
      </Grid>
    </>
  );
};

export default SliderInputTypeToggle;
