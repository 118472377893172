import { Domain } from '@3nickels/data-modules';
import { Grid, Typography } from '@mui/material';
import { Svgs } from '../../assets/svg';
import { Colors } from '../../themes';
import { t } from 'i18next';
import { PlayCircleOutline } from '@mui/icons-material';

interface LmsLessonProgressCardProps {
  lesson: Domain.LessonDto;
  variant?: 'compressed' | 'contained';
  selected?: boolean;
  yourLessonsView?: boolean;
  startLesson: () => void;
  isUniversityLesson?: boolean;
}

const LmsLessonProgressCard: React.FC<LmsLessonProgressCardProps> = ({
  lesson,
  selected,
  variant = 'contained',
  yourLessonsView = false,
  startLesson,
  isUniversityLesson,
}) => {
  const iconStyle = { minWidth: '30px', minHeight: '30px' };
  return (
    <Grid
      container
      className='unit-progress-card-lesson'
      style={{
        backgroundColor: yourLessonsView ? Colors.tertiaryBase : undefined,
        marginTop: yourLessonsView ? '12px' : undefined,
      }}
      flexWrap='nowrap'
      onClick={startLesson}>
      <Grid className='unit-progress-card-details'>
        {isUniversityLesson ? (
          <>
            <Svgs.DisplaySmallCustomVideoCamera className='hide-on-hover' style={iconStyle} />
            <PlayCircleOutline className='show-on-hover' color='primary' style={iconStyle} />
          </>
        ) : (
          <>
            <Svgs.DisplaySmallCustomBook className='hide-on-hover' style={iconStyle} />
            <Svgs.DisplaySmallPrimaryLightBook className='show-on-hover' style={iconStyle} />
          </>
        )}
        <Grid display='flex' flexDirection='column' rowGap='5px' minWidth={0}>
          <Typography
            className='lesson-title'
            variant={variant === 'compressed' ? 'p12' : 'p16'}
            color={selected ? 'secondary' : 'primary'}
            fontWeight={selected || yourLessonsView ? 'bold' : undefined}
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              width: '100%',
              display: 'block',
            }}>
            {lesson.name}
          </Typography>
          <Typography
            variant='p12'
            color={selected || yourLessonsView ? 'secondary' : 'primary'}
            fontWeight={selected ? 'bold' : undefined}
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              width: '100%',
              display: 'block',
            }}>
            {t('xMin', { x: Math.round(lesson.minutes ?? 0) })}
          </Typography>
        </Grid>
      </Grid>
      {lesson.complete && <Svgs.IconCheckmark style={{ flexShrink: 0 }} />}
    </Grid>
  );
};

export default LmsLessonProgressCard;
