/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Button, Card, Grid, Link, Typography } from '@mui/material';
import { useNavigate, Link as DomLink } from 'react-router-dom';
import { Column, Row, Spacer } from '../components';
import { logo3NSmall, universityAdvisor } from '../assets/png';
import { useEffect, useMemo, useState } from 'react';
import { Form } from 'react-final-form';
import { useService } from '@aesop-fables/containr-react';
import { mobileMargin } from '../App';
import { useDeviceType } from '../hooks/useDeviceType';
import { LicenseData } from '../models/LicenseData';
import { LicenseWizard, licenseWizardKey } from '../services/license';
import { SignUpProgressWizard, signUpProgressWizardKey } from '../services/signUpProgress';
import { ApiKeys } from '../api/apis/ApiKeys';
import { PricebookApi } from '../api/apis/PricebookApi';
import { Api, Domain, Hooks } from '@3nickels/data-modules';
import { PageContentWithBackground } from '../pages/layout/PageContentWithBackground';
import { API_URL } from '../configuration/Environment';
import InfoCard from '../components/InfoCard';
import { Trans, useTranslation } from 'react-i18next';
import i18next from 'i18next';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { formatDecimalDollars } from '../helpers/utilityFunctions';
import { ProductOptions } from './components/ProductOptions';

const Welcome = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { language } = i18next;

  const { isMobile, isTablet } = useDeviceType();
  const mobile = isMobile || isTablet;
  const { height } = useWindowDimensions();
  const INIT_LOGO_HEIGHT = 100;

  const signUpProgressWizard = useService<SignUpProgressWizard>(signUpProgressWizardKey);
  const licenseWizard = useService<LicenseWizard>(licenseWizardKey);
  const termsApi = useService<Api.TermsAndConditionsApi>(Api.ApiKeys.TermsAndConditions);
  const pricebookApi = useService<PricebookApi>(ApiKeys.Pricebook);

  const organizationData = Hooks.useOrganizationData();
  const organizationMetadata = Hooks.useOrganizationMetadata();
  const signupPageText = organizationMetadata?.signupPageText;

  const docs = Hooks.useTermsAndConditionsDocs();
  const [tosLink, setTosLink] = useState<string>();
  const [logoHeight, setLogoHeight] = useState<number>(INIT_LOGO_HEIGHT);
  const hasLogo = Boolean(organizationData?.logoUri);

  const { availableProductsData: pricebookData } = Hooks.useAvailableProducts();
  const [memberPaidOptions, setMemberPaidOptions] = useState<Domain.PricebookBundle[]>([]);
  const [orgPaidOptions, setOrgPaidOptions] = useState<Domain.PricebookBundle[]>([]);
  const [selectedPricebook, setSelectedPricebook] = useState<Domain.PricebookBundle | undefined>(
    undefined
  );

  const domain = Hooks.useDomain();

  const memOptions = memberPaidOptions.length;
  const orgOptions = orgPaidOptions.length;
  const isOnlyOneOption = useMemo(() => {
    // only one of these should be >0 at once
    return memOptions === 1 || orgOptions === 1;
  }, [memOptions, orgOptions]);

  const headerText = useMemo(() => {
    if (!hasLogo && organizationData?.name) {
      return t('SignUpWelcomeToOrg', { orgName: organizationData.name });
    }
    return t('SignUpWelcome');
  }, [mobile, hasLogo, organizationData?.name, t]);

  const pricebookMetadata = useMemo(() => {
    return typeof organizationMetadata !== 'undefined' &&
      Object.keys(organizationMetadata.pricebookMetadata ?? {}).length > 0
      ? JSON.parse(organizationMetadata?.pricebookMetadata?.pricebook ?? '')
      : undefined;
  }, [organizationMetadata]);

  const promotion = useMemo(
    () =>
      pricebookData?.filter((bundle) =>
        bundle.prices.find((price) => price.introductionPeriodInDays > 1)
      ),
    [pricebookData]
  );
  const introPeriod = useMemo(
    () =>
      promotion
        ?.map((bundle) => bundle.prices)
        .map((price) =>
          price.map((arr) => arr.introductionPeriodInDays).find((promo) => promo > 1)
        ),
    [promotion]
  );
  const introPeriodInMonths = useMemo(
    () => introPeriod?.map((promo) => Math.floor((promo ?? 0) / 30)),
    [introPeriod]
  );
  const specialPromoOffer = useMemo(
    () => pricebookData?.find((p) => p.pricebook.metadata?.subHeader === 'Special Promo Offer!*'),
    [pricebookData]
  );
  const specialNonProfitOffer = useMemo(
    () =>
      pricebookData?.find((p) => p.pricebook.metadata?.subHeader === 'Special Non-Profit Offer*'),
    [pricebookData]
  );

  useEffect(() => {
    const getTermsLink = async () => {
      if (docs) {
        const termsObject = docs.find((doc) => doc.docDescription === 'Terms of Service');
        if (!termsObject) {
          return;
        }
        const url = termsApi.getDocString(termsObject.id);
        setTosLink(url);
      }
    };
    getTermsLink();
  }, [docs, termsApi]);

  useEffect(() => {
    const getPricebookOptions = async () => {
      if (pricebookData) {
        const member = pricebookData.filter((bundle) => bundle.pricebook.paidBy === 'USER');
        const org = pricebookData.filter((bundle) => bundle.pricebook.paidBy === 'ORG');
        setMemberPaidOptions(member);
        setOrgPaidOptions(org);
      }
    };

    getPricebookOptions();
  }, [pricebookData]);

  useEffect(() => {
    if (!memberPaidOptions || !orgPaidOptions) {
      return;
    }
    if (isOnlyOneOption) {
      if (memOptions === 1) {
        setSelectedPricebook(memberPaidOptions[0]);
      } else {
        setSelectedPricebook(orgPaidOptions[0]);
      }
    }
  }, [isOnlyOneOption, memOptions, memberPaidOptions, orgPaidOptions]);

  useEffect(() => {
    const img = new Image();
    img.src = `${API_URL}organization/logo`;

    img.onload = () => {
      const aspectRatio = img.naturalWidth / img.naturalHeight;

      const rateDecrease = -30; // slope
      const baseHeight = 123; // y-intercept

      // Use default height for square images
      // Decrease height as image gets wider
      const height = rateDecrease * Math.log(aspectRatio) + baseHeight;
      setLogoHeight(height);
    };
  }, []);

  const onSubmit = async (values: LicenseData) => {
    signUpProgressWizard.setState(true);
    if (!selectedPricebook) {
      return;
    }

    try {
      const licenseData: LicenseData = {
        ...values,
        paymentParty: selectedPricebook.pricebook.paidBy,
        package: selectedPricebook.pricebook.productType,
      };
      licenseWizard.setState({ licenseData, pricebook: selectedPricebook });
      if (selectedPricebook.pricebook.productType === 'GUIDED_CHOICE') {
        window.location.href = `https://app.${domain.gcDomain}/retail/setup-account?pricebookId=${selectedPricebook.pricebook.id}`;
      } else {
        await pricebookApi.selectPricebook(selectedPricebook.pricebook.id);
        navigate('/create-account');
      }
    } catch (error) {
      console.error('Failed to select pricebook with id: ' + selectedPricebook.pricebook.id);
    }
  };

  const onSetUpTime = () => {
    window
      .open(
        'https://outlook.office365.com/owa/calendar/beta@guidedchoice.com/bookings/s/ag4FCPYR0USkWp4Rf49o6Q2',
        '_blank'
      )
      ?.focus();
  };

  const onCancel = () => {
    window.location.replace('https://3nickels.com');
  };

  return (
    <PageContentWithBackground
      sx={{ height: mobile ? height : undefined, overflowX: 'hidden' }}
      useContainer={false}>
      <Form onSubmit={onSubmit}>
        {({ values, handleSubmit }) => (
          <>
            <Column
              style={{
                margin: mobile ? mobileMargin : '25px 100px',
              }}>
              <Row
                style={{
                  alignItems: mobile ? undefined : 'center',
                  alignSelf: mobile ? 'center' : undefined,
                  marginTop: mobile && hasLogo ? '20px' : undefined,
                  justifyContent: 'space-between',
                  height: hasLogo ? '75px' : '0px',
                }}>
                {organizationData ? (
                  hasLogo ? (
                    <Box
                      style={{
                        height: '75px',
                        visibility: logoHeight === INIT_LOGO_HEIGHT ? 'hidden' : 'visible',
                      }}>
                      <img
                        src={`${API_URL}organization/logo`}
                        alt={organizationData?.name}
                        style={{
                          height: `${logoHeight}%`,
                          width: 'auto',
                          maxWidth: '100%',
                          objectFit: 'contain',
                        }}
                      />
                    </Box>
                  ) : (
                    <Box />
                  )
                ) : (
                  <Card variant='ghost' />
                )}

                {!mobile && (
                  <Grid marginTop={hasLogo ? '25px' : '100px'}>
                    <InfoCard
                      onClick={onSetUpTime}
                      icon={<img src={universityAdvisor} width='60px' />}>
                      <Typography color='secondary' variant='p16' maxWidth='185px' ml='20px'>
                        {t('HaveAnyQuestions?SetUpATime')}
                      </Typography>
                    </InfoCard>
                  </Grid>
                )}
              </Row>

              {(hasLogo || (!mobile && !hasLogo)) && <Spacer height='sm' />}

              <Column
                style={{
                  maxWidth: mobile ? '100%' : 'calc(100% - 350px)',
                }}>
                <Typography variant='h1' color='secondary'>
                  {headerText}
                </Typography>

                {signupPageText && (
                  <Column>
                    <Spacer height='xs' />
                    <Typography variant='p16' color='secondary'>
                      {signupPageText}
                    </Typography>
                  </Column>
                )}
              </Column>

              <Spacer height='xs' />

              <Link variant='h3' href='/'>
                {t('AlreadyHaveAnAccount?LogIn')}
              </Link>

              <Spacer height='xs' />

              <ProductOptions
                memberPaidOptions={memberPaidOptions}
                orgPaidOptions={orgPaidOptions}
                pricebookMetadata={pricebookMetadata}
                values={values}
                selectedPricebook={selectedPricebook}
                setSelectedPricebook={
                  memOptions > 1 || orgOptions > 1 ? setSelectedPricebook : undefined
                }
                onSubmit={onSubmit}
              />

              {!specialPromoOffer && !specialNonProfitOffer && (
                <Typography className='link-wrapper' variant='p14' color='secondary'>
                  <Trans
                    i18nKey='PleaseSeeTOSandCancellationPolicy'
                    components={{
                      TermsOfService: <Link href={tosLink} target='_blank' />,
                      CancellationPolicy: <Link href='/cancellation-policy' target='_blank' />,
                    }}
                  />{' '}
                  <Trans
                    i18nKey='HaveAnyQuestions?WereAvailablePhoneAndEmail'
                    values={{ emailAddress: 'help@3nickels.com' }}
                    components={{
                      Email: <Link href={'mailto:help@3nickels.com'} />,
                    }}
                  />
                  {t('', {})}
                </Typography>
              )}

              {introPeriodInMonths?.length ?? 0 > 1 ? (
                <>
                  {!isTablet && <Spacer height='xxxs' />}
                  <Typography variant='p14' color='secondary'>
                    *{' '}
                    {t('AfterThePeriodOfFreeAccessEndsAdviceSubscriptionAutoRenewsAt', {
                      price: `${formatDecimalDollars(14.99, language)}/${t('month')}`,
                    })}
                  </Typography>
                </>
              ) : (
                introPeriodInMonths?.map((x, index) => (
                  <>
                    {!isTablet && <Spacer height='xxxs' />}
                    <Typography key={index} variant='p14' color='secondary'>
                      *{' '}
                      {t('AfterXMonthsAdviceSubscriptionAutoRenewsAt', {
                        x,
                        price: `${formatDecimalDollars(14.99, language)}/${t('month')}`,
                      })}
                    </Typography>
                  </>
                ))
              )}

              {(specialPromoOffer || specialNonProfitOffer) && (
                <>
                  <Typography className='link-wrapper' variant='p14' color='secondary'>
                    * {t('TermsAndConditionsApply')}{' '}
                    {t('PromotionalPricingDiscountIsOffStandardMonthlyPricing')}{' '}
                    {t(
                      'WeReserveTheRightToValidateEligibilityAndToModifyOrEndThisPromotionalOfferAtAnyTime'
                    )}{' '}
                    <Trans
                      i18nKey='PleaseSeeTOSandCancellationPolicy'
                      components={{
                        TermsOfService: <Link href={tosLink} target='_blank' />,
                        CancellationPolicy: <Link href='/cancellation-policy' target='_blank' />,
                      }}
                    />
                  </Typography>
                </>
              )}

              {mobile ? (
                <>
                  <Spacer height='sm' />
                  <Column style={{ justifyContent: 'space-between' }}>
                    {isOnlyOneOption && (
                      <>
                        <Button style={{ width: '100%' }} onClick={handleSubmit} color='secondary'>
                          {t('SignUp')}
                        </Button>
                        <Spacer height='xs' />
                      </>
                    )}
                    <Button
                      style={{ width: '100%' }}
                      onClick={onCancel}
                      variant='outlined'
                      color='secondary'>
                      {t('Cancel')}
                    </Button>
                  </Column>
                </>
              ) : (
                <>
                  <Spacer height='sm' />
                  <Row style={{ justifyContent: 'space-between' }}>
                    <Button
                      style={{ minWidth: 'fit-content' }}
                      className='md'
                      onClick={onCancel}
                      variant='outlined'
                      color='secondary'>
                      {t('Cancel')}
                    </Button>
                    <Button
                      disabled={isOnlyOneOption ? false : !selectedPricebook}
                      style={{ minWidth: 'fit-content' }}
                      className='md'
                      onClick={handleSubmit}
                      color='secondary'>
                      {t('SignUp')}
                    </Button>
                  </Row>
                </>
              )}

              {mobile && (
                <>
                  <Spacer height='xs' />
                  <InfoCard
                    onClick={onSetUpTime}
                    icon={<img src={universityAdvisor} width='60px' />}>
                    <Typography color='secondary' variant='p16' maxWidth='185px' ml='20px'>
                      {t('HaveAnyQuestions?SetUpATime')}
                    </Typography>
                  </InfoCard>
                </>
              )}

              <Spacer height='lg' />

              <DomLink
                style={{
                  display: 'flex',
                  width: 'fit-content',
                }}
                to='/'>
                <img
                  src={logo3NSmall}
                  alt='logo'
                  style={{
                    position: 'relative',
                    left: '-35px',
                    width: '250px',
                  }}
                />
              </DomLink>

              <Spacer height='xxs' />

              <Typography color='primary' variant='p12' maxWidth='600px'>
                {t('3NickelsBelievesEveryone')}
              </Typography>
            </Column>
          </>
        )}
      </Form>
    </PageContentWithBackground>
  );
};

export default Welcome;
