import { t } from 'i18next';
import { Card, Divider, Grid, IconButton, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Svgs } from '../../../assets/svg';
import React, { useState } from 'react';
import { Button } from '../../../components/buttons/Button';
import { Colors, Margins } from '../../../themes';
import { Domain } from '@3nickels/data-modules';
import { useDeviceType } from '../../../hooks/useDeviceType';
import { Row } from '../../../components';
import { resolveHeaders } from './functions';
import { convertToTranslationKey } from '../../../helpers/utilityFunctions';

const getFeatureLabel = (feature: string) => {
  switch (feature) {
    case 'Spending overview':
      return 'Spendingoverview';

    default:
      return convertToTranslationKey(feature);
  }
};

const resolveFeatureIcon = (feature: string) => {
  switch (feature) {
    case 'Mobile app access':
      return <Svgs.DisplayMediumCustomCellPhone style={{ marginRight: '20px' }} />;
    case 'One-on-one coaching':
      return <Svgs.DisplaySmallCustomMembers style={{ marginRight: '20px' }} />;
    case 'Live Q&A':
      return <Svgs.DisplaySmallCustomAdvice style={{ marginRight: '20px' }} />;
    case '7+ hours of educational videos':
      return <Svgs.DisplaySmallCustomVideoCamera style={{ marginRight: '20px' }} />;
    case 'Financial planning':
      return <Svgs.DisplaySmallCustomGoals style={{ marginRight: '20px' }} />;
    case 'Investment advice':
      return <Svgs.DisplaySmallCustomInvestment style={{ marginRight: '20px' }} />;
    case 'Account linking':
      return <Svgs.DisplaySmallCustomBank style={{ marginRight: '20px' }} />;
    case 'Spending overview':
      return <Svgs.DisplaySmallCustomLoans style={{ marginRight: '20px' }} />;
    case 'Advanced mini-courses':
      return <Svgs.IconCollege style={{ marginRight: '20px' }} />;
    case 'Personalized tools':
      return <Svgs.DisplaySmallCustomCalculator style={{ marginRight: '20px' }} />;
    case 'Budgeting':
      return <Svgs.DisplaySmallCustomBudget style={{ marginRight: '20px' }} />;
    default:
      return <Svgs.DisplaySmallCustomBook style={{ marginRight: '20px' }} />;
  }
};

const BasicTools = ['Mobile app access', 'Personalized tools', 'Budgeting', 'Basic mini-courses'];
const Advice = [
  'Mobile app access',
  'Financial planning',
  'Investment advice',
  'Account linking',
  'Spending overview',
  'Advanced mini-courses',
  'Personalized tools',
  'Budgeting',
  'Basic mini-courses',
];
const AdviceAnd3NU = [
  'Mobile app access',
  'One-on-one coaching',
  'Live Q&A',
  '7+ hours of educational videos',
  'Financial planning',
  'Investment advice',
  'Account linking',
  'Spending overview',
  'Advanced mini-courses',
  'Personalized tools',
  'Budgeting',
  'Basic mini-courses',
];

type HeadersDataType = {
  'Basic Tools': string[];
  'Advice': string[];
  'Advice + 3NickelsU': string[];
  [key: string]: string[];
};

interface PlanFeaturesTableProps {
  products: Domain.PricebookBundle[];
}

const PlanFeaturesTable: React.FC<PlanFeaturesTableProps> = ({ products }) => {
  const { isMobile, isTablet } = useDeviceType();
  const mobile = isMobile || isTablet;
  const headers = resolveHeaders(products);
  const [expanded, setExpanded] = useState(mobile ?? false);
  const headersData: HeadersDataType = {
    'Basic Tools': BasicTools,
    'Advice': Advice,
    'Advice + 3NickelsU': AdviceAnd3NU,
  };
  const includesUni = headers.find((header) => header === 'Advice + 3NickelsU');
  const includesAdvice = headers.find((header) => header === 'Advice');
  const allFeatures =
    headers.length > 1
      ? includesUni
        ? headersData['Advice + 3NickelsU']
        : includesAdvice
        ? headersData['Advice']
        : headersData['Basic Tools']
      : headersData[headers[0]];
  const removePadding = mobile && headers.length > 1;

  return (
    <>
      {mobile ? (
        headers.length === 1 && (
          <Typography mt={3} variant='p18Bold' color='secondary'>
            {t('WhatsIncluded')}
          </Typography>
        )
      ) : (
        <Button
          className='ghostAlt'
          sx={{
            textDecoration: 'none !important',
            paddingLeft: '0px !important',
            marginTop: '15px',
          }}
          onClick={() => setExpanded(!expanded)}
          disableRipple>
          <Grid item justifySelf='center' alignSelf='center'>
            <Typography variant='p18Bold' color='secondary'>
              {headers.length == 1 ? t('WhatsIncluded') : t('CompareOptions')}
            </Typography>
          </Grid>
          <Grid item sx={{ marginLeft: '8px', marginTop: '5px' }}>
            <IconButton onClick={() => setExpanded(!expanded)}>
              {expanded ? (
                <KeyboardArrowUpIcon fontSize='large' color='primary' />
              ) : (
                <KeyboardArrowDownIcon fontSize='large' color='primary' />
              )}
            </IconButton>
          </Grid>
        </Button>
      )}

      {(mobile || expanded) && (
        <Grid
          container={mobile ? undefined : true}
          item={mobile ? undefined : true}
          xs={mobile ? undefined : headers.length > 2 ? 12 : headers.length === 2 ? 8 : 6}
          spacing={mobile ? undefined : 3}>
          <Grid item xs={6} sm={6} />
          {!mobile &&
            headers.map((header, i) => {
              return (
                <Grid
                  item
                  xs={Math.floor(6 / headers.length)}
                  sm={Math.floor(6 / headers.length)}
                  key={i}
                  container
                  justifyContent='center'
                  alignItems='center'
                  style={{ padding: '0px 15px' }}>
                  <Typography
                    variant={mobile ? 'p16Bold' : 'p18Bold'}
                    color={mobile ? 'secondary' : 'primary'}>
                    {header}
                  </Typography>
                </Grid>
              );
            })}

          {mobile ? (
            <Grid
              sx={[
                Margins.mt_xs,
                {
                  padding: '20px',
                  borderRadius: removePadding ? '10px 10px 0px 0px' : '10px',
                  backgroundColor: Colors.tertiaryBase,
                  elevation: 5,
                },
              ]}>
              {headers.length > 1 && (
                <>
                  <Row style={{ justifyContent: 'space-between' }}>
                    <Grid sx={{ width: `${100 - headers.length * 20}%` }} />
                    {headers.map((header, i) => {
                      return (
                        <Typography
                          key={i}
                          sx={{ width: '20%', textAlign: 'center', justifyContent: 'center' }}
                          variant={'p16Bold'}
                          color={'secondary'}>
                          {header}
                        </Typography>
                      );
                    })}
                  </Row>
                  <Divider
                    sx={{ borderColor: Colors.primaryLightest }}
                    style={{ margin: '10px 0px' }}
                  />
                </>
              )}

              {allFeatures.map((feature, i) => {
                const shouldRenderRow = headers.some((header) =>
                  headersData[header].includes(feature)
                );
                const shouldRenderDivider = i !== 0;

                return (
                  shouldRenderRow && (
                    <>
                      {shouldRenderDivider && (
                        <Divider
                          sx={{ borderColor: Colors.primaryLightest }}
                          style={{ margin: '10px 0px' }}
                        />
                      )}
                      <MobileRow
                        key={i}
                        feature={feature}
                        headers={headers}
                        headersData={headersData}
                      />
                    </>
                  )
                );
              })}
            </Grid>
          ) : (
            <Grid container item xs={12}>
              <Card className='card-row' style={{ width: '100%', padding: '15px' }}>
                {allFeatures.map((feature, i) => {
                  const shouldRenderRow = headers.some((header) =>
                    headersData[header].includes(feature)
                  );
                  const shouldRenderDivider = i !== 0;

                  return (
                    shouldRenderRow && (
                      <>
                        {shouldRenderDivider && (
                          <Divider
                            sx={{ borderColor: Colors.primaryLightest }}
                            style={{ margin: '0px 15px' }}
                          />
                        )}
                        <DesktopRow
                          key={i}
                          feature={feature}
                          headers={headers}
                          headersData={headersData}
                        />
                      </>
                    )
                  );
                })}
              </Card>
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
};

interface MobileRowProps {
  feature: string;
  headers: string[];
  headersData: HeadersDataType;
}
const MobileRow: React.FC<MobileRowProps> = ({ feature, headers, headersData }) => {
  const featureLabel = getFeatureLabel(feature);

  return (
    <Row
      style={{
        justifyContent: 'space-between',
        alignItems: 'center',
      }}>
      <Row style={{ width: `${100 - headers.length * 20}%`, alignItems: 'center' }}>
        <Grid style={{ width: '30px' }}>{resolveFeatureIcon(feature)}</Grid>
        <Typography ml={2} color='secondary' variant='p16'>
          {t(featureLabel)}
        </Typography>
      </Row>
      {headers.map((header: string) =>
        headersData[header].includes(feature) ? (
          <Svgs.DisplayExtraSmallCustomCheckmark style={{ width: '20%', alignSelf: 'center' }} />
        ) : (
          <Svgs.DisplayExtraSmallCustomX style={{ width: '20%' }} />
        )
      )}
    </Row>
  );
};

interface DesktopRowProps {
  feature: string;
  headers: string[];
  headersData: HeadersDataType;
}
const DesktopRow: React.FC<DesktopRowProps> = ({ feature, headers, headersData }) => {
  const featureLabel = getFeatureLabel(feature);

  return (
    <Grid container item xs={12}>
      <Grid
        item
        xs={6}
        sm={6}
        justifyContent='center'
        alignItems='center'
        style={{ padding: '15px' }}>
        <Grid
          container
          display='flex'
          justifyContent='space-between'
          alignItems='center'
          columnSpacing='40px'>
          <Grid item xs={1}>
            {resolveFeatureIcon(feature)}
          </Grid>
          <Grid item xs={10} alignItems='left'>
            <Typography variant='p16' color='secondary'>
              {t(featureLabel)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {headers.map((header: string, j: number) => (
        <Grid
          item
          xs={Math.floor(6 / headers.length)}
          sm={Math.floor(6 / headers.length)}
          key={j}
          container
          justifyContent='center'
          alignItems='center'
          style={{ padding: '15px' }}>
          {headersData[header].includes(feature) ? (
            <Svgs.IconCheckmark />
          ) : (
            <Svgs.DisplayExtraSmallCustomX />
          )}
        </Grid>
      ))}
    </Grid>
  );
};

export default PlanFeaturesTable;
