/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useMemo } from 'react';
import { useForm, useFormContext } from 'react-hook-form';
import { useAutoSaveHandler } from '../../../../hooks/useAutoSaveHandler';
import { Data, Hooks, formatWholeDollars } from '@3nickels/data-modules';
import { BudgetSectionProps, FoodFormData, mapFoodFormToMonthlySpendingRest } from '../../Types';
import * as Yup from 'yup';
import EmbeddedFormInput from '../../../../components/EmbeddedFormInput';
import { Grid } from '@mui/material';
import { cleanWholeNumberStr } from '../../../../helpers/utilityFunctions';
import { SpendingProps } from '../Types';
import { CollapsiblePanel } from '../../../../components/CollapsiblePanel';
import { Svgs } from '../../../../assets/svg';
import FormContent from '../../../../components/form/FormContent';
import _ from 'lodash';
import { t } from 'i18next';

const schema: () => Yup.Schema = () =>
  Yup.object({
    otherTransportation: Yup.string().notRequired(),
    transportation: Yup.string().notRequired(),
    carMaintenance: Yup.string().notRequired(),
    carInsurance: Yup.string().notRequired(),
    fuel: Yup.string().notRequired(),
  });

export declare type FoodFormProps = BudgetSectionProps & { defaultValues: FoodFormData };

export const FoodForm: React.FC<FoodFormProps> = ({ defaultValues, mode }) => {
  const { formState } = useFormContext<FoodFormData>();
  const { person } = Hooks.useCombinedSelfData();
  const commands = Hooks.useCommands();

  const max = useMemo(() => {
    if (mode === 'monthly') {
      return 999999;
    }

    return 999999 * 12;
  }, [mode]);

  const formatInputs = (data: any): FoodFormData => {
    const grocery = cleanWholeNumberStr(data.grocery ?? '', { max });
    const restaurants = cleanWholeNumberStr(data.restaurants ?? '', { max });
    const otherFood = cleanWholeNumberStr(data.otherFood ?? '', { max });

    return { grocery, restaurants, otherFood };
  };

  const saveHandler = useCallback(
    async (data: FoodFormData) => {
      const params = mapFoodFormToMonthlySpendingRest(mode, data);
      const filtered = _.pickBy(params, (x) => typeof x !== 'undefined');
      await commands.execute(Data.Budget.Commands.UpdateMonthlySpending, filtered);
    },
    [person, mode, commands]
  );

  const { onChange } = useAutoSaveHandler({
    defaultValues,
    mode: 'partial',
    onSave: saveHandler,
    schema: schema(),
    transformValues: formatInputs,
  });

  // TODO -- CardDisplay should be the refactored version: CollapsibleCard
  // (we just need to create it)
  return (
    <form onChange={onChange}>
      <Grid className='embedded-form'>
        <EmbeddedFormInput<FoodFormData>
          label='Groceries'
          placeholder='$0'
          autoComplete='off'
          name='grocery'
          type='dollar'
          max={max}
          defaultValue={defaultValues.grocery}
          error={formState.errors.grocery !== undefined}
          helperText={formState.errors.grocery?.message?.toString()}
        />
        <EmbeddedFormInput<FoodFormData>
          label='Restaurants'
          placeholder='$0'
          autoComplete='off'
          name='restaurants'
          type='dollar'
          max={max}
          defaultValue={defaultValues.restaurants}
          error={formState.errors.restaurants !== undefined}
          helperText={formState.errors.restaurants?.message?.toString()}
        />
        <EmbeddedFormInput<FoodFormData>
          label='OtherFoodCosts'
          placeholder='$0'
          autoComplete='off'
          name='otherFood'
          type='dollar'
          max={max}
          defaultValue={defaultValues.otherFood}
          error={formState.errors.otherFood !== undefined}
          helperText={formState.errors.otherFood?.message?.toString()}
        />
      </Grid>
    </form>
  );
};

export const FoodSpendingSection: React.FC<BudgetSectionProps & SpendingProps> = ({
  mode,
  monthlySpending,
  spendingSummary,
}) => {
  const defaultValues = useMemo(() => {
    return {
      grocery: Data.Budget.adjustMonthlyAmount(mode, monthlySpending?.grocery),
      restaurants: Data.Budget.adjustMonthlyAmount(mode, monthlySpending?.restaurants),
      otherFood: Data.Budget.adjustMonthlyAmount(mode, monthlySpending?.otherFood),
    };
  }, [monthlySpending, mode]);

  const summary = useMemo(
    () => Data.Budget.adjustMonthlyAmount(mode, spendingSummary.food),
    [spendingSummary, mode]
  );

  const methods = useForm<FoodFormData>({ defaultValues });
  return (
    <CollapsiblePanel
      variant='compressed'
      header={t('Food')}
      expandIconPosition='relative'
      icon={<Svgs.DisplaySmallCustomFood />}
      summary={formatWholeDollars(summary)}>
      <Grid className='embedded-content'>
        <FormContent formProviderProps={methods}>
          <FoodForm defaultValues={defaultValues} mode={mode} />
        </FormContent>
      </Grid>
    </CollapsiblePanel>
  );
};
