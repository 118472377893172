import { Typography } from '@mui/material';
import { UniversityUpsellHeaderCard } from './UniversityUpsellHeaderCard';
import { UniversityUpsellFooterCard } from './UniversityUpsellFooterCard';
import { SubscriptionDetails } from '../SubscriptionDetails';
import { FreeCarousel } from '../FreeCarousel';
import { t } from 'i18next';

export const UniversityUpsellView: React.FC = () => {
  return (
    <>
      <UniversityUpsellHeaderCard />

      <Typography mt={5} mb={3} variant='p18Bold' color='primary'>
        {t('HereWhatYouGet')}
      </Typography>

      <FreeCarousel variant='nickelsU' />

      <SubscriptionDetails
        sx={{ padding: '38px', paddingBottom: '45px', mx: '15%' }}
        buttonProps={{ color: 'secondary' }}
      />

      <UniversityUpsellFooterCard />
    </>
  );
};
