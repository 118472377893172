import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Stack, Typography } from '@mui/material';
import { Spacing } from '../../../../themes';
import { KeyValuePair, Spacer } from '../../../../components';
import { Api, Domain, Hooks } from '@3nickels/data-modules';
import { formatPercent, formatWholeDollars } from '../../../../helpers/utilityFunctions';
import InfoCard from '../../../../components/InfoCard';
import { useNavigate } from 'react-router-dom';

type HowMuchHouseCalculatorResultsProps = {
  results: Api.HouseAffordabilityResultRest | undefined;
};

const HowMuchHouseCalculatorResults: React.FC<HowMuchHouseCalculatorResultsProps> = ({
  results,
}) => {
  const { t } = useTranslation();
  const { wizard } = Hooks.useBasicGoalWizard();
  const navigate = useNavigate();

  const onInfoCardClick = () => {
    wizard.start({ type: Domain.GoalTypeEnum.House });
    navigate('/goals/house/buy');
  };

  return (
    <Grid container direction='column'>
      <Grid item>
        <Stack spacing={Spacing.xxxs + 0.5}>
          <Spacer height='xxs' />
          <Typography variant='p16Bold' color='secondary'>
            {t('MortgageDetails')}
          </Typography>
          <KeyValuePair
            label={t('MonthlyPayment')}
            value={formatWholeDollars(results?.monthlyPayment ?? 0)}
          />
          <KeyValuePair
            label={t('DownPayment')}
            value={`${formatWholeDollars(results?.downPaymentDollars ?? 0)} (${formatPercent(
              results?.downPaymentPercentage ?? 0
            )})`}
          />
          <KeyValuePair
            label={t('InterestRate')}
            value={formatPercent(results?.interestRate ?? 0)}
          />
          <KeyValuePair
            label={t('MortgageTerm')}
            value={t(
              Domain.MortgageTermTypeEnumMap.get(
                results?.mortgageTermType ?? Domain.MortgageTermTypeEnum['30-Year Fixed']
              ) ?? 'Other'
            )}
          />

          <Spacer height='xxxs' />
          <InfoCard onClick={onInfoCardClick}>
            <Typography variant='p16' color='secondary'>
              {t('ReadyToStartSavingForAHouse')}
            </Typography>
          </InfoCard>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default HowMuchHouseCalculatorResults;
