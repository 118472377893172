import { useNavigate } from 'react-router-dom';
import { Data, Domain } from '@3nickels/data-modules';
import LmsLessonProgressCard from '../../learning-management-system/LmsLessonProgressCard';
import { useObservableQuery } from '@aesop-fables/scrinium';

export function findCourseByUnitId(unitId: number, courses: Domain.CourseDto[]) {
  for (let courseIndex = 0; courseIndex < courses.length; courseIndex++) {
    const course = courses[courseIndex];
    if (course.units) {
      for (let unitIndex = 0; unitIndex < course.units.length; unitIndex++) {
        const unit = course.units[unitIndex];
        if (unit.unitId === unitId) {
          return course;
        }
      }
    }
  }
  return {};
}

interface LessonResultCardProps {
  lesson: Domain.LessonDto;
  courses: Domain.CourseDto[];
}

const LessonResultCard: React.FC<LessonResultCardProps> = ({ lesson, courses }) => {
  const navigate = useNavigate();
  const course = findCourseByUnitId(lesson?.unitId ?? 0, courses);
  const lessonWithProgress =
    useObservableQuery(Data.Lms.Queries.FindLessonByLessonId, lesson.lessonId) ?? {};
  const isUniversityLesson = !lesson.contents;

  const pathnameSegments = location.pathname.split('/');
  const toolsIndex = pathnameSegments.indexOf('tools');
  const module = pathnameSegments[toolsIndex + 1];

  const startLesson = () => {
    if (isUniversityLesson) {
      if (!lesson.name) return;
      navigate(`/tools/${module}/your-lessons/${encodeURIComponent(lesson.name)}`);
      return;
    }

    if (lesson.quiz && lesson.quiz.mostRecentAttempt) {
      navigate(
        `${course.courseId}/${lesson.unitId}/${lesson.lessonId}/${lesson.quiz.quizId}/results`
      );
    } else {
      navigate(`${course.courseId}/${lesson.unitId}/${lesson.lessonId}/content`);
    }
  };

  return (
    <LmsLessonProgressCard
      isUniversityLesson={isUniversityLesson}
      lesson={isUniversityLesson ? lesson : lessonWithProgress}
      startLesson={startLesson}
      yourLessonsView
    />
  );
};

export default LessonResultCard;
