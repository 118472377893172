import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Data } from '@3nickels/data-modules';
import { useService } from '@aesop-fables/containr-react';
import { Box } from '@mui/material';
import { AccountSettingsWizard, accountSettingsWizardKey } from '../../../services/accountSettings';
import TextInput from '../../../components/form/TextInput';
import FormContent from '../../../components/form/FormContent';
import { WizardFooter } from '../../../components/form/WizardFooter';
import { useMessage } from '../../../hooks/useMessage';
import { t } from 'i18next';

const currentPasswordSchema = () =>
  Yup.object({
    password: Yup.string().required(t('Required') as string),
  });

interface CurrentPasswordFormProps {
  statusComplete: boolean;
  setStatusComplete: React.Dispatch<React.SetStateAction<boolean>>;
}

const CurrentPasswordForm: React.FC<CurrentPasswordFormProps> = ({
  statusComplete,
  setStatusComplete,
}) => {
  const { t } = useTranslation();
  const { showMessage } = useMessage();
  const accountSettingsWizard = useService<AccountSettingsWizard>(accountSettingsWizardKey);
  const methods = useForm<Data.ChangePassword.ChangePasswordData>({
    resolver: yupResolver(currentPasswordSchema()),
  });

  const changePassword = async (values: Data.ChangePassword.ChangePasswordData) => {
    try {
      await accountSettingsWizard.initiateChangePassword(values);
      setStatusComplete(true);
    } catch (err) {
      showMessage(t('WeWerentBankingOnThat') as string, 'error');
    }
  };

  const onSubmit = methods.handleSubmit((data) => {
    changePassword(data);
  });

  return (
    <FormContent formProviderProps={methods}>
      <Box component='form' onSubmit={onSubmit}>
        <TextInput<Data.ChangePassword.ChangePasswordData>
          error={methods.formState.errors.password !== undefined}
          helperText={methods.formState.errors.password?.message?.toString()}
          InputProps={{ readOnly: statusComplete }}
          label='EnterCurrentPassword'
          placeholder={t('EnterCurrentPassword') as string}
          name='password'
          type='password'
        />
        {!statusComplete && (
          <WizardFooter onDone={onSubmit} nextLabel='Submit' backLabel='Cancel' />
        )}
      </Box>
    </FormContent>
  );
};

export default CurrentPasswordForm;
