/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { Data, Domain, Hooks } from '@3nickels/data-modules';
import { FormLoader } from '../../../../components/FormLoader';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import FormContent from '../../../../components/form/FormContent';
import { WizardStep, useObservable } from '@aesop-fables/scrinium';
import { LayoutMeta } from '../../../../types/LayoutMeta';
import { WizardFooter } from '../../../../components/form/WizardFooter';
import { useLoading } from '../../../../hooks/useLoading';
import { EditViewProps, getWizardFooterProps } from '../../../EditViewUtils';
import { GoalsLayoutMeta } from '../../GoalsLayout';
import CashGivingGoalBasicForm from './CashGivingGoalBasicForm';
import { cleanWholeNumberStr } from '../../../../helpers/utilityFunctions';
import { GoalFormTitle } from '../../../../components/FormTitle';
import CashGivingGoalDetailsForm from './CashGivingGoalDetailsForm';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';

const formSchema = () =>
  Yup.object({
    dollarOrPercentSelf: Yup.string().required(t('Required') as string),
    personalGivingDream: Yup.string().when('dollarOrPercentSelf', {
      is: Domain.GivingPlanDollarPercentEnum.Dollar.toString(),
      then: (schema) =>
        schema
          .required(t('Required') as string)
          .test('amount', t('AmountMustBeGreaterThan0') as string, (amount) => {
            const cleanNumber = cleanWholeNumberStr(amount);
            return cleanNumber > 0;
          }),
    }),
    personalGivingDreamPercent: Yup.string().when('dollarOrPercentSelf', {
      is: Domain.GivingPlanDollarPercentEnum.Percent.toString(),
      then: (schema) =>
        schema
          .required(t('Required') as string)
          .test('amount', t('AmountMustBeGreaterThan0') as string, (amount) => {
            const cleanNumber = cleanWholeNumberStr(amount);
            return cleanNumber > 0;
          }),
    }),
    frequencyGivingSelf: Yup.string().required(t('Required') as string),
    currentPersonalGiving: Yup.string()
      .required(t('Required') as string)
      .test(
        'currentPersonalGiving',
        t('AmountICurrentlyGiveMustBeGreaterThan0') as string,
        (amount) => {
          const cleanNumber = cleanWholeNumberStr(amount);
          return cleanNumber > 0;
        }
      ),
  });

export const CashGivingGoalCombinedEditViewWrapper: React.FC<EditViewProps> = ({
  editing,
  onBack,
}) => {
  const { loading, currentStep, wizard } =
    Hooks.useCashGivingGoalWizard<Data.Goals.CashGivingGoalBasicFormData>();
  const isStarted = useObservable(wizard.isStarted$);

  useEffect(() => {
    if (isStarted) {
      wizard.selectStep('details');
    }
  }, [isStarted]);

  return (
    <FormLoader loading={loading}>
      <CashGivingGoalCombinedEditView
        currentStep={currentStep}
        wizard={wizard}
        editing={editing}
        onBack={onBack}
      />
    </FormLoader>
  );
};

interface CashGivingGoalCombinedEditViewProps extends EditViewProps {
  currentStep: WizardStep<Data.Goals.CashGivingGoalBasicFormData, Data.Goals.WizardParamsWithId>;
  wizard: Data.Goals.CashGivingGoalWizard;
}

const CashGivingGoalCombinedEditView: React.FC<CashGivingGoalCombinedEditViewProps> = ({
  currentStep,
  wizard,
  editing,
  onBack,
}) => {
  const methods = useForm<Data.Goals.CashGivingGoalBasicFormData>({
    defaultValues: currentStep.model,
    resolver: yupResolver(formSchema()),
  });
  const { t } = useTranslation();
  const { setLoading } = useLoading();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formatInputs = (values: any) => {
    const personalGivingDream = cleanWholeNumberStr(values.personalGivingDream);
    const personalGivingDreamPercent = cleanWholeNumberStr(values.personalGivingDreamPercent);
    const currentPersonalGiving = cleanWholeNumberStr(values.currentPersonalGiving ?? '');

    return {
      personalGivingDream,
      personalGivingDreamPercent,
      currentPersonalGiving,
    };
  };

  const onSubmit = async (values: any) => {
    setLoading(true);

    try {
      const cleanedValues = formatInputs(values);
      currentStep.save({
        ...values,
        ...cleanedValues,
      });

      // The models are combined and sent up so let's just use the last step to save both
      await wizard.commitStep('details');

      if (editing && onBack) {
        onBack();
        return;
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <FormContent formProviderProps={methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Box>
            <GoalFormTitle editing={editing} type={Domain.GoalTypeEnum.Cash_Giving} />

            <Typography variant='p16Bold' color='secondary' mt={2}>
              {t('DreamGiving')}
            </Typography>
            <Typography mt='7px' variant='p12' color='primary'>
              {t('NoteYourDreamGivingIsLimited')}
            </Typography>
            <Grid container justifyContent='center' mt={2}>
              <Grid item sm={editing ? 10 : 6}>
                <CashGivingGoalBasicForm model={currentStep.model} onSubmit={onSubmit} />
              </Grid>
            </Grid>

            <Typography variant='p16Bold' color='secondary' mt={2}>
              {t('CurrentGiving')}
            </Typography>
            <Grid container justifyContent='center' mt={2}>
              <Grid item sm={editing ? 10 : 6}>
                <CashGivingGoalDetailsForm model={currentStep.model} onSubmit={onSubmit} />
              </Grid>
            </Grid>
          </Box>
          <WizardFooter
            color={editing ? 'primary' : undefined}
            disableBack={false}
            onBack={onBack}
            {...getWizardFooterProps(meta.nextLocaleKey, editing)}
          />
        </form>
      </FormContent>
    </>
  );
};

const meta = {
  nextLocaleKey: 'Save&Continue',
  title: 'Giving',
} satisfies LayoutMeta<GoalsLayoutMeta>;
