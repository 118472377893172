import { useEffect, useState } from 'react';
import { LayoutMeta, withLayoutMeta } from '../../../../types/LayoutMeta';
import { ToolsLayoutMeta } from '../../ToolsLayout';
import { Api, Data, Hooks } from '@3nickels/data-modules';
import { Grid, Stack, Typography } from '@mui/material';
import ToolHeader from '../../../../components/ToolHeader';
import { Spacing } from '../../../../themes';
import { useLoading } from '../../../../hooks/useLoading';
import { LeftColumn } from './LeftColumn';
import { RightColumn } from './RightColumn';
import { Row } from '../../../../components';
import { errorBase, secondaryBase } from '../../../../themes/colors';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import { desert } from '../../../../assets/png';

const DebtConsolidationView: React.FC = () => {
  const { setLoading } = useLoading();
  const commands = Hooks.useCommands();
  const debts = Hooks.useCombinedDebtsWithCarLoan();
  const [debtConsolidationResultData, setDebtConsolidationResultData] =
    useState<Api.DebtConsolidationResultRest>();
  const noDebts = !debts || debts.length === 0;
  const savingsOfInterest = debtConsolidationResultData?.savingsOfInterest ?? 0;
  const savingsOfTime = debtConsolidationResultData?.savingsOfTime ?? 0;
  const wouldHelp = savingsOfInterest > 0 || savingsOfTime > 0;

  useEffect(() => {
    if (noDebts) return;
    calculate();
  }, [noDebts]);

  const calculate = async () => {
    setLoading(true);
    try {
      const result = await commands.execute(
        Data.Debt.Commands.CalculateAdviceDebtConsolidation,
        {}
      );
      setDebtConsolidationResultData(result);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const onDownload = async () => {
    setLoading(true);

    try {
      const pdfResults = await commands.execute(
        Data.Debt.Commands.CalculateAdviceDebtConsolidationPdf,
        {}
      );
      const url = window.URL.createObjectURL(
        new Blob([pdfResults.data], { type: 'application/pdf' })
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'DebtConsolidation.pdf');
      document.body.appendChild(link);
      link.click();
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid display='flex' justifyContent='space-between' alignItems='center'>
      <Grid container flexDirection='column'>
        <Stack spacing={Spacing.xxs}>
          <ToolHeader
            title={
              noDebts ? (
                'NoDebtsYet'
              ) : (
                <Row>
                  <Typography color='primary' variant='p30' display='inline'>
                    <Trans
                      i18nKey='WouldConsolidationHelp'
                      values={{ wouldHelp: wouldHelp ? t('Yes!') : t('No') }}
                      components={{
                        Styled: (
                          <Typography
                            sx={{ color: wouldHelp ? secondaryBase : errorBase, display: 'inline' }}
                            variant='p30Bold'
                          />
                        ),
                      }}
                    />
                  </Typography>
                </Row>
              )
            }
            subtitle={
              noDebts
                ? undefined
                : wouldHelp
                ? 'DebtConsolidationCouldBeBeneficial'
                : 'DebtConsolidationWouldNotBeBeneficial'
            }
            subtitleStyle={{ color: wouldHelp ? secondaryBase : errorBase }}
            onDownload={wouldHelp && !noDebts ? onDownload : undefined}
          />
          <Grid container alignItems='flex-start'>
            <LeftColumn
              wouldHelp={wouldHelp}
              noDebts={noDebts}
              savingsOfTime={wouldHelp ? savingsOfTime : 0}
              savingsOfInterest={wouldHelp ? savingsOfInterest : 0}
            />
            <RightColumn
              wouldHelp={wouldHelp}
              noDebts={noDebts}
              results={debtConsolidationResultData}
            />
          </Grid>
        </Stack>
      </Grid>
    </Grid>
  );
};

const meta = {
  background: <img src={desert} className='tools-start-view-background' />,
} satisfies LayoutMeta<ToolsLayoutMeta>;

export default withLayoutMeta(DebtConsolidationView, meta);
