import { Grid } from '@mui/material';
import React, { useMemo } from 'react';
import { LayoutMeta, withLayoutMeta } from '../../../types/LayoutMeta';
import { HolisticFinancialAdviceLayoutMeta } from '../HolisticFinancialAdviceLayout';
import { WizardFooter } from '../../../components/form/WizardFooter';
import { Domain, Hooks } from '@3nickels/data-modules';
import { useNavigate, useParams } from 'react-router-dom';
import GoalHeader from './GoalHeader';
import { Loading } from '../../../hooks/useLoading';
import { useBackHandler } from '../progress-indicator/useBackHandler';
import GoalDetailsCard from './GoalDetailsCard';
import { useNeedsOnboarding } from '../../../hooks';
import useAdviceResultsNextInvestmentAccount from '../../../hooks/useAdviceResultsNextInvestmentAccount';
import { useUniqueInvMixForNavigation } from '../progress-indicator/useUniqueInvMixForNavigation';
import { t } from 'i18next';

const GoalOverviewWrapper: React.FC = () => {
  const holisticAdviceOverview = Hooks.useHolisticAdviceOverview();
  const holisticAdvice = Hooks.useHolisticAdvice();
  const { id } = useParams();
  const goalId = parseInt(id ?? '');
  const totalGoals = holisticAdviceOverview?.goalSummaries?.length ?? 0;
  const ssi = holisticAdviceOverview?.ssiIncomeFromRetirementAdvise ?? 0;
  const holisticGoalAdvice = holisticAdvice?.holisticGoalAdvices?.find(
    (x) => x.goal?.id === goalId
  );
  const backHandler = useBackHandler(holisticAdviceOverview, 'goal', goalId);

  const [goal, index, nextId, nextInvestmentId] = useMemo(() => {
    const goal = holisticAdviceOverview?.goalSummaries?.find(
      (x) => x.advisedGoalPlan.id === goalId
    );
    const index =
      holisticAdviceOverview?.goalSummaries.findIndex((x) => x.advisedGoalPlan.id === goalId) ?? -1;

    if (typeof index === 'undefined' || index < 0) return [goal, index];

    const next = holisticAdviceOverview?.goalSummaries[index + 1];
    const navigationInvMix = useUniqueInvMixForNavigation(
      holisticAdviceOverview?.investmentMixes ?? []
    );

    return [goal, index, next?.advisedGoalPlan.id, navigationInvMix[0]?.id];
  }, [holisticAdviceOverview, goalId]);

  if (!goal) {
    return <Loading />;
  }

  return (
    <GoalOverview
      goal={goal}
      index={index}
      totalGoals={totalGoals}
      nextId={nextId}
      nextInvestmentId={nextInvestmentId}
      ssi={ssi}
      projectedFutureMonthlyIncome={
        holisticGoalAdvice?.retirementAdvice?.projectedFutureMonthlyIncome ?? 0
      }
      onBack={backHandler}
    />
  );
};

export interface GoalOverviewProps {
  goal: Domain.GoalSummary;
  index: number;
  totalGoals: number;
  nextId?: number;
  nextInvestmentId?: number;
  ssi: number;
  onBack: (() => void) | undefined;
  projectedFutureMonthlyIncome: number;
}

const GoalOverview: React.FC<GoalOverviewProps> = ({
  goal,
  index,
  totalGoals,
  nextId,
  nextInvestmentId,
  ssi,
  onBack,
  projectedFutureMonthlyIncome,
}) => {
  const navigate = useNavigate();
  const nextLabel = nextId
    ? t('NextGoal')
    : nextInvestmentId
    ? t('NextMyInvestments')
    : t('NextMyPlan');
  const onboarding = useNeedsOnboarding();
  const navigateToNext = useAdviceResultsNextInvestmentAccount();

  const onDone = useMemo(() => {
    if (nextId) {
      return () => navigate(`/holistic-advice/goals/${nextId}`);
    } else if (onboarding) {
      if (nextInvestmentId) {
        return () => navigate('/holistic-advice/investments');
      }
      // skip investments section if there are none
      return () => navigate('/holistic-advice/plan');
    }

    // skip start screen if not in onboarding
    return () => navigateToNext(nextInvestmentId);
  }, [onboarding, nextId, nextInvestmentId]);

  return (
    <Grid container justifyContent='center'>
      <Grid container>
        <GoalHeader goal={goal} index={index} totalGoals={totalGoals} />
        <GoalDetailsCard
          goal={goal}
          ssi={ssi}
          projectedFutureMonthlyIncome={projectedFutureMonthlyIncome}
        />
      </Grid>
      <WizardFooter nextLabel={nextLabel} onDone={onDone} onBack={onBack} />
    </Grid>
  );
};

const meta = {
  nextLocaleKey: 'Done',
  showProgress: true,
} satisfies LayoutMeta<HolisticFinancialAdviceLayoutMeta>;

export default withLayoutMeta(GoalOverviewWrapper, meta);
