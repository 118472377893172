/* eslint-disable @typescript-eslint/no-explicit-any */
import { Backdrop, Box, Grid } from '@mui/material';
import React, { useEffect } from 'react';
import {
  Data,
  Domain,
  Hooks,
  ObservableGuard,
  useObservableGuardCondition,
} from '@3nickels/data-modules';
import { FormLoader } from '../../../components/FormLoader';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormContent from '../../../components/form/FormContent';
import { WizardStep, useObservable } from '@aesop-fables/scrinium';
import { LayoutMeta, withLayoutMeta } from '../../../types/LayoutMeta';
import { WizardFooter } from '../../../components/form/WizardFooter';
import { Loading, useLoading } from '../../../hooks/useLoading';
import { firstValueFrom, map } from 'rxjs';
import { EditViewProps, getWizardFooterProps } from '../../EditViewUtils';
import { GoalsLayoutMeta } from '../GoalsLayout';
import VacationGoalForm from './VacationGoalForm';
import useBasicGoalValidation from '../../../hooks/useBasicGoalValidation';
import { GoalFormTitle } from '../../../components/FormTitle';
import { AxiosError } from 'axios';

export const VacationGoalEditViewWrapper: React.FC<EditViewProps> = ({ editing, onBack }) => {
  const { loading, currentStep, wizard } = Hooks.useBasicGoalWizard();
  const isStarted = useObservable(wizard.isStarted$);
  const params = useObservable(wizard.params$);
  const { id } = useParams();

  useEffect(() => {
    if (typeof isStarted !== 'undefined' && !isStarted) {
      wizard.start({ id: parseInt(id ?? ''), type: Domain.GoalTypeEnum.Vacation });
    } else if (isStarted) {
      wizard.selectStep('basic');
    }
  }, [isStarted]);

  return (
    <ObservableGuard
      predicate$={wizard.current$.pipe(
        // guard against incorrect step to prevent incorrect default values when navigating from another step
        map((current) => current?.key === 'basic')
      )}
      loadingFn={() => (
        <Backdrop open>
          <Loading />
        </Backdrop>
      )}>
      {currentStep?.key === 'basic' && // don't even mount until correct step is set
        params && ( // load params before mounting to prevent flickering
          <FormLoader loading={loading}>
            <VacationGoalEditView
              currentStep={currentStep}
              wizard={wizard}
              params={params}
              editing={editing}
              onBack={onBack}
            />
          </FormLoader>
        )}
    </ObservableGuard>
  );
};

interface VacationGoalEditViewProps extends EditViewProps {
  currentStep: WizardStep<Data.Goals.BasicGoalFormData, Data.Goals.BasicGoalWizardParams>;
  wizard: Data.Goals.BasicGoalWizard;
  params: Data.Goals.BasicGoalWizardParams;
}

const VacationGoalEditView: React.FC<VacationGoalEditViewProps> = ({
  currentStep,
  wizard,
  editing,
  onBack,
}) => {
  const navigate = useNavigate();
  const ready = useObservableGuardCondition();
  const { formSchema, formatInputs } = useBasicGoalValidation();
  const methods = useForm<Data.Goals.BasicGoalFormData>({
    defaultValues: currentStep.model,
    resolver: yupResolver(formSchema),
  });
  const { setError } = methods;
  const { setLoading } = useLoading();

  const onSubmit = async (values: any) => {
    setLoading(true);

    try {
      const cleanedValues = formatInputs(values);
      currentStep.save({ ...values, ...cleanedValues });
      await wizard.commitStep('basic');

      if (editing && onBack) {
        onBack();
        return;
      }

      const wizardParams = await firstValueFrom(wizard.params$);
      // set id in url so if user navigates back, we load the wizard with the set ID
      navigate(`/goals/vacation/${wizardParams?.id}`, { replace: true });
      navigate('/goals');
    } catch (err) {
      const error = err as AxiosError;
      const message = (error.response?.data as AxiosError).message;
      if (error?.response?.status === 500 && message.includes('UNIQUE_ACCOUNT_ID_GOAL_NAME')) {
        setError('name', {
          type: 'server',
          message: 'Please enter a unique goal name.',
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {ready && (
        <FormContent formProviderProps={methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Box>
              <GoalFormTitle editing={editing} type={Domain.GoalTypeEnum.Vacation} />

              <Grid container justifyContent='center' mt={2}>
                <Grid item sm={editing ? 10 : 6}>
                  <VacationGoalForm model={currentStep.model} onSubmit={onSubmit} />
                </Grid>
              </Grid>
            </Box>
            <WizardFooter
              color={editing ? 'primary' : undefined}
              disableBack={false}
              onBack={onBack}
              {...getWizardFooterProps(meta.nextLocaleKey, editing)}
            />
          </form>
        </FormContent>
      )}
    </>
  );
};

const meta = {
  nextLocaleKey: 'Save&Continue',
  title: 'Vacation',
} satisfies LayoutMeta<GoalsLayoutMeta>;

export default withLayoutMeta(VacationGoalEditViewWrapper, meta);
