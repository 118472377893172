import { Grid, Typography } from '@mui/material';
import { Svgs } from '../../../assets/svg';
import GroupPanels from '../../../components/GroupPanels';
import { universityAdvisor } from '../../../assets/png';
import { t } from 'i18next';

interface CoachingSessionPanelProps {
  coachingSessionUrl: string | undefined;
}

const CoachingSessionPanel: React.FC<CoachingSessionPanelProps> = ({ coachingSessionUrl }) => {
  return (
    <GroupPanels
      variant='Popover'
      groups={[
        {
          textKey: '3NickelsUCoachingSession',

          content: (
            <Grid container display='flex' alignItems='center' justifyContent='space-between'>
              <img src={universityAdvisor} width='75px' />
              <Typography variant='p16Bold' color='secondary' maxWidth='50%'>
                {t('BookAOneOnOneCoachingSession')}
              </Typography>
              <Svgs.IconArrowRight />
            </Grid>
          ),
          onClick: () => {
            window.open(`${coachingSessionUrl}`);
          },
        },
      ]}
    />
  );
};

export default CoachingSessionPanel;
