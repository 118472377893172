/* eslint-disable @typescript-eslint/no-explicit-any */
import { Grid, Typography } from '@mui/material';
import { Data, Domain, Hooks } from '@3nickels/data-modules';
import { LayoutMeta, withLayoutMeta } from '../../../../types/LayoutMeta';
import { ToolsLayoutMeta } from '../../ToolsLayout';
import ToolTemplate from '../../../../components/ToolTemplate';
import ToolHeader from '../../../../components/ToolHeader';
import { useForm } from 'react-hook-form';
import { cleanWholeNumberStr } from '../../../../helpers/utilityFunctions';
import { useLoading } from '../../../../hooks/useLoading';
import { useMemo, useState } from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Svgs } from '../../../../assets/svg';
import { t } from 'i18next';
import { useLoaderData } from 'react-router-dom';
import IraFeeCheckerForm from './IraFeeCheckerForm';
import HelpPopover, { HelpPopoverText } from '../../../../components/HelpPopover';
import IraFeeCheckerResults from './IraFeeCheckerResults';
import { SavedFormsKeyEnum } from '../../../../types/SavedForms';
import { Trans } from 'react-i18next';

const iraFeeCheckerSchema = () =>
  Yup.object({
    iraId: Yup.string().required(t('Required') as string),
    maintenanceFee: Yup.string().notRequired(),
    minimumBalanceFee: Yup.string().notRequired(),
    portfolioManagementFee: Yup.string().notRequired(),
    tradingFee: Yup.string().notRequired(),
  });

const IraFeeCheckerView: React.FC = () => {
  const { setLoading } = useLoading();
  const commands = Hooks.useCommands();
  const [results, setResults] = useState<Domain.AdviceFeeCheckerPositionResultData | undefined>();
  const [selectedIra, setSelectedIra] = useState<Domain.PlanData | undefined>();
  const [iraFeeCheckerFormData, setIraFeeCheckerFormData] = useState<
    Domain.AdviceFeeCheckerPlanData | undefined
  >(undefined);

  const formService = Hooks.useFormService();
  const formData = Hooks.useSavedFormData<Domain.AdviceFeeCheckerPlanData>(
    SavedFormsKeyEnum.IraFeeChecker
  );

  const methods = useForm({
    resolver: yupResolver(iraFeeCheckerSchema()),
    defaultValues: formData,
  });

  const initialInvestmentAccounts = useLoaderData() as Domain.PlanData[] | undefined;
  const combinedInvestmentAccounts = Hooks.useCombinedInvestmentAccountData();
  const investmentAccounts = useMemo(
    // use preloaded initalInvestmentAccounts value from loader until combinedInvestmentAccounts resloves to prevent screen flickering
    // when combined accounts length is 0 initially
    () =>
      combinedInvestmentAccounts.length === 0
        ? initialInvestmentAccounts
        : combinedInvestmentAccounts,
    [initialInvestmentAccounts, combinedInvestmentAccounts]
  );

  const iras = useMemo(() => {
    return investmentAccounts?.filter((x) => Domain.isIRA(x.planType));
  }, [investmentAccounts]);

  const yourFixedIRA = useMemo(() => {
    return Number((results?.yourIra ?? 0).toFixed(2));
  }, [results]);

  const formatInputs = (values: any): Domain.AdviceFeeCheckerPlanData => {
    const formData: Domain.AdviceFeeCheckerPlanData = {
      maintenanceFee: cleanWholeNumberStr(values.maintenanceFee ?? 0),
      minimumBalanceFee: cleanWholeNumberStr(values.minimumBalanceFee ?? 0),
      portfolioManagementFee: cleanWholeNumberStr(values.portfolioManagementFee ?? 0, {
        float: true,
        precision: 3,
      }),
      tradingFee: cleanWholeNumberStr(values.tradingFee ?? 0),
    };

    return formData;
  };

  const onSubmit = async (values: Domain.AdviceFeeCheckerPlanData) => {
    setLoading(true);

    try {
      const cleanedValues = formatInputs(values);
      const data = await commands.execute(
        Data.FeeChecker.Commands.CalculateFeeCheckerOutputByNonGc,
        { planId: selectedIra?.id ?? 0, data: cleanedValues }
      );
      setIraFeeCheckerFormData(cleanedValues);
      await formService.addOrUpdateSavedForm({
        toolName: SavedFormsKeyEnum.IraFeeChecker,
        formData: cleanedValues,
      });
      setResults(data);
    } finally {
      setLoading(false);
    }
  };

  const onDownload = async () => {
    setLoading(true);

    try {
      const pdfResults = await commands.execute(
        Data.FeeChecker.Commands.CalculateFeeCheckerOutputByNonGcPdf,
        { planId: selectedIra?.id ?? 0, data: iraFeeCheckerFormData }
      );
      const url = window.URL.createObjectURL(
        new Blob([pdfResults.data as any], { type: 'application/pdf' })
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'IraFeeCheckerResults.pdf');
      document.body.appendChild(link);
      link.click();
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  if (typeof iras === 'undefined' || typeof formData === 'undefined') return null;

  return (
    <Grid display='flex' justifyContent='space-between' alignItems='center'>
      <ToolTemplate
        header={
          iras.length === 0 ? (
            <ToolHeader title={'NoPlansAddedYet'} />
          ) : results ? (
            <ToolHeader
              icon={<Svgs.ArtLightbulbLarge />}
              title={
                <Typography sx={{ display: 'inline' }} variant='p30' color='primary'>
                  <Trans
                    i18nKey='YourIraFeesAreWithResults'
                    values={{ results: getResultsText(yourFixedIRA, results?.averageIra ?? 0) }}
                    components={{
                      Results: (
                        <Typography
                          sx={{ display: 'inline' }}
                          variant='p30Bold'
                          color={getResultsColor(yourFixedIRA, results?.averageIra ?? 0)}
                        />
                      ),
                    }}
                  />
                </Typography>
              }
              onDownload={onDownload}
            />
          ) : (
            <ToolHeader
              title={'AddAnyFeesChargedByYourIraProvider'}
              helpContent={<IraFeesModal />}
            />
          )
        }
        inputColumn={
          <IraFeeCheckerForm
            iras={iras}
            formMethods={methods}
            defaultValues={formData}
            selectedIra={selectedIra}
            results={results}
            setSelectedIra={setSelectedIra}
            resetResults={() => setResults(undefined)}
            onSubmit={onSubmit}
          />
        }
        outputColumn={<IraFeeCheckerResults results={results} />}
      />
    </Grid>
  );
};

const getResultsColor = (yourIRA: number, averageIRA: number): string => {
  if (yourIRA > averageIRA) return 'error';
  else if (yourIRA < averageIRA) return 'secondary';
  return 'primary';
};
const getResultsText = (yourIRA: number, averageIRA: number): string => {
  if (yourIRA > averageIRA) return t('aboveTheAverageCost');
  else if (yourIRA < averageIRA) return t('belowTheAverageCost');
  return t('theSameAsTheAverageCost');
};

const IraFeesModal: React.FC = () => {
  return (
    <HelpPopover title={t('IraFees')}>
      <HelpPopoverText>{t('InAdditionToInvestmentFees')}</HelpPopoverText>
    </HelpPopover>
  );
};

const meta = {
  background: <img src={undefined} />,
} satisfies LayoutMeta<ToolsLayoutMeta>;

export default withLayoutMeta(IraFeeCheckerView, meta);
