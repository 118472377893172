import { Data, Domain } from '@3nickels/data-modules';
import { IServiceContainer } from '@aesop-fables/containr';
import { DataStore, ScriniumServices } from '@aesop-fables/scrinium';
import { json, redirect } from 'react-router';
import { firstValueFrom } from 'rxjs';
import { waitFor } from '../utils';

export async function universityLoader(container: IServiceContainer) {
  const dataStore = container.get<DataStore>(ScriniumServices.DataStore);
  const userDataCache = Data.Users.retrieveUserData(dataStore);
  const universityCache = dataStore.cache<Data.University.UniversityCompartments>(
    Data.University.universityStorageKey
  );
  const features = await firstValueFrom(userDataCache.observe$<Domain.FeatureMap>('features'));
  if (!features?.threeNickelsU) {
    return redirect('/');
  }

  await waitFor(universityCache.initialized$);

  return json('3nU enabled');
}
