import { Domain, Hooks } from '@3nickels/data-modules';
import { Grid, Typography, Box } from '@mui/material';
import { Svgs } from '../../../assets/svg';
import { GroupEdit } from '../../../components/GroupEdit';
import { formatWholeDollars } from '../../../helpers/utilityFunctions';
import { mapDebtEnumToTypePath, mapDebtTypeToType } from './DebtUtils';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MutatorState } from '@aesop-fables/scrinium';
import { useLoading } from '../../../hooks/useLoading';
import { useTranslation } from 'react-i18next';
import { DebtSvg } from '../../../components/images/DebtSvg';
import { usePlaidCredentialRefresh } from '../../../hooks/usePlaidCredentialRefresh';
import RemoveAccountModal from '../RemoveAccountModal';

interface DebtViewProps {
  debts: Domain.DebtData[];
  deleteDebt: MutatorState<Domain.IDebt>;
}

export const DebtView: React.FC<DebtViewProps> = ({ debts, deleteDebt }) => {
  const { t } = useTranslation();
  const { setLoading } = useLoading();
  const navigate = useNavigate();
  const { wizard } = Hooks.useDebtWizard();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState<Domain.IDebt>();
  const { setIdAndUpdateLogin } = usePlaidCredentialRefresh();

  const handleEdit = (x: Domain.DebtData) => {
    wizard.start({ id: x.id });
    navigate(`/account-details/debt/summary/${mapDebtEnumToTypePath(x.debtType)}/${x.id}`);
  };

  const handleDeleteDebt = (debt: Domain.IDebt) => {
    setLoading(true);
    deleteDebt.action(debt);
  };

  if (debts.length === 0) return null;

  return (
    <>
      <RemoveAccountModal
        name={selectedAccount?.name}
        type={mapDebtTypeToType(selectedAccount?.debtType)}
        variant='Debt'
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        handleSave={() => {
          if (selectedAccount) {
            handleDeleteDebt(selectedAccount);
          }
        }}
      />
      <Grid item xs={12} className='account-view'>
        <Typography
          className='account-type-header'
          display='flex'
          alignItems='center'
          color='primary'
          variant='p18Bold'>
          {DebtSvg({ fontSize: 'large' })}
          {t('DebtAccount', { number: debts.length })}
        </Typography>
        <Box>
          <GroupEdit<Domain.DebtData>
            className='account-list'
            items={debts}
            keyFn={(x) => `${x.id ?? 0}`}
            mainContentFn={(x) => (
              <Grid container>
                <Grid marginRight='10px'>
                  <Typography
                    className='hover-clickable'
                    color='secondary'
                    fontWeight='bold'
                    onClick={() => handleEdit(x)}>
                    {x.name}
                  </Typography>
                  <Grid direction='row' display='flex' alignItems='center'>
                    <Typography marginTop='5px' color='secondary' variant='p12'>
                      {x.linkedInstitution?.institutionName ?? t('ManuallyAdded')}
                    </Typography>
                    {x.linkedInstitution?.requiresLogin && (
                      <>
                        <Typography ml={'10px'} marginTop='5px' color='secondary' variant='p12'>
                          |
                        </Typography>
                        <Typography
                          onClick={() => {
                            setIdAndUpdateLogin(x.linkedInstitution?.accessId ?? 0);
                          }}
                          sx={{
                            '&:hover': {
                              textDecoration: 'underline',
                              cursor: 'pointer',
                            },
                          }}
                          ml={'10px'}
                          marginTop='5px'
                          color='error'
                          component='span'
                          variant='p12'>
                          {t('LoginExpired')}
                        </Typography>
                      </>
                    )}
                  </Grid>
                </Grid>
                <Grid className='account-view-status-icon' height='20px'>
                  {x.isLinked && (
                    <Grid display='flex' alignItems='center'>
                      <Svgs.LinkPaperClip />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}
            summaryFn={(x) => (
              <Grid>
                <Typography color='error'>-{formatWholeDollars(x.amountOwed ?? 0)}</Typography>
              </Grid>
            )}
            needsAttention={(x) => x.linkedInstitution?.requiresLogin}
            stackEndContent
            onDelete={(x) => {
              setSelectedAccount(x);
              setModalOpen(true);
            }}
            onEdit={(x) => {
              handleEdit(x);
            }}
          />
        </Box>
      </Grid>
    </>
  );
};
