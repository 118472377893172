import { useObservableQuery } from '@aesop-fables/scrinium';
import { Grid, Typography } from '@mui/material';
import { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams, useOutletContext } from 'react-router-dom';
import { Spacer } from '../../../components';
import { useMessage } from '../../../hooks/useMessage';
import { Colors } from '../../../themes';
import { LayoutContext, LayoutMeta, withLayoutMeta } from '../../../types/LayoutMeta';
import { LmsLayoutMeta } from '../LmsLayout';
import QuizAnswerCard from './QuizAnswerCard';
import { QuizQuestionHeader } from './QuizQuestionHeader';
import { Data, Domain, Hooks } from '@3nickels/data-modules';
import { useLoading } from '../../../hooks/useLoading';
import { t } from 'i18next';

const LmsQuizView = () => {
  const navigate = useNavigate();
  const { setLoading } = useLoading();
  const { questionId, lessonId, courseId, unitId, quizId } = useParams();
  const { setMeta } = useOutletContext<LayoutContext>();

  const commands = Hooks.useCommands();
  const { showMessage } = useMessage();
  const { updateSavedAnswers, getSelectedAnswerIds, isAnswerSubmitted } = Hooks.useQuizContext();
  const { index, nextId, isLastQuestion } = Hooks.useQuizNavigation({
    questionId: Number(questionId) ?? 0,
    lessonId: Number(lessonId) ?? 0,
  });

  const question = useObservableQuery(
    Data.Lms.Queries.FindQuestionByQuestionId,
    parseInt(questionId ?? '0')
  );
  const quiz = useObservableQuery(Data.Lms.Queries.FindQuizByLessonId, parseInt(lessonId ?? '0'));

  const pathnameSegments = location.pathname.split('/');
  const toolsIndex = pathnameSegments.indexOf('tools');
  const module = pathnameSegments[toolsIndex + 1];
  const yourLessonsView = pathnameSegments.includes('your-lessons');
  const path = yourLessonsView ? `/tools/${module}/your-lessons` : '/learning';

  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [answerSubmitted, setAnswerSubmitted] = useState(false);

  useEffect(() => {
    const submitted = isAnswerSubmitted(quiz?.quizId ?? 0, question?.questionId ?? 0);
    setAnswerSubmitted(submitted);
  }, [isAnswerSubmitted, quiz?.quizId, question?.questionId]);

  useEffect(() => {
    if (answerSubmitted) {
      const answerIds = getSelectedAnswerIds(quiz?.quizId ?? 0, question?.questionId ?? 0);
      setSelectedIds(answerIds);
    }
  }, [answerSubmitted, getSelectedAnswerIds, quiz?.quizId, question?.questionId]);

  useEffect(() => {
    setMeta((prev) => ({
      ...prev,
      centerComponent: (
        <QuestionProgress
          currentQuestion={(index ?? 0) + 1}
          totalQuestions={quiz?.questions?.length ?? 0}
        />
      ),
      disableNextButton: selectedIds.length === 0,
      backLocaleKey:
        index === 0 ? (yourLessonsView ? 'CaretYourLessons' : 'CourseSummary') : undefined,
      onNext: handleSubmit,
      onBack: navigateToLessons,
    }));
  }, [selectedIds, index, yourLessonsView]);

  const handleSubmit = async () => {
    try {
      if (!answerSubmitted) {
        setLoading(true);
        if (!quiz?.mostRecentAttempt?.quizAttemptId) {
          await commands.execute(Data.Lms.Commands.PostQuizAttempt, quiz);
        }

        const { isCorrect } = await commands.execute(Data.Lms.Commands.PostQuizAttemptAnswers, {
          attemptId: quiz?.mostRecentAttempt?.quizAttemptId ?? 0,
          answerIds: selectedIds,
          isLastQuestion: isLastQuestion,
        });

        updateSavedAnswers(quiz?.quizId ?? 0, question?.questionId ?? 0, selectedIds);
        setLoading(false);
        showMessage(Data.Lms.getLmsToastMessage(isCorrect), isCorrect ? 'success' : 'error', 3000);
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }

      isLastQuestion
        ? navigate(`${path}/${courseId}/${unitId}/${lessonId}/${quizId}/results`)
        : navigate(`${path}/${courseId}/${unitId}/${lessonId}/${quizId}/${nextId}`);
    } catch (err) {
      setLoading(false);
      showMessage(t('ErrorSubmittingAnswer') as string, 'error', 3000);
    }
  };

  const navigateToLessons = () => {
    index === 0
      ? yourLessonsView
        ? navigate(path)
        : navigate(`/learning/${courseId}`)
      : navigate(-1);
  };

  const onAnswerPress = useCallback(
    (id: number) => {
      if (answerSubmitted) return;

      if (question?.answerType === Domain.AnswerTypeEnum.SINGLE) {
        setSelectedIds((prev) => (prev.includes(id) ? [] : [id]));
      } else {
        setSelectedIds((prev) =>
          prev.includes(id) ? prev.filter((x) => x !== id) : [...prev, id]
        );
      }
    },
    [answerSubmitted, question?.answerType]
  );

  if (!question) return null;

  return (
    <Grid container flexDirection='column'>
      <QuizQuestionHeader
        questionNumber={index}
        text={question.text}
        answerType={question.answerType}
      />

      <Spacer height='md' />

      <Grid minWidth='60%' alignSelf='center'>
        {question?.answers?.map((answer, i) => (
          <QuizAnswerCard
            key={answer.answerId}
            index={i}
            answer={answer}
            selectedIds={selectedIds}
            answerSubmitted={answerSubmitted}
            onAnswerPress={onAnswerPress}
          />
        ))}
      </Grid>
    </Grid>
  );
};

const meta = {
  nextLocaleKey: 'CheckAnswer',
  backLocaleKey: 'Back',
  disableNextButton: true,
} satisfies LayoutMeta<LmsLayoutMeta>;

interface QuestionProgressProps {
  currentQuestion: number;
  totalQuestions: number;
}

const QuestionProgress: React.FC<QuestionProgressProps> = ({ currentQuestion, totalQuestions }) => (
  <Grid container alignItems='center' justifyContent='center'>
    <Typography variant='p16Bold' color={Colors.secondaryBase}>
      {t('CurrentOfTotal', { current: currentQuestion, total: totalQuestions })}
    </Typography>
  </Grid>
);

export default withLayoutMeta(LmsQuizView, meta);
