import React from 'react';
import HelpPopover, { HelpPopoverText } from '../../../../components/HelpPopover';
import { Trans, useTranslation } from 'react-i18next';
import { Margins } from '../../../../themes';
import { Stack } from '@mui/material';
import BulletedText from '../../../../components/BulletedText';

const HomeImprovementHelp: React.FC = () => {
  const { t } = useTranslation();

  return (
    <HelpPopover title={t('EligibleHomeImprovements')}>
      <HelpPopoverText style={Margins.mb_md}>
        {t('MoneySpentOnYourHomeFallsIntoTwoCategories')}
      </HelpPopoverText>
      <HelpPopoverText style={Margins.mb_sm}>{t('TheIrsDoesntProvideAList')}</HelpPopoverText>
      <Stack mr={2} ml={2}>
        <Trans
          i18nKey='AddsValueProlongsLifeIncreasesUseHomeBullets'
          components={{
            BulletedText: <BulletedText />,
          }}
        />
      </Stack>
      <HelpPopoverText style={Margins.mt_sm}>
        {t('ImproventDoesntHaveToBeExpensive')}
      </HelpPopoverText>
      <HelpPopoverText style={Margins.mt_md}>{t('HomeRepairsWillNotQualify')}</HelpPopoverText>
    </HelpPopover>
  );
};

export default HomeImprovementHelp;
