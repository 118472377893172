import { Domain } from '@3nickels/data-modules';
import { Grid, Typography, Card, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Svgs } from '../../../assets/svg';
import { Colors } from '../../../themes';

interface FailureContentProps {
  onRetake: () => void;
  onReview: () => void;
  quizAttempt: Domain.QuizAttemptDto;
  questions: Domain.QuizQuestionDto[];
}

const FailureContent: React.FC<FailureContentProps> = ({
  onRetake,
  onReview,
  quizAttempt,
  questions,
}) => {
  const { t } = useTranslation();
  const totalQuestions = questions.length ?? 0;

  return (
    <Grid display='flex' flexDirection='column' alignItems='center'>
      <Typography mb={4} textAlign='center' variant='p30Bold' color={Colors.errorBase}>
        {t('RetakeQuizToFinish')}
      </Typography>
      <Svgs.ArtBrainFog />
      <Card
        className='quiz-results-card'
        sx={{
          marginTop: '30px',
          marginBottom: '46px',
          width: '50%',
          minWidth: '200px',
          maxWidth: '400px',
        }}>
        <Typography textAlign='center' mb={1} variant='p16' color='secondary'>
          {t('CurrentOutOfTotalQuestions', {
            current: quizAttempt.answersCorrect ?? 0,
            total: totalQuestions,
          })}
        </Typography>
        <Typography textAlign='center' variant='p30Bold' color={Colors.errorBase}>
          {Math.round(quizAttempt.percentage ?? 0)}%
        </Typography>
      </Card>
      <Button
        onClick={onRetake}
        style={{ marginTop: '10px', width: '40%', maxWidth: '350px', minWidth: '175px' }}>
        {t('RetakeQuiz')}
      </Button>
      <Button
        onClick={onReview}
        style={{ marginTop: '10px', width: '40%', maxWidth: '350px', minWidth: '175px' }}
        variant='outlined'>
        {t('ReviewLesson')}
      </Button>
    </Grid>
  );
};

export default FailureContent;
