/* eslint-disable @typescript-eslint/no-explicit-any */
import { Typography, Button, Card, CardContent, IconButton } from '@mui/material';
import { Column, RightDrawer, Row, Spacer } from '../components';
import { logo3nUni } from '../assets/png';
import { Svgs } from '../assets/svg';
import { useDeviceType } from '../hooks/useDeviceType';
import { LearnMoreDrawerProps } from './LearnMoreAdviceDrawer';
import BulletedList from '../components/BulletedList';
import { Domain } from '@3nickels/data-modules';
import i18next, { t } from 'i18next';
import { Trans } from 'react-i18next';
import { formatDecimalDollars, formatWholeDollars } from '../helpers/utilityFunctions';

const LearnMoreAdviceAndThreeNickelsUDrawer: React.FC<LearnMoreDrawerProps> = ({
  drawerOpen,
  setDrawerOpen,
  pricebookBundle,
  pricebookMetadata,
}) => {
  const { isMobile } = useDeviceType();
  const promotion = pricebookBundle.prices.find((price) => price.introductionPeriodInDays > 1);
  const introPeriodInMonths = Math.floor((promotion?.introductionPeriodInDays ?? 0) / 30);
  const eduPricebook = pricebookBundle.pricebook.description.some((item) => item.includes('.edu'));
  const accessToAdviceText = eduPricebook
    ? t('ThisPromotionOffersYouAccessToTheAdviceTier')
    : promotion
    ? t('ForXPeriodYouHaveAccessToTheAdviceTier', {
        x: introPeriodInMonths,
        period: `${introPeriodInMonths === 1 ? t('month') : t('months')}`,
      })
    : t('ThisPackageOffersYouAccessToTheAdviceTier');

  return (
    <RightDrawer open={drawerOpen} setOpen={setDrawerOpen}>
      <Row>
        <Column style={{ width: isMobile ? '100%' : '90%' }}>
          {!isMobile && (
            <Typography color='secondary' variant='p14'>
              {t('3NickelsPackages')}
            </Typography>
          )}
          <Spacer height='xxs' />

          <Typography color='primary' variant={isMobile ? 'p24Bold' : 'p30Bold'}>
            {pricebookBundle.pricebook.pricebookName}
          </Typography>
          <Spacer height='xxs' />
        </Column>
        {isMobile ? (
          <IconButton onClick={() => setDrawerOpen(false)}>
            <Svgs.IconNavAlertDefault />
          </IconButton>
        ) : (
          <img src={logo3nUni} alt='logo' style={{ height: '40px' }} />
        )}
      </Row>
      <PackageCard pricebookBundle={pricebookBundle} pricebookMetadata={pricebookMetadata} />
      {isMobile && (
        <>
          <Spacer height='xs' />
          <Column style={{ alignItems: 'center' }}>
            <Svgs.ArtStudyMaterials />
          </Column>
        </>
      )}
      <Spacer height='xxs' />
      <Typography color='secondary' variant='p18Bold'>
        {t('Description')}
      </Typography>
      <Spacer height='xxs' />
      <Row>
        <Column style={{ width: '80%', margin: '0px 20px 0px 0px' }}>
          <Typography color='secondary' variant='p16'>
            {t('NoMatterWhereYoureAtOnYourJourneyWereSettingYouUpForSuccess')}
          </Typography>
        </Column>
        {!isMobile && (
          <Svgs.ArtStudyMaterials style={{ marginLeft: '20px', marginRight: '20px' }} />
        )}
      </Row>
      <Spacer height='xxs' />
      <Typography color='secondary' variant='p18Bold'>
        {t('AtAGlance...')}
      </Typography>
      <Column style={{ width: '85%' }}>
        <Spacer height='xs' />
        <Row style={{ alignItems: 'center' }}>
          <Svgs.BulletCoach
            style={{ width: isMobile ? '70px' : '50px', height: 'auto', flexShrink: 0 }}
          />
          <Typography
            className='link-wrapper'
            style={{ marginLeft: '20px' }}
            variant='body1'
            color='secondary'>
            <Trans i18nKey='PersonalizedCoaching' />
          </Typography>
        </Row>

        <Spacer height='xs' />
        <Row style={{ alignItems: 'center' }}>
          <Svgs.BulletLessons
            style={{ width: isMobile ? '70px' : '50px', height: 'auto', flexShrink: 0 }}
          />
          <Typography
            className='link-wrapper'
            style={{ marginLeft: '20px' }}
            variant='body1'
            color='secondary'>
            <Trans i18nKey='30QuickLessons' />
          </Typography>
        </Row>

        <Spacer height='xs' />
        <Row style={{ alignItems: 'center' }}>
          <Svgs.BulletQa
            style={{ width: isMobile ? '70px' : '50px', height: 'auto', flexShrink: 0 }}
          />
          <Typography
            className='link-wrapper'
            style={{ marginLeft: '20px' }}
            variant='body1'
            color='secondary'>
            <Trans i18nKey='LiveQ&A' />
          </Typography>
        </Row>
      </Column>

      {isMobile && (
        <Row style={{ justifyContent: 'center' }}>
          <Svgs.ArtPieChart width='110px' />
        </Row>
      )}
      <Spacer height={isMobile ? 'xxs' : 'sm'} />
      <Typography color='secondary' variant='p18Bold'>
        {t('AndYouHaveAccessToAdvice')}
      </Typography>
      <Row>
        {!isMobile && <Svgs.ArtPieChart width='110px' />}
        <Column style={{ width: '80%', margin: isMobile ? '0px' : '0px 0px 0px 20px' }}>
          <Spacer height='xs' />
          <Typography color='secondary' variant='p16'>
            {accessToAdviceText} {t('ExperienceTheFinancialAdvisorInYourPocket')}
          </Typography>
          <Spacer height='xs' />
          <Typography color='secondary' variant='p16'>
            {t('ConnectAllOfYOurFinancesInOnePlace')}
          </Typography>
        </Column>
      </Row>
      {!isMobile && (
        <>
          <Spacer height='xxxs' />
          <Row style={{ justifyContent: 'space-between' }}>
            <Button
              onClick={() => setDrawerOpen(false)}
              className='xs'
              variant='outlined'
              color='primary'>
              {t('Close')}
            </Button>
          </Row>
        </>
      )}
      <Spacer height='md' />
    </RightDrawer>
  );
};

interface PackageCardProps {
  pricebookBundle: Domain.PricebookBundle;
  pricebookMetadata: any;
}

export const PackageCard: React.FC<PackageCardProps> = ({ pricebookBundle, pricebookMetadata }) => {
  const { language } = i18next;
  const { isMobile } = useDeviceType();
  const pricebook = pricebookBundle.prices[0];
  const orgPays = pricebookBundle.pricebook.paidBy === 'ORG';
  const guidedChoice = pricebookBundle.pricebook.productType === 'GUIDED_CHOICE';

  const promotion = pricebookBundle.prices.find((price) => price.introductionPeriodInDays > 1);
  const introPeriodInMonths = Math.floor((promotion?.introductionPeriodInDays ?? 0) / 30);
  const freeTrial30Day = pricebookBundle.pricebook.metadata?.freeTrial30Day === 'true';
  const price = pricebook.price / 100.0;
  const decimalDollarsPrice = price.toString().endsWith('.99');

  return (
    <>
      <Card variant='innerCard' color='primary'>
        <CardContent className='packageCard'>
          {isMobile ? (
            <Column style={{ alignItems: 'center' }}>
              <img src={pricebookBundle.pricebook.logoUrl} alt='Product Logo' height='50px' />
              <Spacer height='sm' />
              <img
                src={pricebookBundle.pricebook.artUrl}
                alt='Product Art'
                style={{ height: '90px' }}
              />
              <Spacer height='xs' />
              <Typography
                variant={guidedChoice ? 'p20OpenSansSemiBold' : 'p20SemiBold'}
                color={guidedChoice ? 'guidedChoice.main' : 'secondary'}>
                {pricebookBundle.pricebook.pricebookName}
              </Typography>
              <Spacer height='xxs' />
              <Column style={{ justifyContent: 'space-between', width: '100%' }}>
                <BulletedList
                  children={pricebookBundle.pricebook.description}
                  variant={guidedChoice ? 'p16OpenSans' : 'body1'}
                  color={guidedChoice ? 'secondary.dark' : 'secondary'}
                />
              </Column>
              <Spacer height='xs' />
              <Card variant='embeddedCard' style={{ width: '100%' }}>
                <CardContent className='centered-inner'>
                  <Column style={{ alignItems: 'center' }}>
                    {pricebookMetadata ? (
                      <Typography variant='p16Bold' color='primary' textAlign='center'>
                        {t(pricebookMetadata.innerCard)}
                      </Typography>
                    ) : orgPays ? (
                      <Typography variant='p16Bold' color='primary'>
                        <Trans i18nKey='PaidByYourBROrganization' />
                      </Typography>
                    ) : (
                      <>
                        <Row
                          key={pricebook.id}
                          style={{ alignItems: 'baseline', justifyContent: 'center' }}>
                          {freeTrial30Day ? (
                            <Typography
                              variant={guidedChoice ? 'p20OpenSansSemiBold' : 'p20SemiBold'}
                              color={guidedChoice ? 'info.main' : 'secondary'}>
                              {t('30DaysFree')}
                            </Typography>
                          ) : (
                            <>
                              <Typography
                                variant={guidedChoice ? 'p20OpenSansSemiBold' : 'p20SemiBold'}
                                color={guidedChoice ? 'secondary.dark' : 'secondary'}>
                                {decimalDollarsPrice
                                  ? formatDecimalDollars(price, language)
                                  : formatWholeDollars(price, language)}
                              </Typography>
                            </>
                          )}
                          {pricebook.intervalType !== 'NONE' && !freeTrial30Day && (
                            <Typography
                              variant={guidedChoice ? 'p16OpenSans' : 'h3'}
                              color={guidedChoice ? 'info.main' : 'secondary'}>
                              /{t(pricebook.intervalType)}
                            </Typography>
                          )}
                        </Row>
                        <Spacer height='xxxs' />
                        <Typography
                          variant={guidedChoice ? 'p12OpenSansSemiBold' : 'p12SemiBold'}
                          color={guidedChoice ? 'secondary.dark' : 'primary'}>
                          {freeTrial30Day
                            ? t('thenPricePeriod', {
                                price: formatDecimalDollars(14.99, language),
                                period: t('mo'),
                              })
                            : price === 0
                            ? t('free')
                            : pricebook.intervalType === 'MONTH'
                            ? t('autoRenewsMonthly')
                            : pricebook.intervalType === 'YEAR'
                            ? t('autoRenewsAnnually')
                            : t('oneTimePayment')}
                        </Typography>
                      </>
                    )}
                  </Column>
                </CardContent>
              </Card>
            </Column>
          ) : (
            <Row style={{ justifyContent: 'space-between' }}>
              <img
                src={pricebookBundle.pricebook.artUrl}
                alt='Product Art'
                style={{ height: 'auto', maxWidth: '30%', objectFit: 'contain' }}
              />
              <Spacer height='s15' />
              <Column style={{ width: '40%', margin: '10px', flex: 1 }}>
                <BulletedList
                  children={pricebookBundle.pricebook.description}
                  variant={guidedChoice ? 'p16OpenSans' : 'body1'}
                  color={guidedChoice ? 'secondary.dark' : 'secondary'}
                />
              </Column>
              <Card
                style={{ alignSelf: 'center', width: '30%', flex: 1, minWidth: '165px' }}
                variant='embeddedCard'>
                <CardContent className='centered-inner'>
                  <Column style={{ alignItems: 'center' }}>
                    {pricebookMetadata ? (
                      <Typography variant='p16Bold' color='primary' textAlign='center'>
                        {t(pricebookMetadata.innerCard)}
                      </Typography>
                    ) : orgPays ? (
                      <Typography variant='p16Bold' color='primary'>
                        <Trans i18nKey='PaidByYourBROrganization' />
                      </Typography>
                    ) : (
                      <>
                        <Row style={{ alignItems: 'center', justifyContent: 'center' }}>
                          {freeTrial30Day ? (
                            <Typography
                              variant={guidedChoice ? 'p20OpenSansSemiBold' : 'p20SemiBold'}
                              color={guidedChoice ? 'info.main' : 'secondary'}>
                              {t('30DaysFree')}
                            </Typography>
                          ) : (
                            <>
                              <Typography
                                variant={guidedChoice ? 'p20OpenSansSemiBold' : 'p20SemiBold'}
                                color={guidedChoice ? 'secondary.dark' : 'secondary'}>
                                {decimalDollarsPrice
                                  ? formatDecimalDollars(price, language)
                                  : formatWholeDollars(price, language)}
                              </Typography>
                            </>
                          )}
                          {pricebook.intervalType !== 'NONE' && !freeTrial30Day && (
                            <Typography
                              variant={guidedChoice ? 'p16OpenSans' : 'h3'}
                              color={guidedChoice ? 'info.main' : 'secondary'}>
                              /{t(pricebook.intervalType)}
                            </Typography>
                          )}
                        </Row>
                        <Typography
                          variant={guidedChoice ? 'p12OpenSansSemiBold' : 'p12SemiBold'}
                          color={guidedChoice ? 'secondary.dark' : 'primary'}
                          textAlign='center'>
                          {freeTrial30Day
                            ? t('thenPricePeriod', {
                                price: formatDecimalDollars(14.99, language),
                                period: t('mo'),
                              })
                            : price === 0
                            ? t('free')
                            : pricebook.intervalType === 'MONTH'
                            ? t('autoRenewsMonthly')
                            : pricebook.intervalType === 'YEAR'
                            ? t('autoRenewsAnnually')
                            : t('oneTimePayment')}
                        </Typography>
                      </>
                    )}
                  </Column>
                </CardContent>
              </Card>
            </Row>
          )}
        </CardContent>
      </Card>
      <Spacer height='xs' />
      {promotion && (
        <Typography variant='p14' color='secondary'>
          *{' '}
          {t('AfterXMonthsAdviceSubscriptionAutoRenewsAt', {
            x: introPeriodInMonths,
            price: `${formatDecimalDollars(14.99, language)}/${t('month')}`,
          })}
        </Typography>
      )}
    </>
  );
};

export default LearnMoreAdviceAndThreeNickelsUDrawer;
