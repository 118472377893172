/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Grid } from '@mui/material';
import { Api } from '@3nickels/data-modules';
import { useFormContext, UseFormReturn } from 'react-hook-form';
import { Button } from '../../../../components/buttons/Button';
import SliderPercent from '../../../../components/form/SliderPercent';

type HowMuchCarCalculatorFormProps = {
  methods: UseFormReturn<Api.AdviceCarAffordabilityRest, any, undefined>;
  onSubmit: (values: Api.AdviceCarAffordabilityRest) => Promise<void>;
  results: Api.AdviceCarAffordabilityResultRest | undefined;
  defaultValues: Api.AdviceCarAffordabilityRest;
};

const HowMuchCarCalculatorForm: React.FC<HowMuchCarCalculatorFormProps> = ({
  methods,
  onSubmit,
  results,
  defaultValues,
}) => {
  const { formState } = useFormContext();

  const onClick = methods.handleSubmit((data) => {
    // TODO add loading spinner for calculate button
    onSubmit(data);
  });

  return (
    <Grid className='tool-form-container'>
      <SliderPercent<Api.AdviceCarAffordabilityRest>
        error={formState.errors.availableCash !== undefined}
        helperText={formState.errors.availableCash?.message?.toString()}
        label='CurrentSavingsBalance'
        name='cashAvailable'
        type='dollar'
        initialMax={100000}
        step={1000}
        defaultValue={defaultValues.cashAvailable}
      />
      <SliderPercent<Api.AdviceCarAffordabilityRest>
        error={formState.errors.salesTaxRate !== undefined}
        helperText={formState.errors.salesTaxRate?.message?.toString()}
        label='SalesTaxRate'
        name='salesTaxRate'
        type='percent'
        precision={3}
        defaultValue={defaultValues.salesTaxRate}
      />
      <SliderPercent<Api.AdviceCarAffordabilityRest>
        error={formState.errors.tradeInAllowance !== undefined}
        helperText={formState.errors.tradeInAllowance?.message?.toString()}
        label='CurrentTradeInValue'
        name='tradeInAllowance'
        type='dollar'
        initialMax={100000}
        step={1000}
        defaultValue={defaultValues.tradeInAllowance}
      />
      <SliderPercent<Api.AdviceCarAffordabilityRest>
        error={formState.errors.owedOnTradeIn !== undefined}
        helperText={formState.errors.owedOnTradeIn?.message?.toString()}
        label='LoanBalanceOwedTradeIn'
        name='owedOnTradeIn'
        type='dollar'
        initialMax={100000}
        step={1000}
        defaultValue={defaultValues.owedOnTradeIn}
      />
      <Grid container justifyContent='flex-end'>
        <Button
          label={results ? 'Recalculate' : 'Calculate'}
          color='primary'
          style={{ minWidth: 'fit-content', width: '35%' }}
          onClick={onClick}
        />
      </Grid>
    </Grid>
  );
};

export default HowMuchCarCalculatorForm;
