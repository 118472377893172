/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import HexGrid, { HexGridItem } from '../../components/HexGrid';
import { Svgs } from '../../assets/svg';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ToolSelectionItem, ToolTypeEnum } from './Types';
import { LayoutMeta, withLayoutMeta } from '../../types/LayoutMeta';
import { ToolsLayoutMeta } from './ToolsLayout';

const toolTypes: HexGridItem<ToolSelectionItem>[] = [
  {
    icon: <Svgs.DisplayMediumPrimaryBudget />,
    label: 'Budget',
    item: { key: 'budget', type: ToolTypeEnum.Budget, label: 'Budget' },
  },
  {
    icon: <Svgs.DisplayMediumPrimaryCar />,
    label: 'Car',
    item: { key: 'car', type: ToolTypeEnum.Car, label: 'Car' },
  },
  {
    icon: <Svgs.DisplayMediumPrimaryCollege />,
    label: 'College',
    item: { key: 'college', type: ToolTypeEnum.College, label: 'College' },
  },
  {
    icon: <Svgs.DisplayMediumPrimaryCreditCard />,
    label: 'CreditCard',
    item: { key: 'credit-card', type: ToolTypeEnum.CreditCard, label: 'CreditCard' },
  },
  {
    icon: <Svgs.DisplayMediumPrimaryDebt />,
    label: 'Debt',
    item: { key: 'debt', type: ToolTypeEnum.Debt, label: 'Debt' },
  },
  {
    icon: <Svgs.DisplayMediumPrimaryGifts />,
    label: 'Gifts',
    item: { key: 'gifts', type: ToolTypeEnum.Gifts, label: 'Gifts' },
  },
  {
    icon: <Svgs.DisplayMediumPrimaryGoals />,
    label: 'Goals',
    item: { key: 'goals', type: ToolTypeEnum.Goals, label: 'Goals' },
  },
  {
    icon: <Svgs.DisplayMediumPrimaryHouse />,
    label: 'House',
    item: { key: 'house', type: ToolTypeEnum.House, label: 'House' },
  },
  {
    icon: <Svgs.DisplayMediumPrimaryInvestment />,
    label: 'Investment',
    item: { key: 'investment', type: ToolTypeEnum.Investment, label: 'Investment' },
  },
  {
    icon: <Svgs.DisplayMediumPrimaryLoans />,
    label: 'Loans',
    item: { key: 'loans', type: ToolTypeEnum.Loans, label: 'Loans' },
  },
  {
    icon: <Svgs.DisplayMediumPrimaryMedical />,
    label: 'Medical',
    item: { key: 'medical', type: ToolTypeEnum.Medical, label: 'Medical' },
  },
  {
    icon: <Svgs.DisplayMediumPrimaryRetirement />,
    label: 'Retirement',
    item: { key: 'retirement', type: ToolTypeEnum.Retirement, label: 'Retirement' },
  },
];

export const ToolsView: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const toolTypeSelected = async (tool: ToolSelectionItem) => {
    navigate(tool.key);
  };

  return (
    <Box>
      <Typography className='title' color='primary' component='h1' variant='h1'>
        {t('Tools')}
      </Typography>
      <Typography className='subtitle' color='secondary' variant='p16'>
        {t('Our12ModulesAreFilledWithTools')}
      </Typography>
      <HexGrid
        items={toolTypes}
        keyFn={(i) => i.key}
        onClick={(x) => toolTypeSelected(x)}
        itemsPerRow={6}
      />
    </Box>
  );
};

const meta = {
  background: undefined,
} satisfies LayoutMeta<ToolsLayoutMeta>;

export default withLayoutMeta(ToolsView, meta);
