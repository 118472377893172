import React, { useEffect } from 'react';
import Tippy from '@tippyjs/react';
import { HelpOutline } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';
import { t } from 'i18next';
import { Spacing } from '../../../../themes';
import { theme } from '../../../../themes/ThemeWrapper';
import { useNavigate } from 'react-router';

interface MedicalBenefitsContentProps {
  questionNumber: number;
  questionTitle: string;
  questionSubtitle?: string;
  questionContent: React.JSX.Element;
  helpContext?: React.ReactNode;
}

export const MedicalBenefitsContent: React.FC<MedicalBenefitsContentProps> = ({
  questionNumber,
  questionTitle,
  questionSubtitle,
  questionContent,
  helpContext,
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleBeforeUnload = () => {
      sessionStorage.setItem('pageRefreshed', 'true');
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    const isRefreshed = sessionStorage.getItem('pageRefreshed');
    if (isRefreshed) {
      sessionStorage.removeItem('pageRefreshed');
      navigate('/tools/medical/medical-benefit-selector-tool');
    }
  }, []);

  return (
    <Grid id='mb-wrapper' display='flex' flexDirection='column' rowSpacing={Spacing.lg}>
      <Grid id='mb-content' display='flex' flexDirection='row' alignItems='start' width='100%'>
        <Typography variant='p30Bold' color='primary'>
          {questionNumber}.&nbsp;
        </Typography>
        <Grid id='mb-question-content' display='flex' flexDirection='column' width='100%'>
          <Grid
            id='mb-question-title'
            display='flex'
            flexDirection='row'
            mb={questionSubtitle ? 0 : 10}>
            <Typography
              display={helpContext ? 'inline' : undefined}
              variant='p30Bold'
              color='primary'>
              {t(questionTitle)}
              {helpContext && (
                <Grid display='inline' ml='5px' mt={1}>
                  <Tippy placement='right' content={helpContext} theme='light-nickels-theme'>
                    <HelpOutline style={{ color: theme.palette.primary.main }} />
                  </Tippy>
                </Grid>
              )}
            </Typography>
          </Grid>

          {questionSubtitle && (
            <Typography mt={1} mb={10} variant='p16' color='primary'>
              {t(questionSubtitle)}
            </Typography>
          )}
          {questionContent}
        </Grid>
      </Grid>
    </Grid>
  );
};
