import { Data, Domain, formatWholeDollars } from '@3nickels/data-modules';
import { Grid, Typography } from '@mui/material';
import CardDisplay from '../../../../components/panels/CardDisplay';
import { SummaryCardProps } from '../../Types';
import { useTranslation } from 'react-i18next';

export interface PensionPlanDetailsCardProps extends SummaryCardProps {
  details: Data.Pensions.PensionFormData;
}

const PensionPlanDetailsCard: React.FC<PensionPlanDetailsCardProps> = ({ details, onEdit }) => {
  const { t } = useTranslation();
  const ageLabel =
    details?.payoutMethod === Domain.PensionPayoutMethodEnum.Monthly
      ? 'AgePaymentsBegin'
      : 'AgeLumpSumPaymentExpected';
  const paymentLabel =
    details?.payoutMethod === Domain.PensionPayoutMethodEnum.Monthly
      ? 'ExpectedMonthlyPayments'
      : 'ExpectedLumpSumPensionIncome';

  return (
    <CardDisplay headerKey='PensionDetails' actionContent='Edit' actionClick={onEdit}>
      <Grid container spacing={2}>
        <Grid item xs>
          <Typography variant='h3' component='h3' color='primary'>
            {t('HowWillYouReceiveYourPayout')}
          </Typography>
          <Typography color='secondary'>
            {details?.payoutMethod === Domain.PensionPayoutMethodEnum.Monthly
              ? 'Monthly'
              : 'Lump Sum'}
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography variant='h3' component='h3' color='primary'>
            {t(ageLabel)}
          </Typography>
          <Typography color='secondary'>{details?.agePensionBegins ?? 0}</Typography>
        </Grid>
        <Grid item xs>
          <Typography variant='h3' component='h3' color='primary'>
            {t(paymentLabel)}
          </Typography>
          <Typography color='secondary'>
            {formatWholeDollars(details?.expectedMonthlyPayments ?? 0)}
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography variant='h3' component='h3' color='primary'>
            {t('PensionAdjustsForInflation')}
          </Typography>
          <Typography color='secondary'>{details?.adjustForInflation ? 'Yes' : 'No'}</Typography>
        </Grid>
      </Grid>
    </CardDisplay>
  );
};

export default PensionPlanDetailsCard;
