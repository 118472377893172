import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form } from 'react-final-form';
import { Button, Typography } from '@mui/material';
import { useService } from '@aesop-fables/containr-react';
import { Column, Row, Spacer, ConfirmationCodeField } from '../components';
import { logo3NSmall } from '../assets/png';
import { PageContentWithBackground } from '../pages/layout/PageContentWithBackground';
import { AccountSettingsWizard, accountSettingsWizardKey } from '../services/accountSettings';
import { useObservable } from '@aesop-fables/scrinium';
import { useDeviceType } from '../hooks/useDeviceType';
import { mobileMargin } from '../App';
import { useMessage } from '../hooks/useMessage';
import { useTranslation } from 'react-i18next';

const ForgotPasswordTwoFactorAuth = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isMobile, isTablet } = useDeviceType();
  const { showMessage } = useMessage();
  const accountSettingsWizard = useService<AccountSettingsWizard>(accountSettingsWizardKey);
  const forgotPasswordData = useObservable(accountSettingsWizard.forgotPasswordData$) ?? {};
  const [codeValue, setCodeValue] = useState<string>('');
  const [disabledSubmitButton, setDisabledSubmitButton] = useState<boolean>(true);

  const CELL_COUNT = 6;
  useEffect(() => {
    if (String(codeValue).length === CELL_COUNT) setDisabledSubmitButton(false);
    else setDisabledSubmitButton(true);
  }, [codeValue]);

  const onSubmit = async () => {
    try {
      await accountSettingsWizard.verifyResetPassword(codeValue);
      accountSettingsWizard.resetForgotPasswordData();
      navigate('/change-password');
    } catch (err) {
      showMessage(t('WeWerentBankingOnThat') as string, 'error');
    }
  };

  const onResendCode = async () => {
    try {
      setCodeValue('');
      await accountSettingsWizard.initiateResetPassword(forgotPasswordData);
      showMessage(t('AuthenticationCodeSent') as string, 'success', 4000);
    } catch (err) {
      showMessage(t('WeWerentBankingOnThat') as string, 'error');
    }
  };

  const navBack = () => {
    navigate(-1);
  };

  const handleChange = (x: string) => {
    setCodeValue(x);
  };

  return (
    <PageContentWithBackground useContainer={false}>
      <Form onSubmit={onSubmit}>
        {({ handleSubmit }) => (
          <>
            <Column style={{ margin: isMobile || isTablet ? mobileMargin : '15px 100px' }}>
              <img src={logo3NSmall} alt='logo' style={{ height: '55px', alignSelf: 'flex-end' }} />
              <Spacer height='xs' />
              <Typography variant={isMobile || isTablet ? 'p22Bold' : 'p50Bold'} color='primary'>
                {t('EnterCode')}
              </Typography>
              <Spacer height={isMobile || isTablet ? 'xxs' : 'xs'} />
              <Typography
                variant={isMobile || isTablet ? 'p16Bold' : 'p20SemiBold'}
                color='secondary'>
                {t('CheckYourSaved2FAMethod')}
              </Typography>
              <Spacer height={isMobile || isTablet ? 'xxs' : 'xs'} />
              <Typography variant={isMobile || isTablet ? 'p16' : 'p20'} color='secondary'>
                {t('IfThereIsAnAccountAssociatedWithTheInfo')}
              </Typography>
              <Spacer height='lg' />
              <Column style={{ alignItems: 'center' }}>
                <ConfirmationCodeField fieldName='2fa' value={codeValue} onChange={handleChange} />
                <Spacer height='lg' />
                <Button onClick={onResendCode} className='ghostAlt'>
                  {t('ResendCode')}
                </Button>
              </Column>
              <Spacer height='lg' />

              {isMobile || isTablet ? (
                <Column style={{ justifyContent: 'space-between' }}>
                  <Button
                    onClick={handleSubmit}
                    disabled={disabledSubmitButton}
                    color='secondary'
                    type='submit'>
                    {t('Submit')}
                  </Button>
                  <Spacer height='xs' />
                  <Button onClick={navBack} variant='outlined' color='secondary'>
                    {t('Back')}
                  </Button>
                </Column>
              ) : (
                <Row style={{ justifyContent: 'space-between' }}>
                  <Button onClick={navBack} className='xs' variant='outlined' color='secondary'>
                    {t('Back')}
                  </Button>
                  <Button
                    onClick={handleSubmit}
                    disabled={disabledSubmitButton}
                    className='md'
                    color='secondary'
                    type='submit'
                    style={{ alignSelf: 'flex-end' }}>
                    {t('Submit')}
                  </Button>
                </Row>
              )}
            </Column>
          </>
        )}
      </Form>
    </PageContentWithBackground>
  );
};

export default ForgotPasswordTwoFactorAuth;
