import { Api, Data, Domain, Hooks } from '@3nickels/data-modules';
import { Grid, Typography } from '@mui/material';
import { Svgs } from '../../assets/svg';
import GroupPanels from '../../components/GroupPanels';
import { useLoading } from '../../hooks/useLoading';
import { Colors, Spacing } from '../../themes';
import { Button } from '../../components/buttons/Button';
import { useMemo } from 'react';
import { t } from 'i18next';
import ProgressBar from '../../components/ProgressBar';
import { AxiosResponse } from 'axios';
import { useService } from '@aesop-fables/containr-react';

interface LmsCourseCertificatePanelProps {
  course?: Domain.CourseDto;
  university?: boolean;
}

const LmsCourseCertificatePanel: React.FC<LmsCourseCertificatePanelProps> = ({
  course,
  university,
}) => {
  const { setLoading } = useLoading();
  const commands = Hooks.useCommands();
  const complete = useMemo(
    () => course?.completionPercentage === 100,
    [course?.completionPercentage]
  );
  const inProgress = useMemo(
    () => !complete && course?.completionPercentage !== 0,
    [course?.completionPercentage]
  );
  const api = useService<Api.IUniversityApi>(Api.ApiKeys.University);

  const downloadCertificate = async () => {
    if (!complete) return;

    try {
      setLoading(true);
      const response: AxiosResponse = university
        ? await api.getGraduationCertificate()
        : await commands.execute(Data.Lms.Commands.GetWebLmsCertificate, course?.courseId);
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: 'application/pdf' })
      );
      const link = document.createElement('a');
      link.href = url;
      const documentTitle = university ? '3Nickels University' : course?.name ?? 'Course';
      link.setAttribute('download', `${documentTitle + ' Certificate'}.pdf`);
      document.body.appendChild(link);
      link.click();
      window.open(url);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <GroupPanels
      variant='Collapsible'
      groups={[
        {
          textKey: `${course?.courseId}-certificate`,
          style: {
            boxShadow: !complete ? 'none' : undefined,
            cursor: complete ? 'pointer' : undefined,
          },
          content: (
            <Grid
              onClick={downloadCertificate}
              container
              padding={Spacing.xxxs + 1}
              paddingX='20px'
              display='flex'
              flexDirection='column'
              rowGap='10px'
              alignItems='center'
              justifyContent='space-between'
              textAlign='center'
              minWidth='300px'
              sx={{
                boxShadow: !complete ? 'none !important' : undefined,
              }}>
              {!complete && !inProgress && (
                <Grid display='flex' flexDirection='column' alignItems='center' rowGap='10px'>
                  <Svgs.ArtLightbulbSmall />
                  <Typography variant='p16Bold' color='secondary'>
                    {t('GetStarted')}
                  </Typography>
                  <Typography variant='p12' color='secondary'>
                    {t('WhatWillYouLearnToday?')}
                  </Typography>
                </Grid>
              )}
              {inProgress && (
                <Grid display='flex' flexDirection='column' alignItems='center' rowGap='10px'>
                  <Svgs.ArtLightbulbSmall />
                  <Typography variant='p16Bold' color='secondary'>
                    {t('KeepGoing')}
                  </Typography>
                  <Typography variant='p12' color='secondary'>
                    {t('LearningDoesntStop')}
                  </Typography>
                </Grid>
              )}
              {complete && (
                <Grid display='flex' flexDirection='column' alignItems='center' rowGap='10px'>
                  <Svgs.ArtTrophySmall2 height='40px' />
                  <Typography variant='p16Bold' color='secondary'>
                    {t('CelebrateYourAchievement')}
                  </Typography>
                  <Typography variant='p12' color='secondary'>
                    {t('CheckOutYourCertificate')}
                  </Typography>
                </Grid>
              )}
              <ProgressBar
                progress={course?.completionPercentage ?? 0}
                color={complete ? Colors.secondaryLight : Colors.primaryLight}
                backgroundColor={Colors.primaryLightest}
              />
              <Grid container justifyContent='center' gap='10px'>
                <Typography
                  variant='p12'
                  color={course?.completionPercentage === 100 ? 'secondary' : 'primary'}
                  fontWeight='bold'>
                  {t('PercentComplete', { percentage: course?.completionPercentage })}
                </Typography>
                <Typography
                  variant='p12'
                  color={course?.completionPercentage === 100 ? 'secondary' : 'primary'}
                  fontWeight='normal'>
                  |
                </Typography>
                <Typography
                  variant='p12'
                  color={course?.completionPercentage === 100 ? 'secondary' : 'primary'}
                  fontWeight='normal'>
                  {t('completed/totalX', {
                    completed: course?.lessonsCompleted ?? 0,
                    total: course?.totalLessons,
                    x: 'Lessons',
                  })}
                </Typography>
              </Grid>
              {complete && (
                <Button variant='ghost' style={{ marginTop: '10px' }}>
                  {t('ViewMyCertificate')}
                </Button>
              )}
            </Grid>
          ),
          url: '/',
        },
      ]}
    />
  );
};

export default LmsCourseCertificatePanel;
