import { Box, Card, Grid, MenuItem, Stack, Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';
import TextInput from '../../../components/form/TextInput';
import { Colors, Spacing } from '../../../themes';
import { Api, Data, Domain, formatWholeDollars } from '@3nickels/data-modules';
import { useFormContext } from 'react-hook-form';
import SelectInput from '../../../components/form/SelectInput';
import HelpPopover, { HelpPopoverText } from '../../../components/HelpPopover';
import Spacer from '../../../components/Spacer';
import BulletedList from '../../../components/BulletedList';
import { Margins } from '../../../themes';
import { cleanWholeNumberStr, convertToTranslationKey } from '../../../helpers/utilityFunctions';
import { t } from 'i18next';

export type CollegeGoalDetailsFormProps = {
  model?: Data.Goals.CollegeGoalFormData;
  averageCosts: Api.CollegeFundSelfAverageCostsResultRest;
  onSubmit: (values: Data.Goals.CollegeGoalFormData) => Promise<void>;
};

const options = [
  { label: 'Custom', value: Domain.CollegeTuitionTypeEnum.Custom },
  { label: 'Average Private University', value: Domain.CollegeTuitionTypeEnum.Private },
  {
    label: 'Average Public University (In-State)',
    value: Domain.CollegeTuitionTypeEnum.PublicInState,
  },
  {
    label: 'Average Public University (Out-of-State)',
    value: Domain.CollegeTuitionTypeEnum.PublicOutState,
  },
];

const CollegeGoalDetailsForm: React.FC<CollegeGoalDetailsFormProps> = (props) => {
  const { formState, setValue, reset, watch } = useFormContext();
  const { averageCosts, model } = props;
  const [annualCost, setAnnualCost] = useState(0);
  const [annualCostDisabled, setAnnualCostDisabled] = useState(false);
  const [beneficiaryType, setBeneficiaryType] = useState<Domain.BeneficiaryTypeEnum | undefined>(
    model?.beneficiaryType
  );

  const onTuitionTypeChanged = useCallback(
    (type: Domain.CollegeTuitionTypeEnum) => {
      switch (type) {
        case Domain.CollegeTuitionTypeEnum.Custom:
          setAnnualCostDisabled(false);
          break;
        case Domain.CollegeTuitionTypeEnum.Private:
          setValue(
            'annualTuitionCost',
            formatWholeDollars(averageCosts.averagePrivateSchoolAnnualCost ?? 0)
          );
          setAnnualCostDisabled(true);
          break;
        case Domain.CollegeTuitionTypeEnum.PublicInState:
          setValue(
            'annualTuitionCost',
            formatWholeDollars(averageCosts.averagePublicSchoolInStateAnnualCost ?? 0)
          );
          setAnnualCostDisabled(true);
          break;
        case Domain.CollegeTuitionTypeEnum.PublicOutState:
          setValue(
            'annualTuitionCost',
            formatWholeDollars(averageCosts.averagePublicSchoolOutStateAnnualCost ?? 0)
          );
          setAnnualCostDisabled(true);
          break;
        default:
          break;
      }
    },
    [averageCosts]
  );

  React.useEffect(() => {
    setBeneficiaryType(model?.beneficiaryType);
  }, [model?.beneficiaryType]);

  React.useEffect(() => {
    setAnnualCost(
      model?.annualTuitionCostCoveragePercent
        ? (model?.annualTuitionCost ?? 0) * 4 * (model?.annualTuitionCostCoveragePercent / 100)
        : (model?.annualTuitionCost ?? 0) * 4
    );
  }, [model, reset, setAnnualCost]);

  React.useEffect(() => {
    const subscription = watch((values, bag) => {
      const { name } = bag;
      if (name === 'annualTuitionCost' || name === 'annualTuitionCostCoveragePercent') {
        let coverage = 0;
        if (model?.beneficiaryType !== Domain.BeneficiaryTypeEnum.DEPENDENT) {
          coverage = 1;
        } else {
          coverage = Math.min(
            (cleanWholeNumberStr(values.annualTuitionCostCoveragePercent) ?? 0) / 100,
            1
          );
        }

        setAnnualCost(cleanWholeNumberStr(values.annualTuitionCost) * coverage * 4);
      } else if (name === 'tuitionType') {
        onTuitionTypeChanged(values.tuitionType);
      } else if (name === 'beneficiaryType') {
        setBeneficiaryType(values.beneficiaryType);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, setAnnualCost, model]);

  return (
    <Box>
      <Stack spacing={Spacing.xxs}>
        {beneficiaryType !== Domain.BeneficiaryTypeEnum.DEPENDENT && (
          <TextInput<Data.Goals.CollegeGoalFormData>
            error={formState.errors.targetDate !== undefined}
            helperText={formState.errors.targetDate?.message?.toString()}
            defaultValue={props.model?.targetDate}
            label={
              beneficiaryType === Domain.BeneficiaryTypeEnum.SPOUSE
                ? 'WhenWouldYourSpouseLikeToAttendCollege'
                : 'WhenWouldYouLikeToAttendCollege'
            }
            name='targetDate'
            type='dateInput'
          />
        )}

        {beneficiaryType === Domain.BeneficiaryTypeEnum.DEPENDENT && (
          <TextInput<Data.Goals.CollegeGoalFormData>
            error={formState.errors.annualTuitionCostCoveragePercent !== undefined}
            helperText={formState.errors.annualTuitionCostCoveragePercent?.message?.toString()}
            helpContext={<PercentageCoveredHelpContent />}
            defaultValue={props.model?.annualTuitionCostCoveragePercent ?? 50}
            label='PercentageOfCostYouPlanToCover'
            name='annualTuitionCostCoveragePercent'
            type='percent'
            precision={0}
          />
        )}

        <SelectInput<Data.Goals.CollegeGoalFormData>
          error={formState.errors.tuitionType !== undefined}
          helperText={formState.errors.tuitionType?.message?.toString()}
          defaultValue={model?.tuitionType}
          name='tuitionType'
          autoFocus
          placeholder='ChooseExpenseType'
          label='TypeOfCollegeExpenses'>
          {options.map((x) => (
            <MenuItem value={x.value}>{t(convertToTranslationKey(x.label))}</MenuItem>
          ))}
        </SelectInput>

        <Grid container alignItems='flex-end' justifyContent={'space-between'}>
          <Grid item sm={6}>
            <TextInput<Data.Goals.CollegeGoalFormData>
              error={formState.errors.annualTuitionCost !== undefined}
              helperText={formState.errors.annualTuitionCost?.message?.toString()}
              defaultValue={props.model?.annualTuitionCost}
              helpContext={<AnnualCostHelpContent />}
              label='AnnualCostOfCollege'
              name='annualTuitionCost'
              InputProps={{ readOnly: annualCostDisabled }}
              type='dollar'
              autoFocus
            />
          </Grid>
          <Grid item sm={5} ml={2}>
            <Grid
              className='info-card-border'
              sx={{
                '& :hover': {
                  cursor: 'auto',
                  backgroundColor: Colors.tertiaryBase,
                },
              }}>
              <Card>
                <Stack spacing={Spacing.xxxs}>
                  <Typography color='secondary' variant='p14Bold'>
                    {t('Total4YearCost')}
                  </Typography>
                  <Typography color='secondary' variant='p14'>
                    {formatWholeDollars(annualCost)}
                  </Typography>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Stack>
    </Box>
  );
};

export const PercentageCoveredHelpContent = () => {
  return (
    <HelpPopover title={t('PercentageOfCost')}>
      <HelpPopoverText>{t('MostFamiliesExpectToCover50Percent')}</HelpPopoverText>
    </HelpPopover>
  );
};

export const AnnualCostHelpContent = () => {
  return (
    <HelpPopover title={t('AnnualCostOfCollege')}>
      <HelpPopoverText>{t('TheFollowingAreIncluededIntTheCostOfCollege')}</HelpPopoverText>
      <Spacer height='xxs' />
      <BulletedList style={Margins.mt_xxs}>
        <>{t('TuitionAndFees')}</>
        <>{t('RoomAndBoard')}</>
        <>{t('BooksAndSupplies')}</>
        <>{t('Transportation')}</>
        <>{t('OtherExpenses')}</>
      </BulletedList>
      <Spacer height='xxs' />
      <HelpPopoverText>{t('WeHaveAssumedOnCampusHousing')}</HelpPopoverText>
    </HelpPopover>
  );
};

export default CollegeGoalDetailsForm;
