/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Grid, Skeleton, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { LayoutMeta, withLayoutMeta } from '../../../types/LayoutMeta';
import { exploreMountain } from '../../../assets/png';
import { useNavigate } from 'react-router-dom';
import { HolisticFinancialAdviceLayoutMeta } from '../HolisticFinancialAdviceLayout';
import { WizardFooter } from '../../../components/form/WizardFooter';
import HolisticPlanStepIcon from '../../../components/HolisticPlanStepIcon';
import { Data, Hooks } from '@3nickels/data-modules';
import { useBackHandler } from '../progress-indicator/useBackHandler';
import { useMutation } from '@aesop-fables/scrinium';
import i18next from 'i18next';
import { convertToTranslationKey } from '../../../helpers/utilityFunctions';

const PlanOutlineView: React.FC = () => {
  const { t } = useTranslation();
  const { language } = i18next;
  const navigate = useNavigate();
  const holisticAdvice = Hooks.useHolisticAdvice();
  const overview = Hooks.useHolisticAdviceOverview();
  const plan = Hooks.HolisticAdvicePlan.useHolisticAdvicePlan();
  const onboardingInfo = Hooks.useOnboardingInfo();
  const commands = Hooks.useCommands();
  const acknowledgeAdvice = useMutation(new Data.Users.Mutations.AcknowledgeAdvice());
  const [guideGenerated, setGuideGenerated] = useState(false);

  const backHandler = useBackHandler(overview, 'plan');

  useEffect(() => {
    (async () => {
      if (typeof onboardingInfo === 'undefined') return;
      if (!onboardingInfo.adviceAcknowledged) {
        await acknowledgeAdvice.action();
      }
    })();
  }, [onboardingInfo]);

  useEffect(() => {
    if (holisticAdvice?.caseId) {
      commands.execute(Data.HolisticAdvice.AcceptHolisticAdvice, holisticAdvice.caseId);
    }
  }, [commands, holisticAdvice?.caseId]);

  useEffect(() => {
    (async () => {
      if (holisticAdvice?.caseId && !guideGenerated) {
        await commands.execute(Data.HolisticAdvice.GenerateHolisticGuide, holisticAdvice.caseId);
        await commands.relay(Data.HolisticAdvice.ReloadSavedGuides);
        setGuideGenerated(true);
      }
    })();
  }, [holisticAdvice?.caseId]);

  const onDone = () => {
    navigate('/');
  };

  const isGoalLabelAutoGenerated = (goalLabel: string) => {
    switch (goalLabel) {
      case 'Pay Off Non-Mortgage Debt':
      case 'Emergency Fund':
      case 'Retirement':
      case 'Cash Giving':
      case 'Volunteering Goal':
      case 'Brokerage Account':
        return true;
      default:
        return false;
    }
  };

  return (
    <Box>
      <Typography className='title' color='primary' component='h1' variant='h1'>
        {t('WhatNow?')}
      </Typography>
      <Typography className='subtitle' color='secondary' variant='p16'>
        {t('HeresThePlan')}
      </Typography>
      <Grid container flexWrap='nowrap' justifyContent='space-between'>
        <Grid item>
          {plan.length === 0 ? (
            <Stack mt='40px' spacing={1}>
              <SkeletonPlan />
              <SkeletonPlan />
              <SkeletonPlan />
            </Stack>
          ) : (
            plan.map((goal) => (
              <Grid>
                <Typography color='primary' variant='p18Bold' mt='40px'>
                  {isGoalLabelAutoGenerated(goal.label)
                    ? t(convertToTranslationKey(goal.label))
                    : goal.label}
                </Typography>
                {goal.steps.map((step) => (
                  <Typography className='subtitle' color='secondary' variant='p16' columnGap='20px'>
                    <HolisticPlanStepIcon
                      type={step.type}
                      style={{ minWidth: '30px', minHeight: '30px' }}
                    />
                    {language === 'es' || language.includes('es-') ? step.esContent : step.content}
                  </Typography>
                ))}
              </Grid>
            ))
          )}
        </Grid>
        <Grid sm={5}>
          <Grid component='img' src={exploreMountain} style={{ width: '100%', height: 'auto' }} />
        </Grid>
      </Grid>
      <WizardFooter nextLabel='GoToAdviceHome' onDone={onDone} onBack={backHandler} />
    </Box>
  );
};

const SkeletonPlan: React.FC = () => {
  return (
    <Grid container display='flex' alignItems='center' columnGap={2}>
      <Skeleton variant='circular' height='30px' width='30px' />
      <Skeleton variant='rounded' height='20px' width='250px' />
    </Grid>
  );
};

const meta = {
  nextLocaleKey: 'GoToAdviceHome',
  headerVariant: 'download',
  showProgress: true,
} satisfies LayoutMeta<HolisticFinancialAdviceLayoutMeta>;

export default withLayoutMeta(PlanOutlineView, meta);
