import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Box, Divider, Grid, Stack, Typography } from '@mui/material';
import { Api, Hooks } from '@3nickels/data-modules';
import { useService } from '@aesop-fables/containr-react';
import { Colors } from '../../../themes';
import { Button } from '../../../components/buttons/Button';
import { LayoutMeta, withLayoutMeta } from '../../../types/LayoutMeta';
import { AccountSettingsLayoutMeta } from '../AccountSettingsLayout';

const LegalView: React.FC = () => {
  const { t } = useTranslation();
  const legalDocs = Hooks.useTermsAndConditions();

  const fairUseNoticeDoc: Api.TermsDoc = {
    id: -1,
    docDescription: 'Fair Use Notice',
  };

  return (
    <Box>
      <Typography mb={3} variant='p30Bold' component='h1' color='primary'>
        {t('Legal')}
      </Typography>
      <Stack spacing={2}>
        {legalDocs &&
          legalDocs.map((doc) => {
            return <DocumentSection key={doc.id} document={doc} />;
          })}
        <DocumentSection document={fairUseNoticeDoc} hideDivider />
      </Stack>
    </Box>
  );
};

interface DocumentSectionProps {
  document: Api.TermsDoc;
  hideDivider?: boolean;
}

const DocumentSection: React.FC<DocumentSectionProps> = ({ document, hideDivider }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const api = useService<Api.ITermsAndConditionsApi>(Api.ApiKeys.TermsAndConditions);
  const { id, docDescription } = document;

  const getSubHeader = (description: string) => {
    switch (description) {
      case 'Disclosure':
        return 'DisclosureSubtext';
      case 'Privacy Policy':
        return 'PrivacyPolicySubtext';
      case 'Terms of Service':
        return 'TermsOfServiceSubtext';
      default:
        return 'FairUseNoticeSubtext';
    }
  };

  const onClick = async () => {
    if (id === -1) {
      navigate('/settings/legal/fair-use-notice');
    } else {
      const response = await api.getDoc(id);
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: 'application/pdf' })
      );
      window.open(url);
    }
  };

  return (
    <>
      <Grid container display='inline-flex' justifyContent='space-between'>
        <Typography color='secondary' variant='p18Bold'>
          {t(docDescription)}
        </Typography>
        <Grid display='flex-end'>
          <Button variant='outlined' color='primary' onClick={onClick}>
            {t('View')}
          </Button>
        </Grid>
      </Grid>
      <Grid sm={9}>
        <Typography color='secondary' variant='p16'>
          {t(getSubHeader(docDescription))}
        </Typography>
      </Grid>
      {!hideDivider && <Divider sx={{ borderColor: Colors.primaryLight }} />}
    </>
  );
};

const meta = {
  showBack: true,
} satisfies LayoutMeta<AccountSettingsLayoutMeta>;

export default withLayoutMeta(LegalView, meta);
