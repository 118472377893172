import { Api, Domain, formatWholeDollars } from '@3nickels/data-modules';
import { Grid } from '@mui/material';
import { GoalBannerIcon } from '../../../../goals';
import { CollapsiblePanel } from '../../../../../components/CollapsiblePanel';
import { GoalCardProps } from './Types';
import GoalCardHeader from './components/GoalCardHeader';
import GoalCardSummary from './components/GoalCardSummary';
import GoalCardDetails from './components/GoalCardDetails';
import { useTranslation } from 'react-i18next';

const CashGivingGoalCard: React.FC<GoalCardProps> = ({ goal }) => {
  const { t } = useTranslation();
  const { goalPlan, advisedGoalPlan, goalStatus } = goal;
  const target = advisedGoalPlan as Api.CashGoalRest;

  return (
    <CollapsiblePanel
      variant='shadow'
      header={<GoalCardHeader goal={goal} hideDate />}
      expandIconPosition='end'
      icon={<GoalBannerIcon type={goalPlan.goalType} />}>
      <Grid container gap='20px'>
        <GoalCardSummary
          mainContent={<strong>{formatWholeDollars(target.annualGivingAmountTarget ?? 0)}</strong>}
          subContent={t('AnnualDreamGivingAmount')}
        />
        {(goalStatus === Domain.GoalStatusEnum.OnTarget ||
          goalStatus === Domain.GoalStatusEnum.PartiallyFunded) && (
          <GoalCardDetails
            details={[
              {
                key: t('MonthlyGiving'),
                value: formatWholeDollars(target.monthlySavings ?? 0),
              },
            ]}
          />
        )}
      </Grid>
    </CollapsiblePanel>
  );
};

export default CashGivingGoalCard;
