import React, { useMemo } from 'react';
import { Grid, Skeleton, Typography } from '@mui/material';
import { IncomeReadySubject } from '../../subjects/AppReadySubject';
import { useSubject } from '@aesop-fables/scrinium';
import Box from '@mui/material/Box';
import { BudgetDetailsLayoutMeta } from './BudgetDetailsLayout';
import { LayoutMeta, withLayoutMeta } from '../../types/LayoutMeta';
import { Data, Hooks, formatWholeDollars } from '@3nickels/data-modules';
import { TabPanel, Tabs } from '../../components/Tabs';
import { formatPercent } from '../../helpers/utilityFunctions';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { Svgs } from '../../assets/svg';
import { Trans, useTranslation } from 'react-i18next';
import { t } from 'i18next';

const BudgetDetailsAverages: React.FC = () => {
  const { t } = useTranslation();
  const [value, setValue] = React.useState(0);
  const incomeReady = useSubject<boolean>(IncomeReadySubject);
  const result = Hooks.useBudgetProjectionResult();

  const loading = useMemo(
    () => typeof incomeReady === 'undefined' || incomeReady === false || result.loading,
    [incomeReady, result]
  );

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant='h1' color='primary' sx={{ mb: 2 }}>
        {t('BudgetAverages')}
      </Typography>
      <Typography variant='p16' color='secondary' sx={{ mb: 3 }}>
        {t('HowAreYouDoingAverage')}
      </Typography>
      <Tabs labels={['Monthly', 'Annual']} value={value} onChange={handleChange} />
      {loading ? (
        <BudgetDetailsAveragesSkeleton />
      ) : (
        <>
          <BudgetDetailsAveragesContent result={result} value={value} index={0} mode='monthly' />
          <BudgetDetailsAveragesContent result={result} value={value} index={1} mode='annual' />
        </>
      )}
      <Typography variant='p12' color='primary'>
        {t('BudgetAveragesCOPewResearchCenter')}
      </Typography>
    </Box>
  );
};

const BudgetDetailsAveragesSkeleton: React.FC = () => {
  return (
    <>
      <Grid className='budget-details-panel-ghost' sx={{ marginBottom: '10px' }}>
        <Grid item display='flex' direction='row' alignItems='center'>
          <Skeleton width='30px' height='30px' variant='circular' />
          <Skeleton sx={{ marginLeft: '10px' }} width='80px' height='25px' variant='rounded' />
        </Grid>
        <Grid item>
          <Skeleton width='80px' height='30px' variant='rounded' />
        </Grid>
      </Grid>
      <BudgetDetailsPanelSkeleton />
      <BudgetDetailsPanelSkeleton />
      <BudgetDetailsPanelSkeleton />
      <Grid className='budget-details-panel-ghost'>
        <Grid item />
        <Grid item display='flex'>
          <Typography
            className='budget-details-panel-end'
            variant='p16Bold'
            color='secondary'
            marginRight='20px'>
            {t('AmountLeftover')}
          </Typography>
          <Skeleton width='80px' height='30px' variant='rounded' />
        </Grid>
      </Grid>
    </>
  );
};

const BudgetDetailsPanelSkeleton: React.FC = () => {
  return (
    <Grid className='budget-details-panel' sx={{ marginBottom: '10px' }}>
      <Grid item display='flex' direction='row' alignItems='center' xs={2}>
        <Skeleton width='30px' height='30px' variant='circular' />
        <Skeleton sx={{ marginLeft: '10px' }} width='80px' height='25px' variant='rounded' />
      </Grid>
      <Grid
        item
        className='budget-details-panel-content'
        display='flex'
        direction='row'
        alignItems='center'
        xs={7}>
        <Skeleton width='40px' height='25px' variant='rounded' />
        <Skeleton
          sx={{ marginLeft: '30px !important' }}
          width='300px'
          height='20px'
          variant='rounded'
        />
      </Grid>
      <Grid item xs={2} display='flex' justifyContent='flex-end'>
        <Skeleton width='80px' height='30px' variant='rounded' />
      </Grid>
    </Grid>
  );
};

const BudgetDetailsAveragesContent: React.FC<{
  result: Hooks.BudgetProjectionResult;
  value: number;
  index: number;
  mode: Data.Budget.BudgetDetailsMode;
}> = ({ result, value, index, mode }) => {
  return (
    <TabPanel className='budget-details-averages' value={value} index={index}>
      <IncomePanel
        income={Data.Budget.adjustAnnualAmount(
          mode,
          result.projection?.summary?.afterTaxHouseholdIncome ?? 0
        )}
      />
      <BudgetDetailsPanel
        category='saving'
        icon={<Svgs.PiggyBank />}
        percentDifference={result.projection?.differences?.averageSavingsDifference ?? 0}
        amount={Data.Budget.adjustMonthlyAmount(mode, result.projection?.summary?.totalSaving ?? 0)}
      />
      <BudgetDetailsPanel
        category='spending'
        icon={<Svgs.DisplaySmallCustomBudget />}
        percentDifference={result.projection?.differences?.averageSpendingDifference ?? 0}
        amount={Data.Budget.adjustMonthlyAmount(
          mode,
          result.projection?.summary?.totalSpending ?? 0
        )}
      />
      <BudgetDetailsPanel
        category='giving'
        icon={<Svgs.DisplaySmallCustomGifts />}
        percentDifference={result.projection?.differences?.averageGivingDifference ?? 0}
        amount={Data.Budget.adjustMonthlyAmount(mode, result.projection?.summary?.totalGiving ?? 0)}
      />
      <LeftoverPanel
        leftover={Data.Budget.adjustMonthlyAmount(
          mode,
          result.projection?.summary?.remainingFunds ?? 0
        )}
      />
    </TabPanel>
  );
};

const IncomePanel: React.FC<{ income: number }> = ({ income }) => {
  return (
    <Grid className='budget-details-panel-ghost'>
      <Grid item>
        <Typography className='budget-details-panel-start' variant='p18Bold' color='secondary'>
          <Svgs.DisplaySmallCustomWallet />
          <Trans
            i18nKey='INCOMEAfterTax'
            components={{
              Styled: (
                <Typography variant='p12' fontWeight='regular' color='primary' marginLeft='10px' />
              ),
            }}
          />
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          className='budget-details-panel-end'
          variant='p22Bold'
          color='primary'
          fontFamily='Montserrat'>
          {formatWholeDollars(income)}
        </Typography>
      </Grid>
    </Grid>
  );
};

const LeftoverPanel: React.FC<{ leftover: number }> = ({ leftover }) => {
  return (
    <Grid className='budget-details-panel-ghost'>
      <Grid item />
      <Grid item display='flex'>
        <Typography
          className='budget-details-panel-end'
          variant='p16Bold'
          color='secondary'
          marginRight='20px'>
          {t('AmountLeftover')}
        </Typography>
        <Typography
          className='budget-details-panel-end'
          variant='p22Bold'
          color={leftover > 0 ? 'secondary' : 'error'}
          fontFamily='Montserrat'>
          {formatWholeDollars(leftover)}
        </Typography>
      </Grid>
    </Grid>
  );
};

interface BudgetDetailsPanelProps {
  category: string;
  icon: React.ReactNode;
  percentDifference: number;
  amount: number;
}

const BudgetDetailsPanel: React.FC<BudgetDetailsPanelProps> = ({
  category,
  icon,
  percentDifference,
  amount,
}) => {
  const memoizedPercent = useMemo(
    () => formatPercent(percentDifference, true),
    [percentDifference, formatPercent]
  );
  return (
    <Grid className='budget-details-panel'>
      <Grid item xs={2}>
        <Typography className='budget-details-panel-start' variant='p18Bold' color='secondary'>
          {icon}
          {t(category.toLocaleUpperCase())}
        </Typography>
      </Grid>
      <Grid item xs={7} className='budget-details-panel-content'>
        <Typography variant='p18Bold' color='secondary'>
          {memoizedPercent}
          <Grid item marginLeft='5px'>
            {percentDifference > 0 && <ArrowUpward color='primary' />}
            {percentDifference < 0 && <ArrowDownward color='primary' />}
            {/* === 0 ? */}
          </Grid>
        </Typography>
        <Typography variant='p14' color='primary'>
          <Trans
            i18nKey='YourCategoryIsPercentDifferentAverage'
            values={{
              category: t(category.toLocaleLowerCase()),
              percent: memoizedPercent,
              difference: percentDifference > 0 ? t('above') : t('below'),
            }}
          />
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography
          className='budget-details-panel-end'
          variant='p22Bold'
          color='primary'
          fontFamily='Montserrat'>
          {formatWholeDollars(amount)}
        </Typography>
      </Grid>
    </Grid>
  );
};

const meta = {
  showBack: true,
  showNext: false,
  headerVariant: 'averages',
} satisfies LayoutMeta<BudgetDetailsLayoutMeta>;

export default withLayoutMeta(BudgetDetailsAverages, meta);
