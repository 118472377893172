import { Domain, Hooks } from '@3nickels/data-modules';
import { Divider, Grid, Typography } from '@mui/material';
import { Colors, Spacing } from '../../themes';
import { LmsMedia } from './LmsMedia';
import { Row } from '../../components';
import { useNextLessonByCourseId } from './LmsHooks';
import { logo3nUni, universitySchoolBrownSquare } from '../../assets/png';
import LmsNextLessonContent from './LmsNextLessonContent';
import { useMemo } from 'react';
import { t } from 'i18next';

interface LmsCourseCardProps {
  course: Domain.CourseDto;
  university?: boolean;
}
export const LmsCourseCard: React.FC<LmsCourseCardProps> = ({ course, university }) => {
  const complete = course.completionPercentage === 100;
  const getStarted = course.completionPercentage === 0 || !course.completionPercentage;
  const subText = getStarted ? 'Get Started!' : 'Congratulations on completing the course!';
  const showNext = !getStarted && !complete;
  const textColor = complete ? 'secondary' : 'primary';
  const progressColor = complete ? Colors.secondaryLight : Colors.primaryLight;

  return (
    <Grid className='lms-course-list'>
      <Grid className='content-section' sx={{ justifyContent: 'space-between', gap: '20px' }}>
        <Grid minWidth={0} flexShrink={1}>
          {university ? (
            <img src={logo3nUni} style={{ maxWidth: '115px' }} />
          ) : (
            <Typography
              variant='p18Bold'
              color='primary'
              sx={{
                display: '-webkit-inline-box',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
                lineHeight: 1.2,
              }}>
              {course.name}
            </Typography>
          )}
          <LmsSubtext
            showNext={showNext}
            subText={subText}
            courseId={course.courseId}
            university={university}
          />
        </Grid>
        <Grid width='40%' flexShrink={0} mx={1} display='flex' justifyContent='center'>
          {university ? (
            <img src={universitySchoolBrownSquare} style={{ maxHeight: 140 }} alt={`3NU Icon`} />
          ) : (
            <LmsMedia
              id={course?.icon?.id}
              title={course?.name ?? ''}
              style={{ maxHeight: 140 }}
              icon
            />
          )}
        </Grid>
      </Grid>
      <Grid width='100%'>
        <Row style={{ justifyContent: 'center', marginBottom: Spacing.xs }}>
          <Typography variant='p12' color={textColor} fontWeight='bold'>
            {t('PercentComplete', { percentage: Math.round(course?.completionPercentage ?? 0) })}
          </Typography>
          <Typography variant='p12' color={textColor} ml={1} mr={1}>
            |
          </Typography>
          <Typography variant='p12' color={textColor}>
            {t('completed/totalX', {
              completed: course?.lessonsCompleted ?? 0,
              total: course?.totalLessons,
              x: 'Lessons',
            })}
          </Typography>
        </Row>
        <Divider
          color={progressColor}
          sx={{
            height: '7px',
            width: `${course.completionPercentage}%`,
            minWidth: (course.completionPercentage ?? 0) === 0 ? undefined : '10%',
            backgroundColor: progressColor,
          }}
        />
      </Grid>
    </Grid>
  );
};

interface LmsSubtextProps {
  showNext: boolean;
  subText?: string;
  courseId?: number;
  university?: boolean;
}

const LmsSubtext: React.FC<LmsSubtextProps> = ({ showNext, courseId, subText, university }) => {
  const nextLmsLesson = useNextLessonByCourseId(courseId ?? 0);
  const nextUniLesson = Hooks.useNextUniversityLesson();

  const minRemaining = useMemo(() => {
    if (nextUniLesson?.progress && nextUniLesson.duration) {
      const secondsViewed = nextUniLesson.duration * (nextUniLesson.progress / 100);
      const remaining = nextUniLesson.duration - secondsViewed;
      return Math.round(remaining / 60);
    }

    return Math.round((nextUniLesson?.duration ?? 0) / 60);
  }, [nextUniLesson]);

  return showNext ? (
    university ? (
      <LmsNextLessonContent
        variant='alt'
        nextLesson={{ name: nextUniLesson?.title, minutes: minRemaining }}
      />
    ) : (
      <LmsNextLessonContent nextLesson={nextLmsLesson} variant='alt' />
    )
  ) : (
    <Typography mt='10px' variant='p16' color='secondary'>
      {subText}
    </Typography>
  );
};
