import React, { CSSProperties } from 'react';
import TextField, { type TextFieldProps } from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import {
  Grid,
  type FilledInputProps,
  type InputBaseComponentProps,
  type InputLabelProps,
  InputAdornment,
  IconButton,
  InputLabel,
  Typography,
} from '@mui/material';
import Tippy from '@tippyjs/react';
import 'tippy.js/themes/light-border.css'; // must import any theme you wish to use with Tippy
import 'tippy.js/dist/tippy.css'; // optional
import '../../themes/tippy-light-nickels.css';
import { HelpOutline, Visibility, VisibilityOff } from '@mui/icons-material';
import { theme } from '../../themes/ThemeWrapper';
import DollarInput from './DollarInput';
import PhoneNumberInput from './PhoneNumberInput';
import NumberInput from './NumberInput';
import DateInput from './DateInput';

type Props = Omit<
  TextFieldProps,
  'label' | 'inputProps' | 'name' | 'InputProps' | 'InputLabelProps'
>;

export type TextInputProps<T> = {
  label?: string;
  precision?: number;
  min?: number;
  max?: number;
  inputProps?: InputBaseComponentProps;
  InputProps?: Partial<FilledInputProps>;
  InputLabelProps?: Partial<InputLabelProps>;
  name: keyof T;
  register?: boolean;
  helpContext?: React.ReactNode;
  resolver?: (values: { [x: string]: unknown }) => string;
  fieldStyle?: CSSProperties;
} & Props;

export default function TextInput<T>(props: TextInputProps<T>) {
  const { t } = useTranslation();

  const { label, register = true, fieldStyle, ...textProps } = props;
  if (textProps.helpContext) {
    delete textProps.helpContext;
  }

  const text = label ? t(label) : undefined;

  const getInputField = () => {
    if (props.type === 'tel') return <PhoneNumberInput {...props} label='' />;
    else if (props.type === 'dateInput') return <DateInput {...props} label='' />;
    else if (props.type === 'dollar') return <DollarInput {...props} label='' />;
    else if (props.type === 'percent')
      return <NumberInput precision={props.precision ?? 2} {...props} type='percent' label='' />;
    else if (props.type === 'number' || props.type === 'integer' || props.type === 'percent')
      return <NumberInput {...props} label='' />;
    else if (props.type === 'password') return <PasswordInput {...props} label='' />;
    else return <StandardInput {...props} register={register} label='' />;
  };

  return (
    <Grid display='flex' flexDirection='column' sx={fieldStyle}>
      {(text || props.helpContext) && (
        <Grid
          item
          flexDirection='row'
          justifyContent='space-between'
          alignItems='center'
          display='flex'
          mb='6px'>
          {text && (
            <InputLabel
              shrink={false}
              disableAnimation
              required={props.required}
              htmlFor={props.name as string}
              error={props.error}>
              <Typography
                style={{ whiteSpace: 'normal' }}
                display='inline'
                variant='p16Bold'
                color='primary'>
                {text}
              </Typography>
              {props.type === 'dateInput' && (
                <Typography
                  style={{ whiteSpace: 'normal' }}
                  display='inline'
                  variant='p14'
                  color='primary'>
                  &nbsp;({t('MM/DD/YYYY')})
                </Typography>
              )}
            </InputLabel>
          )}
          {props.helpContext && (
            <Grid item display='flex' justifyContent='flex-end'>
              <Tippy
                placement='right'
                content={props.helpContext}
                theme='light-nickels-theme'
                className='help'>
                <HelpOutline style={{ color: theme.palette.primary.main }} />
              </Tippy>
            </Grid>
          )}
        </Grid>
      )}
      {getInputField()}
    </Grid>
  );
}

export function PasswordInput<T>(props: TextInputProps<T>) {
  const [passwordShow, setPasswordShow] = React.useState(false);
  const [formattedValue, setFormattedValue] = React.useState<string>(
    props.defaultValue?.toString() ?? ''
  );
  const methods = useFormContext();

  const passwordHandler =
    props.type === 'password'
      ? {
          ...props.InputProps,
          role: 'input',
          type: !passwordShow ? 'password' : 'text',
          endAdornment: (
            <InputAdornment variant='standard' position='end'>
              <IconButton
                size='small'
                color='secondary'
                disableRipple
                onClick={(e) => {
                  e.preventDefault();
                  setPasswordShow(!passwordShow);
                }}>
                {!passwordShow ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }
      : props.InputProps;

  React.useEffect(() => {
    const subscription = methods.watch((values, { name }) => {
      if (name === props.name) {
        // allow custom function to handle inputs with unconventional names
        // i.e. fieldArrays where convention is fieldArrayName.index.property
        if (props.resolver) {
          const newVal = props.resolver(values);
          setFormattedValue(newVal);
          return;
        }
        const newVal = values[name];
        setFormattedValue(newVal);
      }
    });
    return () => subscription.unsubscribe();
  }, [methods.watch, props.name, setFormattedValue]);

  return (
    <TextField
      {...(props as TextFieldProps)}
      fullWidth
      size='small'
      variant='outlined'
      onFocus={(event) => {
        if (props.autoFocus && !event.target?.hasAttribute('data-autoFocused')) {
          event.target?.setSelectionRange(event.target.value.length, event.target.value.length);
          event.target?.setAttribute('data-autoFocused', 'true');
        }
      }}
      InputProps={passwordHandler}
      inputProps={{
        ...props.inputProps,
        ...{ color: 'secondary' },
        ...(methods && methods.register ? { ...methods.register(props.name as string) } : {}),
      }}
      value={formattedValue}
    />
  );
}

export function StandardInput<T>(props: TextInputProps<T>) {
  const [formattedValue, setFormattedValue] = React.useState<string>(
    props.defaultValue?.toString() ?? ''
  );
  const methods = useFormContext();

  React.useEffect(() => {
    const subscription = methods.watch((values, { name }) => {
      if (name === props.name) {
        // allow custom function to handle inputs with unconventional names
        // i.e. fieldArrays where convention is fieldArrayName.index.property
        if (props.resolver) {
          const newVal = props.resolver(values);
          setFormattedValue(newVal);
          return;
        }
        const newVal = values[name];
        setFormattedValue(newVal);
      }
    });
    return () => subscription.unsubscribe();
  }, [methods.watch, props.name, setFormattedValue]);

  return (
    <TextField
      fullWidth
      size='small'
      variant='outlined'
      {...(props as TextFieldProps)}
      onFocus={(event) => {
        if (props.autoFocus && !event.target?.hasAttribute('data-autoFocused')) {
          event.target?.setSelectionRange(event.target.value.length, event.target.value.length);
          event.target?.setAttribute('data-autoFocused', 'true');
        }
      }}
      inputProps={{
        ...props.inputProps,
        ...{ color: 'secondary' },
        ...(props.register && methods && methods.register
          ? { ...methods.register(props.name as string) }
          : {}),
      }}
      defaultValue={undefined}
      value={formattedValue}
    />
  );
}
