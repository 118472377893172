import { Card, Grid, Typography } from '@mui/material';
import React from 'react';
import { Row, Spacer } from '../../../components';
import { Api, Domain, Hooks } from '@3nickels/data-modules';
import {
  formatDateMMDDYYYY,
  formatWholeDollars,
  convertMonthsToYearMonth,
} from '../../../helpers/utilityFunctions';
import { Trans, useTranslation } from 'react-i18next';
import { RetirementAgeGoalCard } from './RetirementAgeGoalCard';
import { theme } from '../../../themes/ThemeWrapper';
import Tippy from '@tippyjs/react';
import { HelpOutline } from '@mui/icons-material';
import HelpPopover, { HelpPopoverText } from '../../../components/HelpPopover';
import { t } from 'i18next';
import BulletedList from '../../../components/BulletedList';
import { Margins } from '../../../themes';

const MortgageMonthlyPaymentHelp = (extraPrincipal: number) => {
  return (
    <HelpPopover title={t('ExtraPaidTowardPrincipal')}>
      <HelpPopoverText>
        {t('ThisMonthlyPaymentIncludesExtra', { extra: formatWholeDollars(extraPrincipal) })}
      </HelpPopoverText>
    </HelpPopover>
  );
};

const CollegeGoalAmountHelp = () => {
  return (
    <HelpPopover title={t('CollegeGoalAmount')}>
      <HelpPopoverText>{t('WhyIsThisAmountMoreThanEntered')}</HelpPopoverText>
      <Spacer height='xxs' />
      <HelpPopoverText>{t('WeAccountForTheTimeValueOfMoney')}</HelpPopoverText>
    </HelpPopover>
  );
};

const RetirementGoalAmountHelp = (ssi: string) => {
  return (
    <HelpPopover title={t('MonthlyRetirementIncome')}>
      <HelpPopoverText>{t('ThisIsAfterTaxIncome')}</HelpPopoverText>
      <Spacer height='xxs' />
      <BulletedList
        style={Margins.mt_xxs}
        children={[
          t('IncludesSSIPerMonth', { ssi: ssi }),
          t('EstBasedOnThousands'),
          t('WeSimulateFutureValue'),
        ]}
      />
    </HelpPopover>
  );
};

interface DetailItem {
  displayText: string;
  value: string | JSX.Element;
  subText?: string | JSX.Element;
  helpContext?: React.ReactNode;
}

const resolveDetails = (
  advisedGoalPlan: Api.GoalRest,
  originalGoalPlan: Api.GoalRest,
  goalStatus: Domain.GoalStatusEnum,
  ssi: number,
  projectedFutureMonthlyIncome: number
): DetailItem[] => {
  const details: DetailItem[] = [];

  switch (advisedGoalPlan?.goalType) {
    case Domain.GoalTypeEnum.Non_Mortgage_Debt: {
      const debtGoal = advisedGoalPlan as Api.PayOffDebtGoalRest;
      const debtPayOff = convertMonthsToYearsAndMonths(debtGoal.numberOfMonthsToPayoff ?? 0);
      if (goalStatus === Domain.GoalStatusEnum.Complete) {
        details.push({
          displayText: 'HeresWhenYoullPayOffThisDebt',
          value: <strong>{t('today!')}</strong>,
        });
      } else {
        details.push({
          displayText: 'HeresWhenYoullPayOffThisDebt',
          value: debtPayOff,
        });
        details.push({
          displayText: 'YouNeedToPayOffThisAmountEachMonth',
          value: (
            <Grid>
              <Trans
                i18nKey='StyledXPerPeriod'
                values={{
                  x: formatWholeDollars(debtGoal.monthlyPaymentTarget ?? 0),
                  period: t('month'),
                }}
                components={{
                  Styled: <strong />,
                }}
              />
            </Grid>
          ),
        });
        details.push({
          displayText: 'HeresHowMuchInterestYoullPayInTotal',
          value: <strong>{formatWholeDollars(debtGoal.totalInterestPaid ?? 0)}</strong>,
        });
      }
      break;
    }
    case Domain.GoalTypeEnum.Mortgage: {
      const mortgageGoal = advisedGoalPlan as Api.PayOffMortgageGoalRest;
      const mortgagePayOff = convertMonthsToYearsAndMonths(
        mortgageGoal.numberOfMonthsToPayoff ?? 0
      );
      const monthlyPayment =
        (mortgageGoal.monthlySavings ?? 0) + (mortgageGoal.extraMonthlyPrinciplePayment ?? 0);
      if (goalStatus === Domain.GoalStatusEnum.Complete) {
        details.push({
          displayText: 'HeresWhenYoullPayOffThisMortgage',
          value: <strong>{t('today!')}</strong>,
        });
      } else {
        details.push({
          displayText: 'HeresWhenYoullPayOffThisMortgage',
          value: mortgagePayOff,
        });
        details.push({
          displayText: 'YouNeedToPayThisAmountEachMonth',
          value: (
            <Grid>
              <Trans
                i18nKey='StyledXPerPeriod'
                values={{
                  x: formatWholeDollars(monthlyPayment),
                  period: t('month'),
                }}
                components={{
                  Styled: <strong />,
                }}
              />
            </Grid>
          ),
          helpContext:
            (mortgageGoal.extraMonthlyPrinciplePayment ?? 0) === 0
              ? undefined
              : MortgageMonthlyPaymentHelp(mortgageGoal.extraMonthlyPrinciplePayment ?? 0),
        });
        details.push({
          displayText: 'HeresHowMuchInterestYoullPayInTotal',
          value: <strong>{formatWholeDollars(mortgageGoal.totalInterestPaid ?? 0)}</strong>,
        });
      }
      break;
    }
    case Domain.GoalTypeEnum.Retirement: {
      const retirementGoal = advisedGoalPlan as Api.RetirementGoalRest;
      details.push({
        displayText: 'YourSpendableIncome',
        value: (
          <Grid>
            <Trans
              i18nKey='StyledXPerPeriod'
              values={{
                x: formatWholeDollars(projectedFutureMonthlyIncome),
                period: t('month'),
              }}
              components={{
                Styled: <strong />,
              }}
            />
          </Grid>
        ),
        helpContext: RetirementGoalAmountHelp(formatWholeDollars(ssi)),
        subText: (
          <Grid>
            <Trans
              i18nKey='FeelsLikeDueToInflation'
              values={{
                amount: formatWholeDollars(retirementGoal.projectedMonthlyIncome ?? 0),
              }}
              components={{
                Styled: <strong />,
              }}
            />
          </Grid>
        ),
      });
      details.push({
        displayText: 'YouNeedToSaveThisAmountEachMonth',
        value: (
          <Grid>
            <Trans
              i18nKey='StyledXPerPeriod'
              values={{
                x: formatWholeDollars(
                  (retirementGoal.monthlySavings ?? 0) +
                    (retirementGoal.outOfPlanSavingsAmount ?? 0)
                ),
                period: t('month'),
              }}
              components={{
                Styled: <strong />,
              }}
            />
          </Grid>
        ),
      });
      break;
    }
    case Domain.GoalTypeEnum.Cash_Giving: {
      const cashGoal = advisedGoalPlan as Api.CashGoalRest;
      details.push({
        displayText: 'YouDreamOfGivingThisAmountEachYear',
        value: (
          <Grid>
            <Trans
              i18nKey='StyledXPerPeriod'
              values={{
                x: formatWholeDollars(cashGoal.annualGivingAmountTarget ?? 0),
                period: t('year'),
              }}
              components={{
                Styled: <strong />,
              }}
            />
          </Grid>
        ),
      });
      if ((cashGoal.monthlySavings ?? 0) > 0) {
        details.push({
          displayText: 'YouNeedToGiveThisAmountEachMonth',
          value: (
            <Grid>
              <Trans
                i18nKey='StyledXPerPeriod'
                values={{
                  x: formatWholeDollars(cashGoal.monthlySavings ?? 0),
                  period: t('month'),
                }}
                components={{
                  Styled: <strong />,
                }}
              />
            </Grid>
          ),
        });
      }
      break;
    }
    case Domain.GoalTypeEnum.Volunteer: {
      const volunteeringGoal = advisedGoalPlan as Domain.VolunteerGoalHolder;
      for (let i = 0; i < volunteeringGoal.items.length; i++) {
        const item = volunteeringGoal.items[i];
        const { numberOfHours, frequency } = item;
        details.push({
          displayText: item.nameOfPlace ?? '',
          value: (
            <Grid>
              <Trans
                i18nKey='StyledXUnitPerPeriod'
                values={{
                  x: numberOfHours,
                  unit: (numberOfHours ?? 0) > 1 ? t('hours') : t('hour'),
                  period:
                    frequency === Domain.VolunteeringFrequencyEnum.Weekly ? t('week') : t('month'),
                }}
                components={{
                  Styled: <strong />,
                }}
              />
            </Grid>
          ),
        });
      }
      break;
    }
    default: {
      const target = advisedGoalPlan ?? originalGoalPlan;
      details.push({
        displayText: 'YourSavingsGoalAmount',
        value: <strong>{formatWholeDollars(originalGoalPlan.amountNeeded ?? 0).toString()}</strong>,
        helpContext:
          target.goalType === Domain.GoalTypeEnum.College ? CollegeGoalAmountHelp() : undefined,
      });
      if (
        goalStatus === Domain.GoalStatusEnum.OnTarget ||
        (goalStatus === Domain.GoalStatusEnum.PartiallyFunded && target.monthlySavings !== 0)
      ) {
        details.push({
          displayText: 'YouNeedToSaveThisAmountEachMonth',
          value: (
            <Grid>
              <Trans
                i18nKey='StyledXPerPeriod'
                values={{
                  x: formatWholeDollars(target.monthlySavings ?? 0),
                  period: t('month'),
                }}
                components={{
                  Styled: <strong />,
                }}
              />
            </Grid>
          ),
        });
        details.push({
          displayText: 'YoullGetThereByThisDate',
          value: <strong>{formatDateItem(target?.goalDate)}</strong>,
        });
      }
      if (goalStatus === Domain.GoalStatusEnum.Complete) {
        details.push({
          displayText: 'YoullGetThereByThisDate',
          value: <strong>{t('today!')}</strong>,
        });
      }
    }
  }

  return details;
};

const formatDateItem = (value?: Date) => {
  return value ? formatDateMMDDYYYY(value.toString()) : 'ASAP';
};

function convertMonthsToYearsAndMonths(numMonths: number) {
  const { years, months } = convertMonthsToYearMonth(numMonths);

  if (numMonths < 12) {
    const monthText = numMonths > 1 ? 'months' : 'month';
    return (
      <Typography fontFamily='Montserrat' variant='p30' color='secondary' display='inline'>
        <Trans
          i18nKey='xUnitStyled'
          values={{ x: numMonths, unit: t(monthText) }}
          components={{
            Styled: <strong />,
          }}
        />
      </Typography>
    );
  } else if (months === 0) {
    const yearText = years > 1 ? 'years' : 'year';

    return (
      <Typography fontFamily='Montserrat' variant='p30' color='secondary' display='inline'>
        <Trans
          i18nKey='xUnitStyled'
          values={{ x: years, unit: t(yearText) }}
          components={{
            Styled: <strong />,
          }}
        />
      </Typography>
    );
  } else {
    const yearText = years > 1 ? 'years' : 'year';
    const monthText = months > 1 ? 'months' : months === 1 ? 'month' : '';

    return (
      <Typography fontFamily='Montserrat' variant='p30' color='secondary' display='inline'>
        <Trans
          i18nKey='xUnit1YUnit2Styled'
          values={{ x: years, unit1: t(yearText), y: months, unit2: t(monthText) }}
          components={{
            Styled: <strong />,
          }}
        />
      </Typography>
    );
  }
}

export interface GoalDetailsCardProps {
  goal: Domain.GoalSummary;
  ssi: number;
  projectedFutureMonthlyIncome: number;
}

const GoalDetailsCard: React.FC<GoalDetailsCardProps> = ({
  goal,
  ssi,
  projectedFutureMonthlyIncome,
}) => {
  const { t } = useTranslation();
  const includeSpouse = Hooks.useIncludeSpouse();
  const { advisedGoalPlan, goalPlan, goalStatus } = goal;
  const retirementGoal = advisedGoalPlan as Api.RetirementGoalRest;
  const originalRetirementGoal = goalPlan as Api.RetirementGoalRest;
  const details = resolveDetails(
    advisedGoalPlan,
    goalPlan,
    goalStatus,
    ssi,
    projectedFutureMonthlyIncome
  );

  return (
    <Grid container justifyContent='center' mt={4}>
      <Grid container className='goal-summary' maxWidth='550px'>
        {details.length !== 0 && (
          <>
            {details.map((detail) => {
              return (
                <Card style={{ padding: '15px', textAlign: 'center' }}>
                  <Row>
                    <Typography variant='p16' color='secondary'>
                      {t(detail.displayText)}
                    </Typography>
                    {detail.helpContext && (
                      <Grid item ml='5px' display='inline-flex' flexDirection='row'>
                        <Tippy
                          placement='right'
                          content={detail.helpContext}
                          theme='light-nickels-theme'
                          className='help'>
                          <HelpOutline style={{ color: theme.palette.primary.main }} />
                        </Tippy>
                      </Grid>
                    )}
                  </Row>
                  <Typography mt='10px' fontFamily='Montserrat' variant='p30' color='secondary'>
                    {detail.value}
                  </Typography>
                  {detail.subText && (
                    <Typography mt='10px' variant='p16' color='primary'>
                      {detail.subText}
                    </Typography>
                  )}
                </Card>
              );
            })}
          </>
        )}
        {advisedGoalPlan?.goalType === Domain.GoalTypeEnum.Retirement && (
          <RetirementAgeGoalCard
            includeSpouse={includeSpouse}
            retirementGoal={retirementGoal}
            originalRetirementGoal={originalRetirementGoal}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default GoalDetailsCard;
