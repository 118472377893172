import { formatWholeDollars } from '@3nickels/data-modules';
import { Card, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { convertMonthsToYearMonth } from '../../../../helpers/utilityFunctions';
import { errorBase } from '../../../../themes/colors';
import { Button } from '../../../../components/buttons/Button';
import { useNavigate } from 'react-router-dom';
import { Svgs } from '../../../../assets/svg';

interface LeftColumnProps {
  savingsOfInterest: number;
  savingsOfTime: number;
  wouldHelp: boolean;
  noDebts: boolean;
}

export const LeftColumn: React.FC<LeftColumnProps> = ({
  savingsOfInterest,
  savingsOfTime,
  noDebts,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { months, years } = convertMonthsToYearMonth(savingsOfTime);
  const yearText = years === 1 ? 'year' : 'years';
  const monthText = months === 1 ? 'month' : 'months';

  return (
    <Grid item xs={5} container direction='column' justifyContent='flex-start'>
      <Grid className='tool-form-container'>
        {noDebts ? (
          <Card className='no-debts'>
            <Grid item container direction='row' justifyContent='space-around'>
              <Grid item container direction='column' alignItems='center'>
                <Svgs.ArtCreditCard3 />

                <Typography mt={5} mb={3} variant='p16' sx={{ color: errorBase }}>
                  {t('PleaseAddADebtInTheAccounts')}
                </Typography>
                <Button
                  label={'Go to Accounts'}
                  color='primary'
                  style={{ minWidth: 'fit-content', width: '35%' }}
                  onClick={() => navigate('/account-details')}
                />
              </Grid>
            </Grid>
          </Card>
        ) : (
          <>
            <Card className='tools-output-box'>
              <Grid item container direction='row' justifyContent='space-around'>
                <Grid item container direction='column' alignItems='center'>
                  <Typography variant='p16' color='secondary'>
                    {t(`MoneySaved`)}
                  </Typography>
                  <Typography variant='p30Bold' color='secondary' fontFamily='Montserrat'>
                    {formatWholeDollars(savingsOfInterest)}
                  </Typography>
                </Grid>
              </Grid>
            </Card>
            <Card className='tools-output-box'>
              <Grid item container direction='row' justifyContent='space-around'>
                <Grid item container direction='column' alignItems='center'>
                  <Typography variant='p16' color='secondary'>
                    {t(`TimeSaved`)}
                  </Typography>
                  {years > 0 && months > 0 ? (
                    <Typography variant='p30' color='secondary' fontFamily='Montserrat'>
                      <strong>{years}</strong>&nbsp;{t(yearText)}&nbsp;
                      <strong>{months}</strong>&nbsp;{t(monthText)}
                    </Typography>
                  ) : years > 0 ? (
                    <Typography variant='p30' color='secondary' fontFamily='Montserrat'>
                      <strong>{years}</strong>&nbsp;{t(yearText)}
                    </Typography>
                  ) : (
                    <Typography variant='p30' color='secondary' fontFamily='Montserrat'>
                      <strong>{months}</strong>&nbsp;{t(monthText)}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Card>
          </>
        )}
      </Grid>
    </Grid>
  );
};
