import { Domain } from '@3nickels/data-modules';
import { Grid, Typography } from '@mui/material';
import { useMemo } from 'react';

interface UnitProgressHeaderProps {
  unit: Domain.Unit;
  selected?: boolean;
}

const UnitProgressHeader: React.FC<UnitProgressHeaderProps> = ({ unit, selected }) => {
  const progress = useMemo(() => {
    let completedLessons = 0;
    unit.lessons.forEach((lesson) => {
      if (lesson.complete) completedLessons++;
    });
    return completedLessons;
  }, [unit]);
  const duration = useMemo(() => {
    const totalMins = Math.floor(
      unit.lessons.reduce((acc, lesson) => acc + lesson.duration, 0) / 60
    );

    if (totalMins >= 60) {
      const hours = Math.floor(totalMins / 60);
      const mins = totalMins % 60;
      return `${hours} ${hours === 1 ? 'hr' : 'hrs'}${mins > 0 ? ` ${mins} min` : ''}`;
    } else {
      return `${totalMins} min`;
    }
  }, [unit]);

  return (
    <Grid overflow='hidden'>
      <Typography
        variant='p18Bold'
        color={selected ? 'secondary' : 'primary'}
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          display: 'block',
        }}>
        {unit.title}
      </Typography>
      <Grid container gap='10px'>
        <Typography variant='p12' color={selected ? 'secondary' : 'primary'} fontWeight='normal'>
          {progress}/{unit.lessons.length}
        </Typography>
        <Typography variant='p12' color={selected ? 'secondary' : 'primary'} fontWeight='normal'>
          |
        </Typography>
        <Typography variant='p12' color={selected ? 'secondary' : 'primary'} fontWeight='normal'>
          {duration}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default UnitProgressHeader;
