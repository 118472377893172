import React from 'react';
import { Grid, Table, TableBody, TableContainer, Typography } from '@mui/material';
import { Data, Hooks, formatWholeDollars } from '@3nickels/data-modules';
import { Circle } from '@mui/icons-material';
import { formatPercent, convertToTranslationKey } from '../../helpers/utilityFunctions';
import { TransactionsSummaryChartColors } from './SpendingSummaryOverview';
import SpendingSummaryTotal from './SpendingSummaryTotal';
import SkeletonSpendingSummaryBreakdown from './skeletons/SkeletonSpendingSummaryBreakdown';
import { t } from 'i18next';

export interface SpendingSummaryBreakdownProps {
  transactionsSummary: Data.Transactions.TransactionsSummaryData[];
  onSelectCategory?: (category?: Data.Transactions.TransactionCategoryEnum) => void;
  colors?: string[];
  repeatColorIndex?: number;
}

const SpendingSummaryBreakdown: React.FC<SpendingSummaryBreakdownProps> = ({
  transactionsSummary,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onSelectCategory = () => {},
  colors = TransactionsSummaryChartColors,
  repeatColorIndex = colors.length - 1,
}) => {
  const { loadingSummary } = Hooks.useTransactionsSummary();
  const spendingTotal = Hooks.useTransactionsSpendingTotal(transactionsSummary);

  if (loadingSummary) {
    return <SkeletonSpendingSummaryBreakdown />;
  }

  return (
    <Grid display='flex' flexDirection='column' width='60%' rowGap='20px' flexGrow={1}>
      <SpendingSummaryTotal transactionsSummary={transactionsSummary} />
      <TableContainer className='spending-overview-categories-container'>
        <Table className='spending-overview-categories'>
          <TableBody className='spending-overview-view'>
            {transactionsSummary.map((category, index) => {
              const categoryLabel = convertToTranslationKey(
                category.primaryCategory?.toString() ?? ''
              );
              return (
                <Grid
                  className='category-row'
                  onClick={() => onSelectCategory(category.primaryCategory)}>
                  <Grid className='category-section'>
                    <Circle
                      className='circle'
                      htmlColor={colors[index > repeatColorIndex ? repeatColorIndex : index]}
                    />
                    <Typography className='category' variant='p16Bold' color='secondary'>
                      {t(`${categoryLabel}`)}
                    </Typography>
                  </Grid>
                  <Grid className='amount-section' display='flex' width='50%'>
                    <Typography variant='p16' color='primary'>
                      {formatPercent(((category.amount ?? 0) / spendingTotal) * 100)}
                    </Typography>
                    <Typography variant='p16Bold' color='primary'>
                      {formatWholeDollars(category.amount ?? 0)}
                    </Typography>
                  </Grid>
                </Grid>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

export default SpendingSummaryBreakdown;
