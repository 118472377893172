import { Typography } from '@mui/material';
import { Colors } from '../themes';
import { Domain } from '@3nickels/data-modules';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import { convertToTranslationKey } from '../helpers/utilityFunctions';

export declare type FormTitleProps = {
  title: string;
  emphasizedText?: string;
  secondaryText?: JSX.Element;
};

const FormTitle: React.FC<FormTitleProps> = ({ secondaryText, emphasizedText, title }) => {
  return (
    <Typography component='h1' variant='h1' color={Colors.primaryBase} display='inline-block'>
      <Trans
        i18nKey={title}
        values={{ type: t(`${emphasizedText}`) }}
        components={{
          Styled: <span style={{ fontWeight: '100' }} />,
        }}
      />
      {secondaryText && secondaryText}
    </Typography>
  );
};

export const GoalFormTitle: React.FC<{
  secondaryText?: JSX.Element;
  editing?: boolean;
  type: Domain.GoalTypeEnum;
}> = ({ secondaryText, editing, type }) => {
  const goalType = Domain.GoalTypeLabels[type];
  const goalTypeLabel = convertToTranslationKey(goalType);

  return (
    <FormTitle
      title={editing ? 'EditGoalTypeStyled' : 'AddGoalTypeStyled'}
      emphasizedText={goalTypeLabel}
      secondaryText={secondaryText}
    />
  );
};
