import React from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import CardDisplay from '../../../components/panels/CardDisplay';
import { Domain } from '@3nickels/data-modules';
import { GroupEdit } from '../../../components/GroupEdit';
import { AddDependentRow } from './DependentView';
import { SummaryCardProps } from '../../account-details/Types';
import { t } from 'i18next';

export interface DependentInfoCardProps extends Omit<SummaryCardProps, 'onEdit'> {
  dependents: Domain.DependentData[];
  onAdd: () => void;
  onEdit: (dependent: Domain.DependentData) => void;
  onDelete: (dependent: Domain.DependentData) => void;
}

const DependentInfoCard: React.FC<DependentInfoCardProps> = ({
  dependents,
  onAdd,
  onDelete,
  onEdit,
}) => {
  return (
    <CardDisplay
      headerKey='Dependents'
      collapsible={dependents.length > 0}
      summary={<DependentSummary dependents={dependents} />}>
      <Box>
        <GroupEdit<Domain.DependentData>
          variant='compressed'
          items={dependents}
          keyFn={(x) => `${x.id ?? 0}`}
          mainContentFn={(x) => (
            <Typography
              className='hover-clickable'
              fontWeight='bold'
              color='secondary'
              onClick={() => onEdit(x)}>
              {x.name ?? ''}
            </Typography>
          )}
          summaryFn={(x) => t('BornYear', { year: x.birthYear }).toString()}
          onEdit={onEdit}
          onDelete={onDelete}
          addItemComponent={<AddDependentRow onAdd={onAdd} />}
        />
      </Box>
    </CardDisplay>
  );
};

type DependentSummaryProps = {
  dependents: Domain.DependentData[];
};

const DependentSummary: React.FC<DependentSummaryProps> = ({ dependents }) => {
  const [dependentRows, setDependentRows] = React.useState<Domain.DependentData[][]>([]);

  React.useEffect(() => {
    const perRow = 5;
    const rowCount = Math.ceil(dependents.length / perRow);
    const rows = [];
    for (let i = 0; i < rowCount; i++) {
      const start = i * perRow;
      const row = dependents.slice(start, start + perRow);
      for (let j = row.length; j < perRow; j++) {
        row.push({});
      }
      rows.push(row);
    }
    setDependentRows(rows);
  }, [dependents]);

  return (
    <>
      {dependentRows.map((row, index) => (
        <div key={`key${index}`}>
          {index > 0 && <Divider className='dependent-divider' color='primary.light' />}
          <Grid container spacing={2}>
            {row
              .filter((x) => typeof x.id !== 'undefined')
              .map((dependent) => (
                <Grid item xs key={`${dependent.id}:${dependent.name}:${dependent.birthYear}`}>
                  <Typography color='secondary'>
                    {dependent.name} ({dependent.birthYear})
                  </Typography>
                </Grid>
              ))}
          </Grid>
        </div>
      ))}
    </>
  );
};

export default DependentInfoCard;
