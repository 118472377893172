import { Card, Grid, Typography } from '@mui/material';
import React from 'react';
import { Row, Spacer } from '../../../components';
import { Api } from '@3nickels/data-modules';
import { useTranslation } from 'react-i18next';
import { HelpOutline } from '@mui/icons-material';
import Tippy from '@tippyjs/react';
import { theme } from '../../../themes/ThemeWrapper';
import { t } from 'i18next';

export interface RetirementAgeGoalCardProps {
  includeSpouse: boolean;
  retirementGoal: Api.RetirementGoalRest;
  originalRetirementGoal: Api.RetirementGoalRest;
}

export const RetirementAgeGoalCard: React.FC<RetirementAgeGoalCardProps> = ({
  includeSpouse,
  retirementGoal,
  originalRetirementGoal,
}) => {
  const { t } = useTranslation();
  const originalRetirementAge = originalRetirementGoal.primary?.retirementAge ?? 0;
  const recommendedRetirementAge = retirementGoal.primary?.retirementAge ?? 0;
  const agesDifferent = originalRetirementAge !== recommendedRetirementAge;
  const showHelpModal = recommendedRetirementAge > originalRetirementAge;

  return (
    <>
      <Spacer height='xs' />
      {includeSpouse ? (
        <Grid container>
          <Grid xs={5.75} container direction='column' alignItems='center' justifyContent='center'>
            <Card>
              <Row>
                <Typography mr={1} variant='p16' color='secondary'>
                  {t('YourRetirementAge')}
                </Typography>
                {showHelpModal && (
                  <Tippy
                    placement='right'
                    content={<HelpContent recommendedRetirementAge={recommendedRetirementAge} />}
                    theme='light-nickels-theme'>
                    <HelpOutline style={{ color: theme.palette.primary.main }} />
                  </Tippy>
                )}
              </Row>
              {agesDifferent ? (
                <Grid container xs={8} alignItems='center'>
                  <Grid
                    xs={6}
                    container
                    direction='column'
                    alignItems='center'
                    justifyContent='center'>
                    <Typography mt='3px' fontFamily='Montserrat' variant='p30' color='tertiaryDark'>
                      {originalRetirementAge}
                    </Typography>
                    <Typography variant='p12' color='tertiaryDark'>
                      {t('currentGoal')}
                    </Typography>
                  </Grid>
                  <Grid
                    xs={6}
                    container
                    direction='column'
                    alignItems='center'
                    justifyContent='center'>
                    <Typography
                      mt='3px'
                      fontFamily='Montserrat'
                      variant='p30Bold'
                      color='secondary'>
                      {recommendedRetirementAge}
                    </Typography>
                    <Typography variant='p12' color='secondary'>
                      {t('recommended')}
                    </Typography>
                  </Grid>
                </Grid>
              ) : (
                <Grid container xs={8} alignItems='center'>
                  <Grid container direction='column' alignItems='center' justifyContent='center'>
                    <Typography
                      mt='3px'
                      fontFamily='Montserrat'
                      variant='p30Bold'
                      color='secondary'>
                      {recommendedRetirementAge}
                    </Typography>
                    <Typography variant='p12' color='secondary'>
                      {t('currentGoal')}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Card>
          </Grid>
          <Grid item xs={0.5} />
          <Grid xs={5.75} container direction='column' alignItems='center' justifyContent='center'>
            <Card>
              <Typography variant='p16' color='secondary'>
                {t('SpousesRetirementAge')}
              </Typography>
              <Grid container xs={8} alignItems='center'>
                <Grid container direction='column' alignItems='center' justifyContent='center'>
                  <Typography mt='3px' fontFamily='Montserrat' variant='p30Bold' color='secondary'>
                    {originalRetirementGoal.spouse?.retirementAge ?? 0}
                  </Typography>
                  <Typography variant='p12' color='secondary'>
                    {t('currentGoal')}
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      ) : (
        <Card style={{ padding: '15px', textAlign: 'center' }}>
          <Row>
            <Typography variant='p16' color='secondary'>
              {t('YoureShootingToRetireAtThisAge')}
            </Typography>
            {showHelpModal && (
              <Tippy
                placement='right'
                content={<HelpContent recommendedRetirementAge={recommendedRetirementAge} />}
                theme='light-nickels-theme'>
                <HelpOutline style={{ color: theme.palette.primary.main }} />
              </Tippy>
            )}
          </Row>
          {agesDifferent ? (
            <Grid container xs={8} alignItems='center'>
              <Grid xs={6} container direction='column' alignItems='center' justifyContent='center'>
                <Typography mt='10px' fontFamily='Montserrat' variant='p30' color='tertiaryDark'>
                  {originalRetirementAge}
                </Typography>
                <Typography variant='p12' color='tertiaryDark'>
                  {t('currentGoal')}
                </Typography>
              </Grid>
              <Grid xs={6} container direction='column' alignItems='center' justifyContent='center'>
                <Typography mt='10px' fontFamily='Montserrat' variant='p30Bold' color='secondary'>
                  {recommendedRetirementAge}
                </Typography>
                <Typography variant='p12' color='secondary'>
                  {t('recommended')}
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <Typography mt='10px' fontFamily='Montserrat' variant='p30Bold' color='secondary'>
              {recommendedRetirementAge}
            </Typography>
          )}
        </Card>
      )}
    </>
  );
};

interface HelpContentProps {
  recommendedRetirementAge: number;
}
const HelpContent: React.FC<HelpContentProps> = ({ recommendedRetirementAge }) => {
  return (
    <Grid>
      <Typography variant='p16Bold' color='secondary'>
        {t('RetirementAge')}
      </Typography>
      <Typography variant='p16' color='secondary' mt={1}>
        {t('WeRecommendRetiringLater', { retirementAge: recommendedRetirementAge })}
      </Typography>
    </Grid>
  );
};
