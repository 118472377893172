import { t } from 'i18next';
import HelpPopover, { HelpPopoverText } from '../../../../../components/HelpPopover';

interface HelpContentProps {
  modalTitle: string;
  modalCopy: string;
}
const HelpContent: React.FC<HelpContentProps> = ({ modalTitle, modalCopy }) => {
  return (
    <HelpPopover title={t(modalTitle)}>
      <HelpPopoverText>{t(modalCopy)}</HelpPopoverText>
    </HelpPopover>
  );
};
export default HelpContent;
